'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	calculateInitUserFee,
} from '@drift-labs/sdk';
import { useEffect } from 'react';
import {
	MIN_LEFTOVER_SOL,
	NEW_ACCOUNT_BASE_COST,
	NEW_ACCOUNT_BASE_RENT,
	NEW_ACCOUNT_DONATION,
} from '@drift/common';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

export type AccountCreationCostBreakdown = {
	loaded: boolean; // Whether full cost breakdown has been loaded
	totalCost: BigNum; // The total cost to create a new account
	baseAccountRent: BigNum; // The base solana network account rent component of the fee (refundable)
	newAccountDonation: BigNum; // The drift donation component of the fee (non-refundable)
	extraRent: BigNum; // The dynamic extra rent component of the fee (refundable)
	minSolToDeposit: BigNum; // The minimum amount of SOL required to make a new account
	minSolForFees: BigNum; // The minimum amount of SOL required to cover fees
};

export const syncAccountCreationCost = () => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const setDriftStore = useDriftStore((state) => state.set);

	useEffect(() => {
		if (driftClient && driftClientIsReady) {
			const stateAccount = driftClient.getStateAccount();
			const fee = calculateInitUserFee(stateAccount);

			const newCost = NEW_ACCOUNT_BASE_COST.add(
				BigNum.from(fee, BASE_PRECISION_EXP)
			);

			setDriftStore((s) => {
				s.accountCreationCost.loaded = true;
				s.accountCreationCost.totalCost = newCost;
				s.accountCreationCost.extraRent = newCost
					.sub(NEW_ACCOUNT_BASE_RENT)
					.sub(NEW_ACCOUNT_DONATION);

				s.accountCreationCost.minSolToDeposit = newCost.add(MIN_LEFTOVER_SOL);
				s.accountCreationCost.minSolForFees = MIN_LEFTOVER_SOL;
			});
		}
	}, [driftClient, driftClientIsReady]);
};

export const useAccountCreationCost = () => {
	const accountCreationCost = useDriftStore(
		(state) => state.accountCreationCost
	);

	syncAccountCreationCost();

	return accountCreationCost;
};
