'use client';

import Drawer from 'src/components/Drawer';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import dayjs from 'dayjs';
import { Typo } from 'src/components/Text/Typo';
// import Image from 'next/image';
// import { BREAKPOINT_IGGYERIC_BET } from 'src/constants/predictionMarkets/markets';
// import BREAKPOINTS from 'src/constants/breakpoints';
import { twMerge } from 'tailwind-merge';

const WRAPPER_CLASSNAMES = 'flex flex-col text-text-secondary typo-b5';

const SubHeader = ({ children }: { children: React.ReactNode }) => (
	<div className="flex flex-col w-full">
		<Divider />
		<Typo.T3 className="mb-2 text-text-default">{children}</Typo.T3>
	</div>
);

const Section = ({ children }: { children: React.ReactNode }) => (
	<div className="mb-4">{children}</div>
);

const Divider = () => <div className="w-full h-px mb-4 bg-container-border" />;

const List = ({
	children,
	bottomMargin,
}: {
	children: React.ReactNode;
	bottomMargin?: boolean;
}) => (
	<ul className={twMerge('pl-5 list-disc', bottomMargin && 'mb-4')}>
		{children}
	</ul>
);

const ExternalLink = ({ href }: { href: string }) => (
	<a
		href={href}
		target="_blank"
		rel="noreferrer noopener"
		className="block break-all cursor-pointer text-interactive-link"
	>
		{href}
	</a>
);

const InlineExternalLink = ({ href, text }: { href: string; text: string }) => (
	<a
		href={href}
		target="_blank"
		rel="noreferrer noopener"
		className="inline-flex cursor-pointer text-interactive-link"
	>
		{text}
	</a>
);

// TODO : THIS SHOULD THROW ERRORS IF NOT ALL MARKETS ARE IN HERE
const resolutionComponents = {
	['FED-CUT-50-SEPT-2024-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the Federal Reserve&quot;s decision regarding
				the upper bound of the target federal funds rate range. The Federal Open
				Market Committee (FOMC) is responsible for making these decisions.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If, after the Federal
						Reserve&quot;s September 2024 meeting, the upper bound of the target
						federal funds rate is decreased by 50 or more basis points compared
						to its level before the meeting.
					</li>
					<li>
						<span className="font-semibold">No</span>: If the decrease is less
						than 50 basis points, or if there is no decrease or an increase.
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be the FOMC&quot;s statement
				released after its meeting scheduled for September 17-18, 2024. This
				date is based on the official Federal Reserve calendar:{' '}
				<ExternalLink href="https://www.federalreserve.gov/monetarypolicy/fomccalendars.htm" />
			</Section>
			<Section>
				The specific information regarding the target federal funds rate will be
				published on the Federal Reserve&quot;s official website:{' '}
				<ExternalLink href="https://www.federalreserve.gov/monetarypolicy/openmarket.htm" />
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market may resolve as soon as the FOMC&quot;s statement for their
					September meeting with relevant data is issued.
				</li>
				<li>
					If no statement is released by November 6, 2024, 11:59 PM ET, the
					Security Council will review credible sources of data to determine the
					final outcome
				</li>
			</List>
		</div>
	),
	['TRUMP-WIN-2024-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the candidate who wins the Presidency in the
				2024 US Presidential Election. The Presidency is won by the candidate
				who receives at least 270 out of 538 electoral votes.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If Donald J. Trump
						receives the majority of electoral votes
					</li>
					<li>
						<span className="font-semibold">No</span>: If Donald Trump does not
						receive the majority of electoral votes
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The market will resolve based on an overwhelming consensus from these
				credible sources:
				<List>
					<li>The Associated Press</li>
					<li>Fox News</li>
					<li>NBC</li>
				</List>
			</Section>
			<Section>
				Resolution requires all three sources to report the same winning
				candidate for the Presidency.
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					<span className="font-semibold">Earliest Possible Resolution</span>:
					The end of Election Day once all three credible sources reports the
					same winning candidate for the Presidency
				</li>
				<li>
					If no candidate receives a majority on Election Day, resolution may
					occur after any necessary runoff elections, based on consensus from
					all three sources.
				</li>
				<li>
					If there is no consensus by Inauguration Day (January 20, 2025), the
					winner of the Presidency is determined by who is actually inaugurated.
				</li>
			</List>

			<SubHeader>Early Expiration</SubHeader>
			<Section>
				This market may be resolved early if it becomes definitively clear that
				Donald Trump is no longer in contention for the Presidency. In this
				case, the market will resolve to &ldquo;No&ldquo;.
			</Section>
		</div>
	),
	['KAMALA-POPULAR-VOTE-2024-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the candidate who wins the Popular Vote in the
				2024 US Presidential Election. The Popular Vote is won by the candidate
				who receives the highest total number of individual votes nationwide
				(all 50 states and Washington D.C. combined).
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If Kamala Harris
						receives the highest total number of votes nationwide
					</li>
					<li>
						<span className="font-semibold">No</span>: If Kamala Harris does not
						receive the highest total number of votes nationwide
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The market will resolve based on an overwhelming consensus from these
				credible sources:
				<List>
					<li>The Associated Press</li>
					<li>Fox News</li>
					<li>NBC</li>
				</List>
			</Section>
			<Section>
				Resolution requires all three sources to report the same winning
				candidate for the Popular Vote.
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<Section>
				<li>
					<span className="font-semibold">Earliest Possible Resolution</span>:
					The end of Election Day once all three credible sources reports the
					same winning candidate for the Popular Vote.
				</li>
				<li>
					If there is no consensus at the end of Election Day, resolution will
					be based on the official certification.
				</li>
			</Section>

			<SubHeader>Early Expiration</SubHeader>
			<Section>
				This market may be resolved early if it becomes definitively clear that
				Kamala Harris is no longer in contention for the Presidency. In this
				case, the market will resolve to &ldquo;No&ldquo;.
			</Section>
		</div>
	),
	['REPUBLICAN-POPULAR-AND-WIN-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on two results of the 2024 US Presidential
				Election:
				<List>
					<li>
						<span className="font-semibold">The Presidency</span>: won by a
						candidate who receives at least 270 out of 538 electoral votes.
					</li>
					<li>
						<span className="font-semibold">The Popular Vote</span>: won by the
						candidate who receives the highest total number of individual votes
						nationwide (all 50 states and Washington D.C. combined).
					</li>
				</List>
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If a Republican
						candidate wins both the Presidency AND the Popular Vote
					</li>
					<li>
						<span className="font-semibold">No</span>: If a Republican candidate
						fails to win either the Presidency or Popular Vote (or both)
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The market will resolve based on an overwhelming consensus from these
				credible sources:
				<List>
					<li>The Associated Press</li>
					<li>Fox News</li>
					<li>NBC</li>
				</List>
			</Section>
			<Section>
				Resolution requires all three sources to report the same winning
				candidate for both the Presidency and Popular Vote.
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					<span className="font-semibold">Earliest Possible Resolution</span>:
					The end of Election Day once all three credible sources reports the
					same winning candidate for the Presidency and Popular Vote
				</li>

				<li>
					<span className="font-semibold">For the Presidency</span>:
					<List>
						<li>
							If no candidate receives a majority on Election Day, resolution
							may occur after any necessary runoff elections, based on consensus
							from all three sources.
						</li>
						<li>
							If there is no consensus by Inauguration Day (January 20, 2025),
							the winner of the Presidency is determined by who is actually
							inaugurated.
						</li>
					</List>
				</li>
			</List>

			<SubHeader>Early Expiration</SubHeader>
			<Section>
				This market may be resolved early if it becomes definitively clear that
				the Republican Party candidate is no longer in contention for the
				Presidency. In this case, the market will resolve to &ldquo;No&ldquo;.
			</Section>
		</div>
	),
	['DEMOCRATS-WIN-MICHIGAN-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the candidate who wins the Popular Vote in
				Michigan for the 2024 US Presidential Election. The Popular Vote is won
				by the candidate who receives the highest total number of votes in the
				state.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If a Democratic
						candidate receives the highest number of votes in Michigan
					</li>
					<li>
						<span className="font-semibold">No</span>: If a Democratic candidate
						does not receive the highest total number of votes in Michigan
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The market will resolve based on an overwhelming consensus from these
				credible sources:
				<List>
					<li>The Associated Press</li>
					<li>Fox News</li>
					<li>NBC</li>
				</List>
			</Section>
			<Section>
				Resolution requires all three sources to report the same winning
				candidate for Michigan.
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					<span className="font-semibold">Earliest Possible Resolution</span>:
					The end of Election Day once all three credible sources reports the
					same winning candidate for Michigan.
				</li>
				<li>
					If there is no consensus at the end of Election Day, resolution may
					occur at a later date, based on consensus from all three sources.
				</li>
			</List>

			<SubHeader>Early Expiration</SubHeader>
			<Section>
				This market may be resolved early if it becomes definitively clear that
				a Democratic candidate is no longer in contention for the Presidency. In
				this case, the market will resolve to &ldquo;No&ldquo;.
			</Section>
		</div>
	),
	['BREAKPOINT-IGGYERIC-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the five Drift judges tallied scoring of the
				debate based on a modified parliamentary debate structure. The criterion
				is detailed here:{' '}
				<ExternalLink href="https://www.drift.trade/updates/introduction-to-breakpoint-bet-market" />
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If the final score of
						Iggy Azalea’s weighted sum is higher than that of Eric Wall
					</li>
					<li>
						<span className="font-semibold">No</span>: If the final score of
						Iggy Azalea’s weighted sum is lower than that of Eric Wall
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for the resolution will be the final score and
				scoring sheets from the judges. Both will be published here:{' '}
				<ExternalLink href="https://www.drift.trade/updates/introduction-to-breakpoint-bet-market" />
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					<span className="font-semibold">Earliest Possible Resolution</span>:{' '}
					The end of debate once the winner is published on X. This will be
					within 24 hours of debate completion.
				</li>
				<li>
					<span>In the event of:</span>
					<List>
						<li>A tie: The market will resolve to &ldquo;No&ldquo;.</li>
						<li>
							No winner is announced by 2 days after the debate: The market will
							resolve to &ldquo;No&ldquo;.
						</li>
					</List>
				</li>
			</List>

			<SubHeader>Early Expiration</SubHeader>
			<Section>
				This market may be resolved early if it becomes definitively clear that
				Iggy Azalea is no longer a debate participant. In this case, the market
				will resolve to &ldquo;No&ldquo;.
			</Section>
		</div>
	),
	['LANDO-F1-SGP-WIN-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the winner of the 2024 Formula 1 Grand Prix race
				in Singapore.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: the published winner is
						Lando Norris
					</li>
					<li>
						<span className="font-semibold">No</span>: the published winner is
						NOT Lando Norris
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be the official Formula 1 site
				where 2024 Race Results are published:
				<ExternalLink href="https://www.formula1.com/en/results/2024/races" />
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market may resolve as soon as the winner is published on the
					official Formula 1 site
				</li>
				<li>
					If no winner is published by September 30, 2024, 11:59 PM ET, the
					Security Council will review credible sources of data to determine the
					final outcome
				</li>
			</List>
		</div>
	),
	['WARWICK-FIGHT-WIN-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on whether Kain Warwick (
				<InlineExternalLink href={'http://x.com/kaiynne'} text={'@kaiynne'} />
				) is declared the winner in the fight against David Hoffman (
				<InlineExternalLink
					href={'http://x.com/TrustlessState'}
					text={'@TrustlessState'}
				/>
				). Kain Warwick is scheduled to fight David Hoffman on October 11, 2024
				at Salt Palace Convention Center - Hall E as part of Karate Combat 50
				(KC50)
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If Kain Warwick is
						declared the winner in the fight
					</li>
					<li>
						<span className="font-semibold">No</span>: If Kain Warwick is not
						declared the winner in the fight
					</li>
				</List>
			</Section>
			<Section>
				If this fight is declared a draw, or if for any reason the match is not
				scored, postponed after December 1, 2024, 11:59 PM ET, or cancelled,
				this market will resolve 50-50.
			</Section>
			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be the results posted on the
				Karate Combat webapp:
				<ExternalLink href="https://app.karate.com/" />
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					If the fight proceeds as scheduled, the market will resolve on 12th
					October 2024.
				</li>
				<li>
					If the fight is postponed, the market will resolve once the new date
					is announced:
					<li className="ml-3">
						If the new date is earlier than December 1, 2024, 11:59 PM ET, the
						market will remain open
					</li>
					<li className="ml-3">
						If the new date is later than December 1, 2024, 11:59 PM ET, the
						market will resolve to 50-50
					</li>
				</li>
			</List>
		</div>
	),
	['WLF-5B-1W-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the fully diluted value (FDV) of the the
				governance token launched by World Liberty Financial, 1 week after its
				launch
				<List>
					<li>
						Since the tokens are non-transferable at token generating event
						(TGE), “the launch” is defined as the time and date at which the
						tokens are tradable
					</li>
					<li>
						&quot;1 week after the launch&quot; is defined as 168 hours after
						the tokens become tradable
					</li>
					<li>
						According to The Block, tokens are tradable 12 months after TGE
					</li>
				</List>
			</Section>

			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: If the FDV is at or
						above (equal or more than) $5,000,000,000, 1 week after the launch
					</li>
					<li>
						<span className="font-semibold">No</span>: If the FDV is below (less
						than) $5,000,000,000, 1 week after the launch
					</li>
				</List>
			</Section>

			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The exchange with the deepest liquidity, including any DEX and CEX
			</Section>

			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market will be resolved on the launch D-Day+8 (eight days after
					the token becomes tradable)
				</li>
			</List>
		</div>
	),
	['VRSTPN-WIN-F1-24-DRVRS-CHMP-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the winner of F1 2024 Drivers’ Championship.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: the published winner is
						Max Verstappen
					</li>
					<li>
						<span className="font-semibold">No</span>: the published winner is
						NOT Max Verstappen
					</li>
					<li>
						{`This market may immediately resolve to "No" if it becomes impossible
						for this driver to win based on the rules of the 2024 F1 season.`}
					</li>
					<li>
						{`If the 2024 F1 season does not end by December 29, 2024, 11:59 PM ET, this market will resolve according to the standings available then.`}
					</li>
				</List>
			</Section>
			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be the official Formula 1 site
				where the Driver Standings are published:{' '}
				<ExternalLink href="https://www.formula1.com/en/results/2024/drivers" />
			</Section>
			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					As soon as the results for the final scheduled race of the 2024 F1
					season are known (the final scheduled race for the 2024 season is
					presently scheduled for December 6-8 in Abu Dhabi:{' '}
					<ExternalLink href="https://www.formula1.com/en/racing/2024" />
					), this market will resolve.
				</li>
			</List>
		</div>
	),
	['LNDO-WIN-F1-24-US-GP-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the winner of the 2024 Formula 1 Grand Prix race
				in Austin, United States on Sunday, October 20.
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: the published winner is
						Lando Norris
					</li>
					<li>
						<span className="font-semibold">No</span>: the published winner is
						NOT Lando Norris
					</li>
				</List>
			</Section>
			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be the official Formula 1 site
				where 2024 Race Results are published:
				<ExternalLink href="https://www.formula1.com/en/results/2024/races" />
			</Section>
			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market may resolve as soon as the winner is published on the
					official Formula 1 site
				</li>
				<li>
					If no winner is published by October 25, 2024, 11:59 PM ET, the
					Security Council will review credible sources of data to determine the
					final outcome
				</li>
			</List>
		</div>
	),
	['SUPERBOWL-LIX-LIONS-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the winner of Super Bowl LIX (scheduled February
				9, 2025).
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: the published winner is
						Detroit Lions
					</li>
					<li>
						<span className="font-semibold">No</span>: the published winner is
						NOT Detroit Lions
					</li>
				</List>
			</Section>
			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be accredited media outlets
				Associated Press, ESPN.
			</Section>
			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market may resolve as soon as the winner is published by the
					resolution sources.
				</li>
				<li>
					If Super Bowl LIX is rescheduled, bets will still be valid for the new
					specified date.
				</li>
			</List>
		</div>
	),
	['SUPERBOWL-LIX-CHIEFS-BET']: (
		<div className={WRAPPER_CLASSNAMES}>
			<SubHeader>Resolution</SubHeader>
			<Section>
				This market is based on the winner of Super Bowl LIX (scheduled February
				9, 2025).
			</Section>
			<Section>
				The market will resolve as follows:
				<List>
					<li>
						<span className="font-semibold">Yes</span>: the published winner is
						Kansas City Chiefs
					</li>
					<li>
						<span className="font-semibold">No</span>: the published winner is
						NOT Kansas City Chiefs
					</li>
				</List>
			</Section>
			<SubHeader>Resolution Source</SubHeader>
			<Section>
				The primary source for resolution will be accredited media outlets
				Associated Press, ESPN.
			</Section>
			<SubHeader>Resolution Timeline</SubHeader>
			<List bottomMargin>
				<li>
					This market may resolve as soon as the winner is published by the
					resolution sources.
				</li>
				<li>
					If Super Bowl LIX is rescheduled, bets will still be valid for the new
					specified date.
				</li>
			</List>
		</div>
	),
};

export const BetMarketDetailsDrawer = () => {
	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);
	const { market } = useDrawersStore((s) => s.betMarketDetails);

	const onClose = () => {
		handleCloseHistoryPanel('betMarketDetails');
	};

	return (
		<Drawer
			drawerName="betMarketDetails"
			className="w-full sm:max-w-[400px] sm:w-[400px] flex flex-col"
		>
			<Drawer.Header
				titleContent={
					<div className="flex items-center gap-1">
						<span>BET Market Details & Rules</span>
					</div>
				}
				className="p-4"
				onClose={onClose}
			/>

			<div className="p-4 overflow-y-auto thin-scroll grow">
				<div className="flex justify-between w-full mb-4">
					<Typo.T3 className="justify-start text-text-default">
						Resolves on
					</Typo.T3>
					<Typo.B4 className="justify-end text-text-emphasis">
						{market.resolutionDateUnknown
							? 'TBD'
							: `~ ${dayjs(market.resolutionDate).format('MMM DD, YYYY')}`}
					</Typo.B4>
				</div>

				{resolutionComponents[market.symbol] ?? <></>}

				<div>
					<a
						href="https://docs.drift.trade/prediction-markets/prediction-markets-intro"
						target="_blank"
						rel="noreferrer noopener"
					>
						Learn more about Prediction Market resolution.
					</a>
				</div>
			</div>
		</Drawer>
	);
};
