'use client';

import {
	Close,
	Copy,
	Checkmark,
	Settings,
	Info,
	Layout,
} from '@drift-labs/icons';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import DriftGradientIcon from 'src/components/Icons/DriftGradientIcon';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../../Text/Text';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import { useState } from 'react';
import useShowGridCustomiser from 'src/hooks/useShowGridCustomiser';
import Tooltip from 'src/components/Tooltip/Tooltip';
import Drawer from 'src/components/Drawer';
import { useWindowSizeIsGreaterThan } from 'src/hooks/useWindowSize';
import InfoMessage from '../../TradeForm/InfoMessage';
import CopyButton from '../../Utils/CopyButton';
import useCurrentWalletIconSrc from 'src/hooks/useCurrentWalletIconSrc';
import useCurrentWalletConnectionState from 'src/hooks/useCurrentWalletConnectionState';
import UI_UTILS from 'src/utils/uiUtils';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';

const AccountGeneralInformation = ({
	onClose,
	isExternalWallet,
	isBalancesLoading,
	totalWalletQuoteBalances,
	onManageWallet,
}: {
	onClose: () => void;
	isExternalWallet: boolean;
	isBalancesLoading: boolean;
	totalWalletQuoteBalances: BigNum;
	onManageWallet: () => void;
}) => {
	const isLargeScreenSize = useWindowSizeIsGreaterThan('md');
	const currentAccount = useTickedAccountData();
	const wallet = useCurrentWalletAdapter();
	const [setDriftStore] = useDriftStore((s) => [
		s.set,
		s.wallet.isMetamask,
		s.wallet.isMagicAuth,
	]);
	const [isCopied, setIsCopied] = useState(false);
	const [showGridCustomiser, setShowGridCustomiser] = useShowGridCustomiser();
	const walletConnectionState = useCurrentWalletConnectionState();
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const currentWalletIconSrc = useCurrentWalletIconSrc();

	const openSettingsModal = () => {
		onClose();
		setDriftStore((s) => {
			s.modals.showSettingsModal = true;
		});
	};

	const handleCopyAddress = () => {
		if (!isCopied) {
			UI_UTILS.copyToClipboard(wallet?.publicKey.toString());
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 3000);
		}
	};

	const handleDisconnect = async () => {
		onClose();
		await wallet?.disconnect();
		walletConnectionState.instance.update('Connecting', false);
	};

	const handleSwitchWallet = async () => {
		await wallet?.disconnect();
		onClose();
		showConnectWalletOptions(true);
	};

	const handleOpenGridCustomiser = () => {
		onClose();
		setShowGridCustomiser(!showGridCustomiser);
	};

	return (
		<Drawer.Header
			className="gap-6 mb-2"
			titleContent={
				<div className="flex items-start justify-between w-full gap-2">
					<div className="flex flex-col gap-1 overflow-hidden">
						<div className="flex items-center space-x-1">
							<Text.H3 className="truncate text-text-emphasis whitespace-nowrap">
								{currentAccount?.name ?? 'gm'}
							</Text.H3>
							{currentAccount && (
								<Tooltip
									allowHover
									content={
										<>
											<div className="flex flex-col space-y-2 font-mono">
												<div className="flex items-center justify-between">
													<span className="flex items-center">
														<Text.BODY1 className="mr-2 font-bold">
															Subaccount ID
														</Text.BODY1>
														<span className="mr-1">
															{currentAccount.userId}
														</span>
													</span>
													<CopyButton
														toastMessage="Copied subaccount ID"
														copyValue={'' + currentAccount.userId}
													/>
												</div>
												<div className="flex items-center justify-between">
													<span className="flex items-center">
														<Text.BODY1 className="mr-2 font-bold">
															Subaccount Address
														</Text.BODY1>
														<span className="mr-1">
															{UI_UTILS.abbreviateAddress(
																currentAccount.pubKey.toString()
															)}
														</span>
													</span>
													<CopyButton
														toastMessage="Copied subaccount address"
														copyValue={currentAccount.pubKey.toString()}
													/>
												</div>
												<InfoMessage
													className=""
													message="Never send funds directly to your subaccount address! Use Drift for all deposits."
													type="error"
												/>
											</div>
										</>
									}
								/>
							)}
						</div>
						<div className="flex items-center gap-2">
							<Text.BODY3>
								{UI_UTILS.abbreviateAddress(wallet?.publicKey)}
							</Text.BODY3>
							<span
								className="flex items-center justify-center cursor-pointer hover:opacity-60"
								onClick={handleCopyAddress}
							>
								{isCopied ? (
									<Checkmark className="w-4 h-4" />
								) : (
									<Copy className="w-4 h-4" />
								)}
							</span>
							<Text.BODY3
								className="underline text-interactive-link hover:cursor-pointer"
								onClick={handleSwitchWallet}
							>
								Switch
							</Text.BODY3>
							<Text.BODY3
								className="underline text-interactive-link hover:cursor-pointer"
								onClick={handleDisconnect}
							>
								Disconnect
							</Text.BODY3>
						</div>
					</div>
					<div className="flex items-center text-text-label gap-2 relative top-[2px]">
						{isLargeScreenSize && (
							<Tooltip content={<>Configure your theme and layout</>}>
								<span
									onClick={handleOpenGridCustomiser}
									className="flex items-center justify-center px-1 transition-opacity hover:opacity-60 hover:cursor-pointer"
								>
									<Layout size={20} />
								</span>
							</Tooltip>
						)}
						<Settings
							size={20}
							onClick={openSettingsModal}
							className="transition-opacity hover:cursor-pointer hover:opacity-60"
						/>
						<Close
							size={20}
							onClick={onClose}
							className="transition-opacity hover:cursor-pointer hover:opacity-60"
						/>
					</div>
				</div>
			}
			infoContent={
				<div className="flex items-stretch gap-4">
					<div className="flex flex-col gap-1">
						<Text.H1>
							{BigNum.from(
								currentAccount?.marginInfo.netUsdValue,
								QUOTE_PRECISION_EXP
							).toNotional()}
						</Text.H1>
						<div className="flex items-center gap-[6px]">
							<DriftGradientIcon className="w-4 h-4" />
							<Text.BODY3 className="text-text-label">
								Subacct. Value
							</Text.BODY3>
						</div>
					</div>
					<div className="w-[1px] bg-container-border mr-1" />
					<div className="flex flex-col gap-1">
						{isBalancesLoading ? (
							<SkeletonValuePlaceholder className="w-20 h-6" loading />
						) : (
							<Text.H1>{totalWalletQuoteBalances.toNotional()}</Text.H1>
						)}
						<div className="flex items-center gap-[6px]">
							{!!currentWalletIconSrc && (
								<img src={currentWalletIconSrc} className="w-4 h-4" />
							)}
							<Text.BODY3 className="flex items-center text-text-label">
								Wallet Balance
								<Tooltip content="This amount only counts collateral supported on the platform.">
									<Info size={16} className="ml-1" />
								</Tooltip>
							</Text.BODY3>
							{isExternalWallet && (
								<Text.BODY3
									className="underline text-interactive-link hover:cursor-pointer"
									onClick={onManageWallet}
								>
									Manage
								</Text.BODY3>
							)}
						</div>
					</div>
				</div>
			}
		/>
	);
};

export default AccountGeneralInformation;
