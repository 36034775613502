import produce from 'immer';
import { create } from 'zustand';
import { SpotMarketConfig } from '@drift-labs/sdk';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { MAIN_POOL_ID } from '@drift/common';

/**
 * This store allows the deposit modal to persists the user inputs across opening and closing of the modal.
 */
export interface DepositInputStore {
	set: (x: (s: DepositInputStore) => void) => void;
	get: () => DepositInputStore;
	selectedSpotMarket: SpotMarketConfig;
	selectedPoolId: number;
	targetUserAccountKey: string;
	depositAmount: string;
	persistInputAmount: boolean;
}

const useDepositInputStore = create<DepositInputStore>(
	(set, get): DepositInputStore => ({
		set: (fn) => set(produce(fn)),
		get: () => get(),
		selectedSpotMarket: OrderedSpotMarkets[0],
		selectedPoolId: MAIN_POOL_ID,
		targetUserAccountKey: '',
		depositAmount: '',
		persistInputAmount: false,
	})
);

export default useDepositInputStore;
