import { AccountInfo, Connection, PublicKey } from '@solana/web3.js';
import { BigNum } from '@drift-labs/sdk';
import { Opaque } from '@drift/common';
import { PerformanceDataSet, PnlTimePeriodOption } from './performance';

export * from './trade';

export type ComputeUnits = Opaque<'ComputeUnits', number>;
export type PriorityFee = Opaque<'PriorityFee', number>;

export interface ConnectionContextValues {
	endpoint: string;
	setEndpoint: (newEndpoint: string) => void;
	connection: Connection;
	sendConnection: Connection;
	availableEndpoints: EndpointInfo[];
	setCustomEndpoints: (newCustomEndpoints: EndpointInfo[]) => void;
}

export interface MarketInfo {
	address: PublicKey;
	name: string;
	programId: PublicKey;
	deprecated: boolean;
	quoteLabel?: string;
	baseLabel?: string;
}

export interface CustomMarketInfo {
	address: string;
	name: string;
	programId: string;
	quoteLabel?: string;
	baseLabel?: string;
}

export interface FullMarketInfo {
	address?: PublicKey;
	name?: string;
	programId?: PublicKey;
	deprecated?: boolean;
	quoteLabel?: string;
	baseLabel?: string;
	marketName?: string;
	baseCurrency?: string;
	quoteCurrency?: string;
	marketInfo?: MarketInfo;
}

export interface TokenAccount {
	pubkey: PublicKey;
	account: AccountInfo<Buffer> | null;
	effectiveMint: PublicKey;
}

export interface PreferencesContextValues {
	autoSettleEnabled: boolean;
	setAutoSettleEnabled: (newAutoSettleEnabled: boolean) => void;
}

export interface EndpointInfo {
	name: string;
	url: string;
	websocket: string;
	custom: boolean;
}

/**
 * {tokenMint: preferred token account's base58 encoded public key}
 */
export interface SelectedTokenAccounts {
	[tokenMint: string]: string;
}

export interface ChartTradeType {
	market: string;
	size: number;
	price: number;
	orderId: string;
	time: number;
	side: string;
	feeCost: number;
	marketAddress: string;
}

export interface FeeRates {
	taker: number;
	maker: number;
}

export const DEFAULT_PUBLIC_KEY = new PublicKey(
	'11111111111111111111111111111111'
);

export const PERFORMANCE_TIMEFRAMES: PnlTimePeriodOption[] = [
	'24h',
	'7d',
	'30d',
	'all',
];
export const PERFORMANCE_DAYS_AGO = {
	'24h': 1,
	'7d': 7,
	'30d': 30,
	all: 90,
};

export const PERFORMANCE_VIEW_OPTIONS = [
	{ value: 'pct', label: '% Performance' },
	{ value: 'raw', label: 'Profit per Share' },
];

export const DEFAULT_PERFORMANCE_DATA: PerformanceDataSet = {
	'24h': undefined,
	'7d': undefined,
	'30d': undefined,
	all: undefined,
};

/**
 * CctpState simulates the state machine of a CCTP transfer.
 * The states are numerically assigned in order of progression.
 */
export enum CctpState {
	InitiatingTransfer,
	PollingAttestationInProgress,
	ReadyToReceive,
	Completed,
}

export type MarketPriceState = {
	bestBid: BigNum;
	bestAsk: BigNum;
	markPrice: BigNum;
	spreadPct: BigNum;
	spreadQuote: BigNum;
};

export enum AccountDrawerTab {
	Subaccounts = 'Subaccounts',
	Wallet = 'Wallet',
	Bridge = 'Bridge',
}

export type DailyAllTimePnlSnapshot = {
	epochTs: number;
	totalAccountValue: number;
	allTimeTotalPnl: number;
	allTimeTotalPnlPct: number;
};

export type MarketFilter = 'perp' | 'spot' | 'predictions';

export type SimpleFundingRateRecord = {
	marketIndex: number;
	ts: number;
	fundingRate: number;
	marketPrice: number;
	isAsymmetric: boolean;
	fundingRateLong?: number;
	fundingRateShort?: number;
};

export type StakeAccountInfo = {
	pubKey: PublicKey;
	pubKeyString: string;
	validatorPubKey: PublicKey;
	validatorPubKeyString: string;
	validatorName?: string;
	icon?: string;
	solBalance: BigNum;
};
