import React, { useState } from 'react';
import Drawer from '../Drawer';
import TextField from '../Inputs/TextField';
import { Typo } from '../Text/Typo';
import Button from '../Button';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { notify } from 'src/utils/notifications';

export const GetInTouchWithDriftDrawer = () => {
	const handleCloseDrawer = useDrawersStore((s) => s.handleCloseDrawer);

	const [telegramHandle, setTelegramHandle] = useState('');
	const [description, setDescription] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const isSubmitBtnDisabled = !telegramHandle || !description || isLoading;

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const res = await fetch('/api/get-in-touch', {
				method: 'POST',
				body: JSON.stringify({
					tgHandle: telegramHandle,
					description,
				}),
			});

			if (res.status !== 200) {
				notify({
					type: 'error',
					message: 'Error submitting contact information',
					description: 'Please try again later.',
				});
			} else {
				notify({
					type: 'success',
					message: 'Contact information submitted',
					description:
						'Your telegram handle and description has been submitted. Thank you for getting in touch!',
				});
			}

			handleCloseDrawer('getInTouchWithDrift');
		} catch (e) {
			notify({
				type: 'error',
				message: 'Error submitting contact information',
				description: 'Please try again later.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Drawer
			drawerName="getInTouchWithDrift"
			className="w-full sm:max-w-[400px] sm:w-[400px] "
		>
			<Drawer.Header
				titleContent={'Get in Touch with Drift'}
				className="py-4"
				onClose={() => handleCloseDrawer('getInTouchWithDrift')}
			/>

			<form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4">
				<div className="flex flex-col gap-1">
					<Typo.T5 className="text-text-label">Telegram Username*</Typo.T5>
					<TextField.Default
						type="text"
						onChange={(value) => setTelegramHandle(value)}
						placeholder="@telegram_username"
						className="placeholder:text-text-tertiary"
					/>
				</div>

				<div className="flex flex-col gap-1">
					<Typo.T5 className="text-text-label">Description*</Typo.T5>
					<textarea
						onChange={(e) => setDescription(e.target.value)}
						placeholder="Briefly describe your project"
						className="p-2 !font-normal placeholder:text-text-tertiary typo-b3 bg-input-bg border-input-border text-text-input rounded-sm border resize-none"
						rows={4}
					/>
				</div>

				<Button.Primary
					size="MEDIUM"
					className="w-full"
					buttonType="submit"
					disabled={isSubmitBtnDisabled}
				>
					Submit
				</Button.Primary>
			</form>
		</Drawer>
	);
};
