'use client';

import {
	BigNum,
	isVariant,
	PositionDirection,
	PRICE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { ArrowLeft } from '@drift-labs/icons';
import { useEffect, useRef, useState } from 'react';
import Text from 'src/components/Text/Text';
import TextField from '../Inputs/TextField';
import InfoMessage from './InfoMessage';
import { FieldSplitter, LabelAndField } from './TradeFormPieces';
import TradeFormInputLabel from './TradeFormInputLabel';
import Button from '../Button';
import Utility from '../Inputs/Utility';
import EstimatedProfit from './EstimatedProfit';
import Tooltip from '../Tooltip/Tooltip';
import { Info, Help } from '@drift-labs/icons';
import React from 'react';
import { compareDriftProps } from 'src/utils/compareProps';
import OrderPreviewPopup from '../Popups/OrderPreviewPopup';
import SettingsSwitch from '../Settings/SettingsSwitch';
import UI_UTILS from 'src/utils/uiUtils';
import { matchEnum, UIOrderType } from '@drift/common';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';
import {
	MIRRORED_CONFIG,
	SPLIT_CONFIG,
} from '../TradePageGrid/GridConfigurations';
import { useWindowSizeIsGreaterThan } from 'src/hooks/useWindowSize';
import { CheckboxSmallSecondary } from '../Checkbox';
import { twMerge } from 'tailwind-merge';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import useDepositAndTradeShouldBeEnabled from 'src/components/TradeForm/useDepositAndTradeShouldBeEnabled';

const PERCENT_OPTIONS = [1, 5, 10, 15];

export type TriggerOrderInfo = {
	direction: PositionDirection;
	price: BigNum;
};

/**
 * When a user inputs into the price or percent field, we set that field
 * as the target type, such that when the oracle price changes, the targeted
 * type field does not update accordingly, whereas the other field does.
 */
enum TargetType {
	Price,
	Percent,
	Undefined,
}

type BracketProps = {
	direction: 'long' | 'short';
	entryPrice: BigNum;
	entrySize: BigNum;
	goBackCallback: () => void | null;
	saveOrdersCallback: (bracketOrders: {
		takeProfit: TriggerOrderInfo;
		stopLoss: TriggerOrderInfo;
	}) => void;
	orderType: UIOrderType;
	assetSymbol: string;
	displayPrecision: number;
	savedTakeProfitPrice?: BigNum;
	savedStopLossPrice?: BigNum;
	isLiteMode?: boolean;
	// Only for lite mode, controls display of whether form is expanded or not
	isExpanded?: boolean;
	// Only for lite mode, called when user clicks the TP/SL checkbox
	onToggleExpanded?: () => void;
};

const presetOptions = [1, 3, 5, 10, 15];

const getTPSLTriggerCondition = (
	baseOrderDirection: PositionDirection,
	type: 'TP' | 'SL'
): 'above' | 'below' => {
	if (matchEnum(baseOrderDirection, PositionDirection.LONG)) {
		switch (type) {
			case 'TP':
				return 'above';
			case 'SL':
				return 'below';
			default:
				throw new Error('Expected TP or SL');
		}
	}

	if (matchEnum(baseOrderDirection, PositionDirection.SHORT)) {
		switch (type) {
			case 'TP':
				return 'below';
			case 'SL':
				return 'above';
			default:
				throw new Error('Expected TP or SL');
		}
	}
};

const BracketOrderForm = (props: BracketProps) => {
	const isInDepositToTradeState = useDepositAndTradeShouldBeEnabled();
	const tpSlDisabled = isInDepositToTradeState;

	const calcPercent = (newPrice: BigNum): number => {
		const diff = props.entryPrice.sub(newPrice).abs();
		if (diff.eqZero() || props.entryPrice.eqZero()) {
			return 0;
		}
		const result = diff.toPercentage(
			props.entryPrice,
			PRICE_PRECISION_EXP.toNumber()
		);
		return Number(result);
	};

	const isMounted = useRef(false);

	// Form input states
	const [takeProfitPriceStringValue, setTakeProfitPriceStringValue] = useState(
		props.savedTakeProfitPrice
			? props.savedTakeProfitPrice.toTradePrecision()
			: ''
	);
	const [takeProfitPercentStringValue, setTakeProfitPercentStringValue] =
		useState(
			props.savedTakeProfitPrice
				? calcPercent(props.savedTakeProfitPrice).toFixed(2)
				: ''
		);
	const [stopLossPriceStringValue, setStopLossPriceStringValue] = useState(
		props.savedStopLossPrice ? props.savedStopLossPrice.toTradePrecision() : ''
	);
	const [stopLossPercentStringValue, setStopLossPercentStringValue] = useState(
		props.savedStopLossPrice
			? calcPercent(props.savedStopLossPrice).toFixed(2)
			: ''
	);

	// Valid / invalid states
	const [takeProfitInvalid, setTakeProfitInvalid] = useState(false);
	const [stopLossInvalid, setStopLossInvalid] = useState(false);

	// percentage selector states
	const [tpPercentageSelectorIndex, setTPPercentageSelectorIndex] = useState<
		number | null
	>(null);
	const [slPercentageSelectorIndex, setSLPercentageSelectorIndex] = useState<
		number | null
	>(null);

	// target type states
	const [takeProfitTargetType, setTakeProfitTargetType] = useState(
		TargetType.Undefined
	);
	const [stopLossTargetType, setStopLossTargetType] = useState(
		TargetType.Undefined
	);

	// preview panel states
	const [layoutSetting] = useLayoutConfigSetting();
	const [showBracketOrderPreview, setShowBracketOrderPreview] =
		useLocalStorageState('showBracketOrderPreview', true);
	const [isTPInFocus, setIsTPInFocus] = useState(false);
	const [isSLInFocus, setIsSLInFocus] = useState(false);

	// Computed values of the take profit and stop loss prices
	const [takeProfitPrice, setTakeProfitPrice] = useState<BigNum | null>(
		props.savedTakeProfitPrice ?? null
	);
	const [stopLossPrice, setStopLossPrice] = useState<BigNum | null>(
		props.savedStopLossPrice ?? null
	);
	const [takeProfitPercent, setTakeProfitPercent] = useState<number | null>(
		null
	);
	const [stopLossPercent, setStopLossPercent] = useState<number | null>(null);

	const isLargeScreenSize = useWindowSizeIsGreaterThan('md');

	const previewPanelSide = [MIRRORED_CONFIG.value, SPLIT_CONFIG.value].includes(
		layoutSetting
	)
		? 'right'
		: 'left';

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen: setIsBracketOrderPreviewOpen,
		isPopoverOpen: isBracketOrderPreviewOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: previewPanelSide,
		},
		{
			offset: 12,
			disableAutoPlacement: true,
			disabledDismiss: true,
		}
	);

	useEffect(() => {
		setIsBracketOrderPreviewOpen(showBracketOrderPreview);
	}, [showBracketOrderPreview]);

	const positionIsLong = props.direction === 'long';

	const baseOrderDirection = positionIsLong
		? PositionDirection.LONG
		: PositionDirection.SHORT;

	const triggerOrderDirection = positionIsLong
		? PositionDirection.SHORT
		: PositionDirection.LONG;

	const isMarketOrder = props.orderType === 'market';

	const isExpandedInLiteMode = props.isExpanded && props.isLiteMode;

	const calcStopLossPrice = (percentMove: number): BigNum => {
		const diff = props.entryPrice.scale(percentMove * 100, 10000);
		return positionIsLong
			? props.entryPrice.sub(diff)
			: props.entryPrice.add(diff);
	};

	const calcTakeProfitPrice = (percentMove: number): BigNum => {
		const diff = props.entryPrice.scale(percentMove * 100, 10000);
		return positionIsLong
			? props.entryPrice.add(diff)
			: props.entryPrice.sub(diff);
	};

	const updateTakeProfitPrice = (newValue: string) => {
		setTakeProfitTargetType(TargetType.Price);

		newValue = UI_UTILS.disallowNegativeStringInput(newValue);

		if (!newValue) {
			blankTakeProfitFields();
			return;
		}

		setTakeProfitPriceStringValue(newValue);
		setTPPercentageSelectorIndex(null);

		const newPriceBigNum = BigNum.fromPrint(newValue, PRICE_PRECISION_EXP);
		const newPercent = calcPercent(newPriceBigNum);

		const isNewTakeProfitInvalid =
			(positionIsLong && newPriceBigNum.lt(props.entryPrice)) ||
			(!positionIsLong && newPriceBigNum.gt(props.entryPrice));

		setTakeProfitInvalid(isNewTakeProfitInvalid);

		if (isNewTakeProfitInvalid) {
			setTakeProfitPercentStringValue('');
			setTakeProfitPercent(0);
			setTakeProfitPrice(null);
		} else {
			setTakeProfitPercentStringValue(newPercent.toFixed(2));
			setTakeProfitPercent(newPercent);
			setTakeProfitPrice(newPriceBigNum);
		}

		// if (props.isLiteMode) {
		// 	handleSaveOrders();
		// }
	};

	const updateTakeProfitPercent =
		(resetPercentageSelector: boolean) => (newValue: string) => {
			setTakeProfitTargetType(TargetType.Percent);

			newValue = UI_UTILS.disallowNegativeStringInput(newValue);

			if (!newValue) {
				blankTakeProfitFields();
				return;
			}

			if (resetPercentageSelector) {
				setTPPercentageSelectorIndex(null);
			}

			setTakeProfitPercentStringValue(newValue);
			setTakeProfitInvalid(false);

			const newPercent = Number(newValue);

			let newPrice = calcTakeProfitPrice(newPercent);

			if (newPrice.ltZero()) {
				newPrice = BigNum.zero();
			}

			setTakeProfitPriceStringValue(newPrice.toFixed(props.displayPrecision));
			setTakeProfitPercent(newPercent);
			setTakeProfitPrice(newPrice);

			// if (props.isLiteMode) {
			// 	handleSaveOrders();
			// }
		};

	const updateStopLossPrice = (newValue: string) => {
		setStopLossTargetType(TargetType.Price);

		newValue = UI_UTILS.disallowNegativeStringInput(newValue);

		if (!newValue) {
			blankStopLossFields();
			return;
		}

		setStopLossPriceStringValue(newValue);
		setSLPercentageSelectorIndex(null);

		const newPriceBigNum = BigNum.fromPrint(newValue, PRICE_PRECISION_EXP);
		const newPercent = calcPercent(newPriceBigNum);

		const isNewStopLossInvalid =
			(positionIsLong && newPriceBigNum.gt(props.entryPrice)) ||
			(!positionIsLong && newPriceBigNum.lt(props.entryPrice));

		setStopLossInvalid(isNewStopLossInvalid);

		if (isNewStopLossInvalid) {
			setStopLossPrice(null);
			setStopLossPercent(0);
			setStopLossPercentStringValue('');
		} else {
			setStopLossPrice(newPriceBigNum);
			setStopLossPercent(newPercent);
			setStopLossPercentStringValue(newPercent.toFixed(2));
		}
	};

	const updateStopLossPercent =
		(resetPercentageSelector: boolean) => (newValue: string) => {
			setStopLossTargetType(TargetType.Percent);

			newValue = UI_UTILS.disallowNegativeStringInput(newValue);

			if (!newValue) {
				blankStopLossFields();
				return;
			}

			if (resetPercentageSelector) {
				setSLPercentageSelectorIndex(null);
			}

			setStopLossPercentStringValue(newValue);
			setStopLossInvalid(false);

			const newPercent = Number(newValue);
			let newPrice = calcStopLossPrice(newPercent);

			if (newPrice.ltZero()) {
				newPrice = BigNum.zero();
			}

			setStopLossPercent(newPercent);
			setStopLossPrice(newPrice);
			setStopLossPriceStringValue(newPrice.toFixed(props.displayPrecision));
		};

	const blankTakeProfitFields = () => {
		setTakeProfitPriceStringValue('');
		setTakeProfitPercentStringValue('');
		setTakeProfitInvalid(false);
		setTakeProfitTargetType(TargetType.Undefined);
		setTPPercentageSelectorIndex(null);
	};

	const blankStopLossFields = () => {
		setStopLossPriceStringValue('');
		setStopLossPercentStringValue('');
		setStopLossInvalid(false);
		setStopLossTargetType(TargetType.Undefined);
		setSLPercentageSelectorIndex(null);
	};

	const targetTypesMatch = (
		targetType: TargetType,
		currentTargetType: TargetType
	) => {
		return isMarketOrder && targetType === currentTargetType;
	};

	// call the add orders callback any time the values change if props.isLiteMode is true
	const handleSaveOrders = () => {
		const orders = {
			takeProfit:
				!takeProfitPriceStringValue || takeProfitInvalid
					? undefined
					: takeProfitPrice?.gtZero() || takeProfitPercent
					? {
							direction: triggerOrderDirection,
							price: takeProfitPrice,
					  }
					: props.savedTakeProfitPrice
					? {
							direction: triggerOrderDirection,
							price: props.savedTakeProfitPrice,
					  }
					: undefined,
			stopLoss:
				!stopLossPriceStringValue || stopLossInvalid
					? undefined
					: stopLossPrice?.gtZero() || stopLossPercent
					? {
							direction: triggerOrderDirection,
							price: stopLossPrice,
					  }
					: props.savedStopLossPrice
					? {
							direction: triggerOrderDirection,
							price: props.savedStopLossPrice,
					  }
					: undefined,
			anyInvalid: takeProfitInvalid || stopLossInvalid,
		};
		props.saveOrdersCallback(orders);
	};

	const handleClearOrders = () => {
		props.saveOrdersCallback(undefined);
	};

	const handletoggleIsLiteModeFormExpanded = () => {
		if (props.isExpanded) {
			handleClearOrders();
		}
		props.onToggleExpanded();
	};

	// Need to save the orders when the form expands too, for lite mode
	// Because they get cleared when the user collapses, but the values are still stored in this component
	useEffect(() => {
		if (props.isExpanded && props.isLiteMode) {
			handleSaveOrders();
		}
	}, [
		props.isExpanded,
		props.isLiteMode,
		stopLossPrice,
		takeProfitPrice,
		takeProfitPriceStringValue,
		stopLossPriceStringValue,
	]);

	// Reset values when changing direction
	useEffect(() => {
		// don't reset values on mount
		if (!isMounted.current) {
			isMounted.current = true;
			return;
		}

		if (props.isLiteMode) {
			setTakeProfitPercent(null);
			setStopLossPercent(null);
			setTakeProfitPrice(null);
			setStopLossPrice(null);
			blankTakeProfitFields();
			blankStopLossFields();
			setStopLossInvalid(false);
			setTakeProfitInvalid(false);
			handleClearOrders();
		}
	}, [props.isLiteMode, props.direction]);

	// Keep TP price in sync with percent +/- entry price if percent equals one of the hard coded options
	useEffect(() => {
		if (tpSlDisabled) return;

		if (PERCENT_OPTIONS.includes(takeProfitPercent)) {
			const newPrice = calcTakeProfitPrice(takeProfitPercent);
			setTakeProfitPrice(newPrice);
			setTakeProfitPriceStringValue(newPrice.toFixed(props.displayPrecision));
			if (isExpandedInLiteMode) {
				handleSaveOrders();
			}
		}
	}, [
		takeProfitPercent,
		isExpandedInLiteMode,
		props.entryPrice,
		props.displayPrecision,
		tpSlDisabled,
	]);

	// Keep SL price in sync with percent +/- entry price if percent equals one of the hard coded options
	useEffect(() => {
		if (tpSlDisabled) return;

		if (PERCENT_OPTIONS.includes(stopLossPercent)) {
			const newPrice = calcStopLossPrice(stopLossPercent);
			setStopLossPrice(newPrice);
			setStopLossPriceStringValue(newPrice.toFixed(props.displayPrecision));
			if (isExpandedInLiteMode) {
				handleSaveOrders();
			}
		}
	}, [
		stopLossPercent,
		isExpandedInLiteMode,
		props.entryPrice,
		props.displayPrecision,
		tpSlDisabled,
	]);

	// Clear bracket orders when we enter tpSlDisabled state
	useEffect(() => {
		if (tpSlDisabled) {
			handleClearOrders();
		}
	}, [tpSlDisabled]);

	const errorMessages = (
		<>
			{takeProfitInvalid && isExpandedInLiteMode && (
				<>
					<div className="py-2" />
					<InfoMessage
						type={'error'}
						message={`Take Profit Trigger Oracle Price needs to be ${
							positionIsLong ? 'above' : 'below'
						} the entry price for this type of order`}
					/>
				</>
			)}

			{stopLossInvalid && isExpandedInLiteMode && (
				<>
					<div className="py-2" />
					<InfoMessage
						type={'error'}
						message={`Stop Loss Trigger Oracle Price needs to be ${
							positionIsLong ? 'below' : 'above'
						} the entry price for this type of order`}
					/>
				</>
			)}
		</>
	);

	const estimatedPnlTP = (
		<>
			{takeProfitPrice && takeProfitPrice.gtZero() && (
				<EstimatedProfit
					currentPositionSize={props.entrySize}
					currentPositionEntryPrice={props.entryPrice}
					currentPositionDirection={props.direction}
					side={isVariant(triggerOrderDirection, 'long') ? 'buy' : 'sell'}
					limitExitSize={props.entrySize}
					limitExitPrice={takeProfitPrice}
					customLabel={props.isLiteMode ? '' : 'Est. profit P&L'}
					showPlusSign={true}
					isMarketOrder={true}
				></EstimatedProfit>
			)}
		</>
	);

	const estimatedPnlSL = (
		<>
			{stopLossPrice && stopLossPrice.gtZero() && (
				<EstimatedProfit
					currentPositionSize={props.entrySize}
					currentPositionEntryPrice={props.entryPrice}
					currentPositionDirection={props.direction}
					side={isVariant(triggerOrderDirection, 'long') ? 'buy' : 'sell'}
					limitExitSize={props.entrySize}
					limitExitPrice={stopLossPrice}
					customLabel={props.isLiteMode ? '' : 'Est. stop P&L'}
					isMarketOrder={true}
				></EstimatedProfit>
			)}
		</>
	);

	if (tpSlDisabled) return null;

	if (props.isLiteMode) {
		const formDisabled = !props.entrySize || props.entrySize.eqZero();

		return (
			<div className="my-5">
				<button
					className={twMerge(
						'flex flex-row items-center space-x-2',
						formDisabled && 'opacity-50'
					)}
					onClick={formDisabled ? null : handletoggleIsLiteModeFormExpanded}
					disabled={formDisabled}
				>
					<CheckboxSmallSecondary
						checked={props.isExpanded && !formDisabled}
						checkedColor={'text-text-default'}
					/>{' '}
					<Text.BODY2
						className={`${
							props.isExpanded && !formDisabled
								? 'text-text-default'
								: 'text-text-label'
						} transition-all`}
					>
						Take Profit/Stop Loss
					</Text.BODY2>
				</button>
				<div
					className={`transition-all overflow-hidden h-auto ${
						props.isExpanded && !formDisabled ? 'max-h-[150px]' : 'max-h-0'
					}`}
				>
					<div className="my-3">
						<div className="flex flex-row items-center justify-between">
							<Text.BODY2 className="text-text-label">Take Profit</Text.BODY2>
							{estimatedPnlTP}
						</div>
						<div className="flex flex-row items-center mt-1 space-x-1">
							<div className="shrink min-w-[130px]">
								<TextField.Default
									type="number"
									onChange={updateTakeProfitPrice}
									depsForOnChange={[props.entryPrice.toNum()]}
									value={takeProfitPriceStringValue}
									prefix="$"
									highlighted={targetTypesMatch(
										TargetType.Price,
										takeProfitTargetType
									)}
									onFocus={() => setIsTPInFocus(true)}
									onBlur={() => setIsTPInFocus(false)}
									disabled={!props.entryPrice || props.entryPrice.eqZero()}
								/>
							</div>
							<PercentageSelector
								selectedIndex={tpPercentageSelectorIndex}
								setSelectedIndex={setTPPercentageSelectorIndex}
								selectCallback={updateTakeProfitPercent(false)}
								options={PERCENT_OPTIONS}
								disabled={!props.entryPrice || props.entryPrice.eqZero()}
							/>
						</div>
					</div>

					<div className="mt-3">
						<div className="flex flex-row items-center justify-between">
							<Text.BODY2 className="text-text-label">Stop Loss</Text.BODY2>
							{estimatedPnlSL}
						</div>
						<div className="flex flex-row items-center mt-1 space-x-1">
							<div className="shrink min-w-[130px]">
								<TextField.Default
									type="number"
									onChange={updateStopLossPrice}
									depsForOnChange={[props.entryPrice.toNum()]}
									value={stopLossPriceStringValue}
									prefix="$"
									highlighted={targetTypesMatch(
										TargetType.Price,
										stopLossTargetType
									)}
									onFocus={() => setIsSLInFocus(true)}
									onBlur={() => setIsSLInFocus(false)}
									disabled={!props.entryPrice || props.entryPrice.eqZero()}
								/>
							</div>
							<PercentageSelector
								selectedIndex={slPercentageSelectorIndex}
								setSelectedIndex={setSLPercentageSelectorIndex}
								selectCallback={updateStopLossPercent(false)}
								options={PERCENT_OPTIONS}
								disabled={!props.entryPrice || props.entryPrice.eqZero()}
							/>
						</div>
					</div>
				</div>

				{errorMessages}
			</div>
		);
	}

	return (
		<div className={`flex flex-col overflow-auto thin-scroll justify-between`}>
			<div
				className="justify-start block mb-4"
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				<div className="inline-flex justify-between w-full py-2">
					<div className="inline-flex items-center text-text-label">
						<ArrowLeft
							size={18}
							onClick={props.goBackCallback}
							className="hover:cursor-pointer"
						/>
						<Text.H5 className="ml-2 text-text-emphasis">
							Add Trigger Orders
						</Text.H5>
					</div>
					<div>
						<Tooltip
							key={'tp_sl_tooltip'}
							className="items-center"
							placement={'right'}
							content={
								<div className="flex flex-col space-y-2">
									<span>
										{`If you already have a position open, TP/SL may trigger even
										if your ${props.orderType} order has not filled.`}
									</span>
								</div>
							}
						>
							<Help
								size={18}
								color="var(--text-secondary)"
								className={'hover:cursor-pointer'}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
			<div className="flex flex-col my-2">
				<FieldSplitter>
					<LabelAndField customWidthClass="w-[75%]">
						<TradeFormInputLabel allowPointer>
							<div className="inline-flex items-center">
								<span>Take Profit Oracle Price</span>
								<Tooltip
									key={'tp_price_tooltip'}
									className="items-center"
									placement={'right'}
									content={
										<div className="flex flex-col space-y-2">
											<span>
												{`If oracle price is ${getTPSLTriggerCondition(
													baseOrderDirection,
													'TP'
												)} ${
													takeProfitPrice
														? takeProfitPrice.toNotional(true)
														: 'this price'
												}, a Market ${
													isVariant(triggerOrderDirection, 'long')
														? 'Buy'
														: 'Sell'
												} order for ${props.entrySize.prettyPrint()} ${
													props.assetSymbol
												} will trigger to capture profits.`}
											</span>
										</div>
									}
								>
									<Info size={14} className={'ml-1'} />
								</Tooltip>
							</div>
						</TradeFormInputLabel>
						<TextField.Default
							type="number"
							onChange={updateTakeProfitPrice}
							depsForOnChange={[props.entryPrice.toNum()]}
							value={takeProfitPriceStringValue}
							showIconForMarketSymbol={'USDC'}
							highlighted={targetTypesMatch(
								TargetType.Price,
								takeProfitTargetType
							)}
							onFocus={() => setIsTPInFocus(true)}
							onBlur={() => setIsTPInFocus(false)}
						/>
					</LabelAndField>
					<LabelAndField>
						<TradeFormInputLabel></TradeFormInputLabel>
						<TextField.Default
							type="number"
							onChange={updateTakeProfitPercent(true)}
							value={takeProfitPercentStringValue}
							prefix={positionIsLong ? '+' : '-'}
							prefixTextClass={
								positionIsLong ? 'text-positive-green' : 'text-negative-red'
							}
							suffix={'%'}
							highlighted={targetTypesMatch(
								TargetType.Percent,
								takeProfitTargetType
							)}
							onFocus={() => setIsTPInFocus(true)}
							onBlur={() => setIsTPInFocus(false)}
						/>
					</LabelAndField>
				</FieldSplitter>
			</div>
			<PercentageSelector
				selectCallback={updateTakeProfitPercent(false)}
				selectedIndex={tpPercentageSelectorIndex}
				setSelectedIndex={setTPPercentageSelectorIndex}
			/>
			<div className="flex flex-col mt-4 mb-2">
				<FieldSplitter>
					<LabelAndField customWidthClass="w-[75%]">
						<TradeFormInputLabel allowPointer>
							<div className="inline-flex items-center">
								<span>Stop Loss Oracle Price</span>
								<Tooltip
									key={'sl_price_tooltip'}
									className="items-center"
									placement={'right'}
									content={
										<div className="flex flex-col space-y-2">
											<span>
												{`If oracle price is ${getTPSLTriggerCondition(
													baseOrderDirection,
													'SL'
												)} ${
													stopLossPrice
														? stopLossPrice.toNotional(true)
														: 'this price'
												}, a Market ${
													isVariant(triggerOrderDirection, 'long')
														? 'Buy'
														: 'Sell'
												} order for ${props.entrySize.prettyPrint()} ${
													props.assetSymbol
												} will trigger to prevent further losses.`}
											</span>
										</div>
									}
								>
									<Info size={14} className={'ml-1'} />
								</Tooltip>
							</div>
						</TradeFormInputLabel>
						<TextField.Default
							type="number"
							onChange={updateStopLossPrice}
							depsForOnChange={[props.entryPrice.toNum()]}
							value={stopLossPriceStringValue}
							showIconForMarketSymbol={'USDC'}
							highlighted={targetTypesMatch(
								TargetType.Price,
								stopLossTargetType
							)}
							onFocus={() => setIsSLInFocus(true)}
							onBlur={() => setIsSLInFocus(false)}
						/>
					</LabelAndField>
					<LabelAndField>
						<TradeFormInputLabel></TradeFormInputLabel>
						<TextField.Default
							type="number"
							onChange={updateStopLossPercent(true)}
							value={stopLossPercentStringValue}
							prefix={!positionIsLong ? '+' : '-'}
							prefixTextClass={
								!positionIsLong ? 'text-positive-green' : 'text-negative-red'
							}
							suffix={'%'}
							highlighted={targetTypesMatch(
								TargetType.Percent,
								stopLossTargetType
							)}
							onFocus={() => setIsSLInFocus(true)}
							onBlur={() => setIsSLInFocus(false)}
						/>
					</LabelAndField>
				</FieldSplitter>
			</div>

			<PercentageSelector
				selectedIndex={slPercentageSelectorIndex}
				setSelectedIndex={setSLPercentageSelectorIndex}
				selectCallback={updateStopLossPercent(false)}
			/>

			{isLargeScreenSize && (
				<>
					<div className="mt-4 text-text-label">
						<SettingsSwitch
							label="Order Preview"
							checked={showBracketOrderPreview}
							onChange={() =>
								setShowBracketOrderPreview(!showBracketOrderPreview)
							}
							spacedOut={false}
						/>
					</div>

					<Utility.VERTDIVIDER />
				</>
			)}

			{errorMessages}

			<Utility.VERTSPACERL />

			<div className="flex flex-col w-full space-y-1 rounded-md advanced-trade-details">
				<div className="inline-flex justify-between w-full text-xs">
					<Text.BODY3 className="text-text-secondary">
						Est. Entry Price
					</Text.BODY3>
					<Text.BODY3 className={'text-text-emphasis'}>
						{props.entryPrice.toNotional()}
					</Text.BODY3>
				</div>
				<Tooltip
					key={'est_profits_tooltip'}
					className="flex flex-col w-full space-y-1 rounded-md advanced-trade-details"
					placement={'right'}
					content={
						<div className="flex flex-col space-y-2">
							<span>P&L estimates include your maximum slippage.</span>
						</div>
					}
				>
					{estimatedPnlTP}
					{estimatedPnlSL}
				</Tooltip>

				{!!stopLossPercent && !!takeProfitPercent && (
					<div className="inline-flex justify-between w-full text-xs">
						<Text.BODY3 className="text-text-secondary">
							Risk Reward Ratio
						</Text.BODY3>
						<Text.BODY3 className={'text-text-emphasis'}>
							{(+takeProfitPercent / +stopLossPercent).toFixed(2)}
						</Text.BODY3>
					</div>
				)}
			</div>

			<Utility.VERTSPACERL />

			<Button.BigSemantic
				positive={true}
				disabled={
					takeProfitInvalid ||
					stopLossInvalid ||
					(!takeProfitPrice &&
						!stopLossPrice &&
						!takeProfitPercent &&
						!stopLossPercent)
				}
				onClick={handleSaveOrders}
			>
				{props.savedTakeProfitPrice
					? 'Update Trigger Orders'
					: 'Add Trigger Orders'}
			</Button.BigSemantic>
			<Utility.VERTSPACERS />
			<Button.Ghost
				size="MEDIUM"
				textClass="text-negative-red px-4 mt-1 rounded-md"
				onClick={handleClearOrders}
			>
				{props.savedTakeProfitPrice ? 'Delete Orders' : 'Cancel'}
			</Button.Ghost>

			{isLargeScreenSize && isBracketOrderPreviewOpen && (
				<OrderPreviewPopup
					isLongPosition={positionIsLong}
					takeProfitPct={
						takeProfitPercentStringValue
							? +takeProfitPercentStringValue
							: undefined
					}
					takeProfitPrice={takeProfitPriceStringValue}
					stopLossPct={
						stopLossPercentStringValue ? +stopLossPercentStringValue : undefined
					}
					stopLossPrice={stopLossPriceStringValue}
					onClose={() => setShowBracketOrderPreview(false)}
					isTPInFocus={isTPInFocus}
					isSLInFocus={isSLInFocus}
					entryPrice={props.entryPrice.toFixed(props.displayPrecision)}
					setFloating={refs.setFloating}
					getFloatingProps={getFloatingProps}
					floatingStyles={floatingStyles}
				/>
			)}
		</div>
	);
};

const PercentageSelector = React.memo(function percentageSelectorMemo(props: {
	selectCallback: (value: string) => void;
	selectedIndex: null | number;
	setSelectedIndex: (index: number) => void;
	options?: number[];
	disabled?: boolean;
}) {
	const options = props.options ?? presetOptions;

	return (
		<div className="flex justify-between flex-grow">
			{options.map((option, index) => (
				<Button.Secondary
					key={option}
					size="SMALL"
					roundedGradientBorder
					selected={index === props.selectedIndex}
					className={`flex-grow ${
						index === 0
							? 'mr-0.5'
							: index === options.length - 1
							? 'ml-0.5'
							: 'mx-0.5'
					} px-2 py-2 text-xs font-numeral`}
					style={{ width: '0' }}
					onClick={() => {
						props.selectCallback(option.toString());
						props.setSelectedIndex(index);
					}}
					disabled={props.disabled}
				>
					{option}%
				</Button.Secondary>
			))}
		</div>
	);
});

export default React.memo(BracketOrderForm, compareDriftProps);
