'use client';

import { SpotMarketConfig, BigNum } from '@drift-labs/sdk';
import { MIN_LEFTOVER_SOL } from 'src/constants/constants';
import useCurrentWalletCollateralBalance from './useCurrentWalletCollateralBalance';
import { useAccountCreationCost } from './useAccountCreationCost';

const useTransferableWalletCollateralBalance = (
	bank: SpotMarketConfig,
	isInitialSolDeposit?: boolean
): [BigNum, boolean] => {
	const { totalCost: accountCreationCost } = useAccountCreationCost();
	const [currentBalance, loadingBalance] =
		useCurrentWalletCollateralBalance(bank);

	const minLeftoverSolBigNum = isInitialSolDeposit
		? accountCreationCost.add(MIN_LEFTOVER_SOL)
		: MIN_LEFTOVER_SOL;

	const transferableBalanceBignum =
		bank.symbol === 'SOL'
			? currentBalance.gt(minLeftoverSolBigNum)
				? currentBalance.sub(minLeftoverSolBigNum)
				: new BigNum(0, bank.precisionExp)
			: currentBalance;

	return [transferableBalanceBignum, loadingBalance];
};

export default useTransferableWalletCollateralBalance;
