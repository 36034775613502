'use client';

import { useEffect, useMemo, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useInterval from './useInterval';
import { PublicKey, StakeProgram } from '@solana/web3.js';
import useValidatorsList from './useValidatorsList';
import { BASE_PRECISION_EXP, BigNum } from '@drift-labs/sdk';
import { singletonHook } from 'react-singleton-hook';
import useCurrentAuthority from './useCurrentAuthority';
import { StakeAccountInfo } from 'src/@types/types';

const useAuthorityStakeAccounts = (updateInterval = 0) => {
	const authority = useCurrentAuthority();
	const connection = useDriftStore((s) => s.connection.current);

	const { validators, isLoading: validatorsLoading } = useValidatorsList();

	const [isLoading, setIsLoading] = useState(true);

	const [parsedStakeAccounts, setParsedStakeAccounts] = useState<
		StakeAccountInfo[]
	>([]);

	// Validator info loads slowly, so add it after it loads in
	const stakeAccountsWithValidatorInfo = useMemo<StakeAccountInfo[]>(() => {
		if (validators.length === 0) {
			return parsedStakeAccounts;
		}

		return parsedStakeAccounts.map((stakeAccount) => {
			const validator = validators.find(
				(v) => v.voteAccount === stakeAccount.validatorPubKeyString
			);
			const icon = validator?.icon;
			const validatorName =
				validator?.name || stakeAccount.validatorPubKeyString;
			return {
				...stakeAccount,
				icon,
				validatorName,
			};
		});
	}, [parsedStakeAccounts, validators?.length]);

	const syncStakeAccounts = () => {
		if (authority && connection) {
			connection
				.getParsedProgramAccounts(StakeProgram.programId, {
					filters: [
						{
							memcmp: {
								offset: 44,
								bytes: authority.toString(),
							},
						},
					],
				})
				.then((response) => {
					console.log('Stake Accounts', response);
					const stakeAccounts = response.map(({ account, pubkey }) => {
						const pubKey = pubkey;
						const validatorPubKey = new PublicKey(
							// @ts-ignore
							account?.data?.parsed?.info?.stake?.delegation?.voter
						);
						const validatorPubKeyString = validatorPubKey.toString();
						const solBalance = BigNum.from(
							account.lamports,
							BASE_PRECISION_EXP
						);

						return {
							pubKey,
							pubKeyString: pubKey.toString(),
							validatorPubKey,
							validatorPubKeyString,
							solBalance,
						};
					});
					setParsedStakeAccounts(stakeAccounts);
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		syncStakeAccounts();
	}, [connection, authority]);

	useInterval(() => {
		if (updateInterval) {
			syncStakeAccounts();
		}
	}, updateInterval || 60000);

	return {
		stakeAccounts: stakeAccountsWithValidatorInfo,
		isLoading,
		validatorsLoading,
	};
};

export default singletonHook(
	{
		stakeAccounts: [],
		isLoading: false,
		validatorsLoading: false,
	},
	useAuthorityStakeAccounts
);
