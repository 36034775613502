"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOracleClient = void 0;
const types_1 = require("../types");
const pythClient_1 = require("../oracles/pythClient");
// import { SwitchboardClient } from '../oracles/switchboardClient';
const quoteAssetOracleClient_1 = require("../oracles/quoteAssetOracleClient");
const anchor_1 = require("@coral-xyz/anchor");
const prelaunchOracleClient_1 = require("../oracles/prelaunchOracleClient");
const switchboardClient_1 = require("../oracles/switchboardClient");
const pythPullClient_1 = require("../oracles/pythPullClient");
const switchboardOnDemandClient_1 = require("../oracles/switchboardOnDemandClient");
const pythLazerClient_1 = require("../oracles/pythLazerClient");
function getOracleClient(oracleSource, connection, program) {
    if ((0, types_1.isVariant)(oracleSource, 'pyth')) {
        return new pythClient_1.PythClient(connection);
    }
    if ((0, types_1.isVariant)(oracleSource, 'pythPull')) {
        return new pythPullClient_1.PythPullClient(connection);
    }
    if ((0, types_1.isVariant)(oracleSource, 'pyth1K')) {
        return new pythClient_1.PythClient(connection, new anchor_1.BN(1000));
    }
    if ((0, types_1.isVariant)(oracleSource, 'pyth1KPull')) {
        return new pythPullClient_1.PythPullClient(connection, new anchor_1.BN(1000));
    }
    if ((0, types_1.isVariant)(oracleSource, 'pyth1M')) {
        return new pythClient_1.PythClient(connection, new anchor_1.BN(1000000));
    }
    if ((0, types_1.isVariant)(oracleSource, 'pyth1MPull')) {
        return new pythPullClient_1.PythPullClient(connection, new anchor_1.BN(1000000));
    }
    if ((0, types_1.isVariant)(oracleSource, 'pythStableCoin')) {
        return new pythClient_1.PythClient(connection, undefined, true);
    }
    if ((0, types_1.isVariant)(oracleSource, 'pythStableCoinPull')) {
        return new pythPullClient_1.PythPullClient(connection, undefined, true);
    }
    if ((0, types_1.isVariant)(oracleSource, 'switchboard')) {
        return new switchboardClient_1.SwitchboardClient(connection);
    }
    if ((0, types_1.isVariant)(oracleSource, 'prelaunch')) {
        return new prelaunchOracleClient_1.PrelaunchOracleClient(connection, program);
    }
    if ((0, types_1.isVariant)(oracleSource, 'quoteAsset')) {
        return new quoteAssetOracleClient_1.QuoteAssetOracleClient();
    }
    if ((0, types_1.isVariant)(oracleSource, 'switchboardOnDemand')) {
        return new switchboardOnDemandClient_1.SwitchboardOnDemandClient(connection);
    }
    if ((0, types_1.isVariant)(oracleSource, 'pythLazer')) {
        return new pythLazerClient_1.PythLazerClient(connection);
    }
    throw new Error(`Unknown oracle source ${oracleSource}`);
}
exports.getOracleClient = getOracleClient;
