'use client';

import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import captureException from 'src/utils/captureException';
import useTargetAccountData from './useTargetAccountData';
import useUserAccountIsReady from './useUserAccountIsReady';

const useWithdrawableBankBalance = (
	spotMarket: SpotMarketConfig,
	userKey = '',
	reduceOnly = true
) => {
	const userAccount = useTargetAccountData(userKey)?.client;
	const bankPrecision = spotMarket.precisionExp;

	const SAFE_DEFAULT = BigNum.zero(bankPrecision);

	const userAccountIsReady = useUserAccountIsReady(userKey);

	try {
		if (!userAccountIsReady || userKey === '' || !userAccount) {
			return SAFE_DEFAULT;
		}

		return BigNum.from(
			userAccount.getWithdrawalLimit(spotMarket.marketIndex, reduceOnly),
			bankPrecision
		);
	} catch (e) {
		captureException(e);
		return SAFE_DEFAULT;
	}
};

export default useWithdrawableBankBalance;
