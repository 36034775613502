'use client';

import { memo } from 'react';
import Env from 'src/environmentVariables/EnvironmentVariables';
import Button from '../Button';
import Text from '../Text/Text';

function AcknowledgeTermsPanel({
	onAccept,
	onReject,
}: {
	onAccept: () => void;
	onReject: () => void;
}) {
	return (
		<div className="flex flex-col w-full h-full space-y-5">
			<Text.BODY2 className="text-text-label">
				By continuing and using this Protocol:
			</Text.BODY2>
			<ul className="pb-6 list-disc flex flex-col gap-5 list-outside text-text-label">
				<li className="ml-4">
					<Text.BODY2 className="font-normal text-text-label">
						You understand and agree to the{' '}
						<a href={Env.mainnetTermsUrl} rel="noreferrer" target="_blank">
							Drift Terms and Conditions
						</a>{' '}
						and{' '}
						<a href={Env.mainnetDisclaimerUrl} rel="noreferrer" target="_blank">
							Drift Protocol Disclaimer
						</a>
					</Text.BODY2>
				</li>
				<li className="ml-4">
					<Text.BODY2 className="font-normal text-text-label">
						You understand leverage may change after entering a position;
					</Text.BODY2>
				</li>
				<li className="ml-4">
					<Text.BODY2 className="font-normal text-text-label">
						You understand the rules and risks associated with settlement of
						P&L, bankruptcies, insurance, socialised losses and use of cross
						collateral as margin;
					</Text.BODY2>
				</li>
				<li className="ml-4">
					<Text.BODY2 className="font-normal text-text-label">
						You understand your account may be partially or entirely liquidated
						if any position breaches margin maintenance requirements.
					</Text.BODY2>
				</li>
			</ul>
			<Button.Secondary
				size="LARGE"
				highlight
				onClick={onAccept}
				className={'w-full'}
				id="agree_terms_button"
			>
				<Text.H3 className="normal-case">Accept and Continue</Text.H3>
			</Button.Secondary>
			<button
				className="text-text-label w-full h-5 p-4 text-center"
				onClick={() => onReject()}
			>
				<Text.H3 className="normal-case">Do not accept</Text.H3>
			</button>
		</div>
	);
}

export default memo(AcknowledgeTermsPanel);
