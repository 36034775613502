'use client';

import { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import PreLaunchMarketingAlert, {
	hasSeenPreLaunchMarketAlertForMarket,
} from '../PreLaunchMarketAlerts/PreLaunchMarketingAlert';
import DriftmasAlert from './DriftmasAlert';
import MarketAlert from './MarketAlert';
import PopupAlert from './PopupAlert';
import { PRELAUNCH_MARKET_RESOURCES } from '../../environmentVariables/EnvironmentVariables';
import CollateralAlert from './CollateralAlert';
import DownloadReadyAlert from './DownloadReadyAlert';

const alertDisplayDelayMs = 2000;

const getPreLaunchMarketAlertToShow = () => {
	return PRELAUNCH_MARKET_RESOURCES.find((market) => {
		return (
			Date.now() > market.startTs &&
			Date.now() < market.endTs &&
			!hasSeenPreLaunchMarketAlertForMarket(market.marketId)
		);
	});
};

const FeatureAlerts = () => {
	const [readyToDisplay, setReadyToDisplay] = useState(false);
	const alert = useDriftStore((s) => s.driftFeatureAlert);

	const [
		hasSeenPreLaunchMarketAlertOverride,
		setHasSeenPreLaunchMarketAlertOverride,
	] = useState(false);

	const showPreLaunchMarketMarketingAlert =
		!hasSeenPreLaunchMarketAlertOverride && getPreLaunchMarketAlertToShow();

	// Only show current alerts 1 second after the app has loaded, otherwise it's a little jarring
	useEffect(() => {
		setTimeout(() => {
			setReadyToDisplay(true);
		}, alertDisplayDelayMs);
	}, []);

	// HARDCODED PRE-LAUNCH-MARKET ALERT HANDLING
	if (showPreLaunchMarketMarketingAlert) {
		return (
			<PreLaunchMarketingAlert
				marketId={showPreLaunchMarketMarketingAlert.marketId}
				onContinue={() => {
					setHasSeenPreLaunchMarketAlertOverride(true);
				}}
			/>
		);
	}

	// REGULAR ALERT HANDLING
	if (!readyToDisplay) return null;

	if (!alert?.alertType) return null;

	// @ts-ignore
	if (alert?.alertData?.untilMs && alert.alertData.untilMs < Date.now()) {
		return null;
	}

	switch (alert.alertType) {
		case 'feature':
			if (!alert?.alertData?.heading || !alert?.alertData?.description) {
				return null;
			} else {
				return (
					<PopupAlert
						heading={alert.alertData?.heading}
						featureKey={`${(alert.alertData?.heading ?? '').replace(
							/[^A-Za-z]/g,
							''
						)}-${alert.alertData?.untilMs ?? 0}`}
					>
						<>
							<span>{alert?.alertData?.description}</span>
							{alert?.alertData?.linkText && alert?.alertData?.linkUrl && (
								<span>
									<a
										href={alert.alertData.linkUrl}
										target={alert.alertData.linkNewTab ? '_blank' : undefined}
										rel={alert.alertData.linkNewTab ? 'noreferrer' : undefined}
									>
										{' '}
										{alert.alertData.linkText}
									</a>
								</span>
							)}
						</>
					</PopupAlert>
				);
			}
		case 'warning':
			if (!alert?.alertData?.heading || !alert?.alertData?.description) {
				return null;
			} else {
				// if warning, show once a day
				const dateStr = new Date().toLocaleDateString();
				return (
					<PopupAlert
						alertType={alert.alertType}
						heading={alert.alertData?.heading}
						featureKey={`${alert.alertData?.heading.replace(
							/[^A-Za-z]/g,
							''
						)}-${dateStr}`}
					>
						<div className="flex flex-col space-y-2">
							<span>{alert?.alertData?.description}</span>
							{alert?.alertData?.linkText && alert?.alertData?.linkUrl && (
								<span>
									<a
										href={alert.alertData.linkUrl}
										target={'_blank'}
										rel={'noreferrer'}
									>
										{' '}
										{alert.alertData.linkText}
									</a>
								</span>
							)}
						</div>
					</PopupAlert>
				);
			}
		case 'market':
			if (!alert?.alertData?.baseSymbol) return null;

			return <MarketAlert marketBaseAssetSymbol={alert.alertData.baseSymbol} />;
		case 'collateral':
			if (!alert?.alertData?.baseSymbol) return null;

			return (
				<CollateralAlert marketBaseAssetSymbol={alert.alertData.baseSymbol} />
			);
		case 'driftmas':
			return <DriftmasAlert />;
		case 'downloadReady':
			return <DownloadReadyAlert />;
		default:
			return null;
	}
};

export default FeatureAlerts;
