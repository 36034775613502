'use client';

// import useIsLiteMode from 'src/hooks/useIsLiteMode';
import GradientCheck from '../Icons/GradientCheck';
import useLayoutConfigSetting from 'src/hooks/useLayoutConfigSetting';

const ThemeOptionButton = ({
	// label,
	value,
	selected,
	onClick,
}: {
	// label: string;
	value: string;
	selected: boolean;
	onClick: () => void;
}) => {
	const [layoutSelection] = useLayoutConfigSetting();

	return (
		<div
			className={`relative flex flex-col items-center hover:cursor-pointer hover:text-text-emphasis ${
				selected ? 'text-text-emphasis' : 'text-text-secondary'
			}`}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
		>
			<div>
				<img
					src={`/assets/icons/buttonIcons/layout/${
						layoutSelection || 'default'
					}-${value}-theme.svg`}
				/>
			</div>
			{/* <div className="pt-2">{label}</div> */}

			{selected && (
				<div className="absolute -top-2 -right-3">
					<GradientCheck />
				</div>
			)}
		</div>
	);
};

export default ThemeOptionButton;
