'use client';

import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import Button from './Button';
import { AmountSelector, CollateralInput } from './Inputs/CollateralInput';
import useDriftActions from 'src/hooks/useDriftActions';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BigNum, calculateMaxRemainingDeposit, ZERO } from '@drift-labs/sdk';
import useAccountData from 'src/hooks/useAccountData';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import { MIN_LEFTOVER_SOL } from 'src/constants/constants';
import Text from './Text/Text';
import UI_UTILS from 'src/utils/uiUtils';

export default function CloseBorrowDepositForm({
	spotMarketIndex,
	onClose,
}: {
	spotMarketIndex: number;
	onClose: () => void;
}) {
	const selectedCollatBank = OrderedSpotMarkets[spotMarketIndex];
	const actions = useDriftActions();
	const currentAccount = useAccountData();
	const currentAccountKey = currentAccount?.userKey;
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftClient();
	const targetAccountBankBalance = useAccountTargetSpotBalance(
		selectedCollatBank,
		currentAccountKey
	);
	const [depositableBalanceBigNum] = useTransferableWalletCollateralBalance(
		selectedCollatBank,
		undefined
	);
	const [currentCollatBalanceBigNum] =
		useCurrentWalletCollateralBalance(selectedCollatBank);

	const [collatTransferAmount, setCollatTransferAmount] = useState('');
	const [isMaxRepay, setIsMaxRepay] = useState(false);

	const hasUserUpdatedInput = useRef(false);

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(selectedCollatBank.marketIndex)) ||
		undefined;

	const remainingDepositsAllowed = useMemo(
		() =>
			BigNum.from(
				calculateMaxRemainingDeposit(spotMarketAccount),
				selectedCollatBank.precisionExp
			),
		[selectedCollatBank.precisionExp, spotMarketAccount]
	);

	const showDepositCap =
		spotMarketAccount.maxTokenDeposits.gt(ZERO) && // When maxDeposits = 0 then there is an infinite deposit cap on the market. Therefore don't show it.
		depositableBalanceBigNum.gt(remainingDepositsAllowed);

	const effectiveDepositableBalanceBigNum = showDepositCap
		? remainingDepositsAllowed
		: depositableBalanceBigNum;

	const bufferedCloseBorrowAmount = UI_UTILS.getCloseBorrowAmountWithBuffer(
		driftClient,
		spotMarketAccount.marketIndex,
		targetAccountBankBalance?.netBaseBalance ?? BigNum.zero()
	);

	const insufficientFunds =
		+collatTransferAmount > effectiveDepositableBalanceBigNum.toNum();

	const isButtonDisabled =
		+collatTransferAmount === 0 ||
		+collatTransferAmount > effectiveDepositableBalanceBigNum.toNum();

	useEffect(() => {
		if (
			!hasUserUpdatedInput.current &&
			!targetAccountBankBalance.netBaseBalance.eqZero()
		) {
			handleMaxRepay();
		}
	}, [bufferedCloseBorrowAmount.print()]);

	const handleDeposit = async () => {
		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedCollatBank.precisionExp
		);

		await actions.depositCollateralForExistingAccount(
			collateralAmount,
			selectedCollatBank,
			currentAccountKey,
			isMaxRepay
		);

		onClose();
	};

	const handleValueChange = (newValue: string) => {
		setIsMaxRepay(false);
		setCollatTransferAmount(newValue);
		hasUserUpdatedInput.current = true;
	};

	const handleMaxRepay = () => {
		setCollatTransferAmount(bufferedCloseBorrowAmount.print());
		setIsMaxRepay(true);
		hasUserUpdatedInput.current = false;
	};

	return (
		<div className="flex flex-col w-full">
			<CollateralInput
				selectedMarket={selectedCollatBank}
				maxAmount={effectiveDepositableBalanceBigNum}
				currentBalance={currentCollatBalanceBigNum}
				onChangeMarket={() => {}}
				label="Assets borrowed and Amount"
				value={collatTransferAmount}
				onChangeValue={handleValueChange}
				amountLabel={'Wallet balance'}
				banks={[selectedCollatBank]}
				source={{
					type: 'userAccount',
				}}
			/>

			<AmountSelector
				symbol={selectedCollatBank.symbol}
				maxAmount={bufferedCloseBorrowAmount}
				label="Assets borrowed and Amount"
				onChangeValue={handleValueChange}
				amountLabel="Borrows"
				isNegative
				onMax={handleMaxRepay}
			/>

			<Text.BODY3 className="mt-1 text-text-label">
				We hold back {MIN_LEFTOVER_SOL.toFixed(2)} SOL on max deposits to cover
				transaction fees
			</Text.BODY3>

			<Button.Primary
				size="LARGE"
				className="w-full mt-10"
				onClick={handleDeposit}
				disabled={isButtonDisabled}
			>
				{insufficientFunds ? 'Insufficient Wallet Balance' : 'Confirm Deposit'}
			</Button.Primary>
		</div>
	);
}
