import {
	sepolia,
	mainnet,
	avalancheFuji,
	avalanche,
	arbitrum,
	arbitrumSepolia,
	baseSepolia,
	base,
} from '@wagmi/core/chains';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { Chain } from 'wagmi';
import { JSX } from 'react';
import { MARKET_ICON_PATH } from './constants';

/*
 * IMPORTANT: Chain ID != Domain
 * Chain ID refers to the evm chain id, whereas Domain refers to the CCTP domain id.
 *
 * Configurations that needs to be updated when a new chain is added:
 * - domain
 * - DomainUiConfig
 * - ALL_DOMAINS
 * - SUPPORTED_SOURCE_DOMAINS
 * - SUPPORTED_DESTINATION_DOMAINS
 */

const isDevnet = Env.sdkEnv !== 'mainnet-beta';

export const MINIMUM_SOL_NEEDED_FOR_CCTP = 0.003;

export const CCTP_ATTESTATION_API_URL = isDevnet
	? `https://iris-api-sandbox.circle.com/attestations`
	: 'https://iris-api.circle.com/attestations';

/*
 * Domains
 */
export const ETHEREUM_DOMAIN = 0;
export const AVALANCHE_DOMAIN = 1;
export const ARBITRUM_DOMAIN = 3;
export const SOLANA_DOMAIN = 5;
export const BASE_DOMAIN = 6;

const IMAGE_DIMENSIONS = {
	width: 16,
	height: 16,
};

/*
 * Domain UI configs
 */
type Subnets<T> = {
	testnet: T;
	mainnet: T;
};

export type DomainUiConfig = {
	value: number;
	label: string;
	icon: JSX.Element;
	explorer: string;
	estimatedTransferDurationSecs: Subnets<number>;
	evmChain?: Subnets<Chain>;
	cctp: {
		usdcAddress: Subnets<string>;
		tokenMessengerAddress: Subnets<string>;
		messageTransmitterAddress: Subnets<string>;
	};
};

const ETHEREUM_DOMAIN_CONFIG: DomainUiConfig = {
	value: ETHEREUM_DOMAIN,
	label: 'Ethereum',
	icon: (
		<img
			src={`${MARKET_ICON_PATH}/eth.svg`}
			alt="Ethereum"
			{...IMAGE_DIMENSIONS}
		/>
	),
	explorer: isDevnet ? 'https://sepolia.etherscan.io' : 'https://etherscan.io',
	estimatedTransferDurationSecs: {
		testnet: 60,
		mainnet: 13 * 60,
	},
	evmChain: {
		testnet: sepolia,
		mainnet: mainnet,
	},
	cctp: {
		usdcAddress: {
			testnet: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
			mainnet: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
		},
		tokenMessengerAddress: {
			testnet: '0x9f3B8679c73C2Fef8b59B4f3444d4e156fb70AA5',
			mainnet: '0xbd3fa81b58ba92a82136038b25adec7066af3155',
		},
		messageTransmitterAddress: {
			testnet: '0x7865fAfC2db2093669d92c0F33AeEF291086BEFD',
			mainnet: '0x0a992d191deec32afe36203ad87d7d289a738f81',
		},
	},
};

// not supporting Avalanche for now due to low flow
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AVALANCHE_DOMAIN_CONFIG: DomainUiConfig = {
	value: AVALANCHE_DOMAIN,
	label: 'Avalanche',
	icon: (
		<img
			src={`${MARKET_ICON_PATH}/avax.svg`}
			alt="Avalanche"
			{...IMAGE_DIMENSIONS}
		/>
	),
	explorer: isDevnet ? 'https://testnet.snowtrace.io' : 'https://snowtrace.io',
	estimatedTransferDurationSecs: {
		testnet: 20,
		mainnet: 20,
	},
	evmChain: {
		testnet: avalancheFuji,
		mainnet: avalanche,
	},
	cctp: {
		usdcAddress: {
			testnet: '0x5425890298aed601595a70ab815c96711a31bc65',
			mainnet: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
		},
		tokenMessengerAddress: {
			testnet: '0xeb08f243e5d3fcff26a9e38ae5520a669f4019d0',
			mainnet: '0x6b25532e1060ce10cc3b0a99e5683b91bfde6982',
		},
		messageTransmitterAddress: {
			testnet: '0xa9fb1b3009dcb79e2fe346c16a604b8fa8ae0a79',
			mainnet: '0x8186359af5f57fbb40c6b14a588d2a59c0c29880',
		},
	},
};

const ARBITRUM_DOMAIN_CONFIG: DomainUiConfig = {
	value: ARBITRUM_DOMAIN,
	label: 'Arbitrum',
	icon: (
		<img
			src={`${MARKET_ICON_PATH}/arb.svg`}
			alt="Arbitrum"
			{...IMAGE_DIMENSIONS}
		/>
	),
	explorer: isDevnet ? 'https://sepolia.arbiscan.io' : 'https://arbiscan.io',
	estimatedTransferDurationSecs: {
		testnet: 20,
		mainnet: 13 * 60,
	},
	evmChain: {
		testnet: arbitrumSepolia,
		mainnet: arbitrum,
	},
	cctp: {
		usdcAddress: {
			testnet: '0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d',
			mainnet: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
		},
		tokenMessengerAddress: {
			testnet: '0x9f3B8679c73C2Fef8b59B4f3444d4e156fb70AA5',
			mainnet: '0x19330d10D9Cc8751218eaf51E8885D058642E08A',
		},
		messageTransmitterAddress: {
			testnet: '0xaCF1ceeF35caAc005e15888dDb8A3515C41B4872',
			mainnet: '0xC30362313FBBA5cf9163F0bb16a0e01f01A896ca',
		},
	},
};

const BASE_DOMAIN_CONFIG: DomainUiConfig = {
	value: BASE_DOMAIN,
	label: 'Base',
	icon: (
		<img
			src={`${MARKET_ICON_PATH}/base.svg`}
			alt="Base"
			{...IMAGE_DIMENSIONS}
		/>
	),
	explorer: isDevnet ? 'https://sepolia.basescan.org' : 'https://basescan.org',
	estimatedTransferDurationSecs: {
		testnet: 20,
		mainnet: 13 * 60,
	},
	evmChain: {
		testnet: baseSepolia,
		mainnet: base,
	},
	cctp: {
		usdcAddress: {
			testnet: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
			mainnet: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
		},
		tokenMessengerAddress: {
			testnet: '0x9f3B8679c73C2Fef8b59B4f3444d4e156fb70AA5',
			mainnet: '0x1682Ae6375C4E4A97e4B583BC394c861A46D8962',
		},
		messageTransmitterAddress: {
			testnet: '0x7865fAfC2db2093669d92c0F33AeEF291086BEFD',
			mainnet: '0xAD09780d193884d503182aD4588450C416D6F9D4',
		},
	},
};

const SOLANA_DOMAIN_CONFIG: DomainUiConfig = {
	value: SOLANA_DOMAIN,
	label: 'Solana',
	icon: (
		<img
			src={`${MARKET_ICON_PATH}/sol.svg`}
			alt="Solana"
			{...IMAGE_DIMENSIONS}
		/>
	),
	explorer: 'https://explorer.solana.com',
	estimatedTransferDurationSecs: {
		testnet: 5,
		mainnet: 5,
	},
	cctp: {
		usdcAddress: {
			testnet: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
			mainnet: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
		},
		tokenMessengerAddress: {
			testnet: 'CCTPiPYPc6AsJuwueEnWgSgucamXDZwBd53dQ11YiKX3',
			mainnet: 'CCTPiPYPc6AsJuwueEnWgSgucamXDZwBd53dQ11YiKX3',
		},
		messageTransmitterAddress: {
			testnet: 'CCTPmbSD7gX1bxKPAmg77w8oFzNFpaQiQUWD43TKaecd',
			mainnet: 'CCTPmbSD7gX1bxKPAmg77w8oFzNFpaQiQUWD43TKaecd',
		},
	},
};

/** Array of all domains */
export const ALL_DOMAINS = [
	ETHEREUM_DOMAIN_CONFIG,
	// AVALANCHE_DOMAIN_CONFIG, // not supporting because not much flow from Avalanche to Solana
	ARBITRUM_DOMAIN_CONFIG,
	BASE_DOMAIN_CONFIG,
	SOLANA_DOMAIN_CONFIG,
];

/**
 * Array of supported source domains.
 */
export const SUPPORTED_SOURCE_DOMAINS = [
	ETHEREUM_DOMAIN_CONFIG,
	// AVALANCHE_DOMAIN_CONFIG,
	ARBITRUM_DOMAIN_CONFIG,
	BASE_DOMAIN_CONFIG,
];

/**
 * Array of supported destination domains.
 */
export const SUPPORTED_DESTINATION_DOMAINS = [SOLANA_DOMAIN_CONFIG];

/**
 * Map of supported chains to CCTP domain IDs
 */
export const CHAIN_IDS_TO_CCTP_DOMAIN_IDS: Record<number | 'solana', number> = {
	...ALL_DOMAINS.reduce(
		(acc, domainConfig) => ({
			...acc,
			[domainConfig.evmChain?.testnet.id ?? 0]: domainConfig.value,
			[domainConfig.evmChain?.mainnet.id ?? 0]: domainConfig.value,
		}),
		{}
	),
	solana: SOLANA_DOMAIN,
};

// Used by evm wallet connector provider
export const CCTP_SUPPORTED_CHAINS: Chain[] = ALL_DOMAINS.filter(
	(domainConfig) => domainConfig.value !== SOLANA_DOMAIN
).map((domainConfig) =>
	isDevnet ? domainConfig.evmChain.testnet : domainConfig.evmChain.mainnet
);

/**
 * Map of supported chains to USDC contract addresses
 */
export const DOMAIN_TO_USDC_ADDRESSES: Record<
	number,
	Subnets<string>
> = ALL_DOMAINS.reduce(
	(acc, domainConfig) => ({
		...acc,
		[domainConfig.value]: domainConfig.cctp.usdcAddress,
	}),
	{}
);

/**
 * Map of supported chains to Token Messenger contract addresses
 */
export const CHAIN_IDS_TO_TOKEN_MESSENGER_ADDRESSES: Record<
	number,
	Subnets<string>
> = ALL_DOMAINS.reduce(
	(acc, domainConfig) => ({
		...acc,
		[domainConfig.value]: domainConfig.cctp.tokenMessengerAddress,
	}),
	{}
);

/**
 * Map of supported chains to Message Transmitter contract addresses
 */
export const CHAIN_IDS_TO_MESSAGE_TRANSMITTER_ADDRESSES: Record<
	number,
	Subnets<string>
> = ALL_DOMAINS.reduce(
	(acc, domainConfig) => ({
		...acc,
		[domainConfig.value]: domainConfig.cctp.messageTransmitterAddress,
	}),
	{}
);
