import { PublicKey } from '@solana/web3.js';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { NonTradeRoutes, PageRoute, PageRoutes } from '../constants/constants';

export const AUTHORITY_OVERRIDE = '';
export const USER_OVERRIDE = '';

export const getIsInEmulationMode = (
	searchParams: ReadonlyURLSearchParams | null
): boolean => {
	if (!searchParams) return false;

	return !!(
		AUTHORITY_OVERRIDE ||
		USER_OVERRIDE ||
		searchParams.get('authority') ||
		searchParams.get('userAccount')
	);
};

export const getEmulationParams = (
	searchParams: ReadonlyURLSearchParams | null
) => {
	if (!searchParams) return null;

	const authorityParam = AUTHORITY_OVERRIDE || searchParams.get('authority');
	const userAccountParam = USER_OVERRIDE || searchParams.get('userAccount');

	if (authorityParam) {
		return {
			type: 'authority' as const,
			value: authorityParam,
		};
	}

	if (userAccountParam) {
		return {
			type: 'userAccount' as const,
			value: userAccountParam,
		};
	}

	return null;
};

export const isValidPublicKey = (key: string): boolean => {
	try {
		new PublicKey(key);
		return true;
	} catch {
		return false;
	}
};

export const isReferralRoute = (pathname: string): boolean => {
	const referralMatch = pathname.match(/ref\/(.+)($|\/)/);
	return !!referralMatch?.[1];
};

export const getReferralCode = (pathname: string): string | null => {
	const referralMatch = pathname.match(/ref\/(.+)($|\/)/);
	return referralMatch?.[1] || null;
};

export const getCurrentPageRoute = (
	pathname: string
): PageRoute | undefined => {
	return PageRoutes.find((pageRoute) => pathname.includes(pageRoute));
};

export const isNonTradePage = (pathname: string): boolean => {
	return !!NonTradeRoutes.find((nonTradeRoute) =>
		pathname.includes(nonTradeRoute)
	);
};

/**
 * Gets URL search params without requiring the React hook.
 * Can be used in non-React contexts or server-side code.
 * Returns null if window is not available (SSR).
 */
export const getSearchParams = (): ReadonlyURLSearchParams | null => {
	if (typeof window === 'undefined') return null;

	return new URLSearchParams(
		window.location.search
	) as unknown as ReadonlyURLSearchParams;
};
