import { CoinbaseWalletName } from '@solana/wallet-adapter-wallets';
import {
	BASE_NAVIGATION_OPTIONS,
	RouteNavigationResult,
} from 'src/components/Navigation';
import { MAGIC_EDEN_WALLET_NAME } from 'src/constants/wallets';

export type PosthogEvent =
	| 'submitted_borrow'
	| 'opened_deposit_modal'
	| 'submitted_deposit'
	| 'prelaunch_market_banner_seen'
	| 'prelaunch_market_banner_visited'
	| 'changed_trade_mode_with_switch'
	| 'priority_fee_footer_menu_opened'
	| 'rpc_footer_menu_opened'
	| 'opened_stake_modal'
	| 'submitted_add_if_stake'
	| 'canceled_unstake_request'
	| 'withdrew_unstaked_assets'
	| 'closing_position'
	| 'opening_new_position'
	| 'submitted_unstake_request'
	| 'submitted_transfer'
	| 'submitted_withdrawal'
	| 'opened_add_lp_modal'
	| 'submitted_add_lp'
	| 'submitted_remove_lp'
	| 'initiated_mayan_swap'
	| 'creating_new_lp_subaccount'
	| 'creating_new_subaccount'
	| 'settings_menu_opened_mobile'
	| 'settings_menu_opened_desktop'
	| 'new_user_trade_mode_modal_exit'
	| 'new_user_trade_mode_modal_choice'
	| 'tutorial_completed'
	| 'tutorial_skipped'
	| 'changed_display_settings'
	| 'selected_bridge'
	| 'onboarding_skip_deposit'
	| 'magic_auth_login'
	| 'clicked_mayan_swap_docs_link'
	| 'clicked_jupiter_swap_link'
	| 'clicked_portal_redeem_link'
	| 'clicked_done_bridging_button'
	| 'bridge_USDCet_balance_increased'
	| 'bridge_USDCet_balance_decreased'
	| 'crossed_orderbook'
	| 'closing_position_from_popup'
	| 'changed_show_markets_bar_setting'
	| 'trader_profile_changed'
	| 'selected_metamask_wallet'
	| 'selected_wallet'
	| '$pageview'
	| 'cctp_receive_message_success'
	| 'cctp_deposit_for_burn_init'
	| 'wallet_connected'
	| 'opened_remove_lp_modal'
	| 'unmatched_error'
	| 'market_order_errors'
	| 'clicked_drift_earn_if_stake'
	| 'clicked_drift_earn_sss'
	| 'clicked_drift_earn_mm_vaults'
	| 'settle_pnl_error'
	| 'elections_swap'
	| 'deposit_to_trade_wallet_connected'
	| 'deposit_to_trade_submit'
	| 'deposit_to_trade_submit_success'
	| 'deposit_to_trade_submit_error'
	| 'tracked_lite_pro_mode';

export type PosthogConfig = {
	/**
	 * Based on the sampling rate and the overall enabled flag.
	 */
	enabled: boolean;
	consoleRecordingEnabled: boolean;
	sessionRecordingEnabled: boolean;
	capturePageViewEnabled: boolean;
	overrideEnableAllEvents: boolean;
	/**
	 * Events that are enabled based on the hardcoded config `ENABLED_EVENTS_OVERRIDE` below. This is sampling independent.
	 */
	samplingIndependentEnabledEvents: Set<PosthogEvent>;
	/**
	 * Page paths that should be captured as pageviews
	 */
	pageViewWhitelist: string[];
	wallet: {
		/**
		 * Wallet adapter names that should have all whitelisted events captured.
		 */
		walletAdapterNames: Set<string>;
		/**
		 * Events that should be captured, on top of the sampling independent events.
		 */
		eventsToTrack: Set<PosthogEvent>;
	};
};

const sampleRatio = parseFloat(
	process.env.NEXT_PUBLIC_POSTHOG_ENABLED_SAMPLE_RATIO_PCT
);

const samplingCheck = isNaN(sampleRatio)
	? true // if NaN, we default to true because the "overall" enabled flag is in control
	: Math.random() * 100 < sampleRatio;

const posthogEnabled = process.env.NEXT_PUBLIC_POSTHOG_ENABLED === 'true';

const SAMPLING_INDEPENDENT_ENABLED_EVENTS: PosthogEvent[] = [
	'crossed_orderbook',
	'unmatched_error',
	'market_order_errors',
	'clicked_drift_earn_sss',
	'clicked_drift_earn_mm_vaults',
	'opened_deposit_modal',
	'submitted_deposit',
	'clicked_drift_earn_if_stake',
	'deposit_to_trade_wallet_connected',
	'deposit_to_trade_submit',
	'deposit_to_trade_submit_success',
	'deposit_to_trade_submit_error',
	'selected_wallet',
	'settle_pnl_error',
	'tracked_lite_pro_mode',
];

const PAGE_VIEW_WHITELIST: string[] = [
	(BASE_NAVIGATION_OPTIONS.driftEarn.handler() as RouteNavigationResult).route,
	(BASE_NAVIGATION_OPTIONS.bet.handler() as RouteNavigationResult).route,
	(BASE_NAVIGATION_OPTIONS.stats.handler() as RouteNavigationResult).route,
	'/SOL-PERP',
];

const WALLET_ADAPTER_NAMES_TO_ALLOW_TRACKING: string[] = [
	CoinbaseWalletName,
	MAGIC_EDEN_WALLET_NAME,
];
export const WALLET_EVENTS_TO_TRACK: PosthogEvent[] = ['opening_new_position'];

export const POSTHOG_CONFIG: PosthogConfig = {
	enabled: samplingCheck && posthogEnabled,
	consoleRecordingEnabled:
		process.env.NEXT_PUBLIC_POSTHOG_CONSOLE_RECORDING_ENABLED === 'true',
	sessionRecordingEnabled:
		process.env.NEXT_PUBLIC_POSTHOG_SESSION_RECORDING_ENABLED === 'true',
	capturePageViewEnabled:
		process.env.NEXT_PUBLIC_POSTHOG_CAPTURE_PAGE_VIEW_ENABLED === 'true',
	overrideEnableAllEvents:
		process.env.NEXT_PUBLIC_POSTHOG_OVERRIDE_ENABLE_ALL_EVENTS === 'true',
	samplingIndependentEnabledEvents: new Set<PosthogEvent>(
		SAMPLING_INDEPENDENT_ENABLED_EVENTS
	),
	pageViewWhitelist: PAGE_VIEW_WHITELIST,
	wallet: {
		walletAdapterNames: new Set(WALLET_ADAPTER_NAMES_TO_ALLOW_TRACKING),
		eventsToTrack: new Set(WALLET_EVENTS_TO_TRACK),
	},
};
