import { useState } from 'react';
import { UiVaultConfig } from 'src/@types/vaults';
import Button from 'src/components/Button';
import { Typo } from 'src/components/Text/Typo';
import { twMerge } from 'tailwind-merge';
import { COMMON_UI_UTILS, UIMarket } from '@drift/common';
import {
	BigNum,
	PERCENTAGE_PRECISION,
	PERCENTAGE_PRECISION_EXP,
	SpotMarketConfig,
	BN,
	ZERO,
} from '@drift-labs/sdk';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Utility from 'src/components/Inputs/Utility';
import useDriftActions from 'src/hooks/useDriftActions';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import { Vault, VaultDepositor } from '@drift-labs/vaults-sdk';
import ValueDisplay from 'src/components/ValueDisplay';
import MarketIcon from 'src/components/Utils/MarketIcon';
import {
	getWithdrawalState,
	redeemPeriodToString,
	WithdrawalState,
} from 'src/utils/vault/withdraw';
import { Alert, AlertType } from 'src/components/Alert';
import dayjs from 'dayjs';
import { VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY } from 'src/constants/vaults/misc';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { getVaultDepositorBalance } from 'src/utils/vault/math';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import SecondaryConnectWalletButton from 'src/components/SecondaryConnectWalletButton';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { CollateralInputV2 } from 'src/components/Inputs/CollateralInput';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';

type VaultDepositWithdrawFormProps = {
	uiVaultConfig: UiVaultConfig;
	// need to pass in the following as props instead of using the VaultContext because this component is
	// potentially being used in a modal, which is outside of the Context provider
	vaultDepositorAccountData: VaultDepositor | undefined;
	isVaultDepositorLoaded: boolean;
	vaultAccountData: Vault | undefined;
};

export const VaultDepositWithdrawForm = (
	props: VaultDepositWithdrawFormProps
) => {
	const depositAssetConfig = UIMarket.createSpotMarket(
		props.uiVaultConfig.depositAsset
	).market as SpotMarketConfig;

	const setDriftStore = useDriftStore((s) => s.set);
	const actions = useDriftActions();
	const vaultStats = useVaultsStore((s) =>
		s.getVaultStats(props.uiVaultConfig.vaultPubkeyString)
	);
	const vaultClient = useVaultsStore((s) => s.vaultClient);
	const isWalletConnected = useWalletIsConnected();
	const syncVaultStats = useVaultsStore((s) => s.syncCurrentPageVaultStats);

	const [formType, setFormType] = useState<'deposit' | 'withdraw'>('deposit');
	const [depositAssetWalletBalance, isLoading] =
		useTransferableWalletCollateralBalance(depositAssetConfig);

	const hadSelectedDoNotShowAgain = useDriftStore(
		(s) => s[VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY]
	);

	const [inputAmount, setInputAmount] = useState('');

	const isDeposit = formType === 'deposit';
	const redemptionPeriod = props.vaultAccountData?.redeemPeriod;

	const currentUserVaultBaseBalanceAfterFees = getCurrentUserVaultBalance();
	const afterInputAmountUserVaultBalance =
		getAfterInputAmountUserVaultBalance();
	const maxAmount = getMaxAmount();
	const { withdrawalState } = getWithdrawalState(
		props.vaultDepositorAccountData,
		props.vaultAccountData
	);

	const isVaultDepositorWhitelisted = getIsVaultDepositorWhitelisted();
	const isInputAmountLessThanMinDepositAmount =
		getIsInputAmountLessThanMinDepositAmount();
	const isCtaDisabled = getIsCtaDisabled();
	const withdrawalCtaState = getWithdrawalFormState();
	const withdrawalBaseAmount = getWithdrawalBaseAmount();
	const tsUntilWithdrawal = getTsUntilWithdrawal();
	const alertDetails = getAlertDetails();

	function getIsInputAmountLessThanMinDepositAmount() {
		return (
			props.vaultAccountData?.minDepositAmount.gtn(0) &&
			!isNaN(+inputAmount) &&
			+inputAmount > 0 &&
			BigNum.fromPrint(inputAmount, depositAssetConfig.precisionExp).val.lt(
				props.vaultAccountData.minDepositAmount
			)
		);
	}

	function getIsVaultDepositorWhitelisted() {
		if (!props.vaultAccountData?.permissioned) return true;

		if (props.isVaultDepositorLoaded && !props.vaultDepositorAccountData) {
			return false;
		}

		return true;
	}

	function getCurrentUserVaultBalance() {
		if (
			!props.vaultAccountData ||
			!props.vaultDepositorAccountData ||
			!vaultStats
		) {
			return BigNum.from(0, depositAssetConfig.precisionExp);
		}

		return getVaultDepositorBalance(
			props.vaultDepositorAccountData,
			props.vaultAccountData,
			vaultStats.tvlBase,
			depositAssetConfig.precisionExp,
			true
		);
	}

	function getMaxAmount() {
		if (isDeposit) {
			if (!props.vaultAccountData || !vaultStats)
				return BigNum.from(0, depositAssetConfig.precisionExp);

			// 99.99% of the capacity to allow for tvlBase fluctuations - for UX improvements where user can fail regularly when attempting to deposit the exact leftover amount
			const bufferedCapacity = props.vaultAccountData.maxTokens
				.muln(9999)
				.divn(10000);

			const capacityLeftover = BN.max(
				bufferedCapacity.sub(vaultStats.tvlBase.val),
				ZERO
			);

			const maxAmount = BN.min(capacityLeftover, depositAssetWalletBalance.val);
			const safeMaxAmount = BN.max(maxAmount, ZERO);

			return BigNum.from(safeMaxAmount, depositAssetConfig.precisionExp);
		} else {
			return currentUserVaultBaseBalanceAfterFees;
		}
	}

	function getAfterInputAmountUserVaultBalance() {
		const inputAmountBigNum = BigNum.fromPrint(
			inputAmount,
			depositAssetConfig.precisionExp
		);

		if (isDeposit) {
			return currentUserVaultBaseBalanceAfterFees.add(inputAmountBigNum);
		} else {
			return currentUserVaultBaseBalanceAfterFees.sub(inputAmountBigNum);
		}
	}

	function getWithdrawalFormState() {
		if (withdrawalState === WithdrawalState.UnRequested) {
			return {
				text: 'Request Withdrawal',
				className: 'bg-negative-red',
				displayInput: true,
			};
		} else if (withdrawalState === WithdrawalState.Requested) {
			return {
				text: 'Cancel Request',
				className: 'bg-button-secondary-bg text-text-default',
				displayInput: false,
			};
		} else {
			return {
				text: 'Confirm Withdrawal',
				className: 'bg-negative-red',
				displayInput: false,
			};
		}
	}

	/**
	 * The amount of base value that can be withdrawn is the smaller of the current base value of the request
	 * or the base value of the withdrawal at the time of the request.
	 */
	function getWithdrawalBaseAmount() {
		const zeroBaseBigNum = BigNum.from(0, depositAssetConfig.precisionExp);

		if (withdrawalState === WithdrawalState.UnRequested) {
			return zeroBaseBigNum;
		} else {
			if (
				!props.vaultAccountData ||
				!vaultStats ||
				!props.vaultDepositorAccountData
			) {
				return zeroBaseBigNum;
			}

			const totalShares = props.vaultAccountData.totalShares;
			const currentBaseValueOfRequest =
				props.vaultDepositorAccountData.lastWithdrawRequest.shares
					.mul(vaultStats.tvlBase.val)
					.div(totalShares);

			const baseValueOfWithdrawalAtRequestTime = new BN(
				props.vaultDepositorAccountData.lastWithdrawRequest.value
			);

			const smallerValue = BN.min(
				baseValueOfWithdrawalAtRequestTime,
				currentBaseValueOfRequest
			);

			return BigNum.from(smallerValue, depositAssetConfig.precisionExp);
		}
	}

	function getTsUntilWithdrawal() {
		const withdrawalAvailableTs =
			(props.vaultDepositorAccountData?.lastWithdrawRequest.ts.toNumber() ??
				0) +
			(props.vaultAccountData?.redeemPeriod.toNumber() ?? 0) -
			dayjs().unix();
		return withdrawalAvailableTs;
	}

	function getIsCtaDisabled() {
		if (!props.isVaultDepositorLoaded) return true;

		if (!isVaultDepositorWhitelisted) return true;

		if (
			withdrawalState === WithdrawalState.UnRequested &&
			formType === 'deposit' &&
			isInputAmountLessThanMinDepositAmount
		) {
			return true;
		}

		if (
			withdrawalState === WithdrawalState.Requested ||
			withdrawalState === WithdrawalState.AvailableForWithdrawal
		)
			return false;

		if (+inputAmount === 0) return true;

		return false;
	}

	function getAlertDetails(): {
		type: AlertType;
		message: string;
	} {
		if (!isVaultDepositorWhitelisted && props.isVaultDepositorLoaded) {
			return {
				type: 'error',
				message: 'You are not whitelisted for this vault',
			};
		}

		if (isDeposit) {
			if (withdrawalState !== WithdrawalState.UnRequested) {
				return {
					type: 'warning',
					message: `Deposit are disabled while a withdrawal request is in progress`,
				};
			}

			if (isInputAmountLessThanMinDepositAmount) {
				return {
					type: 'info',
					message: `The minimum deposit amount is ${BigNum.from(
						props.vaultAccountData.minDepositAmount,
						depositAssetConfig.precisionExp
					).prettyPrint()} ${depositAssetConfig.symbol}`,
				};
			}
		} else {
			if (withdrawalState === WithdrawalState.Requested) {
				return {
					type: 'info',
					message: `${withdrawalBaseAmount.prettyPrint()} ${
						depositAssetConfig.symbol
					} is available to withdraw in ${redeemPeriodToString(
						tsUntilWithdrawal
					)}.`,
				};
			} else if (withdrawalState === WithdrawalState.AvailableForWithdrawal) {
				return {
					type: 'info',
					message: `${withdrawalBaseAmount.prettyPrint()} ${
						depositAssetConfig.symbol
					} is available for withdrawal`,
				};
			}
		}

		return null;
	}

	const handleOnValueChange = COMMON_UI_UTILS.formatTokenInputCurried(
		setInputAmount,
		depositAssetConfig
	);

	const handleOnSubmit = async () => {
		if (isCtaDisabled) return;

		const actionCallback = async () => {
			if (isDeposit) {
				await actions.depositVault(
					props.vaultAccountData,
					vaultClient,
					BigNum.fromPrint(inputAmount, depositAssetConfig.precisionExp).val,
					props.vaultDepositorAccountData
				);
			} else {
				if (!props.vaultDepositorAccountData) return;

				if (withdrawalState === WithdrawalState.UnRequested) {
					const inputAmountBN = BigNum.fromPrint(
						inputAmount,
						depositAssetConfig.precisionExp
					);
					const isMaxWithdrawal = inputAmountBN.eq(
						currentUserVaultBaseBalanceAfterFees
					);
					const pctOfSharesToWithdraw = isMaxWithdrawal
						? PERCENTAGE_PRECISION
						: inputAmountBN
								.shift(PERCENTAGE_PRECISION_EXP)
								.div(currentUserVaultBaseBalanceAfterFees).val;

					await actions.requestVaultWithdrawal(
						props.vaultDepositorAccountData.pubkey,
						pctOfSharesToWithdraw,
						vaultClient
					);
				} else if (withdrawalState === WithdrawalState.Requested) {
					await actions.cancelVaultWithdrawalRequest(
						props.vaultDepositorAccountData.pubkey,
						vaultClient
					);
				} else {
					await actions.withdrawFromVault(
						props.vaultDepositorAccountData,
						vaultClient
					);
				}
			}

			setInputAmount('');

			// allow time for action to be processed on the blockchain
			setTimeout(() => {
				syncVaultStats();
			}, 200);
		};

		if (!hadSelectedDoNotShowAgain) {
			setDriftStore((s) => {
				s.modals.showVaultTermsDisclaimerModal = true;
				s.modalsProps.showVaultTermsDisclaimerModal = {
					depositCallback: actionCallback,
				};
			});
			return;
		}

		actionCallback();
	};

	return (
		<div className="flex flex-col w-full p-4 rounded-[3px] bg-container-bg grow sm:grow-0">
			<div className="flex items-center gap-1">
				<Button.Primary
					size="MEDIUM"
					positiveGreen={formType === 'deposit'}
					onClick={() => setFormType('deposit')}
					className={twMerge(
						'flex-1',
						formType !== 'deposit' && 'bg-transparent text-text-secondary'
					)}
				>
					Deposit
				</Button.Primary>
				<Button.Primary
					size="MEDIUM"
					onClick={() => setFormType('withdraw')}
					className={twMerge(
						'flex-1',
						formType === 'withdraw'
							? 'bg-negative-red'
							: 'bg-transparent text-text-secondary'
					)}
				>
					Withdraw
				</Button.Primary>
			</div>

			<div className="flex flex-col gap-4 mt-4">
				<Typo.B5 className="text-text-secondary">
					{isDeposit ? (
						<>
							Deposited funds are subject to a{' '}
							{redeemPeriodToString(redemptionPeriod?.toNumber())} redemption
							period.
						</>
					) : (
						<>
							After the {redeemPeriodToString(redemptionPeriod?.toNumber())}{' '}
							redemption period, your funds can be withdrawn to your wallet.
							<br />
							<br />
							The maximum withdrawal amount is based on share value at request
							time, though the final amount may be lower.
						</>
					)}
				</Typo.B5>

				{(isDeposit || withdrawalCtaState.displayInput) && (
					<div className="flex flex-col gap-2">
						<div
							className="flex items-center justify-between w-full"
							onClick={() => {
								handleOnValueChange(maxAmount.toNum().toString());
							}}
						>
							<Typo.T5 className="text-text-label">Amount</Typo.T5>

							{isWalletConnected && (
								<div className="flex items-center gap-1">
									{!isDeposit && (
										<Tooltip content="The maximum amount is after fees, while the final amount received may differ from the amount requested.">
											<Info className="w-3 h-3" color="var(--text-secondary)" />
										</Tooltip>
									)}
									<Typo.T5 className="flex items-center gap-1 px-1 rounded-sm cursor-pointer bg-button-secondary-bg text-text-secondary">
										<span>Max:</span>
										{isLoading ? (
											<SkeletonValuePlaceholder className="w-10 h-3" loading />
										) : (
											<span>
												{maxAmount.prettyPrint()} {depositAssetConfig.symbol}
											</span>
										)}
									</Typo.T5>
								</div>
							)}
						</div>

						<CollateralInputV2
							maxAmount={maxAmount}
							selectedMarket={depositAssetConfig}
							onChangeValue={handleOnValueChange}
							value={inputAmount}
							label="Amount"
							source={{
								type: 'wallet',
							}}
						/>
					</div>
				)}

				<div className="flex flex-col gap-4 mt-1">
					<Utility.VERTDIVIDER />

					<div className="flex items-center gap-1">
						<ValueDisplay.ValueChange
							label="Balance"
							previousValue={currentUserVaultBaseBalanceAfterFees}
							afterValue={afterInputAmountUserVaultBalance}
							previousValuePrint={`${currentUserVaultBaseBalanceAfterFees.toFixed(
								2
							)}`}
							afterValuePrint={`${afterInputAmountUserVaultBalance.toFixed(2)}`}
						/>
						<MarketIcon
							marketSymbol={depositAssetConfig.symbol}
							className="w-4 h-4"
						/>
					</div>
				</div>
			</div>

			{/** Alerts */}
			<div className="grow h-[100px] w-full mt-2 mb-5 sm:grow-0 flex items-end">
				{alertDetails && (
					<Alert
						type={alertDetails.type}
						message={alertDetails.message}
						className="w-full rounded typo-t5 text-text-default"
					/>
				)}
			</div>

			{isWalletConnected ? (
				<Button.Primary
					size="LARGE"
					className={twMerge(
						'w-full normal-case',
						!isDeposit && withdrawalCtaState.className
					)}
					onClick={handleOnSubmit}
					disabled={isCtaDisabled}
				>
					{isDeposit ? 'Confirm Deposit' : withdrawalCtaState.text}
				</Button.Primary>
			) : (
				<div className="flex items-center justify-center w-full">
					<SecondaryConnectWalletButton />
				</div>
			)}
		</div>
	);
};

export const VaultDepositWithdrawFormWithGradientWrapper = (
	props: VaultDepositWithdrawFormProps
) => {
	return (
		<div className="lg:min-w-[360px] lg:w-[360px] min-w-[308px] w-[308px]">
			<div
				className="p-[1px] rounded w-full"
				style={{ background: 'var(--vaults-bg-gradient)' }}
			>
				<VaultDepositWithdrawForm {...props} />
			</div>
		</div>
	);
};
