'use client';

import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import Button, { ButtonGroup } from 'src/components/Button';
import { CollateralInput } from 'src/components/Inputs/CollateralInput';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import Select from 'src/components/Inputs/Select';
import Utility from 'src/components/Inputs/Utility';
import Text from 'src/components/Text/Text';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import Env, {
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSubaccountCollateralBalanceForBank from 'src/hooks/useSubaccountCollateralBalanceForBank';
import React, { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useSafePush from 'src/hooks/useSafePush';
import { NEW_LP_SUBACCOUNT_NAME } from 'src/constants/constants';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';

type DepositType = 'wallet' | 'account';

const SUGGESTED_INITIAL_MAX_LEV = 2;

const NewLpSubaccountModal = () => {
	const availableSubAccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const collateralTypeIndex = useDriftStore((s) => s.modalCollateralType);
	const { captureEvent } = usePostHogCapture();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const [depositType, setDepositType] = useState<DepositType>('wallet');
	const [depositAccountSelection, setDepositAccountSelection] =
		useState<string>(undefined);
	const [selectedCollatMarket, setSelectedCollatMarket] =
		useState<SpotMarketConfig>(OrderedSpotMarkets[collateralTypeIndex]);
	const [depositAmount, setDepositAmount] = useState<string>('');
	const [submitting, setSubmitting] = useState(false);
	const [availableAccountOptions, setAvailableAccountOptions] = useState([]);
	const [maxLeverageToInit, setMaxLeverageToInit] = useState<
		number | undefined
	>(SUGGESTED_INITIAL_MAX_LEV);

	const [walletBalance] =
		useCurrentWalletCollateralBalance(selectedCollatMarket);
	const subaccountBalance = useSubaccountCollateralBalanceForBank({
		accountKey: depositAccountSelection,
		market: selectedCollatMarket,
		useTokenBalance: true,
	});
	const currentAuthority = useCurrentWalletAdapter()?.publicKey;
	const safePush = useSafePush();

	const {
		totalCost: totalAccountCreationCost,
		loaded: accountCreationCostLoaded,
	} = useAccountCreationCost();
	const isDevMode = useDevSwitchIsOn();

	const currentCollatBalanceBigNum =
		depositType == 'wallet' ? walletBalance : subaccountBalance;

	const depositAmountBigNum = BigNum.fromPrint(
		depositAmount ? depositAmount : '0',
		selectedCollatMarket.precisionExp
	);

	const balanceExceeded = depositAmountBigNum.gt(currentCollatBalanceBigNum);

	const canCreateMoreAccounts =
		isDevMode ||
		Env.maxAccountsPerWallet >
			availableSubAccounts.filter((acct) =>
				acct.authority.equals(currentAuthority)
			).length;
	// Reset transfer amount if market selection changes
	useEffect(() => {
		setDepositAmount('');
	}, [selectedCollatMarket?.symbol]);

	useEffect(() => {
		setDepositAccountSelection(
			depositType == 'account'
				? availableSubAccounts[0]?.userKey ?? undefined
				: undefined
		);
	}, [depositType, availableSubAccounts]);

	useEffect(() => {
		setAvailableAccountOptions(
			availableSubAccounts.map((account) => ({
				value: account.userKey ?? null,
				label: account.name,
			}))
		);
	}, [availableSubAccounts]);

	const handleAddAccount = async () => {
		if (!canCreateMoreAccounts) return;

		setSubmitting(true);

		captureEvent('creating_new_lp_subaccount');

		const fundsSource = depositAccountSelection
			? availableSubAccounts.find(
					(acct) => acct.userKey == depositAccountSelection
			  )?.userId
			: null;

		const success = await actions.initializeAndDepositCollateralForAccount({
			amount: depositAmountBigNum,
			spotMarketConfig: selectedCollatMarket,
			poolId: selectedCollatMarket.poolId,
			name: NEW_LP_SUBACCOUNT_NAME,
			fromId: fundsSource,
			maxLeverage: maxLeverageToInit,
		});

		if (success) {
			hideModal();
			showModal('showAddLiquidityModal', true);
		}

		setSubmitting(false);
	};

	const handleSkip = () => {
		hideModal();
		showModal('showAddLiquidityModal', true);
	};

	const showModal = actions.showModal;
	const hideModal = () => showModal('showNewLpSubaccountModal', false);

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Create a BAL Subaccount</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<div className="flex flex-col items-end">
						<InfoMessage
							type="warn"
							message={`To easily track your BAL P&L, keep trading activity and BAL in separate subaccounts`}
						/>
					</div>
					{!canCreateMoreAccounts && !submitting && (
						<div className="flex mt-2">
							<Text.P1 className="text-text-emphasis">
								{`This wallet has reached the maximum number of subaccounts (8).
								It is recommended to delete a subaccount so you can create a
								designated BAL subaccount. `}
								<p
									className="inline-flex text-purple-40 hover:cursor-pointer"
									onClick={() => {
										safePush('/overview/subaccounts');
										hideModal();
									}}
								>
									Manage Subaccounts
								</p>
							</Text.P1>
						</div>
					)}
					<>
						<div className="flex flex-col items-end">
							{solBalance.lt(totalAccountCreationCost) && (
								<>
									<InfoMessage
										className="mt-2"
										type="warn"
										messageTitle="Not enough SOL in your wallet"
										message={`To create a new subaccount, you need to have ${totalAccountCreationCost.toFixed(
											3
										)} SOL in your wallet.`}
									/>
								</>
							)}
						</div>

						<div className="flex flex-col">
							<Utility.VERTSPACERXL />
							<Utility.VERTDIVIDER />
							<Utility.VERTSPACERM />

							<div className="flex items-center justify-between">
								<Utility.FORMLABEL01 label="Deposit Collateral From" />
								<ButtonGroup.Segmented
									options={[
										{
											label: 'Wallet',
											value: 'wallet',
										},
										{
											label: 'Account',
											value: 'account',
										},
									]}
									selected={depositType}
									size="SMALL"
									selectAction={(val: DepositType) => setDepositType(val)}
								/>
							</div>

							<Utility.VERTSPACERL />

							{depositType === 'account' && (
								<>
									<LabelledInput label="Funding Account">
										<Select.Default
											id="depositAccountSelection"
											currentSelection={depositAccountSelection}
											onChange={setDepositAccountSelection}
											options={availableAccountOptions}
											useFullWidth
										/>
									</LabelledInput>
									<Utility.VERTSPACERM />
								</>
							)}

							<CollateralInput
								selectedMarket={selectedCollatMarket}
								maxAmount={currentCollatBalanceBigNum}
								onChangeMarket={setSelectedCollatMarket}
								label="Amount to deposit"
								showMaxLabel
								value={depositAmount}
								onChangeValue={setDepositAmount}
								singleLine
								source={
									depositType === 'wallet'
										? {
												type: 'wallet',
										  }
										: { type: 'userAccount', userKey: depositAccountSelection }
								}
							/>

							<Utility.VERTSPACERS />

							<>
								<LabelledInput label="Max Leverage For Account">
									<Select.MaxLeverageSelector
										onChange={setMaxLeverageToInit}
										initialSelection={SUGGESTED_INITIAL_MAX_LEV}
										className="w-[130px]"
									/>
								</LabelledInput>
								<Utility.VERTSPACERM />
							</>

							{balanceExceeded && (
								<>
									<Utility.VERTSPACERS />
									<InfoMessage
										type="error"
										messageTitle="Invalid deposit amount"
										message="You can't deposit more than your available account balance"
									/>
								</>
							)}

							<Utility.VERTSPACERL />

							<Button.Primary
								size="MEDIUM"
								onClick={handleAddAccount}
								disabled={
									!accountCreationCostLoaded ||
									solBalance.lt(totalAccountCreationCost) ||
									!canCreateMoreAccounts ||
									submitting ||
									!depositAmount ||
									depositAmountBigNum.lte(
										BigNum.zero(selectedCollatMarket.precisionExp),
										true
									) ||
									balanceExceeded
								}
							>
								Create BAL Subaccount
							</Button.Primary>
							<div className="flex items-center justify-center w-full mt-1">
								<Button.Secondary
									size="MEDIUM"
									className="w-full"
									textClass="text-text-default"
									onClick={handleSkip}
								>
									Use an existing account
								</Button.Secondary>
							</div>
						</div>
					</>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(NewLpSubaccountModal);
