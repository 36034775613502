"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.containsComputeUnitIxs = exports.isSetComputeUnitPriceIx = exports.isSetComputeUnitsIx = exports.findComputeUnitConsumption = void 0;
const web3_js_1 = require("@solana/web3.js");
async function findComputeUnitConsumption(programId, connection, txSignature, commitment = 'confirmed') {
    const tx = await connection.getTransaction(txSignature, { commitment });
    const computeUnits = [];
    const regex = new RegExp(`Program ${programId.toString()} consumed ([0-9]{0,6}) of ([0-9]{0,7}) compute units`);
    tx.meta.logMessages.forEach((logMessage) => {
        const match = logMessage.match(regex);
        if (match && match[1]) {
            computeUnits.push(match[1]);
        }
    });
    return computeUnits;
}
exports.findComputeUnitConsumption = findComputeUnitConsumption;
function isSetComputeUnitsIx(ix) {
    // Compute budget program discriminator is first byte
    // 2: set compute unit limit
    // 3: set compute unit price
    if (ix.programId.equals(web3_js_1.ComputeBudgetProgram.programId) &&
        // @ts-ignore
        ix.data.at(0) === 2) {
        return true;
    }
    return false;
}
exports.isSetComputeUnitsIx = isSetComputeUnitsIx;
function isSetComputeUnitPriceIx(ix) {
    // Compute budget program discriminator is first byte
    // 2: set compute unit limit
    // 3: set compute unit price
    if (ix.programId.equals(web3_js_1.ComputeBudgetProgram.programId) &&
        // @ts-ignore
        ix.data.at(0) === 3) {
        return true;
    }
    return false;
}
exports.isSetComputeUnitPriceIx = isSetComputeUnitPriceIx;
function containsComputeUnitIxs(ixs) {
    return {
        hasSetComputeUnitLimitIx: ixs.some(isSetComputeUnitsIx),
        hasSetComputeUnitPriceIx: ixs.some(isSetComputeUnitPriceIx),
    };
}
exports.containsComputeUnitIxs = containsComputeUnitIxs;
