'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import MarketIcon from 'src/components/Utils/MarketIcon';
import Text from '../../Text/Text';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Button from 'src/components/Button';
import { Menu as MenuIcon } from '@drift-labs/icons';
import { Menu } from '@headlessui/react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useAccountData from 'src/hooks/useAccountData';
import useSetCollateralModalParams from 'src/hooks/useSetCollateralModalParams';
import { useState } from 'react';
import { AccountDrawerTab } from 'src/@types/types';
import { CheckEmpty, CheckFilled, Open } from '@drift-labs/icons';
import useDriftClient from 'src/hooks/useDriftClient';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import { useSyncWalletBalances } from 'src/hooks/useSyncWalletBalances';
import { useDrawersStore } from 'src/stores/useDrawersStore';

// Move SOL to the top of list
const SORTED_SPOT_MARKETS = [...CurrentSpotMarkets].sort((item) => {
	if (item.symbol === 'SOL') {
		return -1;
	} else {
		return 0;
	}
});

const AssetBalanceRow = ({
	spotMarketConfig,
	hasAccount,
	hideZeroBalance,
	onClick,
}: {
	spotMarketConfig: SpotMarketConfig;
	hasAccount: boolean;
	hideZeroBalance: boolean;
	onClick: () => void;
}) => {
	const { symbol, marketIndex } = spotMarketConfig;

	useSyncWalletBalances();

	const assetBalance = useWalletBalancesStore((s) =>
		s.getBalance(spotMarketConfig.symbol, spotMarketConfig.precisionExp)
	);

	const balance = assetBalance.base;
	const loading = assetBalance.loading;
	const driftClient = useDriftClient();

	const oraclePrice = BigNum.from(
		driftClient?.getOracleDataForSpotMarket(marketIndex)?.price,
		PRICE_PRECISION_EXP
	);
	const balanceInUsd = balance
		.shift(PRICE_PRECISION_EXP)
		.mul(oraclePrice)
		.shiftTo(QUOTE_PRECISION_EXP);

	if (hideZeroBalance && balance.eqZero()) {
		return null;
	}

	return (
		<div
			className={`flex flex-row p-2 justify-between text-text-emphasis cursor-pointer hover:bg-container-bg-hover`}
			onClick={onClick}
		>
			<div className="flex flex-row items-center">
				<div className="mr-2">
					<MarketIcon marketSymbol={symbol} sizeClass="w-6 h-6" />
				</div>
				<div className="text-text-emphasis">
					<Text.BODY2>{symbol}</Text.BODY2>
				</div>
			</div>
			{loading && !!hasAccount ? (
				<SkeletonValuePlaceholder className="w-[60px] h-[38px]" loading />
			) : (
				<div className="text-right">
					<div className="mb-0.5">
						<Text.BODY2>{balance.toTradePrecision()}</Text.BODY2>
					</div>
					<div className="text-text-default">
						<Text.BODY2>
							<span>{balanceInUsd.toNotional()}</span>
						</Text.BODY2>
					</div>
				</div>
			)}
		</div>
	);
};

const WalletBalancesTab = ({ onClose }: { onClose: () => void }) => {
	const isMagicWallet = useDriftStore((s) => s.wallet?.isMagicAuth);
	const userKey = useAccountData()?.userKey;
	const setCollateralModalParams = useSetCollateralModalParams();
	const setDriftStore = useDriftStore((s) => s.set);
	const setDrawersStore = useDrawersStore((s) => s.set);
	const currentAccount = useAccountData();
	const [hideZeroBalances, setHideZeroBalances] = useState(false);

	const handleOpenAnotherModal = (openOtherModal: () => void) => {
		onClose();
		openOtherModal();
	};

	const handleOpenDepositModal = (collateralIndex = 0) => {
		handleOpenAnotherModal(() => {
			setCollateralModalParams({
				modalName: 'showDepositModal',
				collateralTypeIndex: collateralIndex,
				targetAccountKey: userKey,
				poolId: CurrentSpotMarkets[collateralIndex].poolId,
			});
		});
	};

	const handleOpenSendFromWalletModal = () => {
		handleOpenAnotherModal(() => {
			setDriftStore((s) => {
				s.modals.showSendFromWalletModal = true;
			});

			setDrawersStore((s) => {
				s.accounts.defaultTab = AccountDrawerTab.Wallet;
			});
		});
	};

	const handleOpenReceiveToWalletModal = () => {
		handleOpenAnotherModal(() => {
			setDriftStore((s) => {
				s.modals.showReceiveToWalletModal = true;
			});

			setDrawersStore((s) => {
				s.accounts.defaultTab = AccountDrawerTab.Wallet;
			});
		});
	};

	return (
		<div className="flex flex-col p-4 py-6">
			{/** Header */}
			<div className="flex items-center justify-between px-2 mb-2">
				<Text.BODY2 className="text-text-label">Balances</Text.BODY2>

				<div
					className="flex items-center gap-1 cursor-pointer group"
					onClick={() => setHideZeroBalances((prev) => !prev)}
				>
					<Text.BODY3 className="text-text-label">Zero Balances</Text.BODY3>
					{hideZeroBalances ? (
						<CheckEmpty
							size={16}
							color="var(--text-label)"
							className="transition-opacity group-hover:opacity-60"
						/>
					) : (
						<CheckFilled size={16} color="var(--interactive-link)" />
					)}
				</div>
			</div>

			{/** Spot Balances */}
			<div className="flex flex-col divide-y divide-[var(--container-border)]">
				{SORTED_SPOT_MARKETS.map((spotMarketConfig) => {
					return (
						<AssetBalanceRow
							spotMarketConfig={spotMarketConfig}
							key={spotMarketConfig.marketIndex}
							hasAccount={!!currentAccount}
							hideZeroBalance={hideZeroBalances}
							onClick={() => {
								handleOpenDepositModal(spotMarketConfig.marketIndex);
							}}
						/>
					);
				})}
			</div>

			{/** Actions */}
			<div className="flex gap-1 py-2">
				<Button.Secondary
					className="w-full whitespace-nowrap"
					size="MEDIUM"
					onClick={() => handleOpenDepositModal()}
				>
					Deposit to Drift
				</Button.Secondary>
				<Button.Secondary
					className="w-full"
					size="MEDIUM"
					onClick={handleOpenReceiveToWalletModal}
				>
					Receive
				</Button.Secondary>
				<Button.Secondary
					className="w-full"
					size="MEDIUM"
					onClick={handleOpenSendFromWalletModal}
				>
					Send
				</Button.Secondary>
				<Menu>
					{() => {
						return (
							<div className="relative">
								<Menu.Button
									as={Button.Secondary}
									size="MEDIUM"
									className="px-0"
								>
									<div className="flex items-center justify-center px-2 text-text-default">
										<MenuIcon size={18} className="rotate-90" />
									</div>
								</Menu.Button>
								<Menu.Items
									className={`bg-container-bg-hover text-text-default mt-2 absolute right-0 divide-y shadow-md outline-none p-0`}
								>
									<Menu.Item>
										<a
											className={`font-normal text-left block border border-container-border p-3 text-text-label whitespace-nowrap`}
											href={
												isMagicWallet
													? 'https://reveal.magic.link/solana/drift'
													: 'https://docs.drift.trade/getting-started/wallet-setup/metamask-setup#metamask-setup'
											}
											target="_blank"
											rel="noreferrer"
										>
											<Text.BODY2 className="flex items-center">
												<span>Get Private Key</span>
												<Open size={14} className="ml-1" />
											</Text.BODY2>
										</a>
									</Menu.Item>
								</Menu.Items>
							</div>
						);
					}}
				</Menu>
			</div>
		</div>
	);
};

export default WalletBalancesTab;
