'use client';

import React, { useCallback, useEffect, useState } from 'react';
import Modal from './Modals/Modal';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import {
	BASE_PRECISION_EXP,
	BigNum,
	MarginCategory,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useUserAccountIsReady from 'src/hooks/useUserAccountIsReady';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import Text from './Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import Button from './Button';
import MarketIcon from './Utils/MarketIcon';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { ChevronDown, ChevronUp, Info } from '@drift-labs/icons';
import useAccountExists from 'src/hooks/useAccountExists';
import {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import {
	ACCOUNT_HEALTH_PERCENT_CRITICAL,
	ACCOUNT_HEALTH_PERCENT_WARNING,
} from 'src/constants/constants';
import Tooltip from './Tooltip/Tooltip';
import HighlightedTabs from './TabbedNavigation/HighlightedTabs';
import CustomMaxLeverageCta from './CustomMaxLeverageCta';
import { useAccountsBeingLiquidatedWithoutBankruptcies } from 'src/hooks/Liquidations/useAccountsBeingLiquidated';
import InfoMessage from './TradeForm/InfoMessage';
import useDriftActions from 'src/hooks/useDriftActions';
import MarginRequirementBar from './MarginRequirementBar';

// This is exported from SDK, as far as I can tell
const WEIGHT_PRECISION_EXP = 4;

const FooterRow = React.memo(function FooterRowMemo({
	// includeOpenOrders,
	totalValue,
}: {
	// includeOpenOrders: boolean;
	totalValue: string;
}) {
	return (
		<div className="flex flex-col-reverse items-end justify-between w-full py-3 mt-2 border-t sm:flex-row sm:items-center border-text-label">
			<div className="mt-1 sm:mt-0">
				{/* {includeOpenOrders && (
					<Text.BODY3 className="text-text-secondary">
						Values include open orders
					</Text.BODY3>
				)} */}
			</div>
			<span>
				<Text.BODY1 className="inline-block mr-4">
					Total Weighted Value
				</Text.BODY1>
				<Text.BODY1>{totalValue}</Text.BODY1>
			</span>
		</div>
	);
});

const HeaderRow = React.memo(function HeaderRowMemo({
	firstColumnLubel,
}: {
	firstColumnLubel: string;
}) {
	return (
		<div className="grid items-end grid-flow-col py-2 mb-1 mb-2 border-b grid-cols-health-modal-mobile sm:grid-cols-health-modal text-text-label border-text-label">
			<div>
				<Text.BODY1>{firstColumnLubel}</Text.BODY1>
			</div>
			<div>
				<Tooltip
					placement="top"
					content={
						<div className="p-2">
							<Text.BODY2>
								Value and size are based on the most risky position the account
								could take on, if all bids <span className="italic">or</span>{' '}
								asks were filled.
							</Text.BODY2>
						</div>
					}
				>
					<Text.BODY1>
						Value / Size <Info size={16} className="relative top-1" />
					</Text.BODY1>
				</Tooltip>
			</div>
			<div className="text-right">
				<Text.BODY1>Weighted Value / %</Text.BODY1>
			</div>
		</div>
	);
});

const AssetLiabilityRow = React.memo(function AssetLiabilityRowMemo(
	props: AssetLiabilityRowData
) {
	return (
		<div className="grid items-center grid-flow-col py-1 grid-cols-health-modal-mobile sm:grid-cols-health-modal">
			<div className={`${props.isPnl ? 'pl-4' : ''}`}>
				<Text.BODY1>
					<MarketIcon
						className="inline-block mr-1"
						marketSymbol={props.marketSymbol}
					/>{' '}
					{props.label}
				</Text.BODY1>
			</div>
			<div>
				<Text.BODY1>{props.value}</Text.BODY1>
				{!props.isPnl && (
					<>
						<br />
						<Text.BODY3 className="text-text-secondary">
							{props.size} {props.baseAssetSymbol}
						</Text.BODY3>
					</>
				)}
			</div>
			<div className="w-full text-right">
				<Text.BODY1>{props.weightedValue}</Text.BODY1>
				<br />
				<Text.BODY3 className="text-text-secondary">{props.weight}%</Text.BODY3>
			</div>
		</div>
	);
});

const UPnlSummaryRow = React.memo(function UPnlSummaryRowMemo({
	averageWeight,
	totalWeightedValue,
	totalValue,
	expanded,
	onClick,
}: {
	averageWeight: number;
	totalWeightedValue: number;
	totalValue: number;
	expanded: boolean;
	onClick: () => void;
}) {
	return (
		<div className="grid items-center grid-flow-col py-1 grid-cols-health-modal-mobile sm:grid-cols-health-modal">
			<div className="cursor-pointer" onClick={onClick}>
				<Text.BODY1>
					Unsettled{' '}
					{expanded ? (
						<ChevronUp className="relative top-[3px]" />
					) : (
						<ChevronDown className="relative top-[3px]" />
					)}
				</Text.BODY1>
			</div>
			<div>
				<Text.BODY1>{UI_UTILS.toNotional(totalValue)}</Text.BODY1>
			</div>
			<div className="text-right">
				<Text.BODY1>{UI_UTILS.toNotional(totalWeightedValue)}</Text.BODY1>
				<br />
				<Text.BODY3 className="text-text-secondary">
					{averageWeight}%
				</Text.BODY3>
			</div>
		</div>
	);
});

type AssetLiabilityRowData = {
	marketSymbol: string;
	baseAssetSymbol: string;
	label: string;
	weight: number;
	weightedValue: string;
	value: string;
	size: string;
	isPnl?: boolean;
};

const AccountHealthModalContent = ({ onClose }: { onClose: () => void }) => {
	const actions = useDriftActions();
	const isMobile = useIsMobileScreenSize();
	const [isAssetUPnlExpanded, setIsAssetUPnlExpanded] = useState(false);
	const [showLiquidationNotice, setShowLiquidationNotice] = useState(false);
	const [marginCategory, setMarginCategory] =
		useState<MarginCategory>('Maintenance');
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const accountExists = useAccountExists();
	const connected = useWalletIsConnected();
	const userAccountIsReady = useUserAccountIsReady();
	const marginInfo = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.marginInfo
	);
	const accountName = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.name
	);

	const accountKey = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.userKey
	);

	const currentSubaccountId = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.userId
	);

	const user =
		connected && driftClient && driftClientIsReady && accountExists
			? driftClient.getUser()
			: undefined;

	const accountsBeingLiqd = useAccountsBeingLiquidatedWithoutBankruptcies();

	const health = connected && userAccountIsReady && user?.getHealth();
	const healthDisplay =
		typeof health === 'number' && !isNaN(health) ? health : 100;

	const marginReqNum = BigNum.from(
		(marginCategory === 'Initial'
			? marginInfo?.initialReq
			: marginInfo?.maintenanceReq) ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();
	const marginReq = UI_UTILS.toNotional(marginReqNum);

	const totalMaintenanceCollateral = BigNum.from(
		user?.getTotalCollateral('Maintenance', true) ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();

	const totalInitialCollateral = BigNum.from(
		user?.getTotalCollateral('Initial', true) ?? 0,
		QUOTE_PRECISION_EXP
	).toNum();

	const totalCollateralNum =
		marginCategory === 'Maintenance'
			? totalMaintenanceCollateral
			: totalInitialCollateral;

	const totalCollateral = UI_UTILS.toNotional(totalCollateralNum);

	const freeCollateralBN =
		marginCategory === 'Initial'
			? marginInfo?.freeCollateral ?? ZERO
			: marginInfo?.maintenanceFreeCollateral ?? ZERO;

	const freeCollateral = UI_UTILS.toNotional(
		BigNum.from(freeCollateralBN, QUOTE_PRECISION_EXP).toNum()
	);

	let totalAssetWeight = 0;
	let totalLiabilityWeight = 0;

	let assetUPnlAvgWeight = 0;
	let assetUPnlTotalValue = 0;
	let assetUPnlTotalWeightedValue = 0;

	const assets: AssetLiabilityRowData[] = [];
	const uPnlAssets: AssetLiabilityRowData[] = [];
	const liabilities: AssetLiabilityRowData[] = [];
	const uPnlLiabilities: AssetLiabilityRowData[] = [];

	const { borrows, deposits, perpPositions, perpPnl } =
		user?.getHealthComponents({ marginCategory }) || {};

	deposits?.forEach((deposit) => {
		const spotMarket = CurrentSpotMarkets[deposit.marketIndex];

		const weight = BigNum.from(deposit.weight, WEIGHT_PRECISION_EXP);
		const weightedValue = BigNum.from(
			deposit.weightedValue,
			QUOTE_PRECISION_EXP
		);
		const value = BigNum.from(deposit.value, QUOTE_PRECISION_EXP);
		const size = BigNum.from(deposit.size, spotMarket.precisionExp);

		totalAssetWeight += weightedValue.toNum();

		assets.push({
			marketSymbol: spotMarket.symbol,
			baseAssetSymbol: spotMarket.symbol,
			label: `${spotMarket.symbol}`,
			weight: parseFloat((weight.toNum() * 100).toFixed(2)),
			weightedValue: weightedValue.toNotional(),
			value: value.toNotional(),
			size: size.prettyPrint(),
			isPnl: false,
		});
	});

	borrows?.forEach((borrow) => {
		const spotMarket = CurrentSpotMarkets[borrow.marketIndex];

		const weight = BigNum.from(borrow.weight, WEIGHT_PRECISION_EXP);
		const weightedValue = BigNum.from(
			borrow.weightedValue,
			QUOTE_PRECISION_EXP
		);
		const value = BigNum.from(borrow.value, QUOTE_PRECISION_EXP);
		const size = BigNum.from(borrow.size, spotMarket.precisionExp);

		totalLiabilityWeight += weightedValue.toNum();

		liabilities.push({
			marketSymbol: spotMarket.symbol,
			baseAssetSymbol: spotMarket.symbol,
			label: `${spotMarket.symbol} borrow`,
			weight: Math.round(weight.toNum() * 100),
			weightedValue: weightedValue.toNotional(),
			value: value.toNotional(),
			size: size.toTradePrecision(),
			isPnl: false,
		});
	});

	perpPositions?.forEach((position) => {
		const perpMArket = CurrentPerpMarkets[position.marketIndex];

		const weight = BigNum.from(position.weight, WEIGHT_PRECISION_EXP);
		const weightedValue = BigNum.from(
			position.weightedValue,
			QUOTE_PRECISION_EXP
		);
		const value = BigNum.from(position.value, QUOTE_PRECISION_EXP);
		const size = BigNum.from(position.size, BASE_PRECISION_EXP);

		totalLiabilityWeight += weightedValue.toNum();

		liabilities.push({
			marketSymbol: perpMArket.symbol,
			baseAssetSymbol: perpMArket.symbol,
			label: `${perpMArket.symbol}`,
			weight: Math.round(weight.toNum() * 100),
			weightedValue: weightedValue.toNotional(),
			value: value.toNotional(),
			size: size.toTradePrecision(),
			isPnl: false,
		});
	});

	perpPnl?.forEach((pnl) => {
		const perpMarket = CurrentPerpMarkets[pnl.marketIndex];

		const weight = Math.round(
			BigNum.from(pnl.weight, WEIGHT_PRECISION_EXP).toNum() * 100
		);
		const weightedValue = BigNum.from(pnl.weightedValue, QUOTE_PRECISION_EXP);
		const value = BigNum.from(pnl.value, QUOTE_PRECISION_EXP);
		const size = BigNum.from(pnl.size, BASE_PRECISION_EXP);

		assetUPnlAvgWeight += weight;
		totalAssetWeight += value.toNum();
		assetUPnlTotalValue += value.toNum();
		assetUPnlTotalWeightedValue += weightedValue.toNum();

		uPnlAssets.push({
			marketSymbol: perpMarket.symbol,
			baseAssetSymbol: perpMarket.baseAssetSymbol,
			label: `${perpMarket.symbol}`,
			weight,
			weightedValue: weightedValue.toNotional(),
			value: value.toNotional(),
			size: size.toTradePrecision(),
			isPnl: true,
		});
	});

	assetUPnlAvgWeight = uPnlAssets.length
		? Math.round(assetUPnlAvgWeight / uPnlAssets.length)
		: 0;

	const handleExpandAssetPnl = useCallback(() => {
		setIsAssetUPnlExpanded(!isAssetUPnlExpanded);
	}, [isAssetUPnlExpanded]);

	const healthClassName =
		healthDisplay >= ACCOUNT_HEALTH_PERCENT_WARNING
			? 'text-positive-green'
			: healthDisplay >= ACCOUNT_HEALTH_PERCENT_CRITICAL
			? 'text-warn-yellow'
			: 'text-negative-red';

	const accountDisplayName = connected
		? accountExists
			? accountName
			: 'No Drift Account Created'
		: 'Not Connected';

	useEffect(() => {
		if (accountsBeingLiqd?.length > 0) {
			if (
				accountsBeingLiqd
					.map((acc) => acc.userKey)
					.some((accKey) => accKey === accountKey)
			) {
				setShowLiquidationNotice(true);
			} else {
				setShowLiquidationNotice(false);
			}
		} else {
			setShowLiquidationNotice(false);
		}
	}, [accountsBeingLiqd, accountKey]);

	return (
		<div
			className="flex flex-col justify-between overflow-y-auto thin-scroll"
			style={
				isMobile ? { maxHeight: `calc(100vh - 74px)` } : { maxHeight: '70vh' }
			}
		>
			<Modal.Content className="p-5">
				<div>
					<div className="border-b border-container-border pb-2">
						{showLiquidationNotice && (
							<InfoMessage
								className="py-2"
								type="error"
								messageTitle="This account is in liquidation state; it can be liquidated more unless funds are deposited. Trades and withdrawals are disabled."
								message={
									<div
										className="hover:cursor-pointer"
										onClick={() => actions.showDepositModal(0, accountKey)}
									>{`Click here to deposit`}</div>
								}
							/>
						)}
						<CustomMaxLeverageCta />
						<div className="mb-2">
							<span className="inline-block w-auto">
								<Tooltip
									content={
										<>
											Account health percentage is equal to:
											<br />1 - Maintenance Margin / Maintenance Collateral
										</>
									}
									placement="right"
									tooltipClassName={'relative top-2'}
								>
									<Text.BODY1 className="underline decoration-dotted text-text-default">
										Account Health ({accountDisplayName})
									</Text.BODY1>
									{/* <Info size={16} className="relative ml-2" /> */}
								</Tooltip>
							</span>
						</div>
						<div className="my-2">
							<Text.H1 className={`${healthClassName} font-2xl`}>
								{healthDisplay}%
							</Text.H1>
						</div>
						<div className="my-2">
							<Text.BODY2 className="text-text-secondary">
								At zero health, the maintenance weighted value of your total
								collateral has fallen below the maintenance margin requirement
								and the account can be liquidated.
							</Text.BODY2>
						</div>
					</div>

					<div className="flex items-center w-full mt-4 mb-3">
						<HighlightedTabs
							currentSelection={marginCategory}
							options={[
								{
									value: 'Maintenance',
									label: 'Maintenance',
								},
								{
									value: 'Initial',
									label: 'Initial',
								},
							]}
							onChange={(selection: MarginCategory) => {
								setMarginCategory(selection);
							}}
							opts={{
								inline: true,
								xlPadding: true,
							}}
						/>
					</div>
					<div>
						<Text.BODY2 className="text-text-secondary">
							{marginCategory === 'Initial' ? (
								<>
									Initial margin represents the amount required to take
									risk-increasing actions.
								</>
							) : (
								<>
									Maintenance margin represents the amount required to avoid
									liquidation.
								</>
							)}
						</Text.BODY2>
					</div>

					<div className="w-full my-6">
						<MarginRequirementBar
							marginCategory={marginCategory}
							subaccountId={currentSubaccountId}
						/>
					</div>

					<div className="flex flex-row items-start justify-between w-full my-3">
						<div className="max-w-[70%]">
							<Text.BODY1>Total Collateral</Text.BODY1>
							<br />
							<Text.BODY3 className="text-text-secondary">
								Weighted value of assets
							</Text.BODY3>
						</div>
						<Text.BODY1>{totalCollateral}</Text.BODY1>
					</div>

					<div className="flex flex-row items-start justify-between w-full my-3">
						<div className="max-w-[70%]">
							<Text.BODY1>Margin Requirement</Text.BODY1>
							<br />
							<Text.BODY3 className="text-text-secondary">
								Weighted value of liabilities
							</Text.BODY3>
						</div>
						<Text.BODY1 className="text-right">{marginReq}</Text.BODY1>
					</div>

					<div className="flex flex-row items-start justify-between w-full my-3">
						<div className="max-w-[70%]">
							<Text.BODY1>Free Collateral</Text.BODY1>
							<br />
							<Text.BODY3 className="text-text-secondary">
								Weighted value of collateral - weighted value of liabilities
							</Text.BODY3>
						</div>
						<Text.BODY1>{freeCollateral}</Text.BODY1>
					</div>

					{/* <div className="flex flex-row items-start justify-between w-full my-5">
						<Text.BODY1>
							All unsettled has zero weight and is not counted as collateral
						</Text.BODY1>
					</div> */}

					<div className="w-full mt-4">
						<HeaderRow firstColumnLubel="Asset" />

						{assets.length > 0 || uPnlAssets.length > 0 ? (
							<>
								{assets.map((data) => (
									<AssetLiabilityRow key={JSON.stringify(data)} {...data} />
								))}
								{uPnlAssets.length > 0 && (
									<>
										<UPnlSummaryRow
											averageWeight={assetUPnlAvgWeight}
											totalWeightedValue={assetUPnlTotalWeightedValue}
											totalValue={assetUPnlTotalValue}
											expanded={isAssetUPnlExpanded}
											onClick={handleExpandAssetPnl}
										/>
										{isAssetUPnlExpanded && (
											<>
												{uPnlAssets.map((data) => (
													<AssetLiabilityRow
														key={JSON.stringify(data)}
														{...data}
													/>
												))}
											</>
										)}
									</>
								)}
								<FooterRow totalValue={UI_UTILS.toNotional(totalAssetWeight)} />
							</>
						) : (
							<div className="w-full p-6 text-center bg-container-bg-hover">
								<Text.BODY1>No Assets</Text.BODY1>
							</div>
						)}
					</div>

					<div className="mt-6">
						<HeaderRow firstColumnLubel="Liability" />

						{liabilities.length > 0 || uPnlLiabilities.length > 0 ? (
							<>
								{liabilities.map((data) => (
									<AssetLiabilityRow key={JSON.stringify(data)} {...data} />
								))}
								{/* {uPnlLiabilities.length > 0 && (
									<>
										<UPnlSummaryRow
											averageWeight={liabilityUPnlAvgWeight}
											totalWeightedValue={liabilityUPnlTotalWeightedValue}
											totalValue={liabilityUPnlTotalValue}
											expanded={isLiabilityUPnlExpanded}
											onClick={handleExpandLiabilityPnl}
										/>
										{isLiabilityUPnlExpanded && (
											<>
												{uPnlLiabilities.map((data) => (
													<AssetLiabilityRow
														key={JSON.stringify(data)}
														{...data}
													/>
												))}
											</>
										)}
									</>
								)} */}
								<FooterRow
									totalValue={UI_UTILS.toNotional(totalLiabilityWeight)}
								/>
							</>
						) : (
							<div className="w-full p-6 text-center bg-container-bg-hover">
								<Text.BODY1>No Liabilities</Text.BODY1>
							</div>
						)}
					</div>

					{/* <div className="w-full my-6">
						<CheckboxInput
							checked={includeOpenOrders}
							label="Include Open Orders"
							onChange={toggleIncludeOpenOrders}
							secondaryStyle
						/>
					</div> */}

					<div className="w-full mt-8 mb-2">
						<Button.Secondary
							size={'MEDIUM'}
							onClick={onClose}
							className="w-full"
						>
							Close
						</Button.Secondary>
					</div>
				</div>
			</Modal.Content>
		</div>
	);
};

export default React.memo(AccountHealthModalContent);
