"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MARGIN_PRECISION = exports.AMM_TIMES_PEG_TO_QUOTE_PRECISION_RATIO = exports.PRICE_TO_QUOTE_PRECISION = exports.PRICE_DIV_PEG = exports.AMM_TO_QUOTE_PRECISION_RATIO = exports.BASE_PRECISION_EXP = exports.BASE_PRECISION = exports.AMM_RESERVE_PRECISION = exports.PEG_PRECISION = exports.FUNDING_RATE_BUFFER_PRECISION = exports.FUNDING_RATE_PRECISION = exports.PRICE_PRECISION = exports.QUOTE_PRECISION = exports.LIQUIDATION_FEE_PRECISION = exports.SPOT_MARKET_IMF_PRECISION = exports.SPOT_MARKET_IMF_PRECISION_EXP = exports.SPOT_MARKET_BALANCE_PRECISION = exports.SPOT_MARKET_BALANCE_PRECISION_EXP = exports.SPOT_MARKET_WEIGHT_PRECISION = exports.SPOT_MARKET_UTILIZATION_PRECISION = exports.SPOT_MARKET_UTILIZATION_PRECISION_EXP = exports.SPOT_MARKET_CUMULATIVE_INTEREST_PRECISION = exports.SPOT_MARKET_CUMULATIVE_INTEREST_PRECISION_EXP = exports.SPOT_MARKET_RATE_PRECISION = exports.SPOT_MARKET_RATE_PRECISION_EXP = exports.AMM_RESERVE_PRECISION_EXP = exports.PEG_PRECISION_EXP = exports.FUNDING_RATE_PRECISION_EXP = exports.PRICE_PRECISION_EXP = exports.FUNDING_RATE_BUFFER_PRECISION_EXP = exports.QUOTE_PRECISION_EXP = exports.CONCENTRATION_PRECISION = exports.PERCENTAGE_PRECISION = exports.PERCENTAGE_PRECISION_EXP = exports.MAX_LEVERAGE_ORDER_SIZE = exports.MAX_LEVERAGE = exports.TEN_MILLION = exports.BN_MAX = exports.TEN_THOUSAND = exports.TEN = exports.NINE = exports.EIGHT = exports.SEVEN = exports.SIX = exports.FIVE = exports.FOUR = exports.THREE = exports.TWO = exports.ONE = exports.ZERO = void 0;
exports.MAX_PREDICTION_PRICE = exports.FUEL_START_TS = exports.FUEL_WINDOW = exports.DUST_POSITION_SIZE = exports.SLOT_TIME_ESTIMATE_MS = exports.IDLE_TIME_SLOTS = exports.ACCOUNT_AGE_DELETION_CUTOFF_SECONDS = exports.DEFAULT_REVENUE_SINCE_LAST_FUNDING_SPREAD_RETREAT = exports.OPEN_ORDER_MARGIN_REQUIREMENT = exports.LAMPORTS_EXP = exports.LAMPORTS_PRECISION = exports.GOV_SPOT_MARKET_INDEX = exports.QUOTE_SPOT_MARKET_INDEX = exports.ONE_YEAR = exports.ONE_HOUR = exports.FIVE_MINUTE = exports.PRICE_TIMES_AMM_TO_QUOTE_PRECISION_RATIO = exports.FUNDING_RATE_OFFSET_DENOMINATOR = exports.LIQUIDATION_PCT_PRECISION = exports.BID_ASK_SPREAD_PRECISION = void 0;
const web3_js_1 = require("@solana/web3.js");
const __1 = require("../");
exports.ZERO = new __1.BN(0);
exports.ONE = new __1.BN(1);
exports.TWO = new __1.BN(2);
exports.THREE = new __1.BN(3);
exports.FOUR = new __1.BN(4);
exports.FIVE = new __1.BN(5);
exports.SIX = new __1.BN(6);
exports.SEVEN = new __1.BN(7);
exports.EIGHT = new __1.BN(8);
exports.NINE = new __1.BN(9);
exports.TEN = new __1.BN(10);
exports.TEN_THOUSAND = new __1.BN(10000);
exports.BN_MAX = new __1.BN(Number.MAX_SAFE_INTEGER);
exports.TEN_MILLION = exports.TEN_THOUSAND.mul(exports.TEN_THOUSAND);
exports.MAX_LEVERAGE = new __1.BN(5);
exports.MAX_LEVERAGE_ORDER_SIZE = new __1.BN('18446744073709551615');
exports.PERCENTAGE_PRECISION_EXP = new __1.BN(6);
exports.PERCENTAGE_PRECISION = new __1.BN(10).pow(exports.PERCENTAGE_PRECISION_EXP);
exports.CONCENTRATION_PRECISION = exports.PERCENTAGE_PRECISION;
exports.QUOTE_PRECISION_EXP = new __1.BN(6);
exports.FUNDING_RATE_BUFFER_PRECISION_EXP = new __1.BN(3);
exports.PRICE_PRECISION_EXP = new __1.BN(6);
exports.FUNDING_RATE_PRECISION_EXP = exports.PRICE_PRECISION_EXP.add(exports.FUNDING_RATE_BUFFER_PRECISION_EXP);
exports.PEG_PRECISION_EXP = new __1.BN(6);
exports.AMM_RESERVE_PRECISION_EXP = new __1.BN(9);
exports.SPOT_MARKET_RATE_PRECISION_EXP = new __1.BN(6);
exports.SPOT_MARKET_RATE_PRECISION = new __1.BN(10).pow(exports.SPOT_MARKET_RATE_PRECISION_EXP);
exports.SPOT_MARKET_CUMULATIVE_INTEREST_PRECISION_EXP = new __1.BN(10);
exports.SPOT_MARKET_CUMULATIVE_INTEREST_PRECISION = new __1.BN(10).pow(exports.SPOT_MARKET_CUMULATIVE_INTEREST_PRECISION_EXP);
exports.SPOT_MARKET_UTILIZATION_PRECISION_EXP = new __1.BN(6);
exports.SPOT_MARKET_UTILIZATION_PRECISION = new __1.BN(10).pow(exports.SPOT_MARKET_UTILIZATION_PRECISION_EXP);
exports.SPOT_MARKET_WEIGHT_PRECISION = new __1.BN(10000);
exports.SPOT_MARKET_BALANCE_PRECISION_EXP = new __1.BN(9);
exports.SPOT_MARKET_BALANCE_PRECISION = new __1.BN(10).pow(exports.SPOT_MARKET_BALANCE_PRECISION_EXP);
exports.SPOT_MARKET_IMF_PRECISION_EXP = new __1.BN(6);
exports.SPOT_MARKET_IMF_PRECISION = new __1.BN(10).pow(exports.SPOT_MARKET_IMF_PRECISION_EXP);
exports.LIQUIDATION_FEE_PRECISION = new __1.BN(1000000);
exports.QUOTE_PRECISION = new __1.BN(10).pow(exports.QUOTE_PRECISION_EXP);
exports.PRICE_PRECISION = new __1.BN(10).pow(exports.PRICE_PRECISION_EXP);
exports.FUNDING_RATE_PRECISION = new __1.BN(10).pow(exports.FUNDING_RATE_PRECISION_EXP);
exports.FUNDING_RATE_BUFFER_PRECISION = new __1.BN(10).pow(exports.FUNDING_RATE_BUFFER_PRECISION_EXP);
exports.PEG_PRECISION = new __1.BN(10).pow(exports.PEG_PRECISION_EXP);
exports.AMM_RESERVE_PRECISION = new __1.BN(10).pow(exports.AMM_RESERVE_PRECISION_EXP);
exports.BASE_PRECISION = exports.AMM_RESERVE_PRECISION;
exports.BASE_PRECISION_EXP = exports.AMM_RESERVE_PRECISION_EXP;
exports.AMM_TO_QUOTE_PRECISION_RATIO = exports.AMM_RESERVE_PRECISION.div(exports.QUOTE_PRECISION); // 10^3
exports.PRICE_DIV_PEG = exports.PRICE_PRECISION.div(exports.PEG_PRECISION); //10^1
exports.PRICE_TO_QUOTE_PRECISION = exports.PRICE_PRECISION.div(exports.QUOTE_PRECISION); // 10^1
exports.AMM_TIMES_PEG_TO_QUOTE_PRECISION_RATIO = exports.AMM_RESERVE_PRECISION.mul(exports.PEG_PRECISION).div(exports.QUOTE_PRECISION); // 10^9
exports.MARGIN_PRECISION = exports.TEN_THOUSAND;
exports.BID_ASK_SPREAD_PRECISION = new __1.BN(1000000); // 10^6
exports.LIQUIDATION_PCT_PRECISION = exports.TEN_THOUSAND;
exports.FUNDING_RATE_OFFSET_DENOMINATOR = new __1.BN(5000);
exports.PRICE_TIMES_AMM_TO_QUOTE_PRECISION_RATIO = exports.PRICE_PRECISION.mul(exports.AMM_TO_QUOTE_PRECISION_RATIO);
exports.FIVE_MINUTE = new __1.BN(60 * 5);
exports.ONE_HOUR = new __1.BN(60 * 60);
exports.ONE_YEAR = new __1.BN(31536000);
exports.QUOTE_SPOT_MARKET_INDEX = 0;
exports.GOV_SPOT_MARKET_INDEX = 15;
exports.LAMPORTS_PRECISION = new __1.BN(web3_js_1.LAMPORTS_PER_SOL);
exports.LAMPORTS_EXP = new __1.BN(Math.log10(web3_js_1.LAMPORTS_PER_SOL));
exports.OPEN_ORDER_MARGIN_REQUIREMENT = exports.QUOTE_PRECISION.div(new __1.BN(100));
exports.DEFAULT_REVENUE_SINCE_LAST_FUNDING_SPREAD_RETREAT = new __1.BN(-25).mul(exports.QUOTE_PRECISION);
exports.ACCOUNT_AGE_DELETION_CUTOFF_SECONDS = 60 * 60 * 24 * 13; // 13 days
exports.IDLE_TIME_SLOTS = 9000;
exports.SLOT_TIME_ESTIMATE_MS = 400;
exports.DUST_POSITION_SIZE = exports.QUOTE_PRECISION.divn(100); // Dust position is any position smaller than 1c
exports.FUEL_WINDOW = new __1.BN(60 * 60 * 24 * 28); // 28 days
exports.FUEL_START_TS = new __1.BN(1723147200); // unix timestamp
exports.MAX_PREDICTION_PRICE = exports.PRICE_PRECISION;
