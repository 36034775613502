import {
	BigNum,
	BN,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	THREE,
	ZERO,
} from '@drift-labs/sdk';
import { UIOrderType } from '@drift/common';
import { MarketInfo } from 'src/hooks/useInfoForCurrentlySelectedMarket';
import NumLib from './NumLib';

const BASE_MULTIPLIER = 1;

/**
 * Calculates an even distribution of prices between a starting and ending price
 * across a given number of orders.
 *
 * The prices are distributed linearly from startPrice to endPrice, with each price
 * evenly spaced based on the number of orders.
 *
 * @param {BN} startPrice - The starting price for the distribution.
 * @param {BN} endPrice - The ending price for the distribution.
 * @param {number} orderCount - The number of prices (orders) to generate.
 * @returns {BN[]} An array of prices, evenly distributed between startPrice and endPrice.
 */
export function calculatePriceDistribution(
	startPrice: BN,
	endPrice: BN,
	orderCount: number
): BN[] {
	if (orderCount === 1) {
		return [startPrice];
	}
	if (orderCount === 2) {
		return [startPrice, endPrice];
	}
	const priceStep = endPrice.sub(startPrice).div(new BN(orderCount - 1));
	const prices: BN[] = [];
	for (let i = 0; i < orderCount; i++) {
		prices.push(startPrice.add(priceStep.mul(new BN(i))));
	}
	return prices;
}

export function calculateNotionalSize(
	sizeDistribution: 'flat' | 'ascending' | 'descending',
	totalOrderSize: BigNum,
	orderStepSize: BigNum,
	orderCount: number,
	startPrice: BN,
	endPrice: BN,
	multiplierStep = 0.5
): BN {
	const orders = calculateScaledOrderSizes(
		sizeDistribution,
		totalOrderSize,
		orderStepSize,
		orderCount,
		multiplierStep
	);
	const prices = calculatePriceDistribution(startPrice, endPrice, orderCount);

	return orders.reduce(
		(acc, val, index) => acc.add(val.val.mul(prices[index])),
		new BN(0)
	);
}

export function calculateAveragePrice(
	sizeDistribution: 'flat' | 'ascending' | 'descending',
	totalOrderSize: BigNum,
	orderStepSize: BigNum,
	orderCount: number,
	startPrice: BN,
	endPrice: BN,
	multiplierStep = 0.5
): BN {
	return calculateNotionalSize(
		sizeDistribution,
		totalOrderSize,
		orderStepSize,
		orderCount,
		startPrice,
		endPrice,
		multiplierStep
	).div(totalOrderSize.val);
}

/**
 * Calculates order sizes based on the specified size distribution strategy.
 * Supports three distribution types: 'flat', 'ascending', and 'descending'.
 *
 * - 'flat': Distributes orders evenly with rounding to the nearest orderStepSize.
 * - 'ascending': Uses a scaling multiplier (1x, 1.5x, 2x, etc.) to increase order sizes.
 * - 'descending': Similar to 'ascending' but reverses the order so that the largest order is first.
 *
 * @param {'flat' | 'ascending' | 'descending'} sizeDistribution - The distribution strategy for calculating order sizes.
 * @param {BN} totalOrderSize - The total size of the order to be distributed.
 * @param {BigNum} orderStepSize - The step size used to round each order.
 * @param {number} orderCount - The number of orders to be generated.
 * @param {number} [multiplierStep=0.5] - The step increment for the scaling multiplier in ascending/descending strategies (default is 0.5).
 * @returns {BigNum[]} An array of order sizes based on the selected size distribution strategy.
 */
export function calculateScaledOrderSizes(
	sizeDistribution: 'flat' | 'ascending' | 'descending',
	totalOrderSize: BigNum,
	orderStepSize: BigNum,
	orderCount: number,
	multiplierStep = 0.5
): BigNum[] {
	if (sizeDistribution === 'flat') {
		return calculateFlatOrdersSizes(totalOrderSize, orderStepSize, orderCount);
	} else if (
		sizeDistribution === 'ascending' ||
		sizeDistribution === 'descending'
	) {
		const orders = calculateScaledMultiplierOrdersSizes(
			totalOrderSize,
			orderStepSize,
			orderCount,
			multiplierStep
		);
		if (sizeDistribution === 'descending') {
			orders.reverse();
		}
		return orders;
	}
}

/**
 * Calculates the minimum total value required to divide the total order
 * into a specified number of smaller orders, ensuring that each individual
 * order meets the minimum order size.
 *
 * This function determines the threshold below which the total value cannot
 * be split into the given number of orders without some orders falling below
 * the specified minimum order size.
 *
 * @param {BN} minOrderSize - The minimum size for each individual order.
 * @param {number} orderCount - The number of orders to divide the total value into.
 * @returns {BN} The minimum total value required to meet the order count while respecting the minimum order size.
 */
export function calculateMinTotalValue(
	minOrderSize: BigNum,
	orderCount: number
): BigNum {
	return minOrderSize.mul(new BN(orderCount));
}

/**
 * Distributes the total order size evenly across a given number of orders,
 * rounding each order to the nearest specified order step size.
 *
 * @param {BigNum} totalOrderSize - The total size of the order to be distributed.
 * @param {BigNum} orderStepSize - The step size used to round each order.
 * @param {number} orderCount - The number of orders to be generated.
 * @returns {BigNum[]} An array of order sizes, each rounded to the nearest order step size.
 */
function calculateFlatOrdersSizes(
	totalOrderSize: BigNum,
	orderStepSize: BigNum,
	orderCount: number
): BigNum[] {
	if (calculateMinTotalValue(orderStepSize, orderCount).gt(totalOrderSize))
		throw new Error('Order size too small');
	const orderSize = totalOrderSize.div(new BN(orderCount));
	const roundedOrderSize = orderSize.div(orderStepSize).mul(orderStepSize);
	const orders: BigNum[] = Array(orderCount).fill(roundedOrderSize);

	const sum = orders.reduce(
		(acc, val) => acc.add(val),
		BigNum.zero(orderStepSize.precision)
	);
	const discrepancy = sum.sub(totalOrderSize);
	if (!discrepancy.eqZero()) {
		orders[orderCount - 1] = orders[orderCount - 1].sub(discrepancy);
	}
	return orders;
}

const MULTIPLIER_PRECISION = new BN(1_000);
const MULTIPLIER_PRECISION_EXP = THREE;

/**
 * Distributes the total order size using a scaling multiplier strategy.
 * The order sizes increase progressively by a multiplier (1x, 1.5x, 2x, ...),
 * with each order rounded to the nearest order step size.
 *
 * @param {BigNum} totalOrderSize - The total size of the order to be distributed.
 * @param {BN} orderStepSize - The step size used to round each order.
 * @param {number} orderCount - The number of orders to be generated.
 * @param {number} [multiplierStep=0.5] - The step increment for the scaling multiplier (default is 0.5).
 * @returns {BN[]} An array of order sizes, each adjusted by the multiplier and rounded to the nearest order step size.
 */
function calculateScaledMultiplierOrdersSizes(
	totalOrderSize: BigNum,
	orderStepSize: BigNum,
	orderCount: number,
	multiplierStep = 0.5
): BigNum[] {
	if (calculateMinTotalValue(orderStepSize, orderCount).gt(totalOrderSize))
		throw new Error('Order size too small');

	// Calculate the last multiplier based on the base multiplier and the multiplier step.
	// For example, if orderCount is 5 and multiplierStep is 0.5:
	//     lastMultiplier = 1 + (5 - 1) * 0.5 = 3
	const lastMultiplier = BASE_MULTIPLIER + (orderCount - 1) * multiplierStep;

	// Calculate the total sum of the multipliers from 1x up to the lastMultiplier.
	// This sum can be calculated using the arithmetic series formula:
	//     sum =  1 + 1.5 + ... + lastMultiplier
	//          = (firstMultiplier + lastMultiplier) * (orderCount / 2)
	// Since firstMultiplier is always BASE_MULTIPLIER (1), this simplifies to:
	//     totalMultiplier = (1 + lastMultiplier) * (orderCount / 2)
	const totalMultiplier = (BASE_MULTIPLIER + lastMultiplier) * (orderCount / 2);
	const totalMultiplierBigNum = BigNum.from(
		MULTIPLIER_PRECISION.muln(totalMultiplier), // ensure that we don't lose the decimal precisions
		MULTIPLIER_PRECISION_EXP
	);

	const baseSizePerMultiplier = totalOrderSize
		.shift(MULTIPLIER_PRECISION_EXP)
		.div(totalMultiplierBigNum)
		.div(orderStepSize) // divide and multiply by orderStepSize Math.floors the base size to the nearest orderStepSize
		.mul(orderStepSize);

	// orderMultipliers represents how many times the orderStepSize should be multiplied
	// They are distributed by 1 1.5 2 ... then rounding into nearest orderStepSize
	// Each value is a multiplier applied to the orderStepSize to determine the actual order size.
	// orderMultipliers[0] should be == BASE_MULTIPLIER
	const orderBaseSizes: BigNum[] = [];
	const basePrecision = orderStepSize.precision;
	let orderSizesSum = BigNum.from(0, basePrecision);

	for (let i = 0; i < orderCount; i++) {
		const multiplier = BASE_MULTIPLIER + multiplierStep * i;
		const multiplierBigNum = BigNum.from(
			MULTIPLIER_PRECISION.muln(multiplier),
			MULTIPLIER_PRECISION_EXP
		);
		const orderBaseSize = multiplierBigNum
			.mul(baseSizePerMultiplier)
			.shiftTo(orderStepSize.precision);

		orderBaseSizes.push(orderBaseSize);
		orderSizesSum = orderSizesSum.add(orderBaseSize);
	}

	const sizeDifference = orderSizesSum.sub(totalOrderSize);
	if (!sizeDifference.eqZero()) {
		orderBaseSizes[orderBaseSizes.length - 1] =
			orderBaseSizes[orderBaseSizes.length - 1].sub(sizeDifference);
	}
	orderBaseSizes.sort((a, b) => (a.sub(b).gteZero() ? 1 : -1));

	// Redistribution
	// In some cases, rounding can cause one or more orders to be rounded down to zero,
	// which is not a valid order size. This part of the code ensures that no order is zero
	// by redistributing values from larger orders to smaller ones (those that became zero).
	//
	// The algorithm sorts the orders in ascending order, then enters a loop where it
	// checks if the smallest order (orders[0]) is zero or less.
	//
	// If a zero or negative order is found, the largest order (orders[orderCount - 1])
	// is reduced by the amount needed to make the smallest order non-zero. This ensures that
	// the sum of the orders remains consistent while ensuring each order is at least 1.
	//
	// After making the adjustment, the orders are re-sorted to repeat the check and ensure
	// no orders remain zero or negative.
	//
	// Acknowledged: this algorithm is O(k * n log n), which isn't ideal.
	// However, since n <= 5, I prioritize readability over optimization,
	// as the performance impact is negligible for this small set.

	while (orderBaseSizes[0].lte(ZERO)) {
		orderBaseSizes[orderCount - 1] = orderBaseSizes[orderCount - 1].sub(
			orderStepSize.sub(orderBaseSizes[0])
		);
		orderBaseSizes[0] = orderStepSize;
		orderBaseSizes.sort((a, b) => (a.sub(b).gteZero() ? 1 : -1));
	}

	return orderBaseSizes;
}

export function getSplitOrders({
	totalSize,
	currentStepSize,
	orderCount,
	orderType,
	priceBoxValue,
	secondaryPriceBoxValue,
	selectedMarketInfo,
	sizeDistribution,
	tradeCurrency,
}: {
	totalSize: BigNum;
	currentStepSize: BigNum;
	orderCount: number;
	orderType: UIOrderType;
	priceBoxValue: number;
	secondaryPriceBoxValue: number;
	selectedMarketInfo: MarketInfo;
	sizeDistribution: 'flat' | 'ascending' | 'descending';
	tradeCurrency: string;
}): {
	size: BigNum;
	price: BigNum;
	percentage: BigNum;
	symbol: string;
}[] {
	if (orderType === 'scaledOrders' && selectedMarketInfo) {
		const scaledOrdersSizes = calculateScaledOrderSizes(
			sizeDistribution,
			totalSize,
			currentStepSize,
			orderCount
		);
		const scaledOrdersPrices = calculatePriceDistribution(
			NumLib.formatNum.toRawBn(priceBoxValue, PRICE_PRECISION),
			NumLib.formatNum.toRawBn(secondaryPriceBoxValue, PRICE_PRECISION),
			orderCount
		);
		return scaledOrdersSizes.map((size, index) => {
			return {
				size: size,
				price: BigNum.from(scaledOrdersPrices[index], PRICE_PRECISION_EXP),
				percentage: size
					.shift(totalSize.precision)
					.div(totalSize)
					.mul(new BN(100)),
				symbol: tradeCurrency,
			};
		});
	}
	return null;
}
