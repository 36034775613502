'use client';
import { PublicKey } from '@solana/web3.js';
import { useContext, useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useTokenAccountsStore } from 'src/stores/tokenAccounts/useTokenAccountsStore';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import { useState } from 'react';
import { useObservable } from 'react-use';
import { BehaviorSubject, Observable } from 'rxjs';
import { dlog } from 'src/dev';
import { OptimisedSubscriptionsContext } from 'src/providers/optimisedDataSubscriptions/optimisedSubscriptionsProvider';

/**
 * ⭐️⭐️⭐️
 * SEE README.md inside src/stores/tokenAccounts for more information about the overall architecture of how we track SPL token account balances
 * ⭐️⭐️⭐️
 */

const useSolBalanceGreaterThanZero = () => {
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	return solBalance?.gtZero?.() ?? false;
};

/**
 * This hook ensures we efficiently subscribe to updates of the token balance of a given token account address
 * @param tokenAccountAddress :: The token account address to subscribe to
 * @param subscriptionRefreshTicker :: Change the shallow equality value of this parameter to trigger a refresh of the token balance subscription
 * @returns
 */
const useSubscribedTokenAccountBalance = (
	tokenAccountAddress: string | null,
	subscriptionRefreshTicker: any
) => {
	const [tokenBalanceObservable, setTokenBalanceObservable] = useState<
		Observable<number>
	>(new BehaviorSubject<number>(undefined));

	const optimisedDataSubscriptionsProvider = useContext(
		OptimisedSubscriptionsContext
	);

	const connection = useDriftStore((s) => s.connection.current);

	useEffect(() => {
		let newObservable: Observable<number>;

		if (tokenAccountAddress === undefined) {
			// TODO DYNAMIC_TOKEN_ACCOUNTS_SUBSCRIPTION :: Currently the tokenAccountAddress will be undefined when the initial token account sync hook runs and an account doesn't exist. If we want to make this work then we may need to change this line
			dlog(
				`spl_subscription`,
				`blank_observable_while_loading_token_account_address`
			);
			newObservable = new BehaviorSubject<number>(undefined);
		} else if (tokenAccountAddress === null) {
			dlog(`spl_subscription`, `blank_observable_for_no_token_account_address`);
			newObservable = new BehaviorSubject<number>(0);
		} else {
			dlog(
				`spl_subscription`,
				`returning_good_observable :: ${tokenAccountAddress}`
			);

			newObservable =
				optimisedDataSubscriptionsProvider.subscriptionProvider.getTokenBalanceObservable(
					tokenAccountAddress,
					connection
				);
		}

		setTokenBalanceObservable(newObservable);
	}, [connection, tokenAccountAddress, subscriptionRefreshTicker]);

	const subscribedTokenBalance = useObservable(tokenBalanceObservable);

	return subscribedTokenBalance;
};

/**
 * Listen to the SPL balance of a given token. Listens to the currently connected wallet by default, otherwise an optional target wallet can be passed in
 *
 * 🚨 This will currently not work properly for tokens which are not Drift Spot Markets! Mainly because the `tokenAccountAddress` will not be stored inside the useSPLTokenAccountsStore
 * - The best solution to do this I think is to make this hook :
 * -- Wait for the lastSyncedWallet to change to the current wallet so that we know the token account addresses have been synced
 * -- Then, check if the token account address is in the useSPLTokenAccountsStore
 * -- If it is not, then we need to fetch the token account address using an RPC call (we can derive it from the token mint address)
 * -- Then, we can subscribe to the token account address
 * -- We could also update the useSPLTokenAccountsStore with the token account state that we fetched from the RPC call, so that future calls to this hook do not need to refetch the token account address
 *
 * @param tokenMintAddress
 * @param targetTokenAccountAddress
 * @param forceUpdate
 * @returns
 */
const useSPLTokenBalance = (tokenMintAddress: PublicKey): [number, boolean] => {
	const wallet = useCurrentWalletAdapter();
	const tokenAccountAddress = useTokenAccountsStore((s) =>
		s.getTokenAccount(tokenMintAddress.toBase58())?.pubkey.toBase58()
	);
	const initTokenBalance = useTokenAccountsStore(
		(s) => s.getTokenAccount(tokenMintAddress.toBase58())?.parsedBalance
	);
	const updateTokenAccountBalance = useTokenAccountsStore(
		(s) => s.updateTokenAccountBalance
	);
	const lastSyncedWallet = useTokenAccountsStore((s) => s.lastSyncedWallet);

	// Listen to SOL balance because when the SOL balance is zero, SPL update listeners don't work. So we want to refresh the SPL token listeners when the SOL balance newly becomes greater than zero
	const solBalanceGreaterThanZero = useSolBalanceGreaterThanZero();

	// The token balance should already be in the useSPLTokenAccountsStore, but we want to also subscribe for updates
	const subscribedTokenBalance = useSubscribedTokenAccountBalance(
		tokenAccountAddress,
		solBalanceGreaterThanZero
	);

	// Keep the stored token balance in the store up to date
	useEffect(() => {
		if (subscribedTokenBalance !== undefined && tokenAccountAddress) {
			updateTokenAccountBalance(
				tokenMintAddress.toBase58(),
				subscribedTokenBalance
			);
		}
	}, [subscribedTokenBalance, tokenAccountAddress]);

	// If the last synced wallet is not the current wallet then the token balance must still be loading
	const isLoading = lastSyncedWallet !== wallet?.publicKey?.toBase58();

	// Use the stored token balance in the store if the subscribed token balance is undefined (should only happen on initial load)
	const tokenBalance = subscribedTokenBalance ?? initTokenBalance;

	return [tokenBalance, isLoading];
};

export default useSPLTokenBalance;
