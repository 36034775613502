'use client';

import React, { useEffect } from 'react';
import { PnLTimeSeriesDataPoint } from 'src/@types/performance';
import { LoadingChart } from 'src/components/Charts/LoadingChart';
import { twMerge } from 'tailwind-merge';
import PnlChart from 'src/components/PnlChart';

const DISPLAY_DECIMALS = 5;

export const IfVaultPerformanceGraph = ({
	chartData,
	isHourly,
}: {
	chartData: PnLTimeSeriesDataPoint[];
	isHourly: boolean;
}) => {
	const [isDataLoaded, setIsDataLoaded] = React.useState(false);

	useEffect(() => {
		if (chartData.length > 0) {
			// timeout is to allow the chart to render at opacity-0 before fading in
			setTimeout(() => {
				setIsDataLoaded(true);
			}, 0);
		}
	}, [chartData]);

	if (chartData.length === 0) {
		return <LoadingChart />;
	}

	return (
		<PnlChart
			id="if-performance"
			data={chartData}
			field="totalPnl"
			label="Share Price"
			positive={false}
			decimalsToShow={DISPLAY_DECIMALS}
			isInModal={true}
			overrideNoBeginAtZero={true}
			className={twMerge(
				'min-h-[200px] max-h-[200px] transition-opacity duration-500',
				isDataLoaded ? 'opacity-100' : 'opacity-0'
			)}
			isHourly={isHourly}
		/>
	);
};
