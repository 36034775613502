import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { Token } from 'src/stores/types';
import useLstMetricsStore from 'src/stores/useLstMetricsStore';
import { SearchBar } from 'src/components/MarketSelectorDropdown/MarketsSearchBar';
import NumLib from 'src/utils/NumLib';
import ImageWithFallback from 'src/components/ImageWithFallback';
import Text from 'src/components/Text/Text';
import { LstMetrics } from '@drift/common';
import { useState } from 'react';
import { useMemo } from 'react';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useAuthorityTokenAccounts from 'src/hooks/useAuthorityTokens';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import UI_UTILS from 'src/utils/uiUtils';

const emptyIcon = <SkeletonValuePlaceholder className="w-8 h-8 rounded-full" />;

interface AssetSelectListProps {
	assetType: 'spotMarkets' | 'lsts' | 'spls';
	assetList: Token[];
	onSelect: (asset: Token) => void;
	source: 'wallet' | 'subaccount';
}

const AssetSelectList = ({
	assetList,
	onSelect,
	source,
}: AssetSelectListProps) => {
	const lstMetrics = useLstMetricsStore((s) => s.lstMetrics);
	const [searchValue, setSearchValue] = useState('');

	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const { authorityTokenAccounts, isLoading: isLoadingAuthorityTokenAccounts } =
		useAuthorityTokenAccounts(10000);

	const mappedLstMetrics = useMemo(() => {
		const mintMap: Record<string, LstMetrics> = {};
		Object.values(SPOT_MARKETS_LOOKUP).forEach((config) => {
			if (lstMetrics[config.marketIndex]) {
				mintMap[config.mint.toString()] = lstMetrics[config.marketIndex];
			}
		});
		return mintMap;
	}, [lstMetrics]);

	const assetListWithBalances = useMemo(() => {
		return assetList.map((asset) => {
			if (asset.ticker === 'SOL') {
				return {
					...asset,
					uiAmount: solBalance.toNum(),
				};
			}
			return {
				...asset,
				uiAmount: authorityTokenAccounts.find((t) => t.mint === asset.mint)
					?.tokenAmount.uiAmount,
				lstApy: mappedLstMetrics[asset.mint]?.lstPriceApy30d,
			};
		});
	}, [assetList, authorityTokenAccounts, source, mappedLstMetrics, solBalance]);

	const filteredAssets = useMemo(() => {
		if (!searchValue) return assetListWithBalances;

		const searchLower = searchValue.toLowerCase();
		const filtered = assetListWithBalances
			.filter((asset) => asset.ticker.toLowerCase().includes(searchLower))
			.sort((a, b) => {
				if (a.uiAmount === undefined && b.uiAmount !== undefined) {
					return 1;
				} else if (b.uiAmount === undefined && a.uiAmount !== undefined) {
					return -1;
				}

				return b.uiAmount - a.uiAmount;
			});

		return filtered;
	}, [assetListWithBalances, searchValue, authorityTokenAccounts]);

	return (
		<div className="flex flex-col max-h-[70vh] sm:max-h-[50vh]">
			<div className="mt-6 px-4">
				<SearchBar
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search by token name"
				/>
			</div>
			<div className="flex flex-row items-center justify-between gap-0.5 px-4 py-4 border-b border-container-border">
				<Text.BODY3 className="text-text-label">Token</Text.BODY3>
				<Text.BODY3 className="text-text-label">
					In {source === 'wallet' ? 'Wallet' : 'Subaccount'}
				</Text.BODY3>
			</div>
			<div className="flex flex-col gap-2 overflow-auto thin-scroll">
				{filteredAssets.map((asset) => {
					return (
						<div
							key={asset.ticker}
							className="flex justify-between items-center p-3 px-4 hover:bg-container-bg-hover cursor-pointer rounded text-text-default border-b border-container-border last:border-0"
							onClick={() => onSelect(asset)}
						>
							<div className="flex items-center gap-3 w-full justify-between">
								<div className="flex items-center gap-2">
									<ImageWithFallback
										src={asset.logo}
										className="w-8 h-8 rounded-full"
										loading="lazy"
										fallbackComponent={emptyIcon}
									/>
									<div className="flex flex-col items-start gap-1">
										<Text.BODY1>{asset.ticker}</Text.BODY1>
										<Text.MICRO3 className="text-text-label">
											{UI_UTILS.abbreviateAddress(asset.mint)}
										</Text.MICRO3>
									</div>
								</div>
								<div className="flex flex-col items-end justify-center gap-2 h-6">
									<div className="flex flex-col items-end gap-0.5">
										{isLoadingAuthorityTokenAccounts ? (
											<SkeletonValuePlaceholder className="w-16 h-4" />
										) : (
											!!asset.uiAmount && (
												<>
													<Text.BODY3>
														{NumLib.millify(asset.uiAmount).displayString}{' '}
														{asset.ticker}
													</Text.BODY3>
												</>
											)
										)}
									</div>
									{!!asset.notional && (
										<Text.BODY3>
											{NumLib.formatNum.toNotionalDisplay(asset.notional)}
										</Text.BODY3>
									)}
									{/* {!!asset.lstApy && (
										<span className="text-interactive-link bg-interactive-secondary-bg px-1">
											<Text.MICRO1>{asset.lstApy.toFixed(2)}%</Text.MICRO1> LST
										</span>
									)} */}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AssetSelectList;
