import { useMemo } from 'react';
import useAuthorityStakeAccounts from 'src/hooks/useAuthorityStakeAccounts';
import { useState } from 'react';
import { SearchBar } from 'src/components/MarketSelectorDropdown/MarketsSearchBar';
import Text from 'src/components/Text/Text';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { Open } from '@drift-labs/icons';
import UI_UTILS from 'src/utils/uiUtils';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { StakeAccountInfo } from 'src/@types/types';
import { twMerge } from 'tailwind-merge';
import InfoMessage from 'src/components/TradeForm/InfoMessage';

const DRIFT_VALIDATOR_VOTE_ACCOUNT =
	'DriFTm3wM9ugxhCA1K3wVQMSdC4Dv4LNmyZMmZiuHRpp';

const emptyIcon = (
	<div className="w-8 h-8 rounded-full overflow-hidden">
		<SkeletonValuePlaceholder className="w-full h-full" loading />
	</div>
);

const emptyRow = (
	<div className="flex justify-between items-center p-3 px-4 hover:bg-container-bg-hover cursor-pointer rounded text-text-default border-b border-container-border last:border-0">
		<div className="flex items-center gap-3 w-full justify-between">
			<div className="flex items-center gap-2">
				{emptyIcon}

				<div className="flex flex-col items-start gap-1">
					<SkeletonValuePlaceholder loading className="w-[180px] h-4" />
					<SkeletonValuePlaceholder loading className="w-16 h-3" />
				</div>
			</div>
			<div className="flex flex-col items-end gap-2 min-h-4">
				<SkeletonValuePlaceholder loading className="w-16 h-4" />
			</div>
		</div>
	</div>
);

const StakeAccountsList = ({
	onSelect,
}: {
	onSelect: (selectedStakeAccount: StakeAccountInfo) => void;
}) => {
	const { stakeAccounts, isLoading, validatorsLoading } =
		useAuthorityStakeAccounts();

	const [searchValue, setSearchValue] = useState('');

	const filteredStakeAccounts = useMemo(() => {
		if (!searchValue) return stakeAccounts;

		const searchLower = searchValue.toLowerCase();
		return stakeAccounts
			.filter(
				(stakeAccount) =>
					stakeAccount.validatorName.toLowerCase().includes(searchLower) ||
					stakeAccount.validatorPubKeyString.includes(searchLower)
			)
			.sort((a, b) => {
				if (a.validatorPubKeyString === DRIFT_VALIDATOR_VOTE_ACCOUNT) return -1;
				if (b.validatorPubKeyString === DRIFT_VALIDATOR_VOTE_ACCOUNT) return 1;
				return 0;
			});
	}, [stakeAccounts, searchValue]);

	return (
		<div className="flex flex-col gap-4 max-h-[70vh] sm:max-h-[50vh]">
			<div className="mt-6 px-4">
				<SearchBar
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search by validator name"
				/>
			</div>
			<div className="px-4">
				<InfoMessage
					type="info"
					message={
						<Text.BODY3>
							Only stake accounts with the Drift validator are available for
							conversion to dSOL at this time.
						</Text.BODY3>
					}
				/>
			</div>
			<div className="flex flex-col gap-2 overflow-auto thin-scroll min-h-[300px] text-text-label">
				{isLoading ? (
					<>
						{emptyRow}
						{emptyRow}
						{emptyRow}
					</>
				) : filteredStakeAccounts.length === 0 ? (
					<div className="flex items-center justify-center min-h-[300px] h-full w-full">
						<Text.BODY1 className="text-text-label">
							No active stake accounts found
						</Text.BODY1>
					</div>
				) : (
					filteredStakeAccounts.map((stakeAccount) => {
						const availableForSwap =
							stakeAccount.validatorPubKeyString ===
							DRIFT_VALIDATOR_VOTE_ACCOUNT;

						return (
							<div
								key={stakeAccount.pubKeyString}
								className={twMerge(
									'flex justify-between items-center p-3 px-4 rounded text-text-default border-b border-container-border last:border-0',
									availableForSwap
										? 'hover:bg-container-bg-hover cursor-pointer'
										: 'opacity-50'
								)}
								onClick={
									availableForSwap ? () => onSelect(stakeAccount) : undefined
								}
							>
								<div className="flex items-center gap-3 w-full justify-between">
									<div className="flex items-center gap-2">
										{validatorsLoading || !stakeAccount.icon ? (
											emptyIcon
										) : (
											<ImageWithFallback
												src={stakeAccount.icon}
												className="w-8 h-8 rounded-full"
												loading="lazy"
												fallbackComponent={emptyIcon}
											/>
										)}

										<div className="flex flex-col items-start gap-1">
											<Text.BODY1 className="max-w-[150px] md:max-w-[240px] truncate">
												{validatorsLoading ? (
													<SkeletonValuePlaceholder
														loading
														className="w-[180px] h-4"
													/>
												) : (
													stakeAccount.validatorName ||
													stakeAccount.pubKeyString
												)}
											</Text.BODY1>
											<Text.MICRO3 className="text-text-label">
												<a
													href={UI_UTILS.getUrlForAccount(
														stakeAccount.validatorPubKeyString
													)}
													target="_blank"
													onClick={(e) => e.stopPropagation()}
													rel="noreferrer"
													className="flex items-center gap-1 text-text-label"
												>
													{UI_UTILS.abbreviateAddress(
														stakeAccount.validatorPubKeyString
													)}
													<Open size={14} />
												</a>
											</Text.MICRO3>
										</div>
									</div>
									<div className="flex flex-col items-end gap-2 min-h-4">
										{!!stakeAccount.solBalance && (
											<Text.BODY1>
												{stakeAccount.solBalance.toMillified()} SOL
											</Text.BODY1>
										)}
									</div>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default StakeAccountsList;
