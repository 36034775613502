'use client';

import LightningIcon2 from 'src/components/Icons/LightningIcon2';
import Text from 'src/components/Text/Text';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import React, {
	CSSProperties,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftTheme from '../../hooks/useDriftTheme';
import useOrderedMarkets from '../../hooks/useOrderedMarkets';
import RoundedGradientBorderBox from '../RoundedGradientBorderBox';
import MarketIcon from '../Utils/MarketIcon';
import { MarketId } from '@drift/common';
import { Menu, Close, Star, Hotified } from '@drift-labs/icons';
import { PriceChangePercentDisplay } from '../PerpPageStats/PriceChangePercentDisplay';
import useMarkPrice from 'src/hooks/useMarkPrice';
import { twMerge } from 'tailwind-merge';
import { allColors } from 'src/constants/figmaGenerated';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import useDevSwitchIsOn from '../../hooks/useDevSwitchIsOn';

type MarketBarMarket = {
	marketId: MarketId;
	marketIndex: number;
	baseAssetSymbol: string;
	isNew: boolean;
	isFavourited: boolean;
	isHot: boolean;
};

const ChipWrapper = (
	props: PropsWithChildren<{ style?: CSSProperties; hoverText?: string }>
) => (
	<div
		//@ts-ignore
		style={{ ...props.style, width: '12px', height: '12px' }}
		className="flex items-center justify-center border rounded-full border-container-border bg-container-border"
		title={props.hoverText}
	>
		{props.children}
	</div>
);

const FavouriteChip = () => (
	<ChipWrapper hoverText="Market is favourited">
		<Star size={12} color={allColors.yellow[50]} />
	</ChipWrapper>
);

const NewChip = (props: { style?: CSSProperties }) => (
	<ChipWrapper style={props.style} hoverText="Market is new">
		<LightningIcon2 style={{ width: '9px', height: '9px' }} />
	</ChipWrapper>
);

const HotChip = (props: { style?: CSSProperties }) => (
	<ChipWrapper
		style={props.style}
		hoverText="Market is experiencing increased volume"
	>
		<Hotified size={11} />
	</ChipWrapper>
);

const MarketChips = ({
	isFavourited,
	isNew,
	isHot,
}: {
	isFavourited: boolean;
	isNew: boolean;
	isHot: boolean;
}) => {
	const chipsToRender = [
		isNew && {
			key: 'isNew',
			el: <NewChip />,
		},
		isFavourited && { key: 'isFavourites', el: <FavouriteChip /> },
		isHot && { key: 'isHot', el: <HotChip /> },
	].filter((a) => !!a);

	return (
		<div
			className={`absolute flex items-start -bottom-1 -translate-x-[6px] flex-shrink-0`}
		>
			{chipsToRender.map((chip, index) => (
				<div
					key={chip.key}
					style={{
						zIndex: index,
						right: `${index * 5}px`,
						position: 'relative',
					}}
				>
					{chip.el}
				</div>
			))}
		</div>
	);
};

export type MarketButtonProps = {
	marketBarMarket: MarketBarMarket;
	selected: boolean;
	onSelect: (market: MarketId) => void;
	center?: boolean;
	autoWidth?: boolean;
	hidden?: boolean;
};

const PriceChange24hDisplay = (props: { marketId: MarketId }) => {
	const markPrice = useMarkPrice(props.marketId);
	return (
		<PriceChangePercentDisplay
			marketId={props.marketId}
			markPrice={markPrice}
		/>
	);
};

const MarketButton = (props: MarketButtonProps) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const buttonElement = useRef<HTMLDivElement>(null);
	const theme = useDriftTheme();

	const showBorder =
		theme !== DriftTheme.dark && (props.selected || showDropdown);

	return (
		<div
			className="relative z-50"
			onMouseLeave={() => setShowDropdown(false)}
			ref={buttonElement}
		>
			<RoundedGradientBorderBox
				borderColour={showBorder ? 'var(--app-gradient)' : 'none'}
				borderHoverClass={showBorder ? 'var(--app-gradient)' : 'none'}
				className="relative"
				borderWidth="2px"
			>
				<div
					className={twMerge(
						'bg-container-bg hover:bg-container-bg-hover relative select-none max-h-[28px] py-2 flex items-center text-md space-x-2 hover:cursor-pointer',
						props.autoWidth ? 'pl-4 pr-2' : 'w-full px-3',
						props.center && 'justify-center',
						props.selected ? 'text-text-emphasis' : 'text-text-label',
						props.hidden && 'invisible'
					)}
					style={{ flexShrink: '0' }}
					id={`marketButton${props.marketBarMarket.baseAssetSymbol}`}
					onMouseEnter={() => setShowDropdown(true)}
					onClick={() => {
						props.onSelect(props.marketBarMarket.marketId);
					}}
				>
					<div className="relative w-[18px]">
						<MarketIcon
							marketSymbol={props.marketBarMarket.baseAssetSymbol}
							sizeClass={`h-[18px] w-[18px]`}
							customHeight={18}
							customWidth={18}
							grey={!props.selected}
						/>
						<MarketChips
							isNew={props.marketBarMarket.isNew}
							isFavourited={props.marketBarMarket.isFavourited}
							isHot={props.marketBarMarket.isHot}
						/>
					</div>
					<div className="mt-0.5">
						<Text.BODY3>{props.marketBarMarket.baseAssetSymbol}</Text.BODY3>
					</div>
					<div className="mt-0.5 min-w-[50px]">
						<Text.BODY3>
							<PriceChange24hDisplay
								marketId={props.marketBarMarket.marketId}
							/>
						</Text.BODY3>
					</div>
				</div>
			</RoundedGradientBorderBox>
		</div>
	);
};

const MarketBar = () => {
	const [hiddenMarketsStartIndex, setHiddenMarketsStartIndex] =
		useState(Infinity);
	const [showExpandButton, setShowExpandButton] = useState(true);
	const [expanded, setExpanded] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const innerBarRef = useRef<HTMLDivElement>(null);

	const currentlySelectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const currentlySelectedMarketType = useDriftStore(
		(s) => s.selectedMarket.current.marketTypeStr
	);

	const devSwitchIsOn = useDevSwitchIsOn();

	const getMarketHasLiquidity = useMarketStateStore(
		(s) => s.getMarketHasLiquidity
	);

	const actions = useDriftActions();

	const switchMarket = actions.switchMarket;

	const handleSelection = (marketId: MarketId) => {
		switchMarket({
			marketIndex: marketId.marketIndex,
			marketType: marketId.marketType,
		});
		handleClickedCollapseButton();
	};

	const handleCollapse = (event: MouseEvent) => {
		// If event comes from inside of wrapperRef, don't collapse
		let node = event.target as HTMLElement;
		while (node) {
			if (node === wrapperRef.current) {
				return;
			}
			node = node.parentNode as HTMLElement;
		}

		setExpanded(false);
		document.body.removeEventListener('click', handleCollapse);
	};

	const handleClickedCollapseButton = useCallback(() => {
		setExpanded(false);
	}, []);

	const handleExpand = () => {
		setExpanded(true);
		setTimeout(() => {
			document.body.addEventListener('click', handleCollapse);
		}, 0);
	};

	const sortedMarkets = useOrderedMarkets(
		currentlySelectedMarketId?.marketType
	);

	const marketBarMarkets: MarketBarMarket[] = useMemo(() => {
		const marketBarInfo = sortedMarkets
			.map((mktInfo) => ({
				marketIndex: mktInfo.marketIndex,
				marketId: new MarketId(mktInfo.marketIndex, mktInfo.marketType),
				baseAssetSymbol: mktInfo.baseAssetSymbol,
				isNew: mktInfo.isNew,
				isFavourited: mktInfo.isFavourited,
				isHot: mktInfo.isHot,
			}))
			.filter(
				(mktBarInfo) =>
					devSwitchIsOn || getMarketHasLiquidity(mktBarInfo.marketId)
			);

		return marketBarInfo;
	}, [sortedMarkets, currentlySelectedMarketId, devSwitchIsOn]);

	const marketButtons = useMemo(
		() =>
			marketBarMarkets.map((marketInfo, index) => (
				<MarketButton
					key={`${marketInfo.baseAssetSymbol}_${index}`}
					marketBarMarket={marketInfo}
					selected={currentlySelectedMarketId.key === marketInfo.marketId.key}
					onSelect={handleSelection}
					center
					autoWidth
					hidden={expanded && index >= hiddenMarketsStartIndex}
				/>
			)),
		[marketBarMarkets, hiddenMarketsStartIndex, expanded]
	);

	// when innerBarRef's children are wider than wrapperBarRef, show button to expand
	const handleWindowResize = useCallback(() => {
		const wrapperBoundingRect = wrapperRef.current?.getBoundingClientRect();
		let childrenWidth = 0;
		let hiddenMarketsStartIndex = 0;

		if (innerBarRef.current?.children?.length) {
			[...innerBarRef.current.children].forEach((node) => {
				if (node) {
					childrenWidth += node.getBoundingClientRect().width;
					if (childrenWidth < wrapperBoundingRect.width - 20) {
						hiddenMarketsStartIndex += 1;
					}
				}
			});
		}

		setHiddenMarketsStartIndex(hiddenMarketsStartIndex);

		if (wrapperBoundingRect.width > childrenWidth && showExpandButton) {
			setShowExpandButton(false);
			setExpanded(false);

			document.body.removeEventListener('click', handleCollapse);
		}
		if (
			wrapperBoundingRect.width < childrenWidth &&
			!showExpandButton &&
			!expanded
		) {
			setShowExpandButton(true);
		}
	}, [
		showExpandButton,
		marketButtons,
		expanded,
		innerBarRef.current?.children?.length,
	]);

	useEffect(() => {
		// Wait for next render cycle - ensures that marketButtons are rendered
		const timer = setTimeout(() => {
			handleWindowResize();
		}, 0);

		return () => clearTimeout(timer);
	}, [marketButtons, handleWindowResize]);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [showExpandButton, marketButtons, expanded]);

	useEffect(() => {
		handleWindowResize();
	}, [currentlySelectedMarketType]);

	return (
		<div
			className="relative h-8 overflow-visible border-none bg-container-bg"
			ref={wrapperRef}
		>
			{/* {!expanded && ( */}
			<div className="relative z-50 w-full overflow-hidden border rounded border-container-border">
				<div className="flex w-full" ref={innerBarRef}>
					{marketButtons}
				</div>
				{showExpandButton && (
					<div
						className="absolute right-0 top-0 h-full flex w-[40px] bg-gradient-to-r from-transparent to-50% to-button-secondary-bg hover:to-button-secondary-bg-hover z-100 pr-1 items-center justify-end cursor-pointer select-none"
						onClick={expanded ? handleClickedCollapseButton : handleExpand}
					>
						{expanded ? (
							<Close
								size={18}
								className="rotate-90"
								color={'var(--text-label'}
							/>
						) : (
							<Menu
								size={18}
								color={'var(--text-label'}
								className="rotate-90"
							/>
						)}
					</div>
				)}
			</div>
			{expanded && (
				<div className="absolute z-50 right-0 top-[100%] rounded border border-container-border bg-container-bg shadow-xl pt-2 h-auto">
					<div className="flex flex-col thin-scroll overflow-scroll max-h-[600px]">
						{marketBarMarkets
							.slice(hiddenMarketsStartIndex, marketBarMarkets.length)
							.map((marketInfo) => (
								<MarketButton
									key={marketInfo.baseAssetSymbol}
									marketBarMarket={marketInfo}
									selected={
										currentlySelectedMarketId.key === marketInfo.marketId.key
									}
									onSelect={handleSelection}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(MarketBar);
