'use client';

import { useSheetsStore } from 'src/stores/useSheetsStore';
import Sheet from '../Modals/Sheet';
import Text from '../Text/Text';
import MarketIcon from '../Utils/MarketIcon';
import {
	CurrentSpotMarkets,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import useSafePush from 'src/hooks/useSafePush';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useSetCollateralModalParams from 'src/hooks/useSetCollateralModalParams';

const sheetName = 'balanceCardMore';

export default function BalanceCardMoreSheet() {
	const safePush = useSafePush();
	const marketIndex = useSheetsStore((s) => s[sheetName].marketIndex);
	const handleCloseSheet = useSheetsStore((s) => s.handleCloseSheet);
	const marketSymbol = SPOT_MARKETS_LOOKUP[marketIndex].symbol;
	const currentAccountUserKey = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey].userKey
	);

	const setCollateralModalParams = useSetCollateralModalParams();

	const handleSwap = () => {
		handleCloseSheet(sheetName);

		if (marketSymbol === 'USDC') {
			safePush('/swap');
		} else {
			safePush(`/swap/${marketSymbol}-USDC`);
		}
	};

	const handleTransfer = () => {
		handleCloseSheet(sheetName);

		setCollateralModalParams({
			modalName: 'showCollateralTransferModal',
			collateralTypeIndex: marketIndex,
			targetAccountKey: currentAccountUserKey,
			poolId: CurrentSpotMarkets[marketIndex].poolId,
		});
	};

	const handleBorrow = () => {
		handleCloseSheet(sheetName);

		setCollateralModalParams({
			modalName: 'showBorrowModal',
			collateralTypeIndex: marketIndex,
			targetAccountKey: currentAccountUserKey,
			poolId: CurrentSpotMarkets[marketIndex].poolId,
		});
	};

	const handleWithdraw = () => {
		handleCloseSheet(sheetName);

		setCollateralModalParams({
			modalName: 'showWithdrawModal',
			collateralTypeIndex: marketIndex,
			targetAccountKey: currentAccountUserKey,
			poolId: CurrentSpotMarkets[marketIndex].poolId,
		});
	};

	const handleDeposit = () => {
		handleCloseSheet(sheetName);

		setCollateralModalParams({
			modalName: 'showDepositModal',
			collateralTypeIndex: marketIndex,
			targetAccountKey: currentAccountUserKey,
			poolId: CurrentSpotMarkets[marketIndex].poolId,
		});
	};

	return (
		<Sheet sheetName={sheetName}>
			<Sheet.Header sheetName={sheetName}>
				<div className="flex flex-row items-center space-x-3">
					<MarketIcon marketSymbol={marketSymbol} />
					<Text.BODY2 className="text-text-default">{marketSymbol}</Text.BODY2>
				</div>
			</Sheet.Header>
			<div className="w-full p-2 text-text-default flex flex-col space-y-1 pb-4">
				<button onClick={handleDeposit} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Deposit</Text.BODY2>
				</button>
				<button onClick={handleWithdraw} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Withdraw</Text.BODY2>
				</button>
				<button onClick={handleBorrow} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Borrow</Text.BODY2>
				</button>
				<button onClick={handleTransfer} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Transfer</Text.BODY2>
				</button>
				<button onClick={handleSwap} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Swap</Text.BODY2>
				</button>
			</div>
		</Sheet>
	);
}
