'use client';

import { useTheme } from 'next-themes';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import { MARKET_ICON_PATH } from 'src/constants/constants';
import { COMMON_UI_UTILS } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import { CSSProperties } from 'react';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { PredictionMarketIcon } from 'src/components/PredictionMarkets/PredictionMarketIcon';

const DEFAULT_SIZE = 16;

// Markets in here have base icons that have theme variants (e.g. apt-dark.svg, apt-light.svg)
const BASE_MARKET_SYMBOLS_WITH_THEME_VARIANTS_LIST = ['apt'];

// Markets in here use webp icons for all variants (i.e. base icon, grey dark/light icons)
const MARKET_SYMBOLS_USING_WEBP_LIST = ['wif', 'pepe'];

// Markets in here are a 1:1 mapping to their updated file name (e.g. sol_grey-dark: sol_grey-dark-v1.svg)
const ORIGINAL_FILE_NAME_TO_UPDATED_FILE_NAME_LOOKUP = {
	jlp: 'jlp-v1.svg',
	bonk: 'bonk.webp', // we want themed variants of bonks to still use svg
	inf: 'inf-v1.webp',
	cbbtc: 'cbbtc-v1.svg',
};

type MarketIconFileNameParams = {
	baseSymbol: string;
	grey?: boolean;
	theme?: DriftTheme;
};

const getMarketIconFileName = ({
	baseSymbol,
	grey,
	theme = DriftTheme.dark,
}: MarketIconFileNameParams) => {
	let originalFileName = `${baseSymbol.toLowerCase()}${
		grey ? `_grey-${theme}` : ''
	}`;

	if (
		BASE_MARKET_SYMBOLS_WITH_THEME_VARIANTS_LIST.includes(baseSymbol) &&
		!grey
	) {
		originalFileName = `${baseSymbol}-${theme}`;
	}

	if (ORIGINAL_FILE_NAME_TO_UPDATED_FILE_NAME_LOOKUP[originalFileName]) {
		return ORIGINAL_FILE_NAME_TO_UPDATED_FILE_NAME_LOOKUP[originalFileName];
	}

	if (MARKET_SYMBOLS_USING_WEBP_LIST.includes(baseSymbol)) {
		return `${originalFileName}.webp`;
	}

	return `${originalFileName}.svg`;
};

export const getMarketIconSrc = ({
	baseSymbol,
	grey,
	theme = DriftTheme.dark,
	useProxy,
}: MarketIconFileNameParams & { useProxy?: boolean }) => {
	const fileName = getMarketIconFileName({ baseSymbol, grey, theme });
	const src = useProxy
		? `/api/market-icon?path=${fileName}`
		: `${MARKET_ICON_PATH}/${fileName}`;
	return src;
};

export const TokenPairLogos = ({
	fromTokenSymbol,
	toTokenSymbol,
	size,
	leftImgFront,
	useProxy,
}: {
	fromTokenSymbol: string;
	toTokenSymbol: string;
	size?: number;
	leftImgFront?: boolean;
	useProxy?: boolean;
}) => {
	const theme = (useTheme().theme as DriftTheme) ?? DriftTheme.dark;

	if (!fromTokenSymbol || !toTokenSymbol) return null;

	const fromTokenLogo = getMarketIconSrc({
		baseSymbol: fromTokenSymbol,
		theme,
		grey: false,
		useProxy,
	});
	const toTokenLogo = getMarketIconSrc({
		baseSymbol: toTokenSymbol,
		theme,
		grey: false,
		useProxy,
	});

	return (
		<div className="flex flex-row">
			<div>
				<img
					src={fromTokenLogo}
					className={twMerge('rounded-full', leftImgFront && 'relative z-10')}
					width={size ? size : DEFAULT_SIZE}
				/>
			</div>
			<div className="-ml-[20%]">
				<img
					src={toTokenLogo}
					className="rounded-full"
					width={size ? size : DEFAULT_SIZE}
				/>
			</div>
		</div>
	);
};

const MarketIcon = ({
	marketSymbol,
	className,
	grey,
	sizeClass,
	customHeight,
	customWidth,
	isSpot,
	style,
	useProxy,
}: {
	marketSymbol: string;
	className?: string;
	grey?: boolean;
	sizeClass?: string;
	customHeight?: number;
	customWidth?: number;
	isSpot?: boolean;
	style?: CSSProperties;
	useProxy?: boolean;
}) => {
	const baseSymbol = COMMON_UI_UTILS.getBaseAssetSymbol(
		marketSymbol,
		true
	).toLowerCase();
	const theme = (useTheme().theme as DriftTheme) ?? DriftTheme.dark;

	// displays as pair logo between base token and USDC token
	if (isSpot) {
		return (
			<TokenPairLogos
				fromTokenSymbol={baseSymbol}
				toTokenSymbol="usdc"
				size={customWidth ?? 14}
				leftImgFront
				useProxy={useProxy}
			/>
		);
	}

	const src = getMarketIconSrc({
		baseSymbol,
		theme,
		grey,
		useProxy,
	});

	const predictionMarketConfig =
		PredictionMarketConfigs.getConfigFromMarketSymbol(marketSymbol);

	if (predictionMarketConfig) {
		// apparently it needs both the classname and height/width to work for pngs and svgs
		return (
			<PredictionMarketIcon
				symbol={predictionMarketConfig.symbol}
				className={twMerge(sizeClass ?? 'h-[18px] w-[18px]', className)}
				small
			/>
		);
	}

	// Apparently it needs both the classname and height/width to work for pngs and svgs
	return (
		<img
			src={src}
			className={twMerge(sizeClass ?? 'h-[18px] w-[18px]', className)}
			width={customWidth ?? 18}
			height={customHeight ?? 18}
			alt={`${marketSymbol} icon`}
			style={style}
		/>
	);
};

export default MarketIcon;
