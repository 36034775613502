'use client';

import {
	EventSubscriber,
	EventSubscriptionOptions,
	EventType,
	PublicKey,
	WrappedEvent,
} from '@drift-labs/sdk';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { dlog } from '../dev';
import Env, {
	EventSubscriberType,
} from '../environmentVariables/EnvironmentVariables';
import useCurrentSettings from '../hooks/useCurrentSettings';
import useCurrentWalletAdapter from '../hooks/useCurrentWalletAdapter';
import useUserAccountPubKey from '../hooks/useUserAccountPubKey';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import { Commitment } from '@solana/web3.js';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';
import { useSettingIsDefaultValue } from './settingsProvider';

/**
 * This provider is responsible for providing the drift blocking event subscribers to the rest of the app. It does NOT manage the actual subscriptions.
 */

const WEBSOCKET_RESUBSCRIBE_TIMEOUT_MS = 2_000;
const WEBSOCKET_ALLOWED_RETRY_ATTEMPTS = 3;
const COMMITMENT_LEVEL = DEFAULT_COMMITMENT_LEVEL;

const USER_EVENTS_TO_LISTEN_TO: EventType[] = [
	'DepositRecord',
	'FundingPaymentRecord',
	'LiquidationRecord',
	'OrderRecord',
	'SettlePnlRecord',
	'SwapRecord',
	'OrderActionRecord', // NOTE : The global event listener will catch the FILL records, but we still need to subscribe to order action records for the OTHER actions (like place, cancel, etc.) for the sake of keeping market order toasts in sync
];

type EventSubscriberContextType = {
	ready: boolean;
	userEventSubscriber?: EventSubscriber;
	globalFillEventSubscriber?: EventSubscriber;
	commitment: Commitment;
};

const INITIAL_CONTEXT_VALUE: EventSubscriberContextType = {
	ready: false,
	userEventSubscriber: undefined,
	globalFillEventSubscriber: undefined,
	commitment: COMMITMENT_LEVEL,
};

/**
 * userEventSubscriber: EventSubscriber for the current user's events
 * globalFillEventSubscriber: EventSubscriber for market-wide order action events
 */
export const DriftBlockchainEventSubscriberContext =
	React.createContext<EventSubscriberContextType>(INITIAL_CONTEXT_VALUE);

const getEventsServerSubscriberProps = (
	address?: PublicKey,
	pollingMultiplier = 1,
	eventTypes?: EventType[]
): EventSubscriptionOptions => {
	if (Env.eventsServerToUse) {
		return {
			commitment: COMMITMENT_LEVEL,
			logProviderConfig: {
				type: 'events-server',
				url: Env.eventsServerToUse,
				maxReconnectAttempts: WEBSOCKET_ALLOWED_RETRY_ATTEMPTS,
				fallbackFrequency: Env.pollingFrequencyMs * pollingMultiplier, // The websocket provider falls back to polling when it detects issues
			},
			address,
			eventTypes,
		};
	} else {
		dlog(
			`missing_events_server_url`,
			`falling_back_to_websocket_event_subscriber`
		);
		return getWebsocketSubscriberProps(address, pollingMultiplier, eventTypes);
	}
};

const getWebsocketSubscriberProps = (
	address?: PublicKey,
	pollingMultiplier = 1,
	eventTypes?: EventType[]
): EventSubscriptionOptions => {
	return {
		maxTx: 128,
		maxEventsPerType: 256,
		orderBy: 'blockchain',
		commitment: COMMITMENT_LEVEL,
		logProviderConfig: {
			type: 'websocket',
			resubTimeoutMs: WEBSOCKET_RESUBSCRIBE_TIMEOUT_MS,
			maxReconnectAttempts: WEBSOCKET_ALLOWED_RETRY_ATTEMPTS,
			fallbackFrequency: Env.pollingFrequencyMs * pollingMultiplier, // The websocket provider falls back to polling when it detects issues
		},
		address,
		eventTypes,
	};
};

const getPollingSubscriberProps = (
	address?: PublicKey,
	pollingMultiplier = 1,
	eventTypes?: EventType[]
): EventSubscriptionOptions => {
	return {
		maxTx: 128,
		maxEventsPerType: 256,
		orderBy: 'blockchain',
		commitment: COMMITMENT_LEVEL,
		logProviderConfig: {
			type: 'polling',
			frequency: Env.pollingFrequencyMs * pollingMultiplier,
		},
		address,
		eventTypes,
	};
};

const DriftBlockchainEventSubscriberProvider = (
	props: PropsWithChildren<any>
) => {
	const connection = useDriftStore((s) => s.connection.current);
	const selectedRpc = useDriftStore((s) => s.connection.rpc);

	const driftClient = useDriftStore((s) => s.driftClient.client);
	const currentAuthority = useCurrentWalletAdapter().publicKey;
	const currentUserAccount = useUserAccountPubKey();
	const walletIsConnected = useWalletIsConnected();
	const pollingMultiplier = useDriftStore((s) => s.pollingMultiplier);

	const [contextValue, setContextValue] = useState(INITIAL_CONTEXT_VALUE);

	const eventSubscriberSetting = useCurrentSettings()[0].eventSubscriberType;
	const eventSubscriberSettingIsDefault = useSettingIsDefaultValue(
		'eventSubscriberType'
	);

	const shouldUseEventsServerOverride = () => {
		return (
			useFeatureFlagStore
				.getState()
				.flagEnabled('PREFER_EVENTS_SERVER_EVENTS') &&
			eventSubscriberSettingIsDefault
		);
	};

	const createSelfEventSubscriber = (
		eventSubscriberSetting: EventSubscriberType,
		address: PublicKey
	) => {
		const useEventsServer =
			eventSubscriberSetting === 'events-server' ||
			shouldUseEventsServerOverride();

		const useWebsocket =
			!useEventsServer &&
			eventSubscriberSetting === 'websocket' &&
			selectedRpc?.wsValue;

		let eventSubscriber: EventSubscriber;

		const strategyToUse = useEventsServer
			? 'events-server'
			: useWebsocket
			? 'websocket'
			: 'polling';

		switch (strategyToUse) {
			case 'events-server': {
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getEventsServerSubscriberProps(
						address,
						pollingMultiplier,
						USER_EVENTS_TO_LISTEN_TO
					)
				);
				break;
			}
			case 'websocket': {
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getWebsocketSubscriberProps(
						address,
						pollingMultiplier,
						USER_EVENTS_TO_LISTEN_TO
					)
				);
				break;
			}
			case 'polling': {
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getPollingSubscriberProps(
						address,
						pollingMultiplier,
						USER_EVENTS_TO_LISTEN_TO
					)
				);
				break;
			}
		}

		eventSubscriber.subscribe();

		return eventSubscriber;
	};

	const createGlobalOrderActionRecordEventSubscriber = (
		eventSubscriberSetting: EventSubscriberType
	) => {
		const useEventsServer =
			eventSubscriberSetting === 'events-server' ||
			shouldUseEventsServerOverride();

		const useWebsocket =
			!useEventsServer &&
			eventSubscriberSetting === 'websocket' &&
			selectedRpc?.wsValue;

		const strategyToUse = useEventsServer
			? 'events-server'
			: useWebsocket
			? 'websocket'
			: 'polling';

		let eventSubscriber: EventSubscriber;

		switch (strategyToUse) {
			case 'events-server': {
				dlog(
					`optimised_event_subscriptions`,
					`creating_events_server_blockchain_global_event_subscriber`
				);
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getEventsServerSubscriberProps(undefined, pollingMultiplier, [
						'OrderActionRecord',
					])
				);
				break;
			}
			case 'websocket': {
				dlog(
					`optimised_event_subscriptions`,
					`creating_websocket_blockchain_global_event_subscriber`
				);
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getWebsocketSubscriberProps(undefined, pollingMultiplier, [
						'OrderActionRecord',
					])
				);
				break;
			}
			case 'polling': {
				dlog(
					`optimised_event_subscriptions`,
					`creating_blockchain_blockchain_global_event_subscriber`
				);
				eventSubscriber = new EventSubscriber(
					connection,
					driftClient.program,
					getPollingSubscriberProps(undefined, pollingMultiplier, [
						'OrderActionRecord',
					])
				);
			}
		}

		eventSubscriber.subscribe();

		return eventSubscriber;
	};

	// The params that subscribing depend on can change in bursts, but we don't want to subscribe+unsubscribe in quick bursts because it's unnecessary. For this reason we're going to make a ticker to sync with these params, and only update it with a debounced hook.
	const [subscribeUpdateTicker, setSubscribeUpdateTicker] = useState([]);

	// The debounced hook which can only update the ticker at a maximum speed
	useDebounce(
		() => {
			setSubscribeUpdateTicker([]);
		},
		1000,
		[
			connection,
			driftClient,
			walletIsConnected && currentAuthority, // To prevent wasteful (un)subscribes , we only actually need to update the subscriber if the combination of these changes
			eventSubscriberSetting,
			pollingMultiplier,
			currentUserAccount,
		]
	);

	useEffect(() => {
		if (!connection) return;
		if (!driftClient) return;
		if (!driftClient.isSubscribed) return;

		const selfEventSubscriber = currentUserAccount
			? createSelfEventSubscriber(eventSubscriberSetting, currentUserAccount)
			: undefined;

		const globalOrderActionRecordEventSubscriber =
			createGlobalOrderActionRecordEventSubscriber(eventSubscriberSetting);

		setContextValue({
			ready: true,
			userEventSubscriber: selfEventSubscriber,
			globalFillEventSubscriber: globalOrderActionRecordEventSubscriber,
			commitment: COMMITMENT_LEVEL,
		});

		return () => {
			if (selfEventSubscriber) selfEventSubscriber.unsubscribe();
			globalOrderActionRecordEventSubscriber.unsubscribe();
		};
	}, [subscribeUpdateTicker, eventSubscriberSetting]);

	// Log events coming through the global fill event subscriber
	useEffect(() => {
		if (contextValue.globalFillEventSubscriber) {
			const handler = (_event: WrappedEvent<EventType>) => {
				dlog(
					`optimised_event_subscriptions`,
					`received::global_fill_event_from_blockchain_event_subscriber`
				);
			};

			contextValue.globalFillEventSubscriber.eventEmitter.on(
				'newEvent',
				handler
			);

			return () => {
				contextValue.globalFillEventSubscriber.eventEmitter.off(
					'newEvent',
					handler
				);
			};
		}
	}, [contextValue.globalFillEventSubscriber]);

	// Log events coming through the user event subscriber
	useEffect(() => {
		if (contextValue.userEventSubscriber) {
			const handler = (_event: WrappedEvent<EventType>) => {
				dlog(
					`optimised_event_subscriptions`,
					`received::user_event_from_blockchain_event_subscriber`
				);
			};

			contextValue.userEventSubscriber.eventEmitter.on('newEvent', handler);

			return () => {
				contextValue.userEventSubscriber.eventEmitter.off('newEvent', handler);
			};
		}
	}, [contextValue.userEventSubscriber]);

	return (
		<DriftBlockchainEventSubscriberContext value={contextValue}>
			{props.children}
		</DriftBlockchainEventSubscriberContext>
	);
};

export default DriftBlockchainEventSubscriberProvider;
