import React from 'react';
import { ChevronRight } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';

const IsolatedPoolsHelpLink = ({ className }: { className?: string }) => {
	return (
		<a
			href="https://docs.drift.trade/lend-borrow/isolated-pools"
			target="_blank"
			rel="noreferrer"
			className={twMerge('text-text-default', className)}
		>
			Learn more about isolated pools{' '}
			<ChevronRight className="relative top-[5px] mt-[-4px]" />
		</a>
	);
};

export default IsolatedPoolsHelpLink;
