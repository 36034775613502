import { getMarketIconSrc } from 'src/components/Utils/MarketIcon';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useSWR from 'swr';

const JUPITER_TOKEN_LIST_API = 'https://tokens.jup.ag/tokens?tags=verified';

export interface OpenToken {
	address: string;
	decimals: number;
	name: string;
	symbol: string;
	logoURI: string;
}

const WETH_TOKEN = {
	address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
	decimals: 8,
	name: 'Wrapped Ether (Wormhole)',
	symbol: 'wETH',
	logoURI:
		'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/ETH_wh.png',
};

/**
 * The tokens in this list are not how we want the details to be (e.g. Jupiter shows ETH instead of wETH).
 * Hence we customize the token details for these tokens.
 */
const ADJUSTED_TOKEN_LIST = [WETH_TOKEN];

/**
 * Uses Drift's supported spot markets.
 */
const DEFAULT_TOKEN_LIST = SPOT_MARKETS_LOOKUP.map((marketConfig) => ({
	address: marketConfig.mint.toString(),
	decimals: marketConfig.precisionExp.toNumber(),
	name: marketConfig.symbol,
	symbol: marketConfig.symbol,
	logoURI: getMarketIconSrc({ baseSymbol: marketConfig.symbol }),
}));

/**
 * Singleton hook that caches the open Jupiter Token List.
 *
 * Read more: https://station.jup.ag/docs/apis/token-list/token-list-api
 */
const useTokenList = () => {
	const { data } = useSWR<OpenToken[]>(JUPITER_TOKEN_LIST_API, null, {
		fallbackData: DEFAULT_TOKEN_LIST,
	});

	const tokenList = data
		.filter(
			(t) =>
				!ADJUSTED_TOKEN_LIST.map((token) => token.address).includes(t.address)
		)
		.concat(ADJUSTED_TOKEN_LIST);

	return tokenList;
};

export default useTokenList;
