import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { useCallback, useEffect } from 'react';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	useTokenAccountsStore,
	TokenAccountInfo,
} from './useTokenAccountsStore';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';

/**
 * This hook will sync the token account state for all token accounts for the current wallet
 *
 * If FILTER_BY_SPOT_MARKETS is true, only token accounts for DRIFT spot markets will be synced
 *
 * This hook does not KEEP the accounts in sync at the moment. It just refreshes them each time the wallet changes, or the refreshTokenAccounts event is emitted
 *
 * the useSPLTokenBalance hook does SUBSCRIBE to token account updates and push them back into this store. See the README.md in this folder.
 */

/**
 * TODO :: DYNAMIC_TOKEN_ACCOUNTS_SUBSCRIPTION
 *
 * Currently the UI does not stay in sync with any new token accounts created while the UI is live. You can Search DYNAMIC_TOKEN_ACCOUNTS_SUBSCRIPTION in the code base to see relevant places.
 *
 * Basically:
 * - The key in the token balances store for any non-existed token accounts will be undefined for any tokens that the wallet doesn't have
 * - Theoretically to fix this, we need to re-run the syncTokenAccounts hook whenever we think there might be a new token account
 * - There are more todos if you search DYNAMIC_TOKEN_ACCOUNTS_SUBSCRIPTION to make it work end-to-end.
 */

const FILTER_BY_SPOT_MARKETS = false;

const DRIFT_SPOT_MARKET_MINTS = CurrentSpotMarkets.map((m) =>
	m.mint.toString()
);

const TOKEN_PROGRAMS_TO_FETCH = [TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID];

export const useSyncTokenAccountsStore = () => {
	const wallet = useCurrentWalletAdapter();
	const publicKey = wallet?.publicKey;
	const connection = useDriftStore((s) => s.connection.current);
	const { setTokenAccount, clearAllTokenAccounts, setLastSyncedWallet } =
		useTokenAccountsStore();
	const eventEmitter = useDriftStore((s) => s.appEventEmitter);

	const fetchAndUpdateTokenAccounts = useCallback(async () => {
		// Clear existing accounts first
		clearAllTokenAccounts();

		if (!publicKey || !connection) {
			return;
		}

		// Fetch accounts for each token program
		for (const programId of TOKEN_PROGRAMS_TO_FETCH) {
			try {
				const response = await connection.getParsedTokenAccountsByOwner(
					publicKey,
					{
						programId,
					}
				);

				// Update with new accounts
				response.value.forEach((accountInfo) => {
					const parsedInfo = accountInfo.account.data.parsed.info;
					const mintAddress = parsedInfo.mint as string;

					if (
						FILTER_BY_SPOT_MARKETS &&
						!DRIFT_SPOT_MARKET_MINTS.includes(mintAddress)
					) {
						return;
					}

					const account: TokenAccountInfo = {
						pubkey: accountInfo.pubkey,
						mint: new PublicKey(mintAddress),
						programId: TOKEN_PROGRAM_ID,
						parsedBalance: parsedInfo.tokenAmount.uiAmount,
					};

					setTokenAccount(mintAddress, account);
				});
			} catch (error) {
				console.error('Error fetching token accounts:', error);
			}

			// Update last synced wallet after successful sync
			setLastSyncedWallet(publicKey);
		}
	}, [
		publicKey,
		connection,
		setTokenAccount,
		clearAllTokenAccounts,
		setLastSyncedWallet,
	]);

	// Sync when wallet changes
	useEffect(() => {
		fetchAndUpdateTokenAccounts();
	}, [publicKey, fetchAndUpdateTokenAccounts]);

	// Listen for refresh events
	useEffect(() => {
		if (!eventEmitter) return;

		eventEmitter.on('refreshTokenAccounts', fetchAndUpdateTokenAccounts);

		return () => {
			eventEmitter.off('refreshTokenAccounts', fetchAndUpdateTokenAccounts);
		};
	}, [eventEmitter, fetchAndUpdateTokenAccounts]);
};
