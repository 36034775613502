import {
	ConfirmationStrategy,
	DriftEnv,
	getMarketsAndOraclesForSubscription,
	initialize,
	Wallet,
} from '@drift-labs/sdk';
import {
	Config as CommonConfig,
	EnvironmentConstants,
	Initialize as InitializeCommon,
	JLP_POOL_ID,
	MAIN_POOL_ID,
	MarketId,
} from '@drift/common';
import { E2EWalletAdapter } from '@jet-lab/e2e-react-adapter';
import { Keypair } from '@solana/web3.js';
import {
	DriftTheme,
	EnvironmentVariables,
	LocalEnv,
	UserSettings,
} from './EnvironmentVariableTypes';
import { defaultUserSettings } from './DefaultSettings';
import { PRELAUNCH_MARKET_RESOURCE_CONSTANTS } from '../components/PreLaunchMarketAlerts/PreLaunchMarketConstants';
import { PriorityFee } from '../@types/types';
import { Transaction } from '@solana/web3.js';
import { VersionedTransaction } from '@solana/web3.js';
import {
	isVersionedTransaction,
	WalletNotConnectedError,
} from '@solana/wallet-adapter-base';
export * from './EnvironmentVariableTypes';
export * from './DefaultSettings';

const urlHasParam = (param: string) => {
	const urlSearchParams = new URLSearchParams(window.location.search);

	return urlSearchParams.has(param);
};

async function signTransaction<T extends Transaction | VersionedTransaction>(
	transaction: T
): Promise<T> {
	if (!this._underlyingWallet) throw new WalletNotConnectedError();

	if (isVersionedTransaction(transaction)) {
		transaction.sign([this._underlyingWallet]);
	} else {
		transaction.sign(this._underlyingWallet);
	}

	return transaction;
}

async function signAllTransactions<
	T extends Transaction | VersionedTransaction
>(transactions: T[]): Promise<T[]> {
	if (!this._underlyingWallet) throw new WalletNotConnectedError();

	for (const transaction of transactions) {
		if (isVersionedTransaction(transaction)) {
			// Sign VersionedTransaction
			transaction.sign([this._underlyingWallet]);
		} else {
			// Sign regular Transaction
			transaction.sign(this._underlyingWallet);
		}
	}

	return transactions;
}

// overwriting wallet sign functions as it does not support versionedTransactions
E2EWalletAdapter.prototype.signTransaction = signTransaction;
E2EWalletAdapter.prototype.signAllTransactions = signAllTransactions;

const isWindowDefined = () => typeof window !== 'undefined';

export const syncGetCurrentSettings = (): UserSettings | undefined => {
	if (!isWindowDefined()) return undefined;

	const settings = localStorage.getItem('settings');

	let parsedSettings = {};

	if (settings) {
		parsedSettings = JSON.parse(settings);
	}

	const result = {
		...defaultUserSettings,
		...parsedSettings,
	};

	if (urlHasParam('useMainnet')) {
		result.mainnetOverride = true;
	}

	return result;
};

const getSdkEnvFromEnv = (env: LocalEnv): DriftEnv => {
	if (env === 'master') return 'devnet';
	return env;
};

// --- Environment Settings
const localEnv: LocalEnv = (process.env.NEXT_PUBLIC_ENV ??
	'master') as LocalEnv;
let sdkEnv = getSdkEnvFromEnv(localEnv);

const savedSettings = syncGetCurrentSettings();

if (savedSettings?.mainnetOverride) {
	sdkEnv = 'mainnet-beta';
}

const sdkConfig = initialize({ env: sdkEnv });
InitializeCommon(sdkEnv);

// default to random rpc to not overload one
export const RPC_LIST =
	sdkEnv === 'mainnet-beta'
		? EnvironmentConstants.rpcs.mainnet
		: EnvironmentConstants.rpcs.dev;
const randomIndex = Math.floor(Math.random() * RPC_LIST.length);
const rpcToUse = RPC_LIST[randomIndex];

const historyServerToUse =
	sdkConfig.ENV === 'mainnet-beta' &&
	process.env.NEXT_PUBLIC_FORCE_USE_ENV_HISTORY_SERVER !== 'true'
		? savedSettings?.stagingHistoryServerOverride ||
		  process.env.NEXT_PUBLIC_FORCE_USE_STAGING_SERVER
			? EnvironmentConstants.historyServerUrl.staging
			: EnvironmentConstants.historyServerUrl.mainnet
		: process.env.NEXT_PUBLIC_EXCHANGE_HISTORY_SERVER_URL;

const dlobHttpServerToUse = process.env.NEXT_PUBLIC_DLOB_SERVER_OVERRIDE
	? process.env.NEXT_PUBLIC_DLOB_SERVER_OVERRIDE
	: savedSettings?.stagingHistoryServerOverride
	? EnvironmentConstants.dlobServerHttpUrl.staging
	: sdkConfig.ENV === 'mainnet-beta'
	? EnvironmentConstants.dlobServerHttpUrl.mainnet
	: EnvironmentConstants.dlobServerHttpUrl.dev;

const dlobWsServerToUse = process.env.NEXT_PUBLIC_OVERRIDE_WS_URL
	? process.env.NEXT_PUBLIC_OVERRIDE_WS_URL
	: savedSettings?.stagingHistoryServerOverride
	? EnvironmentConstants.dlobServerWsUrl.staging
	: sdkConfig.ENV === 'mainnet-beta'
	? EnvironmentConstants.dlobServerWsUrl.mainnet
	: EnvironmentConstants.dlobServerWsUrl.dev;

const eventsServerToUse = process.env.NEXT_PUBLIC_EVENTS_SERVER_OVERRIDE
	? process.env.NEXT_PUBLIC_EVENTS_SERVER_OVERRIDE
	: sdkConfig.ENV === 'mainnet-beta'
	? EnvironmentConstants.eventsServerUrl.mainnet
	: undefined;

const DEFAULT_WALLET = new Wallet(new Keypair());

const E2E_TEST_WALLET_SECRET = process.env.NEXT_PUBLIC_E2E_TEST_WALLET_SECRET
	? process.env.NEXT_PUBLIC_E2E_TEST_WALLET_SECRET.split(',').map(Number)
	: undefined;
const E2E_WALLET_KEYPAIR = E2E_TEST_WALLET_SECRET
	? Keypair.fromSecretKey(Uint8Array.from(E2E_TEST_WALLET_SECRET))
	: new Keypair();
const E2E_WALLET = new E2EWalletAdapter({ keypair: E2E_WALLET_KEYPAIR });

const { oracleInfos, perpMarketIndexes, spotMarketIndexes } =
	getMarketsAndOraclesForSubscription(sdkConfig.ENV);

const Env: EnvironmentVariables = {
	sdkEnv: sdkEnv,
	sdkConfig,
	localEnv: localEnv,
	defaultRpcAddress: rpcToUse.value,
	defaultRpcLabel: rpcToUse.label,
	driftClientProgramId: sdkConfig.DRIFT_PROGRAM_ID,
	usdcMint: sdkConfig.SPOT_MARKETS[0].mint,
	historyServerUrl: historyServerToUse,
	dlobWsServerToUse: dlobWsServerToUse,
	eventsServerToUse: eventsServerToUse,
	dlobServerHttpUrl: dlobHttpServerToUse,
	mainnetTermsUrl:
		'https://docs.drift.trade/legal-and-regulations/terms-of-use',
	mainnetDisclaimerUrl:
		'https://docs.drift.trade/legal-and-regulations/disclaimer',
	pollingFrequencyMs: 1000,
	defaultMaxMarketLeverage: 10,
	oracleInfos,
	spotMarketIndexes,
	perpMarketIndexes,
	serumAddress: sdkConfig.SERUM_V3,
	phoenixAddress: sdkConfig.PHOENIX,
	maxAccountsPerWallet: Number(process.env.MAX_ACCOUNTS_PER_WALLET) || 8,
	locale: 'en-US',
	enableSocialLogin: process.env.NEXT_PUBLIC_ENABLE_SOCIAL_LOGIN === 'true',
	magicAuthApiKey: process.env.NEXT_PUBLIC_MAGIC_AUTH_API_KEY, // || sdkConfig.MAGIC_AUTH_API_KEY
	oAuthRedirectURI: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_URI,
	defaultWallet: DEFAULT_WALLET,
	E2E_WALLET: E2E_WALLET,
	priceDivergenceWarningThreshold:
		Number(process.env.NEXT_PUBLIC_PRICE_DIVERGENCE_THRESHOLD) || 1,
	enableWormhole: process.env.NEXT_PUBLIC_ENABLE_WORMHOLE === 'true',
	wormholePackageVersion: process.env.NEXT_PUBLIC_WORMHOLE_PACKAGE_VERSION,
	enableMmSnap: process.env.NEXT_PUBLIC_ENABLE_MM_SNAP === 'true',
	mmSnapUrl: process.env.NEXT_PUBLIC_MM_SNAP_URL,
	mmSnapId: process.env.NEXT_PUBLIC_MM_SNAP_ID,
	mmSnapVersion: process.env.NEXT_PUBLIC_MM_SNAP_VERSION,
	showErrorLogs: process.env.NEXT_PUBLIC_SHOW_ERROR_LOGS === 'true',
	topLedgerClient: process.env.NEXT_PUBLIC_TL_CLIENT,
	topLedgerDashboardToken: process.env.NEXT_PUBLIC_TL_DASHBOARD_TOKEN,
	minInitialDepositValue:
		Number(process.env.NEXT_PUBLIC_MIN_INITIAL_DEPOSIT_VALUE) || 10,
	feedbackWidgetId:
		Number(process.env.NEXT_PUBLIC_FEEDBACK_WIDGET_ID) || 153000000873,
	enableSupportWidget: process.env.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET === 'true',
	defaultMakerRebateBps: Number(process.env.NEXT_PUBLIC_MAKER_REBATE_BPS) || 2,
	defaultTakerFeeBps:
		Number(process.env.NEXT_PUBLIC_DEFAULT_TAKER_FEE_BPS) || 10,
	maxNumberOfAccountsPer24h:
		Number(process.env.NEXT_PUBLIC_MAX_NUMBER_OF_ACCOUNTS_PER_24H) || Infinity,
	enableDlobWebsocket:
		(process.env.NEXT_PUBLIC_ENABLE_DLOB_WEBSOCKET || 'true') === 'true',
	metricsLoggingServerUrl:
		process.env.NEXT_PUBLIC_METRICS_LOGGING_SERVER_URL ??
		'https://logging.drift.trade',
	metricsLoggingEnabled:
		process.env.NEXT_PUBLIC_ENABLE_METRICS_LOGGING === 'true',
	metricsLoggingSampleRatio:
		Number(process.env.NEXT_PUBLIC_METRICS_LOGGING_SAMPLE_RATIO) || 0.1,
	mainnetSpeedInsightsEnabled: false,
	dynamicPriorityFeeMultiplier:
		Number(process.env.NEXT_PUBLIC_PRIORITY_FEE_MULTIPLIER) || 1,
	uiEssentialUpdateIntervalMs:
		Number(process.env.NEXT_PUBLIC_UI_ESSENTIAL_UPDATE_INTERVAL_MS) || 1000,
	uiNonEssentialUpdateIntervalMs:
		Number(process.env.NEXT_PUBLIC_UI_NON_ESSENTIAL_UPDATE_INTERVAL_MS) || 3000,
	dynamicFeeTargetPercentile:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_FEE_TARGET_PERCENTILE) || 80,
	confirmationStrategy:
		ConfirmationStrategy[
			process.env.NEXT_PUBLIC_CONFIRMATION_STRATEGY ?? 'Combo'
		],
	driftPointsTestTimeMs: Number(
		process.env.NEXT_PUBLIC_DRIFT_POINTS_TEST_TIME_MS
	),
	feeSubscriptionPollingMultiplier:
		Number(
			process.env.NEXT_PUBLIC_PRIORITY_FEE_SUBSCRIPTION_POLLING_MULTIPLIER
		) || 5,
	balEntryPriceCutoffTs:
		Number(process.env.NEXT_PUBLIC_BAL_FIXED_ENTRY_PRICE_TS) || 0,
	enableLiteMode: process.env.NEXT_PUBLIC_ENABLE_LITE_MODE === 'true',
	maxAccountsSwapBuffer:
		Number(process.env.NEXT_PUBLIC_MAX_ACCOUNTS_SWAP_BUFFER) || 3,
	enableDlobWebsocketTradesChannel:
		process.env.NEXT_PUBLIC_ENABLE_DLOB_WEBSOCKET_TRADES_CHANNEL === 'true',
	driftDialectDappAddress:
		process.env.NEXT_PUBLIC_DRIFT_DIALECT_DAPP_ADDRESS ?? // Can override the dialect address with this variable
		sdkEnv === 'mainnet-beta'
			? 'Cuvh8Ji4scfzJGekSeADhDQrm67Ne9tFoouyHRA6VkJT' // This is the MAINNET Dapp address
			: '9tNykNRDwnaUQ9f1NDazU113bs6z1SeZLZasf5QVYFiL', // This is the DEVNET Dapp address,
	dialectNotificationsEnabled:
		process.env.NEXT_PUBLIC_DIALECT_NOTIFICATIONS_ENABLED === 'true',
	useSimulatedComputeUnitBudget:
		process.env.NEXT_PUBLIC_USE_SIMULATED_COMPUTE_UNIT_BUDGET === 'true',
	useSimulatedComputeUnitBudgetForFees:
		process.env.NEXT_PUBLIC_USE_SIMULATED_COMPUTE_UNIT_BUDGET_FOR_FEES ===
		'true',
	computeUnitBufferMultiplier:
		Number(process.env.NEXT_PUBLIC_COMPUTE_UNIT_BUFFER_MULTIPLIER) || 1.2,
	minimumPriorityFee: (Number(process.env.NEXT_PUBLIC_MINIMUM_PRIORITY_FEE) ||
		1) as PriorityFee,
	txSenderRetryInterval:
		parseInt(process.env.NEXT_PUBLIC_TX_SENDER_RETRY_INTERVAL) || undefined,
	driftTraderRewardsBannerEnabled:
		process.env.NEXT_PUBLIC_ENABLE_DRIFT_TRADER_REWARDS_BANNER === 'true',
	initialDataSourceMaxDelay:
		Number(process.env.NEXT_PUBLIC_INITIAL_DATA_SOURCE_MAX_DELAY) || 5_000,
	newDataSourceSubMaxDelay:
		Number(process.env.NEXT_PUBLIC_NEW_DATA_SOURCE_MAX_DELAY) || 2_000,
	upgradeDataSourceDelay:
		Number(process.env.NEXT_PUBLIC_UPGRADE_DATA_SOURCE_DELAY) || 1 * 60 * 1000, // 1 minutes
	placeAndTakeLowerBoundCu:
		Number(process.env.NEXT_PUBLIC_PLACE_AND_TAKE_LOWER_BOUND_CU) || 400_000,
	useCachedBlockhashFetcherInDriftClient: true,
	showPredictionMarketsInPerpTradePage: false,
	pyusdBonusPerWeek: Number(process.env.NEXT_PUBLIC_PYUSD_BONUS_PER_WEEK) || 0,
	usdsBonusPerWeek: Number(process.env.NEXT_PUBLIC_USDS_BONUS_PER_WEEK) || 0,
	enableFuelEarnings: process.env.NEXT_PUBLIC_ENABLE_FUEL_EARNINGS === 'true',
	fuelBaseEarnRates: {
		taker: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_TAKER) || 1,
		maker: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_MAKER) || 1,
		deposit: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_DEPOSIT) || 0,
		borrow: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_BORROW) || 0,
		oi: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_OI) || 0,
		staking: Number(process.env.NEXT_PUBLIC_FUEL_BASE_RATE_STAKING) || 1,
	},
	showAppKitWallet: process.env.NEXT_PUBLIC_SHOW_APPKIT_WALLET === 'true',
	rpcVersion: undefined,
	depositAndTradeEnabled:
		process.env.NEXT_PUBLIC_DEPOSIT_AND_TRADE_ENABLED === 'true',
	rpcsToExclude: process.env.NEXT_PUBLIC_RPCS_TO_EXCLUDE
		? process.env.NEXT_PUBLIC_RPCS_TO_EXCLUDE.split(',')
		: [],
	defaultAuctionDurationPercentage:
		Number(process.env.NEXT_PUBLIC_DEFAULT_AUCTION_DURATION_PCT) || 33,
	enableIsolatedPools: process.env.NEXT_PUBLIC_SHOW_ISOLATED_POOLS === 'true',
	dynamicSlippageMin:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_SLIPPAGE_MIN) || 0.05,
	dynamicSlippageMax: Number(process.env.NEXT_PUBLIC_DYNAMIC_SLIPPAGE_MAX) || 5,
	dynamicBaseSlippageMajor:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_BASE_SLIPPAGE_MAJOR) || 0,
	dynamicBaseSlippageNonMajor:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_BASE_SLIPPAGE_NON_MAJOR) || 0.5,
	dynamicSlippageMultiplierMajor:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_SLIPPAGE_MULTIPLIER_MAJOR) || 1.02,
	dynamicSlippageMultiplierNonMajor:
		Number(process.env.NEXT_PUBLIC_DYNAMIC_SLIPPAGE_MULTIPLIER_NON_MAJOR) ||
		1.2,
	perpsMaxHighLeverage: Number(process.env.NEXT_PUBLIC_MAX_LEVERAGE) || 101,
};

export const isDev = () => ['local', 'master', 'devnet'].includes(Env.localEnv);

export const THEMES = [
	{ label: 'Dark', value: DriftTheme.dark },
	{ label: 'Light', value: DriftTheme.light },
];

export const CurrentPerpMarkets = sdkConfig.PERP_MARKETS;

export const OrderedPerpMarkets = [...CurrentPerpMarkets].sort(
	(a, b) => a.marketIndex - b.marketIndex
);

export const CurrentSpotMarkets = sdkConfig.SPOT_MARKETS;

export const CurrentMainSpotMarkets = sdkConfig.SPOT_MARKETS.filter(
	(market) => market.poolId === MAIN_POOL_ID
);

export const OrderedSpotMarkets = [...CurrentSpotMarkets].sort(
	(a, b) => a.marketIndex - b.marketIndex
);

export const marketStepSizes = {
	SOL: 0.01,
	BTC: 0.0001,
	ETH: 0.001,
	APT: 0.01,
	'1MBONK': 0.1,
};

export const marketTickSizes = {
	SOL: 0.0025,
	BTC: 1.0,
	ETH: 0.1,
	APT: 0.0025,
	'1MBONK': 0.0001,
};

export const PERP_MARKETS_LOOKUP = CommonConfig.perpMarketsLookup;
export const SPOT_MARKETS_LOOKUP = CommonConfig.spotMarketsLookup;
export const JLP_SPOT_MARKETS_LOOKUP = SPOT_MARKETS_LOOKUP.filter(
	(market) => market.poolId === JLP_POOL_ID
);

export const PRELAUNCH_MARKET_RESOURCES =
	sdkConfig.ENV === 'devnet'
		? PRELAUNCH_MARKET_RESOURCE_CONSTANTS.devnet
		: PRELAUNCH_MARKET_RESOURCE_CONSTANTS.mainnet;

export const PERP_MARKET_IDS: MarketId[] = CurrentPerpMarkets.map((mkt) =>
	MarketId.createPerpMarket(mkt.marketIndex)
);

export const SPOT_MARKET_IDS: MarketId[] = CurrentSpotMarkets.map((mkt) =>
	MarketId.createSpotMarket(mkt.marketIndex)
);

export const ALL_MARKET_IDS: MarketId[] = [
	...PERP_MARKET_IDS,
	...SPOT_MARKET_IDS,
];

export const DRIFT_TOKEN_BANK_INDEX = 15;
export const DRIFT_TOKEN_SPOT_MARKET =
	SPOT_MARKETS_LOOKUP[DRIFT_TOKEN_BANK_INDEX] || SPOT_MARKETS_LOOKUP[0];
export const PYUSD_BANK_INDEX = sdkEnv === 'mainnet-beta' ? 22 : 3;
export const PYUSD_SPOT_MARKET = SPOT_MARKETS_LOOKUP[PYUSD_BANK_INDEX];
export const USDE_MARKET_INDEX = 23;
export const SUSDE_MARKET_INDEX = 24;
export const SUSDE_SPOT_MARKET =
	SPOT_MARKETS_LOOKUP[SUSDE_MARKET_INDEX] ?? SPOT_MARKETS_LOOKUP[0];
export const BNSOL_MARKET_INDEX = 25;
export const USDS_MARKET_INDEX = 28;
export const USDS_SPOT_MARKET = SPOT_MARKETS_LOOKUP[USDS_MARKET_INDEX];
export const DSOL_MARKET_INDEX = 17;

export const EXTRA_YIELD_SPOT_MARKETS = [PYUSD_BANK_INDEX, USDS_MARKET_INDEX];

export const PRIORITY_SPOT_MARKETS = {
	[0]: 0, // USDC
	[1]: 1, // SOL
	[USDS_MARKET_INDEX]: 2,
	[PYUSD_BANK_INDEX]: 3,
	[DSOL_MARKET_INDEX]: 4,
	[SUSDE_MARKET_INDEX]: 5,
	[USDE_MARKET_INDEX]: 6,
	[19]: 7, // JLP
};
export const DEFAULT_EARN_SPOT_MARKETS = {
	[0]: 0, // USDC
	[DSOL_MARKET_INDEX]: 1, // USDC
	[1]: 2, // SOL
	[USDS_MARKET_INDEX]: 3, // USDS
	[PYUSD_BANK_INDEX]: 4, // PYUSD
	[DRIFT_TOKEN_BANK_INDEX]: 5, // DRIFT
};

export const ALL_TRADEABLE_MARKET_IDS: MarketId[] = ALL_MARKET_IDS.filter(
	(mktId) => mktId.key !== SPOT_MARKET_IDS[0].key
);

export const ASSET_COLORS = {
	USDC: '#2775CA',
	SOL: '#7C60E9',
	mSOL: '#308D8A',
	wBTC: '#F7931A',
	BTC: '#F7931A',
	wETH: '#7A57DD',
	USDT: '#26A17B',
	jitoSOL: '#8ed693',
	PYTH: '#B39ADB',
	bSOL: '#03DEFB',
	JTO: '#000000',
	WIF: '#A67764',
	JUP: '#66D1B7',
	RNDR: '#D61919',
	W: '#FFFFFF',
	TNSR: '#000000',
	DRIFT: '#AB54CA',
	INF: '#EAD80F',
	dSOL: '#A770CD',
	USDY: '#122A5F',
	JLP: '#C5F484',
	POPCAT: '#EEDFDD',
	CLOUD: '#6EC4FF',
	PYUSD: '#3b6fef',
	USDE: '#000000',
	SUSDE: '#F3F3F3',
	BNSOL: '#F0B90B',
	MOTHER: '#a28c78',
	cbBTC: '#0052FF',
	USDS: '#FFA44D',
	META: '#fb4b4b',
	ME: '#f542d4',
	PENGU: '#c9f5f0',
	BONK: '#f5f520',
	AI16Z: '#eb7d34',
	TRUMP: '#F6E59B',
};

export default Env;
