import {
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	ContractType,
	DriftClient,
	MAX_LEVERAGE_ORDER_SIZE,
	MakerInfo,
	MarketType,
	OptionalOrderParams,
	OrderTriggerCondition,
	PRICE_PRECISION_EXP,
	PerpMarketAccount,
	PositionDirection,
	PublicKey,
	ReferrerInfo,
	SpotMarketAccount,
	TxParams,
	User,
	UserAccount,
	getTriggerMarketOrderParams,
	isVariant,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	MarketId,
	UIOrderType,
	TradeOffsetPrice,
} from '@drift/common';
import { UserSettings } from '../../../../environmentVariables/EnvironmentVariableTypes';
import {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
	DEFAULT_MARKET_AUCTION_DURATION,
	OrderedSpotMarkets,
	syncGetCurrentSettings,
} from '../../../../environmentVariables/EnvironmentVariables';
import { AuctionToastEvent } from '../../../../utils/DriftAppEvents';
import { DriftWindow } from '../../../../window/driftWindow';
import { DriftStore } from '../../useDriftStore';
import { PREDICTION_MARKET_AUCTION_PRICE_CAPS } from 'src/constants/math';
import UI_UTILS from 'src/utils/uiUtils';

// Reverts optional flags from generic type
type RequiredProps<T> = {
	[K in keyof T]-?: T[K];
};

// Strict type to reduce noise out of params which the SDK asks for but don't actually get used
export type StrictPerpMarketOrderParams = RequiredProps<
	Pick<
		OptionalOrderParams,
		| 'reduceOnly'
		| 'marketIndex'
		| 'marketType'
		| 'baseAssetAmount'
		| 'direction'
		| 'orderType'
		| 'auctionDuration'
		| 'auctionStartPrice'
		| 'auctionEndPrice'
		| 'price'
		| 'oraclePriceOffset'
		// | 'postOnly'
		// | 'maxTs' :: Not actually used in the UI
		// | 'triggerPrice'
		// | 'triggerCondition'
		// | 'immediateOrCancel'
	>
>;

// Strict type to reduce noise out of params which the SDK asks for but don't actually get used
export type StrictPerpPlaceAndTakeOrderParams = RequiredProps<
	Pick<
		OptionalOrderParams,
		| 'reduceOnly'
		// | 'userOrderId'
		| 'marketIndex'
		| 'marketType'
		| 'baseAssetAmount'
		| 'direction'
		| 'orderType'
		| 'auctionDuration'
		| 'auctionStartPrice'
		| 'auctionEndPrice'
		| 'price'
		| 'oraclePriceOffset'
		// | 'immediateOrCancel'
		// | 'maxTs' :: Not actually used in the UI
		// | 'triggerPrice'
		// | 'triggerCondition'
		// | 'postOnly'
	>
>;

export type StrictSpotBasicMarketOrderParams = RequiredProps<
	Pick<
		OptionalOrderParams,
		| 'reduceOnly'
		| 'orderType'
		| 'marketIndex'
		| 'baseAssetAmount'
		| 'direction'
		| 'marketType'
		| 'price'
		| 'auctionDuration'
		| 'auctionStartPrice'
		| 'auctionEndPrice'
		| 'oraclePriceOffset'
	>
>;

export type StrictSpotOracleMarketOrderParams = RequiredProps<
	Pick<
		OptionalOrderParams,
		| 'reduceOnly'
		| 'orderType'
		| 'marketIndex'
		| 'baseAssetAmount'
		| 'direction'
		| 'marketType'
		| 'auctionDuration'
		| 'auctionStartPrice'
		| 'auctionEndPrice'
		| 'oraclePriceOffset'
	>
>;

/**
 *
 * Stages of order props:
 * # Trade Form Output Props
 * These are the raw properties that we currently spit out of the trade form when we want to create an order
 *
 * # Processed Props
 * These are the properties that we've parsed and done extra work on, to gather everything necessary for the OrderParams that the Drift Client requires
 *
 * # Final Prepped Props
 * These are all of the properties required for the Drift Client's method to create the order. We pass the "Processed Props" into the common deriveMarketOrderParams and only return the strict props required for the order.
 */

// Type which should strictly only contain the properties output by the tradeform which are actually required for the order construction
export type CommonTradeFormOutputOrderConstructionProps = Partial<
	Omit<
		DriftStore['tradeForm'],
		| 'closingPosition'
		| 'useAdvSettings'
		| 'leverage'
		| 'showBracketOrderForm'
		| 'stepSize'
		| 'savedLimitPrice'
	>
> & {
	targetMarketIndex: number;
	oraclePrice: BN;
	markPrice: BN;
	currentSettings?: Partial<UserSettings>;
};

export type PerpTradeFormOutputProps =
	CommonTradeFormOutputOrderConstructionProps & {
		perpMarketAccount: PerpMarketAccount;
		cancelExistingOrders?: boolean;
	};

export type SpotTradeFormOutputProps =
	CommonTradeFormOutputOrderConstructionProps & {
		targetMarketIndex: number;
		currentPositionBaseSize: BN;
		currentPositionDirection: 'long' | 'short';
		spotMarketAccount: SpotMarketAccount;
	};

// Staged props ready to be passed into the methods to create the final props.
type ProcessedPerpMarketOrderParams = {
	direction: PositionDirection;
	baseAmount: BN;
	marketId: MarketId;
	maxLeverageOrderSize: BN;
	allowInfSlippage: boolean;
	limitPrice: BN;
	bestPrice: BN;
	entryPrice: BN;
	worstPrice: BN;
	marketTickSize: BN;
	auctionDuration: number;
	auctionStartPriceOffset: number;
	auctionEndPriceOffset: number;
	auctionPriceCaps?: {
		min: BN;
		max: BN;
	};
	auctionStartPriceOffsetFrom: TradeOffsetPrice;
	auctionEndPriceOffsetFrom: TradeOffsetPrice;
	slippageTolerance: number;
	isOracleOrder: boolean;
	auctionToastEventProps: AuctionToastEvent;
	oraclePrice: BN;
	markPrice: BN;
	reduceOnly: boolean;
	maxLeverageSelected: boolean;
	numOrders: number;
	toastId: string;
	additionalEndPriceBuffer?: BN;
};

// These are the params actually passed to the Drift Client's method to prepare the market order
export type FinalPreppedMarketOrderProps = {
	orderParams: OptionalOrderParams;
	userAccountPublicKey: PublicKey;
	user: User;
	userAccount: UserAccount;
	makerInfo?: MakerInfo | MakerInfo[];
	txParams?: TxParams;
	bracketOrdersParams: OptionalOrderParams[];
	referrerInfo: ReferrerInfo;
	cancelExistingOrders?: boolean;
	settlePnl?: boolean;
	auctionToastEventProps: AuctionToastEvent;
	entryPrice: BN;
	nextOrderId: number;
	subAccountId: number;
	attemptPlaceAndTake: boolean;
	numOrders: number;
	toastId: string;
	marketName: string;
};

export type OrderPrepHarnessProps = {
	getUserAccountData: () => {
		client: User;
		pubKey: PublicKey;
	};
	getDriftClient: () => DriftClient;
};

const getToastId = (
	marketId: MarketId,
	direction: PositionDirection,
	orderType: UIOrderType
) => {
	return `${marketId.key}-${orderType}-${ENUM_UTILS.toStr(
		direction
	)}-${Date.now()}`;
};

export const TRADE_PREP_UTILS = {
	getToastId,
	getAuctionToastEventProps: (props: {
		marketId: MarketId;
		direction: PositionDirection;
		baseSize: BigNum;
		numOrders: number;
		currentSettings: Partial<UserSettings>;
		bracketOrders: CommonTradeFormOutputOrderConstructionProps['bracketOrders'];
		orderType: CommonTradeFormOutputOrderConstructionProps['orderType'];
	}) => {
		const auctionToastEventProps: AuctionToastEvent = {
			v2Props: {
				identifierNonce: DriftWindow.getAndIncrementNonce(),
				marketId: MarketId.createPerpMarket(props.marketId.marketIndex),
				direction: props.direction,
				baseAmountOrdered: props.baseSize.val,
				numOrders: props.numOrders,
				auctionEnabled:
					props.currentSettings?.auctionDuration > 0 &&
					!props.currentSettings.placeAndTakeEnabled,
				includesSlOrder: !!props.bracketOrders?.stopLoss,
				includesTpOrder: !!props.bracketOrders?.takeProfit,
			},
			toastId: getToastId(props.marketId, props.direction, props.orderType),
		};

		return auctionToastEventProps;
	},
	perp: {
		formatTradeFormFieldValue: {
			baseSize: (input: string): BigNum => {
				return BigNum.fromPrint(input, BASE_PRECISION_EXP);
			},
			price: (input: string): BigNum => {
				return BigNum.fromPrint(input, PRICE_PRECISION_EXP);
			},
			secondaryPrice: (input: string): BigNum => {
				return BigNum.fromPrint(input, PRICE_PRECISION_EXP);
			},
		},
		adjustForStepSize: (
			baseSize: BigNum,
			perpMarketAccount: PerpMarketAccount
		) => {
			if (!perpMarketAccount) return baseSize;

			const stepSizeBn = perpMarketAccount.amm.orderStepSize;
			const baseSizeBn = baseSize.val;
			const baseSizeAfterStep = baseSizeBn.div(stepSizeBn).mul(stepSizeBn);

			return BigNum.from(baseSizeAfterStep, BASE_PRECISION_EXP);
		},
	},
	spot: {
		formatTradeFormFieldValue: {
			baseSize: (
				input: string,
				spotMarketAccount: SpotMarketAccount
			): BigNum => {
				const basePrecisionToUse =
					OrderedSpotMarkets[spotMarketAccount.marketIndex].precisionExp;

				return BigNum.fromPrint(input, basePrecisionToUse);
			},
			price: (input: string): BigNum => {
				return BigNum.fromPrint(input, PRICE_PRECISION_EXP);
			},
			secondaryPrice: (input: string): BigNum => {
				return BigNum.fromPrint(input, PRICE_PRECISION_EXP);
			},
		},
		adjustForStepSize: (
			baseSize: BigNum,
			spotMarketAccount: SpotMarketAccount
		) => {
			const basePrecisionToUse =
				OrderedSpotMarkets[spotMarketAccount.marketIndex].precisionExp;

			const stepSizeBn = spotMarketAccount.orderStepSize;
			const baseSizeBn = baseSize.val;
			const baseSizeAfterStep = baseSizeBn.div(stepSizeBn).mul(stepSizeBn);

			return BigNum.from(baseSizeAfterStep, basePrecisionToUse);
		},
	},
};

/**
 * This method does any parsing and other processing of the trade form's raw output into the necessary properties to create the OrderParams object that the Drift Client requires
 * @param inputProps
 * @param harnessProps
 * @returns
 */
const processPerpTradeFormOutputProps = (
	inputProps: PerpTradeFormOutputProps
): ProcessedPerpMarketOrderParams => {
	const direction =
		inputProps.side === 'buy'
			? PositionDirection.LONG
			: PositionDirection.SHORT;

	const parsedBaseAmount =
		TRADE_PREP_UTILS.perp.formatTradeFormFieldValue.baseSize(
			inputProps.baseSizeStringValue
		);
	const roundedBaseAmount = TRADE_PREP_UTILS.perp.adjustForStepSize(
		parsedBaseAmount,
		inputProps.perpMarketAccount
	);

	const marketId = MarketId.createPerpMarket(
		inputProps.perpMarketAccount.marketIndex
	);

	const numOrders = inputProps.bracketOrders
		? inputProps.bracketOrders.stopLoss && inputProps.bracketOrders.takeProfit
			? 3
			: inputProps.bracketOrders.stopLoss || inputProps.bracketOrders.takeProfit
			? 2
			: 1
		: 1;

	const currentSettings =
		inputProps?.currentSettings ?? syncGetCurrentSettings();

	const auctionToastEventProps = TRADE_PREP_UTILS.getAuctionToastEventProps({
		marketId,
		direction,
		baseSize: roundedBaseAmount,
		numOrders,
		currentSettings,
		bracketOrders: inputProps.bracketOrders,
		orderType: inputProps.orderType,
	});

	const allowInfSlippage =
		inputProps.slippageTolerance == undefined || inputProps.allowInfSlippage;

	const { bestPrice, entryPrice, worstPrice } = inputProps.priceImpact;

	const priceObject = COMMON_UI_UTILS.getPriceObject({
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestOffer: bestPrice,
		entryPrice,
		worstPrice,
		direction,
	});

	const startPriceFromSettings =
		priceObject[currentSettings?.auctionStartPriceOffsetFrom];

	const limitPrice = COMMON_UI_UTILS.getMarketOrderLimitPrice({
		direction,
		baselinePrice: startPriceFromSettings,
		slippageTolerance: allowInfSlippage
			? undefined
			: inputProps.slippageTolerance,
	});

	const useMaxleverage = inputProps.maxLeverageSelected;

	const toastId = `${inputProps.targetMarketIndex.toString()}-${roundedBaseAmount.toString()}-${
		isVariant(direction, 'long') ? 'LONG' : 'SHORT'
	}-${Date.now()}`;

	const isOracleOrder = currentSettings.oracleOffsetOrdersEnabled;

	const auctionPriceCaps = ENUM_UTILS.match(
		inputProps.perpMarketAccount.contractType,
		ContractType.PREDICTION
	)
		? PREDICTION_MARKET_AUCTION_PRICE_CAPS
		: undefined;

	const output: ProcessedPerpMarketOrderParams = {
		direction,
		baseAmount: roundedBaseAmount.val.abs(),
		marketId,
		maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
		allowInfSlippage,
		limitPrice,
		bestPrice,
		entryPrice,
		worstPrice,
		marketTickSize: inputProps.perpMarketAccount.amm.orderTickSize,
		auctionDuration:
			currentSettings?.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
		auctionStartPriceOffset: currentSettings?.auctionStartPriceOffset,
		auctionEndPriceOffset: currentSettings?.auctionEndPriceOffset,
		auctionStartPriceOffsetFrom: currentSettings?.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: currentSettings?.auctionEndPriceOffsetFrom,
		auctionPriceCaps: auctionPriceCaps,
		slippageTolerance: inputProps.slippageTolerance,
		auctionToastEventProps,
		reduceOnly: inputProps.reduceOnly,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		maxLeverageSelected: useMaxleverage,
		numOrders,
		toastId,
		isOracleOrder,
		additionalEndPriceBuffer: UI_UTILS.getAdditionalEndPriceBuffer(
			inputProps.perpMarketAccount
		),
	};

	return output;
};

const getBracketOrdersParams = ({
	bracketOrders,
	marketIndex,
	baseAmount,
	direction,
}: {
	bracketOrders: CommonTradeFormOutputOrderConstructionProps['bracketOrders'];
	marketIndex: number;
	baseAmount: BN;
	direction: PositionDirection;
}) => {
	if (bracketOrders) {
		const bracketOrdersParams = [];

		if (bracketOrders.takeProfit) {
			const takeProfitParams = getTriggerMarketOrderParams({
				marketIndex,
				marketType: MarketType.PERP,
				direction: bracketOrders.takeProfit.direction,
				baseAssetAmount: baseAmount,
				triggerPrice: bracketOrders.takeProfit.price.val,
				triggerCondition: isVariant(direction, 'short')
					? OrderTriggerCondition.BELOW
					: OrderTriggerCondition.ABOVE,
				reduceOnly: true,
			});

			bracketOrdersParams.push(takeProfitParams);
		}

		if (bracketOrders.stopLoss) {
			const stopLossParams = getTriggerMarketOrderParams({
				marketIndex,
				marketType: MarketType.PERP,
				direction: bracketOrders.stopLoss.direction,
				baseAssetAmount: baseAmount,
				triggerPrice: bracketOrders.stopLoss.price.val,
				triggerCondition: isVariant(direction, 'short')
					? OrderTriggerCondition.ABOVE
					: OrderTriggerCondition.BELOW,
				reduceOnly: true,
			});

			bracketOrdersParams.push(stopLossParams);
		}

		return bracketOrdersParams;
	}

	return undefined;
};

/**
 * Takes the output from the tradeform and some necessary harness stuff, outputs the parameters we can pass into the DriftClient's market order method.
 * @param inputProps
 * @param harnessProps
 * @returns
 */
const prepPerpMarketOrderParams = (
	inputProps: PerpTradeFormOutputProps,
	harnessProps: OrderPrepHarnessProps,
	userDataOverride?: {
		accountDoesNotExistYet: boolean;
		pubKey: PublicKey;
		nextOrderId: number;
		subAccountId: number;
	}
): FinalPreppedMarketOrderProps => {
	const driftClient = harnessProps.getDriftClient();
	const userData = harnessProps.getUserAccountData();
	const processedProps = processPerpTradeFormOutputProps(inputProps);
	const currentSettings =
		inputProps?.currentSettings ?? syncGetCurrentSettings();

	const attemptPlaceAndTake =
		driftClient.txVersion === 0 && currentSettings?.placeAndTakeEnabled;

	const orderParams = attemptPlaceAndTake
		? getStrictPerpPlaceAndTakeOrderParams(processedProps)
		: getStrictPerpMarketOrderParams(processedProps);

	const marketName = CurrentPerpMarkets.find(
		(market) => market.marketIndex === inputProps.targetMarketIndex
	).symbol;

	const output: FinalPreppedMarketOrderProps = {
		orderParams,
		userAccountPublicKey: userDataOverride?.pubKey ?? userData.pubKey,
		userAccount: userDataOverride
			? undefined
			: userData.client.getUserAccount(),
		user: userDataOverride ? undefined : userData.client,
		cancelExistingOrders: inputProps.cancelExistingOrders,
		settlePnl: processedProps.reduceOnly,
		auctionToastEventProps: processedProps.auctionToastEventProps,
		entryPrice: processedProps.entryPrice,
		nextOrderId:
			userDataOverride?.nextOrderId ??
			userData.client.getUserAccount().nextOrderId,
		subAccountId:
			userDataOverride?.subAccountId ??
			userData.client.getUserAccount().subAccountId,
		attemptPlaceAndTake,
		numOrders: processedProps.numOrders,
		toastId: processedProps.toastId,
		referrerInfo: userDataOverride?.accountDoesNotExistYet
			? undefined
			: driftClient.getUserStats()?.getReferrerInfo(),
		marketName,
		bracketOrdersParams: getBracketOrdersParams({
			bracketOrders: inputProps.bracketOrders,
			marketIndex: inputProps.targetMarketIndex,
			baseAmount: processedProps.baseAmount,
			direction: processedProps.direction,
		}),
	};

	return output;
};

// ### SPOT

export interface ProcessedSpotMarketOrderParams {
	direction: PositionDirection;
	baseAmount: BN;
	marketId: MarketId;
	maxLeverageOrderSize: BN;
	allowInfSlippage: boolean;
	limitPrice: BN;
	bestPrice: BN;
	entryPrice: BN;
	worstPrice: BN;
	marketTickSize: BN;
	auctionDuration: number;
	auctionStartPriceOffset: number;
	auctionEndPriceOffset: number;
	auctionStartPriceOffsetFrom: TradeOffsetPrice;
	auctionEndPriceOffsetFrom: TradeOffsetPrice;
	slippageTolerance: number;
	userAccount: UserAccount;
	auctionToastEventProps: AuctionToastEvent;
	reduceOnly: boolean;
	oraclePrice: BN;
	markPrice: BN;
	maxLeverageSelected: boolean;
	toastId: string;
	oracleOffsetOrdersEnabled: boolean;
	isOracleOrder: boolean;
}

export interface FinalPreppedSpotMarketOrderProps {
	orderParams: OptionalOrderParams;
	userAccountPublicKey: PublicKey;
	user: User;
	userAccount: UserAccount;
	auctionToastEventProps: AuctionToastEvent;
	entryPrice: BN;
	nextOrderId: number;
	subAccountId: number;
	attemptPlaceAndTake: boolean;
	toastId: string;
	referrerInfo: ReferrerInfo;
	marketName: string;
}

export const prepSpotMarketOrderParams = (
	inputProps: SpotTradeFormOutputProps,
	harnessProps: OrderPrepHarnessProps
): FinalPreppedSpotMarketOrderProps => {
	const driftClient = harnessProps.getDriftClient();
	const userData = harnessProps.getUserAccountData();
	const processedProps = processSpotTradeFormOutput(inputProps, harnessProps);
	const currentSettings =
		inputProps?.currentSettings ?? syncGetCurrentSettings();

	const orderParams = processedProps.isOracleOrder
		? getStrictSpotOracleOrderParams(processedProps)
		: getStrictSpotMarketOrderParams(processedProps);

	const attemptPlaceAndTake =
		driftClient.txVersion === 0 && currentSettings?.placeAndTakeEnabled;

	const targetMarket = CurrentSpotMarkets.find(
		(market) => market.marketIndex === inputProps.targetMarketIndex
	);

	const marketName = targetMarket.symbol;

	return {
		orderParams,
		userAccountPublicKey: userData.pubKey,
		userAccount: userData.client.getUserAccount(),
		auctionToastEventProps: processedProps.auctionToastEventProps,
		entryPrice: processedProps.entryPrice,
		nextOrderId: userData.client.getUserAccount().nextOrderId,
		subAccountId: userData.client.getUserAccount().subAccountId,
		user: userData.client,
		attemptPlaceAndTake,
		toastId: processedProps.toastId,
		referrerInfo: driftClient.getUserStats()?.getReferrerInfo(),
		marketName,
	};
};

const processSpotTradeFormOutput = (
	inputProps: SpotTradeFormOutputProps,
	harnessProps: OrderPrepHarnessProps
): ProcessedSpotMarketOrderParams => {
	const direction =
		inputProps.side === 'buy'
			? PositionDirection.LONG
			: PositionDirection.SHORT;

	const formattedBaseAmount =
		TRADE_PREP_UTILS.spot.formatTradeFormFieldValue.baseSize(
			inputProps.baseSizeStringValue,
			inputProps.spotMarketAccount
		);

	const currentSettings =
		inputProps?.currentSettings ?? syncGetCurrentSettings();

	const marketId = MarketId.createSpotMarket(inputProps.targetMarketIndex);

	const useMaxleverage = inputProps.maxLeverageSelected;

	const baseAmountBn = formattedBaseAmount.val;

	const userAccount = harnessProps.getUserAccountData().client.getUserAccount();

	const toastId = getToastId(marketId, direction, 'market');

	const auctionToastEventProps: AuctionToastEvent = {
		v2Props: {
			identifierNonce: DriftWindow.getAndIncrementNonce(),
			marketId: MarketId.createSpotMarket(inputProps.targetMarketIndex),
			direction,
			baseAmountOrdered: formattedBaseAmount.val,
			numOrders: 1,
			auctionEnabled: false,
			includesSlOrder: false,
			includesTpOrder: false,
		},
		toastId,
	};

	const allowInfSlippage =
		inputProps.slippageTolerance == undefined || inputProps.allowInfSlippage;

	const { bestPrice, entryPrice, worstPrice } = inputProps.priceImpact;

	const priceObject = COMMON_UI_UTILS.getPriceObject({
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestOffer: bestPrice,
		entryPrice,
		worstPrice,
		direction,
	});

	const startPriceFromSettings =
		priceObject[currentSettings?.auctionStartPriceOffsetFrom];

	const limitPrice = COMMON_UI_UTILS.getMarketOrderLimitPrice({
		direction,
		baselinePrice: startPriceFromSettings,
		slippageTolerance: allowInfSlippage
			? undefined
			: inputProps.slippageTolerance,
	});

	const isOracleOrder = currentSettings.oracleOffsetOrdersEnabled;

	return {
		direction,
		baseAmount: baseAmountBn.abs(),
		marketId,
		maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
		allowInfSlippage,
		limitPrice,
		bestPrice,
		entryPrice,
		worstPrice,
		marketTickSize: inputProps.spotMarketAccount.orderTickSize,
		auctionDuration:
			currentSettings?.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
		auctionStartPriceOffset: currentSettings?.auctionStartPriceOffset,
		auctionEndPriceOffset: currentSettings?.auctionEndPriceOffset,
		auctionStartPriceOffsetFrom: currentSettings?.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: currentSettings?.auctionEndPriceOffsetFrom,
		slippageTolerance: inputProps.slippageTolerance,
		userAccount,
		auctionToastEventProps,
		reduceOnly: inputProps.reduceOnly,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		maxLeverageSelected: useMaxleverage,
		toastId,
		oracleOffsetOrdersEnabled: currentSettings.oracleOffsetOrdersEnabled,
		isOracleOrder,
	};
};

const getStrictPerpMarketOrderParams = (
	inputProps: ProcessedPerpMarketOrderParams
): StrictPerpMarketOrderParams => {
	const derivedParams = COMMON_UI_UTILS.deriveMarketOrderParams({
		marketType: MarketType.PERP,
		marketIndex: inputProps.marketId.marketIndex,
		direction: inputProps.direction,
		baseAmount: inputProps.baseAmount,
		reduceOnly: inputProps.reduceOnly,
		allowInfSlippage: inputProps.allowInfSlippage,
		worstPrice: inputProps.worstPrice,
		auctionDuration: inputProps.auctionDuration,
		auctionStartPriceOffset: inputProps.auctionStartPriceOffset,
		auctionEndPriceOffset: inputProps.auctionEndPriceOffset,
		maxLeverageSelected: inputProps.maxLeverageSelected,
		maxLeverageOrderSize: inputProps.maxLeverageOrderSize,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestPrice: inputProps.bestPrice,
		entryPrice: inputProps.entryPrice,
		auctionStartPriceOffsetFrom: inputProps.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: inputProps.auctionEndPriceOffsetFrom,
		auctionPriceCaps: inputProps.auctionPriceCaps,
		slippageTolerance: inputProps.slippageTolerance,
		isOracleOrder: inputProps.isOracleOrder,
		additionalEndPriceBuffer: inputProps.additionalEndPriceBuffer,
	});

	return {
		reduceOnly: derivedParams.reduceOnly,
		marketIndex: derivedParams.marketIndex,
		marketType: derivedParams.marketType,
		baseAssetAmount: derivedParams.baseAssetAmount,
		direction: derivedParams.direction,
		orderType: derivedParams.orderType,
		// maxTs: derivedParams.maxTs,
		auctionDuration: derivedParams.auctionDuration,
		auctionStartPrice: derivedParams.auctionStartPrice,
		auctionEndPrice: derivedParams.auctionEndPrice,
		price: derivedParams.price,
		oraclePriceOffset: derivedParams.oraclePriceOffset ?? 0,
	};
};

const getStrictPerpPlaceAndTakeOrderParams = (
	inputProps: ProcessedPerpMarketOrderParams
): StrictPerpPlaceAndTakeOrderParams => {
	const derivedParams = COMMON_UI_UTILS.deriveMarketOrderParams({
		marketType: MarketType.PERP,
		marketIndex: inputProps.marketId.marketIndex,
		direction: inputProps.direction,
		baseAmount: inputProps.baseAmount,
		reduceOnly: inputProps.reduceOnly,
		allowInfSlippage: inputProps.allowInfSlippage,
		worstPrice: inputProps.worstPrice,
		auctionDuration: inputProps.auctionDuration,
		auctionStartPriceOffset: inputProps.auctionStartPriceOffset,
		auctionEndPriceOffset: inputProps.auctionEndPriceOffset,
		maxLeverageSelected: inputProps.maxLeverageSelected,
		maxLeverageOrderSize: inputProps.maxLeverageOrderSize,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestPrice: inputProps.bestPrice,
		entryPrice: inputProps.entryPrice,
		auctionStartPriceOffsetFrom: inputProps.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: inputProps.auctionEndPriceOffsetFrom,
		auctionPriceCaps: inputProps.auctionPriceCaps,
		slippageTolerance: inputProps.slippageTolerance,
		isOracleOrder: inputProps.isOracleOrder,
		additionalEndPriceBuffer: inputProps.additionalEndPriceBuffer,
	});

	return {
		reduceOnly: derivedParams.reduceOnly,
		marketIndex: derivedParams.marketIndex,
		marketType: derivedParams.marketType,
		baseAssetAmount: derivedParams.baseAssetAmount,
		direction: derivedParams.direction,
		orderType: derivedParams.orderType,
		auctionDuration: derivedParams.auctionDuration,
		auctionStartPrice: derivedParams.auctionStartPrice,
		auctionEndPrice: derivedParams.auctionEndPrice,
		price: derivedParams.price,
		oraclePriceOffset: derivedParams.oraclePriceOffset ?? 0,
	};
};

const getStrictSpotMarketOrderParams = (
	inputProps: ProcessedSpotMarketOrderParams
): StrictSpotBasicMarketOrderParams => {
	const derivedParams = COMMON_UI_UTILS.deriveMarketOrderParams({
		marketType: MarketType.SPOT,
		marketIndex: inputProps.marketId.marketIndex,
		direction: inputProps.direction,
		baseAmount: inputProps.baseAmount,
		reduceOnly: inputProps.reduceOnly,
		allowInfSlippage: inputProps.allowInfSlippage,
		worstPrice: inputProps.worstPrice,
		auctionDuration: inputProps.auctionDuration,
		auctionStartPriceOffset: inputProps.auctionStartPriceOffset,
		auctionEndPriceOffset: inputProps.auctionEndPriceOffset,
		maxLeverageSelected: inputProps.maxLeverageSelected,
		maxLeverageOrderSize: inputProps.maxLeverageOrderSize,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestPrice: inputProps.bestPrice,
		entryPrice: inputProps.entryPrice,
		auctionStartPriceOffsetFrom: inputProps.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: inputProps.auctionEndPriceOffsetFrom,
		slippageTolerance: inputProps.slippageTolerance,
		isOracleOrder: inputProps.oracleOffsetOrdersEnabled,
	});

	return {
		price: derivedParams.price,
		reduceOnly: derivedParams.reduceOnly,
		orderType: derivedParams.orderType,
		marketIndex: derivedParams.marketIndex,
		baseAssetAmount: derivedParams.baseAssetAmount,
		direction: derivedParams.direction,
		marketType: derivedParams.marketType,
		auctionDuration: derivedParams.auctionDuration,
		auctionStartPrice: derivedParams.auctionStartPrice,
		auctionEndPrice: derivedParams.auctionEndPrice,
		oraclePriceOffset: derivedParams.oraclePriceOffset ?? 0,
	};
};

const getStrictSpotOracleOrderParams = (
	inputProps: ProcessedSpotMarketOrderParams
): StrictSpotOracleMarketOrderParams => {
	const derivedParams = COMMON_UI_UTILS.deriveMarketOrderParams({
		marketType: MarketType.SPOT,
		marketIndex: inputProps.marketId.marketIndex,
		direction: inputProps.direction,
		baseAmount: inputProps.baseAmount,
		reduceOnly: inputProps.reduceOnly,
		allowInfSlippage: inputProps.allowInfSlippage,
		worstPrice: inputProps.worstPrice,
		auctionDuration: inputProps.auctionDuration,
		auctionStartPriceOffset: inputProps.auctionStartPriceOffset,
		auctionEndPriceOffset: inputProps.auctionEndPriceOffset,
		maxLeverageSelected: inputProps.maxLeverageSelected,
		maxLeverageOrderSize: inputProps.maxLeverageOrderSize,
		oraclePrice: inputProps.oraclePrice,
		markPrice: inputProps.markPrice,
		bestPrice: inputProps.bestPrice,
		entryPrice: inputProps.entryPrice,
		auctionStartPriceOffsetFrom: inputProps.auctionStartPriceOffsetFrom,
		auctionEndPriceOffsetFrom: inputProps.auctionEndPriceOffsetFrom,
		slippageTolerance: inputProps.slippageTolerance,
		isOracleOrder: inputProps.oracleOffsetOrdersEnabled,
	});

	return {
		reduceOnly: derivedParams.reduceOnly,
		orderType: derivedParams.orderType,
		marketIndex: derivedParams.marketIndex,
		baseAssetAmount: derivedParams.baseAssetAmount,
		direction: derivedParams.direction,
		marketType: derivedParams.marketType,
		auctionDuration: derivedParams.auctionDuration,
		auctionStartPrice: derivedParams.auctionStartPrice,
		auctionEndPrice: derivedParams.auctionEndPrice,
		oraclePriceOffset: derivedParams.oraclePriceOffset ?? 0,
	};
};

export const ORDER_PREP_UTILS = {
	prepPerpMarketOrderParams,
	prepSpotMarketOrderParams,
};
