import { OffChainVaultStats, PeriodApys, VaultStats } from 'src/@types/vaults';
import { BigNum, BN, QUOTE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import { S3_BUCKET_DRIFT_PUBLIC_MAINNET } from '../constants';

export const VAULT_MANAGER_IMG_BUCKET = `${S3_BUCKET_DRIFT_PUBLIC_MAINNET}/protocols`;

export const VAULT_CONFIGS_REMOTE_URL = `${S3_BUCKET_DRIFT_PUBLIC_MAINNET}/vaults/configs.json`;

export const VAULT_TERMS_AND_CONDITIONS_DO_NOT_SHOW_AGAIN_KEY =
	'vaultTermsAndConditionsDoNotShowAgain';

export const FEES_PLACEHOLDER = 'fees_placeholder';
export const MANAGEMENT_FEE_PLACEHOLDER = 'management_fee_placeholder';
export const PERIOD_PLACEHOLDER = 'period_placeholder';

export type Placeholders =
	| typeof FEES_PLACEHOLDER
	| typeof MANAGEMENT_FEE_PLACEHOLDER
	| typeof PERIOD_PLACEHOLDER;

export const CAPACITY_WARNING_THRESHOLD = 90;

export const DEFAULT_PERIOD_APY: keyof PeriodApys = '90d';

export const VAULT_TEMP_APY_LABEL = 'Target APY';
export const VAULT_TARGET_APY_TOOLTIP_CONTENT =
	'This vault is under 30 days old, hence the target APY set by the vault manager is used instead.';
export const VAULT_APY_LABEL = 'APY';

export const VAULT_NOTIONAL_GROWTH_STRATEGY_INFO_TEXT =
	'This vault focuses on the USDC value of your deposits.';

export const DEFAULT_OFF_CHAIN_STATS: OffChainVaultStats = {
	apys: {
		'7d': 0,
		'30d': 0,
		'90d': 0,
	},
	maxDrawdownPct: 0,
	numOfVaultSnapshots: 0,
	hasLoadedOffChainStats: false,
};

export const GET_DEFAULT_VAULT_STATS = (basePrecisionExp: BN): VaultStats => {
	const baseZero = BigNum.from(0, basePrecisionExp);
	const quoteZero = BigNum.from(0, QUOTE_PRECISION_EXP);

	return {
		capacityPct: 0,
		isUncappedCapacity: false,
		totalBasePnl: baseZero,
		totalQuotePnl: quoteZero,
		tvlBase: baseZero,
		tvlQuote: quoteZero,
		volume30Days: quoteZero,
		isOnChainStatsLoaded: false,
		totalShares: baseZero,
		vaultRedeemPeriodSecs: ZERO,
		notionalGrowthQuotePnl: quoteZero,
		hasLoadedOnChainStats: false,
		profitShare: 0,
		...DEFAULT_OFF_CHAIN_STATS,
	};
};
