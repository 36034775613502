'use client';

import {
	PriorityFeeStrategy,
	SolanaPriorityFeeResponse,
} from '@drift-labs/sdk';
import { useCallback, useEffect, useRef } from 'react';
import useInterval from 'src/hooks/useInterval';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Env from '../../environmentVariables/EnvironmentVariables';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import usePriorityFeeStore from '../../stores/usePriorityFeeStore';
import {
	PriorityFeeCalculator,
	SANITY_CHECK_ABS_MAX_FEE_IN_SOL,
} from '../../utils/PriorityFeeCalculator';
import {
	BOOSTED_MULITPLIER,
	FEE_STRATEGY_TARGET_PERCENTILE,
	FEE_SUBSCRIPTION_SLOT_LOOKBACK,
	TURBO_MULTIPLIER,
} from './consts';
import useHeliusSourcedPriorityFeeSample from './useHeliusSourcedPriorityFees';
import usePriorityFeeSubscriber from './usePriorityFeeSubscriber';
import useRecentTimeouts from './useRecentTimeouts';
import useDriftClient from 'src/hooks/useDriftClient';
import { MarketId } from '@drift/common';
import { ComputeUnits, PriorityFee } from '../../@types/types';
import { DriftWindow } from '../../window/driftWindow';
import { dlog } from 'src/dev';

// add an extra multiplier to polling prioritization fees, we can force update before transactions so less need to spam
const EXTRA_POLLING_MULTIPLIER = Env.feeSubscriptionPollingMultiplier;

const RECENT_SAMPLES: number[][] = [];
let LAST_SEEN_SLOT_IN_SAMPLES = 0;

const CUSTOM_FEE_STRATEGY: PriorityFeeStrategy = {
	calculate(newSamples: SolanaPriorityFeeResponse[]) {
		if (!newSamples?.length) return 0;

		const filteredSamples = newSamples.filter(
			(sample) => sample.slot > LAST_SEEN_SLOT_IN_SAMPLES
		);

		RECENT_SAMPLES.unshift(newSamples.map((s) => s.prioritizationFee));
		RECENT_SAMPLES.splice(FEE_SUBSCRIPTION_SLOT_LOOKBACK);

		const allRecentSamplesAscendingSorted = RECENT_SAMPLES.flat().sort(
			(a, b) => {
				return a - b;
			}
		);

		const targetPercentileIndex = Math.min(
			allRecentSamplesAscendingSorted.length - 1,
			Math.ceil(
				(allRecentSamplesAscendingSorted.length / 100) *
					FEE_STRATEGY_TARGET_PERCENTILE
			)
		);

		const shouldSplitWithBelow =
			FEE_STRATEGY_TARGET_PERCENTILE < 100 &&
			targetPercentileIndex >= allRecentSamplesAscendingSorted.length - 1; // If the number of samples being returned are sufficiently small then in practise the target percentile is just selecting the MAX priority fee every time. For some safety, average top two instead when we get this case.

		const pFee = shouldSplitWithBelow
			? (allRecentSamplesAscendingSorted[targetPercentileIndex] +
					allRecentSamplesAscendingSorted[targetPercentileIndex - 1]) /
			  2
			: allRecentSamplesAscendingSorted[targetPercentileIndex];

		LAST_SEEN_SLOT_IN_SAMPLES = Math.max(
			...filteredSamples.map((sample) => sample.slot)
		);

		return pFee;
	},
};

const PRIORITY_FEE_STRATEGY = CUSTOM_FEE_STRATEGY;

const useSyncPriorityFeeStore = () => {
	const priorityFeeStore = usePriorityFeeStore();

	const pollingMultiplier = useDriftStore((s) => s.pollingMultiplier);
	const pollMs =
		Env.pollingFrequencyMs * pollingMultiplier * EXTRA_POLLING_MULTIPLIER;

	const timeoutCount = useRecentTimeouts(pollMs);
	const priorityFeeSubscriber = usePriorityFeeSubscriber(PRIORITY_FEE_STRATEGY);

	const driftClient = useDriftClient();

	const heliusFeeSample = useHeliusSourcedPriorityFeeSample();

	const latestSubscriberFeeSample = useRef<number>(undefined);

	// Log the differences in sourced priority fees whenever they change
	useEffect(() => {
		dlog(`priority_fees`, `updated_helius_and_subscriber_samples`, {
			helius: heliusFeeSample.current,
			subscriber: latestSubscriberFeeSample.current,
		});
	}, [heliusFeeSample.current, latestSubscriberFeeSample.current]);

	const [
		{
			priorityFee: userSettingsPriorityFee,
			customPriorityFee: userSettingsCustomPriorityFee,
			maxPriorityFeeCap: userSettingsPriorityFeeCap,
		},
	] = useCurrentSettings();

	const updateRecentSubscriberFeeHistory = useCallback(() => {
		if (!priorityFeeSubscriber) {
			return;
		}

		const latestSubscriberFeeResult =
			priorityFeeSubscriber.lastCustomStrategyResult;

		latestSubscriberFeeSample.current = latestSubscriberFeeResult;
	}, [priorityFeeSubscriber, timeoutCount]);

	useInterval(updateRecentSubscriberFeeHistory, pollMs);

	const getDynamicCUPrice = (boostMultiplier?: number, marketId?: MarketId) => {
		const heliusSample = marketId
			? heliusFeeSample.current[marketId.key] ??
			  heliusFeeSample.current.fallback
			: heliusFeeSample.current.fallback;

		let cuPriceSampleToUse = Math.max(
			latestSubscriberFeeSample?.current ?? 0,
			heliusSample ?? 0
		);

		if (isNaN(cuPriceSampleToUse)) {
			// Fallback for safety
			cuPriceSampleToUse = 0;
			DriftWindow.metricsBus?.next({
				type: 'dev_invariant',
				value: 'Fee Sample is NaN',
			});
		}

		const useCUPriceSampleOverride = !!localStorage?.getItem(
			`DEV_FEE_SAMPLE_OVERRIDE`
		);

		if (useCUPriceSampleOverride) {
			cuPriceSampleToUse = parseFloat(
				localStorage?.getItem(`DEV_FEE_SAMPLE_OVERRIDE`)
			);
		}

		if (useCUPriceSampleOverride) {
			console.log(`USING DEV_FEE_SAMPLE_OVERRIDE : ${cuPriceSampleToUse}`);
		}

		const dynamicCUPriceTouse =
			PriorityFeeCalculator.calculateDynamicCUPriceToUse({
				latestFeeSample: cuPriceSampleToUse,
				boostMultiplier: boostMultiplier,
			});

		return dynamicCUPriceTouse;
	};

	const getPriorityFeeToUse = useCallback(
		(
			feeTypeOverride?: typeof userSettingsPriorityFee,
			txComputeUnits?: ComputeUnits,
			marketId?: MarketId
		): PriorityFee => {
			const priorityFeeSetting = feeTypeOverride ?? userSettingsPriorityFee;

			let cuPriceToUse: number;
			if (priorityFeeSetting === 'custom') {
				cuPriceToUse = PriorityFeeCalculator.calculateCUPriceForTargetSolValue(
					userSettingsCustomPriorityFee ?? 0
				);
			}

			if (priorityFeeSetting === 'dynamic') {
				cuPriceToUse = getDynamicCUPrice(undefined, marketId);
			}

			if (priorityFeeSetting === 'boosted') {
				cuPriceToUse = getDynamicCUPrice(BOOSTED_MULITPLIER, marketId);
			}

			if (priorityFeeSetting === 'turbo') {
				cuPriceToUse = getDynamicCUPrice(TURBO_MULTIPLIER, marketId);
			}

			if (isNaN(cuPriceToUse)) {
				DriftWindow.metricsBus?.next({
					type: 'dev_invariant',
					value: 'Priority Fee Calculator result is NaN',
				});
				cuPriceToUse = 0;
			}

			// apply the user max cap if not a custom fixed fee
			if (userSettingsPriorityFee !== 'custom') {
				const computeUnitPriceCapForFeeCap =
					PriorityFeeCalculator.calculateCUPriceForTargetSolValue(
						userSettingsPriorityFeeCap,
						txComputeUnits
					);
				cuPriceToUse = Math.min(cuPriceToUse, computeUnitPriceCapForFeeCap);
			} else {
				const sanityCheckMaxFee =
					PriorityFeeCalculator.calculateCUPriceForTargetSolValue(
						SANITY_CHECK_ABS_MAX_FEE_IN_SOL,
						txComputeUnits
					);
				// shouldnt be possible
				cuPriceToUse = Math.min(cuPriceToUse, sanityCheckMaxFee);
			}

			if (driftClient) {
				// update the compute units price in driftClient for any actions that don't explicitly pass it
				driftClient.txParams = {
					...driftClient.txParams,
					computeUnitsPrice: cuPriceToUse,
				};
			}

			return cuPriceToUse as PriorityFee;
		},
		[
			userSettingsPriorityFee,
			userSettingsCustomPriorityFee,
			userSettingsPriorityFeeCap,
			timeoutCount,
			driftClient,
		]
	);

	useEffect(() => {
		priorityFeeStore.set((s) => {
			s.ready = true;
			s.getPriorityFeeToUse = getPriorityFeeToUse;
		});
	}, [getPriorityFeeToUse]);
};

export default useSyncPriorityFeeStore;
