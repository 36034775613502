'use client';

import { Close, Details, LP, Money, Share, Swap } from '@drift-labs/icons';
import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	ContractType,
	MAX_PREDICTION_PRICE,
	MarketStatus,
	MarketType,
	OrderType,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	PerpMarketAccount,
	PerpMarketConfig,
	PositionDirection,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	OpenPosition,
	UIOrderType,
	UI_ORDER_TYPES_LIST,
	matchEnum,
	MarketId,
	UISerializableOrder,
	UIMarket,
	UIOrderTypeValue,
} from '@drift/common';
import { useCallback, useRef, useState } from 'react';
import Env, {
	OrderedPerpMarkets,
	PERP_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import { useTickedAccountData } from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';
import useIsMobile from 'src/hooks/useIsMobileScreenSize';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useShowAccountValues from '../hooks/useShowAccountValues';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import Button from './Button';
import TableActionButton from './Buttons/TableActionButton';
import ClosePositionPopup, {
	ORDER_TYPE_SELECT_ID,
} from './Popups/ClosePositionPopup';
import TableV2 from './Tables/TableV2';
import Text from './Text/Text';
import Tooltip from './Tooltip/Tooltip';
import NumberDisplay from './Utils/NumberDisplay';
import ValueChangeBlink from './Utils/ValueChangeBlink';
import { twMerge } from 'tailwind-merge';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import useMarketStateStore from '../stores/useMarketStateStore';
import React from 'react';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useWindowSizeString from 'src/hooks/useWindowSize';
import EditOrderPopup from './Popups/EditOrderPopup';
import { TakeProfitStopLossButton } from './Buttons/TakeProfitStopLossButton';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import PopoverWrapper from './PopoverWrapper';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import UI_UTILS from 'src/utils/uiUtils';
import {
	MAX_PREDICTION_PRICE_BIGNUM,
	ZERO_PRICE_BIGNUM,
} from 'src/constants/math';
import PositionsAndOrdersFilterDropdown, {
	PositionsAndOrdersFilterOption,
} from './Utils/PositionAndOrdersFiltersDropdown';
import TableStateWrapper from './TableStateWrapper';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import useGetTickSizeForMarket, {
	getTickSizeDecimalPlaces,
} from 'src/hooks/useGetTickSizeForMarket';
import NumLib from 'src/utils/NumLib';
import { useTableFiltersState } from 'src/hooks/useTableFiltersState';
import Chevron from './Icons/Chevron';
import NumberDisplayV3 from './Utils/NumberDisplayV3';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';

export interface AccountOpenPosition extends OpenPosition {
	accountName: string;
	accountKey: string;
	marketAccount: PerpMarketAccount;
	isLpPosition?: boolean;
	isDustPosition?: boolean;
	showLpEntryPriceTooltip?: boolean;
}

interface OpenPositionTableRowProps {
	position: AccountOpenPosition;
	tableGrid: string;
	smallTableGrid: string;
	predictionsTableGrid: string;
	small: boolean;
	isMobile: boolean;
	pnlReference: 'mark' | 'oracle';
	isOverviewPage: boolean;
	isPredictionsPage: boolean;
	handleSelection: (market: PerpMarketConfig) => void;
	handleCopySize: (size: string) => void;
	handleShowPnlModal: (position: OpenPosition, pnl: number) => void;
	handleEditLp: (marketIndex: number) => void;
	handleViewLpDetails: (marketIndex: number) => void;
	handleTogglePnlReference: () => void;
}

const _pageSize = 8;
const CLOSE_POSITION_ORDER_TYPES: OrderType[] = [
	OrderType.LIMIT,
	OrderType.MARKET,
	OrderType.TRIGGER_LIMIT,
	OrderType.TRIGGER_MARKET,
];

// hide scaledOrders option for closing positions for now
const CLOSE_POSITION_ORDER_TYPE_OPTIONS: UIOrderTypeValue[] =
	UI_ORDER_TYPES_LIST.filter((uiOrderType) =>
		CLOSE_POSITION_ORDER_TYPES.some((includedOrderType) =>
			matchEnum(includedOrderType, uiOrderType.orderType)
		)
	).filter((uiOrderType) => uiOrderType.value !== 'scaledOrders');

const CLOSE_PREDICTION_MARKET_ORDER_TYPES: OrderType[] = [
	OrderType.LIMIT,
	OrderType.MARKET,
];

const OpenPositionTableRow = ({
	position,
	tableGrid,
	smallTableGrid,
	predictionsTableGrid,
	small,
	pnlReference,
	isMobile,
	isOverviewPage,
	isPredictionsPage,
	handleSelection,
	handleCopySize,
	handleShowPnlModal,
	handleEditLp,
	handleViewLpDetails,
	handleTogglePnlReference,
}: OpenPositionTableRowProps) => {
	const {
		marketSymbol,
		marketIndex,
		direction,
		entryPrice,
		liqPrice,
		accountKey,
		baseSize,
	} = position;

	const set = useDriftStore((s) => s.set);
	const setDrawers = useDrawersStore((s) => s.set);
	const actions = useDriftActions();

	const baseSizeBigNum = BigNum.from(baseSize.abs(), BASE_PRECISION_EXP);

	const isLong = direction === 'long';
	const orderTypeDropdownElementId = `order_type_dropdown_${marketSymbol}`;

	const isPredictionMarketPosition = ENUM_UTILS.match(
		position.marketAccount.contractType,
		ContractType.PREDICTION
	);
	const isMarketInSettlementMode = ENUM_UTILS.match(
		position.marketAccount.status,
		MarketStatus.SETTLEMENT
	);

	const [closePositionPopupType, setClosePositionPopupType] = useState<
		'takeProfit' | 'stopLoss' | 'closePosition'
	>('closePosition');
	const [closePositionPopupReference, setClosePositionPopupReference] =
		useState(null);

	const [orderToEdit, setOrderToEdit] = useState<UISerializableOrder | null>(
		null
	);
	const [editOrderPopupReference, setEditOrderPopupReference] = useState(null);

	const uiMarket = UIMarket.createPerpMarket(marketIndex);
	const marketId = uiMarket.marketId;

	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket({
			isPredictionMarket: uiMarket.isPredictionMarket,
			isSellSide: direction === 'short',
		})
	);

	const getTickSizeForMarket = useGetTickSizeForMarket();
	const tickSize = getTickSizeForMarket(uiMarket.marketId);
	const decimalPlaces = getTickSizeDecimalPlaces(tickSize);

	const getMarkPriceForMarket = useMarketStateStore(
		(s) => s.getMarkPriceForMarket
	);
	const markPrice = getMarkPriceForMarket(marketId) ?? ZERO_PRICE_BIGNUM;
	const markPriceToUse = isSellPredictionMarket
		? MAX_PREDICTION_PRICE_BIGNUM.sub(markPrice)
		: markPrice;

	const getOraclePrice = useGetOraclePriceForMarket();
	const oraclePrice = getOraclePrice(marketId);

	const [userSettings] = useCurrentSettings();
	const accountLeverage =
		useDriftAccountStore(
			(s) => s.accounts[s.currentUserKey]?.marginInfo?.leverage
		) ?? 1;
	const advancedLp =
		useDriftAccountStore(
			(s) => s.accounts[s.currentUserKey]?.marginInfo?.advancedLp
		) ?? false;

	const {
		refs: orderTypesDropdownRefs,
		floatingStyles: orderTypesDropdownFloatingStyles,
		getReferenceProps: getOrderTypesDropdownReferenceProps,
		getFloatingProps: getOrderTypesDropdownFloatingProps,
		setIsPopoverOpen: setIsMoreOrderTypesDropdownOpen,
		isPopoverOpen: isMoreOrderTypesDropdownOpen,
	} = useTargetedPopover(
		{
			placement: 'bottom-end',
			strategy: 'fixed',
		},
		{
			disableAutoPlacement: !!isOverviewPage,
		}
	);

	const {
		refs: closePositionsPopupRefs,
		floatingStyles: closePositionsPopupFloatingStyles,
		getFloatingProps: getClosePositionsPopupFloatingProps,
		setIsPopoverOpen: setIsClosePositionsPopupOpen,
		isPopoverOpen: isClosePositionsPopupOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: isOverviewPage ? 'left' : 'left-end',
			elements: {
				reference: closePositionPopupReference,
			},
		},
		{ nestedPopoverIds: [ORDER_TYPE_SELECT_ID], disableAutoPlacement: true }
	);

	const {
		refs: editOrderPopupRefs,
		floatingStyles: editOrderPopupFloatingStyles,
		getFloatingProps: getEditOrderPopupFloatingProps,
		setIsPopoverOpen: setIsEditOrderPopupOpen,
		isPopoverOpen: isEditOrderPopupOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: isOverviewPage ? 'left' : 'left-end',
			elements: {
				reference: editOrderPopupReference,
			},
		},
		{ disableAutoPlacement: true }
	);

	const closePositionButtonRef = useRef<HTMLDivElement>(null);

	const notional = BigNum.from(
		position.notional,
		QUOTE_PRECISION_EXP
	).toNotional();

	const showAccountValues = useShowAccountValues();

	const pnlReferenceToUse = isPredictionMarketPosition ? 'mark' : pnlReference;
	const positionPnl = BigNum.from(
		pnlReferenceToUse === 'mark' ? position.pnlVsMark : position.pnlVsOracle,
		QUOTE_PRECISION_EXP
	);

	const fundingPnl = BigNum.from(
		position.feesAndFundingPnl,
		QUOTE_PRECISION_EXP
	);

	const netPnl = positionPnl; // we will remove funding pnl from the UI position table pnl for now, since the UI's wording needs to improve to better inform users that pnl includes funding pnl
	const pnl = netPnl.toNum();
	const dollarPnl = showAccountValues ? `${netPnl.toNotional()}` : '∗∗∗∗∗∗';

	const percentageNum = uiMarket.isPredictionMarket
		? markPriceToUse
				.sub(BigNum.from(entryPrice, PRICE_PRECISION_EXP))
				.mul(PRICE_PRECISION)
				.div(entryPrice)
				.toNum() * 100
		: COMMON_UI_UTILS.calculatePnlPctFromPosition(
				netPnl.val,
				position.isLpPosition ? position.notional : position.quoteEntryAmount,
				userSettings.includeLeverageInPnl ? accountLeverage : 1
		  );

	const percentagePnl = `${percentageNum.toFixed(2)}%`;

	const handleClickMarket = () => {
		handleSelection(PERP_MARKETS_LOOKUP[marketIndex]);
	};

	const handleSelectCloseOrderType = (closePositionOrderType: UIOrderType) => {
		setIsMoreOrderTypesDropdownOpen(false);
		setClosePositionPopupType('closePosition');
		setClosePositionPopupReference(closePositionButtonRef.current);

		set((s) => {
			s.popups.closePositionPopupOptions.marketIndex = marketIndex;
			s.popups.closePositionPopupOptions.uiOrderType = closePositionOrderType;
			s.popups.closePositionPopupOptions.isStopLossOnly =
				closePositionPopupType === 'stopLoss';
			s.popups.closePositionPopupOptions.isTakeProfitOnly =
				closePositionPopupType === 'takeProfit';
			s.modals.showClosePositionModal = isMobile;
		});

		if (!isMobile) {
			setIsClosePositionsPopupOpen(true);
		}
	};

	const handleOpenEditOrderPopup = (
		targetElement: HTMLElement,
		order: UISerializableOrder
	) => {
		setOrderToEdit(order);
		setIsClosePositionsPopupOpen(false);

		if (isMobile) {
			set((s) => {
				s.modals.showEditOrderModal = true;
				s.popups.editOrderPopupOptions.orderToEdit = order;
			});
		} else {
			setEditOrderPopupReference(targetElement);
			setIsEditOrderPopupOpen(true);
		}
	};

	const handleOpenClosePositionPopup = (
		popupOrderType: UIOrderType,
		popupType: 'takeProfit' | 'stopLoss' | 'closePosition',
		targetElement: HTMLElement
	) => {
		setClosePositionPopupType(popupType);
		setClosePositionPopupReference(targetElement);
		setIsEditOrderPopupOpen(false);

		set((s) => {
			s.popups.closePositionPopupOptions.marketIndex = marketIndex;
			s.popups.closePositionPopupOptions.uiOrderType =
				popupOrderType || 'market';
			s.popups.closePositionPopupOptions.isStopLossOnly =
				popupType === 'stopLoss';
			s.popups.closePositionPopupOptions.isTakeProfitOnly =
				popupType === 'takeProfit';
			s.modals.showClosePositionModal = isMobile;
		});

		if (!isMobile) {
			setIsClosePositionsPopupOpen(true);
		}
	};

	const gridToUse = small
		? smallTableGrid
		: isPredictionsPage
		? predictionsTableGrid
		: tableGrid;

	const handleShowPositionDrawer = () => {
		setDrawers((s) => {
			s.position.show = true;
			s.position.marketIndex = position.marketIndex;
			s.position.isLpPosition = position.isLpPosition;
		});
	};

	return (
		<>
			{isMobile ? (
				<TableV2.TableCard
					marketSymbol={marketSymbol}
					marketString={UI_UTILS.getPerpMarketDisplayName(marketIndex)}
					direction={isLong ? PositionDirection.LONG : PositionDirection.SHORT}
					actionButtons={
						<div className="flex flex-row items-center w-full space-x-2">
							{position.isLpPosition && (
								<Button.Secondary
									size="SMALL"
									className="w-[33%]"
									onClick={() => handleViewLpDetails(position.marketIndex)}
									id={`editLp_${position.marketIndex.toString()}_${accountKey}`}
								>
									<LP className="mr-1" /> BAL
								</Button.Secondary>
							)}
							<Button.Secondary
								size="SMALL"
								className={position.isLpPosition ? 'w-[33%]' : 'w-1/2'}
								onClick={handleShowPositionDrawer}
							>
								<Details className="mr-1" /> Details
							</Button.Secondary>
							<Button.Secondary
								size="SMALL"
								className={position.isLpPosition ? 'w-[33%]' : 'w-1/2'}
								positiveGreen={pnl >= 0}
								negativeRed={pnl < 0}
								onClick={() => {
									handleOpenClosePositionPopup(
										'market',
										'closePosition',
										closePositionButtonRef.current
									);
								}}
							>
								Close
							</Button.Secondary>
						</div>
					}
					isDataRow
					className={twMerge(
						'whitespace-nowrap border-t border-container-border m-0'
					)}
					useSpotWording={uiMarket.isSpot}
					isPredictionMarket={uiMarket.isPredictionMarket}
				>
					<TableV2.CardValue
						className="w-[33%]"
						label="Size"
						value={
							<button
								onClick={() =>
									handleCopySize(baseSizeBigNum.toTradePrecision())
								}
								className="flex space-x-1"
							>
								{`${
									showAccountValues
										? UI_UTILS.baseSizeDisplayForPerpMarket(
												baseSizeBigNum,
												position.marketSymbol,
												!showAccountValues
										  )
										: '∗∗∗∗'
								}`}
							</button>
						}
					/>

					<TableV2.CardValue
						className={`w-[33%] items-end ${
							pnl > 0
								? `text-positive-green`
								: pnl < 0
								? `text-negative-red`
								: ``
						}`}
						label="Est. Net P&amp;L"
						icon={
							<Share
								color="var(--text-label)"
								className="sm:w-[18px] sm:h-[18px] w-[14px] h-[14px]"
							/>
						}
						onClick={() => handleShowPnlModal(position, pnl)}
						value={
							<span className="relative flex items-center w-full">
								<ValueChangeBlink value={dollarPnl}>
									<span className="w-24">{dollarPnl}</span>
								</ValueChangeBlink>
								<ValueChangeBlink value={percentagePnl}>
									<span className="w-24 ml-1">({percentagePnl})</span>
								</ValueChangeBlink>
							</span>
						}
					/>

					<TableV2.CardValue
						className="w-[33%] items-end"
						label={
							pnlReference === 'mark' || isPredictionMarketPosition
								? 'Mark'
								: 'Oracle'
						}
						icon={!isPredictionMarketPosition ? <Swap /> : null}
						onClick={
							!isPredictionMarketPosition
								? () => handleTogglePnlReference()
								: null
						}
						value={
							<NumberDisplay
								displayType="tradePrecision"
								value={
									pnlReference === 'mark' || isPredictionMarketPosition
										? markPriceToUse?.toNum()
										: oraclePrice.toNum()
								}
								subType="$"
							/>
						}
					/>
					<TableV2.CardValue
						className="w-[33%] items-start"
						label={'Entry'}
						value={
							<NumberDisplay
								displayType="tradePrecision"
								value={
									position.isDustPosition
										? 0
										: BigNum.from(entryPrice, PRICE_PRECISION_EXP).toNum()
								}
								subType="$"
							/>
						}
					/>

					{!isPredictionMarketPosition && (
						<TableV2.CardValue
							className="w-[33%] items-end"
							label="Take Profit"
							value={
								<TakeProfitStopLossButton
									type="takeProfit"
									position={position}
									handleOpenEditOrderPopup={handleOpenEditOrderPopup}
									handleOpenClosePositionPopup={handleOpenClosePositionPopup}
								/>
							}
						></TableV2.CardValue>
					)}

					{!isPredictionMarketPosition && (
						<TableV2.CardValue
							className="w-[33%] items-end"
							label="Stop Loss"
							value={
								<TakeProfitStopLossButton
									type="stopLoss"
									position={position}
									handleOpenEditOrderPopup={handleOpenEditOrderPopup}
									handleOpenClosePositionPopup={handleOpenClosePositionPopup}
								/>
							}
						></TableV2.CardValue>
					)}
				</TableV2.TableCard>
			) : (
				<TableV2.BodyRow grid={gridToUse} isDataRow>
					{/** Desktop - Market + Direction */}
					{isPredictionMarketPosition ? (
						<TableV2.OpenPredictionPositionIdCell
							title={PredictionMarketConfigs.get(position.marketIndex).title}
							marketIndex={position.marketIndex}
							direction={position.direction}
							small
						/>
					) : (
						<TableV2.OpenPositionMarketDirectionCell
							handleClickMarket={handleClickMarket}
							position={position}
						/>
					)}

					{/** Desktop - Size */}
					<TableV2.BodyCell className="font-numeral text-text-default">
						<button
							className="flex flex-col"
							onClick={() => handleShowPositionDrawer()}
						>
							<span className="text-left">
								{UI_UTILS.baseSizeDisplayForPerpMarket(
									baseSizeBigNum,
									position.marketSymbol,
									!showAccountValues
								)}
							</span>
							<ValueChangeBlink
								value={showAccountValues ? notional : '$ ∗∗∗∗∗∗'}
							>
								<span className="text-text-secondary">
									{showAccountValues ? notional : '$ ∗∗∗∗∗∗'}
								</span>
							</ValueChangeBlink>
						</button>
					</TableV2.BodyCell>

					{/** Desktop - Entry/Mark Price */}
					{!small && (
						<>
							<TableV2.BodyCell className="font-numeral text-text-default">
								<button
									onClick={() => handleShowPositionDrawer()}
									className="flex flex-col"
								>
									{/* Tooltip required if position is lp position from before the entry price program update */}
									{position.showLpEntryPriceTooltip ? (
										<Tooltip
											content="Entry price for older BAL positions may be inaccurate, please reference Unsettled tab for actual performance"
											placement="top"
										>
											$
											{position.isDustPosition
												? '-'
												: NumLib.formatNum.toDecimalPlaces(
														BigNum.from(
															entryPrice,
															PRICE_PRECISION_EXP
														).toNum(),
														decimalPlaces
												  )}
										</Tooltip>
									) : (
										<span>
											<NumberDisplayV3
												value={BigNum.from(entryPrice, PRICE_PRECISION_EXP)}
												formattingProps={{
													toPrecision: 6,
													displayType: 'price$',
												}}
											/>
										</span>
									)}

									<Text.BODY3 className="text-text-secondary">
										<NumberDisplayV3
											value={
												pnlReferenceToUse === 'mark'
													? markPriceToUse
													: oraclePrice
											}
											formattingProps={{
												toPrecision: 6,
												displayType: 'price$',
											}}
										/>
									</Text.BODY3>
								</button>
							</TableV2.BodyCell>
						</>
					)}

					{/** Desktop - PnL */}
					<TableV2.BodyCell
						className={`font-numeral ${
							pnl > 0
								? `text-positive-green`
								: pnl < 0
								? `text-negative-red`
								: ``
						}`}
					>
						<span className="relative flex items-center w-full">
							<Tooltip
								placement="top"
								content={
									<>
										<div className="flex flex-col items-start mb-2 space-y-2">
											<div>
												<Text.BODY3>
													Net P&L based on current{' '}
													{pnlReferenceToUse === 'mark'
														? 'mark price'
														: 'oracle price'}
													. P&L upon closing your position may differ due to
													exit price.
												</Text.BODY3>
											</div>
											<div className="w-full pt-2 border-t border-text-label"></div>
											<div className="flex flex-row items-center justify-between w-full">
												<Text.BODY3>Position P&L: </Text.BODY3>
												<Text.BODY3>
													{showAccountValues
														? positionPnl.toNotional()
														: '$ ∗∗∗∗∗∗'}
												</Text.BODY3>
											</div>
											<div className="flex flex-row items-center justify-between w-full">
												<Text.BODY3>Fees and Funding: </Text.BODY3>
												<Text.BODY3>
													{showAccountValues
														? fundingPnl.toNotional()
														: '$ ∗∗∗∗∗∗'}
												</Text.BODY3>
											</div>
										</div>
									</>
								}
							>
								<ValueChangeBlink value={dollarPnl}>
									<div
										className="flex flex-col cursor-pointer"
										onClick={handleShowPositionDrawer}
									>
										<span>{dollarPnl}</span>
										{!position.isDustPosition && (
											<Text.MICRO1>{percentagePnl}</Text.MICRO1>
										)}
									</div>
								</ValueChangeBlink>
							</Tooltip>

							{!small && (
								<span
									className="flex justify-end w-full hover:opacity-80 hover:cursor-pointer mb-0.5 ml-1"
									onClick={() => handleShowPnlModal(position, pnl)}
								>
									<Share color="var(--text-label)" size={18} />
								</span>
							)}
						</span>
					</TableV2.BodyCell>

					{/** Desktop - TP */}
					{!small && !isPredictionsPage && (
						<TableV2.BodyCell>
							{isPredictionMarketPosition ? (
								'-'
							) : (
								<TakeProfitStopLossButton
									type="takeProfit"
									position={position}
									handleOpenEditOrderPopup={handleOpenEditOrderPopup}
									handleOpenClosePositionPopup={handleOpenClosePositionPopup}
								/>
							)}
						</TableV2.BodyCell>
					)}

					{/** Desktop - SL */}
					{!small && !isPredictionsPage && (
						<TableV2.BodyCell>
							{isPredictionMarketPosition ? (
								'-'
							) : (
								<TakeProfitStopLossButton
									type="stopLoss"
									position={position}
									handleOpenEditOrderPopup={handleOpenEditOrderPopup}
									handleOpenClosePositionPopup={handleOpenClosePositionPopup}
								/>
							)}
						</TableV2.BodyCell>
					)}

					{/** Desktop - Liq. Price */}
					{!small && (
						<TableV2.BodyCell className="font-numeral text-text-default">
							<span className="flex flex-col">
								<ValueChangeBlink
									value={
										showAccountValues
											? BigNum.from(liqPrice, PRICE_PRECISION_EXP).toNotional(
													true
											  )
											: '$ ∗∗∗∗∗∗'
									}
								>
									{showAccountValues
										? BigNum.from(liqPrice, PRICE_PRECISION_EXP).lteZero()
											? 'None'
											: BigNum.from(liqPrice, PRICE_PRECISION_EXP).toNotional(
													true
											  )
										: '$ ∗∗∗∗∗∗'}
								</ValueChangeBlink>
								{position.isLpPosition && !advancedLp && (
									<Tooltip
										placement="top"
										content={`If the oracle price of ${
											OrderedPerpMarkets[position.marketIndex].symbol
										} reaches ${BigNum.from(
											position.lpDeriskPrice,
											PRICE_PRECISION_EXP
										).toNotional(
											true
										)}, de-risking of BAL shares and position will be triggered by the next P&L settle.`}
									>
										<Text.BODY3 className="text-text-secondary">
											<ValueChangeBlink
												value={
													showAccountValues
														? BigNum.from(
																position.lpDeriskPrice,
																PRICE_PRECISION_EXP
														  ).toNotional(true)
														: '$ ∗∗∗∗∗∗'
												}
											>
												{showAccountValues
													? BigNum.from(
															position.lpDeriskPrice,
															PRICE_PRECISION_EXP
													  ).lteZero()
														? 'None'
														: BigNum.from(
																position.lpDeriskPrice,
																PRICE_PRECISION_EXP
														  ).toNotional(true)
													: '$ ∗∗∗∗∗∗'}
											</ValueChangeBlink>
										</Text.BODY3>
									</Tooltip>
								)}
							</span>
						</TableV2.BodyCell>
					)}

					{/** Desktop - Action buttons */}
					<TableV2.BodyCell alignRight>
						<div className="inline-flex items-center gap-2">
							{position.isLpPosition && (
								<Tooltip placement="top" content="Add or remove liquidity">
									<Button.Secondary
										id={`editLp_${position.marketIndex.toString()}_${accountKey}`}
										size="SMALL"
										className="w-8"
										onClick={() => {
											handleEditLp(position.marketIndex);
										}}
									>
										<LP />
									</Button.Secondary>
								</Tooltip>
							)}

							<Tooltip placement="top" content="View position details">
								<Button.Secondary
									size="SMALL"
									onClick={handleShowPositionDrawer}
									className="w-8"
								>
									<Details />
								</Button.Secondary>
							</Tooltip>

							{isMarketInSettlementMode ? (
								<>
									<TableActionButton
										ref={closePositionButtonRef}
										className="justify-start px-0"
										buttonClassName="rounded-l-sm rounded-r-none"
										label="Settle"
										handleClick={() => {
											actions.settlePnl(position.marketIndex);
										}}
										positive={pnl >= 0}
									/>
								</>
							) : (
								<div className="flex flex-row items-center gap-[1px]">
									<Button.Secondary
										// @ts-ignore
										ref={closePositionButtonRef}
										className={twMerge(
											'w-8 py-1 bg-negative-red-secondary-bg text-negative-red hover:text-container-bg rounded-l-none rounded-r-sm',
											pnl < 0
												? 'bg-negative-red-secondary-bg hover:bg-negative-red-hover text-negative-red hover:text-container-bg'
												: 'bg-positive-green-secondary-bg hover:bg-positive-green-hover text-positive-green hover:text-container-bg'
										)}
										size="SMALL"
										negativeRed
										onClick={() => {
											handleOpenClosePositionPopup(
												'market',
												'closePosition',
												closePositionButtonRef.current
											);
										}}
									>
										<Close size={20} />
									</Button.Secondary>

									<Button.Secondary
										id={orderTypeDropdownElementId}
										size="SMALL"
										onClick={() =>
											setIsMoreOrderTypesDropdownOpen((prev) => !prev)
										}
										className={twMerge(
											'w-8',
											'py-1 bg-negative-red-secondary-bg text-negative-red hover:text-container-bg rounded-l-none rounded-r-sm',
											pnl < 0
												? 'bg-negative-red-secondary-bg hover:bg-negative-red-hover text-negative-red hover:text-container-bg'
												: 'bg-positive-green-secondary-bg hover:bg-positive-green-hover text-positive-green hover:text-container-bg'
										)}
										ref={orderTypesDropdownRefs.setReference}
										{...getOrderTypesDropdownReferenceProps()}
									>
										<Chevron
											direction={isMoreOrderTypesDropdownOpen ? 'up' : 'down'}
											size={20}
										/>
									</Button.Secondary>
									{isMoreOrderTypesDropdownOpen && (
										<PopoverWrapper
											ref={orderTypesDropdownRefs.setFloating}
											style={orderTypesDropdownFloatingStyles}
											{...getOrderTypesDropdownFloatingProps()}
											className="w-[146px]"
										>
											<div className="flex flex-col border divide-y divide-container-border border-container-border first:rounded-t last:rounded-b">
												{isPredictionMarketPosition &&
													CLOSE_PREDICTION_MARKET_ORDER_TYPES.map(
														(orderType) => {
															const uiOrderType = UI_ORDER_TYPES_LIST.find(
																(uiType) =>
																	matchEnum(orderType, uiType.orderType)
															);
															return (
																<Text.BODY1
																	key={uiOrderType.value}
																	className="p-2 cursor-pointer hover:bg-container-bg-hover"
																	onClick={() =>
																		handleSelectCloseOrderType(
																			uiOrderType.value
																		)
																	}
																>
																	{uiOrderType.label}
																</Text.BODY1>
															);
														}
													)}
												{!isPredictionMarketPosition &&
													CLOSE_POSITION_ORDER_TYPE_OPTIONS.map((orderType) => (
														<Text.BODY1
															key={orderType.value}
															className="p-2 cursor-pointer hover:bg-container-bg-hover"
															onClick={() =>
																handleSelectCloseOrderType(orderType.value)
															}
														>
															{orderType.label}
														</Text.BODY1>
													))}
											</div>
										</PopoverWrapper>
									)}
								</div>
							)}
						</div>
					</TableV2.BodyCell>
				</TableV2.BodyRow>
			)}

			{isClosePositionsPopupOpen && !isMobile && (
				<ClosePositionPopup
					onClose={() => setIsClosePositionsPopupOpen(false)}
					isOverviewPage={isOverviewPage}
					setFloating={closePositionsPopupRefs.setFloating}
					floatingStyles={closePositionsPopupFloatingStyles}
					getFloatingProps={getClosePositionsPopupFloatingProps}
				/>
			)}

			{orderToEdit && isEditOrderPopupOpen && (
				<EditOrderPopup
					existingOrder={orderToEdit}
					onClose={() => setIsEditOrderPopupOpen(false)}
					setFloating={editOrderPopupRefs.setFloating}
					floatingStyles={editOrderPopupFloatingStyles}
					getFloatingProps={getEditOrderPopupFloatingProps}
				/>
			)}
		</>
	);
};

const OpenPositionsTable = (props: {
	small?: boolean;
	overviewPage?: boolean;
	predictionsPage?: boolean;
}) => {
	const setStore = useDriftStore((s) => s.set);
	const [isTableScrollable, setIsTableScrollable] = useState(false);
	const {
		showOnlyCurrentMarket,
		showPerpMarkets,
		showPredictionMarkets,
		setShowPredictionMarkets,
		setShowPerpMarkets,
		setShowOnlyCurrentMarket,
	} = useTableFiltersState(props);

	const positionFilterOptions: PositionsAndOrdersFilterOption[] = [
		{
			type: 'perpMarkets',
			label: 'Perp Positions',
			value: showOnlyCurrentMarket ? false : showPerpMarkets,
		},
		{
			type: 'predictionMarkets',
			label: 'BETs',
			value: showOnlyCurrentMarket ? false : showPredictionMarkets,
		},
	];

	if (!props.overviewPage) {
		positionFilterOptions.push({
			type: 'selectedMarketOnly',
			label: 'Only selected market',
			value: showOnlyCurrentMarket,
		});
	}

	const selectedMarketIndex = useDriftStore(
		(s) => s.selectedMarket?.current?.market?.marketIndex
	);

	const windowSize = useWindowSizeString();

	const marketDirectionColumnSize =
		windowSize === 'xl' ? 'minmax(130px, 11fr)' : 'minmax(100px, 8fr)';

	const tableGrid = `${marketDirectionColumnSize} minmax(110px,10fr) minmax(110px,10fr)  minmax(100px,9fr) minmax(105px,9fr) minmax(105px,9fr)minmax(100px,9fr) minmax(160px,5fr)`;
	const smallTableGrid = `minmax(150px, 15fr) minmax(95px,10fr) minmax(95px,8fr) minmax(100px,10fr)`;
	const predictionsTableGrid = `minmax(340px, 8fr) minmax(110px,10fr) minmax(110px,10fr)  minmax(100px,9fr) minmax(100px,9fr) minmax(160px,5fr)`;

	const currentAccount = useTickedAccountData();
	const actions = useDriftActions();
	const perpMarketInfoLookup =
		useMarketsInfoStore()?.allMarketsInfo?.perpLookup;
	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);
	const checkIsSellPredictionMarket = useDriftStore(
		(s) => s.checkIsSellPredictionMarket
	);

	const markPriceCallback = (marketIndex: number) => {
		return (
			getMarketDataForMarket(MarketId.createPerpMarket(marketIndex))
				?.derivedState?.markPrice.val ?? ZERO
		);
	};

	const displayPositions = getDisplayPosition();
	const anyFiltersApplied = positionFilterOptions.reduce(
		(acc, option) => acc || option.value,
		false
	);
	const filteredPositions = !anyFiltersApplied
		? displayPositions
		: showOnlyCurrentMarket
		? displayPositions
				// Filter for only show current market
				.filter((pos) => pos.marketIndex === selectedMarketIndex)
		: // Filter out hidden market types
		  displayPositions.filter((pos) => {
				if (
					showPredictionMarkets &&
					ENUM_UTILS.match(
						pos.marketAccount.contractType,
						ContractType.PREDICTION
					)
				) {
					return true;
				}
				if (
					showPerpMarkets &&
					ENUM_UTILS.match(
						pos.marketAccount.contractType,
						ContractType.PERPETUAL
					)
				) {
					return true;
				}
		  });

	const hasPredictionMarketPositions = filteredPositions.some((pos) =>
		ENUM_UTILS.match(pos.marketAccount.contractType, ContractType.PREDICTION)
	);

	const hiddenPositionsCount =
		displayPositions.length - filteredPositions.length;

	function getDisplayPosition(): AccountOpenPosition[] {
		if (!currentAccount || !currentAccount?.positionsLoaded) return [];

		const lpMarketIndexes = currentAccount.openPerpPositions
			.filter((pos) => !pos.lpShares.eq(ZERO))
			.map((pos) => pos.marketIndex);

		const lastLpAdded =
			currentAccount.client
				.getUserAccount()
				.lastAddPerpLpSharesTs?.toNumber() ?? Math.round(Date.now() / 1000);

		const normalPositions: AccountOpenPosition[] =
			currentAccount.openPerpPositions
				.filter((pos) => !pos.baseSize.eq(ZERO))
				.map((pos) => {
					return {
						...pos,
						accountName: currentAccount.name,
						accountKey: currentAccount.userKey,
						isLpPosition: lpMarketIndexes.includes(pos.marketIndex),
						isDustPosition: pos.baseSize
							.abs()
							.lt(
								perpMarketInfoLookup[
									pos.marketIndex
								].account.amm.orderStepSize.divn(10)
							),
						showLpEntryPriceTooltip:
							lpMarketIndexes.includes(pos.marketIndex) &&
							lastLpAdded < Env.balEntryPriceCutoffTs,
						marketAccount: perpMarketInfoLookup[pos.marketIndex].account,
					};
				})
				.map((pos) => {
					const isSellPredictionMarket = checkIsSellPredictionMarket({
						isPredictionMarket: ENUM_UTILS.match(
							pos.marketAccount.contractType,
							ContractType.PREDICTION
						),
						isSellSide: pos.direction === 'short',
					});

					if (!isSellPredictionMarket) {
						return pos;
					}

					const markPrice = markPriceCallback(pos.marketIndex);
					const invertedMarkPrice = MAX_PREDICTION_PRICE.sub(markPrice);

					const invertedPosition = {
						...pos,
						notional: invertedMarkPrice
							.mul(pos.baseSize)
							.div(BASE_PRECISION)
							.abs(),
						entryPrice: MAX_PREDICTION_PRICE.sub(pos.entryPrice),
					};

					return invertedPosition;
				});

		return normalPositions;
	}

	const { switchMarket } = useDriftActions();

	const showCloseallPositionsModal = useCallback(() => {
		setStore((s) => {
			s.modals.showCloseAllPositionsModal = true;
			s.modalsProps.closeAllPositionsModal = {
				perpMarketIndexesToClose: filteredPositions.map(
					(pos) => pos.marketIndex
				),
			};
		});
	}, [filteredPositions]);

	const showPnlModal = useCallback(
		(position: OpenPosition, pnl: number) => {
			const isSellPredictionMarket = checkIsSellPredictionMarket({
				isPredictionMarket: UIMarket.createPerpMarket(position.marketIndex)
					.isPredictionMarket,
				isSellSide: position.direction === 'short',
			});
			setStore((s) => {
				s.pnlModalPosition = {
					position,
					actualExitPrice: BigNum.from(
						isSellPredictionMarket
							? MAX_PREDICTION_PRICE.sub(position.entryPrice)
							: position.exitPrice,
						PRICE_PRECISION_EXP
					).toNum(),
					pnl,
					isClosed: false,
					isLpPosition: !position.lpShares.eq(ZERO),
				};
			});
			actions.showModal('showPnlModal');
		},
		[checkIsSellPredictionMarket]
	);

	const showLpModal = useCallback((marketIndex: number) => {
		actions.switchMarket({
			marketIndex,
			marketType: MarketType.PERP,
		});
		actions.showModal('showAddLiquidityModal');
	}, []);

	const showLpDetailsModal = useCallback((marketIndex: number) => {
		actions.switchMarket({
			marketIndex,
			marketType: MarketType.PERP,
		});
		actions.showModal('showLpDetailsModal');
	}, []);

	const copySize = useCallback(
		(marketIndex: number) => (size: string) => {
			setStore((s) => {
				if (
					ENUM_UTILS.match(
						s.selectedMarket.current.marketType,
						MarketType.PERP
					) &&
					s.selectedMarket.current.market.marketIndex === marketIndex
				) {
					s.tradeForm.leadSide = 'base';
					// @ts-expect-error
					s.tradeForm.baseSizeStringValue = size;
				}
			});
		},
		[]
	);

	const handleSelection = useCallback((market: PerpMarketConfig) => {
		switchMarket({
			marketIndex: market.marketIndex,
			marketType: MarketType.PERP,
			goToTradePage: true,
		});
	}, []);

	const handlechangeFilters = useCallback(
		(filterOption: PositionsAndOrdersFilterOption) => {
			switch (filterOption.type) {
				case 'perpMarkets':
					setShowPerpMarkets(filterOption.value);
					break;
				case 'predictionMarkets':
					setShowPredictionMarkets(filterOption.value);
					break;
				case 'selectedMarketOnly':
					setShowOnlyCurrentMarket(filterOption.value);
					break;
			}
		},
		[setShowPerpMarkets, setShowPredictionMarkets]
	);

	// Store pnl display type preference in local storage
	const [_pnlReference, setPnlReference] = useLocalStorageState<
		'mark' | 'oracle'
	>('pnlReference', 'mark');

	const pnlReference = props.predictionsPage ? 'mark' : _pnlReference;

	const togglePnlReference = () => {
		setPnlReference(pnlReference === 'mark' ? 'oracle' : 'mark');
	};

	const isMobile = useIsMobile();

	const headers = props.small
		? ['market', 'size', 'p&l', 'action']
		: props.predictionsPage
		? ['market', 'size', `entry/mark`, 'p&l', 'liq price', 'action']
		: [
				'market',
				'size',
				`entry/${pnlReference}`,
				'p&l',
				'Take Profit',
				'Stop Loss',
				'liq price',
				'action',
		  ];

	const allMarketsControls = (
		<div className={`items-center w-full flex justify-between p-1`}>
			<PositionsAndOrdersFilterDropdown
				filterOptions={positionFilterOptions}
				onChange={handlechangeFilters}
				labelHelperText={
					hiddenPositionsCount === 0
						? 'Showing all positions'
						: `Showing ${filteredPositions.length}/${displayPositions.length} positions`
				}
			/>

			<Button.Secondary
				size="SMALL"
				disabled={!filteredPositions || !filteredPositions.length}
				onClick={showCloseallPositionsModal}
				negativeRed
				className="whitespace-nowrap"
			>
				{`Close ${filteredPositions?.length || 0} Position${
					filteredPositions?.length === 1 ? '' : 's'
				}`}
			</Button.Secondary>
		</div>
	);

	const gridToUse = props.small
		? smallTableGrid
		: props.predictionsPage
		? predictionsTableGrid
		: tableGrid;

	const tableContent = (
		<TableStateWrapper
			requireAccountCreated
			records={displayPositions}
			emptyStateText={'No open positions found'}
			requireWalletConnect
			id="open_positions_table"
		>
			<TableV2.Skeleton
				top={
					isMobile ? (
						<div className="pb-3 border-container-border bg-container-bg">
							{allMarketsControls}
						</div>
					) : (
						<TableV2.HeaderRow
							forceBottomBorder
							grid={gridToUse}
							header
							addScrollPadding={isTableScrollable}
						>
							{headers.map((label, i) => (
								<TableV2.HeaderCell
									className={twMerge(
										props.overviewPage && 'bg-main-bg',
										'capitalize'
									)}
									alignRight={i === headers.length - 1}
									key={`header_${label}`.replace(/ /g, '')}
								>
									{label === 'p&l' ? (
										<>
											<Tooltip
												placement="top"
												className="flex items-center h-full"
												content={
													<div>
														Position P&L is based on {pnlReference} price
														{hasPredictionMarketPositions &&
														pnlReference !== 'mark' ? (
															<>
																, while BET market positions are always based on
																mark price.
															</>
														) : (
															<>.</>
														)}
														{!props.predictionsPage && (
															<>
																<br /> <br /> Click on the header to toggle P&L
																to reflect{' '}
																{pnlReference === 'mark' ? 'oracle' : 'mark'}{' '}
																price.
															</>
														)}
													</div>
												}
											>
												<Button
													size="MEDIUM"
													className={twMerge(
														'flex items-center justify-start h-full gap-1 px-0 py-0',
														props.predictionsPage && 'cursor-default'
													)}
													onClick={
														props.predictionsPage ? null : togglePnlReference
													}
												>
													<div className="underline capitalize">{label}</div>
													<Money size={18} />
												</Button>
											</Tooltip>
										</>
									) : label === 'liq price' ? (
										<>
											<Tooltip
												placement="top"
												content={
													<div className="flex flex-col space-y-2 text-xs">
														<span>
															Liquidation Price is the Oracle Price of the asset
															where your account can be liquidated, assuming the
															prices of your other assets/positions are
															constant.
														</span>
													</div>
												}
												allowHover
											>
												<span>{label}</span>
											</Tooltip>
										</>
									) : (
										<>{label}</>
									)}
								</TableV2.HeaderCell>
							))}
						</TableV2.HeaderRow>
					)
				}
				middle={
					<div className="w-full text-xs sm:max-h-full">
						{filteredPositions
							?.filter((position) => !!position)
							?.map((position) => {
								const key = `${position.accountKey}-${position.direction}-${position.marketSymbol}-${position.baseSize}-${position.entryPrice}`;

								return (
									<OpenPositionTableRow
										position={position}
										isMobile={isMobile}
										pnlReference={pnlReference}
										small={props.small}
										smallTableGrid={smallTableGrid}
										predictionsTableGrid={predictionsTableGrid}
										tableGrid={tableGrid}
										handleSelection={handleSelection}
										handleCopySize={copySize(position.marketIndex)}
										handleShowPnlModal={showPnlModal}
										handleEditLp={showLpModal}
										handleViewLpDetails={showLpDetailsModal}
										key={key}
										isOverviewPage={props.overviewPage}
										isPredictionsPage={props.predictionsPage}
										handleTogglePnlReference={togglePnlReference}
									/>
								);
							})}
					</div>
				}
				bottom={
					!isMobile ? (
						<TableV2.SummaryRow
							className={
								'border-t border-container-border bg-container-bg sm:bg-main-bg'
							}
							grid={''}
						>
							{allMarketsControls}
						</TableV2.SummaryRow>
					) : null
				}
				noBorder
				setIsScrollable={setIsTableScrollable}
			/>
		</TableStateWrapper>
	);

	return isMobile ? (
		<div className={props.overviewPage ? 'mb-4' : 'p-4'}>{tableContent}</div>
	) : (
		<>{tableContent}</>
	);
};

export default React.memo(OpenPositionsTable);
