import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	MAX_LEVERAGE_ORDER_SIZE,
	PositionDirection,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import UI_UTILS from '../uiUtils';
import { ENUM_UTILS } from '@drift/common';
import {
	InvariantResult,
	InvariantInputFieldValueType,
	QuerySelector,
	InvariantCheckingContext,
} from './types';
import {
	INVARIANT_CHECKER_INPUT_IDS,
	INVARIANT_CHECKER_INPUT_TYPES,
	INVARIANT_CHECKER_ATTRIBUTE_TYPES,
	INVARIANT_CHECKER_CONTEXT_WRAPPER_IDS,
} from './constants';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';
import { dlog } from 'src/dev';

/**
 * This is a utility class for checking invariants in the UI. Using the methods this class makes available, you should be able to check any invariants in the UI.
 *
 * Example use-case:
 * - When the user clicks a button to open a trade
 * - Use InvariantChecker to check that the order parameters in state actually match the values in the DOM being shown to the user
 * - If the invariant fails, we can cancel the trade (because we know we would be doing something wrong), and trigger an alarm for ourselves to fix this critical bug.
 */
export class InvariantChecker {
	/**
	 * Checks multiple invariants and returns true only if all pass
	 */
	static checkInvariants(invariants: InvariantResult[]): InvariantResult {
		// Skip and return success if invariant checks feature flag is disabled
		if (
			!useFeatureFlagStore.getState().flagEnabled('ENABLE_UI_INVARIANT_CHECKS')
		) {
			dlog(
				`invariant_checker`,
				`Skipping invariant checks because feature flag is disabled`
			);
			return { passed: true };
		}

		dlog(`invariant_checker`, `Running invariant checks`);

		const failedInvariants = invariants.filter((inv) => !inv.passed);

		if (failedInvariants.length === 0) {
			return { passed: true };
		}

		return {
			passed: false,
			message: failedInvariants.map((inv) => inv.message).join(', '),
		};
	}

	static getIdQuerySelector(id: string): QuerySelector {
		return `#${id}` as QuerySelector;
	}

	static getRelativeQuerySelectorSearchNode(
		context: InvariantCheckingContext
	): HTMLElement {
		return document.querySelector(
			`#${INVARIANT_CHECKER_CONTEXT_WRAPPER_IDS[context]}`
		) as HTMLElement;
	}

	static querySelectorForContext(
		querySelector: QuerySelector,
		context: InvariantCheckingContext
	): HTMLElement {
		try {
			return InvariantChecker.getRelativeQuerySelectorSearchNode(
				context
			).querySelector(querySelector) as HTMLElement;
		} catch (e) {
			console.error(
				`InvariantChecker: Error getting query selector for context ${context} and query selector ${querySelector}`,
				e
			);
		}
	}

	/**
	 * This is a helper function to check that only one element matches a specific query selector. E.g. if looking for an element with an ID, there should only be one match.
	 * @param querySelector
	 */
	private static invariantCheckOnlyOneQuerySelectorMatch(
		querySelector: QuerySelector,
		context: InvariantCheckingContext
	): void {
		try {
			const matches =
				this.getRelativeQuerySelectorSearchNode(context).querySelectorAll(
					querySelector
				);

			if (matches.length !== 1) {
				throw new Error(
					`InvariantChecker: Expected 1 match for query selector ${querySelector}, but got ${matches.length}`
				);
			}
		} catch (e) {
			console.error(
				`InvariantChecker: Error checking for only one match for query selector ${querySelector}`,
				e
			);
		}
	}

	private static getValueForHeroInput(
		inputSelector: QuerySelector,
		targetInputType: InvariantInputFieldValueType,
		context: InvariantCheckingContext
	): string {
		const inputElement = InvariantChecker.querySelectorForContext(
			inputSelector,
			context
		) as HTMLInputElement;

		const inputValueType = inputElement.getAttribute(
			INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType
		);

		if (inputValueType === targetInputType) {
			return inputElement.value;
		}

		const heroWrapper = inputElement.closest(
			`*[${INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType}="${INVARIANT_CHECKER_INPUT_TYPES.heroWrapper}"]`
		);

		const heroSubtext = heroWrapper?.querySelector(
			`*[${INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType}="${INVARIANT_CHECKER_INPUT_TYPES.heroSubtext}"]`
		) as HTMLInputElement;

		if (
			heroSubtext.getAttribute(INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType) !==
			targetInputType
		) {
			throw new Error(
				`InvariantChecker: Hero subtext ${heroSubtext.value} does not match target input type ${targetInputType}`
			);
		}

		return heroSubtext.textContent;
	}

	private static getValueForOrderDirectionInput(
		inputSelector: QuerySelector,
		context: InvariantCheckingContext
	): string {
		const inputElement = InvariantChecker.querySelectorForContext(
			inputSelector,
			context
		);

		if (!inputElement) {
			throw new Error('InvariantChecker: Input element is undefined');
		}

		const inputValueType = inputElement.getAttribute(
			INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType
		);

		if (inputValueType !== 'order-direction') {
			throw new Error(
				'InvariantChecker: Input value type is not order-direction'
			);
		}

		return inputElement.getAttribute(
			INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputValue
		);
	}

	private static getValueFromInputSelector(
		inputSelector: QuerySelector,
		targetInputType: InvariantInputFieldValueType,
		context: InvariantCheckingContext
	): string {
		try {
			InvariantChecker.invariantCheckOnlyOneQuerySelectorMatch(
				inputSelector,
				context
			);

			if (targetInputType === 'order-direction') {
				return InvariantChecker.getValueForOrderDirectionInput(
					inputSelector,
					context
				);
			}

			const element = InvariantChecker.querySelectorForContext(
				inputSelector,
				context
			);

			if (
				element.getAttribute(INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType) ===
				INVARIANT_CHECKER_INPUT_TYPES.hero
			) {
				return InvariantChecker.getValueForHeroInput(
					inputSelector,
					targetInputType,
					context
				);
			}

			// @ts-ignore
			return element?.value ?? element.textContent;
		} catch (e) {
			console.error(
				`InvariantChecker: Error getting value from input selector ${inputSelector}`,
				e
			);
		}
	}

	private static checkNumericInvariant(
		transactionAmount: BN,
		inputSelector: QuerySelector,
		precision: BN,
		targetInputType: InvariantInputFieldValueType,
		context: InvariantCheckingContext,
		opts?: {
			expectingMaxLeverageValue?: boolean;
		}
	): InvariantResult {
		const inputValue = InvariantChecker.getValueFromInputSelector(
			inputSelector,
			targetInputType,
			context
		);

		const inputBN = BigNum.fromPrint(inputValue, precision).val;

		const matches = opts?.expectingMaxLeverageValue
			? // If we're expecting a max leverage transaction, then we know that the input value might not match the amount we're passing through to the transaction
			  // TODO :: Ideally we would do extra checks here that that UI at least is showing the user "we're about to use everything you have available"
			  transactionAmount.eq(MAX_LEVERAGE_ORDER_SIZE)
			: transactionAmount.eq(inputBN);

		return {
			passed: matches,
			message: matches
				? undefined
				: `Transaction amount ${transactionAmount.toString()} does not match input ${inputValue} (precision adjusted: ${inputBN.toString()}). context: ${context}, inputSelector: ${inputSelector}.`,
		};
	}

	static checkQuoteInvariant(
		transactionQuoteAmount: BN,
		inputSelector: QuerySelector,
		context: InvariantCheckingContext
	): InvariantResult {
		return InvariantChecker.checkNumericInvariant(
			transactionQuoteAmount,
			inputSelector,
			QUOTE_PRECISION_EXP,
			'quote',
			context
		);
	}

	static checkBaseInvariant(
		transactionBaseAmount: BN,
		expectingMaxLeverage: boolean,
		inputSelector: QuerySelector,
		context: InvariantCheckingContext
	): InvariantResult {
		try {
			return InvariantChecker.checkNumericInvariant(
				transactionBaseAmount,
				inputSelector,
				BASE_PRECISION_EXP,
				'base',
				context,
				{
					expectingMaxLeverageValue: expectingMaxLeverage,
				}
			);
		} catch (e) {
			console.error(`InvariantChecker: Error checking base invariant`, e);
		}
	}

	static checkPriceInvariant(
		transactionPrice: BN,
		inputSelector: QuerySelector,
		context: InvariantCheckingContext
	): InvariantResult {
		return InvariantChecker.checkNumericInvariant(
			transactionPrice,
			inputSelector,
			PRICE_PRECISION_EXP,
			'price',
			context
		);
	}

	static checkOrderDirectionInvariant(
		transactionOrderDirection: PositionDirection,
		context: InvariantCheckingContext
	): InvariantResult {
		const inputValue = InvariantChecker.getValueFromInputSelector(
			InvariantChecker.getIdQuerySelector(
				INVARIANT_CHECKER_INPUT_IDS.orderDirectionInput
			),
			'order-direction',
			context
		);

		if (!inputValue) {
			return {
				passed: true,
				message: 'Input value is undefined. Assuming invariant passed.',
			};
		}

		const isLong = inputValue === 'long';
		const isShort = inputValue === 'short';

		if (!isLong && !isShort) {
			throw new Error(
				'InvariantChecker: Input value is not a valid order direction'
			);
		}

		const inputPositionDirection = isLong
			? PositionDirection.LONG
			: PositionDirection.SHORT;

		const directionMatches = ENUM_UTILS.match(
			transactionOrderDirection,
			inputPositionDirection
		);

		return {
			passed: directionMatches,
			message: directionMatches
				? undefined
				: `Transaction order direction ${transactionOrderDirection} does not match input ${inputValue}`,
		};
	}

	static checkMarketAgainstCurrentUrlInvariant(transactionMarket: {
		marketIndex: number;
	}): InvariantResult {
		const currentPath = window.location.pathname;
		const targetMarket = UI_UTILS.getMarketFromUrlPathname(currentPath);

		if (!targetMarket) {
			return {
				passed: false,
				message: `Could not find market for URL Path: ${currentPath}`,
			};
		}

		const matches = targetMarket.marketIndex === transactionMarket.marketIndex;

		return {
			passed: matches,
			message: matches
				? undefined
				: `Transaction market index ${transactionMarket.marketIndex} does not match URL Path market ${targetMarket.marketIndex}`,
		};
	}

	static getPositionDirectionInputHtmlAttributes(isOnBuySide: boolean): {
		[key: string]: string;
	} {
		return {
			id: INVARIANT_CHECKER_INPUT_IDS.orderDirectionInput,
			[INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputValue]: isOnBuySide
				? 'long'
				: 'short',
			[INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType]: 'order-direction',
		};
	}

	static getInvariantCheckerContextWrapperHtmlAttributes(
		context: InvariantCheckingContext
	): {
		[key: string]: string;
	} {
		return {
			id: INVARIANT_CHECKER_CONTEXT_WRAPPER_IDS[context],
		};
	}
}
