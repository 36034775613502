'use client';

import { WalletReadyState } from '@solana/wallet-adapter-base';
import { useSearchParams } from 'next/navigation';
import { useRef, useEffect } from 'react';
import useHasConnectedWalletBefore from 'src/hooks/useHasConnectedWalletBefore';
import useWalletContext from 'src/hooks/useWalletContext';
import { DriftWindow } from 'src/window/driftWindow';
import { getIsInEmulationMode } from 'src/utils/routerUtils';

const AUTOCONNECT_DELAY_MS = 4000;

const cleanWalletName = (walletName: string) =>
	walletName.replace(/[^a-zA-Z0-9]/g, '');

/**
 * Should skip autoconnect if we detect emulation mode params in the URL : Otherwise it tries to connect to the regular current wallet then switches to the emulation wallet afterwards which is unnecessary
 * @returns
 */
const useShouldSkipAutoConnect = () => {
	const searchParams = useSearchParams();
	const isInEmulationMode = getIsInEmulationMode(searchParams);

	// Put this in a ref because it should only ever hold the initial value :: The emulation params might change later but the autoconnect value should not change
	return useRef(isInEmulationMode);
};

/**
 * 🚨 Currently only works for solana/wallet-adapater wallets. Not our custom integrations.
 *
 * Phantom mobile app has a weird bug where autoconnect breaks if we try to programmatically enable/disable it based on whether they've used the UI before. need to use our own custom autoconnect logic to get around this.
 *
 * The logic is as follows:
 * - We want to wait some hardcoded timeout before we try to connect
 * - We only want to autoconnect if we have connected a wallet before
 * - We also only want to autoconnect if we have the name of the previous wallet saved in local state
 * - If all of these things are true, then after the timeout try to connect to that previous wallet. Only do this once by tracking if we've already tried to autoconnect aswelll
 */
export const useCustomWalletAutoconnect = () => {
	const [walletHasConnectedBefore] = useHasConnectedWalletBefore();
	const walletContext = useWalletContext();
	const alreadyTriedToAutoconnect = useRef(false);
	const initialTimerHasElapsed = useRef(false);

	const skipAutoConnectRef = useShouldSkipAutoConnect();

	useEffect(() => {
		setTimeout(() => {
			initialTimerHasElapsed.current = true;
		}, AUTOCONNECT_DELAY_MS);
	}, []);

	// ⭐️ THIS HOOK IS WHAT MAKES THE UI AUTOCONNECT TO THE WALLET
	useEffect(() => {
		if (skipAutoConnectRef.current) {
			return;
		}

		if (initialTimerHasElapsed.current) return;

		// The purpose of this is to prevent trying to connect to a wallet multiple times if the wallet context changes for whatever reason (and it previous only tried when it SHOULD HAVE SUCCEEDED so we don't expect a change).
		if (alreadyTriedToAutoconnect.current) return;

		// NOTE: the localStorage key ('walletName') is defined on the WalletProvider
		const previouslyConnectedWalletName = window.localStorage.walletName;
		if (!previouslyConnectedWalletName) return;

		if (walletHasConnectedBefore) {
			// Need to clean the wallet name because it gets saved with weird other characters sometimes
			const cleanedPreviousWalletName = cleanWalletName(
				previouslyConnectedWalletName
			);

			const matchingWallet = walletContext.wallets.find(
				(wallet) =>
					cleanWalletName(wallet.adapter.name) === cleanedPreviousWalletName
			);

			if (
				!matchingWallet ||
				matchingWallet.readyState !== WalletReadyState.Installed
			) {
				return;
			}

			if (matchingWallet) {
				DriftWindow.shouldTrackAutoConnectStartupTimeMetrics.set(true); // We're going to try to autoconnect, so we want to enable tracking of related metrics

				alreadyTriedToAutoconnect.current = true;
				matchingWallet.adapter.connect();
			}
		}
	}, [walletContext, walletHasConnectedBefore]);
};
