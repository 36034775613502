'use client';

import Text from 'src/components/Text/Text';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import InlineLoadingBar from '../InlineLoadingBar/InlineLoadingBar';
import { ButtonGroup } from '../Button';
import ColourCodedValue from '../Utils/ColourCodedValue';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import UI_UTILS from 'src/utils/uiUtils';
import { PERFORMANCE_TIMEFRAMES } from 'src/@types/types';
import useAccountData from 'src/hooks/useAccountData';
import Select from '../Inputs/Select';
import { MarketType } from '@drift-labs/sdk';
import { CurrentPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import dynamic from 'next/dynamic';
import { UIMarket } from '@drift/common';
import { useFundingRateMarketPerformance } from 'src/hooks/performance/useFundingRateMarketPerformance';
import {
	PnLTimeSeriesDataPoint,
	PnlTimePeriodOption,
} from 'src/@types/performance';

const PnlChart = dynamic(() => import('../PnlChart'));

const DEFAULT_INDEX = 2;

const marketOptions = [{ label: 'All', value: 'all' }].concat(
	CurrentPerpMarkets.filter(
		(market) => !UIMarket.checkIsPredictionMarket(market)
	).map((mkt) => {
		return {
			label: UIMarket.createPerpMarket(mkt.marketIndex).marketName,
			value: mkt.marketIndex.toString(),
		};
	})
);

const UserCumFundingModal = () => {
	const setState = useDriftStore((s) => s.set);
	const currentAccountPubKey = useAccountData()?.pubKey;
	const displayDecimals = 2;
	const [chartData, setChartData] = useState<PnLTimeSeriesDataPoint[]>();
	const [currentMarketFilter, setCurrentMarketFilter] = useState<
		number | 'all'
	>('all');

	const { data: marketPerformanceData = [] } = useFundingRateMarketPerformance({
		currentAccountPubKey,
		marketFilter: currentMarketFilter,
	});

	const [selectedTimeFrame, setSelectedTimeFrame] = useState(
		PERFORMANCE_TIMEFRAMES[DEFAULT_INDEX]
	);

	const onClose = useCallback(() => {
		setState((s) => {
			s.modals.showUserCumFundingModal = false;
		});
	}, []);

	const handleMarketChange = useCallback((newMarket: string) => {
		setCurrentMarketFilter(newMarket === 'all' ? 'all' : Number(newMarket));
	}, []);

	useEffect(() => {
		const perfData = marketPerformanceData.find(
			(mkt) => mkt.marketFilter === currentMarketFilter
		);
		setChartData(perfData?.performance[selectedTimeFrame]);
	}, [currentMarketFilter, marketPerformanceData, selectedTimeFrame]);

	useEffect(() => {
		const perfData = marketPerformanceData.find(
			(mkt) => mkt.marketFilter === currentMarketFilter
		)?.performance;

		if (perfData) {
			setChartData(perfData[selectedTimeFrame]);
		}
	}, [currentMarketFilter, marketPerformanceData]);

	const chartLabel = 'Funding Earned';

	return (
		<Modal
			onClose={onClose}
			id={`user_funding_modal`}
			sizeConfig={{ xs: 16, sm: 12, md: 8, xl: 6 }}
			className="p-4"
		>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<div className="inline-flex items-center justify-start">
						<Text.H2 className="mr-2">{`Historical Cumulative Funding`}</Text.H2>
						<Select.MarketSelector
							id="cum_funding_market_selector"
							marketType={MarketType.PERP}
							options={marketOptions}
							onChange={handleMarketChange}
							currentSelection={String(currentMarketFilter)}
							customWidth="w-[150px]"
							maxHeight={206}
						/>
					</div>
				</Modal.Header>

				<div className="items-center justify-start flex-grow w-full px-4 pb-2">
					<div>
						<Text.H1>
							<span className="pl-2 text-2xl whitespace-nowrap">
								{chartData?.length ? (
									<ColourCodedValue
										value={
											chartData[chartData.length - 1].totalPnl -
											chartData[0].totalPnl
										}
									>
										{UI_UTILS.toNotional(
											chartData[chartData.length - 1].totalPnl -
												chartData[0].totalPnl,
											5
										)}
									</ColourCodedValue>
								) : (
									<SkeletonValuePlaceholder
										className="w-12 h-6"
										loading={false}
									/>
								)}
							</span>
						</Text.H1>
					</div>
				</div>

				<div className="items-center justify-center flex-grow min-h-[430px] max-h-[430px] w-full px-4 pb-4 pt-1">
					{!chartData ? (
						<div
							className={`flex flex-col justify-center items-center h-[430px] w-full text-center text-th-fgd-3 rounded-md`}
						>
							<InlineLoadingBar />
						</div>
					) : (
						<PnlChart
							id="user-funding-history"
							data={chartData}
							field={'totalPnl'}
							label={chartLabel}
							positive={false}
							decimalsToShow={displayDecimals}
							isInModal={true}
							//overrideNoBeginAtZero={true}
							className="min-h-[430px]"
							isHourly={selectedTimeFrame === '24h'}
						/>
					)}
				</div>
				<div className="inline-flex items-center justify-between w-full">
					<div>
						<ButtonGroup.Segmented
							className="m-4"
							options={PERFORMANCE_TIMEFRAMES.map((label) => {
								return {
									label: label === 'all' ? '90d' : label,
									value: label,
								};
							})}
							selected={selectedTimeFrame}
							size="MEDIUM"
							selectAction={(val) =>
								setSelectedTimeFrame(val as PnlTimePeriodOption)
							}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default UserCumFundingModal;
