import { useDrawersStore, useDrawerToggler } from 'src/stores/useDrawersStore';

export const useShowWalletConnectOptions = () => {
	const _toggleDrawer = useDrawerToggler('connectWallet');
	const setDrawerStore = useDrawersStore((s) => s.set);

	const toggleDrawer = (show: boolean, callback?: () => void) => {
		_toggleDrawer(show);

		setDrawerStore((s) => {
			s.connectWallet.completeWalletConnectCallback =
				show && callback ? callback : undefined;
		});
	};

	return toggleDrawer;
};
