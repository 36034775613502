'use client';

import React, { useState } from 'react';
import Drawer from '../../Drawer';
import ConnectWalletButtonsList from '../../ConnectWalletButtonsList';
import {
	DRIFT_CUSTOM_WALLET_OPTIONS,
	DriftWalletName,
} from 'src/constants/wallets';
import {
	getHistoricallySelectedWallets,
	HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY,
} from 'src/hooks/useWalletsToDisplay';
import useHandleSelectWalletProvider from 'src/hooks/useHandleSelectWalletProvider';
import { dlog } from 'src/dev';
import useSafePush from 'src/hooks/useSafePush';
import AcknowledgeTermsPanel from '../../Onboarding/AcknowledgeTermsPanel';
import useHasAcknowledgedTerms from 'src/hooks/useHasAcknowledgedTerms';
import useAccountExists from 'src/hooks/useAccountExists';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { usePathname } from 'next/navigation';
import { useDebounce } from 'react-use';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useCurrentWalletConnectionState from 'src/hooks/useCurrentWalletConnectionState';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';
import { MagicAuthLoginDrawer } from './MagicAuthLoginDrawer';
import useHasConnectedWalletBefore from 'src/hooks/useHasConnectedWalletBefore';
import { useDrawersStore } from 'src/stores/useDrawersStore';

const CONNECTION_STEP_LOCK_KEY = 'ONBOARDING_CONNECTING_LOCK';

export const ConnectWalletDrawer = () => {
	const setStore = useDriftStore((s) => s.set);
	const safePush = useSafePush();
	const walletConnectionState = useCurrentWalletConnectionState();
	const currentWalletAdapter = useCurrentWalletAdapter();
	const connected = useWalletIsConnected();
	const accountExists = useAccountExists();
	const pathname = usePathname();
	const showConnectWalletOptions = useShowWalletConnectOptions();
	const completeWalletConnectCallback = useDrawersStore(
		(s) => s.connectWallet.completeWalletConnectCallback
	);

	const [isMagicLinkAuth, setIsMagicLinkAuth] = useState(false);
	const [hasAcknowledgedTerms, updateHasAcknowledgedTerms] =
		useHasAcknowledgedTerms();

	const { handleSelectProvider } = useHandleSelectWalletProvider();
	const [_, setHasConnectedWalletBefore] = useHasConnectedWalletBefore();

	const handleAddToHistoricallySelectedWallets = (name: string) => {
		const previouslySelectedWallets = getHistoricallySelectedWallets();
		if (!previouslySelectedWallets.includes(name)) {
			previouslySelectedWallets.push(name);
			localStorage.setItem(
				HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY,
				JSON.stringify(previouslySelectedWallets)
			);
		}
	};

	const handleSelectWallet = (walletName: DriftWalletName) => {
		dlog(`wallet_debugging`, `handleSelectWallet: ${walletName}`);

		handleAddToHistoricallySelectedWallets(walletName);

		switch (walletName) {
			case DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME:
				setIsMagicLinkAuth(true);
				break;
			default:
				handleSelectProvider(walletName);
				walletConnectionState.instance.update(
					'Connecting',
					true,
					CONNECTION_STEP_LOCK_KEY
				);
		}
	};

	const handleClose = (callCallback = true) => {
		showConnectWalletOptions(false);
		if (callCallback) {
			completeWalletConnectCallback?.();
		}
	};

	const handleBackFromMagicAuth = () => {
		setIsMagicLinkAuth(false);
	};

	// May want a better solution here... adding a debounce because sometimes connected and accountExists aren't updated simultaneously
	useDebounce(
		() => {
			if (connected && walletConnectionState.instance.FullyConnected) {
				setHasConnectedWalletBefore(true);

				// Go to old onboarding for now if account doesn't exist
				if (accountExists === false) {
					const isMagicAuth =
						currentWalletAdapter.name ===
						DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME;
					const isMetamask =
						currentWalletAdapter.name ===
						DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME;

					if (isMagicAuth) {
						safePush('/onboarding/your-magic-wallet');
					} else if (isMetamask) {
						safePush('/onboarding/your-metamask-wallet');
					} else {
						safePush('/onboarding/fund-your-account');
					}

					if (pathname === '/deposit') {
						// because the subsequent page is about funding the user account, we don't want to the user to go back to the deposit modal
						setStore((s) => {
							s.wallet.lastRouteBeforeConnect = '/';
						});
					} else {
						setStore((s) => {
							s.wallet.lastRouteBeforeConnect = pathname;
						});
					}
				}

				handleClose(accountExists);
			}
		},
		300,
		[
			connected,
			walletConnectionState.instance.FullyConnected,
			accountExists,
			currentWalletAdapter?.name,
		]
	);

	return (
		<Drawer
			drawerName="connectWallet"
			className="w-full sm:max-w-[400px] sm:w-[400px] max-h-full"
		>
			<Drawer.Header
				titleContent="Connect Wallet"
				className="p-4"
				onClose={handleClose}
			/>

			<Drawer.Body className="p-4 overflow-y-auto hidden-scroll text-text-default">
				{!hasAcknowledgedTerms.hasPreviouslyAcknowledgedTerms ? (
					<AcknowledgeTermsPanel
						onAccept={() => {
							updateHasAcknowledgedTerms();
						}}
						onReject={() => {
							handleClose();
						}}
					/>
				) : isMagicLinkAuth ? (
					<MagicAuthLoginDrawer
						onBackFromMagicAuth={handleBackFromMagicAuth}
						handleDrawerClose={handleClose}
					/>
				) : (
					<ConnectWalletButtonsList
						showMetamaskIfDetected
						showEmail
						onSelectedWallet={handleSelectWallet}
					/>
				)}
			</Drawer.Body>
		</Drawer>
	);
};
