'use client';

import {
	Resize,
	ResizeFull,
	ResizeMinimize,
	ResizeOnethird,
	ResizeTwothirds,
} from '@drift-labs/icons';
import {
	TRADE_PAGE_TABLE_DEFAULT_SIZE,
	TRADE_PAGE_TABLE_RESIZE_OPTION,
} from '../../hooks/tradePageTablePreference/tradePageTablePreferenceTypes';
import useTradeTableSizePreference from '../../hooks/tradePageTablePreference/useTradeTableSizePreference';
import Button from '../Button';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import PopoverWrapper from '../PopoverWrapper';
import Chevron from '../Icons/Chevron';

type ButtonOption = {
	label: string;
	value: TRADE_PAGE_TABLE_RESIZE_OPTION;
	icon: typeof ResizeFull;
};

const RESIZE_BUTTON_OPTIONS: ButtonOption[] = [
	{
		label: 'Full',
		value: TRADE_PAGE_TABLE_RESIZE_OPTION.FULL,
		icon: ResizeFull,
	},
	{
		label: 'Medium',
		value: TRADE_PAGE_TABLE_RESIZE_OPTION.MEDIUM,
		icon: ResizeTwothirds,
	},
	{
		label: 'Small',
		value: TRADE_PAGE_TABLE_RESIZE_OPTION.SMALL,
		icon: ResizeOnethird,
	},
	{
		label: 'Minimize',
		value: TRADE_PAGE_TABLE_RESIZE_OPTION.MINIMIZE,
		icon: ResizeMinimize,
	},
];

const ResizeOptionButton = ({
	alreadySelected,
	Option,
	onClick,
}: {
	alreadySelected?: boolean;
	Option: ButtonOption;
	onClick: (value: TRADE_PAGE_TABLE_RESIZE_OPTION) => void;
}) => {
	return (
		<div
			className={`flex space-x-4 hover:text-text-label hover:cursor-pointer w-[130px] ${
				alreadySelected && 'text-text-label hover:brightness-125'
			}`}
			onClick={() => {
				onClick(Option.value);
			}}
		>
			<span>
				<Option.icon />
			</span>
			<span>{Option.label}</span>
		</div>
	);
};

const TradePageTableResizer = () => {
	const [currentPreference, setTableSizePreference] =
		useTradeTableSizePreference();

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover({});

	const handleResizeButtonClick = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	const handleResizeOptionSelection = (selection: string) => {
		setTableSizePreference(selection);
	};

	return (
		<>
			<div ref={refs.setReference} {...getReferenceProps()}>
				<Button.Secondary size="SMALL" onClick={handleResizeButtonClick}>
					<div className="flex items-center space-x-1">
						<Resize size={16} />
						<span className="hidden xl:inline-block">Resize</span>
					</div>
				</Button.Secondary>
			</div>
			{isPopoverOpen && (
				<PopoverWrapper
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps()}
				>
					<div className="flex flex-col p-2 space-y-1">
						{RESIZE_BUTTON_OPTIONS.map((option) => {
							return (
								<ResizeOptionButton
									key={option.label}
									alreadySelected={currentPreference === option.value}
									Option={option}
									onClick={() => {
										handleResizeOptionSelection(option.value);
										setIsPopoverOpen(false);
									}}
								/>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</>
	);
};

const LiteModeTradePageTableResizer = () => {
	const [currentPreference, setTableSizePreference] =
		useTradeTableSizePreference();

	const handleToggleTable = () => {
		if (currentPreference === TRADE_PAGE_TABLE_RESIZE_OPTION.MINIMIZE) {
			if (window.innerHeight > 1150) {
				setTableSizePreference(TRADE_PAGE_TABLE_RESIZE_OPTION.FULL);
			} else {
				setTableSizePreference(TRADE_PAGE_TABLE_DEFAULT_SIZE);
			}
		} else {
			setTableSizePreference(TRADE_PAGE_TABLE_RESIZE_OPTION.MINIMIZE);
		}
	};

	return (
		<button
			className="flex items-center text-xs rounded-sm text-text-label hover:bg-container-bg-hover"
			onClick={handleToggleTable}
		>
			<Chevron
				direction={currentPreference === '0' ? 'up' : 'down'}
				size={20}
			/>
		</button>
	);
};

export { TradePageTableResizer, LiteModeTradePageTableResizer };
