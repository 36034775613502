'use client';

import Text from 'src/components/Text/Text';
import Button from '../../Button';
import TextField from '../../Inputs/TextField';
import UI_UTILS from 'src/utils/uiUtils';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useAccount, useBalance, useNetwork, useSwitchNetwork } from 'wagmi';
import {
	MINIMUM_SOL_NEEDED_FOR_CCTP,
	SUPPORTED_DESTINATION_DOMAINS,
	SUPPORTED_SOURCE_DOMAINS,
} from 'src/constants/cctp';
import { useEvmTokenApproval } from 'src/hooks/cctp/useEvmTokenApproval';
import { byteArrayToHexString } from 'src/utils/evm';
import {
	getChainFromDomain,
	getDomainUiConfig,
	getEstimatedTransferDurationSecs,
	getTokenMessengerContractAddress,
	getUsdcContractAddress,
} from 'src/utils/cctp';
import { useTokenMessenger } from 'src/hooks/cctp/useTokenMessenger';
import { HexString } from 'src/@types/evm';
import { BytesLike } from 'ethers';
import { AccordionPanel } from 'src/components/Accordion';
import { ArrowRight, SuccessFilled } from '@drift-labs/icons';
import Select from 'src/components/Inputs/Select';
import { useEffect, useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import { twMerge } from 'tailwind-merge';
import InfoMessage from 'src/components/TradeForm/InfoMessage';

const USDC_PRECISION_EXP = QUOTE_PRECISION_EXP;
const DEPOSIT_FOR_BURN_TX_TOAST_ID = 'cctp-deposit-for-burn';

enum InitTransferState {
	NeedToConnectEvmWallet,
	NeedToSwitchNetwork,
	NeedToApproveSpendingLimit,
	CanInitiateTransfer,
}

export const TransferDetails = ({
	usdcAmount,
	sourceDomain,
	setSourceDomain,
	destinationDomain,
	setDestinationDomain,
	setUsdcAmount,
	setDepositForBurnTx,
	expanded,
	destinationWalletAddress,
	destinationUsdcTokenAccount,
	setDestinationWalletAddress,
	isDepositForBurnApproving,
	destinationWalletPassPreReq,
}: {
	usdcAmount: string;
	expanded: boolean;
	sourceDomain: number;
	setSourceDomain: (newDomain: number) => void;
	destinationDomain: number;
	setDestinationDomain: (newDomain: number) => void;
	setUsdcAmount: (newValue: string) => void;
	setMessageHash: (newValue: string) => void;
	setMessageBytes: (newValue: BytesLike) => void;
	setDepositForBurnTx: (tx: { hash: string; toastId?: string }) => void;
	destinationWalletAddress: string;
	destinationUsdcTokenAccount?: PublicKey;
	setDestinationWalletAddress: (address: string) => void;
	isDepositForBurnApproving?: boolean;
	destinationWalletPassPreReq: boolean;
}) => {
	const setStore = useDriftStore((s) => s.set);
	const { address: evmWalletAddress, isConnected: isEvmWalletConnected } =
		useAccount();
	const { chain } = useNetwork();

	const [initTransferState, setInitTransferState] = useState<InitTransferState>(
		InitTransferState.NeedToConnectEvmWallet
	);

	const usdcAmountBigInt = BigInt(
		BigNum.fromPrint(usdcAmount, USDC_PRECISION_EXP).toString()
	);
	const sourceDomainUiConfig = getDomainUiConfig(sourceDomain);
	const destinationDomainUiConfig = getDomainUiConfig(destinationDomain);
	const tokenMessengerContractAddress =
		getTokenMessengerContractAddress(sourceDomain);
	const usdcContractAddress = getUsdcContractAddress(sourceDomain);
	const sourceChain = getChainFromDomain(sourceDomain);
	const isWalletOnCorrectChain = sourceChain.id === chain?.id;

	// on-chain hooks

	// get usdc balance
	const { data: usdcSourceBalance } = useBalance({
		address: evmWalletAddress,
		token: usdcContractAddress as HexString,
	});

	const { switchNetwork } = useSwitchNetwork();

	// get allowance and approve function
	const {
		allowance,
		approve,
		isAllowanceLoading,
		isApproving: isApprovalApproving,
	} = useEvmTokenApproval(
		usdcContractAddress as HexString,
		evmWalletAddress,
		tokenMessengerContractAddress as HexString,
		usdcAmountBigInt
	);

	// get depositForBurn function
	const { depositForBurn, depositForBurnTxHash } = useTokenMessenger({
		amount: usdcAmountBigInt,
		allowance,
		sourceDomainUiConfig,
		destinationDomainUiConfig,
		mintRecipient: byteArrayToHexString(
			destinationUsdcTokenAccount
				? destinationUsdcTokenAccount?.toBytes()
				: undefined
		),
		burnTokenAddress: usdcContractAddress,
		toastId: DEPOSIT_FOR_BURN_TX_TOAST_ID,
	});

	// effects
	useEffect(() => {
		setDepositForBurnTx({
			hash: depositForBurnTxHash,
			toastId: DEPOSIT_FOR_BURN_TX_TOAST_ID,
		});
	}, [depositForBurnTxHash]);

	// state machine logic for transfer initiation
	useEffect(() => {
		if (isEvmWalletConnected) {
			if (!!chain && !isWalletOnCorrectChain) {
				setInitTransferState(InitTransferState.NeedToSwitchNetwork);
			} else if (
				usdcAmountBigInt === 0n ||
				usdcAmountBigInt > allowance // evm requires address to approve a spending limit by a protocol for a specific token
			) {
				setInitTransferState(InitTransferState.NeedToApproveSpendingLimit);
			} else {
				setInitTransferState(InitTransferState.CanInitiateTransfer);
			}
		} else {
			setInitTransferState(InitTransferState.NeedToConnectEvmWallet);
		}
	}, [
		usdcAmountBigInt,
		allowance,
		isEvmWalletConnected,
		isWalletOnCorrectChain,
		chain,
	]);

	// derived variables
	const durationOfTransfer = `(~${UI_UTILS.secondsToMinutes(
		getEstimatedTransferDurationSecs(sourceDomain),
		true
	)})`;
	const { cta, ctaText, isCtaDisabled } = getNextStepConfig();

	// functions
	const handleUsdcAmountChange = (newValue: string) => {
		setUsdcAmount(
			UI_UTILS.truncateInputToPrecision(newValue, USDC_PRECISION_EXP)
		);
	};

	function getNextStepConfig() {
		const isAmountMoreThanBalance =
			(usdcSourceBalance?.value ?? 0n) < usdcAmountBigInt;
		switch (initTransferState) {
			case InitTransferState.NeedToConnectEvmWallet:
			default:
				return {
					ctaText: `Connect ${sourceDomainUiConfig.label} Wallet`,
					cta: openEvmWalletsSelectorModal,
					isCtaDisabled: false,
				};
			case InitTransferState.NeedToSwitchNetwork:
				return {
					ctaText: 'Switch Network',
					cta: () => switchNetwork(sourceChain.id),
					isCtaDisabled: false,
				};
			case InitTransferState.NeedToApproveSpendingLimit:
				return {
					ctaText: isAmountMoreThanBalance
						? 'Not enough USDC'
						: 'Approve spending limit',
					cta: approve,
					isCtaDisabled:
						usdcAmountBigInt === 0n ||
						isAllowanceLoading ||
						isApprovalApproving ||
						isAmountMoreThanBalance ||
						!isWalletOnCorrectChain,
				};
			case InitTransferState.CanInitiateTransfer: {
				return {
					ctaText: !destinationWalletPassPreReq
						? 'Insufficient SOL in destination wallet'
						: isAmountMoreThanBalance
						? 'Not enough USDC'
						: `Initiate Transfer ${durationOfTransfer}`,
					cta: depositForBurn,
					isCtaDisabled:
						!destinationWalletAddress ||
						!destinationWalletPassPreReq ||
						!depositForBurn ||
						!isWalletOnCorrectChain ||
						isDepositForBurnApproving ||
						isAmountMoreThanBalance,
				};
			}
		}
	}

	function openEvmWalletsSelectorModal() {
		setStore((s) => {
			s.modals.showEvmWalletsSelectorModal = true;
		});
	}

	return (
		<AccordionPanel
			header="Transfer Details"
			expanded={expanded}
			onClickHeader={() => {}} // do nothing
			CustomIcon={expanded ? () => <></> : () => <SuccessFilled size={18} />}
			headerClassNames={twMerge(
				'bg-transparent [&>span]:text-[16px]',
				expanded ? 'text-text-emphasis pb-0' : 'text-text-secondary'
			)}
			content={
				<div className="flex flex-col w-full gap-4">
					<div className="flex items-center justify-between [&>div]:flex [&>div]:flex-col [&>div]:gap-1">
						<div className="w-[43%]">
							<Text.BODY3 className="text-text-label">Source</Text.BODY3>
							<Select.Default
								id="source_chain_selector"
								currentSelection={sourceDomain}
								onChange={(newDomain) => setSourceDomain(newDomain)}
								options={SUPPORTED_SOURCE_DOMAINS}
								labelClassName={'flex-row-reverse gap-2 items-center'}
								optionsClassName={
									'flex-row-reverse gap-2 items-center [&>img]:relative [&>img]:-top-[2px]'
								}
								preventPopupIfOneOptionOnly
							/>
						</div>
						<div>
							<Text.BODY3 className="invisible">
								{/** Faux component to align arrow icon with select components */}
								.
							</Text.BODY3>
							<ArrowRight size={24} />
						</div>
						<div className="w-[43%]">
							<Text.BODY3 className="text-text-label">Destination</Text.BODY3>
							<Select.Default
								id="destination_chain_selector"
								currentSelection={destinationDomain}
								onChange={(newDomain) => setDestinationDomain(newDomain)}
								options={SUPPORTED_DESTINATION_DOMAINS}
								labelClassName={'flex-row-reverse gap-2 items-center'}
								optionsClassName={'flex-row-reverse gap-2 items-center'}
								preventPopupIfOneOptionOnly
							/>
						</div>
					</div>

					<div className="flex flex-col gap-1">
						<Text.BODY3 className="text-text-label">
							Destination {destinationDomainUiConfig.label} Address
						</Text.BODY3>
						<TextField.Default
							type="text"
							value={destinationWalletAddress}
							onChange={(value) => setDestinationWalletAddress(value)}
						/>
						<Text.MICRO1
							className={twMerge(
								'text-text-label',
								!destinationWalletPassPreReq && 'text-text-negative-red-button'
							)}
						>
							Please ensure that you have enough SOL (~
							{MINIMUM_SOL_NEEDED_FOR_CCTP} SOL) in your wallet to receive the
							USDC.
						</Text.MICRO1>
					</div>

					<div className="flex flex-col gap-1">
						<div className="flex items-center justify-between">
							<Text.BODY3 className="text-text-label">
								Amount to Transfer
							</Text.BODY3>
							{usdcSourceBalance?.formatted && (
								<Text.MICRO1 className="text-text-label">
									Wallet Balance: {usdcSourceBalance?.formatted} USDC
								</Text.MICRO1>
							)}
						</div>

						<div className="flex items-center gap-1">
							<TextField.Default
								type="number"
								placeholder="USDC"
								value={usdcAmount}
								onChange={handleUsdcAmountChange}
							/>
							<Button.Secondary
								size="MEDIUM"
								onClick={() => {
									handleUsdcAmountChange(
										usdcSourceBalance
											? (+usdcSourceBalance.formatted / 2).toString()
											: ''
									);
								}}
							>
								50%
							</Button.Secondary>
							<Button.Secondary
								size="MEDIUM"
								onClick={() => {
									handleUsdcAmountChange(
										usdcSourceBalance?.formatted.toString() ?? ''
									);
								}}
							>
								Max
							</Button.Secondary>
						</div>
					</div>

					{initTransferState === InitTransferState.NeedToSwitchNetwork && (
						<InfoMessage
							type="warn"
							messageTitle="Wrong network!"
							message="Source chain and selected network in wallet must be the same."
						/>
					)}

					<Button.Secondary
						size="MEDIUM"
						onClick={cta}
						disabled={isCtaDisabled}
						highlight
					>
						{ctaText}
					</Button.Secondary>
				</div>
			}
		/>
	);
};
