import Text from 'src/components/Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { AdvancedChip } from '../Chips/AdvancedFeatureChip';
import { ButtonGroup } from '../Button';

interface SettingsButtonGroupProps {
	label: string;
	value: string;
	onChange: (value: string | number) => void;
	spacedOut?: boolean;
	includeLineSpace?: boolean;
	textClass?: string;
	tooltipContent?: string | ReactNode;
	className?: string;
	betaFeature?: boolean;
	options: {
		label: string;
		value: string | number;
		color?: string;
	}[];
}

const SettingsButtonGroup = ({
	label,
	value,
	options,
	onChange,
	spacedOut = true,
	includeLineSpace = true,
	textClass,
	tooltipContent: tooltipText,
	className,
	betaFeature,
}: SettingsButtonGroupProps) => {
	return (
		<div
			key={label}
			className={twMerge(
				`flex items-center`,
				spacedOut ? 'justify-between' : 'gap-2',
				includeLineSpace ? 'mb-6' : '',
				className
			)}
		>
			{tooltipText ? (
				<Tooltip
					className={`inline-flex items-center ${
						textClass ? textClass : 'text-text-label'
					}`}
					content={tooltipText}
					allowHover
				>
					<Text.BODY2>{label}</Text.BODY2>
					<Info className={`ml-1`} size={12} />
					{betaFeature && <AdvancedChip customLabel="BETA" />}
				</Tooltip>
			) : (
				<Text.BODY2 className={`${textClass ? textClass : 'text-text-label'}`}>
					{label}
				</Text.BODY2>
			)}
			<ButtonGroup.Segmented
				options={options}
				selected={value}
				size="MEDIUM"
				selectAction={onChange}
			/>
		</div>
	);
};

export default SettingsButtonGroup;
