import { BN } from '@drift-labs/sdk';
import { Vault, VaultDepositor } from '@drift-labs/vaults-sdk';
import dayjs from 'dayjs';

export enum WithdrawalState {
	/**
	 * No withdrawal request has been made
	 */
	UnRequested,
	/**
	 * A withdrawal request has been made but not yet available
	 */
	Requested,
	/**
	 * A withdrawal request has been made and is available
	 */
	AvailableForWithdrawal,
}

export const getWithdrawalState = (
	vaultDepositorAccountData: VaultDepositor | undefined,
	vaultAccountData: Vault | undefined
) => {
	const withdrawalAvailableTs =
		(vaultDepositorAccountData?.lastWithdrawRequest.ts.toNumber() ?? 0) +
		(vaultAccountData?.redeemPeriod.toNumber() ?? 0);
	const lastRequestedShares =
		vaultDepositorAccountData?.lastWithdrawRequest.shares ?? new BN(0);

	const hasRequestedWithdrawal = lastRequestedShares.toNumber() > 0;
	const isBeforeWithdrawalAvailableDate = dayjs().isBefore(
		dayjs.unix(withdrawalAvailableTs)
	);

	const withdrawalState = deriveWithdrawalState();

	const isFullWithdrawal = lastRequestedShares.eq(
		vaultDepositorAccountData?.vaultShares ?? new BN(0)
	);
	const isWithdrawalInProgress =
		withdrawalState !== WithdrawalState.UnRequested;

	// update withdrawal state

	function deriveWithdrawalState() {
		if (hasRequestedWithdrawal) {
			if (isBeforeWithdrawalAvailableDate) {
				return WithdrawalState.Requested;
			} else {
				return WithdrawalState.AvailableForWithdrawal;
			}
		} else {
			return WithdrawalState.UnRequested;
		}
	}

	return { withdrawalState, isFullWithdrawal, isWithdrawalInProgress };
};

export const redeemPeriodToString = (seconds = 0) => {
	const totalHours = Math.floor(seconds / 60 / 60);
	const days = Math.floor(totalHours / 24);
	const hours = totalHours % 24;

	let mins = 0;

	if (seconds % (60 * 60) !== 0) {
		mins = Math.floor((seconds % (60 * 60)) / 60);
	}

	if (totalHours < 1) {
		return `${mins} mins`;
	} else if (totalHours < 24) {
		return `${totalHours} hours${mins > 0 ? ` ${mins} mins` : ''}`;
	} else {
		return `${days} day${days > 1 ? 's' : ''}${
			hours > 0 ? ` ${hours} hours` : ''
		}${mins > 0 ? ` ${mins} mins` : ''}`;
	}
};
