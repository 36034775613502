import { produce } from 'immer';
import { create } from 'zustand';
import { VaultClient } from '@drift-labs/vaults-sdk';
import { UiVaultConfig, VaultStats } from 'src/@types/vaults';
import { UiVaults } from 'src/constants/vaults';

export type VaultStatWithConfig = VaultStats & { uiVaultConfig: UiVaultConfig };

export interface VaultsStore {
	set: (x: (s: VaultsStore) => void) => void;
	get: () => VaultsStore;
	vaultClient: VaultClient | undefined;
	/**
	 * Vaults stats of vaults. Updates when the oracle price stores are updated (see `useSyncVaultsStats`)
	 */
	vaultsStats: Record<string, VaultStats>;
	areInitialVaultsStatsLoaded: boolean;

	/**
	 * Returns a list of vault stats with their respective ui vault config
	 */
	getVaultsStatsList: () => VaultStatWithConfig[];

	/**
	 * Returns the stats for a given vault
	 */
	getVaultStats: (vaultPubkey: string) => VaultStats | undefined;

	syncCurrentPageVaultStats: () => Promise<void>;
}

export const useVaultsStore = create<VaultsStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	vaultClient: undefined,
	vaultsStats: {},
	areInitialVaultsStatsLoaded: false,

	getVaultsStatsList: () => {
		const vaultStats = get().vaultsStats;
		return Object.keys(vaultStats)
			.map((vaultPubkey) => ({
				...vaultStats[vaultPubkey],
				uiVaultConfig: UiVaults.getVaultConfig(vaultPubkey),
			}))
			.filter((vaultStat) => !vaultStat.uiVaultConfig.hidden);
	},

	getVaultStats: (vaultPubkey: string) => {
		const vaultStats = get().vaultsStats;
		return vaultStats[vaultPubkey];
	},

	syncCurrentPageVaultStats: () => Promise.resolve(),
}));
