'use client';

import { useEffect, useMemo, useState } from 'react';
import Button from 'src/components/Button';
import Chevron from 'src/components/Icons/Chevron';
import Modal from 'src/components/Modals/Modal';
import RoundedGradientBorderBox from 'src/components/RoundedGradientBorderBox';
import Text from 'src/components/Text/Text';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useIsSafari from 'src/hooks/useIsSafari';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { twMerge } from 'tailwind-merge';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import useDriftTheme from '../../hooks/useDriftTheme';
import SettingsInterfaceIcon from '../Icons/Settings/SettingsInterfaceIcon';
import SettingsMarginLeverageIcon from '../Icons/Settings/SettingsMarginLeverageIcon';
import SettingsNetwork from '../Icons/Settings/SettingsNetwork';
import SettingsOther from '../Icons/Settings/SettingsOther';
import SettingsTrade from '../Icons/Settings/SettingsTrade';
import InterfaceSettings from './InterfaceSettings';
import MarginLeverageSettings, {
	AllSubaccountUpdates,
} from './MarginLeverageSettings';
import NetworkSettings from './NetworkSettings';
import OtherSettings from './OtherSettings';
import TradeSettings from './TradeSettings';
import useDriftActions from 'src/hooks/useDriftActions';
import { useImmer } from 'use-immer';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import UI_UTILS from 'src/utils/uiUtils';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import { MarginMode } from '@drift-labs/sdk';

interface SettingsFormProps {
	onClose: () => void;
	settingGroupIndex?: number;
}

type SettingsGroup =
	| 'Trade'
	| 'Network'
	| 'Margin/Leverage'
	| 'Interface'
	| 'Other';

const SETTINGS_GROUPS: Array<SettingsGroup> = [
	'Trade',
	'Network',
	'Margin/Leverage',
	'Interface',
	'Other',
];

const SettingsForm = ({ onClose, settingGroupIndex }: SettingsFormProps) => {
	const setState = useDriftStore((s) => s.set);

	const [savedSettings, updateSettings] = useCurrentSettings();

	const [stagedSettings, setStagedSettings] = useImmer(savedSettings);

	const [disableSave, setDisableSave] = useState(false);
	const toggleSetting = (key: string) => {
		setStagedSettings({ ...stagedSettings, [key]: !stagedSettings[key] });
	};
	const [mobileView, setMobileView] = useState(
		SETTINGS_GROUPS[settingGroupIndex] ?? 'Nav'
	);
	const [isRpcDropdownOpen, setIsRpcDropdownOpen] = useState(false);
	const toggleRpcDropdown = () => {
		setIsRpcDropdownOpen(!isRpcDropdownOpen);
	};

	const [marginTradingUpdates, setMarginTradingUpdates] = useState([]);
	const [marginModeUpdates, setMarginModeUpdates] = useState([]);
	const [marginRatioUpdates, setMarginRatioUpdates] = useState([]);
	const [dlpAdvancedFlagUpdates, setDlpAdvancedFlagUpdates] = useState([]);
	const [reduceOnlyFlagUpdates, setReduceOnlyFlagUpdates] = useState([]);

	const [currentGroup, setCurrentGroup] = useState(
		SETTINGS_GROUPS[settingGroupIndex ? settingGroupIndex : 0]
	);

	const { captureEvent } = usePostHogCapture();
	const actions = useDriftActions();

	const isInputChanged = useMemo(
		() =>
			Object.keys(stagedSettings).some((key) => {
				if (key === 'rpcEndpoint') {
					return (
						stagedSettings.rpcEndpoint.value !== savedSettings.rpcEndpoint.value
					);
				} else {
					return stagedSettings[key] !== savedSettings[key];
				}
			}) ||
			marginTradingUpdates.length ||
			marginRatioUpdates.length ||
			dlpAdvancedFlagUpdates.length ||
			reduceOnlyFlagUpdates.length ||
			marginModeUpdates.length,
		[
			marginTradingUpdates.length,
			marginRatioUpdates.length,
			dlpAdvancedFlagUpdates.length,
			reduceOnlyFlagUpdates.length,
			marginModeUpdates.length,
			stagedSettings,
		]
	);

	const allSubAccountUpdates: AllSubaccountUpdates = useMemo(() => {
		const subAccountUpdates: AllSubaccountUpdates = {};
		marginTradingUpdates.forEach((update) => {
			subAccountUpdates[update.subAccountId] = {
				...subAccountUpdates[update.subAccountId],
				marginTradingEnabled: update.marginTradingEnabled,
			};
		});
		marginRatioUpdates.forEach((update) => {
			subAccountUpdates[update.subAccountId] = {
				...subAccountUpdates[update.subAccountId],
				maxLeverage: update.maxLeverage,
			};
		});
		dlpAdvancedFlagUpdates.forEach((update) => {
			subAccountUpdates[update.subAccountId] = {
				...subAccountUpdates[update.subAccountId],
				advancedLp: update.advancedLp,
			};
		});
		reduceOnlyFlagUpdates.forEach((update) => {
			subAccountUpdates[update.subAccountId] = {
				...subAccountUpdates[update.subAccountId],
				reduceOnly: update.reduceOnly,
			};
		});
		marginModeUpdates.forEach((update) => {
			subAccountUpdates[update.subAccountId] = {
				...subAccountUpdates[update.subAccountId],
				marginMode: update.marginMode,
			};
		});
		return subAccountUpdates;
	}, [
		marginTradingUpdates,
		marginRatioUpdates,
		dlpAdvancedFlagUpdates,
		reduceOnlyFlagUpdates,
		marginModeUpdates,
	]);

	const inputValid = useMemo(() => {
		// if changing priority fee to custom, must be a value there
		return (
			stagedSettings.priorityFee !== 'custom' ||
			(stagedSettings.priorityFee === 'custom' &&
				stagedSettings.customPriorityFee)
		);
	}, [stagedSettings]);

	const addToMarginModeUpdates = (
		subAccountId: number,
		marginMode: MarginMode
	) => {
		const indexToReplace = marginModeUpdates.findIndex(
			(val) => val.subAccountId === subAccountId
		);

		if (indexToReplace > -1) {
			// -1 passed tells us that it's the same as original and to remove from the changing array
			if (marginMode === -1) {
				setMarginModeUpdates(
					marginModeUpdates.filter((item) => item.subAccountId !== subAccountId)
				);
			} else {
				const updatesCopy = [...marginModeUpdates];
				updatesCopy[indexToReplace].marginMode = marginMode;
				setMarginModeUpdates(updatesCopy);
			}
		} else {
			setMarginModeUpdates(
				marginModeUpdates.concat({ marginMode, subAccountId })
			);
		}
	};

	const addToMarginRatioUpdates = (
		subAccountId: number,
		maxLeverage: number
	) => {
		const indexToReplace = marginRatioUpdates.findIndex(
			(val) => val.subAccountId === subAccountId
		);

		if (indexToReplace > -1) {
			// -1 passed tells us that it's the same as original and to remove from the changing array
			if (maxLeverage === -1) {
				setMarginRatioUpdates(
					marginRatioUpdates.filter(
						(item) => item.subAccountId !== subAccountId
					)
				);
			} else {
				const updatesCopy = [...marginRatioUpdates];
				updatesCopy[indexToReplace].maxLeverage = maxLeverage;
				setMarginRatioUpdates(updatesCopy);
			}
		} else {
			setMarginRatioUpdates(
				marginRatioUpdates.concat({ maxLeverage, subAccountId })
			);
		}
	};

	const addToMarginTradingUpdates = (
		subAccountId: number,
		marginTradingEnabled: boolean
	) => {
		const indexToReplace = marginTradingUpdates.findIndex(
			(val) => val.subAccountId === subAccountId
		);

		if (indexToReplace > -1) {
			// undefined passed tells us that it's the same as original and to remove from the changing array
			if (marginTradingEnabled == undefined) {
				setMarginTradingUpdates(
					marginTradingUpdates.filter(
						(item) => item.subAccountId !== subAccountId
					)
				);
			} else {
				const updatesCopy = [...marginTradingUpdates];
				updatesCopy[indexToReplace].marginTradingEnabled = marginTradingEnabled;
				setMarginTradingUpdates(updatesCopy);
			}
		} else {
			setMarginTradingUpdates(
				marginTradingUpdates.concat({ marginTradingEnabled, subAccountId })
			);
		}
	};

	const addToDlpAdvancedUpdates = (
		subAccountId: number,
		advancedLp: boolean
	) => {
		const indexToReplace = dlpAdvancedFlagUpdates.findIndex(
			(val) => val.subAccountId === subAccountId
		);

		if (indexToReplace > -1) {
			// undefined passed tells us that it's the same as original and to remove from the changing array
			if (advancedLp == undefined) {
				setDlpAdvancedFlagUpdates(
					dlpAdvancedFlagUpdates.filter(
						(item) => item.subAccountId !== subAccountId
					)
				);
			} else {
				const updatesCopy = [...dlpAdvancedFlagUpdates];
				updatesCopy[indexToReplace].advancedLp = advancedLp;
				setDlpAdvancedFlagUpdates(updatesCopy);
			}
		} else {
			setDlpAdvancedFlagUpdates(
				dlpAdvancedFlagUpdates.concat({ advancedLp, subAccountId })
			);
		}
	};

	const addToReduceOnlyUpdates = (
		subAccountId: number,
		reduceOnly: boolean
	) => {
		const indexToReplace = reduceOnlyFlagUpdates.findIndex(
			(val) => val.subAccountId === subAccountId
		);

		if (indexToReplace > -1) {
			// undefined passed tells us that it's the same as original and to remove from the changing array
			if (reduceOnly == undefined) {
				setReduceOnlyFlagUpdates(
					reduceOnlyFlagUpdates.filter(
						(item) => item.subAccountId !== subAccountId
					)
				);
			} else {
				const updatesCopy = [...reduceOnlyFlagUpdates];
				updatesCopy[indexToReplace].reduceOnly = reduceOnly;
				setReduceOnlyFlagUpdates(updatesCopy);
			}
		} else {
			setReduceOnlyFlagUpdates(
				reduceOnlyFlagUpdates.concat({ reduceOnly, subAccountId })
			);
		}
	};

	const handleUpdateAllSubaccountSettings = async () => {
		await actions.updateSubaccountSettings(allSubAccountUpdates);
	};

	const handleSave = async () => {
		setDisableSave(true);

		updateSettings(stagedSettings);

		// TODO: Is this stuff necessary?
		const isEnvChanged =
			stagedSettings.mainnetOverride !== savedSettings.mainnetOverride;
		const isRpcChanged =
			stagedSettings.rpcEndpoint.value !== savedSettings.rpcEndpoint.value;
		const isStagingServerChanged =
			stagedSettings.stagingHistoryServerOverride !==
			savedSettings.stagingHistoryServerOverride;
		const isSubscriberTypeChanged =
			stagedSettings.eventSubscriberType !==
				savedSettings.eventSubscriberType ||
			stagedSettings.accountSubscriberType !==
				savedSettings.accountSubscriberType;
		const isTraderProfileChanged =
			stagedSettings.traderProfile !== savedSettings.traderProfile;

		setState((s) => {
			s.tradeForm.slippageTolerance = parseFloat(
				stagedSettings.slippageTolerance
			);
			s.tradeForm.allowInfSlippage = stagedSettings.allowInfSlippage;
		});

		/* TX REQUIRING UPDATES */
		if (
			marginRatioUpdates.length ||
			marginModeUpdates.length ||
			marginTradingUpdates.length ||
			dlpAdvancedFlagUpdates.length ||
			reduceOnlyFlagUpdates.length
		) {
			await handleUpdateAllSubaccountSettings();
		}

		onClose();

		if (isEnvChanged) {
			UI_UTILS.handleRefresh('About to refresh to change Environment');
		} else if (isRpcChanged) {
			UI_UTILS.handleRefresh('About to refresh to change RPC Node');
		} else if (isStagingServerChanged) {
			UI_UTILS.handleRefresh('About to refresh to change history server');
		} else if (isSubscriberTypeChanged) {
			UI_UTILS.handleRefresh(`About to refresh to toggle websockets`);
		}

		// log trader profile change to posthog
		if (isTraderProfileChanged) {
			captureEvent('trader_profile_changed', {
				old_profile: savedSettings.traderProfile,
				new_profile: stagedSettings.traderProfile,
			});
		}

		setDisableSave(false);
	};

	let settingsGroup;
	if (currentGroup === 'Network') {
		settingsGroup = (
			<NetworkSettings
				localSettings={stagedSettings}
				setLocalSettings={setStagedSettings}
				toggleRpcDropdown={toggleRpcDropdown}
				isRpcDropdownOpen={isRpcDropdownOpen}
			/>
		);
	} else if (currentGroup === 'Trade') {
		settingsGroup = (
			<TradeSettings
				localSettings={stagedSettings}
				setLocalSettings={setStagedSettings}
			/>
		);
	} else if (currentGroup === 'Interface') {
		settingsGroup = (
			<InterfaceSettings
				localSettings={stagedSettings}
				toggleSetting={toggleSetting}
			/>
		);
	} else if (currentGroup === 'Margin/Leverage') {
		settingsGroup = (
			<MarginLeverageSettings
				allSubAccountUpdates={allSubAccountUpdates}
				addToMarginRatioUpdates={addToMarginRatioUpdates}
				addToMarginTradingUpdates={addToMarginTradingUpdates}
				addToDlpAdvancedUpdates={addToDlpAdvancedUpdates}
				addToReduceOnlyUpdates={addToReduceOnlyUpdates}
				addToMarginModeUpdates={addToMarginModeUpdates}
			/>
		);
	} else if (currentGroup === 'Other') {
		settingsGroup = (
			<OtherSettings
				localSettings={stagedSettings}
				toggleSetting={toggleSetting}
				updateSettings={setStagedSettings}
			/>
		);
	}

	const isLightTheme = useDriftTheme() === DriftTheme.light;

	const isMobile = useIsMobileScreenSize();
	const isSafari = useIsSafari();

	/* Txn requiring settings reset if you switch to another tab, to not confuse users */
	useEffect(() => {
		if (currentGroup !== 'Margin/Leverage') {
			setMarginRatioUpdates([]);
			setMarginTradingUpdates([]);
			setDlpAdvancedFlagUpdates([]);
		}
	}, [currentGroup]);

	useEffect(() => {
		// this syncs staged settings when saved settings are changed outside of this component
		setStagedSettings(savedSettings);
	}, [savedSettings]);

	return (
		<div
			className={twMerge(
				'h-full overflow-visible',
				isSafari && isMobile && 'pb-16'
			)}
		>
			<div className="flex border-b border-container-border xs:h-full sm:h-[420px] xs:flex-col pb-16 sm:pb-0 sm:flex-row">
				{/* mobile navigation */}
				<div className="w-full px-4 py-4 xs:block sm:hidden">
					{mobileView === 'Nav' &&
						SETTINGS_GROUPS.map((group) => {
							return (
								<div
									key={group}
									className="flex items-center justify-between w-full p-3 mb-3 rounded cursor-pointer bg-container-bg hover:bg-container-bg-hover"
									onClick={() => {
										setMobileView(group);
										setCurrentGroup(group);
									}}
								>
									<div className="flex">
										<div className="flex items-center justify-center w-4 h-4 mr-3">
											{group === 'Interface' ? (
												<SettingsInterfaceIcon active={false} />
											) : group === 'Margin/Leverage' ? (
												<SettingsMarginLeverageIcon active={false} />
											) : group === 'Other' ? (
												<SettingsOther active={false} />
											) : (
												<SettingsTrade active={false} />
											)}
										</div>
										<Text.BODY1 className="text-text-label">{group}</Text.BODY1>
									</div>
									<Chevron
										direction="right"
										className="w-3 h-3"
										colour="#6683A7"
									/>
								</div>
							);
						})}
				</div>
				{/* mobile settings */}
				{isMobile && mobileView !== 'Nav' && (
					<>
						<div
							onClick={() => {
								setMobileView('Nav');
							}}
							className="block px-4 mb-6 -my-4 cursor-pointer sm:hidden"
						>
							<Text.H5 className="flex text-text-label">
								<span>← Back / </span>
								<span className="flex">
									<span className="flex items-center justify-center w-4 h-4 mx-2">
										{(() => {
											return mobileView === 'Interface' ? (
												<SettingsInterfaceIcon active={false} />
											) : mobileView === 'Margin Trading' ? (
												<SettingsMarginLeverageIcon active={false} />
											) : mobileView === 'Other' ? (
												<SettingsOther active={false} />
											) : (
												<SettingsTrade active={false} />
											);
										})()}
									</span>
									{mobileView}
								</span>
							</Text.H5>
						</div>
						<div className="flex flex-col flex-shrink w-full h-full px-4 overflow-auto xs:block sm:hidden text-neutrals-30 thin-scroll">
							{settingsGroup}
						</div>
					</>
				)}
				{/* desktop navigation */}
				<div className="w-1/3 px-4 py-3 border-r xs:hidden sm:block border-container-border">
					{SETTINGS_GROUPS.map((group) => {
						const isActive = group === currentGroup;
						return (
							<RoundedGradientBorderBox
								key={group}
								className="mb-1"
								borderColour={isActive && isLightTheme && 'var(--app-gradient)'}
								borderHoverClass={
									isActive && isLightTheme
										? 'var(--app-gradient)'
										: 'transparent'
								}
							>
								<div
									onClick={() => {
										setIsRpcDropdownOpen(false);
										setCurrentGroup(group);
									}}
									className={`flex items-center p-2 rounded cursor-pointer bg-container-bg hover:bg-container-bg-hover ${
										isActive && 'bg-container-bg-hover'
									}`}
								>
									<div
										className={`mr-2.5 h-6 w-6 flex justify-center items-center rounded-full ${
											isActive && 'bg-container-bg'
										}`}
									>
										{group === 'Interface' ? (
											<SettingsInterfaceIcon active={isActive} />
										) : group === 'Margin/Leverage' ? (
											<SettingsMarginLeverageIcon active={isActive} />
										) : group === 'Other' ? (
											<SettingsOther active={isActive} />
										) : group === 'Network' ? (
											<SettingsNetwork active={isActive} />
										) : (
											<SettingsTrade active={isActive} />
										)}
									</div>
									<Text.BODY1
										className={
											isActive ? 'text-text-emphasis' : 'text-text-label'
										}
									>
										{group}
									</Text.BODY1>
								</div>
							</RoundedGradientBorderBox>
						);
					})}
				</div>
				{/* desktop settings */}
				<div className="w-2/3 px-4 py-4 overflow-auto thin-scroll xs:hidden sm:block text-text-default">
					{settingsGroup}
				</div>
			</div>
			{/* buttons */}
			<div
				className={twMerge(
					'flex justify-end gap-3 px-6 py-2 xs:absolute sm:static xs:w-full sm:w-auto',
					isMobile && (isSafari ? 'bottom-16' : 'bottom-0')
				)}
			>
				<Modal.ButtonBar>
					<Button.Secondary
						size="MEDIUM"
						onClick={onClose}
						disabled={disableSave}
					>
						<Text.H3>Cancel</Text.H3>
					</Button.Secondary>
					<Button.Primary
						size="MEDIUM"
						onClick={handleSave}
						disabled={!isInputChanged || !inputValid || disableSave}
					>
						<Text.H3>Save</Text.H3>
					</Button.Primary>
				</Modal.ButtonBar>
			</div>
		</div>
	);
};

export default SettingsForm;
