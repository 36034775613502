'use client';

import {
	AMM_RESERVE_PRECISION,
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	ONE,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
	ZERO,
} from '@drift-labs/sdk';
import { useEffect } from 'react';
import useAccountData from 'src/hooks/useAccountData';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';
import useBestBidAsk from 'src/hooks/useBestBidAsk';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useCurrentMarketStepSize } from 'src/hooks/useMarketStepSize';
import UI_UTILS from 'src/utils/uiUtils';
import useUserAccountIsReady from 'src/hooks/useUserAccountIsReady';
import { MAX_PREDICTION_PRICE_BIGNUM } from 'src/constants/math';
import {
	useSetTradeFormBaseSize,
	useSetTradeFormQuoteSize,
} from 'src/components/TradeForm/useSetTradeFormInputState';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import useMarkPrice from 'src/hooks/useMarkPrice';

const useTradeFormInputStateEngine = () => {
	const currentAccount = useAccountData();
	const set = useDriftStore((s) => s.set);
	const bestBidAsk = useBestBidAsk();
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const selectedMarketId = useDriftStore((s) => s.selectedMarket.marketId);
	const isPredictionMarket = selectedMarket.isPredictionMarket;
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);
	const userAccountIsReady = useUserAccountIsReady();
	const stepSize = useCurrentMarketStepSize();
	const getOraclePrice = useGetOraclePriceForMarket();
	const oraclePrice = getOraclePrice(selectedMarketId);
	const markPrice = useMarkPrice(selectedMarketId);

	const setQuoteFormValue = useSetTradeFormQuoteSize();
	const setBaseFormValue = useSetTradeFormBaseSize();

	const {
		tradeSide,
		leadSide,
		quoteSizeStringValue,
		baseSizeStringValue,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		orderType,
		estEntryPrice,
		isFlippingPosition,
	} = useDriftStore((s) => ({
		tradeSide: s.tradeForm.side,
		quoteSizeStringValue: s.tradeForm.quoteSizeStringValue,
		baseSizeStringValue: s.tradeForm.baseSizeStringValue,
		priceBoxStringValue: s.tradeForm.priceBoxStringValue,
		secondaryPriceBoxStringValue: s.tradeForm.secondaryPriceBoxStringValue,
		leadSide: s.tradeForm.leadSide,
		orderType: s.tradeForm.orderType,
		selectedMarket: s?.selectedMarket?.current,
		estEntryPrice: s.tradeForm.priceImpact.entryPrice,
		isFlippingPosition: s.tradeForm.isFlippingPosition,
	}));

	const currentPosition =
		userAccountIsReady && currentAccount
			? currentAccount.client
					?.getUserAccount()
					.perpPositions.find(
						(position) => position.marketIndex === selectedMarketId.marketIndex
					)
			: undefined;

	const clearBracketOrders = () =>
		set((s) => {
			s.tradeForm.bracketOrders = undefined;
		});

	const basePrecisionToUse = selectedMarket.isPerp
		? BASE_PRECISION_EXP
		: (selectedMarket.market as SpotMarketConfig).precisionExp;

	const rawBaseValue = BigNum.fromPrint(
		baseSizeStringValue,
		basePrecisionToUse
	);

	// Keep the step size in sync with the trade form
	useEffect(() => {
		set((s) => {
			s.tradeForm.stepSize = stepSize;
		});
	}, [stepSize]);

	// Listen for entry price / trade form updates to maintain correct state
	useEffect(() => {
		if (!oraclePrice) return;

		const isUserPriceDependentOrderType =
			orderType === 'limit' ||
			orderType === 'stopLimit' ||
			orderType === 'stopMarket' ||
			orderType === 'takeProfitLimit' ||
			orderType === 'takeProfitMarket';

		const shouldUseTradeFormPrice =
			oraclePrice.eqZero() || isUserPriceDependentOrderType; // This flag controls whether we should use the price in the tradeform that the user has entered when we are calculating the size/value of trades

		const tradeFormPriceStringValueToUse =
			orderType == 'stopLimit' || orderType == 'takeProfitLimit'
				? secondaryPriceBoxStringValue
				: priceBoxStringValue;

		const DISPLAY_QUOTE_VALUES_USING_PRICE_FIELD_INPUT = true; // Feature flag controlling whether we want to display quote sizes using the value the user put into the price field instead of using the oracle price

		const useDisplayPriceForQuoteDisplay =
			DISPLAY_QUOTE_VALUES_USING_PRICE_FIELD_INPUT &&
			isUserPriceDependentOrderType &&
			tradeFormPriceStringValueToUse;

		if (!shouldUseTradeFormPrice && !bestBidAsk) return; // If we're not using the trade form price and we don't have a best bid ask to use for a price, we can skip
		if (shouldUseTradeFormPrice && !tradeFormPriceStringValueToUse) return; // If we're using the trade form price but the user hasn't entered a price, we can skip
		if (quoteSizeStringValue === '' && baseSizeStringValue === '') return; // If the quote size and base size are both empty, we can skip

		let indexPriceToUse: BigNum; // This is the price we're going to base the rest of our calculations on

		// set price to use
		if (orderType == 'market') {
			indexPriceToUse = markPrice?.gtZero() ? markPrice : oraclePrice;
		} else {
			indexPriceToUse = shouldUseTradeFormPrice
				? BigNum.fromPrint(tradeFormPriceStringValueToUse, PRICE_PRECISION_EXP)
				: markPrice;
		}

		const isSellSidePredictionMarket =
			isPredictionMarket && tradeSide === 'sell';

		const priceToUseIsInverted =
			isSellSidePredictionMarket && shouldUseTradeFormPrice;

		const nonInversePriceToUse = priceToUseIsInverted
			? MAX_PREDICTION_PRICE_BIGNUM.sub(indexPriceToUse)
			: indexPriceToUse;

		const inversePriceToUse = priceToUseIsInverted
			? indexPriceToUse
			: MAX_PREDICTION_PRICE_BIGNUM.sub(indexPriceToUse); // Store the inverted price. Should only be necessary for short side prediction market orders

		const priceToUse = isSellPredictionMarket
			? inversePriceToUse
			: nonInversePriceToUse;

		const currentPositionDirection = currentPosition?.baseAssetAmount.isNeg()
			? 'short'
			: 'long';

		const currentPositionBase = currentPosition?.baseAssetAmount.abs() ?? ZERO;

		/**
		 * Handles the logic for the trade form if the user is leading with the quote size of the trade
		 * @returns
		 */
		const handleQuoteLeadSide = () => {
			// # Set base size to empty if price is zero
			if (priceToUse.eqZero()) {
				setBaseFormValue('');
				return;
			}

			// # if order type is oracle limit, price box is an offset so this calc doesnt make sense
			if (orderType === 'oracleLimit') {
				return;
			}

			let newBaseSize = BigNum.fromPrint(
				quoteSizeStringValue,
				QUOTE_PRECISION_EXP
			)
				.shift(AMM_RESERVE_PRECISION_EXP)
				.div(priceToUse);

			let stepSizePrecision = new BN(1 / (stepSize || 1));
			stepSizePrecision = stepSizePrecision.eq(ZERO) ? ONE : stepSizePrecision;

			const roundToStepSizePrecision =
				AMM_RESERVE_PRECISION.div(stepSizePrecision);

			newBaseSize = UI_UTILS.getBigNumRoundedToStepSize(
				newBaseSize,
				roundToStepSizePrecision
			);

			const isNewBaseSizeGreaterThanCurrentPositionBase =
				newBaseSize.val.gt(currentPositionBase);

			// we handle this specific case because prediction market prices are inverted for the sell side,
			// so when flipping a position in a prediction market, we need to account for the differing prices on both sides
			// when we are sure that the new base size is greater than the current position base size
			if (
				isPredictionMarket &&
				isFlippingPosition &&
				currentPosition &&
				isNewBaseSizeGreaterThanCurrentPositionBase
			) {
				const currentPositionBaseBigNum = BigNum.from(
					currentPositionBase,
					BASE_PRECISION_EXP
				);

				const currentPositionIsShort = currentPositionDirection === 'short';

				if (currentPositionIsShort && isSellSidePredictionMarket) {
					// If we're using shouldUseFlippedPrice then we know we should be on the sell side of the trade form. This logic should only be hit if we're flipping the position. Therefore should not be possible for currentPositionIsShort && shouldUseFlippedPrice to be true here.
					return;
				}

				const quoteAmountForClosingCurrentPosition = currentPositionBaseBigNum
					// A short position's value is denominated in base size multiplied by flipped price
					.mul(
						currentPositionIsShort ? inversePriceToUse : nonInversePriceToUse
					)
					.shiftTo(QUOTE_PRECISION_EXP);

				const remainingOpenFlippedPositionQuoteAmount = BigNum.fromPrint(
					quoteSizeStringValue,
					QUOTE_PRECISION_EXP
				).sub(quoteAmountForClosingCurrentPosition);

				const remainingOpenFlippedPositionBaseAmount =
					remainingOpenFlippedPositionQuoteAmount
						.shift(AMM_RESERVE_PRECISION_EXP)
						.div(
							isSellSidePredictionMarket
								? inversePriceToUse
								: nonInversePriceToUse
						);

				setBaseFormValue(
					currentPositionBaseBigNum
						.add(remainingOpenFlippedPositionBaseAmount)
						.toTradePrecision()
				);
			} else {
				setBaseFormValue(newBaseSize.toTradePrecision());
			}
		};

		/**
		 * Handles the logic for the trade form if the user is leading with the base size of the trade
		 * @returns
		 */
		const handleBaseLeadSide = () => {
			if (indexPriceToUse.eqZero()) {
				setQuoteFormValue('');
				return;
			}

			const priceToUseForQuoteCalculation = useDisplayPriceForQuoteDisplay
				? BigNum.fromPrint(tradeFormPriceStringValueToUse, PRICE_PRECISION_EXP)
				: isSellSidePredictionMarket
				? inversePriceToUse
				: nonInversePriceToUse;

			const newQuoteSize = priceToUseForQuoteCalculation.mul(rawBaseValue);

			// avoid infinite loops
			if (newQuoteSize.toTradePrecision() === quoteSizeStringValue) {
				return;
			}

			const isNewBaseSizeGreaterThanCurrentPositionBase =
				rawBaseValue.val.gt(currentPositionBase);

			if (
				isPredictionMarket &&
				isFlippingPosition &&
				currentPosition &&
				isNewBaseSizeGreaterThanCurrentPositionBase
			) {
				const currentPositionBaseBigNum = BigNum.from(
					currentPositionBase,
					BASE_PRECISION_EXP
				);

				const currentPositionIsShort = currentPositionDirection === 'short';

				if (currentPositionIsShort && isSellSidePredictionMarket) {
					// If we're using shouldUseFlippedPrice then we know we should be on the sell side of the trade form. This logic should only be hit if we're flipping the position. Therefore should not be possible for currentPositionIsShort && shouldUseFlippedPrice to be true here.
					return;
				}

				const quoteAmountForClosingCurrentPosition = currentPositionBaseBigNum
					// A short position's value is denominated in base size multiplied by flipped price
					.mul(
						currentPositionIsShort ? inversePriceToUse : nonInversePriceToUse
					)
					.shiftTo(QUOTE_PRECISION_EXP);

				const remainingOpenFlippedPositionBaseAmount = rawBaseValue.sub(
					currentPositionBaseBigNum
				);

				const remainingOpenFlippedPositionQuoteAmount =
					remainingOpenFlippedPositionBaseAmount
						.mul(
							isSellSidePredictionMarket
								? inversePriceToUse
								: nonInversePriceToUse
						)
						.shiftTo(QUOTE_PRECISION_EXP);

				setQuoteFormValue(
					quoteAmountForClosingCurrentPosition
						.add(remainingOpenFlippedPositionQuoteAmount)
						.toTradePrecision()
				);
			} else {
				setQuoteFormValue(newQuoteSize.toTradePrecision());
			}
		};

		if (leadSide === 'quote') {
			handleQuoteLeadSide();
		} else {
			handleBaseLeadSide();
		}
	}, [
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		leadSide,
		baseSizeStringValue,
		quoteSizeStringValue,
		orderType,
		bestBidAsk,
		stepSize,
		isSellPredictionMarket,
		tradeSide,
		estEntryPrice,
		currentPosition,
		isFlippingPosition,
		oraclePrice,
	]);

	const resetFormItems = () => {
		set((s) => {
			// @ts-expect-error
			s.tradeForm.quoteSizeStringValue = '';
			// @ts-expect-error
			s.tradeForm.baseSizeStringValue = '';
			s.tradeForm.maxLeverageSelected = false;
			s.tradeForm.showBracketOrderForm = false;
			s.tradeForm.bracketOrders = undefined;
		});
	};

	// reset form fields on market change
	useEffect(() => {
		resetFormItems();
	}, [selectedMarket?.market?.symbol]);

	useEffect(() => {
		set((s) => {
			s.tradeForm.bracketOrders = undefined;
		});
	}, [orderType]);

	const appEventEmitter = useAppEventEmitter();

	const resetTradeForm = () => {
		setQuoteFormValue('');
		setBaseFormValue('');
		clearBracketOrders();
	};

	// Reset the form when a transaction goes through successfully
	useEffect(() => {
		const tradeConfirmationHandler = () => {
			resetTradeForm();
		};

		appEventEmitter.on('tradeConfirmation', tradeConfirmationHandler);

		return () => {
			appEventEmitter.removeListener(
				'tradeConfirmation',
				tradeConfirmationHandler
			);
		};
	}, []);

	useEffect(() => {
		appEventEmitter.on('resetTradeForm', resetTradeForm);

		return () => {
			appEventEmitter.removeListener('tradeConfirmation', resetTradeForm);
		};
	}, []);
};

export default useTradeFormInputStateEngine;
