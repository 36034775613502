'use client';

import { EyeShow, Open } from '@drift-labs/icons';
import {
	BN,
	BigNum,
	MarketType,
	OrderActionExplanation,
	PositionDirection,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	Serializer,
	UIMarket,
	UISerializableOrderActionRecord,
	matchEnum,
} from '@drift/common';
import { useEffect, useState } from 'react';
import useDateDisplayState from 'src/hooks/useDateDisplayState';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useSafePush from 'src/hooks/useSafePush';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';
import useDevSwitchIsOn from '../hooks/useDevSwitchIsOn';
import useShowAccountValues from '../hooks/useShowAccountValues';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import useMarketsInfoStore from '../stores/useMarketsInfoStore';
import Button from './Button';
import InlineLoadingBar from './InlineLoadingBar/InlineLoadingBar';
import PaginatedTableV2 from './PaginatedTableV2';
import TableV2 from './Tables/TableV2';
import Text from './Text/Text';
import Tooltip from './Tooltip/Tooltip';
import Counterparty from './Counterparty';
import { TradePageHistoryRow } from './TradePageHistoryRow';

export type HistoryRowCommonProps = {
	baseAssetAmountFilled: BigNum;
	baseAssetSymbol: string;
	displayDatesAsTimestamp: boolean;
	fee: BigNum;
	isLong: boolean;
	isSpot: boolean;
	isPredictionMarket: boolean;
	marketName: string;
	quoteAssetAmountFilled: BigNum;
	showAccountValues: boolean;
	tableGrid: string;
	ts: BN;
	txSig: string;
	counterparty: string | undefined;
	marketType: string;
	actionExplanation: OrderActionExplanation;
	decimals: number;
	isTaker?: boolean;
};

const MobileOverviewTradeHistoryRow = (
	props: HistoryRowCommonProps & {
		handleClickRow: () => void;
		avgEntryPrice: string;
	}
) => {
	// Todo add onClick here that shows the trade details modal and populates its data
	return (
		<TableV2.BodyRow
			grid={props.tableGrid}
			lastColumnJustify="start"
			onClick={props.handleClickRow}
		>
			<TableV2.MarketCell
				baseAssetSymbol={props.baseAssetSymbol}
				marketSymbol={props.marketName}
				isSpot={props.isSpot}
				large
				direction={
					props.isSpot || props.isPredictionMarket
						? props.isLong
							? 'buy'
							: 'sell'
						: props.isLong
						? 'long'
						: 'short'
				}
				suffix={
					matchEnum(props.actionExplanation, OrderActionExplanation.LIQUIDATION)
						? '(liq.)'
						: matchEnum(
								props.actionExplanation,
								OrderActionExplanation.DERISK_LP
						  )
						? '(BAL auto-derisk)'
						: ''
				}
			/>

			<TableV2.BodyCell className="font-numeral" flexCol>
				{props.showAccountValues
					? UI_UTILS.roundBigNumToDecimalPlace(
							props.baseAssetAmountFilled,
							props.decimals
					  ).prettyPrint()
					: '∗∗∗∗'}{' '}
				{props.baseAssetSymbol}
				<Text.MICRO1 className="mt-1 text-text-secondary">
					{props.showAccountValues
						? UI_UTILS.isNotionalDust(props.quoteAssetAmountFilled)
							? '< $0.01'
							: props.quoteAssetAmountFilled.toNotional()
						: '$ ∗∗∗∗∗∗'}
				</Text.MICRO1>
			</TableV2.BodyCell>

			<TableV2.BodyCell className="font-numeral">
				{props.avgEntryPrice}
			</TableV2.BodyCell>

			{/* <TableV2.BodyCell className="font-numeral">
				{props.showAccountValues
					? props.fee?.toTradePrecision() ?? '0.00'
					: '∗∗∗∗'}{' '}
				USDC
			</TableV2.BodyCell> */}

			{/* <TableV2.DateCell
				className="text-left font-numeral"
				date={new Date(props.ts.toNumber() * 1000)}
				displayAsTimestamp={props.displayDatesAsTimestamp}
			/> */}

			{/* <TableV2.BodyCell>
				<TradeHistoryRowActions
					txSig={props.txSig}
					counterparty={props.counterparty}
					marketType={props.marketType}
					actionExplanation={props.actionExplanation}
					isTaker={props.isTaker}
				/>
			</TableV2.BodyCell> */}
		</TableV2.BodyRow>
	);
};

export const TradeHistoryRowActions = ({
	txSig,
	counterparty,
	marketType,
	actionExplanation,
	isTaker,
}: {
	txSig: string;
	counterparty: string | undefined;
	marketType: string;
	actionExplanation: OrderActionExplanation;
	isTaker?: boolean;
}) => {
	return (
		<div className="flex items-center gap-2 [&>div]:flex [&>div]:items-center">
			<div
				className="hover:cursor-pointer hover:opacity-80"
				onClick={() => {
					UI_UTILS.openUrl(UI_UTILS.getUrlForTx(txSig));
				}}
			>
				<Tooltip placement="top" content={'View transaction'}>
					<Open color="var(--text-label)" />
				</Tooltip>
			</div>
			<Counterparty
				counterparty={counterparty}
				marketType={ENUM_UTILS.toObj(marketType)}
				actionExplanation={actionExplanation}
				isTaker={isTaker}
			/>
		</div>
	);
};

const OverviewTradeHistoryRow = (
	props: HistoryRowCommonProps & {
		avgEntryPrice: string;
		isDataRow?: boolean;
	}
) => {
	return (
		<TableV2.BodyRow
			grid={props.tableGrid}
			lastColumnJustify="start"
			isDataRow={props.isDataRow}
		>
			<div className="flex items-center w-full gap-2 overflow-hidden">
				<TableV2.MarketCell
					baseAssetSymbol={props.baseAssetSymbol}
					marketSymbol={props.marketName}
					isSpot={props.isSpot}
					className="whitespace-nowrap"
				/>
				{matchEnum(
					props.actionExplanation,
					OrderActionExplanation.LIQUIDATION
				) && (
					<TableV2.BodyCell className="ml-2 text-text-label">
						(Liquidation)
					</TableV2.BodyCell>
				)}
			</div>

			<TableV2.BodyCell
				className={`capitalize ${
					props.isLong ? `text-positive-green` : `text-negative-red`
				}`}
			>
				{props.isSpot || props.isPredictionMarket
					? props.isLong
						? `Buy`
						: `Sell`
					: props.isLong
					? `Long`
					: `Short`}
			</TableV2.BodyCell>

			<TableV2.TradeSizeCell
				baseSize={UI_UTILS.roundBigNumToDecimalPlace(
					props.baseAssetAmountFilled,
					props.decimals
				)}
				baseSymbol={props.baseAssetSymbol}
				marketSymbol={props.marketName}
				hideValues={!props.showAccountValues}
				className="font-numeral"
			/>

			<TableV2.NotionalCell
				value={props.quoteAssetAmountFilled}
				textOverride={!props.showAccountValues && '$ ∗∗∗∗∗∗'}
				className="font-numeral"
			/>

			<TableV2.BodyCell className="font-numeral">
				{props.avgEntryPrice}
			</TableV2.BodyCell>

			<TableV2.AssetCell
				value={props.fee ? props.fee : BigNum.zero()}
				assetSymbol={'USDC'}
				isAccountValueToHide
				className="font-numeral"
			/>
			<TableV2.DateCell
				className="text-left font-numeral"
				date={new Date(props.ts.toNumber() * 1000)}
				displayAsTimestamp={props.displayDatesAsTimestamp}
			/>
			<TableV2.BodyCell className="justify-end w-full">
				<TradeHistoryRowActions
					txSig={props.txSig}
					counterparty={props.counterparty}
					marketType={props.marketType}
					actionExplanation={props.actionExplanation}
					isTaker={props.isTaker}
				/>
			</TableV2.BodyCell>
		</TableV2.BodyRow>
	);
};

const TradeHistoryTable = (props: {
	page: 'trade' | 'overview' | 'predictions';
}) => {
	// TODO : This table isn't 100% correctly styled. Do a trade with a prediction market and you will see.
	const setStoreState = useDriftStore((s) => s.set);
	const isMobile = useIsMobileScreenSize();

	const getMarketInfoByIndexAndType = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);
	const [isTableScrollable, setIsTableScrollable] = useState(false);

	const safePush = useSafePush();

	const getDecimalsForMarket = (
		marketIndex: number,
		marketType: MarketType
	) => {
		const marketInfo = getMarketInfoByIndexAndType(marketIndex, marketType);

		return marketInfo.genericInfo.baseDisplayDecimals;
	};

	// # Constants
	const pageSize = props.page == 'trade' ? (isMobile ? 10 : 50) : 20;
	const historyKey =
		props.page == 'predictions' ? 'predictionsTradeHistory' : 'tradeHistory';

	const tableGrid =
		props.page == 'trade'
			? isMobile
				? `minmax(206px, 1fr) 120px 120px minmax(120px, 1fr) minmax(50%, 1fr) minmax(50%, 1fr)`
				: `minmax(140px,10fr) minmax(120px,10fr) minmax(100px,140px) minmax(100px,140px) minmax(100px,140px) minmax(120px,8fr) minmax(60px, 2fr)`
			: isMobile
			? `1.6fr 1fr 1fr`
			: `300px 100px minmax(100px,200px) minmax(100px,140px) minmax(100px,140px) minmax(120px,140px) minmax(140px,8fr) minmax(80px,1fr)`;

	const headers =
		props.page == 'trade'
			? isMobile
				? ['market', 'size', 'price', 'fee', 'date']
				: ['market', 'size', 'price', 'notional', 'fee', 'date', '']
			: isMobile
			? ['market', 'size', 'price']
			: ['market', 'direction', 'size', 'notional', 'price', 'fee', 'date', ''];

	const accountTradeHistory = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount && currentAccount[historyKey]?.initialHistoryLoaded
			? currentAccount[historyKey].loadedUserTradeHistory
			: undefined;
	});

	const allTradeHistoryTotal = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount?.[historyKey]?.userTradeHistoryTotalCount ?? 0;
	});

	const historyIsLoading = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount?.[historyKey]?.loading ?? false;
	});

	const currentAccount = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return !!currentAccount;
	});

	const currentAccountAuthority = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount?.authority;
	});

	const currentAccountUserKey = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount?.userKey;
	});

	const currentAccountPubkey = useDriftAccountStore((s) => {
		const currentAccountKey = s.currentUserKey;
		const currentAccount = s.accounts[currentAccountKey];

		return currentAccount?.pubKey;
	});

	const setAccountState = useDriftAccountStore((s) => s.set);
	const { displayDatesAsTimestamp, toggleDateDisplayType } =
		useDateDisplayState();

	const [currentTradeHistory, setCurrentTradeHistory] = useState<
		UISerializableOrderActionRecord[]
	>([]);

	// # State
	const [currentPage, setCurrentPage] = useState(0);

	// # Effect Hooks
	const showAccountValues = useShowAccountValues();

	// Reset state when authority changes
	useEffect(() => {
		setCurrentTradeHistory(
			accountTradeHistory?.slice(0, pageSize) ?? undefined
		);
		setCurrentPage(0);
	}, [currentAccountAuthority, props.page]);

	// # Methods
	const setUserTradeHistoryLoading = (isLoading: boolean) => {
		setAccountState((s) => {
			s.accounts[currentAccountUserKey][historyKey].loading = isLoading;
		});
	};

	const executePageChange = (newPage: number) => {
		if (newPage === currentPage) return;

		// Pages are zero-indexed, so newPage+1
		if (accountTradeHistory?.length < pageSize * (newPage + 1) || newPage < 0) {
			setUserTradeHistoryLoading(true);

			const { beforeTs, afterTs } = UI_UTILS.getBeforeOrAfterTs(
				currentTradeHistory,
				currentPage,
				newPage
			);

			ExchangeHistoryClient.getTradesForUsers(
				[currentAccountPubkey],
				newPage,
				currentPage,
				beforeTs,
				afterTs,
				undefined,
				undefined,
				undefined,
				props.page === 'predictions'
			).then((result) => {
				setCurrentTradeHistory(result?.body?.data?.records?.[0]);
				setUserTradeHistoryLoading(false);

				const totalCount = result?.body?.data?.totalCounts?.[0];
				const isJumpToLast = newPage < 0;

				// if going directly to last page, derive the page number from count
				setCurrentPage(
					isJumpToLast ? UI_UTILS.getPageNumForCount(totalCount) : newPage
				);
			});
		} else {
			setCurrentTradeHistory(
				accountTradeHistory.slice(
					newPage * pageSize,
					newPage * pageSize + pageSize
				)
			);

			setCurrentPage(newPage);
		}
	};

	const showSelectedTradeModal = (
		selectedTradeDetails: DriftStore['selectedTradeDetails']
	) => {
		setStoreState((s) => {
			s.selectedTradeDetails = selectedTradeDetails;
			s.modals.showTradeDetailsModal = true;
		});
	};

	useEffect(() => {
		if (currentPage === 0) {
			setCurrentTradeHistory(
				accountTradeHistory?.slice(
					currentPage * pageSize,
					currentPage * pageSize + pageSize
				) ?? undefined
			);
		}
	}, [accountTradeHistory?.length]);

	const resetState = () => {
		setCurrentTradeHistory([]);
		setCurrentPage(0);
	};

	const isConnected = useWalletIsConnected({
		onDisconnectedCallback: resetState,
	});

	const devSwitch = useDevSwitchIsOn();

	const goToTradeHistoryPage = () => {
		safePush('/overview/trades'); // mobile's trade history page
	};

	// # Rendering
	return props.page == 'trade' ? (
		<>
			<PaginatedTableV2
				noBorder
				tableGrid={tableGrid}
				pageSize={pageSize}
				currentPage={currentPage}
				pageChangeCallback={executePageChange}
				totalItems={allTradeHistoryTotal}
				tableState={{
					records: currentTradeHistory,
					emptyStateText: 'No Trade History found',
					requireAccountCreated: true,
					requireWalletConnect: true,
					pageChangeLoading: historyIsLoading,
					id: 'trade_history_table',
				}}
				setIsScrollable={setIsTableScrollable}
				tableContentTop={
					<TableV2.HeaderRow
						grid={tableGrid}
						className={`w-full grid text-xs pr-2`}
						header
						forceBottomBorder
						addScrollPadding={isTableScrollable}
					>
						{/* Table Header Cells */}
						{headers.map((label) => (
							<TableV2.HeaderCell
								key={`header_${label}`.replace(/ /g, '')}
								className="capitalize"
							>
								{label.toLowerCase() === 'date' ? (
									<div
										onClick={toggleDateDisplayType}
										className={'cursor-pointer flex items-center gap-1'}
									>
										<span>{label}</span>
										<EyeShow size={16} />
									</div>
								) : (
									<>{label}</>
								)}
								{/* TODO-v2 temp disabled filtering here, need to rewrite it to query for new dataset + record count */}
							</TableV2.HeaderCell>
						))}
					</TableV2.HeaderRow>
				}
				tableContentMiddle={
					<div className="max-h-full">
						{historyIsLoading ? (
							<div
								className={`flex flex-col justify-center items-center h-full w-full text-center rounded-md`}
							>
								<InlineLoadingBar />
							</div>
						) : (
							currentAccount &&
							isConnected &&
							UI_UTILS.sortRecordsByTs(currentTradeHistory)?.map(
								(tradeRecord, index) => {
									const tradeInfo = UI_UTILS.getTradeInfoFromOrderActionRecord(
										currentAccountPubkey,
										tradeRecord
									);

									const decimals = getDecimalsForMarket(
										tradeInfo.marketIndex,
										ENUM_UTILS.toObj(tradeInfo.marketType)
									);
									const avgEntryPrice =
										COMMON_UI_UTILS.calculateAverageEntryPrice(
											tradeInfo.quoteAssetAmountFilled,
											tradeInfo.baseAssetAmountFilled
										);
									const uiMarket = new UIMarket(
										tradeInfo.marketIndex,
										ENUM_UTILS.toObj(tradeInfo.marketType)
									);
									const marketName = uiMarket.marketName;
									const baseAssetSymbol = uiMarket.baseAssetSymbol();
									const stringifiedTradeRecord = JSON.stringify(
										Serializer.Serialize.UIOrderActionRecord(tradeRecord)
									);

									const isPredictionMarket = uiMarket.isPredictionMarket;

									return (
										<TradePageHistoryRow
											key={`${index}_${tradeInfo.marketIndex.toString()}_${tradeInfo.txSig.toString()}`}
											baseAssetAmountFilled={tradeInfo.baseAssetAmountFilled}
											baseAssetSymbol={baseAssetSymbol}
											decimals={decimals}
											devSwitch={devSwitch}
											displayDatesAsTimestamp={displayDatesAsTimestamp}
											entryPriceBigNum={avgEntryPrice}
											fee={tradeInfo.fee}
											isLong={matchEnum(
												tradeInfo.direction,
												PositionDirection.LONG
											)}
											isSpot={uiMarket.isSpot}
											marketName={marketName}
											quoteAssetAmountFilled={tradeInfo.quoteAssetAmountFilled}
											showAccountValues={showAccountValues}
											stringifiedTradeRecord={stringifiedTradeRecord}
											tableGrid={tableGrid}
											ts={tradeInfo.ts}
											txSig={tradeRecord.txSig}
											isDataRow
											// TODO PERFORMANCE : The pubkey toStrings in this component are potentially a high roi option if we can figure out how to minimise them
											counterparty={tradeInfo.counterparty?.toString()}
											marketType={tradeInfo.marketType}
											actionExplanation={tradeRecord.actionExplanation}
											marketSymbol={uiMarket.symbol}
											isPredictionMarket={isPredictionMarket}
										/>
									);
								}
							)
						)}
					</div>
				}
			/>
			{isMobile && (
				<Button.Ghost
					className="flex items-center w-full gap-1 text-center text-text-label"
					size="MEDIUM"
					onClick={goToTradeHistoryPage}
				>
					<Text.BODY2>View full trade history</Text.BODY2>
					<Open />
				</Button.Ghost>
			)}
		</>
	) : (
		<PaginatedTableV2
			noBorder
			tableGrid={tableGrid}
			pageSize={pageSize}
			currentPage={currentPage}
			pageChangeCallback={executePageChange}
			totalItems={allTradeHistoryTotal}
			tableState={{
				records: currentTradeHistory,
				emptyStateText: 'No Trade History found',
				requireWalletConnect: true,
				requireAccountCreated: true,
				pageChangeLoading: historyIsLoading,
				id: 'trade_history_table',
			}}
			setIsScrollable={setIsTableScrollable}
			tableContentTop={
				<TableV2.HeaderRow
					grid={tableGrid}
					className={`w-full grid text-xs`}
					header
					forceBottomBorder
					lastColumnJustify="start"
					addScrollPadding={isTableScrollable}
				>
					{headers.map((label, index) => {
						return (
							<TableV2.HeaderCell key={`trade_history_header_${index}`}>
								{label.toLowerCase() === 'date' ? (
									<div
										onClick={toggleDateDisplayType}
										className={'cursor-pointer flex items-center gap-1'}
									>
										<span>{label}</span>
										<EyeShow size={16} />
									</div>
								) : (
									<>{label}</>
								)}
							</TableV2.HeaderCell>
						);
					})}
				</TableV2.HeaderRow>
			}
			tableContentMiddle={
				historyIsLoading ? (
					<div className="flex flex-col justify-center items-center xs:h-[40vh] sm:h-full w-full text-center rounded-md xs:pt-8 sm:pt-0">
						<InlineLoadingBar />
					</div>
				) : (
					<div className={`absolute max-h-full w-full text-xs`}>
						{/* Table Data Cells */}
						{isConnected &&
							currentAccount &&
							currentTradeHistory?.map((tradeRecord, index) => {
								const tradeInfo = UI_UTILS.getTradeInfoFromOrderActionRecord(
									currentAccountPubkey,
									tradeRecord
								);
								const avgEntryPrice =
									COMMON_UI_UTILS.calculateAverageEntryPrice(
										tradeInfo.quoteAssetAmountFilled,
										tradeInfo.baseAssetAmountFilled
									).toNotional(true);
								const uiMarket = new UIMarket(
									tradeInfo.marketIndex,
									ENUM_UTILS.toObj(tradeInfo.marketType)
								);
								const marketName = uiMarket.marketName;

								const baseAssetSymbol = uiMarket.baseAssetSymbol();
								const isSpot = uiMarket.isSpot;
								const isLong = matchEnum(
									tradeInfo.direction,
									PositionDirection.LONG
								);
								const isPredictionMarket = uiMarket.isPredictionMarket;

								const decimals = getDecimalsForMarket(
									tradeInfo.marketIndex,
									ENUM_UTILS.toObj(tradeInfo.marketType)
								);

								return isMobile ? (
									<MobileOverviewTradeHistoryRow
										key={`${index}_${tradeInfo.marketIndex.toString()}_${tradeInfo.txSig.toString()}`}
										avgEntryPrice={avgEntryPrice}
										baseAssetAmountFilled={tradeInfo.baseAssetAmountFilled}
										baseAssetSymbol={baseAssetSymbol}
										displayDatesAsTimestamp={displayDatesAsTimestamp}
										fee={tradeInfo.fee}
										isLong={isLong}
										isSpot={isSpot}
										marketName={marketName}
										quoteAssetAmountFilled={tradeInfo.quoteAssetAmountFilled}
										showAccountValues={showAccountValues}
										tableGrid={tableGrid}
										ts={tradeInfo.ts}
										handleClickRow={() => {
											showSelectedTradeModal({
												baseAssetAmountFilled: tradeInfo.baseAssetAmountFilled,
												baseAssetSymbol,
												avgEntryPrice,
												fee: tradeInfo.fee,
												isLong,
												isSpot,
												marketName,
												quoteAssetAmountFilled:
													tradeInfo.quoteAssetAmountFilled,
												ts: tradeInfo.ts,
											});
										}}
										txSig={tradeRecord.txSig}
										counterparty={tradeInfo.counterparty?.toString()}
										marketType={tradeInfo.marketType}
										actionExplanation={tradeRecord.actionExplanation}
										isTaker={tradeInfo.isTaker}
										isPredictionMarket={isPredictionMarket}
										decimals={decimals}
									/>
								) : (
									<OverviewTradeHistoryRow
										key={`${index}_${tradeInfo.marketIndex.toString()}_${tradeInfo.ts.toString()}`}
										avgEntryPrice={avgEntryPrice}
										baseAssetAmountFilled={tradeInfo.baseAssetAmountFilled}
										baseAssetSymbol={baseAssetSymbol}
										displayDatesAsTimestamp={displayDatesAsTimestamp}
										fee={tradeInfo.fee}
										isLong={isLong}
										isSpot={isSpot}
										marketName={marketName}
										quoteAssetAmountFilled={tradeInfo.quoteAssetAmountFilled}
										showAccountValues={showAccountValues}
										tableGrid={tableGrid}
										ts={tradeInfo.ts}
										isDataRow
										txSig={tradeRecord.txSig}
										counterparty={tradeInfo.counterparty?.toString()}
										marketType={tradeInfo.marketType}
										actionExplanation={tradeRecord.actionExplanation}
										isTaker={tradeInfo.isTaker}
										isPredictionMarket={isPredictionMarket}
										decimals={decimals}
									/>
								);
							})}
					</div>
				)
			}
			tableContentBottom={
				!isMobile && (
					<Text.P1 className="flex items-center p-1 text-text-tertiary">
						Only past 3mo of history is shown. Your full history is available
						via export.
					</Text.P1>
				)
			}
		/>
	);
};

export default TradeHistoryTable;
