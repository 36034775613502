'use client';

import { BigNum } from '@drift-labs/sdk';
import React, { useEffect, useMemo, useState } from 'react';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { compareDriftProps } from 'src/utils/compareProps';
import useShowAccountValues from '../../hooks/useShowAccountValues';
import { delimiter as localeDelimiter } from '../../utils/locale';
import UI_UTILS from '../../utils/uiUtils';
import ColourCodedValue from './ColourCodedValue';
import { COMMON_UI_UTILS } from '@drift/common';
import { twMerge } from 'tailwind-merge';

type Props = {
	value: BigNum;
	displayType:
		| 'notional'
		| 'tradePrecision'
		| 'millified'
		| 'percentageChange'
		| 'percentage'
		| 'asset'
		| 'int';
	subType?: '$' | '$Change' | 'usdc' | 'usdcIcon' | 'assetChange';
	colourCoded?: boolean;
	keepStaleValues?: boolean;
	showSkeletonInstead?: string;
	className?: string;
	loading?: boolean;
	assetSymbol?: string;
	millify?: boolean;
	textOverride?: string;
	toTradePrecision?: boolean;
	toFixed?: number;
	isHiddenAccountValue?: boolean;
	softenZeroValues?: boolean;
	dataPuppetTag?: string;
	trimZeroes?: number;
};

const numIsInvalid = (val: BigNum) => {
	if (val === null) return true;
	if (typeof val === 'undefined') return true;
	return false;
};

export const formatValue = ({
	value,
	displayType,
	subType,
	isInvalid,
	assetSymbol,
	millify,
	toTradePrecision,
	toFixed,
	trimZeroes,
}: Pick<
	Props,
	| 'value'
	| 'subType'
	| 'displayType'
	| 'assetSymbol'
	| 'millify'
	| 'toTradePrecision'
	| 'toFixed'
	| 'trimZeroes'
> & {
	isInvalid: boolean;
}) => {
	let prefix = '';
	let formattedValue = value?.print() ?? '';
	let suffix = '';

	if (isInvalid) {
		formattedValue = '';
	}

	switch (displayType) {
		case 'millified':
			if (!isInvalid) {
				formattedValue = value.toMillified();
			}
			break;
		case 'notional':
			if (!isInvalid) {
				if (!millify) {
					if (toFixed) {
						formattedValue = UI_UTILS.toNotional(value.abs().toNum(), toFixed);
					} else if (formattedValue.length < 6) {
						// If not a large number, use default notional function to force 2 decimal places
						formattedValue = value.abs().toNotional();
					} else {
						formattedValue = value.abs().toNotional(toTradePrecision);
					}
				} else {
					formattedValue = `$${value.abs().toMillified(4)}`;
				}
				if (value.lt(BigNum.zero())) {
					prefix = '-';
				}
			}
			break;
		case 'tradePrecision':
			if (!isInvalid) {
				formattedValue = value.toTradePrecision();
			}
			break;
		case 'percentage':
			if (!isInvalid) {
				formattedValue = toFixed ? value.toFixed(toFixed) : value.printShort();
			}
			suffix = '%';
			break;
		case 'percentageChange':
			if (!isInvalid) {
				formattedValue = value.abs().toFixed(2);

				if (value.lt(BigNum.zero())) {
					prefix = '-';
				} else {
					prefix = '+';
				}
			}
			suffix = '%';
			break;
		case 'asset':
			formattedValue = millify
				? value.toMillified(toFixed ?? 6)
				: value.prettyPrint(toTradePrecision);
			suffix = ` ${assetSymbol}`;
			break;
		case 'int':
			if (!isInvalid) {
				formattedValue = value.toString();
			}
			break;
	}

	switch (subType) {
		case '$Change':
			prefix = displayType == 'notional' ? '' : '$';
			// nick - won't this have a - anyways??
			// if (value.lt(BigNum.zero())) prefix = `-${prefix}`;
			if (value.gt(BigNum.zero())) prefix = `+${prefix}`;
			break;
		case '$':
			// if (value.lt(BigNum.zero())) prefix = `-${prefix}`;
			break;
		case 'assetChange':
			// nick - won't this have a - anyways??
			// if (value.lt(BigNum.zero())) prefix = `-${prefix}`;
			if (value.gt(BigNum.zero())) prefix = `+${prefix}`;
			break;
		case 'usdc':
			suffix = ' USDC';
			break;
	}

	if (toFixed && !millify && displayType !== 'millified') {
		if (formattedValue.includes(localeDelimiter)) {
			const splitDelim = formattedValue.split(localeDelimiter);
			const digitsAfterDecimal = splitDelim[1].length;
			if (digitsAfterDecimal < toFixed) {
				formattedValue += new Array(toFixed - digitsAfterDecimal)
					.fill('0')
					.join('');
			} else {
				formattedValue = [
					splitDelim[0],
					localeDelimiter,
					splitDelim[1].slice(0, toFixed),
				].join('');
			}
		} else {
			formattedValue =
				formattedValue +
				`${localeDelimiter}${new Array(toFixed).fill('0').join('')}`;
		}
	}

	if (!isNaN(trimZeroes)) {
		formattedValue = COMMON_UI_UTILS.trimTrailingZeros(
			formattedValue,
			trimZeroes
		);
	}

	return `${prefix}${formattedValue}${suffix}`;
};

const prefixIcon = (subtype: Props['subType']) => {
	switch (subtype) {
		case 'usdcIcon':
			return (
				<img
					alt=""
					width="22"
					height="22"
					src={`/assets/icons/usdc-logo-blue.svg`}
					className={`mr-2 mb-0.5`}
				/>
			);
		default:
			return null;
	}
};

const NumberDisplayV2 = ({
	value,
	displayType,
	subType,
	colourCoded,
	keepStaleValues = true,
	showSkeletonInstead = '',
	loading = false,
	isHiddenAccountValue = false,
	softenZeroValues,
	dataPuppetTag,
	...props
}: Props) => {
	const isInvalid = useMemo(() => numIsInvalid(value), [value]);

	const [staleValue, setStaleValue] = useState(undefined);
	const [showStaleValue, setShowStaleValue] = useState(false);

	const showAccountValues = useShowAccountValues();

	const shouldHide = isHiddenAccountValue && !showAccountValues;

	const currentFormattedValue = formatValue({
		value,
		displayType,
		subType,
		isInvalid,
		assetSymbol: props.assetSymbol,
		millify: props.millify,
		toTradePrecision: props.toTradePrecision,
		toFixed: props.toFixed,
		trimZeroes: props.trimZeroes,
	});

	useEffect(() => {
		if (!keepStaleValues) {
			setStaleValue(undefined);
			setShowStaleValue(false);
			return;
		}
		if (!isInvalid) {
			setStaleValue(value);
			setShowStaleValue(false);
		} else {
			setShowStaleValue(true);
		}
	}, [isInvalid, value?.toString()]);

	let colourCodedValue = null;

	(() => {
		if (colourCoded) {
			if (isInvalid) {
				if (!showStaleValue || numIsInvalid(staleValue)) return;
				colourCodedValue = staleValue;
				return;
			}
			colourCodedValue = value;
		}
	})();

	if (showSkeletonInstead && isInvalid && !staleValue) {
		return (
			<SkeletonValuePlaceholder
				className={showSkeletonInstead}
				loading={loading}
			/>
		);
	}

	const memoedClassName = useMemo(
		() =>
			twMerge(
				softenZeroValues && value.eqZero() && 'opacity-60',
				showStaleValue && `opacity-50`,
				props.className
			),
		[softenZeroValues, value, props.className]
	);

	const displayNode = (
		<>
			{prefixIcon(subType)}
			{props.textOverride
				? props.textOverride
				: showStaleValue
				? formatValue({
						value: staleValue,
						displayType,
						subType,
						isInvalid: numIsInvalid(staleValue),
						assetSymbol: props.assetSymbol,
						millify: props.millify,
				  })
				: currentFormattedValue}
		</>
	);

	return (
		<div className={memoedClassName} data-puppet-tag={dataPuppetTag}>
			{shouldHide ? (
				<>*****</>
			) : colourCoded ? (
				<ColourCodedValue
					value={colourCodedValue}
					className={'whitespace-nowrap'}
				>
					{displayNode}
				</ColourCodedValue>
			) : (
				<span className="whitespace-nowrap">{displayNode}</span>
			)}
		</div>
	);
};

export default React.memo(NumberDisplayV2, compareDriftProps);
