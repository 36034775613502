'use client';

import useAccountData from 'src/hooks/useAccountData';
import useSafePush from 'src/hooks/useSafePush';
import useSetCollateralModalParams from 'src/hooks/useSetCollateralModalParams';
import Text from '../Text/Text';
import { UserBalanceInfo } from './UserBalancesPortfolioTable';
import PopoverWrapper from '../PopoverWrapper';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';

const BalanceActionsPopup = (props: {
	targetElementId: string;
	onClose: () => void;
	market: UserBalanceInfo;
	isOnBorrow: boolean;
	setFloating: (node: HTMLElement) => void;
	floatingStyles: React.CSSProperties;
	getFloatingProps: () => Record<string, unknown>;
}) => {
	const currentAccount = useAccountData();
	const safePush = useSafePush();
	const setCollateralModalParams = useSetCollateralModalParams();

	const getSwapRoute = () => {
		// USDC
		if (props.market.spotMarketIndex === 0) {
			return 'SOL-USDC';
		} else {
			return `USDC-${props.market.symbol}`;
		}
	};

	const BALANCE_ACTIONS_OPTIONS = [
		{
			label: 'Deposit',
			action: () =>
				setCollateralModalParams({
					modalName: 'showDepositModal',
					collateralTypeIndex: props.market.spotMarketIndex,
					targetAccountKey: currentAccount?.userKey,
					poolId: CurrentSpotMarkets[props.market.spotMarketIndex].poolId,
				}),
		},
		{
			label: 'Swap',
			action: () => safePush(`/swap/${getSwapRoute()}`),
		},
		{
			label: 'Borrow',
			action: () =>
				setCollateralModalParams({
					modalName: 'showBorrowModal',
					collateralTypeIndex: props.market.spotMarketIndex,
					targetAccountKey: currentAccount?.userKey,
					poolId: CurrentSpotMarkets[props.market.spotMarketIndex].poolId,
				}),
		},
		{
			label: 'Withdraw',
			action: () =>
				setCollateralModalParams({
					modalName: 'showWithdrawModal',
					collateralTypeIndex: props.market.spotMarketIndex,
					targetAccountKey: currentAccount?.userKey,
					poolId: CurrentSpotMarkets[props.market.spotMarketIndex].poolId,
				}),
		},
		{
			label: 'Transfer',
			action: () =>
				setCollateralModalParams({
					modalName: 'showCollateralTransferModal',
					collateralTypeIndex: props.market.spotMarketIndex,
					targetAccountKey: currentAccount?.userKey,
					poolId: CurrentSpotMarkets[props.market.spotMarketIndex].poolId,
				}),
		},
	];

	const BALANCE_ACTIONS_OPTIONS_ON_BORROW = [
		{
			label: 'Repay',
			action: () =>
				setCollateralModalParams({
					modalName: 'showDepositModal',
					collateralTypeIndex: props.market.spotMarketIndex,
					targetAccountKey: currentAccount?.userKey,
					poolId: CurrentSpotMarkets[props.market.spotMarketIndex].poolId,
				}),
		},
		{
			label: 'Swap',
			action: () => safePush(`/swap/${getSwapRoute()}`),
		},
	];

	const optionsToRender = props.isOnBorrow
		? BALANCE_ACTIONS_OPTIONS_ON_BORROW
		: BALANCE_ACTIONS_OPTIONS;

	const renderOptions = () => {
		return optionsToRender.map((option, index) => {
			return (
				<Text.BODY1
					className="p-2 cursor-pointer text-text-emphasis hover:bg-container-bg-hover"
					key={index}
					onClick={() => {
						option.action();
						props.onClose();
					}}
				>
					{option.label}
				</Text.BODY1>
			);
		});
	};

	return (
		<PopoverWrapper
			ref={props.setFloating}
			style={props.floatingStyles}
			{...props.getFloatingProps()}
		>
			<div className="flex flex-col divide-y rounded bg-container-bg divide-container-border">
				{renderOptions()}
			</div>
		</PopoverWrapper>
	);
};

export default BalanceActionsPopup;
