'use client';

import { WalletName } from '@solana/wallet-adapter-base';
import usePostHogCapture from './posthog/usePostHogCapture';
import useDriftActions from './useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { notify } from 'src/utils/notifications';
import { DriftWalletName } from 'src/constants/wallets';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';
import usePrefferredWalletSetting from './usePrefferredWalletSetting';
import { dlog } from '../dev';
import useCurrentWalletConnectionState from './useCurrentWalletConnectionState';
import useWalletContext from 'src/hooks/useWalletContext';
import { useContext } from 'react';
import { Web3ModalProviderContext } from 'src/providers/web3ModalProvider';

const useHandleSelectWalletProvider = (isFromDepositToTrade = false) => {
	const actions = useDriftActions();
	const walletContext = useWalletContext();
	const { captureEvent } = usePostHogCapture();
	const { set: setDriftStore } = useDriftStore((s) => s);
	const [_preferredWalletUrl, setPreferredWalletUrl] =
		usePrefferredWalletSetting();
	const walletConnectionState = useCurrentWalletConnectionState();

	const web3ModalWalletProvider = useContext(Web3ModalProviderContext);

	const handleSelectAppKit = async () => {
		await actions.connectWithCustomWallet(
			DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME,
			web3ModalWalletProvider.walletProvider
		);
	};

	const handleSelectMetamask = async () => {
		try {
			await actions.connectWithCustomWallet(
				DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME
			);

			captureEvent(`selected_metamask_wallet`, {
				success: true,
			});
		} catch (err) {
			if (err.data?.method === 'wallet_getSnaps') {
				// This probably means a different wallet extension is overwriting window.ethereum
				// Or MetaMask is VERY out of date
				notify({
					type: 'error',
					message: 'There was an error connecting to MetaMask',
					description: 'Drift was not able to connect to MetaMask snaps.',
					showUntilCancelled: true,
					action: {
						type: 'custom',
						content: (
							<a
								href="https://docs.drift.trade/getting-started/wallet-setup/metamask-setup#metamask-setup"
								target="_black"
								rel="noreferrer"
							>
								Why did this happen?
							</a>
						),
					},
				});
			} else {
				notify({
					type: 'error',
					message: 'There was an error connecting to MetaMask',
					// description: "",
				});
			}

			captureEvent(`selected_metamask_wallet`, {
				success: false,
			});
		}
	};

	const handleSelectProvider = async (
		selectedProviderName: DriftWalletName
	) => {
		dlog(`wallet_debugging`, `handleSelectProvider: ${selectedProviderName}`);
		walletConnectionState.instance.progress('Connecting');

		setPreferredWalletUrl(selectedProviderName);
		if (
			selectedProviderName ===
			DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME
		) {
			await handleSelectAppKit();
		} else if (
			selectedProviderName ===
			DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME
		) {
			await handleSelectMetamask();
		} else {
			const provider = walletContext.wallets.find(
				(wallet) => wallet.adapter.name === selectedProviderName
			);

			try {
				setDriftStore((state) => {
					// @ts-expect-error
					state.wallet.current = provider;
					state.wallet.isMagicAuth = false;
					state.wallet.isAppKit = false;
				});

				walletContext.select(selectedProviderName as WalletName);
				await provider.adapter.connect();

				if (isFromDepositToTrade) {
					captureEvent(`selected_wallet`, {
						provider: provider.adapter.name,
						from_deposit_to_trade_flow: true,
					});
				}
			} catch (e) {
				console.error(e);
				walletConnectionState.instance.progress('NotConnected');

				notify({
					type: 'error',
					message: 'There was an error connecting to the selected wallet',
					description:
						"You may need to install the wallet provider's extension",
				});

				// captureEvent(`selected_wallet`, {
				// 	provider: provider.adapter.name,
				// 	success: false,
				// });
			}
		}
	};

	const handleDeselectProvider = async () => {
		walletConnectionState.instance.disconnect();
		walletContext.disconnect();
	};

	return {
		handleSelectProvider,
		handleDeselectProvider,
	};
};

export default useHandleSelectWalletProvider;
