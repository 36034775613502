/**
 * There is a "millify" npm library but it is is multiple kilobytes and opens space for security vulnerabilities. Easier to just roll our own as it's not complicated.
 */

interface MillifyOptions {
	precision?: number;
	notation?: 'scientific' | 'financial';
	trimEndingZeroes?: boolean;
}

export default function millify(
	value: number,
	options?: MillifyOptions
): string {
	const precision = options?.precision ?? 6;
	const notation = options?.notation ?? 'financial';
	const trimEndingZeroes = options?.trimEndingZeroes ?? false;

	if (isNaN(value)) return '0';

	const isNegative = value < 0;
	const absoluteValue = Math.abs(value);

	// Units for financial and scientific notation
	const financialUnits = ['', 'K', 'M', 'B', 'T', 'Q'];
	const scientificUnits = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

	const units = notation === 'financial' ? financialUnits : scientificUnits;

	// If value is less than 1000, return as is
	if (absoluteValue < 1000) {
		const formattedValue = absoluteValue.toPrecision(precision);
		const trimmedValue = trimEndingZeroes
			? parseFloat(formattedValue).toString()
			: formattedValue;
		return `${isNegative ? '-' : ''}${trimmedValue}`;
	}

	// Calculate the unit index (how many times to divide by 1000)
	const unitIndex = Math.min(
		Math.floor(Math.log10(absoluteValue) / 3),
		units.length - 1
	);

	// Calculate the scaled value
	const scaledValue = absoluteValue / Math.pow(1000, unitIndex);
	const formattedValue = scaledValue.toPrecision(precision);
	const trimmedValue = trimEndingZeroes
		? parseFloat(formattedValue).toString()
		: formattedValue;

	return `${isNegative ? '-' : ''}${trimmedValue}${units[unitIndex]}`;
}
