'use client';

import { useEffect, useRef, useState } from 'react';
import {
	LabelAndInputWithAction,
	MarginAndLeverageSelector,
	RouteDetails,
	SwapDetails,
	SwapPrice,
} from './SwapForm';
import { BigNum, SpotBalanceType, SpotMarketConfig } from '@drift-labs/sdk';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import UI_UTILS from 'src/utils/uiUtils';
import {
	useJupiterTokenPairData,
	useTokenPairData,
} from 'src/hooks/swap/useTokenPairData';
import SlippageTolerance from '../TradeForm/SlippageTolerance';
import Button from '../Button';
import InfoMessage from '../TradeForm/InfoMessage';
import useSwapFormEngine from 'src/hooks/swap/useSwapFormEngine';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import { ENUM_UTILS } from '@drift/common';
import Utility from '../Inputs/Utility';
import { twMerge } from 'tailwind-merge';
import InputAction from '../Inputs/InputAction';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { COULD_NOT_FIND_ANY_ROUTE_ERROR } from './SwapQuoteErrorDisplay';

export const SWAP_FROM_MARKET_SELECTOR_ID =
	'closeBorrow_swapFromMarketSelector';
export const SWAP_TO_MARKET_SELECTOR_ID = 'closeBorrow_swapToMarketSelector';
const MAX_HEIGHT = 350;

const CloseBorrowSwapForm = ({
	swapToSpotMarketIndex,
	onClose,
	fullHeight,
}: {
	swapToSpotMarketIndex: number;
	fullHeight?: boolean;
	onClose: () => void;
}) => {
	const swapToMarket = OrderedSpotMarkets[swapToSpotMarketIndex];
	const currentUserKey = useDriftAccountsStore((s) => s.currentUserKey);
	const spotBalances = useAccountSpotBalances(currentUserKey ?? '', null);
	const [swapFromMarket, setSwapFromMarket] = useState<SpotMarketConfig>(
		OrderedSpotMarkets[0]
	);
	const [eligibleSwapFromMarkets, setEligibleSwapFromMarkets] = useState<
		SpotMarketConfig[]
	>([]);
	const [isMaxRepay, setIsMaxRepay] = useState(true);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const swapFromMarketSpotBalance = spotBalances?.find(
		(bal) => bal.asset.marketIndex === swapFromMarket.marketIndex
	);
	const swapToMarketSpotBalance = spotBalances?.find(
		(bal) =>
			bal.asset.marketIndex === swapToSpotMarketIndex &&
			ENUM_UTILS.match(bal.balanceType, SpotBalanceType.BORROW)
	);

	const { fromTokenPrice, toTokenPrice } = useTokenPairData(
		swapFromMarket?.symbol,
		swapToMarket?.symbol
	);

	const tokenPairPrice = useJupiterTokenPairData(swapFromMarket, swapToMarket);

	const {
		enableVersionedTx,
		enableVersionedTransaction,
		setSwapMode,
		swapFromAmount,
		setSwapFromAmount,
		swapToAmount,
		setSwapToAmount,
		inputNumericAmount,
		quoteDetails,
		swapPrice,
		handleSwap,
		isLoading,
		isButtonDisabled,
		maxLeverageAllowed,
		maxSwapSize,
		currentLeverage,
		isInsufficientBalance,
		calcOtherAmountAtMaxSlippage,
		swapMode,
		isAboveMaxSwapSizeWithoutLeverage,
	} = useSwapFormEngine(swapFromMarket, swapToMarket, 'ExactOut');

	const isExactIn = swapMode === 'ExactIn';
	const containerRef = useRef(null);
	const containerOverflowed = fullHeight
		? false
		: containerRef?.current?.scrollHeight > MAX_HEIGHT; // ensure that max height of MAX_HEIGHT corresponds with CSS below

	const willIncurBorrow =
		+swapFromAmount > swapFromMarketSpotBalance?.balance.toNum() || 0;

	const bufferedCloseBorrowAmount = UI_UTILS.getCloseBorrowAmountWithBuffer(
		driftClient,
		swapToMarket.marketIndex,
		swapToMarketSpotBalance?.balance ?? BigNum.zero()
	).print();

	useEffect(() => {
		const sortedMarketsWithDeposits = spotBalances
			.filter((bal) =>
				ENUM_UTILS.match(bal.balanceType, SpotBalanceType.DEPOSIT)
			)
			.sort((a, b) => b.balance.abs().toNum() - a.balance.abs().toNum());

		const eligibleAssets = sortedMarketsWithDeposits
			.map((market) => market.asset)
			.filter((asset) => asset.marketIndex !== swapToSpotMarketIndex);

		setSwapFromMarket(eligibleAssets[0] ?? OrderedSpotMarkets[0]);
		setEligibleSwapFromMarkets(eligibleAssets);
	}, [spotBalances?.length]);

	useEffect(() => {
		if (
			isMaxRepay &&
			swapToMarketSpotBalance &&
			!swapToMarketSpotBalance.balance.eqZero() &&
			driftClientIsReady
		) {
			const closeBorrowBufferedAmount = UI_UTILS.getCloseBorrowAmountWithBuffer(
				driftClient,
				swapToMarket.marketIndex,
				swapToMarketSpotBalance.balance
			);

			setSwapToAmount(closeBorrowBufferedAmount.printShort());
		}
	}, [
		swapToMarketSpotBalance?.balance?.toString(),
		isMaxRepay,
		driftClient,
		driftClientIsReady,
		swapToMarket.marketIndex,
	]);

	const handleSwapFromAmountUserChange = (val: string) => {
		setSwapMode('ExactIn');
		const truncatedInput = UI_UTILS.truncateInputToPrecision(
			val,
			swapFromMarket.precisionExp
		);
		setSwapFromAmount(truncatedInput);
		setIsMaxRepay(false);
	};

	const handleSwapToAmountUserChange = (val: string, isMax = false) => {
		setSwapMode('ExactOut');
		const truncatedInput = UI_UTILS.truncateInputToPrecision(
			val,
			swapToMarket.precisionExp
		);
		setSwapToAmount(truncatedInput);
		setIsMaxRepay(isMax);
	};

	// Try to estimate a repay borrow amount for ExactIn mode as a fallback for when ExactOut fails to find a route
	const handleTryExactIn = () => {
		if (swapToAmount && tokenPairPrice) {
			const estimatedSwapFromAmount =
				(Number(swapToAmount) / tokenPairPrice) * 1.001;

			handleSwapFromAmountUserChange(`${estimatedSwapFromAmount}`);
		}
	};

	useEffect(() => {
		if (
			quoteDetails?.errorCode === COULD_NOT_FIND_ANY_ROUTE_ERROR &&
			swapMode === 'ExactOut'
		) {
			handleTryExactIn();
		}
	}, [quoteDetails, swapMode]);

	return (
		<div className="flex flex-col w-full h-full gap-3">
			<div
				className={twMerge(
					'flex flex-col w-full h-full gap-3',
					!fullHeight && 'thin-scroll overflow-scroll',
					containerOverflowed && 'pr-2'
				)}
				style={fullHeight ? null : { maxHeight: MAX_HEIGHT }}
				ref={containerRef}
			>
				<LabelAndInputWithAction
					id={SWAP_FROM_MARKET_SELECTOR_ID}
					label="Pay"
					value={swapFromAmount}
					onValueChange={handleSwapFromAmountUserChange}
					actionComponent={
						<InputAction
							onClick={() =>
								handleSwapFromAmountUserChange(maxSwapSize.print())
							}
						>
							Max: {maxSwapSize.toFixed(2)} {swapFromMarket.symbol}
						</InputAction>
					}
					selectedMarket={swapFromMarket}
					onMarketChange={setSwapFromMarket}
					tokenUsdPrice={fromTokenPrice}
					options={eligibleSwapFromMarkets}
				/>

				<LabelAndInputWithAction
					id={SWAP_TO_MARKET_SELECTOR_ID}
					label="Receive"
					value={swapToAmount}
					onValueChange={handleSwapToAmountUserChange}
					selectedMarket={swapToMarket}
					onMarketChange={() => {}}
					tokenUsdPrice={toTokenPrice}
					options={[swapToMarket]}
					actionComponent={
						<InputAction
							onClick={() =>
								handleSwapToAmountUserChange(bufferedCloseBorrowAmount, true)
							}
						>
							To Close: {bufferedCloseBorrowAmount} {swapToMarket.symbol}
						</InputAction>
					}
				/>

				<SwapPrice
					inAmount={BigNum.fromPrint(
						swapFromAmount,
						swapFromMarket.precisionExp
					)}
					outAmount={BigNum.fromPrint(swapToAmount, swapToMarket.precisionExp)}
					inMarket={swapFromMarket}
					outMarket={swapToMarket}
					isLoadingRoute={isLoading}
					swapPrice={swapPrice}
				/>

				<MarginAndLeverageSelector
					swapFromNumericAmount={inputNumericAmount}
					maxSwapSize={maxSwapSize}
					setSwapAmount={handleSwapFromAmountUserChange}
					currentLeverage={currentLeverage}
					maxLeverageAllowed={maxLeverageAllowed}
				/>

				<div className="mt-6 sm:mt-0">
					<SlippageTolerance />
				</div>

				<RouteDetails
					loading={isLoading}
					routePlan={quoteDetails?.routePlan ?? []}
				/>

				<Utility.VERTDIVIDER />

				<SwapDetails
					amount={calcOtherAmountAtMaxSlippage()}
					priceImpact={+quoteDetails?.priceImpactPct || 0}
					isExactIn={isExactIn}
				/>
			</div>

			{isAboveMaxSwapSizeWithoutLeverage && (
				<InfoMessage
					type="warn"
					message="You have exceed the max swap size without leverage. If you would like to increase the max swap size, you can enable leverage in the checkbox above."
				/>
			)}

			{!enableVersionedTx && (
				<InfoMessage
					type="warn"
					message={
						<div className="flex flex-col gap-1 text-text-default">
							<div>You need to enable Versioned Transactions to use Swaps.</div>
							<div>
								<Button.Secondary
									size="SMALL"
									onClick={enableVersionedTransaction}
								>
									Enable
								</Button.Secondary>
							</div>
						</div>
					}
				/>
			)}

			{!!willIncurBorrow && !isAboveMaxSwapSizeWithoutLeverage && (
				<InfoMessage
					type="warn"
					message={`Swapping at this size will incur a borrow on ${swapFromMarket.symbol}`}
				/>
			)}

			<Button.Primary
				size="LARGE"
				className="my-1"
				onClick={() => {
					handleSwap().then(() => {
						onClose();
					});
				}}
				disabled={isButtonDisabled}
			>
				{!quoteDetails
					? 'No routes found'
					: isInsufficientBalance
					? 'Insufficient Balance'
					: isLoading
					? 'Loading'
					: !enableVersionedTx
					? 'Enable Versioned Transactions'
					: 'Swap'}
			</Button.Primary>
		</div>
	);
};

export default CloseBorrowSwapForm;
