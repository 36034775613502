import { useQuery } from '@tanstack/react-query';
import { PnLTimeSeriesDataPoint } from 'src/@types/performance';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import { HistoricalTokenPriceDuration } from 'src/utils/graph';
import { ONE_DAY_MS } from '@drift/common';

export function useRateHistory({
	marketIndex,
	type,
	dateRange,
}: {
	marketIndex: number;
	type: 'borrow' | 'deposit';
	dateRange: HistoricalTokenPriceDuration;
}) {
	return useQuery<{ averageRate: number; chartData: PnLTimeSeriesDataPoint[] }>(
		{
			queryKey: ['rateHistory', marketIndex, type, dateRange],
			staleTime: ONE_DAY_MS,
			queryFn: async () => {
				const result = await ExchangeHistoryClient.getRateHistory(
					marketIndex,
					type,
					parseInt(dateRange)
				);

				console.log('getRateHistory result', result);

				if (result.success) {
					let sum = 0;
					const points: PnLTimeSeriesDataPoint[] = result.body.data.map(
						(point) => {
							const totalPnl = Number(point[1]) * 100;
							sum += totalPnl;
							return {
								date: new Date(point[0] * 1000),
								totalPnl,
							};
						}
					);

					return {
						averageRate: points?.length > 0 ? sum / points.length : 0,
						chartData: points,
					};
				}
				return {
					averageRate: 0,
					chartData: [],
				};
			},
		}
	);
}
