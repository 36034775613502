'use client';

import React, { useEffect, useState, memo } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../Text/Text';
import Env, {
	CurrentSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import useAccountExists from 'src/hooks/useAccountExists';
import Button from '../Button';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import { CollateralInput } from '../Inputs/CollateralInput';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import { NEW_ACCOUNT_BASE_COST } from 'src/constants/constants';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import useReferralRewardStructure from 'src/hooks/useReferralRewardStructure';
import Utility from '../Inputs/Utility';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import NewAccountCreationCost from '../Utils/NewAccountCreationCost';
import useNumberOfAccountsCreatedLast24h from 'src/hooks/utils/useNumberOfAccountsCreatedLast24h';
import { MIN_LEFTOVER_SOL, ONE_DAY_MS } from '@drift/common';
import InfoMessage from '../TradeForm/InfoMessage';
import { useMaxSubaccountsReached } from 'src/hooks/useMaxSubaccountsReached';
import { MaxSubaccountsWarning } from '../Utils/MaxSubaccountsWarning';
import useSafePush from 'src/hooks/useSafePush';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';

const SOL_BANK = CurrentSpotMarkets.find((bank) => bank.symbol === 'SOL');
const USDC_BANK = CurrentSpotMarkets.find((bank) => bank.symbol === 'USDC');
const SHOW_FAUCET_BUTTON = false;

function DepositToDriftPanel() {
	const actions = useDriftActions();
	const { captureEvent } = usePostHogCapture();
	const referrer = useDriftStore((s) => s.referrerParam);
	const { refereeDiscountPercent } = useReferralRewardStructure();
	const accountExists = useAccountExists();
	const connected = useWalletIsConnected();
	const [lastRouteBeforeConnect, solBalance, isMagicAuth] = useDriftStore(
		(s) => [
			s.wallet.lastRouteBeforeConnect,
			s.wallet.currentSolBalance,
			s.wallet.isMagicAuth,
		]
	);
	const safePush = useSafePush();
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const [showDepositSolBox, setShowDepositSolBox] = useState(
		solBalance.lt(NEW_ACCOUNT_BASE_COST)
	);
	const [showDepositAssets, setShowDepositAssets] = useState(
		solBalance.gte(NEW_ACCOUNT_BASE_COST)
	);
	const [submitting, setSubmitting] = useState(false);
	const [userHasAgreedToFee, setUserHasAgreedToFee] = useState(false);

	const {
		numberOfAccountsCreatedLast24h,
		lastCreatedAccountTimestamp,
		incrementNumberOfAccountsCreated,
	} = useNumberOfAccountsCreatedLast24h();

	const maxSubaccountsReached = useMaxSubaccountsReached();

	const hitMaxNumberOfAccountsIn24h =
		!accountExists &&
		Date.now() - lastCreatedAccountTimestamp < ONE_DAY_MS &&
		numberOfAccountsCreatedLast24h >= Env.maxNumberOfAccountsPer24h;

	// Deposit amount state
	const [depositAmount, setDepositAmount] = useState('');
	const [selectedBank, setSelectedBank] = useState(USDC_BANK);
	const [currentBalance] = useCurrentWalletCollateralBalance(selectedBank);
	const isInitialSolDeposit = selectedBank.symbol === 'SOL';
	const [transferableBalance] = useTransferableWalletCollateralBalance(
		selectedBank,
		isInitialSolDeposit
	);
	const depositAmountBigNum = BigNum.fromPrint(
		depositAmount ? depositAmount : '0',
		selectedBank.precisionExp
	);
	const exceededMax = depositAmountBigNum.gt(transferableBalance);

	const { totalCost: totalAccountCreationCost } = useAccountCreationCost();

	const solRequiredToDeposit = totalAccountCreationCost.add(MIN_LEFTOVER_SOL);
	const hasEnoughSolInWallet = solBalance.gte(solRequiredToDeposit);

	const canDeposit =
		showDepositAssets &&
		!submitting &&
		depositAmountBigNum.gtZero() &&
		userHasAgreedToFee &&
		hasEnoughSolInWallet &&
		!hitMaxNumberOfAccountsIn24h;

	if (!connected) {
		showConnectWalletOptions(true);
	}

	if (accountExists) {
		safePush(isMagicAuth ? '/onboarding/complete' : '/');
	}

	const handleAirdrop = async (bank: SpotMarketConfig) => {
		await actions.tryFaucetAirdrop(bank);
	};

	const handleChangeAssetType = (bank: SpotMarketConfig) => {
		setDepositAmount('');
		setSelectedBank(bank);
	};

	const handleSkipDeposit = () => {
		captureEvent('onboarding_skip_deposit');
		safePush(lastRouteBeforeConnect || '/');
	};

	const handleConfirmDeposit = async () => {
		try {
			setSubmitting(true);

			const accountCreatedSuccess =
				await actions.initializeAndDepositCollateralForAccount({
					amount: depositAmountBigNum,
					spotMarketConfig: selectedBank,
					poolId: selectedBank.poolId,
				});

			if (accountCreatedSuccess) {
				incrementNumberOfAccountsCreated();
				if (isMagicAuth) {
					safePush('/onboarding/complete');
				} else {
					safePush(lastRouteBeforeConnect || '/');
				}
			}
		} catch (_err) {
			// this code block doesn't run even when it fails and idk why???
		}

		captureEvent('submitted_deposit', {
			spot_market_symbol: selectedBank.symbol,
			depositAmount: depositAmountBigNum.toNum(),
			newAccount: true,
		});

		setSubmitting(false);
	};

	useEffect(() => {
		if (exceededMax) {
			setDepositAmount(transferableBalance.print());
		}
	}, [exceededMax]);

	return (
		<div className="w-full h-full">
			<Text.H1 className="text-text-emphasis">Deposit to Drift</Text.H1>
			<div className="p-4 pt-8 mt-6 mb-4 border bg-container-bg border-container-border">
				<CollateralInput
					selectedMarket={selectedBank}
					maxAmount={transferableBalance}
					currentBalance={currentBalance}
					onChangeMarket={handleChangeAssetType}
					label="Asset and amount to deposit"
					value={depositAmount}
					onChangeValue={(value) => setDepositAmount(value)}
					amountLabel={'Wallet balance'}
					source={{ type: 'wallet' }}
				/>
			</div>
			{referrer && (
				<div className="mt-2 leading-4">
					<Text.BODY1 className="text-text-secondary">
						Referred by: <span className="text-text-emphasis">{referrer}</span>
					</Text.BODY1>
					<Utility.VERTSPACERM />
					<Text.BODY1 className="text-text-secondary">
						You&apos;ll receive {refereeDiscountPercent}% off trading fees after
						creating your account.
					</Text.BODY1>
				</div>
			)}

			{hitMaxNumberOfAccountsIn24h ? (
				<>
					<Utility.VERTSPACERXS />
					<InfoMessage
						type="warn"
						messageTitle=""
						message={
							<>
								You can only create 2 new Drift accounts every 24 hours. Please
								connect a different wallet with an existing account or try again
								later.
							</>
						}
					/>
				</>
			) : !hasEnoughSolInWallet ? (
				<InfoMessage
					type="warn"
					messageTitle={
						<div className="flex items-center space-x-1">
							<span>Not enough SOL in your wallet</span>
						</div>
					}
					message={
						<>
							{`You need to have ${solRequiredToDeposit.prettyPrint(
								true
							)} SOL in your wallet to cover account creation and transaction fees.`}
						</>
					}
				/>
			) : (
				<>
					<div className="mt-4">
						{maxSubaccountsReached ? (
							<MaxSubaccountsWarning />
						) : (
							<NewAccountCreationCost
								checkboxUpdated={(selection) =>
									setUserHasAgreedToFee(selection)
								}
								forceAgreement={true}
							/>
						)}
					</div>
				</>
			)}

			{/* ******** BUTTONS SECTION ******** */}
			<div className="my-8">
				<Button.Secondary
					highlight
					className="w-full"
					size={'MEDIUM'}
					disabled={!canDeposit}
					onClick={handleConfirmDeposit}
				>
					{submitting ? 'Confiming Deposit...' : 'Confirm Deposit'}
				</Button.Secondary>
			</div>
			<div className="my-8 text-center">
				<Button.Ghost
					highlight
					className="w-full mb-4 text-text-secondary"
					size={'MEDIUM'}
					onClick={handleSkipDeposit}
				>
					Deposit Later
				</Button.Ghost>
			</div>
			{SHOW_FAUCET_BUTTON && (
				<div className="flex flex-row my-8">
					<Button.Secondary
						className="w-full mr-2"
						size={'MEDIUM'}
						onClick={() => {
							handleAirdrop(SOL_BANK);
						}}
					>
						Gimme SOL
					</Button.Secondary>
					<Button.Secondary
						className="w-full"
						size={'MEDIUM'}
						onClick={() => {
							handleAirdrop(USDC_BANK);
						}}
					>
						Gimme USDC
					</Button.Secondary>
					<Button.Secondary
						className="w-full ml-2"
						size={'MEDIUM'}
						onClick={() => {
							if (showDepositSolBox) {
								setShowDepositSolBox(false);
								setTimeout(() => {
									setShowDepositAssets(true);
								}, 500);
							} else {
								setShowDepositSolBox(true);
								setShowDepositAssets(false);
							}
						}}
					>
						Toggle
					</Button.Secondary>
				</div>
			)}
		</div>
	);
}

export default memo(DepositToDriftPanel);
