'use client';

import {
	BulkAccountLoader,
	InsuranceFundStakeAccountSubscriber,
	PollingInsuranceFundStakeAccountSubscriber,
	PublicKey,
	WebSocketInsuranceFundStakeAccountSubscriber,
	getInsuranceFundStakeAccountPublicKey,
	getInsuranceFundVaultPublicKey,
	PollingTokenAccountSubscriber,
	TokenAccountSubscriber,
	//WebSocketTokenAccountSubscriber,
} from '@drift-labs/sdk';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	OrderedSpotMarkets,
	isDev,
} from '../environmentVariables/EnvironmentVariables';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { dlog } from 'src/dev';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import useCurrentAuthority from 'src/hooks/useCurrentAuthority';

const INITIAL_CONTEXT_VALUE: {
	ifStakeVaultSubscribers: TokenAccountSubscriber[];
	userIfStakeSubscribers: InsuranceFundStakeAccountSubscriber[];
} = {
	ifStakeVaultSubscribers: [],
	userIfStakeSubscribers: [],
};

export const IfStakeSubscriberContext = React.createContext<{
	ifStakeVaultSubscribers: TokenAccountSubscriber[];
	userIfStakeSubscribers: InsuranceFundStakeAccountSubscriber[];
}>(INITIAL_CONTEXT_VALUE);

const IfStakeSubscriberProvider = (props: PropsWithChildren<any>) => {
	const connection = useDriftStore((s) => s.connection.current);
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const currentAuthority = useCurrentAuthority();
	const connected = useWalletIsConnected();

	const [contextValue, setContextValue] = useState(INITIAL_CONTEXT_VALUE);
	const subscribersRef = useRef(contextValue);

	const createUserSubscriber = async (
		program: ConstructorParameters<
			typeof PollingInsuranceFundStakeAccountSubscriber
		>[0],
		publicKey: PublicKey,
		usePolling: boolean,
		bulkAccountLoader?: BulkAccountLoader
	) => {
		const subscriber = usePolling
			? new PollingInsuranceFundStakeAccountSubscriber(
					program,
					publicKey,
					bulkAccountLoader
			  )
			: new WebSocketInsuranceFundStakeAccountSubscriber(
					program,
					publicKey,
					3000, //todo - confirm this
					DEFAULT_COMMITMENT_LEVEL
			  );

		try {
			await subscriber.subscribe();

			if (usePolling) {
				// This handles a race-condition where the value isn't fetched on the initial subscription. Not necessary for websocket subscribers.
				await (
					subscriber as PollingInsuranceFundStakeAccountSubscriber
				).fetchIfUnloaded();
			}
		} catch (e) {
			dlog('Error subscribing to User IF account: ', e);
		}

		return subscriber;
	};

	const createVaultSubscriber = async (
		publicKey: PublicKey,
		bulkAccountLoader?: BulkAccountLoader
	) => {
		const subscriber = new PollingTokenAccountSubscriber(
			publicKey,
			bulkAccountLoader
		);

		try {
			await subscriber.subscribe();
		} catch (e) {
			dlog('Error subscribing to Vault IF account: ', e);
		}

		return subscriber;
	};

	const createVaultSubscribers = async (): Promise<
		TokenAccountSubscriber[]
	> => {
		// create new bulk account loader to lower refresh frequency to 1 minute
		const bulkAccountLoader = new BulkAccountLoader(
			connection,
			DEFAULT_COMMITMENT_LEVEL,
			60_000
		);

		const subs = await Promise.all(
			OrderedSpotMarkets.map(async (spotMarket) => {
				try {
					const pubKey = await getInsuranceFundVaultPublicKey(
						driftClient.program.programId,
						spotMarket.marketIndex
					);

					return await createVaultSubscriber(pubKey, bulkAccountLoader);
				} catch (err) {
					if (isDev()) {
						console.error(err);
					}
				}
			})
		);

		return subs;
	};

	const createUserSubscribers = async (
		usePolling: boolean,
		userAuthority: PublicKey
	): Promise<InsuranceFundStakeAccountSubscriber[]> => {
		// create new bulk account loader to lower refresh frequency to 30s  - but we should be using websockets anyways
		const bulkAccountLoader = new BulkAccountLoader(
			connection,
			DEFAULT_COMMITMENT_LEVEL,
			30_000
		);

		const subs = await Promise.all(
			OrderedSpotMarkets.map(async (spotMarket) => {
				try {
					const pubKey = getInsuranceFundStakeAccountPublicKey(
						driftClient.program.programId,
						userAuthority,
						spotMarket.marketIndex
					);

					return await createUserSubscriber(
						driftClient.program,
						pubKey,
						usePolling,
						bulkAccountLoader
					);
				} catch (err) {
					console.error(err);
				}
			})
		);

		return subs;
	};

	// set up subscribers
	useEffect(() => {
		if (!connection || !driftClient || !driftClient.isSubscribed) {
			setContextValue(INITIAL_CONTEXT_VALUE);
			subscribersRef.current = INITIAL_CONTEXT_VALUE;
			return;
		}

		// to do - revisit this?
		const usePolling = true;

		const createAndSubscribe = async () => {
			const ifStakeVaultSubscribers = await createVaultSubscribers();

			let userIfStakeSubscribers: InsuranceFundStakeAccountSubscriber[] = [];
			if (connected && currentAuthority) {
				userIfStakeSubscribers = await createUserSubscribers(
					usePolling,
					currentAuthority
				);
			}

			setContextValue({
				ifStakeVaultSubscribers,
				userIfStakeSubscribers,
			});
			subscribersRef.current = {
				ifStakeVaultSubscribers,
				userIfStakeSubscribers,
			};
		};

		const teardown = () => {
			subscribersRef.current.ifStakeVaultSubscribers?.forEach((sub) => {
				try {
					if (sub) {
						sub.unsubscribe();
					}
				} catch (err) {
					console.error(err);
					dlog(
						'if_stake_unsubscribe - ifStakeVaultSubscribers',
						subscribersRef.current.ifStakeVaultSubscribers
					);
					dlog('if_stake_unsubscribe - current subscriber', sub);
				}
			});
			subscribersRef.current.userIfStakeSubscribers?.forEach((sub) => {
				if (sub) {
					sub.unsubscribe();
				}
			});
			setContextValue(INITIAL_CONTEXT_VALUE);
		};

		createAndSubscribe();

		return teardown;
	}, [connection, driftClient, connected, currentAuthority]);

	return (
		<IfStakeSubscriberContext value={contextValue}>
			{props.children}
		</IfStakeSubscriberContext>
	);
};

export default IfStakeSubscriberProvider;
