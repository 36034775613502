'use client';

import { useRouter } from 'next/navigation';
import { memo, useEffect, useMemo, useState } from 'react';
import useAccountExists from 'src/hooks/useAccountExists';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import Button from '../Button';
import Text from '../Text/Text';
import useSafePush from 'src/hooks/useSafePush';
import UI_UTILS from 'src/utils/uiUtils';
import { Open, Copy } from '@drift-labs/icons';

function YourWalletPanel({
	handleNext,
	isMetamask,
	isMagicAuth,
}: {
	handleNext: () => void;
	isMetamask?: boolean;
	isMagicAuth?: boolean;
}) {
	const router = useRouter();
	const safePush = useSafePush();
	const accountExists = useAccountExists();
	const wallet = useCurrentWalletAdapter();
	const connected = useWalletIsConnected();
	const isNativeSolWallet = useDriftStore(
		(s) => !s.wallet.isMagicAuth && !s.wallet.isMetamask
	);
	const [copied, setCopied] = useState(false);

	if (!connected) {
		router.replace('/');
	}

	// Redirect if user lands on this route with a normal wallet
	if (isNativeSolWallet) {
		if (!accountExists) {
			safePush('/');
		} else {
			safePush('/onboarding/fund-your-account');
		}
	} else if (accountExists) {
		safePush('/onboarding/complete');
	}

	const copyWalletAddress = () => {
		if (!copied) {
			UI_UTILS.copyToClipboard(wallet?.publicKey?.toString());
			setCopied(true);
		}
	};

	useEffect(() => {
		let resetCopiedTimeout: ReturnType<typeof setTimeout>;
		if (copied) {
			resetCopiedTimeout = setTimeout(() => {
				setCopied(false);
				resetCopiedTimeout = null;
			}, 3000);
		}
		return () => clearTimeout(resetCopiedTimeout);
	}, [copied]);

	const walletString = useMemo(() => {
		return wallet?.publicKey?.toString();
	}, [wallet?.publicKey]);

	return (
		<div className="w-full h-full">
			<Text.H1 className="text-text-emphasis">
				{isMetamask
					? 'MetaMask is ready to use!'
					: isMagicAuth
					? 'Your Magic Wallet'
					: null}
			</Text.H1>
			<div className="my-4 mb-8">
				<Text.BODY3 className="leading-4">
					{isMetamask ? (
						<>
							Metamask automatically created a Solana wallet for you that&apos;s
							compatible with Drift. You&apos;ll be able to interact with this
							wallet via Drift&apos;s UI and sign transactions with Metamask.
						</>
					) : isMagicAuth ? (
						<>
							Drift doesn&apos;t store your private keys and cannot recover your
							wallet on your behalf.{' '}
							<a
								href="https://docs.drift.trade/getting-started/passwordless-login"
								target="_blank"
								rel="noreferrer"
							>
								Learn More
							</a>
						</>
					) : null}
				</Text.BODY3>
			</div>
			<div className="my-4 mt-8">
				<Text.BODY2 className="text-text-emphasis text-bold">
					{isMetamask ? (
						<>Your Solana Wallet Address</>
					) : (
						<>Your Wallet Address</>
					)}
				</Text.BODY2>
				<div className="w-full p-4 my-2 mb-3 break-all bg-input-bg">
					<Text.BODY1>{walletString}</Text.BODY1>
				</div>
				<Button.Ghost
					size="MEDIUM"
					onClick={copyWalletAddress}
					className="flex items-center border rounded border-container-border"
				>
					<Copy size={16} className="mr-1" /> {copied ? 'Copied!' : 'Copy'}
				</Button.Ghost>
			</div>
			{isMagicAuth && (
				<div className="my-4 mt-8">
					<Text.BODY1 className="text-text-emphasis text-bold">
						Your Private Key
					</Text.BODY1>
					<div className="my-2 mb-3 leading-4">
						<Text.BODY3>
							The private key grants full access to your wallet. Safely store it
							and don&apos;t disclose it to others.
						</Text.BODY3>
					</div>
					<a
						href="https://reveal.magic.link/solana/drift"
						target="_blank"
						rel="noreferrer"
					>
						<Button.Ghost
							size="MEDIUM"
							className="flex items-center border rounded border-container-border"
						>
							<Open size={14} className="mr-2" /> <span>Get Private Key</span>
						</Button.Ghost>
					</a>
				</div>
			)}
			<div className="my-4 mt-10">
				<Button.Secondary
					highlight
					size={'MEDIUM'}
					className={'w-full'}
					onClick={handleNext}
				>
					<Text.BODY2>
						{isMetamask ? <>Next: Fund Your Solana Wallet</> : <>Next</>}
					</Text.BODY2>
				</Button.Secondary>
			</div>
		</div>
	);
}

export default memo(YourWalletPanel);
