'use client';

import { useRef } from 'react';
import { SpotBalanceType } from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import useDriftAccountStore, {
	AccountSpotBalance,
	checkIfAccountsSpotBalancesAreEqual,
} from 'src/stores/useDriftAccountsStore';
import useUiUpdateInterval from './useUiUpdateInterval';

const EMPTY_BALANCES = [];

const useAccountSpotBalances = (
	userKey: string,
	balanceType?: SpotBalanceType
): AccountSpotBalance[] => {
	const userAccountBalances = useDriftAccountStore(
		(s) => s.accounts[userKey]?.spotBalances ?? EMPTY_BALANCES
	);

	const filteredBalances = userAccountBalances.filter((balance) =>
		balanceType ? matchEnum(balance.balanceType, balanceType) : true
	);

	return filteredBalances;
};

export const useAggregateAccountsSpotBalances = (
	balanceType?: SpotBalanceType
) => {
	const getAggregateAccountsSpotBalances = useDriftAccountStore(
		(s) => s.getAggregateAccountsSpotBalances
	);

	const aggregateAccountBalancesRef = useRef<AccountSpotBalance[]>([]);

	const getAndSetAggregateAccountBalances = () => {
		const newAggregateAccountsSpotBalances = getAggregateAccountsSpotBalances();
		const filteredBalances = newAggregateAccountsSpotBalances.filter(
			(balance) =>
				balanceType ? matchEnum(balance.balanceType, balanceType) : true
		);

		if (
			!checkIfAccountsSpotBalancesAreEqual(
				filteredBalances,
				aggregateAccountBalancesRef.current
			)
		) {
			aggregateAccountBalancesRef.current = filteredBalances;
		}
	};

	useUiUpdateInterval(getAndSetAggregateAccountBalances, true, false);

	return aggregateAccountBalancesRef.current;
};

export default useAccountSpotBalances;
