import { BigNum } from '@drift-labs/sdk';
import React from 'react';
import TableStateWrapper from 'src/components/TableStateWrapper';
import TableV2 from 'src/components/Tables/TableV2';
import NumberDisplayV2 from 'src/components/Utils/NumberDisplayV2';
import useShowAccountValues from 'src/hooks/useShowAccountValues';
import InlineLoadingBar from '../InlineLoadingBar/InlineLoadingBar';

const tableGrid = 'minmax(80px,12fr) minmax(100px,10fr) minmax(75px,10fr)';

const headers = ['Price', 'Order size', 'Percentage'];

export type Order = {
	price: BigNum;
	size: BigNum;
	percentage: BigNum;
	symbol: string;
};

const ScaledOrdersSplitTable = ({ orders }: { orders: Order[] | null }) => {
	const showAccountValues = useShowAccountValues();

	if (!orders) return null;
	return (
		<TableStateWrapper
			records={orders}
			emptyStateText={''}
			requireWalletConnect={false}
		>
			<TableV2.Skeleton
				className="rounded"
				fillSpace
				top={
					<TableV2.HeaderRow
						forceBottomBorder
						header
						grid={tableGrid}
						className={`w-full grid text-tiny bg-transparent`}
						lastColumnJustify="end"
						//border="fit"
					>
						{headers.map((label, index) => (
							<TableV2.HeaderCell
								alignRight={index === headers.length - 1}
								key={`header_${label}`.replace(/ /g, '')}
								className="bg-transparent py-1 leading-[14px]"
								textClassname="font-normal text-[12px] leading-[14px]"
							>
								{label}
							</TableV2.HeaderCell>
						))}
					</TableV2.HeaderRow>
				}
				middle={
					orders?.length ? (
						<div className={`h-full w-full thin-scroll pt-0.5 pb-1.5`}>
							{orders?.map(({ price, size, percentage, symbol }, index) => {
								return (
									<TableV2.BodyRow
										key={`order_${price}_${size}_${index}`}
										grid={tableGrid}
										lastColumnJustify="end"
										className={'py-0'}
										noBorder
									>
										<TableV2.BodyCell
											className="items-center py-0.5"
											key="order_price"
										>
											<NumberDisplayV2
												className="text-[12px] leading-[14px]"
												value={price}
												displayType="notional"
												softenZeroValues
												textOverride={!showAccountValues && '∗∗∗∗∗∗'}
											/>
										</TableV2.BodyCell>
										<TableV2.AssetCell
											key="order_size"
											className="items-center py-0.5"
											textClassname="text-[12px] leading-[14px] pt-0"
											value={size}
											assetSymbol={symbol}
											isAccountValueToHide
											softenZeroValues
											dataPuppetTag="balance_cell"
										/>
										<TableV2.BodyCell
											className="items-center py-0.5"
											key="order_percentage"
											alignRight
										>
											<NumberDisplayV2
												value={percentage}
												displayType="percentage"
												softenZeroValues
												className="text-[12px] leading-[14px]"
												toFixed={2}
											/>
										</TableV2.BodyCell>
									</TableV2.BodyRow>
								);
							})}
						</div>
					) : (
						<div
							className={`flex flex-col justify-center items-center h-full w-full text-center rounded-md`}
						>
							<InlineLoadingBar />
						</div>
					)
				}
			/>
		</TableStateWrapper>
	);
};

export default ScaledOrdersSplitTable;
