'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import UI_UTILS from '../utils/uiUtils';
import Button from './Button';
import Chevron from './Icons/Chevron';
import useAccountData from 'src/hooks/useAccountData';
import useHasConnectedWalletBefore from '../hooks/useHasConnectedWalletBefore';
import useSafePush from 'src/hooks/useSafePush';
import useWalletsToDisplay from 'src/hooks/useWalletsToDisplay';
import useHandleSelectWalletProvider from 'src/hooks/useHandleSelectWalletProvider';
import { ChevronDown } from '@drift-labs/icons';
import usePrefferredWalletSetting from 'src/hooks/usePrefferredWalletSetting';
import Text from './Text/Text';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useHasAcknowledgedTerms from 'src/hooks/useHasAcknowledgedTerms';
import useShowGridCustomiser from 'src/hooks/useShowGridCustomiser';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { twMerge } from 'tailwind-merge';
import { DriftCustomWalletName, DriftWalletName } from 'src/constants/wallets';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';
import usePreferredWalletIconInfo from 'src/hooks/usePreferredWalletIconInfo';
import useTryConnectAppKit from 'src/hooks/useTryConnectAppKit';
import useCurrentWalletConnectionState from 'src/hooks/useCurrentWalletConnectionState';
import { ONBOARDING_CONNECTION_STEP_LOCK_KEY } from 'src/utils/WalletConnectionState';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';

const ConnectWalletDropdownButton = ({
	preferredWalletName,
	preferredWalletIcon,
}: {
	preferredWalletName: string;
	preferredWalletIcon: string;
}) => {
	const safePush = useSafePush();
	const { handleSelectProvider } = useHandleSelectWalletProvider();
	const [hasPreviouslyConnectedWallet] = useHasConnectedWalletBefore();
	const connectionState = useCurrentWalletConnectionState();
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const tryConnectWithAppKit = useTryConnectAppKit({
		onConnectedCb: () => {
			handleSelectProvider(
				DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME
			);
		},
	});

	const handleTrySelectWallet = (name: string) => {
		const customWalletName = name as DriftCustomWalletName;

		switch (customWalletName) {
			case DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME: {
				// Route to email signup page
				safePush(
					hasPreviouslyConnectedWallet
						? '/onboarding/login-with-email'
						: '/onboarding/signup-with-email'
				);
				return;
			}
			case DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME: {
				tryConnectWithAppKit();
				return;
			}
			case DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME:
				// Can use default handling
				break;
			default: {
				break;
			}
		}

		// Default wallet handling
		handleSelectProvider(name as DriftWalletName);
	};

	const handleOpenPopup = () => {
		connectionState.instance.update(
			'Connecting',
			false,
			ONBOARDING_CONNECTION_STEP_LOCK_KEY
		);
		showConnectWalletOptions(true);
	};

	return (
		<div
			className="flex flex-row items-center bg-brand-gradient h-[31px] rounded-[2px]"
			id="connect-wallet-dropdown-button"
		>
			<button
				className={`text-xs md:text-sm whitespace-nowrap flex flex-row items-center`}
				onClick={() => handleTrySelectWallet(preferredWalletName)}
			>
				<img
					src={preferredWalletIcon}
					className="w-5 h-5 ml-3 mr-2 border rounded-full border-connect-button-icon-border bg-connect-button-icon-background"
				/>
				<div className="text-left">
					<div className="text-left">
						<Text.BODY2 className="leading-3 tracking-normal text-text-gradient-button">
							Connect{' '}
						</Text.BODY2>
					</div>
					<div className="mt-[-8px] text-left">
						<Text.MICRO1 className="leading-3 tracking-normal text-text-gradient-button">
							{preferredWalletName ===
							DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME
								? 'MetaMask'
								: preferredWalletName ===
								  DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME
								? 'Email'
								: preferredWalletName}
						</Text.MICRO1>
					</div>
				</div>
			</button>
			<button
				className={`text-xs md:text-sm whitespace-nowrap border-l border-container-border p-2 ml-4 flex flex-row items-center`}
				onClick={handleOpenPopup}
			>
				<div className="w-4 h-4 transition-all">
					<ChevronDown className="w-4 h-4" />
				</div>
			</button>
		</div>
	);
};

const ConnectWalletButton = (props: {
	className?: string;
	children?: ReactNode;
	fullWidth?: boolean;
	hideDropdown?: boolean;
}) => {
	const [_, showGridCustomiser] = useShowGridCustomiser();
	const isMobile = useIsMobileScreenSize();
	const setDrawersState = useDrawersStore((s) => s.set);
	const isAccountsSidebarOpen = useDrawersStore((s) => s.accounts.show);
	const walletIsConnected = useDriftStore(
		(s) => s.wallet.current?.adapter?.connected
	);
	const walletPubKey = useDriftStore(
		(s) => s.wallet.current?.adapter?.publicKey
	);
	const [isCopied, setIsCopied] = useState(false);
	const currentAccount = useAccountData();
	const [hasPreviouslyConnectedWallet] = useHasConnectedWalletBefore();
	const [hasAcknowledgedTerms] = useHasAcknowledgedTerms();
	const { walletsToShow, hiddenWallets, emailWallets } = useWalletsToDisplay(1);
	const [preferredWallet] = usePrefferredWalletSetting();
	const showConnectWalletOptions = useShowWalletConnectOptions();
	const preferredWalletInfo = usePreferredWalletIconInfo();

	const showTerms =
		!hasAcknowledgedTerms.hasPreviouslyAcknowledgedTerms ||
		hasAcknowledgedTerms.shouldShowTermsReminder;

	const preferredWalletProvider = [
		...walletsToShow,
		...hiddenWallets,
		...emailWallets,
	].find((wallet) => wallet?.provider.adapter.name === preferredWallet);

	const preferredMagicAuth =
		preferredWallet ===
		DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME;

	const preferredMetamask =
		preferredWallet === DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME;

	const preferredAppKit =
		preferredWallet === DRIFT_CUSTOM_WALLET_OPTIONS.APPKIT_WALLET_ADAPTER_NAME;

	useEffect(() => {
		if (isCopied) {
			const timer = setTimeout(() => {
				setIsCopied(false);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [isCopied]);

	const handleShowConnectWalletOptions = () => {
		showConnectWalletOptions(true);
	};

	return (
		<>
			<div id="wallet_button" className={`items-center h-full`}>
				{walletIsConnected && walletPubKey && !props.hideDropdown ? (
					<div
						className="bg-transparent cursor-pointer"
						onClick={() => {
							showGridCustomiser(false);
							setDrawersState((s) => {
								if (isAccountsSidebarOpen) {
									s.accounts.show = false;
								} else {
									s.accounts.show = true;
								}
							});
						}}
					>
						<Button.Wallet
							size="MEDIUM"
							className="min-w-[135px]"
							isDelegate={currentAccount?.isDelegatedTo}
						>
							<div>
								{isCopied ? (
									<div className="font-bold">Copied!</div>
								) : (
									<div className="flex flex-col items-start justify-center ml-2">
										<div className="flex flex-col items-start">
											<div
												className={`mt-0.5 text-xs md:text-sm font-bold text-text-default whitespace-nowrap`}
												style={{ lineHeight: '14px', fontSize: '14px' }}
											>
												{currentAccount?.isDelegatedTo
													? UI_UTILS.getDelegateAccountLabel(currentAccount)
													: `${currentAccount?.name.slice(0, 13) || ''}${
															currentAccount?.name?.length > 13 ? ' ...' : ''
													  }`}
											</div>
											<div
												className={`whitespace-nowrap text-2xs md:text-xs text-text-secondary`}
												style={{ lineHeight: '14px', fontSize: '10px' }}
											>
												{UI_UTILS.abbreviateAddress(walletPubKey)}
											</div>
										</div>
									</div>
								)}
							</div>
							{!isMobile && (
								<Chevron
									direction={isAccountsSidebarOpen ? 'up' : 'down'}
									className="h-[12px] w-[12px] ml-2"
								/>
							)}
						</Button.Wallet>
					</div>
				) : hasPreviouslyConnectedWallet &&
				  !props.hideDropdown &&
				  !showTerms &&
				  (preferredWalletProvider ||
						preferredMagicAuth ||
						preferredMetamask ||
						preferredAppKit) &&
				  !isMobile ? (
					<ConnectWalletDropdownButton
						preferredWalletIcon={preferredWalletInfo.iconSrc}
						preferredWalletName={preferredWalletInfo.walletName}
					/>
				) : (
					<Button.Secondary
						highlight
						size="MEDIUM"
						className={twMerge(
							`text-xs md:text-sm whitespace-nowrap ${
								props.fullWidth ? 'w-full' : ''
							}`,
							props.className
						)}
						onClick={handleShowConnectWalletOptions}
					>
						{props.children ? (
							props.children
						) : (
							<span className="text-darkBlue-110">Connect</span>
						)}
					</Button.Secondary>
				)}
			</div>
		</>
	);
};

export default ConnectWalletButton;
