'use client';

import React, { MouseEvent } from 'react';
import Button from './Button';
import { Wallet } from '@drift-labs/icons';
import { useShowWalletConnectOptions } from 'src/hooks/wallets/useShowWalletConnectOptions';

const SecondaryConnectWalletButton = () => {
	const showConnectWalletOptions = useShowWalletConnectOptions();

	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
		showConnectWalletOptions(true);
	};

	return (
		<div className="xs:py-8 sm:py-0">
			<Button.Ghost
				size="MEDIUM"
				className="flex items-center justify-center"
				onClick={handleClick}
			>
				<Wallet className="mr-2" color="var(--text-default)" size={18} />
				<span className="text-xs text-text-default">Connect</span>
			</Button.Ghost>
		</div>
	);
};

export default SecondaryConnectWalletButton;
