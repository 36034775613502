'use client';

import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

export type Validator = {
	account: string;
	name: string;
	icon: string;
	voteAccount: string;
};

const useValidatorsList = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [validators, setValidators] = useState<Validator[]>([]);

	const fetchValidators = () => {
		setIsLoading(true);
		fetch(`https://www.validators.app/api/v1/validators/mainnet.json`, {
			headers: {
				Token: `${process.env.NEXT_PUBLIC_VALIDATORS_APP_API_KEY}`,
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data) {
					const formattedValidators = data.map((validator: any) => ({
						account: validator.account,
						name: validator.name,
						icon: validator.avatar_url,
						voteAccount: validator.vote_account,
					}));
					setValidators(formattedValidators);
				}
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchValidators();
	}, []);

	return {
		validators,
		isLoading,
	};
};

export default singletonHook(
	{ validators: [], isLoading: false },
	useValidatorsList
);
