'use client';

import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonGroup } from './Button';
import NumberDisplayV2 from './Utils/NumberDisplayV2';
import { BigNum, BN } from '@drift-labs/sdk';
import Text from './Text/Text';
import SkeletonValuePlaceholder from './SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { ZERO_BIGNUM } from 'src/constants/math';
import { LoadingChart } from './Charts/LoadingChart';
import { useRateHistory } from 'src/hooks/lendAndBorrow/useRateHistory';
import { HistoricalTokenPriceDuration } from 'src/utils/graph';
import PnlChart from './PnlChart';

const dateRangeOptions = [
	// { label: '24h', value: '1' },
	{ label: '7d', value: HistoricalTokenPriceDuration.ONE_WEEK },
	{ label: '30d', value: HistoricalTokenPriceDuration.ONE_MONTH },
	{ label: '90d', value: HistoricalTokenPriceDuration.NINETY_DAYS },
];

export const RateHistoryChart = (props: {
	//timeframeIndex: number;
	marketIndex: number;
	type: 'deposit' | 'borrow';
	showAverage?: boolean;
	averageLabelText?: 'string';
	className?: string;
	chartClassName?: string;
	loadingClassName?: string;
}) => {
	const displayDecimals = 5;
	const [dateRange, setDateRate] = useState<HistoricalTokenPriceDuration>(
		HistoricalTokenPriceDuration.NINETY_DAYS
	);
	const { data, isLoading, error } = useRateHistory({
		marketIndex: props.marketIndex,
		type: props.type,
		dateRange,
	});

	const { chartData, averageRate } = data ?? {};

	const handleChangeDateRange = (range: HistoricalTokenPriceDuration) => {
		if (isLoading) return;
		setDateRate(range);
	};

	const capitalizedType = props.type === 'deposit' ? 'Deposit' : 'Borrow';
	const chartLabel = `${capitalizedType} Rate`;

	return (
		<div>
			<div className="flex flex-row items-center justify-between">
				<ButtonGroup.Segmented
					options={dateRangeOptions}
					selected={dateRange}
					size="MEDIUM"
					selectAction={handleChangeDateRange}
					className={isLoading && 'opacity-50'}
				/>
				<div className="flex flex-col items-end gap-0.5 text-text-label">
					<Text.MICRO1 className="text-text-label">
						{props.averageLabelText || 'Avg APR'}
					</Text.MICRO1>
					{props.showAverage &&
						(isLoading ? (
							<SkeletonValuePlaceholder className="w-12 h-3 mt-1" loading />
						) : (
							<NumberDisplayV2
								value={
									(averageRate &&
										!isNaN(averageRate) &&
										BigNum.fromPrint(
											`${Math.round(averageRate * 10000) / 10000}`,
											new BN(4)
										)) ||
									ZERO_BIGNUM
								}
								displayType="percentage"
								colourCoded
							/>
						))}
				</div>
			</div>
			<div
				className={twMerge(
					'items-center justify-center flex-grow min-h-[200px] w-full mt-4 mb-4',
					props.className
				)}
			>
				{isLoading ? (
					<div
						className={twMerge(
							`flex flex-col justify-center items-center h-[200px] w-full text-center rounded-md`,
							props.loadingClassName
						)}
					>
						<LoadingChart />
					</div>
				) : error ? (
					<div
						className={twMerge(
							`flex flex-col justify-center items-center h-[200px] w-full text-center rounded-md p-6`,
							props.loadingClassName
						)}
					>
						<Text.BODY3 className="text-text-label">
							Unable to fetch {props.type} rate history
						</Text.BODY3>
					</div>
				) : (
					<PnlChart
						id={`rate-history-${props.type}`}
						data={chartData}
						field={'totalPnl'}
						label={chartLabel}
						positive={false}
						decimalsToShow={displayDecimals}
						isInModal={true}
						overrideNoBeginAtZero={true}
						isPercent={true}
						nonNotional={true}
						isHourly={false}
						className={twMerge('min-h-[200px]', props.chartClassName)}
					/>
				)}
			</div>
		</div>
	);
};
