'use client';

import { useCallback } from 'react';
import useSafePush from '../useSafePush';
import { MAIN_POOL_ID } from '@drift/common';

/**
 * The swap page's params are derived from the URL, hence to update the swap params is to update the URL.
 */
const useUpdateSwapParams = () => {
	const safePush = useSafePush();

	const updateSwapParams = useCallback(
		(fromTokenSymbol: string, toTokenSymbol: string, poolId = MAIN_POOL_ID) => {
			if (poolId === MAIN_POOL_ID) {
				safePush(`/swap/${fromTokenSymbol}-${toTokenSymbol}`);
			} else {
				safePush(`/swap/${fromTokenSymbol}-${toTokenSymbol}-${poolId}`);
			}
		},
		[]
	);

	return updateSwapParams;
};

export default useUpdateSwapParams;
