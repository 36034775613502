"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStats = void 0;
const web3_js_1 = require("@solana/web3.js");
const pollingUserStatsAccountSubscriber_1 = require("./accounts/pollingUserStatsAccountSubscriber");
const webSocketUserStatsAccountSubsriber_1 = require("./accounts/webSocketUserStatsAccountSubsriber");
const pda_1 = require("./addresses/pda");
const grpcUserStatsAccountSubscriber_1 = require("./accounts/grpcUserStatsAccountSubscriber");
const numericConstants_1 = require("./constants/numericConstants");
const numericConstants_2 = require("./constants/numericConstants");
const numericConstants_3 = require("./constants/numericConstants");
const anchor_1 = require("@coral-xyz/anchor");
const fuel_1 = require("./math/fuel");
class UserStats {
    constructor(config) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.driftClient = config.driftClient;
        this.userStatsAccountPublicKey = config.userStatsAccountPublicKey;
        if (((_a = config.accountSubscription) === null || _a === void 0 ? void 0 : _a.type) === 'polling') {
            this.accountSubscriber = new pollingUserStatsAccountSubscriber_1.PollingUserStatsAccountSubscriber(config.driftClient.program, config.userStatsAccountPublicKey, config.accountSubscription.accountLoader);
        }
        else if (((_b = config.accountSubscription) === null || _b === void 0 ? void 0 : _b.type) === 'grpc') {
            this.accountSubscriber = new grpcUserStatsAccountSubscriber_1.grpcUserStatsAccountSubscriber(config.accountSubscription.grpcConfigs, config.driftClient.program, config.userStatsAccountPublicKey, {
                resubTimeoutMs: (_c = config.accountSubscription) === null || _c === void 0 ? void 0 : _c.resubTimeoutMs,
                logResubMessages: (_d = config.accountSubscription) === null || _d === void 0 ? void 0 : _d.logResubMessages,
            });
        }
        else if (((_e = config.accountSubscription) === null || _e === void 0 ? void 0 : _e.type) === 'websocket') {
            this.accountSubscriber = new webSocketUserStatsAccountSubsriber_1.WebSocketUserStatsAccountSubscriber(config.driftClient.program, config.userStatsAccountPublicKey, {
                resubTimeoutMs: (_f = config.accountSubscription) === null || _f === void 0 ? void 0 : _f.resubTimeoutMs,
                logResubMessages: (_g = config.accountSubscription) === null || _g === void 0 ? void 0 : _g.logResubMessages,
            }, config.accountSubscription.commitment);
        }
        else {
            throw new Error(`Unknown user stats account subscription type: ${(_h = config.accountSubscription) === null || _h === void 0 ? void 0 : _h.type}`);
        }
    }
    async subscribe(userStatsAccount) {
        this.isSubscribed = await this.accountSubscriber.subscribe(userStatsAccount);
        return this.isSubscribed;
    }
    async fetchAccounts() {
        await this.accountSubscriber.fetch();
    }
    async unsubscribe() {
        await this.accountSubscriber.unsubscribe();
        this.isSubscribed = false;
    }
    getAccountAndSlot() {
        return this.accountSubscriber.getUserStatsAccountAndSlot();
    }
    getAccount() {
        return this.accountSubscriber.getUserStatsAccountAndSlot().data;
    }
    getInsuranceFuelBonus(now, includeSettled = true, includeUnsettled = true) {
        const userStats = this.getAccount();
        let insuranceFuel = numericConstants_2.ZERO;
        if (includeSettled) {
            insuranceFuel = insuranceFuel.add(new anchor_1.BN(userStats.fuelInsurance));
        }
        if (includeUnsettled) {
            // todo: get real time ifStakedGovTokenAmount using ifStakeAccount
            if (userStats.ifStakedGovTokenAmount.gt(numericConstants_2.ZERO)) {
                const spotMarketAccount = this.driftClient.getSpotMarketAccount(numericConstants_3.GOV_SPOT_MARKET_INDEX);
                const fuelBonusNumeratorUserStats = anchor_1.BN.max(now.sub(anchor_1.BN.max(new anchor_1.BN(userStats.lastFuelIfBonusUpdateTs), numericConstants_1.FUEL_START_TS)), numericConstants_2.ZERO);
                insuranceFuel = insuranceFuel.add((0, fuel_1.calculateInsuranceFuelBonus)(spotMarketAccount, userStats.ifStakedGovTokenAmount, fuelBonusNumeratorUserStats));
            }
            if (userStats.ifStakedQuoteAssetAmount.gt(numericConstants_2.ZERO)) {
                const spotMarketAccount = this.driftClient.getSpotMarketAccount(numericConstants_3.QUOTE_SPOT_MARKET_INDEX);
                const fuelBonusNumeratorUserStats = anchor_1.BN.max(now.sub(anchor_1.BN.max(new anchor_1.BN(userStats.lastFuelIfBonusUpdateTs), numericConstants_1.FUEL_START_TS)), numericConstants_2.ZERO);
                insuranceFuel = insuranceFuel.add((0, fuel_1.calculateInsuranceFuelBonus)(spotMarketAccount, userStats.ifStakedQuoteAssetAmount, fuelBonusNumeratorUserStats));
            }
        }
        return insuranceFuel;
    }
    getReferrerInfo() {
        if (this.getAccount().referrer.equals(web3_js_1.PublicKey.default)) {
            return undefined;
        }
        else {
            return {
                referrer: (0, pda_1.getUserAccountPublicKeySync)(this.driftClient.program.programId, this.getAccount().referrer, 0),
                referrerStats: (0, pda_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, this.getAccount().referrer),
            };
        }
    }
    static getOldestActionTs(account) {
        return Math.min(account.lastFillerVolume30DTs.toNumber(), account.lastMakerVolume30DTs.toNumber(), account.lastTakerVolume30DTs.toNumber());
    }
}
exports.UserStats = UserStats;
