import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react';

const ImageWithFallback = ({
	fallbackComponent,
	...rest
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
	fallbackComponent: React.ReactNode;
}) => {
	const [hasError, setHasError] = useState(false);

	const handleError = () => {
		setHasError(true);
	};

	if (hasError) {
		return fallbackComponent;
	}

	return <img {...rest} onError={handleError} />;
};

export default ImageWithFallback;
