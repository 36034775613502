import produce from 'immer';
import { create } from 'zustand';

// Base types for feature flags
export interface BaseFeatureFlag {
	enabled: boolean;
	rawPayload?: unknown;
}

// Special feature flags that need custom handling
export interface HighlightedAssetsFlag extends BaseFeatureFlag {
	assets: string[];
}

// Define the feature flag configurations
export interface FeatureFlagConfigs {
	SKIP_TRANSACTION_PREFLIGHTS: BaseFeatureFlag;
	HIGHLIGHTED_ASSETS: HighlightedAssetsFlag;
	ENABLE_UI_INVARIANT_CHECKS: BaseFeatureFlag;
	PREFER_EVENTS_SERVER_EVENTS: BaseFeatureFlag;
	// Add new feature flags here by extending this interface
	// NEW_FEATURE_FLAG: BaseFeatureFlag;
}

// Derive the feature flag key type from the configs
export type FeatureFlagKey = keyof FeatureFlagConfigs;

// Type helper to get the flag type for a specific key
export type FlagTypeForKey<K extends FeatureFlagKey> = FeatureFlagConfigs[K];

// The store's flag state type
type FlagState = {
	[K in FeatureFlagKey]: FlagTypeForKey<K>;
};

// Default values for all feature flags
export const FEATURE_FLAG_DEFAULTS: FlagState = {
	SKIP_TRANSACTION_PREFLIGHTS: {
		enabled: false,
	},
	HIGHLIGHTED_ASSETS: {
		enabled: false,
		assets: [],
	},
	ENABLE_UI_INVARIANT_CHECKS: {
		enabled: false,
	},
	PREFER_EVENTS_SERVER_EVENTS: {
		enabled: false,
	},
};

export interface FeatureFlagStore {
	set: (x: (s: FeatureFlagStore) => void) => void;
	get: () => FeatureFlagStore;
	flags: FlagState;
	flagEnabled: (flagKey: FeatureFlagKey) => boolean;
	getFlagPayload: <K extends FeatureFlagKey>(
		flagKey: K
	) => FlagTypeForKey<K>['rawPayload'];
}

const useFeatureFlagStore = create<FeatureFlagStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	flags: FEATURE_FLAG_DEFAULTS,
	flagEnabled: (flagKey: FeatureFlagKey) =>
		get().flags[flagKey]?.enabled ?? false,
	getFlagPayload: <K extends FeatureFlagKey>(flagKey: K) =>
		get().flags[flagKey]?.rawPayload,
}));

export default useFeatureFlagStore;
