'use client';

import { useEffect } from 'react';
import Env, {
	isDev,
	syncGetCurrentSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { notify } from 'src/utils/notifications';
import { getRpcToUse } from 'src/utils/rpcUtils/rpcUtils_api';
import useLiquidationAlerts from './Liquidations/useLiquidationAlerts';
import use24hMarketDataFromApi from './use24hMarketDataFromApi';
import use24hPricePoints from './use24hPricePoints';
import useBorrowLendBalances from './useBorrowLendBalances';
import useClearStaleNotifications from './useClearStaleNotifications';
import useCurrentMarketMarkPrice from './useCurrentMarketMarkPrice';
import { useKeepCurrentRpcHealthInSync } from './useCurrentRpcHealth';
import useCurrentSnapshot from './useCurrentSnapshot';
import useDriftActions from './useDriftActions';
import useDriftColours from './useDriftColours';
import useDriftStatus from './useDriftStatus';
import useDriftMarketEventListener from './driftEvents/useDriftMarketEventListener';
import useGeolocation from './useGeolocation';
import useKeepWalletsInSync from './useKeepWalletsInSync';
import useMarginInfo from './useMarginInfo';
import useMarketHistory from './useMarketHistory';
import useOpenOrdersData from './useOpenOrdersData';
import useSyncOpenPositions from './useSyncOpenPositionData';
import usePnlHistory from './usePnlHistory';
import usePredictedFunding from './usePredictedFunding';
import usePriceChange from './usePriceChange';
import { useKeepTradeformPriceImpactInSync } from './usePriceImpact';
import useSyncOracleDataWithRpc from './useSyncOracleDataWithRpc';
import useSolBalance from './useSolBalance';
import useSyncWithAllMarketsInfo from './useSyncWithAllMarketsInfo';
import useTradeHistory from './useTradeHistory';
import useWalletUsdcBalance from './useWalletUsdcBalance';
import useSyncLiquidationAlertState from './useSyncLiquidationAlertState';
import useUserStatsAccount from './useUserStatsAccount';
import useListenForNewDeploys from './useListenForNewDeploys';
import gtag from 'src/utils/gtag';
import useIdlePollingRateSwitcher from './useIdlePollingSwitcher';
import useLiquidityPools from './useLiquidityPools';
import useSyncSavedSubaccount from './useSyncSavedSubaccount';
import useCountConsoleErrors from './utils/useCountConsoleErrors';
import useDevInvariants from './dev/useDevInvariants';
import usePostHogGlobalEvents from './posthog/usePostHogGlobalEvents';
import UI_UTILS from 'src/utils/uiUtils';
import useDocumentTitle from './useDocumentTitle';
import useAutomatedTxnsListener from './useAutomatedTxnsListener';
import useSyncMarketDataState from './dlob/useSyncMarketDataState';
import useWalletHooks from './useWalletHooks';
import { useSyncIfStakeData } from '../stores/ifDataStore/useSyncIfStakeData';
import { useSyncLocalStorage } from './useSyncLocalStorage';
import { useSyncAccountSpotBalances } from './useSyncAccountSpotBalances';
import useLogMetricsToGrafana from './useLogMetricsToGrafana';
import useSyncPriorityFeeStore from '../providers/priorityFees/useSyncPriorityFeeStore';
import useSyncAccountExists from './useSyncAccountExists';
import useLogEventLoopLag from './dev/useLogEventLoopLag';
import useSyncWindowSizeStore from './useSyncWindowSizeStore';
import useManageDriftEventSubscriptions from './driftEvents/useManageDriftEventSubscriptions';
import useSyncMarketOrderToastStateAndUI from '../components/MarketOrderToasts/useSyncMarketOrderToastStateAndUI';
import useDevActions from './useDevActions';
import useSelfFilling from './useSelfFilling';
import useSyncDriftClock from './useSyncChainClock';
import usePredictionsTradeHistory from './usePredictionsTradeHistory';
import useSyncFeatureFlags from 'src/hooks/useSyncFeatureFlags';
import TransactionErrorHandler from 'src/utils/TransactionErrorHandler';
import useAppEventEmitter from './useAppEventEmitter';
import usePostHogCapture from './posthog/usePostHogCapture';
import useDownloadRequests from './useDownloadRequests';
import useRouterConfig from './useRouterConfig';
import { useSyncTokenAccountsStore } from '../stores/tokenAccounts/useSyncTokenAccountsStore';
import useNavigationState from 'src/components/Navigation/useNavigationState';

/**
 * Nextjs is really annoying with its server-side rendering, and most of the app setup needs to happen in hooks, otherwise the server tries to access things like "window" which is only available in the browser.
 * @returns
 */
const useAppSetup = () => {
	// PERFORMANCE NOTE : THIS HOOK MAKE THE OUTER HOOK RENDER MUTLIPLE TIMES WHEN ON THE /dev/dev route, but not the trade page
	const actions = useDriftActions();
	const get = useDriftStore((s) => s.get);
	const appEventEmitter = useAppEventEmitter();
	const { captureEvent } = usePostHogCapture();

	useEffect(() => {
		// @ts-ignore
		window.drift_dev = { getStore: get };
	}, []);

	// Initial setup of app-related things
	useEffect(() => {
		const rpcToUse = getRpcToUse();

		const usingMainnetOverride = syncGetCurrentSettings()?.mainnetOverride;

		if (usingMainnetOverride) {
			notify({
				type: 'info',
				message: 'Using mainnet settings',
			});
		}

		const currentSettings = syncGetCurrentSettings();

		// initialize clearing house and connection
		actions.updateConnection(
			rpcToUse,
			currentSettings.accountSubscriberType,
			Env.sdkEnv,
			'initializing'
		);

		// # Dev Logging Info
		if (isDev()) {
			console.log('');
			console.log('');
			console.log(
				"%cTo disable dev logging type 'window.drift.devLogging = false' into the console",
				'color:orange; font-size:12px'
			);
			console.log('');
			console.log('');
		}
	}, []);

	// Set up inspectlet
	useEffect(() => {
		const isLocalHost = window.location.href.includes('localhost');

		if (isLocalHost) return;

		(() => {
			//@ts-ignore
			window.__insp = window.__insp || [];
			//@ts-ignore
			__insp.push(['wid', 1802840925]);
			const ldinsp = function () {
				//@ts-ignore
				if (typeof window.__inspld != 'undefined') return;
				//@ts-ignore
				window.__inspld = 1;
				const insp = document.createElement('script');
				insp.type = 'text/javascript';
				insp.async = true;
				insp.id = 'inspsync';
				insp.src =
					('https:' == document.location.protocol ? 'https' : 'http') +
					'://cdn.inspectlet.com/inspectlet.js?wid=1802840925&r=' +
					Math.floor(new Date().getTime() / 3600000);
				const x = document.getElementsByTagName('script')[0];
				x.parentNode.insertBefore(insp, x);
			};
			setTimeout(ldinsp, 0);
		})();
	}, []);

	// Set up google analytics
	useEffect(() => {
		if (UI_UTILS.isWindowDefined() && !window.gtag) {
			const ga4 = document.createElement('script');
			ga4.async = true;
			ga4.src = 'https://www.googletagmanager.com/gtag/js?id=G-THJV1LECW1';
			document.body.appendChild(ga4);

			window.dataLayer = window.dataLayer || [];
			window.gtag = gtag;
			gtag('js', new Date());
			gtag('config', 'G-THJV1LECW1');
		}
	}, []);

	// Set up Freshdesk
	// useEffect(() => {
	// 	if (Env.enableSupportWidget) {
	// 		//@ts-ignore
	// 		if (!window.FreshworksWidget) {
	// 			//@ts-ignore
	// 			window.fwSettings = {
	// 				widget_id: Env.feedbackWidgetId,
	// 			};

	// 			const freshDesk = document.createElement('script');
	// 			freshDesk.type = 'text/javascript';
	// 			freshDesk.async = true;
	// 			freshDesk.defer = true;
	// 			freshDesk.src = `https://widget.freshworks.com/widgets/${Env.feedbackWidgetId}.js`;
	// 			document.body.appendChild(freshDesk);

	// 			const freshDesk2 = document.createElement('script');
	// 			freshDesk2.type = 'text/javascript';
	// 			freshDesk2.src = '/assets/freshdesk.js';
	// 			document.body.appendChild(freshDesk2);
	// 		}
	// 	}
	// }, []);

	useEffect(() => {
		TransactionErrorHandler.setAppEventEmitter(appEventEmitter);
		TransactionErrorHandler.setCaptureEvent(captureEvent);
	}, [appEventEmitter, captureEvent]);

	useRouterConfig();
	useDriftColours();
	useSyncLocalStorage();
	useListenForNewDeploys();
	useDriftMarketEventListener();
	use24hMarketDataFromApi();
	use24hPricePoints();
	useMarketHistory();
	useSyncOpenPositions();
	useOpenOrdersData();
	useTradeHistory();
	usePredictionsTradeHistory();
	useCurrentMarketMarkPrice();
	useSolBalance();
	useWalletUsdcBalance();
	useGeolocation();
	useCurrentSnapshot();
	useLiquidityPools();
	useDriftStatus();
	useSyncOracleDataWithRpc();
	useBorrowLendBalances();
	usePredictedFunding();
	usePriceChange();
	useLiquidationAlerts();
	useClearStaleNotifications();
	useMarginInfo();
	useKeepCurrentRpcHealthInSync();
	useKeepWalletsInSync();
	useSyncLiquidationAlertState();
	useUserStatsAccount();
	useIdlePollingRateSwitcher();
	useSyncSavedSubaccount();
	useCountConsoleErrors();
	useDevInvariants();
	usePostHogGlobalEvents();
	useDocumentTitle();
	useSyncPriorityFeeStore();
	usePnlHistory(); // not in useOverviewHooks because it seems good to have this ready to go when a user hits overview page, as its often the first thing they look at
	useAutomatedTxnsListener();
	useWalletHooks();
	useSyncIfStakeData();
	useSyncAccountSpotBalances();
	useLogMetricsToGrafana();
	useSyncAccountExists();
	useSyncWindowSizeStore();
	useManageDriftEventSubscriptions();
	useSelfFilling();
	useSyncFeatureFlags();
	useDownloadRequests();
	useSyncTokenAccountsStore();
	useNavigationState();

	// PERFORMANCE NOTE : THESE HOOKS CAUSE THIS HOOK TO RUN ON A LOOP (on the trade page - for some reason it still loops on other pages though e.g. on route /dev/dev )
	useSyncMarketDataState();
	useKeepTradeformPriceImpactInSync();
	useSyncWithAllMarketsInfo();
	useSyncDriftClock();

	// Toasts
	useSyncMarketOrderToastStateAndUI();

	// Dev Stuff
	useLogEventLoopLag();
	useDevActions();

	return;
};

export default useAppSetup;
