'use client';

import { User } from '@drift-labs/sdk';
import { useRef } from 'react';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useHistoryFetcher from './useHistoryFetcher';
import { COMMON_UI_UTILS } from '@drift/common';

const useFundingHistory = () => {
	const getState = useDriftStore((s) => s.get);

	const setAccountsStore = useDriftAccountsStore((s) => s.set);
	const getAccountsStore = useDriftAccountsStore((s) => s.get);

	const doneInitialFetch = useRef(false);

	// Get initial funding history from the history server
	const getAndSetFundingHistoryFromHistoryServer = async (users: User[]) => {
		const isConnected =
			getState().wallet.current.adapter.connected &&
			getState().connection.current;
		if (!isConnected) return;

		const pubKeys = users.map((user) => user.getUserAccountPublicKey());
		const userKeys = users.map((user) =>
			COMMON_UI_UTILS.getUserKey(
				user.getUserAccount().subAccountId,
				user.getUserAccount().authority
			)
		);

		const results = await ExchangeHistoryClient.getFundingForUsers(pubKeys, 0);

		if (!results.success) return false;

		const { records, totalCounts } = results?.body?.data;

		setAccountsStore((s) => {
			userKeys.forEach((userKey, index) => {
				const fundingHistoryForUser = records?.[index];
				const fundingHistoryCountForUser = totalCounts?.[index] ?? 0;

				// check for current fundingPayments in store in case eventListener has already started
				const alreadyLoadedFunding =
					getAccountsStore().accounts[userKey].fundingHistory.records;

				// store trades in descending chronological order
				const orderedFundingRecords =
					[...(fundingHistoryForUser ?? []), ...(alreadyLoadedFunding ?? [])]
						.sort((a, b) => b.ts.toNumber() - a.ts.toNumber())
						.filter(
							(v, _i, a) =>
								!!a.find(
									(t) =>
										`${t.txSig}-${t.txSigIndex}` ===
										`${v.txSig}-${v.txSigIndex}`
								)
						) || [];

				s.accounts[userKey].fundingHistory.records = orderedFundingRecords;
				s.accounts[userKey].fundingHistory.totalCount =
					fundingHistoryCountForUser;
				s.accounts[userKey].fundingHistory.initialHistoryLoaded = true;
			});
		});

		doneInitialFetch.current = true;

		return true;
	};

	useHistoryFetcher(getAndSetFundingHistoryFromHistoryServer, 'fundingHistory');
};

export default useFundingHistory;
