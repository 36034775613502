'use client';

import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import Button, { ButtonGroup } from 'src/components/Button';
import { CollateralInput } from 'src/components/Inputs/CollateralInput';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import Select from 'src/components/Inputs/Select';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import Text from 'src/components/Text/Text';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import ValueDisplay from 'src/components/ValueDisplay';
import Env, {
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSubaccountCollateralBalanceForBank from 'src/hooks/useSubaccountCollateralBalanceForBank';
import React, { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import { MIN_LEFTOVER_SOL } from '@drift/common';
import NewAccountCreationCost from '../Utils/NewAccountCreationCost';
import { MaxSubaccountsWarning } from '../Utils/MaxSubaccountsWarning';
import { useMaxSubaccountsReached } from 'src/hooks/useMaxSubaccountsReached';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';

// TODO - This should come from SDK
const maxChars = 32;

type DepositType = 'wallet' | 'account';

const NewSubaccountModal = () => {
	const availableSubAccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const collateralTypeIndex = useDriftStore((s) => s.modalCollateralType);
	const { captureEvent } = usePostHogCapture();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const [newAccountName, setNewAccountName] = useState('');
	const [depositType, setDepositType] = useState<DepositType>('wallet');
	const [depositAccountSelection, setDepositAccountSelection] =
		useState<string>(undefined);
	const [selectedCollatMarket, setSelectedCollatMarket] =
		useState<SpotMarketConfig>(OrderedSpotMarkets[collateralTypeIndex]);
	const [depositAmount, setDepositAmount] = useState<string>('');
	const [submitting, setSubmitting] = useState(false);
	const [availableAccountOptions, setAvailableAccountOptions] = useState([]);
	const [userHasAgreedToFee, setUserHasAgreedToFee] = useState(false);

	// const [walletBalance] =
	// 	useCurrentWalletCollateralBalance(selectedCollatMarket);
	const subaccountBalance = useSubaccountCollateralBalanceForBank({
		accountKey: depositAccountSelection,
		market: selectedCollatMarket,
		useTokenBalance: true,
	});
	const userStatsAccount = useDriftStore((s) => s.userStatsAccount);
	const currentAuthority = useCurrentWalletAdapter()?.publicKey;
	const subaccountCount = userStatsAccount?.numberOfSubAccountsCreated ?? 1;
	const maxSubaccountReached = useMaxSubaccountsReached();
	const isDevMode = useDevSwitchIsOn();

	const {
		totalCost: totalAccountCreationCost,
		loaded: accountCreationCostLoaded,
	} = useAccountCreationCost();

	const [walletDepositableBalanceBigNum] =
		useTransferableWalletCollateralBalance(selectedCollatMarket, true);

	const currentCollatBalanceBigNum =
		depositType == 'wallet'
			? walletDepositableBalanceBigNum
			: subaccountBalance;

	const depositAmountBigNum = BigNum.fromPrint(
		depositAmount ? depositAmount : '0',
		selectedCollatMarket.precisionExp
	);

	const defaultNewAccountName = `Subaccount ${(subaccountCount ?? 0) + 1}`;

	const balanceExceeded = depositAmountBigNum.gt(currentCollatBalanceBigNum);

	const isMaxSolDeposit =
		depositAmount !== '' &&
		depositType == 'wallet' &&
		depositAmountBigNum.eq(walletDepositableBalanceBigNum) &&
		selectedCollatMarket.symbol === 'SOL';

	const charsRemaining = maxChars - newAccountName.length;

	const canCreateMoreAccounts =
		isDevMode ||
		Env.maxAccountsPerWallet >
			availableSubAccounts.filter((acct) =>
				acct?.authority?.equals(currentAuthority)
			)?.length;

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setDepositAmount('');
	}, [selectedCollatMarket?.marketIndex]);

	useEffect(() => {
		setDepositAccountSelection(
			depositType == 'account'
				? availableSubAccounts[0]?.userKey ?? undefined
				: undefined
		);
	}, [depositType, availableSubAccounts]);

	useEffect(() => {
		setAvailableAccountOptions(
			availableSubAccounts
				.filter((account) => !account.isDelegatedTo)
				.map((account) => ({
					value: account.userKey ?? null,
					label: account.name,
				}))
		);
	}, [availableSubAccounts]);

	const handleAddAccount = async () => {
		if (!canCreateMoreAccounts) return;

		setSubmitting(true);
		const accountName = newAccountName ? newAccountName : defaultNewAccountName;

		captureEvent('creating_new_subaccount');

		const fundsSource = depositAccountSelection
			? availableSubAccounts.find(
					(acct) => acct.userKey == depositAccountSelection
			  )?.userId
			: null;

		const success = await actions.initializeAndDepositCollateralForAccount({
			amount: depositAmountBigNum,
			spotMarketConfig: selectedCollatMarket,
			poolId: selectedCollatMarket.poolId,
			name: accountName,
			fromId: fundsSource,
		});

		setSubmitting(false);
		if (success) {
			hideModal();
		}
	};

	const showModal = actions.showModal;
	const hideModal = () => showModal('showNewSubaccountModal', false);

	return (
		<Modal onClose={hideModal}>
			<Modal.Body>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>Add account</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					{!canCreateMoreAccounts ? (
						<div className="flex flex-col items-center text-center">
							<Text.H3>
								This wallet has reached the maximum number of subaccounts (8).
							</Text.H3>
							<Utility.VERTSPACERXL />
							<Button.Secondary
								size="LARGE"
								className="w-full"
								onClick={hideModal}
							>
								Done
							</Button.Secondary>
						</div>
					) : (
						<>
							<div className="flex flex-col items-end">
								<LabelledInput label="New Account name">
									<TextField.Default
										type="text"
										value={newAccountName}
										onChange={setNewAccountName}
										placeholder={defaultNewAccountName}
										disabled={!canCreateMoreAccounts}
									/>
								</LabelledInput>
								<Utility.VERTSPACERXS />
								<Utility.FORMLABEL02
									label={`${charsRemaining} chars remaining`}
								/>

								{charsRemaining < 0 && (
									<>
										<Utility.VERTSPACERM />
										<InfoMessage
											type="error"
											messageTitle="Invalid account name"
											message="You can't exceed the account name character limit"
										/>
									</>
								)}
							</div>

							<div className="flex flex-col">
								<Utility.VERTSPACERXL />
								<Utility.VERTDIVIDER />
								<Utility.VERTSPACERM />

								<div className="flex items-center justify-between">
									<Utility.FORMLABEL01 label="Deposit Collateral From" />
									<ButtonGroup.Segmented
										options={[
											{
												label: 'Wallet',
												value: 'wallet',
											},
											{
												label: 'Account',
												value: 'account',
											},
										]}
										selected={depositType}
										size="SMALL"
										selectAction={(val: DepositType) => setDepositType(val)}
									/>
								</div>

								<Utility.VERTSPACERL />

								{depositType === 'account' && (
									<>
										<LabelledInput label="Funding Account">
											<Select.Default
												id="depositAccountSelection"
												currentSelection={depositAccountSelection}
												onChange={setDepositAccountSelection}
												options={availableAccountOptions}
												useFullWidth
											/>
										</LabelledInput>
										<Utility.VERTSPACERM />
									</>
								)}

								<CollateralInput
									selectedMarket={selectedCollatMarket}
									maxAmount={currentCollatBalanceBigNum}
									onChangeMarket={setSelectedCollatMarket}
									label="Transfer type and Amount"
									value={depositAmount}
									onChangeValue={setDepositAmount}
									source={
										depositType === 'wallet'
											? {
													type: 'wallet',
											  }
											: {
													type: 'userAccount',
													userKey: depositAccountSelection,
											  }
									}
								/>

								<Utility.VERTSPACERS />

								{balanceExceeded && (
									<>
										<Utility.VERTSPACERS />
										<InfoMessage
											type="error"
											messageTitle="Invalid deposit amount"
											message="You can't deposit more than your available account balance"
										/>
									</>
								)}

								<Utility.VERTSPACERS />

								{depositType === 'wallet' && (
									<>
										<Utility.FORMLABEL01
											label="Depositing funds from wallet"
											className="text-neutrals-30"
										/>
										<Utility.VERTSPACERM />
									</>
								)}

								{isMaxSolDeposit && (
									<>
										<div className="text-text-label">
											<Text.BODY2 className="text-xs leading-4">
												We hold back {MIN_LEFTOVER_SOL.toFixed(2)} SOL on max
												deposits to cover transaction fees
											</Text.BODY2>
										</div>
										<Utility.VERTSPACERM />
									</>
								)}

								{maxSubaccountReached ? (
									<MaxSubaccountsWarning />
								) : (
									<NewAccountCreationCost
										checkboxUpdated={(selection) =>
											setUserHasAgreedToFee(selection)
										}
										forceAgreement={true}
									/>
								)}
								<Utility.VERTSPACERXL />

								<Utility.VERTDIVIDER />
								<Utility.VERTSPACERM />

								<ValueDisplay.ValueChange
									label="Asset Balance"
									previousValue={currentCollatBalanceBigNum}
									afterValue={BigNum.max(
										currentCollatBalanceBigNum.sub(depositAmountBigNum),
										BigNum.zero(selectedCollatMarket.precisionExp)
									)}
									previousValuePrint={`${currentCollatBalanceBigNum.prettyPrint()} ${
										selectedCollatMarket.symbol
									}`}
									afterValuePrint={`${currentCollatBalanceBigNum
										.sub(depositAmountBigNum)
										.prettyPrint()} ${selectedCollatMarket.symbol}`}
								/>
								<Utility.VERTSPACERL />

								<Button.BigSemantic
									positive
									onClick={handleAddAccount}
									disabled={
										!accountCreationCostLoaded ||
										solBalance.lt(totalAccountCreationCost) ||
										!canCreateMoreAccounts ||
										submitting ||
										!depositAmount ||
										depositAmountBigNum.lte(
											BigNum.zero(selectedCollatMarket.precisionExp),
											true
										) ||
										balanceExceeded ||
										!userHasAgreedToFee ||
										maxSubaccountReached
									}
								>
									{canCreateMoreAccounts ? 'ADD' : `UNAVAILABLE`}
								</Button.BigSemantic>
							</div>
						</>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(NewSubaccountModal);
