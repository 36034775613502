import produce from 'immer';
import { create } from 'zustand';
import { LstMetrics } from '@drift/common';

type LstMetricsLookup = Record<number, LstMetrics>;

export interface LstMetricsStore {
	set: (x: (s: LstMetricsStore) => void) => void;
	get: () => LstMetricsStore;
	setLstMetrics: (marketIndex: number, lstMetrics: LstMetrics) => void;
	bulkSetLstMetrics: (
		props: [marketIndex: number, lstMetrics: LstMetrics][]
	) => void;
	getLstMetricsForMarket: (marketIndex: number) => LstMetrics;
	lstMetrics: LstMetricsLookup;
}

const useLstMetricsStore = create<LstMetricsStore>(
	(set, get): LstMetricsStore => {
		const setState = (fn) => set(produce(fn));

		const setLstMetrics = (marketIndex: number, lstMetrics: LstMetrics) => {
			setState((state) => {
				state.lstMetrics[marketIndex] = lstMetrics;
			});
		};

		const bulkSetLstMetrics = (
			props: [marketIndex: number, lstMetrics: LstMetrics][]
		) => {
			setState((state) => {
				for (const [marketIndex, lstMetrics] of props) {
					state.lstMetrics[marketIndex] = lstMetrics;
				}
			});
		};

		return {
			set: setState,
			get: () => get(),
			setLstMetrics,
			getLstMetricsForMarket: (marketIndex: number) =>
				get().lstMetrics[marketIndex],
			bulkSetLstMetrics,
			lstMetrics: {},
		};
	}
);

export default useLstMetricsStore;
