'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Button from 'src/components/Button';
import useAccountData from 'src/hooks/useAccountData';
import { PublicKey } from '@drift-labs/sdk';
import Text from 'src/components/Text/Text';
import {
	UserSettings,
	isDev,
} from '../../environmentVariables/EnvironmentVariables';
import SettingsSwitch from './SettingsSwitch';
import Select from '../Inputs/Select';
import Utility from '../Inputs/Utility';
import UIHelpTextLink from '../UIHelpTextLink';

type OtherSettingsProps = {
	localSettings: UserSettings;
	toggleSetting: (key: keyof UserSettings) => void;
	updateSettings: (settings: UserSettings) => void;
};

const OtherSettings = ({
	localSettings,
	toggleSetting,
	updateSettings,
}: OtherSettingsProps) => {
	const userAuthority = useAccountData()?.authority ?? PublicKey.default;
	const set = useDriftStore((s) => s.set);

	const isDevEnv = isDev();

	return (
		<div className="flex flex-col space-y-4">
			<div className="flex items-center justify-between">
				<Text.BODY2 className="text-text-label">
					Download Historical Data
				</Text.BODY2>
				<Button.Secondary
					size="SMALL"
					onClick={() => {
						set((s) => {
							s.modals.showSettingsModal = false;
							s.modals.showDownloadModal = true;
						});
					}}
					disabled={!userAuthority}
				>
					Download
				</Button.Secondary>
			</div>

			<SettingsSwitch
				key={`versioned_tx_toggle`}
				label={'Enable Versioned Transactions'}
				checked={localSettings.enableVersionedTx}
				onChange={() => {
					toggleSetting('enableVersionedTx');
				}}
				tooltipContent={
					<div className="flex flex-col">
						<span>
							It is recommended to use versioned transactions, although some
							wallets may not support them - in which case you should turn them
							off.
						</span>
						<UIHelpTextLink
							href="https://docs.drift.trade/trading/versioned-transactions"
							text="Learn more about versioned transactions"
							className="mt-2"
						/>
					</div>
				}
			/>

			<div className="flex items-center justify-between">
				<Text.BODY2 className="text-text-label">Preferred Explorer</Text.BODY2>

				<Select.Default
					id="explorer_selector"
					currentSelection={localSettings.explorer}
					options={[
						{
							value: 'solana',
							label: 'Solana Explorer',
						},
						{
							value: 'solscan',
							label: 'Solscan',
						},
						{
							value: 'xray',
							label: 'XRAY',
						},
						{
							value: 'solanafm',
							label: 'Solana FM',
						},
					]}
					onChange={(value) => {
						updateSettings({
							...localSettings,
							// @ts-ignore
							explorer: value,
						});
					}}
					optionsClassName="whitespace-nowrap"
					placement="bottom-end"
				/>
			</div>

			{isDevEnv && (
				<>
					<SettingsSwitch
						includeLineSpace={false}
						label="Use Mainnet"
						checked={localSettings.mainnetOverride}
						onChange={() => {
							toggleSetting('mainnetOverride');
						}}
					/>
					<SettingsSwitch
						label="Use Staging History Server"
						checked={localSettings.stagingHistoryServerOverride}
						onChange={() => {
							toggleSetting('stagingHistoryServerOverride');
						}}
					/>
				</>
			)}

			<Utility.VERTSPACERXS />
			{/* Temp remove until we change the logic to play nice with db stored settings */}
			{/* <Utility.VERTDIVIDER />
			<div className="flex items-center justify-between">
				<div className="flex flex-col space-y-1">
					<Text.BODY2>Clear Local Storage</Text.BODY2>
					<Text.MICRO1>
						This will clear all local settings including saved Drift settings,
						Tradingview Charts, and more.
					</Text.MICRO1>
				</div>

				<Button.Secondary
					size="SMALL"
					onClick={() => {
						localStorage.clear();
						notify({
							type: 'success',
							updatePrevious: true,
							message: `Cache Cleared`,
							id: 'cache_cleared',
						});
					}}
				>
					Clear
				</Button.Secondary>
			</div> */}
		</div>
	);
};

export default OtherSettings;
