'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	MAX_LEVERAGE_ORDER_SIZE,
	MarketType,
	PerpMarketAccount,
	PositionDirection,
} from '@drift-labs/sdk';
import { useEffect, useMemo } from 'react';
import { useDebounce } from 'react-use';
import Env, {
	DEFAULT_MARKET_AUCTION_DURATION,
	syncGetCurrentSettings,
} from '../../environmentVariables/EnvironmentVariables';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { validateMarketOrderParams } from '../../utils/invariants';
import { notify } from '../../utils/notifications';
import useDevSwitchIsOn from '../useDevSwitchIsOn';
import { COMMON_UI_UTILS } from '@drift/common';
import { dlog } from '../../dev';
import { DriftWindow } from '../../window/driftWindow';
import useGetOraclePriceForMarket from '../useGetOraclePriceForMarket';
import useMarkPrice from '../useMarkPrice';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import UI_UTILS from 'src/utils/uiUtils';

const useDevInvariants = () => {
	const devSwitchIsOn = useDevSwitchIsOn();
	const isUsingDevnet = Env.sdkEnv === 'devnet';

	const invariantHooksEnabled =
		devSwitchIsOn ||
		isUsingDevnet ||
		process.env.NEXT_PUBLIC_ISLOCAL === 'true';

	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const marketIndex = selectedMarket?.market?.marketIndex;
	const marketId = selectedMarket.marketId;
	const direction = useDriftStore((s) =>
		s.tradeForm.side === 'buy'
			? PositionDirection.LONG
			: PositionDirection.SHORT
	);
	const baseSizeStringValue = useDriftStore(
		(s) => s.tradeForm.baseSizeStringValue
	);

	const orderType = useDriftStore((s) => s.tradeForm.orderType);

	const baseAmount = useMemo(
		() => BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP).val,
		[baseSizeStringValue]
	);
	const maxLeverageSelected = useDriftStore(
		(s) => s.tradeForm.maxLeverageSelected
	);
	const reduceOnly = useDriftStore((s) => s.tradeForm.reduceOnly);
	const { bestPrice, entryPrice, worstPrice } = useDriftStore(
		(s) => s.tradeForm.priceImpact
	);
	const slippageTolerance = useDriftStore((s) => s.tradeForm.slippageTolerance);
	const allowInfSlippage = slippageTolerance == undefined;

	const getOraclePrice = useGetOraclePriceForMarket();
	const oraclePrice = getOraclePrice(marketId);
	const markPrice = useMarkPrice(marketId);

	const getMarketInfo = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);
	const marketAccount = getMarketInfo(
		marketId.marketIndex,
		marketId.marketType
	)?.account;

	// INVARIANT: MARKET ORDER PARAMS
	useDebounce(
		() => {
			if (!invariantHooksEnabled) return;
			if (!oraclePrice) return;
			if (!baseSizeStringValue) return;

			const settings = syncGetCurrentSettings();

			if (orderType === 'market' || orderType === 'oracle') {
				// Check the market params invariants hold
				const marketOrderParams = COMMON_UI_UTILS.deriveMarketOrderParams({
					marketType: selectedMarket?.isPerp
						? MarketType.PERP
						: MarketType.SPOT,
					marketIndex,
					direction,
					baseAmount: maxLeverageSelected
						? MAX_LEVERAGE_ORDER_SIZE
						: baseAmount,
					reduceOnly,
					allowInfSlippage,
					worstPrice,
					auctionDuration:
						settings?.auctionDuration ?? DEFAULT_MARKET_AUCTION_DURATION,
					auctionStartPriceOffset: settings?.auctionStartPriceOffset,
					auctionEndPriceOffset: settings?.auctionEndPriceOffset,
					maxLeverageSelected,
					maxLeverageOrderSize: MAX_LEVERAGE_ORDER_SIZE,
					oraclePrice: oraclePrice.val,
					markPrice: markPrice?.val,
					bestPrice,
					entryPrice,
					auctionStartPriceOffsetFrom: settings?.auctionStartPriceOffsetFrom,
					auctionEndPriceOffsetFrom: settings?.auctionEndPriceOffsetFrom,
					slippageTolerance,
					isOracleOrder: settings?.oracleOffsetOrdersEnabled,
					additionalEndPriceBuffer: UI_UTILS.getAdditionalEndPriceBuffer(
						marketAccount as PerpMarketAccount
					),
				});

				const result = validateMarketOrderParams(marketOrderParams);

				if (!result) {
					dlog(`market_params_invariant_breach`, `caught_order_params_breach`, {
						orderParams: marketOrderParams,
					});
					notify({
						type: 'warning',
						message: 'Caught Auction Params Invariant Breach',
						description: `If you submitted a market order for the current trade form state it would result in bad auction params, should debug this`,
					});
				}
			}
		},
		1000,
		[oraclePrice, baseSizeStringValue, selectedMarket, orderType]
	);

	useEffect(() => {
		if (!invariantHooksEnabled) return;

		if (!DriftWindow.metricsBus) {
			// allow time buffer for metrics bus to be initialised
			setTimeout(() => {
				DriftWindow.metricsBus?.subscribe((message) => {
					switch (message.type) {
						case 'dev_invariant': {
							notify({
								type: 'warning',
								message: 'Dev Invariant Breach',
								description: message.value,
							});
						}
					}
				});
			}, 1000);
		}
	}, [invariantHooksEnabled]);
};

export default useDevInvariants;
