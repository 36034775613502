'use client';

import { EyeShow, Share } from '@drift-labs/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDateDisplayState from 'src/hooks/useDateDisplayState';
import useIsMobile from 'src/hooks/useIsMobileScreenSize';
import {
	FundingHistoryData,
	fundingHistorySelector,
} from 'src/selectors/fundingHistorySelector';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';
import useShowAccountValues from '../hooks/useShowAccountValues';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import InlineLoadingBar from './InlineLoadingBar/InlineLoadingBar';
import PaginatedTableV2 from './PaginatedTableV2';
import TableV2 from './Tables/TableV2';
import Tooltip from './Tooltip/Tooltip';
import Text from './Text/Text';
import useFundingHistory from 'src/hooks/useFundingHistory';

const pageSize = 20;
const tableGrid =
	'minmax(100px,1fr) minmax(100px,1fr) minmax(100px,1fr) minmax(100px,1fr) minmax(100px,1fr) minmax(40px,.5fr)';
const mobileGrid = '1.4fr 1.2fr 1.1fr';
const headers = ['market', 'direction', 'position size', 'payment', 'date', ''];
const mobileHeaders = ['market', 'payment', 'date'];

const FundingHistoryTable = () => {
	useFundingHistory();

	const setState = useDriftAccountStore((s) => s.set);
	const currentAccountUserKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.userKey
	);
	const currentAccountName = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.name
	);
	const currentAccountPubKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.pubKey
	);
	const currentAccountFundingHistory = useDriftAccountStore(
		(s) => s.getCurrentUserAccount()?.fundingHistory
	);
	const lastUserKey = useRef(currentAccountUserKey ?? '');
	const { displayDatesAsTimestamp, toggleDateDisplayType } =
		useDateDisplayState();

	const fundingHistory: FundingHistoryData[] = useMemo(() => {
		return currentAccountFundingHistory?.initialHistoryLoaded
			? fundingHistorySelector(
					currentAccountFundingHistory.records,
					currentAccountName
			  )
			: undefined;
	}, [
		currentAccountFundingHistory?.records,
		currentAccountName,
		currentAccountFundingHistory?.initialHistoryLoaded,
	]);
	const totalHistoryCount = currentAccountFundingHistory?.totalCount ?? 0;

	const [currentFundingHistory, setCurrentFundingHistory] = useState(
		fundingHistory?.slice(0, pageSize) ?? undefined
	);

	const historyIsLoading = currentAccountFundingHistory?.loading ?? false;

	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		if (fundingHistory && fundingHistory.length > 0) {
			setCurrentFundingHistory(fundingHistory.slice(0, pageSize));
		}
	}, [fundingHistory]);

	useEffect(() => {
		if (lastUserKey.current !== currentAccountUserKey) {
			resetState();
			lastUserKey.current = currentAccountUserKey;
		}
	}, [currentAccountUserKey]);

	const resetState = () => {
		setCurrentPage(0);
		setCurrentFundingHistory([]);
	};

	const isConnected = useWalletIsConnected({
		onDisconnectedCallback: resetState,
	});

	const setUserFundingHistoryLoading = (isLoading: boolean) => {
		setState((s) => {
			s.accounts[currentAccountUserKey].fundingHistory.loading = isLoading;
		});
	};

	const executePageChange = (newPage: number) => {
		if (newPage === currentPage) return;

		if (fundingHistory.length < pageSize * (newPage + 1) || newPage < 0) {
			setUserFundingHistoryLoading(true);

			const { beforeTs, afterTs } = UI_UTILS.getBeforeOrAfterTs(
				currentFundingHistory,
				currentPage,
				newPage
			);

			ExchangeHistoryClient.getFundingForUsers(
				[currentAccountPubKey],
				newPage,
				currentPage,
				beforeTs,
				afterTs
			).then((result) => {
				const newRecords = fundingHistorySelector(
					result?.body?.data?.records?.[0],
					currentAccountName
				);

				setCurrentFundingHistory(newRecords);
				setUserFundingHistoryLoading(false);

				const totalCount = result?.body?.data?.totalCounts?.[0];
				const isJumpToLast = newPage < 0;

				// if going directly to last page, derive the page number from count
				setCurrentPage(
					isJumpToLast ? UI_UTILS.getPageNumForCount(totalCount) : newPage
				);
			});
		} else {
			setCurrentFundingHistory(
				fundingHistory.slice(newPage * pageSize, newPage * pageSize + pageSize)
			);

			setCurrentPage(newPage);
		}
	};

	const isMobile = useIsMobile();

	const showAccountValues = useShowAccountValues();

	return (
		<PaginatedTableV2
			noBorder
			tableGrid={isMobile ? mobileGrid : tableGrid}
			pageSize={pageSize}
			currentPage={currentPage}
			pageChangeCallback={executePageChange}
			totalItems={totalHistoryCount}
			tableState={{
				records: fundingHistory,
				emptyStateText: 'No Funding History found',
				pageChangeLoading: historyIsLoading,
				id: 'funding_history_table',
				requireAccountCreated: true,
				requireWalletConnect: true,
			}}
			tableContentTop={
				<TableV2.HeaderRow
					grid={isMobile ? mobileGrid : tableGrid}
					className="w-full text-xs"
					header
					forceBottomBorder
					lastColumnJustify="start"
				>
					{(isMobile ? mobileHeaders : headers).map((label, index) => {
						return label === 'market funding rate' ? (
							<TableV2.TooltipHeaderCell key={label}>
								<Tooltip
									placement="top"
									content={
										<div>
											Average funding paid/collected per interval over the
											unrealized duration.
										</div>
									}
								>
									{label}
								</Tooltip>
							</TableV2.TooltipHeaderCell>
						) : label === 'direction' ? (
							<TableV2.TooltipHeaderCell key={label}>
								<Tooltip
									placement="top"
									content={<div>Position direction at time of payment</div>}
								>
									{label}
								</Tooltip>
							</TableV2.TooltipHeaderCell>
						) : label === 'position size' ? (
							<TableV2.TooltipHeaderCell key={label}>
								<Tooltip
									placement="top"
									content={
										<div>Position size at the time of funding payment</div>
									}
								>
									{label}
								</Tooltip>
							</TableV2.TooltipHeaderCell>
						) : label === 'payment' ? (
							<TableV2.TooltipHeaderCell key={label}>
								<Tooltip placement="top" content={<div>Total payment</div>}>
									{label}
								</Tooltip>
							</TableV2.TooltipHeaderCell>
						) : label.toLowerCase() === 'date' ? (
							<TableV2.TooltipHeaderCell key={label}>
								<Tooltip
									placement="top"
									content={
										<div>Time funding payment/collection was realized.</div>
									}
								>
									<div
										onClick={toggleDateDisplayType}
										className={'cursor-pointer flex items-center gap-1'}
									>
										<span>{label}</span>
										<EyeShow size={16} />
									</div>
								</Tooltip>
							</TableV2.TooltipHeaderCell>
						) : (
							// : label === 'market' ? (
							// 	// Market Filter
							// 	<Select.NoBox
							// 		id="tradeHistoryMarketFilter"
							// 		options={[
							// 			{
							// 				label: 'ALL',
							// 				value: '',
							// 			},
							// 			...CurrentPerpMarkets.map((market) => ({
							// 				label: market.symbol,
							// 				value: market.symbol,
							// 			})),
							// 		]}
							// 		selection={selectedMarketFilter}
							// 		onChange={(value) =>
							// 			handleMarketFilterUpdate(value)
							// 		}
							// 		noStyle
							// 		defaultLabel="Market"
							// 		style={{ letterSpacing: '2px' }}
							// 		maxHeight={160}
							// 	/>
							// )
							<TableV2.HeaderCell key={`funding_header_${index}`}>
								{label}
							</TableV2.HeaderCell>
						);
					})}
				</TableV2.HeaderRow>
			}
			tableContentMiddle={
				historyIsLoading ? (
					<div
						className={`flex flex-col justify-center items-center xs:h-[40vh] sm:h-full w-full text-center rounded-md xs:pt-8 sm:pt-0`}
					>
						<InlineLoadingBar />
					</div>
				) : (
					<div className="flex flex-col w-full xs:h-[40vh] h-full">
						{isConnected &&
							UI_UTILS.sortRecordsByTs(currentFundingHistory)
								// .filter((payment) =>
								// 	payment.market.includes(selectedMarketFilter)
								// )
								?.map((payment, index) => (
									<TableV2.BodyRow
										key={`${index}_${payment.market}`}
										grid={isMobile ? mobileGrid : tableGrid}
										lastColumnJustify="start"
										isDataRow
									>
										<TableV2.MarketCell
											key={`market_${index}`}
											marketSymbol={`${payment.market}-PERP`}
										/>
										{!isMobile && (
											<TableV2.BodyCell
												key={`direction_${index}`}
												className={
													payment.baseAssetAmount.isNeg()
														? `text-negative-red`
														: `text-positive-green`
												}
											>
												{payment.baseAssetAmount.isNeg() ? 'Short' : 'Long'}
											</TableV2.BodyCell>
										)}

										{!isMobile && (
											<TableV2.BodyCell key={`size_${index}`}>
												{`${
													showAccountValues
														? payment.baseAssetAmount.toTradePrecision()
														: '∗∗∗∗∗'
												} ${payment.market}`}
											</TableV2.BodyCell>
										)}

										<TableV2.AssetCell
											key={`payment_${index}`}
											className={
												payment.size.ltZero()
													? `text-negative-red`
													: `text-positive-green`
											}
											value={payment.size}
											assetSymbol="USDC"
											isAccountValueToHide
											toFixed={4}
											semantic
										/>
										<TableV2.DateCell
											key={`fundingDate_${index}`}
											date={new Date(payment.datetime)}
											displayAsTimestamp={displayDatesAsTimestamp}
										></TableV2.DateCell>
										{!isMobile && (
											<TableV2.BodyCell className="justify-start">
												<div
													className="hover:cursor-pointer hover:opacity-80"
													onClick={() => {
														UI_UTILS.openUrl(
															UI_UTILS.getUrlForTx(payment.txSig)
														);
													}}
												>
													<Tooltip placement="top" content={'View transaction'}>
														<Share color="var(--text-label)" />
													</Tooltip>
												</div>
											</TableV2.BodyCell>
										)}
									</TableV2.BodyRow>
								))}
					</div>
				)
			}
			tableContentBottom={
				!isMobile && (
					<Text.P1 className="flex items-center p-1 text-text-tertiary">
						Only past 3mo of history is shown. Your full history is available
						via export.
					</Text.P1>
				)
			}
		/>
	);
};

export default FundingHistoryTable;
