const currencyPipe = (value: number, symbol = '$', decimals = 2) => {
	if (!value) return '$0.00';
	const prefix = value < 0 ? '-' : '';

	return `${prefix}${symbol}${Math.abs(value).toLocaleString(undefined, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})}`;
};

export default currencyPipe;
