import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	calculateCollateralDepositRequiredForTrade,
	calculateCollateralValueOfDeposit,
	calculateLiquidationPrice,
	PerpMarketAccount,
	PRICE_PRECISION_EXP,
	SpotMarketConfig,
	ZERO,
} from '@drift-labs/sdk';
import { MarketId, UIOrderType } from '@drift/common';
import { ReactNode, useEffect, useState } from 'react';
import { Alert } from 'src/components/Alert';
import Button from 'src/components/Button';
import CheckboxInput from 'src/components/CheckboxInput';
import ConnectWalletButtonsList from 'src/components/ConnectWalletButtonsList';
import {
	CollateralAmountDisplay,
	CollateralSelector,
} from 'src/components/Inputs/CollateralInput';
import Select from 'src/components/Inputs/Select';
import Slider from 'src/components/Slider';
import Text from 'src/components/Text/Text';
import { Typo } from 'src/components/Text/Typo';
import Tooltip from 'src/components/Tooltip/Tooltip';
import TradeFormInputLabel from 'src/components/TradeForm/TradeFormInputLabel';
import { getPerpLiqPrice } from 'src/components/TradeForm/useTradeFormStateEngine';
import Env, {
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import {
	AccountCreationCostBreakdown,
	useAccountCreationCost,
} from 'src/hooks/useAccountCreationCost';
import useAccountExists from 'src/hooks/useAccountExists';
import useCurrentPerpMarketAccount from 'src/hooks/useCurrentPerpMarketAccount';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import useDriftActions from 'src/hooks/useDriftActions';
import useHandleSelectWalletProvider from 'src/hooks/useHandleSelectWalletProvider';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useMarketInfoDisplayData from 'src/hooks/useMarketInfoDisplayData';
import { useTradeformPriceImpact } from 'src/hooks/usePriceImpact';
import { useSyncWalletBalances } from 'src/hooks/useSyncWalletBalances';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import NumLib from 'src/utils/NumLib';
import Modal from './Modal';
import { useCollateralValueRequiredForTrade } from 'src/components/TradeForm/useCollateralValueRequiredForTrade';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import useMarketMaxLeverage from 'src/hooks/useMarketMaxLeverages';
import useHighLeverageSpotsOpen from 'src/hooks/useHighLeverageSpotsOpen';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import useMarketStateStore from '../../stores/useMarketStateStore';

type DepositToTradeModalState = {
	// Trade State
	targetTradeMarketId: MarketId;
	targetTradeSizeString: string;
	targetTradeSizeBigNum: BigNum;
	targetTradePriceString: string;
	targetTradeSide: 'buy' | 'sell';
	targetTradeMarketAccount: PerpMarketAccount;
	targetTradeOrderType: UIOrderType;
	targetTradeMarketOraclePrice: BigNum;
	isNewAccount: boolean;

	// Action Handlers
	onSubmit: () => void;

	// Account Selection Input
	selectedAccountKey: string;
	setSelectedAccountKey: (accountKey: string) => void;

	// Collateral Input
	selectedDepositCollateral: SpotMarketConfig;
	setSelectedDepositCollateral: (collateral: SpotMarketConfig) => void;

	// Leverage Input
	leverageSelected: number;
	setLeverageSelected: (leverage: number) => void;

	// Accepted Terms
	acceptedTerms: boolean;
	setAcceptedTerms: (accepted: boolean) => void;

	// Derived Values
	minCollateralRequired: BigNum;
	targetCollateralRequiredWithLeverageSelected: number;
	targetMarketMaxLeverage: number;
	estimatedLiqPrice: number;
	selectedLeverageMinRequirementMultiplier: number;
	currentUserAccountHasEnoughCollateralForTrade: boolean;
	freeCollateral: number;
	walletHasEnoughSolForNewAccount: boolean;
	walletHasEnoughBalanceForDeposit: boolean;
	submitButtonDiabled: boolean;
	accountCreationCostBreakdown: AccountCreationCostBreakdown;
	isPredictionMarket: boolean;
};

const getDefaultLeverage = (isPredictionMarket: boolean) => {
	return isPredictionMarket ? 1 : 1; // Doing this so that someone doesn't break prediction market modal if we want to change the default leverage for regular markets
};

/**
 * Hook to track all state of the deposit to trade modal in a central place.
 */
const useDepositToTradeModalState = (): DepositToTradeModalState => {
	const targetTradeMarket = useDriftStore((s) => s.selectedMarket);
	const targetTradeMarketId = targetTradeMarket.marketId;
	const targetTradeMarketAccount = useCurrentPerpMarketAccount();
	const isPredictionMarket = targetTradeMarket.current.isPredictionMarket;

	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const { captureEvent } = usePostHogCapture();

	const [awaitingTx, setAwaitingTx] = useState(false);

	// # Input States
	const [selectedAccountKey, setSelectedAccountKey] =
		useState<string>(currentUserKey);
	const [selectedDepositCollateral, setSelectedDepositCollateral] =
		useState<SpotMarketConfig>(SPOT_MARKETS_LOOKUP[0]);
	const [leverageSelected, setLeverageSelected] = useState(
		getDefaultLeverage(isPredictionMarket)
	);
	const [acceptedTerms, setAcceptedTerms] = useState(false);

	const selectedUserId = useDriftAccountStore(
		(s) => s.accounts[selectedAccountKey]?.userId
	);

	// # Base Stuff
	const driftClient = useDriftStore((s) => s.driftClient.client);

	const userClient = useDriftAccountStore(
		(s) => s.accounts[selectedAccountKey ?? s.currentUserKey]?.client
	);

	const actions = useDriftActions();

	const currentUserAccountHasEnoughCollateralForTrade =
		useCurrentUserAccountHasEnoughCollateralForTrade(selectedAccountKey);

	const freeCollateral = useFreeCollateral(selectedAccountKey);

	const walletSolBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const currentWalletCollateral = useWalletBalancesStore(
		(s) => s.balances[selectedDepositCollateral.symbol]?.base
	);

	const accountCreationCostBreakdown = useAccountCreationCost();

	const hideModal = () => {
		actions.showModal('showDepositToTradeModal', false);
	};

	// # Trade State
	const targetTradeSizeString = useDriftStore(
		(s) => s.tradeForm.baseSizeStringValue
	);
	const targetTradePriceString = useDriftStore(
		(s) => s.tradeForm.priceBoxStringValue
	);
	const targetTradeSizeBigNum = BigNum.fromPrint(
		targetTradeSizeString,
		BASE_PRECISION_EXP
	);
	const targetTradeSide = useDriftStore((s) => s.tradeForm.side);
	const targetTradeUIMarket = useDriftStore((s) => s.selectedMarket.current);
	const targetTradeOrderType = useDriftStore((s) => s.tradeForm.orderType);
	const getOraclePrice = useGetOraclePriceForMarket();
	const targetTradeMarketOraclePrice = getOraclePrice(targetTradeMarketId);
	const targetTradeMarketMarkPrice = useMarketStateStore((s) =>
		s.getMarkPriceForMarket(targetTradeMarketId)
	);
	const estEntryPrice = useDriftStore(
		(s) => s.tradeForm.priceImpact.entryPrice
	);

	const priceImpactInfo = useTradeformPriceImpact();

	// # Derived Values
	//// Collateral Required
	const minCollateralValueRequiredForTrade =
		useCollateralValueRequiredForTrade();

	//// Collateral To Deposit
	// const targetMarketMargin = driftClient.getPerpMarketAccount(
	// 	targetTradeMarketId.marketIndex
	// ).marginRatioInitial;

	const { maxLeverage, highLeverageMaxLeverage, isHighLeverage } =
		useMarketMaxLeverage(
			targetTradeMarketId.marketType,
			targetTradeMarketId.marketIndex
		);

	const { highLeverageSpotsOpen } = useHighLeverageSpotsOpen();

	const allowHighLeverage =
		isHighLeverage ||
		(highLeverageMaxLeverage > 0 && highLeverageSpotsOpen > 0);

	const targetMarketMaxLeverage = allowHighLeverage
		? highLeverageMaxLeverage
		: maxLeverage;

	const selectedLeverageMinRequirementMultiplier =
		targetMarketMaxLeverage / leverageSelected;

	// Max leverage is selected if the selected leverage is within 1% of the max leverage
	const maxLeverageSelected =
		Math.abs(1 - leverageSelected / targetMarketMaxLeverage) < 0.01;

	const minTargetCollateralRequired =
		calculateCollateralDepositRequiredForTrade(
			driftClient,
			targetTradeMarketId.marketIndex,
			targetTradeSizeBigNum.val,
			selectedDepositCollateral?.marketIndex ?? 0,
			userClient?.getUserAccount().maxMarginRatio,
			isHighLeverage || leverageSelected > maxLeverage,
			estEntryPrice
		);

	const collateralDecimals = driftClient.getSpotMarketAccount(
		selectedDepositCollateral?.marketIndex ?? 0
	).decimals;

	const offsetCollateral = minCollateralValueRequiredForTrade.val
		.muln(selectedLeverageMinRequirementMultiplier * 1e4)
		.divn(1e4);

	const minCollateralRequiredBigNum = BigNum.from(
		minTargetCollateralRequired,
		collateralDecimals
	);

	const collateralToDepositBigNum = minCollateralRequiredBigNum.scalarMul(
		new BigNum(selectedLeverageMinRequirementMultiplier * 1e4, 4)
	);

	const collateralValueOfDeposit = calculateCollateralValueOfDeposit(
		driftClient,
		selectedDepositCollateral.marketIndex,
		collateralToDepositBigNum.val
	);

	const targetCollateralRequiredWithLeverageSelected =
		collateralToDepositBigNum.toNum();

	const walletHasEnoughSolForNewAccount = walletSolBalance.gte(
		accountCreationCostBreakdown.minSolToDeposit
	);

	const walletHasEnoughBalanceForDeposit = currentWalletCollateral
		? currentWalletCollateral.toNum() >=
		  targetCollateralRequiredWithLeverageSelected
		: false; // If current selected wallet collateral hasn't loaded yet then assume there is enough balance / don't show the warning

	//// Estimated Liquidation Price
	const estimatedEntryPrice = useDriftStore(
		(s) => s.tradeForm.priceImpact.entryPrice
	);

	const estimatedLiqPrice = minCollateralValueRequiredForTrade.val.eq(ZERO)
		? undefined
		: userClient
		? getPerpLiqPrice(
				estimatedEntryPrice,
				targetTradeOrderType,
				targetTradeMarketAccount,
				targetTradeSizeString,
				targetTradePriceString,
				targetTradeSide === 'buy',
				userClient,
				offsetCollateral
		  )
		: BigNum.from(
				calculateLiquidationPrice(
					collateralValueOfDeposit,
					minCollateralValueRequiredForTrade.val,
					targetTradeMarketOraclePrice.val
				),
				PRICE_PRECISION_EXP
		  ).toNum();

	const isNewAccount = !userClient;

	const submitButtonDiabled =
		(isNewAccount && !walletHasEnoughSolForNewAccount) ||
		!walletHasEnoughBalanceForDeposit ||
		!acceptedTerms ||
		awaitingTx;

	// # Action Handlers
	const onSubmit = async () => {
		setAwaitingTx(true);

		try {
			captureEvent('deposit_to_trade_submit', {
				deposit_collateral_market_symbol: selectedDepositCollateral.symbol,
				deposit_amount: collateralToDepositBigNum.toNum(),
				is_new_account: isNewAccount,
				leverage_selected: leverageSelected,
				trade_size: targetTradeSizeBigNum.toNum(),
				trade_price: targetTradePriceString,
				trade_side: targetTradeSide,
				trade_order_type: targetTradeOrderType,
				trade_market_symbol: targetTradeUIMarket.symbol,
			});

			await actions.tryDepositToTrade({
				userId: selectedUserId,
				priceImpactInfo,
				oraclePrice: targetTradeMarketOraclePrice,
				markPrice: targetTradeMarketMarkPrice,
				depositCollateral: selectedDepositCollateral,
				depositAmount: collateralToDepositBigNum,
				isNewAccount: isNewAccount,
				maxLeverageSelected,
				isHighLeverage: leverageSelected > maxLeverage,
				onSuccess: () => {
					hideModal();
				},
				onTxCreated: () => {
					setAwaitingTx(false);
				},
			});

			captureEvent('deposit_to_trade_submit_success');
		} catch (e) {
			captureEvent('deposit_to_trade_submit_error', {
				error: e.message,
				deposit_collateral_market_symbol: selectedDepositCollateral.symbol,
				deposit_amount: collateralToDepositBigNum.toNum(),
				is_new_account: isNewAccount,
				leverage_selected: leverageSelected,
				trade_size: targetTradeSizeBigNum.toNum(),
				trade_price: targetTradePriceString,
				trade_side: targetTradeSide,
				trade_order_type: targetTradeOrderType,
				trade_market_symbol: targetTradeUIMarket.symbol,
			});

			throw e;
		}
	};

	return {
		isNewAccount,
		targetTradeMarketId,
		targetTradeSizeString,
		targetTradeSizeBigNum,
		targetTradeSide,
		targetTradePriceString,
		targetTradeMarketAccount,
		targetTradeOrderType,
		targetTradeMarketOraclePrice,
		onSubmit,
		leverageSelected,
		setLeverageSelected,
		selectedDepositCollateral,
		setSelectedDepositCollateral,
		selectedAccountKey,
		setSelectedAccountKey,
		acceptedTerms,
		setAcceptedTerms,
		minCollateralRequired: minCollateralValueRequiredForTrade,
		targetCollateralRequiredWithLeverageSelected,
		targetMarketMaxLeverage,
		estimatedLiqPrice,
		selectedLeverageMinRequirementMultiplier,
		currentUserAccountHasEnoughCollateralForTrade:
			currentUserAccountHasEnoughCollateralForTrade,
		freeCollateral,
		walletHasEnoughSolForNewAccount,
		walletHasEnoughBalanceForDeposit,
		submitButtonDiabled,
		accountCreationCostBreakdown,
		isPredictionMarket,
	};
};

/**
 * Deposit To Trade:
 * - This modal gets triggered when the user is not connected but they have input a proposed trade and clicked the "submit" button.
 * - This modal needs to guide them through: connecting a wallet, confirming the trade, submitting a single transaction which does the deposit and opens the trade.
 */

enum DepositToTradeStep {
	CONNECT_WALLET,
	CONFIRM_TRADE,
}

function capitalizeWords(input: string): string {
	return input.replace(/\b\w/g, (char) => char.toUpperCase());
}

const DepositToTradeModalHeader = (props: {
	currentStep: DepositToTradeStep;
}) => {
	const tradeFormState = useDriftStore((s) => s.tradeForm);
	const selectedMarket = useMarketInfoDisplayData();

	if (props.currentStep === DepositToTradeStep.CONNECT_WALLET) {
		return 'Connect To Drift';
	}

	const orderType = tradeFormState.orderType;
	const direction = tradeFormState.side;
	const baseSize = tradeFormState.baseSizeStringValue;
	const marketSymbol = selectedMarket.symbol;

	if (props.currentStep === DepositToTradeStep.CONFIRM_TRADE) {
		return capitalizeWords(
			`${orderType} ${
				direction === 'buy' ? 'Long' : 'Short'
			} ${baseSize} ${marketSymbol}`
		);
	}
};

const ConnectionStepContent = () => {
	const { handleSelectProvider } = useHandleSelectWalletProvider(true);

	return (
		<>
			<div>
				<ConnectWalletButtonsList
					showMetamaskIfDetected={false}
					showUndetectedWallets={false}
					showEmail={false}
					onSelectedWallet={(walletName) => {
						handleSelectProvider(walletName);
					}}
				/>
			</div>
		</>
	);
};

/**
 * Calculates how much of a given collateral asset is required to cover the collateral required for the trade and displays that in the UI
 * @param props
 */
const CollatDepositPreview = (props: {
	selectedCollateralMarket: SpotMarketConfig;
	targetCollateralRequiredWithLeverageSelected: number;
}) => {
	const selectedCollateralMarketIndex =
		props?.selectedCollateralMarket?.marketIndex;

	if (selectedCollateralMarketIndex === undefined) {
		return null;
	}

	const targetCollateralRequiredWithLeverageSelected =
		props.targetCollateralRequiredWithLeverageSelected;

	return (
		<div>
			<CollateralAmountDisplay
				label="Amount to Deposit"
				selectedMarket={props.selectedCollateralMarket}
				displayValue={NumLib.formatNum.toBaseDisplay(
					targetCollateralRequiredWithLeverageSelected
				)}
				tooltipText="This is the amount of collateral that will be deposited to cover the trade. This amount is calculated based on the selected trade size, leverage, and collateral."
			/>
		</div>
	);
};

const LeverageInput = ({
	maxLeverage,
	selectedLeverage,
	setSelectedLeverage,
}: {
	maxLeverage: number;
	selectedLeverage: number;
	setSelectedLeverage: (leverage: number) => void;
}) => {
	const minValue = 100 / maxLeverage; // minValue is 1x leverage

	const leverageToSliderValue = (leverage: number) => {
		return (leverage / maxLeverage) * 100;
	};

	const sliderValueToLeverage = (sliderValue: number) => {
		return (maxLeverage * sliderValue) / 100;
	};

	return (
		<div className={'flex flex-col w-full'}>
			<div className="flex w-full mb-3 space-x-2">
				<TradeFormInputLabel className="text-text-label">
					Leverage
				</TradeFormInputLabel>
				<Tooltip
					className="text-text-label"
					content={
						<div className="flex flex-col space-y-2">
							<div>
								{`This market allows a maximum leverage of ${maxLeverage}x. Use
								this slider to determine how much collateral to use to enter this trade with.`}
							</div>
							<div className="text-warn-yellow">
								{`Note: This slider only determines the amount of collateral you will deposit along with your trade. If you already have a Drift account then it does not account for any existing leverage,
								positions, or collateral in your account.`}
							</div>
						</div>
					}
				/>
			</div>
			<Slider
				disabled={false}
				onDrop={(newVal) => {
					setSelectedLeverage(sliderValueToLeverage(newVal));
				}}
				onMove={(newVal) => {
					setSelectedLeverage(sliderValueToLeverage(newVal));
				}}
				step={1}
				value={leverageToSliderValue(selectedLeverage)}
				customButtonOptions={[
					{
						val: minValue,
						label: `1x`,
					},
					{
						val: 25,
						label: `${(maxLeverage * (25 / 100)).toLocaleString(undefined, {
							maximumFractionDigits: 2,
						})}x`,
					},
					{
						val: 50,
						label: `${(maxLeverage * (50 / 100)).toLocaleString(undefined, {
							maximumFractionDigits: 2,
						})}x`,
					},
					{
						val: 75,
						label: `${(maxLeverage * (75 / 100)).toLocaleString(undefined, {
							maximumFractionDigits: 2,
						})}x`,
					},
					{
						val: 100,
						label: `${(maxLeverage * (100 / 100)).toLocaleString(undefined, {
							maximumFractionDigits: 2,
						})}x`,
					},
				]}
				type="leverage"
				displayValue={selectedLeverage.toFixed(2)}
				largeFont
				min={minValue}
				noTrackMarks
			/>
		</div>
	);
};

const DetailValue = ({
	mainValue,
	bracketedValue,
}: {
	mainValue: string;
	bracketedValue?: string;
}) => {
	return (
		<div className="flex space-x-1">
			<Typo.B4 className="text-text-emphasis">{mainValue}</Typo.B4>
			{bracketedValue && (
				<Typo.B4 className="text-text-label">{`(${bracketedValue})`}</Typo.B4>
			)}
		</div>
	);
};

const DetailDisplay = ({
	label,
	value,
	tooltipContent,
}: {
	label: string;
	value: ReactNode;
	tooltipContent?: ReactNode;
}) => {
	return (
		<div className="flex justify-between w-full">
			<div className="flex items-center space-x-2">
				<Typo.B4 className="text-text-label">{label}</Typo.B4>
				{tooltipContent}
			</div>
			{value}
		</div>
	);
};

const DepositAndTradeDetails = ({
	state,
}: {
	state: DepositToTradeModalState;
}) => {
	const limitPrice = state.targetTradePriceString;
	const estimatedLiqPrice = state.estimatedLiqPrice;

	const selectedMarket = useDriftStore((s) => s.selectedMarket);

	const quoteValueOfTrade = state.targetTradeSizeBigNum
		.mul(state.targetTradeMarketOraclePrice)
		.toNum();

	const accountCreationBreakdown = state.accountCreationCostBreakdown;

	const { tradeFee } = useCalculateTradeFee({
		quoteSize: quoteValueOfTrade,
		marketIndex: selectedMarket.marketId.marketIndex,
		marketType: selectedMarket.marketId.marketType,
		isPostOnly: false,
	});

	return (
		<div className="flex flex-col space-y-2">
			<DetailDisplay
				label="Order Size"
				value={
					<DetailValue
						mainValue={`${
							state.targetTradeSizeString
						} ${selectedMarket.current.baseAssetSymbol()}`}
						bracketedValue={NumLib.formatNum.toNotionalDisplay(
							quoteValueOfTrade
						)}
					/>
				}
			/>
			{state.targetTradeOrderType === 'limit' && (
				<DetailDisplay
					label="Limit price"
					value={
						<DetailValue
							mainValue={NumLib.formatNum.toNotionalDisplay(
								Number.parseFloat(limitPrice)
							)}
						/>
					}
				/>
			)}
			<DetailDisplay
				label="Est. liquidation price"
				value={
					<DetailValue
						mainValue={
							estimatedLiqPrice
								? NumLib.formatNum.toNotionalDisplay(estimatedLiqPrice)
								: '-'
						}
					/>
				}
			/>
			<DetailDisplay
				label="Fees"
				value={<DetailValue mainValue={tradeFee.toNotional(true)} />}
			/>
			{state.isNewAccount && (
				<DetailDisplay
					label="New account fee"
					value={
						<DetailValue
							mainValue={`${accountCreationBreakdown.totalCost.prettyPrint(
								false,
								6
							)} SOL`}
							bracketedValue=""
						/>
					}
					tooltipContent={
						<Tooltip
							className="text-text-label"
							content={
								<div className="flex flex-col space-y-3">
									<span className="mb-2">
										Creating a new account costs a small fee in SOL. This fee is
										composed of:
									</span>
									<div className="flex flex-col">
										<Typo.B4 className="mb-1">{`Solana Account Rent: ${accountCreationBreakdown.baseAccountRent.prettyPrint(
											false,
											6
										)} SOL`}</Typo.B4>
										<span>
											{`This covers the Solana Network's rent requirement to host your account on the blockchain.`}
										</span>
									</div>
									<div className="flex flex-col">
										<Typo.B4 className="mb-1">{`Drift Account Rent: ${accountCreationBreakdown.extraRent.prettyPrint(
											false,
											6
										)} SOL`}</Typo.B4>
										<span>
											{`This is a dynamic refundable rent which increases when there is an influx of new Drift accounts. You can reclaim this rent after 13 days of account creation or when you delete your Drift account.`}
										</span>
									</div>
									<div className="flex flex-col">
										<Typo.B4 className="mb-1">{`Drift Account Donation: ${accountCreationBreakdown.newAccountDonation.prettyPrint(
											false,
											6
										)} SOL`}</Typo.B4>
										<span>
											{`A small non-refundable fee charged for account creation.`}
										</span>
									</div>
								</div>
							}
						/>
					}
				/>
			)}
		</div>
	);
};

const SubmitButton = ({
	side,
	disabled,
	onSubmit,
}: {
	side: 'buy' | 'sell';
	disabled: boolean;
	onSubmit: () => void;
}) => {
	return (
		<Button.BigSemantic
			positive={side === 'buy'}
			disabled={disabled}
			onClick={onSubmit}
		>
			<Text.H3 className={`flex items-center justify-center whitespace-normal`}>
				{`Deposit & ${side === 'buy' ? 'Long' : 'Short'}`}
			</Text.H3>
		</Button.BigSemantic>
	);
};

const AccountSelector = ({ state }: { state: DepositToTradeModalState }) => {
	const accoutExists = useAccountExists();
	const availableSubaccounts = useLazySubAccounts();

	if (!accoutExists) return null;
	if (availableSubaccounts.length <= 1) return null;

	return (
		<div className={'flex flex-col w-full'}>
			<div className="flex w-full mb-1 space-x-2">
				<TradeFormInputLabel className="text-text-label">
					Choose Account
				</TradeFormInputLabel>
				<Tooltip
					className="text-text-label"
					content={
						<div className="flex flex-col space-y-2">
							<div>
								{`You already have a Drift Account for this wallet, please choose a subaccount to use for the new trade.`}
							</div>
						</div>
					}
				/>
			</div>
			<Select.Subaccount
				id="depositToTradeAccountSelection"
				onChange={state.setSelectedAccountKey}
				options={availableSubaccounts}
				includeDelegates
				customHeight="h-10"
			/>
		</div>
	);
};

const DepositToTradeInfoMessage = (props: {
	state: DepositToTradeModalState;
}) => {
	if (!props.state.walletHasEnoughBalanceForDeposit) {
		return (
			<Alert
				type="warning"
				message={`Your wallet doesn't have enough collateral.`}
				description={`You need a minimum of ${props.state.targetCollateralRequiredWithLeverageSelected} ${props.state.selectedDepositCollateral.symbol} to cover the currently selected size and leverage of the trade. Try adjusting the leverage slider below or deposit more ${props.state.selectedDepositCollateral.symbol} into your wallet.`}
			/>
		);
	}

	if (
		props.state.isNewAccount &&
		!props.state.walletHasEnoughSolForNewAccount
	) {
		return (
			<Alert
				type="warning"
				message={`Your wallet doesn't have enough SOL to cover the New Account Fees.`}
				description={`You need at least ${props.state.accountCreationCostBreakdown.minSolToDeposit.prettyPrint(
					false,
					6
				)} SOL to cover the New Account Fees. You can see the New Account Fee breakdown below for more information.`}
			/>
		);
	}
};

const CondensedTermsDisplay = (props: { isHighLeverageSelected?: boolean }) => {
	return (
		<div className="w-full h-[100px] overflow-auto flex flex-col space-y-2 border border-2 rounded border-container-border thin-scroll p-2 text-text-label">
			<Text.BODY2>By continuing and using this Protocol:</Text.BODY2>

			<ul className="list-disc list-inside">
				<li className="mb-1">
					<Text.BODY2>
						You understand and agree to the{' '}
						<a href={Env.mainnetTermsUrl} rel="noreferrer" target="_blank">
							Drift Terms and Conditions
						</a>{' '}
						and acknowledge that you have read and understood the{' '}
						<a href={Env.mainnetDisclaimerUrl} rel="noreferrer" target="_blank">
							Drift Protocol Disclaimer
						</a>
						;
					</Text.BODY2>
				</li>

				<li className="mb-1">
					<Text.BODY2 className="font-normal">
						You understand leverage may change after entering a position;
					</Text.BODY2>
				</li>

				<li className="mb-1">
					<Text.BODY2 className="font-normal">
						{`You understand the rules and risks associated with settlement of P&L, bankruptcies, insurance, socialised losses and use of cross collateral as margin;`}
					</Text.BODY2>
				</li>

				<li className="mb-1">
					<Text.BODY2 className="font-normal">
						{`And you understand your account may be partially or entirely liquidated if any position breaches margin maintenance requirements.`}
					</Text.BODY2>
				</li>

				{props.isHighLeverageSelected && (
					<li className="mb-1">
						<Text.BODY2 className="font-normal">
							I understand that enabling higher leverage above 20x increases my
							risk of liquidation
						</Text.BODY2>
					</li>
				)}
			</ul>
		</div>
	);
};

const DepositAndTradeTerms = (props: { state: DepositToTradeModalState }) => {
	return (
		<div className="flex flex-col space-y-4">
			<CondensedTermsDisplay
				isHighLeverageSelected={props.state.leverageSelected > 20}
			/>
			<CheckboxInput
				label={<Typo.B3>I agree to all of the terms above</Typo.B3>}
				checked={props.state.acceptedTerms}
				onChange={() =>
					props.state.setAcceptedTerms(!props.state.acceptedTerms)
				}
				secondaryStyle
				alignStart
			/>
		</div>
	);
};

const ConfirmTradeContent = (props: { state: DepositToTradeModalState }) => {
	const state = props.state;

	const hasEnoughCollateralForTrade =
		state.currentUserAccountHasEnoughCollateralForTrade;
	const freeCollateral = state.freeCollateral;

	const showDebuggingStuff = useDevSwitchIsOn();

	return (
		<>
			{showDebuggingStuff && (
				<>
					<div>isNewAccount: {state.isNewAccount.toString()}</div>
					<div>baseSize: {state.targetTradeSizeBigNum.toNum()}</div>
					<div>
						minCollateralRequiredUSDC: {state.minCollateralRequired.toNum()}
					</div>
					<div>
						depositValueAfterLeverageSelection:{' '}
						{state.minCollateralRequired.toNum() *
							state.selectedLeverageMinRequirementMultiplier}
					</div>
					<div>freeCollateral: {freeCollateral}</div>
					<div>
						currentuserAccountHasEnoughCollateralForTrade:{' '}
						{hasEnoughCollateralForTrade.toString()}
					</div>
					<div>isPredictionMarket: {state.isPredictionMarket.toString()}</div>
				</>
			)}
			<div className="flex flex-col w-full space-y-4">
				<DepositToTradeInfoMessage state={state} />
				<AccountSelector state={state} />
				<CollateralSelector
					label="Choose Collateral"
					onChangeMarket={state.setSelectedDepositCollateral}
				/>
				{!state.isPredictionMarket && (
					<LeverageInput
						maxLeverage={state.targetMarketMaxLeverage}
						selectedLeverage={state.leverageSelected}
						setSelectedLeverage={state.setLeverageSelected}
					/>
				)}

				<CollatDepositPreview
					selectedCollateralMarket={state.selectedDepositCollateral}
					targetCollateralRequiredWithLeverageSelected={
						state.targetCollateralRequiredWithLeverageSelected
					}
				/>
				<DepositAndTradeDetails state={state} />

				<DepositAndTradeTerms state={state} />

				<SubmitButton
					side={state.targetTradeSide}
					disabled={state.submitButtonDiabled}
					onSubmit={state.onSubmit}
				/>
			</div>
		</>
	);
};

const useFreeCollateral = (accountKey?: string) => {
	const currentAccount = useDriftAccountStore(
		(s) => s.accounts[accountKey ?? s.currentUserKey]
	);

	const freeCollateral = NumLib.formatBn.fromQuote(
		currentAccount?.marginInfo?.freeCollateral ?? new BN(0)
	);
	return freeCollateral;
};

const useCurrentUserAccountHasEnoughCollateralForTrade = (
	accountKey?: string
) => {
	const freeCollateral = useFreeCollateral(accountKey);

	const requiredCollateral = useCollateralValueRequiredForTrade();

	return freeCollateral >= requiredCollateral.toNum();
};

const DepositToTradeModal = () => {
	const [currentStep, _setCurrentStep] = useState(
		DepositToTradeStep.CONNECT_WALLET
	);
	const currentWallet = useCurrentWalletAdapter();
	const walletIsConnected = useWalletIsConnected();

	const actions = useDriftActions();
	const setDriftStore = useDriftStore((s) => s.set);

	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);

	const hideModal = () => {
		setDriftStore((s) => {
			s.tradeForm.isInDepositToTradeFlow = false;
		});
		actions.showModal('showDepositToTradeModal', false);
		appEventEmitter.emit('resetTradeForm');
	};

	const handleConnectedWallet = () => {
		_setCurrentStep(DepositToTradeStep.CONFIRM_TRADE);
	};

	useEffect(() => {
		if (currentWallet && walletIsConnected) {
			handleConnectedWallet();
		}
	}, [currentWallet, walletIsConnected]);

	useSyncWalletBalances();

	const state = useDepositToTradeModalState();

	return (
		<Modal onClose={hideModal}>
			<Modal.Body overflow>
				<Modal.Header onClose={hideModal} showX>
					<Modal.Title>
						<DepositToTradeModalHeader currentStep={currentStep} />
					</Modal.Title>
				</Modal.Header>

				<Modal.Content>
					{currentStep === DepositToTradeStep.CONNECT_WALLET ? (
						<ConnectionStepContent />
					) : currentStep === DepositToTradeStep.CONFIRM_TRADE ? (
						<ConfirmTradeContent state={state} />
					) : null}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default DepositToTradeModal;
