'use client';

import BorrowModalContent from 'src/components/BorrowModalContent';
import DepositModalContent from 'src/components/DepositModalContent';
import Text from 'src/components/Text/Text';
import TransferCollateralModalContent from 'src/components/TransferCollateralModalContent';
import WithdrawalModalContent from 'src/components/WithdrawModalContent';
import React, { useState } from 'react';
import Modal from './Modal';
import useAccountData from 'src/hooks/useAccountData';
import SwapOrBridgeCta from '../SwapOrBridgeCta';
import useIsMobileDevice from 'src/hooks/useIsMobileDevice';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useSyncWalletBalances } from 'src/hooks/useSyncWalletBalances';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { MAIN_POOL_ID } from '@drift/common';

type DepositSide = 'deposit' | 'withdraw' | 'borrow' | 'manage';

const CollateralModal = ({
	onClose,
	id,
	initialSide = 'deposit',
}: {
	onClose: () => void;
	id?: string;
	initialSide?: DepositSide;
}) => {
	const [currentSelection, setCurrentSelection] = useState(initialSide);
	const isDelegateAccount = useAccountData()?.isDelegatedTo ?? false;
	const isMobile = useIsMobileDevice();
	const isMobileSize = useIsMobileScreenSize();
	const setDriftStore = useDriftStore((s) => s.set);
	const setDrawersState = useDrawersStore((s) => s.set);
	const poolId = useDriftStore((s) => s.modalCollateralPoolId);
	useSyncWalletBalances();

	const onOpenBridgePage = () => {
		onClose();

		setDrawersState((s) => {
			s.accounts.show = false;
		});
	};

	return (
		<Modal
			onClose={onClose}
			id={id}
			sizeConfig={{ xs: 12, sm: 8, md: 6, xl: 4 }}
		>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>Manage Balances</Text.H2>
				</Modal.Header>

				<Modal.Navigation
					currentSelection={currentSelection}
					onChange={(selection) => {
						setCurrentSelection(selection as DepositSide);
						if (selection === 'withdraw') {
							setDriftStore((s) => {
								s.modalsProps['showWithdrawModal'] = {
									defaultMax: true,
								};
							});
						}
					}}
					options={[
						{
							value: 'deposit',
							label: 'Deposit',
							disabled: isDelegateAccount,
						},
						{
							value: 'withdraw',
							label: 'Withdraw',
							disabled: isDelegateAccount,
						},
						{ value: 'borrow', label: 'Borrow', disabled: isDelegateAccount },
						{
							value: 'manage',
							label: 'Transfer',
							disabled: isDelegateAccount || poolId !== MAIN_POOL_ID,
						},
					]}
				/>

				{currentSelection === 'deposit' ? (
					<DepositModalContent onClose={onClose} />
				) : currentSelection === 'withdraw' ? (
					<WithdrawalModalContent onClose={onClose} />
				) : currentSelection === 'borrow' ? (
					<BorrowModalContent onClose={onClose} />
				) : (
					<TransferCollateralModalContent onClose={onClose} />
				)}
			</Modal.Body>
			{currentSelection === 'deposit' && !isMobile && !isMobileSize && (
				<SwapOrBridgeCta onClose={onOpenBridgePage} />
			)}
		</Modal>
	);
};

export default React.memo(CollateralModal);
