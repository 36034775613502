'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useWeb3Modal } from '@web3modal/solana/react';
import { useState } from 'react';
import { AccountDrawerTab } from 'src/@types/types';
import Drawer from 'src/components/Drawer';
import MarginAccountButton from 'src/components/MarginAccountButton';
import UnderlinedTabs from 'src/components/TabbedNavigation/UnderlinedTabs';
import { APPKIT_SOCIAL_WALLET_PROVIDER_NAME } from 'src/constants/wallets';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { TIPLINK_WALLET_NAME } from 'src/hooks/useWalletsToDisplay';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import AccountGeneralInformation from './AccountGeneralInformation';
import BridgeTab from './BridgeTab';
import SubaccountsTab from './SubaccountsTab';
import WalletBalancesTab from './WalletBalancesTab';

export default function AccountSidebarModal() {
	const isMobile = useIsMobileScreenSize();
	const defaultTab = useDrawersStore((s) => s.accounts.defaultTab);
	const [selectedTab, setSelectedTab] = useState(
		defaultTab ?? AccountDrawerTab.Subaccounts
	);
	const handleCloseDrawer = useDrawersStore((s) => s.handleCloseDrawer);
	const { open: openAppKitModal } = useWeb3Modal();

	// Wallet states
	const wallet = useDriftStore((s) => s.wallet);
	const isMagicLinkWallet = wallet.isMagicAuth;
	const isMetamask = wallet.isMetamask;
	const isTiplink = wallet.current.adapter.name === TIPLINK_WALLET_NAME;
	const isAppKitWallet = useDriftStore((s) => s.wallet.isAppKit);
	const isSocialAppKitWallet =
		isAppKitWallet &&
		wallet?.current?.adapter?.name === APPKIT_SOCIAL_WALLET_PROVIDER_NAME;
	const isExternalWallet =
		isMagicLinkWallet || isMetamask || isTiplink || isSocialAppKitWallet;

	// Total Wallet Balances
	const balances = CurrentSpotMarkets.map((market) =>
		useCurrentWalletCollateralBalance(market)
	);

	const getOraclePrice = useGetOraclePriceForMarket();

	const oraclePrices = CurrentSpotMarkets.map(
		(bank) =>
			getOraclePrice(MarketId.createSpotMarket(bank.marketIndex)) ??
			BigNum.zero(PRICE_PRECISION_EXP)
	);

	const isBalancesLoading = balances.some((balance) => balance[1]);
	const totalWalletQuoteBalances = balances.reduce((sum, balance, i) => {
		const oraclePrice = oraclePrices[i];
		const quoteValue = balance[0]
			.shiftTo(PRICE_PRECISION_EXP)
			.mul(oraclePrice)
			.shiftTo(QUOTE_PRECISION_EXP);

		return sum.add(quoteValue);
	}, BigNum.zero(QUOTE_PRECISION_EXP));

	const SIDEBAR_TABS = [
		{
			label: 'Subaccounts',
			value: AccountDrawerTab.Subaccounts,
		},
	]
		.concat(
			isExternalWallet
				? [
						{
							label: 'Wallet',
							value: AccountDrawerTab.Wallet,
						},
				  ]
				: []
		)
		.concat(
			isMobile
				? []
				: [
						{
							label: 'Bridge',
							value: AccountDrawerTab.Bridge,
						},
						// {
						// 	title: 'Buy Crypto',
						// 	value: SidebarTab.BuyCrypto,
						// },
				  ]
		);

	const onClose = () => {
		handleCloseDrawer('accounts');
	};

	const renderTab = () => {
		switch (selectedTab) {
			case AccountDrawerTab.Subaccounts:
			default:
				return (
					<SubaccountsTab
						onClose={onClose}
						isZeroWalletBalances={totalWalletQuoteBalances.eqZero()}
					/>
				);
			case AccountDrawerTab.Wallet:
				return <WalletBalancesTab onClose={onClose} />;
			case AccountDrawerTab.Bridge:
				return <BridgeTab onClose={onClose} />;
		}
	};

	const onManageWallet = () => {
		if (isSocialAppKitWallet) {
			openAppKitModal({ view: 'Account' });
		} else {
			setSelectedTab(AccountDrawerTab.Wallet);
		}
	};

	return (
		<Drawer drawerName="accounts" className="sm:w-[420px] sm:max-w-[420px]">
			<div className="flex flex-col bg-container-bg">
				<AccountGeneralInformation
					onClose={onClose}
					isExternalWallet={isExternalWallet}
					isBalancesLoading={isBalancesLoading}
					totalWalletQuoteBalances={totalWalletQuoteBalances}
					onManageWallet={onManageWallet}
				/>

				<div className="w-full p-4 pt-1">
					<MarginAccountButton
						className="w-full text-center"
						highlight
						centerText
						desktopMode
					/>
				</div>

				<UnderlinedTabs
					options={SIDEBAR_TABS}
					currentSelection={selectedTab}
					onChange={(newSelection: string) => {
						setSelectedTab(newSelection as AccountDrawerTab);
					}}
					tabClassName={
						'grow text-center text-[14px] leading-[16px] items-center justify-center'
					}
					gradient
				/>
			</div>

			<div className="overflow-auto grow thin-scroll">{renderTab()}</div>
		</Drawer>
	);
}
