'use client';

import { TOKEN_PROGRAM_ID } from '@drift/common';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useInterval from './useInterval';

type TokenAccountInfo = {
	isNative: boolean;
	mint: string;
	owner: string;
	state: string;
	tokenAmount: {
		amount: string;
		decimals: number;
		uiAmount: number;
	};
};

const useAuthorityTokenAccounts = (updateInterval = 0) => {
	const wallet = useCurrentWalletAdapter();
	const connection = useDriftStore((s) => s.connection.current);

	const [isLoading, setIsLoading] = useState(true);
	const [parsedTokenAccounts, setParsedTokenAccounts] = useState<
		TokenAccountInfo[]
	>([]);

	const syncTokenAccounts = () => {
		if (wallet.publicKey && connection) {
			connection
				.getParsedTokenAccountsByOwner(wallet.publicKey, {
					programId: TOKEN_PROGRAM_ID,
				})
				.then((response) => {
					const formattedTokenAccounts = response.value.map((tokenAccount) => {
						return tokenAccount.account.data.parsed.info;
					});

					setParsedTokenAccounts(formattedTokenAccounts);
					setIsLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		syncTokenAccounts();
	}, [connection, wallet.publicKey]);

	useInterval(() => {
		if (updateInterval) {
			syncTokenAccounts();
		}
	}, updateInterval || 60000);

	return {
		authorityTokenAccounts: parsedTokenAccounts,
		isLoading,
	};
};

export default useAuthorityTokenAccounts;
