'use client';

import SemanticChip from './Utils/SemanticChip';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import { useAllRpcLatencies } from 'src/hooks/useRpcLatency';
import Text from './Text/Text';
import { useEffect, useState } from 'react';
import useIsMainnet from 'src/hooks/useIsMainnet';
import UI_UTILS from 'src/utils/uiUtils';
import RoundedGradientBorderBox from './RoundedGradientBorderBox';
import TextField from './Inputs/TextField';
import Button from './Button';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';

const CUSTOM_RPC_LABEL = 'Custom';

const RpcLatencyIndicator = ({ latency }: { latency: number }) => (
	<SemanticChip
		className="mr-2"
		status={
			latency < 0
				? 'grey'
				: latency < 100
				? 'green'
				: latency < 250
				? 'amber'
				: 'red'
		}
	/>
);

const RpcCheckbox = (props: { checked: boolean }) => {
	return props.checked ? (
		<RoundedGradientBorderBox
			className="w-[1.2rem] h-[1.2rem] p-0.5 place-items-center"
			borderRadius={'.6rem'}
			borderWidth={'1px'}
			bgColor={'var(--container-bg)'}
		>
			<div
				className="w-full h-full rounded-full bg-container-bg place-self-center border-container-border"
				style={{ background: 'var(--app-gradient)', borderWidth: '3px' }}
			/>
		</RoundedGradientBorderBox>
	) : (
		<div className="h-5 w-5 rounded-[0.6rem] bg-input-bg hover:bg-container-bg-hover" />
	);
};

const RpcSelectorPopup = () => {
	const { captureEvent } = usePostHogCapture();

	const [savedSettings, updateSettings] = useCurrentSettings();

	const [selectedRpcEndpoint, setSelectedRpcEndpoint] = useState(
		savedSettings?.rpcEndpoint
	);

	const [isCustom, setIsCustom] = useState(
		selectedRpcEndpoint?.label === CUSTOM_RPC_LABEL
	);

	const [customStringEntered, setCustomStringEntered] = useState(
		selectedRpcEndpoint?.label === CUSTOM_RPC_LABEL
			? selectedRpcEndpoint?.value
			: ''
	);

	const [isRefreshing, setIsRefreshing] = useState(false);

	const latencies = useAllRpcLatencies();

	const isMainnet = useIsMainnet();

	const rpcOptions = UI_UTILS.getRpcOptions(
		isMainnet ? 'mainnet-beta' : 'devnet'
	);

	const handleRpcUpdate = (newRpc: { value: string; label: string }) => {
		setSelectedRpcEndpoint(newRpc);
		setIsCustom(newRpc?.label === CUSTOM_RPC_LABEL);

		if (newRpc.value !== savedSettings.rpcEndpoint.value) {
			setIsRefreshing(true);
			updateSettings({ rpcEndpoint: newRpc });
			UI_UTILS.handleRefresh('About to refresh to change RPC Node');
		}
	};

	const handleSelectCustom = () => {
		setIsCustom(true);
	};

	const updateToCustomRpcInput = () => {
		handleRpcUpdate({ value: customStringEntered, label: CUSTOM_RPC_LABEL });
	};

	useEffect(() => {
		setSelectedRpcEndpoint(savedSettings?.rpcEndpoint);
		setIsCustom(savedSettings?.rpcEndpoint?.label === CUSTOM_RPC_LABEL);
	}, [savedSettings?.rpcEndpoint?.label]);

	useEffect(() => {
		captureEvent('rpc_footer_menu_opened');
	}, []);

	return (
		<div className="p-4 relative rounded-md bg-container-bg w-[350px] space-y-2 z-100">
			<Text.BODY1 className="text-text-emphasis">RPC Endpoint</Text.BODY1>
			{rpcOptions.map((rpc, index) => {
				return (
					<div
						key={`rpc_option_${index}`}
						className="flex items-center justify-between"
					>
						<div
							className="flex items-center hover:cursor-pointer"
							onClick={() => handleRpcUpdate(rpc)}
						>
							<div>
								<RpcCheckbox
									checked={
										rpc.label === selectedRpcEndpoint?.label && !isCustom
									}
								/>
							</div>
							<Text.BODY1 className="ml-2 text-text-emphasis">
								{rpc.label}
							</Text.BODY1>
						</div>

						<div className="flex items-center">
							<RpcLatencyIndicator latency={latencies[rpc.value]?.avg} />
							<Text.BODY2 className="text-text-label">
								{latencies[rpc.value]?.avg
									? `${latencies[rpc.value]?.avg}ms`
									: `N/A`}
							</Text.BODY2>
						</div>
					</div>
				);
			})}
			<div className="flex items-center justify-between">
				<div
					className="flex items-center hover:cursor-pointer"
					onClick={handleSelectCustom}
				>
					<div>
						<RpcCheckbox checked={isCustom} />
					</div>
					<Text.BODY1 className="ml-2 text-text-emphasis">Custom</Text.BODY1>
				</div>
				{selectedRpcEndpoint.label === CUSTOM_RPC_LABEL && (
					<div className="flex items-center">
						<RpcLatencyIndicator
							latency={latencies[selectedRpcEndpoint?.value]?.avg}
						/>
						<Text.BODY2 className="text-text-label">
							{latencies[selectedRpcEndpoint?.value]?.avg
								? `${latencies[selectedRpcEndpoint?.value]?.avg}ms`
								: `N/A`}
						</Text.BODY2>
					</div>
				)}
			</div>
			<div className="flex items-center">
				<TextField.Default
					type={'text'}
					onFocus={handleSelectCustom}
					onChange={(value) => setCustomStringEntered(value)}
					value={customStringEntered}
					placeholder={'Enter Custom RPC URL'}
					suffixButton={
						<Button.Secondary
							disabled={
								!isCustom ||
								!customStringEntered ||
								isRefreshing ||
								customStringEntered === selectedRpcEndpoint?.value
							}
							size="XSMALL"
							className="p-2"
							onClick={updateToCustomRpcInput}
						>
							<Text.BODY3>Save</Text.BODY3>
						</Button.Secondary>
					}
				/>
			</div>
		</div>
	);
};

export default RpcSelectorPopup;
