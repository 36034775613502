'use client';

import { PropsWithChildren, useMemo } from 'react';
import Text from 'src/components/Text/Text';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import InlineLoadingBar from './InlineLoadingBar/InlineLoadingBar';
import SecondaryConnectWalletButton from './SecondaryConnectWalletButton';
import Button from './Button';
import { twMerge } from 'tailwind-merge';
import { JSX } from 'react';
export type TableStateProps = PropsWithChildren<{
	records: any[];
	emptyStateText: React.ReactNode;
	requireWalletConnect?: boolean;
	pageChangeLoading?: boolean;
	requireAccountCreated?: boolean;
	id?: string;
	showDepositButtonWhenEmpty?: boolean;
	handleClickDepositButton?: () => void;
	paginator?: React.ReactNode;
	alignTop?: boolean;
	className?: string;
	loading?: boolean;
	style?: React.CSSProperties;
}>;

const genericStateWrapper = (content: JSX.Element, alignTop?: boolean) => {
	return (
		<div
			className={twMerge(
				`flex flex-col justify-center items-center h-full w-full text-center rounded-md`,
				alignTop && 'justify-start pt-6'
			)}
		>
			{content}
		</div>
	);
};

const TableStateWrapper = (props: TableStateProps) => {
	const connected = useWalletIsConnected();
	const userAccountNotInitialized = useDriftAccountStore(
		(s) => s.userAccountNotInitialized
	);
	const currentAccountExists = useDriftAccountStore(
		(s) => !!s.getCurrentUserAccount()
	);

	const loadingState =
		connected &&
		!userAccountNotInitialized &&
		(props.records == undefined || props.loading);
	const emptyState =
		(props.requireAccountCreated && userAccountNotInitialized) ||
		(currentAccountExists &&
			!!props.records &&
			props.records.length == 0 &&
			!props.pageChangeLoading);

	const elementToRender = useMemo(() => {
		if (props.requireWalletConnect && !connected) {
			return genericStateWrapper(<SecondaryConnectWalletButton />);
		}

		if (loadingState) {
			return genericStateWrapper(<InlineLoadingBar />);
		}

		if (emptyState) {
			return genericStateWrapper(
				<div className="block my-8 sm:my-0">
					<Text.BODY2 className="text-text-emphasis">
						{props.emptyStateText}
					</Text.BODY2>
					{props.showDepositButtonWhenEmpty && (
						<div className="mt-4">
							<Button.Secondary
								size="MEDIUM"
								onClick={props.handleClickDepositButton}
							>
								Deposit Assets
							</Button.Secondary>
						</div>
					)}
				</div>,
				props.alignTop
			);
		}

		return props.children;
	}, [
		props.requireWalletConnect,
		connected,
		loadingState,
		emptyState,
		props.emptyStateText,
		props.children,
	]);

	return (
		<div
			id={props.id}
			className={twMerge(`flex flex-col flex-grow h-full`, props.className)}
			style={{ ...props.style }}
		>
			<div className={`h-full overflow-auto thin-scroll`}>
				<div className={`align-middle inline-block min-w-full h-full`}>
					{elementToRender}
				</div>
			</div>
			{props.paginator}
		</div>
	);
};

export default TableStateWrapper;
