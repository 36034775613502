"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findDelistedPerpMarketsAndOracles = exports.capitalize = void 0;
const types_1 = require("../types");
const oracleId_1 = require("../oracles/oracleId");
function capitalize(value) {
    return value[0].toUpperCase() + value.slice(1);
}
exports.capitalize = capitalize;
function findDelistedPerpMarketsAndOracles(perpMarkets, spotMarkets) {
    const delistedPerpMarketIndexes = [];
    const delistedOracles = [];
    for (const perpMarket of perpMarkets) {
        if (!perpMarket.data) {
            continue;
        }
        if ((0, types_1.isVariant)(perpMarket.data.status, 'delisted')) {
            delistedPerpMarketIndexes.push(perpMarket.data.marketIndex);
            delistedOracles.push({
                publicKey: perpMarket.data.amm.oracle,
                source: perpMarket.data.amm.oracleSource,
            });
        }
    }
    // make sure oracle isn't used by spot market
    const filteredDelistedOracles = [];
    for (const delistedOracle of delistedOracles) {
        let isUsedBySpotMarket = false;
        for (const spotMarket of spotMarkets) {
            if (!spotMarket.data) {
                continue;
            }
            const delistedOracleId = (0, oracleId_1.getOracleId)(delistedOracle.publicKey, delistedOracle.source);
            const spotMarketOracleId = (0, oracleId_1.getOracleId)(spotMarket.data.oracle, spotMarket.data.oracleSource);
            if (spotMarketOracleId === delistedOracleId) {
                isUsedBySpotMarket = true;
                break;
            }
        }
        if (!isUsedBySpotMarket) {
            filteredDelistedOracles.push(delistedOracle);
        }
    }
    return {
        perpMarketIndexes: delistedPerpMarketIndexes,
        oracles: filteredDelistedOracles,
    };
}
exports.findDelistedPerpMarketsAndOracles = findDelistedPerpMarketsAndOracles;
