import { useQuery } from '@tanstack/react-query';
import { PerformanceDataSet } from 'src/@types/performance';
import { ONE_DAY_MS } from '@drift/common';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';

export function useInsuranceFundRecords(marketIndex: number) {
	return useQuery<PerformanceDataSet>({
		queryKey: ['insuranceFundRecords', marketIndex],
		staleTime: ONE_DAY_MS,
		queryFn: async () => {
			const result = await ExchangeHistoryClient.getInsuranceFundRecords(
				marketIndex
			);

			if (result.success) {
				const allDataPoints = result?.body.data.insuranceFundRecords
					.filter((record) => !record.totalIfSharesAfter.eqZero())
					.map((record) => {
						return {
							date: new Date(record.ts.toNumber() * 1000),
							totalPnl: record.insuranceVaultAmountBefore
								.add(record.amount)
								// shares can be extremely small
								.scale(1000000, record.totalIfSharesAfter.toNum() * 1000000)
								.toNum(),
						};
					});
				const points90d = allDataPoints;
				const points30d = UI_UTILS.applyTimeFilter(allDataPoints, '30d');
				const points7d = UI_UTILS.applyTimeFilter(allDataPoints, '7d');
				const points24h = UI_UTILS.applyTimeFilter(allDataPoints, '24h');
				return {
					'24h': points24h,
					'7d': points7d,
					'30d': points30d,
					all: points90d,
				};
			} else {
				console.error('Failed to fetch IF records', result);
				return {
					'24h': [],
					'7d': [],
					'30d': [],
					all: [],
				};
			}
		},
	});
}
