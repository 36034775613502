import React from 'react';
import { Close } from '@drift-labs/icons';
import { TouchButton } from '../Button';

const XButton = ({
	onClick,
	...props
}: {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	stroke?: string;
	className?: string;
	size?: number;
	style?: React.CSSProperties;
}) => {
	return (
		<TouchButton
			onClick={onClick}
			className="text-text-label hover:text-text-emphasis hover:cursor-pointer"
		>
			<Close
				size={24}
				className={props.className ?? `h-6 w-6`}
				color={props.stroke ?? 'currentColor'}
				{...props}
			/>
		</TouchButton>
	);
};

export default XButton;
