'use client';

import { twMerge } from 'tailwind-merge';
import { V2AuctionToastData } from '../../utils/notifications';
import Toast from './Toast';
import ArcLoadingSpinner from '../Icons/LoadingSpinner/ArcLoadingSpinner';
import Text from '../Text/Text';
import {
	Close,
	ErrorFilled,
	Lightning,
	SuccessFilled,
	WarningFilled,
} from '@drift-labs/icons';
import { MarketOrderToastStageState } from '../MarketOrderToasts/MarketOrderToastStateTypes';
import { ENUM_UTILS, UIMarket } from '@drift/common';
import { BASE_PRECISION_EXP, BigNum, PositionDirection } from '@drift-labs/sdk';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { DriftWindow } from '../../window/driftWindow';
import {
	SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY,
	TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
} from '../../constants/constants';
import { useEffect, useState } from 'react';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import MarketIcon from '../Utils/MarketIcon';
import useDevSwitchIsOn from '../../hooks/useDevSwitchIsOn';
import UI_UTILS from 'src/utils/uiUtils';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import { useMarketStepSizeBn } from 'src/hooks/useMarketStepSize';

const DEV_SHOW_EVENT_HISTORY = false;

const Direction = ({
	isLong,
	isPredictionMarket,
}: {
	isLong: boolean;
	isPredictionMarket: boolean;
}) => {
	return (
		<span
			className={twMerge(
				'inline',
				isLong ? 'text-positive-green' : 'text-negative-red'
			)}
		>
			{isLong
				? isPredictionMarket
					? 'Yes'
					: 'LONG'
				: isPredictionMarket
				? 'No'
				: 'SHORT'}
		</span>
	);
};

const MarketIconComponent = ({ marketSymbol }: { marketSymbol: string }) => {
	return (
		<span className="p-[1px]">
			<MarketIcon marketSymbol={marketSymbol} sizeClass="w-[18px] h-4" />
		</span>
	);
};

const Headline = (props: { data: V2AuctionToastData }) => {
	const isLong = ENUM_UTILS.match(
		props.data.orderInfo.direction,
		PositionDirection.LONG
	);

	const market = new UIMarket(
		props.data.orderInfo.marketId.marketIndex,
		props.data.orderInfo.marketId.marketType
	);

	const stepSizeBN = useMarketStepSizeBn(props.data.orderInfo.marketId);

	const orderIsPlaced =
		props.data.toastStages.transactionConfirmation.type === 'success';

	const basePrecisionExp = market.isPerp
		? BASE_PRECISION_EXP
		: SPOT_MARKETS_LOOKUP[market.marketIndex].precisionExp;

	const baseOrderedBigNum = BigNum.from(
		props.data.orderInfo.baseAmountOrdered,
		basePrecisionExp
	);

	const roundedBaseOrderedBigNum = UI_UTILS.getBigNumRoundedToStepSize(
		baseOrderedBigNum,
		stepSizeBN
	);

	const baseSizeOrderedDisplay = roundedBaseOrderedBigNum.prettyPrint();

	const isPredictionMarket = market.isPredictionMarket;

	if (orderIsPlaced) {
		const formattedSizeFilled = parseFloat(
			BigNum.from(
				props.data.orderInfo.cumulativeBaseAmountFilled,
				basePrecisionExp
			).print()
		);

		return (
			<span className="flex items-center pb-0 space-x-2">
				<MarketIconComponent marketSymbol={market.symbol} />
				{isPredictionMarket ? (
					<Text.BODY3 className="text-text-emphasis">
						{`Filled ${formattedSizeFilled} / ${roundedBaseOrderedBigNum.prettyPrint()} ${
							PredictionMarketConfigs.get(market.marketIndex).shortTitle
						} `}
						<Direction isPredictionMarket={true} isLong={isLong} />
						{` Shares`}
					</Text.BODY3>
				) : (
					<Text.BODY3 className="text-text-emphasis">
						{`Filled ${formattedSizeFilled} / ${baseSizeOrderedDisplay} `}
						<Direction isPredictionMarket={false} isLong={isLong} />
					</Text.BODY3>
				)}
			</span>
		);
	}

	return (
		<span className="flex items-center pb-0 space-x-2">
			<MarketIconComponent marketSymbol={market.symbol} />
			{isPredictionMarket ? (
				<Text.BODY3 className="text-text-emphasis">
					Placing: {roundedBaseOrderedBigNum.prettyPrint()}{' '}
					<Direction isPredictionMarket={true} isLong={isLong} />
					{` Shares for `}
					{PredictionMarketConfigs.get(market.marketIndex).shortTitle}
				</Text.BODY3>
			) : (
				<Text.BODY3 className="text-text-emphasis">
					{`Placing ${baseSizeOrderedDisplay} `}
					<Direction isPredictionMarket={false} isLong={isLong} />
					{` Order`}
				</Text.BODY3>
			)}
		</span>
	);
};

type ProgressState = {
	start: number;
	end: number;
	current: number;
	direction: 'end-full' | 'end-empty';
	type: 'neutral' | 'warn' | 'strong-warn';
};

const ProgressIndicator = (props: ProgressState) => {
	const startEndDelta = props.end - props.start;
	const currentDelta = props.current - props.start;
	let progressValue = Math.min((currentDelta / startEndDelta) * 100, 100);

	if (props.direction === 'end-empty') {
		progressValue = 100 - progressValue;
	}

	const strokeColour =
		props.type === 'neutral'
			? 'var(--interactive-link)'
			: props.type === 'warn'
			? 'var(--warn-yellow)'
			: 'var(--red-50)';

	return (
		<div style={{ width: 16, height: 16 }}>
			<CircularProgressbar
				value={progressValue}
				strokeWidth={50}
				styles={buildStyles({
					pathColor: strokeColour,
					trailColor: 'transparent',
					pathTransitionDuration: 0.15,
				})}
			/>
		</div>
	);
};

const StageDisplay = ({
	progressState,
	toastState,
}: {
	progressState?: ProgressState;
	toastState: MarketOrderToastStageState;
}) => {
	if (toastState.type === 'none') {
		return null;
	}

	const isJitAuctionStage = toastState.isJitAuction;

	return (
		<>
			{/* Icon Column */}
			<span className="w-full flex justify-center h-[20px] items-center">
				{toastState.type === 'in_progress' ? (
					progressState ? (
						<ProgressIndicator {...progressState} />
					) : (
						<ArcLoadingSpinner size={18} />
					)
				) : toastState.type === 'success' ? (
					<SuccessFilled size={18} />
				) : toastState.type === 'warning' ? (
					<WarningFilled size={18} />
				) : toastState.type === 'warning_in_progress' ? (
					<ArcLoadingSpinner color="var(--warn-yellow)" size={18} />
				) : toastState.type === 'error' ? (
					<ErrorFilled size={18} />
				) : toastState.type === 'neutral' ? (
					<div className="w-[18px] h-[18px] rounded-full bg-container-border" />
				) : null}
			</span>

			{/* Content Column */}
			<div className="flex flex-col">
				<div className="flex items-center space-x-1">
					{isJitAuctionStage && (
						<Lightning size={16} color={'url(#prize-gradient)'} />
					)}
					<span
						className={twMerge(
							`flex h-[20px] items-center mt-[1px]`,
							toastState.type === 'error' ? '' : undefined,
							toastState.type === 'success' ? '' : undefined,
							toastState.type === 'warning_in_progress'
								? 'text-warn-yellow'
								: undefined
						)}
					>
						<Text.BODY3>{toastState.message}</Text.BODY3>
					</span>
				</div>
				{toastState.extraMessage && (
					<Text.MICRO3 className="text-neutrals-30">
						{toastState.extraMessage}
					</Text.MICRO3>
				)}
			</div>
		</>
	);
};

const getPctCompletion = (start: number, end: number, current: number) => {
	const totalProgressSize = end - start;
	const currentProgressSize = current - start;

	return (currentProgressSize / totalProgressSize) * 100;
};

const getProgressStateForStage = (
	stage: keyof V2AuctionToastData['toastStages'],
	data: V2AuctionToastData
): ProgressState => {
	if (stage === 'transactionConfirmation') {
		if (data.toastStages.transactionConfirmation.type !== 'in_progress') {
			return null;
		}

		const blockHeight = DriftWindow.chainClock?.getState(
			TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL
		)?.blockHeight;

		if (!blockHeight || !data.orderInfo.transactionExpiryBlockHeight) {
			return null;
		}

		let type: ProgressState['type'] = 'neutral';

		const start =
			data.orderInfo.transactionExpiryBlockHeight -
			SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY;
		const end = data.orderInfo.transactionExpiryBlockHeight;
		const current = blockHeight;

		const pctCompletion = getPctCompletion(start, end, current);

		if (pctCompletion >= 50) {
			type = 'warn';
		}

		if (pctCompletion >= 80) {
			type = 'strong-warn';
		}

		const progress: ProgressState = {
			start,
			end,
			current,
			type,
			direction: 'end-empty',
		};

		return progress;
	}

	if (stage === 'jitAuction') {
		if (data.toastStages.jitAuction.type !== 'in_progress') {
			return null;
		}

		if (!data.orderInfo.auctionStartSlot || !data.orderInfo.auctionDuration) {
			return null;
		}

		const slot = DriftWindow.chainClock?.getState(
			DEFAULT_COMMITMENT_LEVEL
		)?.slot;

		const progress: ProgressState = {
			start: data.orderInfo.auctionStartSlot,
			end: data.orderInfo.auctionStartSlot + data.orderInfo.auctionDuration,
			current: slot,
			type: 'neutral' as ProgressState['type'],
			direction: 'end-empty',
		};

		return progress;
	}

	if (stage === 'orderCompletion') {
		if (data.toastStages.orderCompletion.type !== 'in_progress') {
			return null;
		}

		if (!data.orderInfo.maxTs || !data.orderInfo.startTs) {
			return null;
		}

		let type: ProgressState['type'] = 'neutral';

		const start = data.orderInfo.startTs?.toNumber();
		const end = data.orderInfo.maxTs.toNumber();
		const current = DriftWindow.chainClock?.getState(
			DEFAULT_COMMITMENT_LEVEL
		)?.ts;

		const pctCompletion = getPctCompletion(start, end, current);

		if (pctCompletion >= 50) {
			type = 'warn';
		}

		if (pctCompletion >= 80) {
			type = 'strong-warn';
		}

		const progress: ProgressState = {
			type,
			start,
			end,
			current,
			direction: 'end-empty',
		};

		return progress;
	}

	return null;
};

const useForceRender = () => {
	const [_, forceRender] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			forceRender((prev) => prev + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	return _;
};

const V2MarketOrderAlert = ({
	data,
	onClose,
}: {
	data: V2AuctionToastData;
	onClose: () => void;
}) => {
	// Force to render each second for the sake of refreshing progress bars ... TODO .. probs a better way of doing this.
	useForceRender();

	const isDev = useDevSwitchIsOn();
	const devData = data.dev_data;

	return (
		<Toast>
			<div className="flex flex-col space-y-3">
				<div className="flex items-center justify-between w-full">
					<Headline data={data} />
					<Close
						color="var(--text-default)"
						onClick={(e) => {
							// TODO : Think need to clear data from the toast ID handler
							e.stopPropagation();
							onClose();
						}}
					/>
				</div>
				<div className="grid grid-cols-[20px_1fr] gap-x-2 gap-y-3">
					{Object.entries(data.toastStages).map(([stage, toastState]) => {
						if (!toastState || toastState.type === 'none') {
							return null;
						}

						return (
							<StageDisplay
								key={stage}
								progressState={getProgressStateForStage(
									stage as keyof V2AuctionToastData['toastStages'],
									data
								)}
								toastState={toastState}
							/>
						);
					})}
				</div>

				{data.smallFooterText && (
					<Text.MICRO3 className="text-neutrals-30">
						{data.smallFooterText}
					</Text.MICRO3>
				)}

				{data.bigFooterText && <Text.BODY3>{data.bigFooterText}</Text.BODY3>}

				{/* Status Footers */}
				{data.toastStages?.transactionConfirmation?.type === 'success' && (
					<div className="flex justify-between w-full">
						<Text.MICRO3 className="text-neutrals-30">
							{data.orderInfo.avgFillPrice}
						</Text.MICRO3>
						<Text.MICRO3 className="text-neutrals-30">
							{data.orderInfo.pctFilled}
						</Text.MICRO3>
					</div>
				)}

				{/* Dev Stuff */}
				{DEV_SHOW_EVENT_HISTORY && isDev && devData && devData.length ? (
					<div className="flex flex-wrap w-full">
						<span>{`Dev: `}</span>
						<>
							{devData.map((str, index) => (
								<span key={`${str}_${index}`}>{`${str}, `}</span>
							))}
						</>
					</div>
				) : null}
			</div>
		</Toast>
	);
};

export default V2MarketOrderAlert;
