'use client';

import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import Button from 'src/components/Button';
import Tooltip from 'src/components/Tooltip/Tooltip';
import {
	CurrentSpotMarkets,
	PRIORITY_SPOT_MARKETS,
} from 'src/environmentVariables/EnvironmentVariables';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import Text from '../Text/Text';
import Select from './Select';
import TextField from './TextField';
import Utility from './Utility';
import { LiquidityPool } from 'src/hooks/useLiquidityPools';
import Slider from '../Slider';
import useBanksToRender from '../../hooks/useBanksToRender';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { twMerge } from 'tailwind-merge';
import { Typo } from 'src/components/Text/Typo';
import useHighlightedSpotMarkets from 'src/hooks/useHighlightedSpotMarkets';
import RoundedGradientBorderBox from '../RoundedGradientBorderBox';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

export const COLLATERAL_DROPDOWN_ID = 'crossCollatMarketTransferSelector';

type CrossCollateralInputProps = {
	useTargetWidth?: boolean;
	maxAmount: BigNum;
	selectedMarket: SpotMarketConfig;
	onChangeValue: (val?: string) => void;
	onChangeMarket?: (val: SpotMarketConfig) => void;
	label: string;
	customLabelRight?: string;
	customElementRight?: ReactNode;
	showMaxLabel?: boolean;
	value?: string;
	onMax?: () => void;
	amountLabel?: string | ReactNode;
	isBorrowAmount?: boolean;
	tooltipText?: string;
	currentBalance?: BigNum; // only used for when user hits max while depositing sol
	banks?: SpotMarketConfig[]; // optionally pass a set of banks instead of using the full list
	singleLine?: boolean; // If true, renders as one line w/ the 50% and max buttons in the input
	disabled?: boolean;
	source:
		| {
				type: 'wallet' | 'ifStake' | 'default';
		  }
		| {
				type: 'userAccount';
				userKey?: string;
		  };
	defaultToHighestBalance?: boolean;
	excludedMarketIndices?: number[];
	poolId?: number;
};

type LpInputProps = {
	maxAmount: BigNum;
	selectedPool: LiquidityPool;
	onChangeValue: (val?: string) => void;
	onChangePool: (val: LiquidityPool) => void;
	label: string;
	direction: 'add' | 'remove';
	allLiqPools: LiquidityPool[];
	currentLeverage?: number;
	maxLeverage?: number;
	customLabelRight?: string;
	customElementRight?: ReactNode;
	showMaxLabel?: boolean;
	value?: string;
	onMax?: () => void;
	amountLabel?: string | ReactNode;
	tooltipText?: string;
	singleLine?: boolean; // If true, renders as one line w/ the 50% and max buttons in the input
	disabled?: boolean;
	exceededMax?: boolean;
	isMobile?: boolean;
};

type AmountSelectorProps = {
	maxAmount: BigNum;
	symbol: string;
	onChangeValue: (val?: string) => void;
	label: string;
	onMax?: () => void;
	amountLabel?: string | ReactNode;
	currentBalance?: BigNum; // only used for when user hits max while depositing sol
	isNegative?: boolean;
	disabled?: boolean;
};

const QuickButtons = ({
	handleChoice,
	className,
	disabled,
}: {
	handleChoice: (value: number) => void;
	className?: string;
	disabled?: boolean;
}) => (
	<>
		<Button.Secondary
			size="XSMALL"
			onClick={() => {
				handleChoice(2);
			}}
			className={`w-full ${className ? className : ''}`}
			disabled={disabled}
		>
			50%
		</Button.Secondary>
		<Button.Secondary
			size="XSMALL"
			onClick={() => {
				handleChoice(4);
			}}
			className={`w-full ${className ? className : ''}`}
			disabled={disabled}
		>
			Max
		</Button.Secondary>
	</>
);

/**
 * A component which shows an amount of a collateral. Non-interactive, used in conjunction with other inputs.
 */
export const CollateralAmountDisplay = (
	props: Pick<
		CrossCollateralInputProps,
		'selectedMarket' | 'label' | 'tooltipText'
	> & {
		displayValue: string;
	}
) => {
	return (
		<div className="flex flex-col cursor-not-allowed">
			<div className="flex items-end w-full space-x-2">
				<Utility.FORMLABEL01 label={props.label} />
				{props.tooltipText && (
					<Tooltip
						className="text-text-label"
						content={<span>{props.tooltipText}</span>}
					/>
				)}
			</div>
			<Utility.VERTSPACERXS />
			<div
				className={twMerge(
					`px-2 bg-input-bg text-text-input border flex items-center`,
					'h-10',
					'border-container-border',
					'w-full',
					'rounded'
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center space-x-1.5">
						<MarketIcon
							marketSymbol={props.selectedMarket.symbol}
							sizeClass="w-[18px] h-[18px]"
						/>

						<Typo.B2
							className={`mt-0.5 truncate overflow-ellipsis text-text-input opacity-60 font-mono`}
						>
							{props.displayValue}
						</Typo.B2>
					</div>
				</div>
			</div>
		</div>
	);
};

/**
 * A basic input type which only allows selecting a collateral type but not the size.
 */
export const CollateralSelector = (
	props: Pick<
		CrossCollateralInputProps,
		'onChangeMarket' | 'label' | 'tooltipText' | 'poolId' | 'banks'
	>
) => {
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const highlightedSpotMarkets = useHighlightedSpotMarkets();

	const sortedBalances = useBanksToRender({
		poolId: props.poolId,
		banks: props.banks,
		userKey: currentUserKey,
		sortBy: 'walletBalance',
		opts: {
			highlightBanks: highlightedSpotMarkets,
			prioritySort: PRIORITY_SPOT_MARKETS,
		},
	});

	const biggestAssetMarket = useWalletBalancesStore((s) =>
		s.getBiggestAssetMarket()
	);

	const [selectedCollatBank, setSelectedCollatBank] =
		useState<SpotMarketConfig>(biggestAssetMarket);

	const collateralOptions = useMemo(
		() =>
			sortedBalances.map((balance) => ({
				value: balance.market.symbol,
				label: balance.market.symbol,
				baseValue: balance.baseValue,
				quoteValue: balance.quoteValue,
			})),
		[sortedBalances]
	);

	useEffect(() => {
		props.onChangeMarket(selectedCollatBank);
	}, [selectedCollatBank]);

	return (
		<div className="flex flex-col">
			<div className="flex items-end w-full space-x-2">
				<Utility.FORMLABEL01 label={props.label} />
				{props.tooltipText && (
					<Tooltip
						className="text-text-label"
						content={<span>{props.tooltipText}</span>}
					/>
				)}
			</div>
			<Utility.VERTSPACERXS />
			<Select.CollateralSelector
				id="crossCollatMarketTransferSelector"
				options={collateralOptions}
				currentSelection={selectedCollatBank.symbol}
				onChange={(value) => {
					const selectedMarket = CurrentSpotMarkets.find(
						(bank) => bank?.symbol === value
					);
					setSelectedCollatBank(selectedMarket || selectedCollatBank);
				}}
				customRounding="rounded"
				useFullWidth
				customHeight="h-10"
			/>
		</div>
	);
};

/**
 * NOTE: May soon be deprecated in favor of CollateralInputV2
 */
export const CollateralInput = ({
	source,
	...props
}: CrossCollateralInputProps) => {
	let sortBy: Parameters<typeof useBanksToRender>[0]['sortBy'];

	switch (source.type) {
		case 'wallet':
			sortBy = 'walletBalance';
			break;
		case 'ifStake':
			sortBy = 'ifStakeBalance';
			break;
		case 'userAccount':
			sortBy = 'accountBalance';
			break;
		case 'default':
			sortBy = 'default';
			break;
		default: {
			const exhaustiveCheck: never = source;
			throw new Error(`Invalid source type : ${exhaustiveCheck}`);
		}
	}

	const highlightedSpotMarkets = useHighlightedSpotMarkets();

	const sortedBalances = useBanksToRender({
		sortBy,
		userKey: source.type === 'userAccount' ? source.userKey : undefined,
		banks: props.banks,
		opts: {
			highlightType:
				source.type === 'default' ? undefined : 'higherPriorityIfZeroBalance',
			highlightBanks: highlightedSpotMarkets,
			prioritySort: PRIORITY_SPOT_MARKETS,
		},
		poolId: props.poolId,
	});

	const marketsKey = sortedBalances
		.map((balance) => balance.market.symbol)
		.join('');

	const collateralOptions = useMemo(
		() =>
			sortedBalances
				.filter(
					(balance) =>
						!props.excludedMarketIndices ||
						!props.excludedMarketIndices?.includes(balance.market.marketIndex)
				)
				.map((balance) => ({
					value: balance.market.symbol,
					label: balance.market.symbol,
					baseValue: balance.baseValue,
					quoteValue: balance.quoteValue,
				})),
		[marketsKey, sortedBalances]
	);

	useEffect(() => {
		if (sortedBalances[0] && props.defaultToHighestBalance) {
			props.onChangeMarket(sortedBalances[0].market);
		}
	}, [sortedBalances[0]?.market.marketIndex]);

	const handleQuickAmount = (amount: number) => {
		props.onChangeValue(props.maxAmount.scale(amount, 4).printShort());

		if (amount === 4) {
			props.onMax?.();
		}
	};

	return (
		<div className="flex flex-col">
			<div className="flex items-end justify-between w-full">
				<Utility.FORMLABEL01 label={props.label} />
				{props.showMaxLabel && (
					<Utility.FORMLABEL02
						label={`Available: ${props.maxAmount.prettyPrint()} ${
							props.selectedMarket.symbol
						}`}
					/>
				)}
				{props.customLabelRight && (
					<Utility.FORMLABEL02 label={props.customLabelRight} />
				)}
				{props.customElementRight && props.customElementRight}
			</div>
			<Utility.VERTSPACERXS />
			<div className="relative flex">
				<div className="flex items-center transition-all bg-darkBlue-90 hover:cursor-pointer text-text-label hover:text-text-emphasis">
					<Select.CollateralSelector
						id="crossCollatMarketTransferSelector"
						options={collateralOptions}
						currentSelection={props.selectedMarket.symbol}
						onChange={(value) => {
							props.onChangeMarket(
								CurrentSpotMarkets.find(
									(bank) =>
										bank?.symbol === value &&
										bank?.poolId === (props.poolId ?? 0)
								)
							);
						}}
						customRounding="rounded-r-none"
					/>
				</div>
				<TextField.Default
					type="number"
					value={props.value}
					onChange={props.onChangeValue}
					customRounding="0 0.125rem 0.125rem 0"
					disabled={props.disabled}
				/>
				{props.singleLine && (
					<div className="absolute flex flex-row top-2 right-1">
						<QuickButtons
							handleChoice={handleQuickAmount}
							className="mx-1 px-0.5"
							disabled={props.disabled}
						/>
					</div>
				)}
			</div>
			{!props.singleLine && (
				<>
					<Utility.VERTSPACERXS />
					<Utility.VERTSPACERXS />
					{props.tooltipText ? (
						<Tooltip content={<span>{props.tooltipText}</span>}>
							<AmountSelector
								maxAmount={props.maxAmount}
								currentBalance={props.currentBalance}
								symbol={props.selectedMarket.symbol}
								onChangeValue={props.onChangeValue}
								label={props.label}
								onMax={props.onMax}
								amountLabel={props.amountLabel}
								disabled={props.disabled}
							/>
						</Tooltip>
					) : (
						<AmountSelector
							maxAmount={props.maxAmount}
							currentBalance={props.currentBalance}
							symbol={props.selectedMarket.symbol}
							onChangeValue={props.onChangeValue}
							label={props.label}
							onMax={props.onMax}
							amountLabel={props.amountLabel}
							disabled={props.disabled}
						/>
					)}
				</>
			)}
		</div>
	);
};

// NOTE: Not fully fleshed out yet
export const CollateralInputV2 = (props: CrossCollateralInputProps) => {
	return (
		<RoundedGradientBorderBox
			className={twMerge(`w-full h-[48px] flex`)}
			borderColour={'var(--input-bg-hover)'}
			borderWidth="1px"
			borderHoverClass={`${props.disabled ? `` : `var(--app-gradient)`}`}
			borderRadius={'4px'}
		>
			<div className="flex items-center w-full gap-2 px-3 py-4 bg-input-bg">
				{/** Collateral Selector */}
				<div className="flex items-center gap-1 shrink-0">
					<MarketIcon
						marketSymbol={props.selectedMarket.symbol}
						className="w-4 h-4"
					/>
					<Typo.T4>{props.selectedMarket.symbol}</Typo.T4>
				</div>

				<input
					type="number"
					className="w-full max-w-full text-right bg-transparent typo-t1"
					placeholder="0.0"
					value={props.value}
					onChange={(e) => props.onChangeValue(e.target.value)}
				/>
			</div>
		</RoundedGradientBorderBox>
	);
};

export const AmountSelector = (props: AmountSelectorProps) => {
	const handleQuickAmount = (amount: number) => {
		props.onChangeValue(props.maxAmount.scale(amount, 4).printShort());

		if (amount === 4) {
			props.onMax?.();
		}
	};

	const displayBalance =
		props.currentBalance && props.currentBalance.gt(props.maxAmount)
			? props.currentBalance.prettyPrint()
			: props.maxAmount.prettyPrint();

	return (
		<div className="flex items-center justify-between w-full mb-2 space-x-1 transition-all text-text-secondary">
			<div>{props.amountLabel ?? 'Available balance'}</div>
			<div className="flex items-center space-x-1">
				<Text.INPUTLABEL01 className="mr-1 whitespace-nowrap">{`${
					props.isNegative ? '-' : ''
				}${displayBalance} ${props.symbol}`}</Text.INPUTLABEL01>
				<QuickButtons
					handleChoice={handleQuickAmount}
					disabled={props.disabled}
				/>
			</div>
		</div>
	);
};

export const AddLpInput = (props: LpInputProps) => {
	const poolsToUse = props.allLiqPools;

	const PoolSelectorOptions = poolsToUse.map((pool) => ({
		value: pool.marketConfig.symbol,
		label: pool.marketConfig.symbol,
	}));

	const availableLeveragePoints = Math.max(
		0,
		(props.maxLeverage - props.currentLeverage) * 100
	);
	const currentPoint =
		(Number(props.value) / props.maxAmount.toNum()) * availableLeveragePoints;
	const displayValue = props.currentLeverage + currentPoint / 100;
	const sliderValue = (currentPoint / availableLeveragePoints) * 100;
	const showDefault = !props.value || Number(props.value) == 0;

	const handleSliderChange = (sliderPct: number) => {
		if (props.maxAmount.eqZero()) {
			return;
		}

		if (sliderPct === 100) {
			props.onMax();
		} else if (sliderPct <= 0) {
			props.onChangeValue('0');
		} else {
			const newAmount = (sliderPct / 100) * props.maxAmount.toNum();
			props.onChangeValue(newAmount.toString());
		}
	};

	if (props.exceededMax) {
		handleSliderChange(100);
	}

	return (
		<div className="flex flex-col">
			<div className="flex items-end justify-between w-full">
				<Utility.FORMLABEL01 label={props.label} />
				{props.showMaxLabel && (
					<div
						className="flex justify-end hover:cursor-pointer"
						onClick={() => handleSliderChange(100)}
					>
						<Text.MICRO4 className="text-text-secondary">Available</Text.MICRO4>
						<Text.MICRO4 className="pl-1 text-text-label">
							{props.maxAmount.toNotional()}
						</Text.MICRO4>
					</div>
				)}
				{props.customLabelRight && (
					<Utility.FORMLABEL02 label={props.customLabelRight} />
				)}
				{props.customElementRight && props.customElementRight}
			</div>
			<Utility.VERTSPACERXS />
			<div className="relative flex">
				<div className="flex items-center transition-all bg-darkBlue-90 hover:cursor-pointer text-text-label hover:text-text-emphasis">
					<Select.CollateralSelector
						id="crossCollatMarketTransferSelector"
						options={PoolSelectorOptions}
						currentSelection={props.selectedPool.marketConfig.symbol}
						onChange={(value) => {
							props.onChangePool(
								props.allLiqPools.find(
									(pool) => pool.marketConfig.symbol === value
								)
							);
						}}
						customRounding="rounded-r-none"
						maxHeight={150}
						customWidth="w-[180px]"
					/>
				</div>
				<TextField.Default
					type="number"
					prefix="$"
					value={props.value}
					onChange={props.onChangeValue}
					customRounding="0 0.125rem 0.125rem 0"
					disabled={props.disabled}
				/>
			</div>
			<div className={`w-full ${props.isMobile ? 'my-2' : 'mt-4'}`}>
				<Slider
					type="leverage"
					disabled={props.disabled}
					value={sliderValue}
					onDrop={(newVal) => {
						handleSliderChange(newVal);
					}}
					onMove={(newVal) => {
						handleSliderChange(newVal);
					}}
					step={1}
					maxButtonTransition={false}
					customButtonOptions={[]}
					displayValue={
						showDefault
							? props.currentLeverage?.toFixed(2) ?? '0'
							: displayValue.toFixed(2)
					}
				/>
			</div>
		</div>
	);
};

export const RemoveLpInput = (props: LpInputProps) => {
	const poolsToUse = props.allLiqPools;

	const PoolSelectorOptions = poolsToUse.map((pool) => ({
		value: pool.marketConfig.symbol,
		label: pool.marketConfig.symbol,
	}));

	const availableLeveragePoints = 100;

	const handleSliderChange = (sliderPct: number) => {
		if (props.maxAmount.eqZero()) {
			return;
		}

		if (sliderPct === 100) {
			props.onMax();
		} else {
			const newAmount = props.maxAmount.scale(sliderPct, 100);
			props.onChangeValue(newAmount.printShort());
		}
	};

	if (props.exceededMax) {
		handleSliderChange(100);
	}

	const showDefault = !props.value || Number(props.value) == 0;

	const currentLeveragePoint = showDefault
		? 0
		: (Number(props.value) / props.maxAmount.toNum()) * availableLeveragePoints;

	// Get slider value from 0 to 100.
	const sliderValue = Math.abs(
		Math.min((currentLeveragePoint / availableLeveragePoints) * 100, 100)
	);

	return (
		<div className="flex flex-col">
			<div className="flex items-end justify-between w-full">
				<Utility.FORMLABEL01 label={props.label} />
				{props.showMaxLabel && (
					<div
						className="flex justify-end hover:cursor-pointer"
						onClick={() => handleSliderChange(100)}
					>
						<Text.MICRO4 className="text-text-secondary">Available</Text.MICRO4>
						<Text.MICRO4 className="pl-1 text-text-label">
							{props.maxAmount.prettyPrint()}
						</Text.MICRO4>
					</div>
				)}
				{props.customLabelRight && (
					<Utility.FORMLABEL02 label={props.customLabelRight} />
				)}
				{props.customElementRight && props.customElementRight}
			</div>
			<Utility.VERTSPACERXS />
			<div className="relative flex">
				<div className="flex items-center transition-all bg-darkBlue-90 hover:cursor-pointer text-text-label hover:text-text-emphasis">
					<Select.CollateralSelector
						id="crossCollatMarketTransferSelector"
						options={PoolSelectorOptions}
						currentSelection={props.selectedPool.marketConfig.symbol}
						onChange={(value) => {
							props.onChangePool(
								props.allLiqPools.find(
									(pool) => pool.marketConfig.symbol === value
								)
							);
						}}
						customRounding="rounded-r-none"
						maxHeight={150}
						customWidth="w-[150px]"
					/>
				</div>
				<TextField.Default
					type="number"
					value={props.value}
					onChange={props.onChangeValue}
					customRounding="0 0.125rem 0.125rem 0"
					disabled={props.disabled}
				/>
			</div>
			<div className={`w-full ${props.isMobile ? 'my-2' : 'mt-4'}`}>
				<Slider
					type="percent"
					disabled={props.disabled}
					value={sliderValue}
					onDrop={(newVal) => {
						handleSliderChange(newVal);
					}}
					onMove={(newVal) => {
						handleSliderChange(newVal);
					}}
					step={1}
					maxButtonTransition={false}
					customButtonOptions={[]}
				/>
			</div>
		</div>
	);
};
