'use client';

import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	calculateEstimatedEntryPriceWithL2,
	L2OrderBook,
	MarketType,
	MAX_PREDICTION_PRICE,
	PositionDirection,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MarketId, UIOrderType } from '@drift/common';
import Env, {
	SPOT_MARKETS_LOOKUP,
} from '../environmentVariables/EnvironmentVariables';
import useDriftStore, { DriftStore } from '../stores/DriftStore/useDriftStore';
import useMarketStateStore, {
	MarketStateStore,
} from '../stores/useMarketStateStore';
import NumLib from '../utils/NumLib';
import useInterval from './useInterval';
import { GetState } from 'zustand/vanilla';
import { useCallback, useEffect, useMemo } from 'react';
import useGetOraclePriceForMarket from './useGetOraclePriceForMarket';

export type PriceImpactInfo = {
	marketIndex: number;
	marketType: MarketType;
	entryPrice: BN;
	priceImpact: BN;
	baseAvailable: BN;
	bestPrice: BN;
	worstPrice: BN;
	// Because usePriceImpact is expensive and we use a refresh speed to limit how often it runs, return the "input" base size so that we know which input basesize the output value is for
	priceImpactInputBaseSize: BN;
	showPriceEstimateOracleDivergenceWarning: boolean;
	exceedsLiquidity: boolean;
};

/**
 * This inverts the prices of the orderbook. Note that it does not flip the orderbook, it only inverts the prices.
 * This should mainly be used for `calculateEstimatedEntryPriceWithL2`, where it handles the "flipping" of the orderbook
 * based on the direction of the trade.
 */
export const invertL2State = (l2State: L2OrderBook): L2OrderBook => {
	const invertedBids = l2State.bids.map((bid) => {
		return {
			sources: bid.sources,
			price: MAX_PREDICTION_PRICE.sub(bid.price),
			size: bid.size,
		};
	});

	const invertedAsks = l2State.asks.map((ask) => {
		return {
			sources: ask.sources,
			price: MAX_PREDICTION_PRICE.sub(ask.price),
			size: ask.size,
		};
	});

	return {
		asks: invertedAsks,
		bids: invertedBids,
	};
};

/**
 * Because we invert the price for price impact at the start, we need to invert it back to the original price when submitting an order.
 */
export const invertPriceImpact = (
	priceImpact: PriceImpactInfo
): PriceImpactInfo => {
	return {
		...priceImpact,
		entryPrice: MAX_PREDICTION_PRICE.sub(priceImpact.entryPrice),
		bestPrice: MAX_PREDICTION_PRICE.sub(priceImpact.bestPrice),
		worstPrice: MAX_PREDICTION_PRICE.sub(priceImpact.worstPrice),
	};
};

/**
 * *IMPORTANT*: Ensure that the price impact is inverted back to the original price before submitting an order.
 * This is only important when `isSellPredictionMarket` is true.
 */
export const getPriceImpactForTargetMarket = (
	tradeInfo: {
		marketId: MarketId;
		side: 'buy' | 'sell';
		quoteSize: BN;
		leadSide: 'quote' | 'base';
		baseSize: BN;
		uiOrderType: UIOrderType;
		isSellPredictionMarket: boolean;
	},
	getState: GetState<DriftStore>,
	getMarketStateStore: GetState<MarketStateStore>,
	oraclePrice: BigNum
): PriceImpactInfo => {
	const { side, quoteSize, leadSide, baseSize } = tradeInfo;

	const l2State = getMarketStateStore().getDlobStateForMarket(
		tradeInfo.marketId
	);
	const l2StateToUse = tradeInfo.isSellPredictionMarket
		? invertL2State(l2State)
		: l2State;

	const driftClient = getState().driftClient?.client;

	const getPriceImpact = (): PriceImpactInfo => {
		if (!oraclePrice) return;
		if (!driftClient) return;

		const direction =
			side === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT;

		const ZERO = new BN(0);
		let [entryPrice, priceImpact, baseFilled, bestPrice, worstPrice] = [
			ZERO,
			ZERO,
			ZERO,
			ZERO,
			ZERO,
		];
		const exceedsLiquidity = false; // TODO: fix logic for this, below logic shouldn't be used for exceeding orderbook liquidity

		if (tradeInfo.marketId.isPerp) {
			try {
				if (leadSide === 'base') {
					const entryResult = calculateEstimatedEntryPriceWithL2(
						leadSide,
						baseSize,
						direction,
						BASE_PRECISION,
						l2StateToUse
					);

					entryPrice = entryResult?.entryPrice || ZERO;
					priceImpact = entryResult?.priceImpact || ZERO;
					baseFilled = entryResult?.baseFilled || ZERO;
					bestPrice = entryResult?.bestPrice || ZERO;
					worstPrice = entryResult?.worstPrice || ZERO;
				} else {
					const entryResult = calculateEstimatedEntryPriceWithL2(
						leadSide,
						quoteSize,
						direction,
						BASE_PRECISION,
						l2StateToUse
					);
					entryPrice = entryResult?.entryPrice || ZERO;
					priceImpact = entryResult?.priceImpact || ZERO;
					baseFilled = entryResult?.baseFilled || ZERO;
					bestPrice = entryResult?.bestPrice || ZERO;
					worstPrice = entryResult?.worstPrice || ZERO;
				}
			} catch (e) {
				// sdk function may throw error if no liquidity, do nothing
			}
		} else {
			const marketAccount = driftClient.getSpotMarketAccount(
				tradeInfo.marketId.marketIndex
			);

			try {
				if (marketAccount) {
					const entryResult = calculateEstimatedEntryPriceWithL2(
						leadSide,
						leadSide === 'base' ? baseSize : quoteSize,
						direction,
						SPOT_MARKETS_LOOKUP[tradeInfo.marketId.marketIndex].precision,
						l2State
					);

					entryPrice = entryResult?.entryPrice || ZERO;
					priceImpact = entryResult?.priceImpact || ZERO;
					baseFilled = entryResult?.baseFilled || ZERO;
					bestPrice = entryResult?.bestPrice || ZERO;
					worstPrice = entryResult?.worstPrice || ZERO;
				}
			} catch (e) {
				// sdk function may throw error if no liquidity, do nothing
			}
		}

		const thresholdPct = BigNum.from(entryPrice, PRICE_PRECISION_EXP).scale(
			Env.priceDivergenceWarningThreshold,
			100
		);
		const oracleDivergence = BigNum.from(
			entryPrice.sub(oraclePrice.val),
			PRICE_PRECISION_EXP
		).abs();
		const isBadPriceDivergence =
			(ENUM_UTILS.match(direction, PositionDirection.LONG) &&
				entryPrice.gt(oraclePrice.val)) ||
			(ENUM_UTILS.match(direction, PositionDirection.SHORT) &&
				entryPrice.lt(oraclePrice.val));
		const showPriceEstimateOracleDivergenceWarning =
			tradeInfo.uiOrderType === 'market' &&
			(baseSize.gt(ZERO) ? oracleDivergence.gt(thresholdPct) : false) &&
			isBadPriceDivergence;

		return {
			marketIndex: tradeInfo.marketId.marketIndex,
			marketType: tradeInfo.marketId.marketType,
			entryPrice,
			priceImpact,
			baseAvailable: baseFilled,
			bestPrice,
			worstPrice,
			priceImpactInputBaseSize: baseSize,
			showPriceEstimateOracleDivergenceWarning,
			exceedsLiquidity,
		};
	};

	const priceImpact = getPriceImpact();

	return priceImpact;
};

export const useKeepTradeformPriceImpactInSync = (refreshSpeedMs = 1000) => {
	const selectedMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	const selectedMarketConfig = useDriftStore(
		(s) => s.selectedMarket.current.market
	);

	const getOraclePriceForMarket = useGetOraclePriceForMarket();

	const setState = useDriftStore((s) => s.set);
	const getState = useDriftStore((s) => s.get);
	const getDlobState = useMarketStateStore((s) => s.get);

	const side = useDriftStore((s) => s.tradeForm.side);
	const quoteSizeStringValue = useDriftStore(
		(s) => s.tradeForm.quoteSizeStringValue
	);
	const quoteSize = useMemo(
		() =>
			NumLib.formatNum.toRawBn(Number(quoteSizeStringValue), QUOTE_PRECISION),
		[quoteSizeStringValue]
	);
	const leadSide = useDriftStore((s) => s.tradeForm.leadSide);
	const baseSizeStringValue = useDriftStore(
		(s) => s.tradeForm.baseSizeStringValue
	);
	const baseSize = useMemo(
		() =>
			BigNum.fromPrint(
				baseSizeStringValue,
				selectedMarketId.isPerp
					? BASE_PRECISION_EXP
					: (selectedMarketConfig as SpotMarketConfig).precisionExp
			).val,
		[baseSizeStringValue, selectedMarketId.isPerp, selectedMarketConfig]
	);

	const uiOrderType = useDriftStore((s) => s.tradeForm.orderType);
	const isSellPredictionMarket = useDriftStore((s) =>
		s.checkIsSellPredictionMarket()
	);

	const refreshPriceImpact = useCallback(() => {
		const oraclePrice = getOraclePriceForMarket(selectedMarketId);
		const priceImpact = getPriceImpactForTargetMarket(
			{
				side,
				quoteSize,
				leadSide,
				baseSize,
				marketId: selectedMarketId,
				uiOrderType,
				isSellPredictionMarket,
			},
			getState,
			getDlobState,
			oraclePrice
		);

		setState((s) => {
			if (
				priceImpact &&
				(!priceImpact?.entryPrice.eq(s.tradeForm.priceImpact?.entryPrice) ||
					!priceImpact?.priceImpactInputBaseSize.eq(
						s.tradeForm.priceImpact?.priceImpactInputBaseSize
					))
			) {
				s.tradeForm.priceImpact = priceImpact;
			}
		});
	}, [
		side,
		quoteSize,
		leadSide,
		baseSize,
		selectedMarketId,
		uiOrderType,
		isSellPredictionMarket,
		getState,
		getDlobState,
		getOraclePriceForMarket,
		setState,
	]);

	useInterval(() => {
		refreshPriceImpact();
	}, refreshSpeedMs);

	useEffect(() => {
		refreshPriceImpact();
	}, [refreshPriceImpact]);
};

export const useTradeformPriceImpact = () => {
	const tradeFormPriceImpact = useDriftStore((s) => s.tradeForm.priceImpact);

	return tradeFormPriceImpact;
};
