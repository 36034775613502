import { create } from 'zustand';
import produce from 'immer';
import { PublicKey } from '@solana/web3.js';
import { dlog } from 'src/dev';

export type TokenAccountInfo = {
	pubkey: PublicKey;
	mint: PublicKey;
	programId: PublicKey;
	parsedBalance: number;
};

export type TokenAccountMap = {
	[mintAddress: string]: TokenAccountInfo | null;
};

export interface SPLTokenAccountsStore {
	// Core state
	tokenAccounts: TokenAccountMap;
	lastSyncedWallet: string | null;

	// Methods
	set: (x: (s: SPLTokenAccountsStore) => void) => void;
	get: () => SPLTokenAccountsStore;

	// Helper methods
	getTokenAccount: (mintAddress: string) => TokenAccountInfo | null;
	setTokenAccount: (
		mintAddress: string,
		account: TokenAccountInfo | null
	) => void;
	clearAllTokenAccounts: () => void;
	setLastSyncedWallet: (walletPubkey: PublicKey | null) => void;
	updateTokenAccountBalance: (mintAddress: string, balance: number) => void;
}

const DEFAULT_STATE: Pick<
	SPLTokenAccountsStore,
	'tokenAccounts' | 'lastSyncedWallet'
> = {
	tokenAccounts: {},
	lastSyncedWallet: null,
};

export const useTokenAccountsStore = create<SPLTokenAccountsStore>(
	(set, get) => ({
		...DEFAULT_STATE,

		// Core methods
		set: (fn) => set(produce(fn)),
		get: () => get(),

		// Helper methods
		getTokenAccount: (mintAddress: string) => {
			return get().tokenAccounts[mintAddress] ?? null;
		},

		setTokenAccount: (
			mintAddress: string,
			account: TokenAccountInfo | null
		) => {
			dlog(
				`spl_store`,
				`setting_token_account :: ${mintAddress} :: ${account.parsedBalance}`
			);
			set(
				produce((state: SPLTokenAccountsStore) => {
					state.tokenAccounts[mintAddress] = account;
				})
			);
		},

		clearAllTokenAccounts: () => {
			dlog(`spl_store`, `clearing_all_token_accounts`);
			set(
				produce((state: SPLTokenAccountsStore) => {
					state.tokenAccounts = {};
					state.lastSyncedWallet = null;
				})
			);
		},

		setLastSyncedWallet: (walletPubkey: PublicKey | null) => {
			set(
				produce((state: SPLTokenAccountsStore) => {
					state.lastSyncedWallet = walletPubkey?.toBase58() ?? null;
				})
			);
		},

		updateTokenAccountBalance: (mintAddress: string, balance: number) => {
			set(
				produce((state: SPLTokenAccountsStore) => {
					state.tokenAccounts[mintAddress].parsedBalance = balance;
				})
			);
		},
	})
);
