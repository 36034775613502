'use client';

import Tooltip from '../Tooltip/Tooltip';
import Text from '../Text/Text';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { memo, useState } from 'react';
import { NEW_ACCOUNT_DONATION } from '@drift/common';
import { Alert } from '../Alert';
import Checkbox from '../Checkbox';
import { twMerge } from 'tailwind-merge';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { Info, Open } from '@drift-labs/icons';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';

const NewAccountCreationCost = (props: {
	forceAgreement?: boolean;
	checkboxUpdated?: (selection: boolean) => void;
}) => {
	const accountCostBreakdown = useAccountCreationCost();
	const isWalletConnected = useWalletIsConnected();

	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const [userHasAgreed, setUserHasAgreed] = useState(false);

	const handleCheckboxClick = () => {
		const newVal = !userHasAgreed;
		props.checkboxUpdated(newVal);
		setUserHasAgreed(newVal);
	};

	const rentCostOfNewAccount = accountCostBreakdown.baseAccountRent.add(
		accountCostBreakdown.extraRent
	);

	const minSolToDeposit = accountCostBreakdown.minSolToDeposit;

	const userHasEnoughSol = solBalance.gte(minSolToDeposit);

	const accountCostBreakdownTooltip = (
		<>
			<Tooltip
				content={
					<div className="px-2">
						<div className="mb-1">
							<Text.BODY3>
								New Account Fee:{' '}
								{accountCostBreakdown.newAccountDonation.prettyPrint()} SOL
							</Text.BODY3>
						</div>
						<div className="mb-1">
							<Text.BODY3>
								Rent: {rentCostOfNewAccount.toFixed(4)} SOL
							</Text.BODY3>
						</div>
						<div className="mb-3">
							<Text.BODY3>
								Minimum SOL for fees:{' '}
								{accountCostBreakdown.minSolForFees.prettyPrint()} SOL
							</Text.BODY3>
						</div>
						<div className="mb-3">
							<Text.BODY3>
								Rent is currently higher due to increased user activity but can
								be reclaimed after 13 days, or when you delete your account.
							</Text.BODY3>
						</div>
						<a
							href="https://docs.drift.trade/getting-started/managing-subaccount"
							target="_blank"
							rel="noreferrer"
							className="flex items-center"
						>
							<Text.BODY3>Learn more </Text.BODY3>
							<Open size={14} className="ml-1 " />
						</a>
					</div>
				}
				className="inline-block"
				allowHover
			>
				<Info size={14} />
			</Tooltip>
		</>
	);

	const accountCostJsx = accountCostBreakdown.loaded ? (
		userHasEnoughSol ? (
			<div className="flex space-x-1">
				<span>
					{`Creating an account costs ${accountCostBreakdown.totalCost.toFixed(
						4
					)} SOL`}
				</span>
				<span>{accountCostBreakdownTooltip}</span>
			</div>
		) : (
			<div className="flex space-x-1">
				<span>
					{`To create a new account and deposit assets, you need to have ${minSolToDeposit.prettyPrint()} SOL in your wallet`}
				</span>
				<span>{accountCostBreakdownTooltip}</span>
			</div>
		)
	) : (
		<SkeletonValuePlaceholder loading className="w-8 h-2" />
	);

	return (
		<>
			{!userHasEnoughSol ? (
				<Alert
					type="warning"
					message={
						<div className="flex items-center space-x-1">
							{isWalletConnected ? (
								<span>Not enough SOL in your wallet</span>
							) : (
								<span>Minimum SOL required in wallet</span>
							)}
							{accountCostBreakdownTooltip}
						</div>
					}
					description={`To create a new account and deposit assets, you need to have ${minSolToDeposit.prettyPrint()} SOL in your wallet to cover account creation and transaction fees SOL.`}
				/>
			) : (
				<Alert
					type="warning"
					message={accountCostJsx}
					description={
						<div
							onClick={handleCheckboxClick}
							className={twMerge(
								`flex justify-start items-center`,
								props.forceAgreement && 'hover:cursor-pointer'
							)}
						>
							{props.forceAgreement && (
								<div className="mr-2">
									<Checkbox checked={userHasAgreed} />
								</div>
							)}
							<Text.INPUTLABEL01 className="items-center mt-1 ml-2 font-normal select-none text-text-default">
								I understand that dynamic fees are in place as a safe guard and
								that rent can be reclaimed upon account deletion, other than the{' '}
								{NEW_ACCOUNT_DONATION.prettyPrint()} SOL New Account Fee.
							</Text.INPUTLABEL01>
						</div>
					}
				/>
			)}
		</>
	);
};

export default memo(NewAccountCreationCost);
