'use client';

import { BridgeCard } from 'src/components/Onboarding/BridgePanel';
import { BRIDGE_OPTIONS } from 'src/constants/constants';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const BridgeTab = ({ onClose }: { onClose: () => void }) => {
	const countryCode = useDriftStore((s) => s.countryCode);
	// filter CCTP if user is russian bc they block russian requests and the flow can't be completed
	const bridgeOptions = BRIDGE_OPTIONS.filter(
		(bridgeOption) => bridgeOption.bridge !== 'cctp' || countryCode !== 'RU'
	);

	return (
		<div className="flex flex-col gap-3 px-4 py-6">
			{bridgeOptions.map((bridgeOption) => (
				<span onClick={onClose} key={bridgeOption.bridge}>
					<BridgeCard
						imageUrl={bridgeOption.imageUrl}
						title={bridgeOption.title}
						description={bridgeOption.description}
						route={bridgeOption.route}
						bridge={bridgeOption.bridge}
					/>
				</span>
			))}
		</div>
	);
};

export default BridgeTab;
