'use client';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const POSITIVE_GREEN_COLOR = 'var(--positive-green)';
export const NEGATIVE_RED_COLOR = 'var(--negative-red)';

export const getDateTicks = (
	firstDateMs: number,
	lastDateMs: number,
	interval: number,
	unit: dayjs.ManipulateType
) => {
	let lastDate = dayjs(lastDateMs);
	const firstDate = dayjs(firstDateMs);
	const ticks: number[] = [];

	while (lastDate.isSameOrAfter(firstDate)) {
		ticks.push(lastDate.valueOf());
		lastDate = lastDate.subtract(interval, unit);
	}

	return ticks.reverse();
};

type TimeBasedAxisInterval = '2h' | '1d';

export const getTimeBasedXAxisTicks = (
	firstDateMs: number,
	lastDateMs: number,
	interval: TimeBasedAxisInterval
): number[] => {
	if (interval === '2h') {
		// find the closest even hour that has past lastDateTs e.g. if lastDateTs represents 13:05, then we want 12:00
		let lastDateHour = dayjs(lastDateMs).hour();
		const isEvenHour = lastDateHour % 2 === 0;
		if (!isEvenHour) {
			lastDateHour -= 1;
		}

		const formattedLastDateMs = dayjs(lastDateMs)
			.hour(lastDateHour)
			.minute(0)
			.valueOf();

		return getDateTicks(firstDateMs, formattedLastDateMs, 2, 'hour');
	} else if (interval === '1d') {
		// find start of day
		const formattedLastDateMs = dayjs(lastDateMs).hour(0).minute(0).valueOf();

		return getDateTicks(firstDateMs, formattedLastDateMs, 1, 'day');
	}

	return [];
};

export const getTimeBasedTickFormatter =
	(duration: TimeBasedAxisInterval) => (tick: number) => {
		if (duration === '2h') {
			return dayjs(tick).format('HH:mm');
		} else {
			return dayjs(tick).format('DD/MM/YYYY');
		}
	};

export const getTooltipDate = (
	ts: number,
	{
		isRelativeToToday = false,
		dateWithTime = true,
	}: {
		isRelativeToToday?: boolean;
		dateWithTime?: boolean;
	} = {
		isRelativeToToday: false,
		dateWithTime: true,
	}
) => {
	if (isRelativeToToday) {
		const isToday = dayjs(ts).isSame(dayjs(), 'date');
		const isYesterday = dayjs(ts).isSame(dayjs().subtract(1, 'day'), 'date');
		const isTomorrow = dayjs(ts).isSame(dayjs().add(1, 'day'), 'date');

		if (isToday || isYesterday || isTomorrow) {
			return dayjs(ts).format(
				`h:mm A [${isToday ? 'Today' : isYesterday ? 'Yesterday' : 'Tomorrow'}]`
			);
		}
	}

	return dayjs(ts).format(`[Date:]${dateWithTime ? ' h:mm A' : ''} DD/MM/YYYY`);
};

/**
 * The color of the area under the graph/line of graph works as such:
 *
 * The highest point (y-axis) of the graph is 0%, the lowest point is 100%.
 * We set the colors to change at X% of the height of the graph.
 * Since we want the colors to change at the x-axis (0 line), we find
 * the percentage of the graph from the top of the graph to the x-axis,
 * and set the colors to change at this offset.
 */
export const getAreaStops = (
	min: number,
	max: number,
	{ endOpacity = 1, startOpacity = 1 } = { endOpacity: 1, startOpacity: 1 },
	{ topColor = POSITIVE_GREEN_COLOR, bottomColor = NEGATIVE_RED_COLOR } = {
		topColor: POSITIVE_GREEN_COLOR,
		bottomColor: NEGATIVE_RED_COLOR,
	}
) => {
	if (min >= 0) {
		return (
			<>
				<stop offset="0%" stopColor={topColor} stopOpacity={startOpacity} />
				<stop offset="100%" stopColor={topColor} stopOpacity={endOpacity} />
			</>
		);
	}

	if (max <= 0) {
		return (
			<>
				<stop offset="0%" stopColor={bottomColor} stopOpacity={endOpacity} />
				<stop
					offset="100%"
					stopColor={bottomColor}
					stopOpacity={startOpacity}
				/>
			</>
		);
	}

	const zeroOffset = (max / (max - min)) * 100;

	return (
		<>
			<stop offset="0%" stopColor={topColor} stopOpacity={1} />
			<stop
				offset={`${zeroOffset}%`}
				stopColor={topColor}
				stopOpacity={endOpacity}
			/>
			<stop
				offset={`${zeroOffset}%`}
				stopColor={bottomColor}
				stopOpacity={endOpacity}
			/>
			<stop offset="100%" stopColor={bottomColor} stopOpacity={1} />
		</>
	);
};

export enum HistoricalTokenPriceDuration {
	ONE_DAY = '1',
	ONE_WEEK = '7',
	ONE_MONTH = '30',
	NINETY_DAYS = '90',
}

/**
 * Get custom x axis ticks based on duration
 *
 * ONE_DAY: every 2 hours
 * ONE_WEEK: every 1 day
 * ONE_MONTH: every 2 days
 */
export const getDurationBasedXAxisTicks = (
	firstDateMs: number,
	lastDateMs: number,
	duration: HistoricalTokenPriceDuration
): number[] => {
	if (duration === HistoricalTokenPriceDuration.ONE_DAY) {
		// find the closest even hour that has past lastDateTs e.g. if lastDateTs represents 13:05, then we want 12:00
		let lastDateHour = dayjs(lastDateMs).hour();
		const isEvenHour = lastDateHour % 2 === 0;
		if (!isEvenHour) {
			lastDateHour -= 1;
		}

		const formattedLastDateMs = dayjs(lastDateMs)
			.hour(lastDateHour)
			.minute(0)
			.valueOf();

		return getDateTicks(firstDateMs, formattedLastDateMs, 2, 'hour');
	} else if (duration === HistoricalTokenPriceDuration.ONE_WEEK) {
		// find start of day
		const formattedLastDateMs = dayjs(lastDateMs).hour(0).minute(0).valueOf();

		return getDateTicks(firstDateMs, formattedLastDateMs, 1, 'day');
	} else if (duration === HistoricalTokenPriceDuration.ONE_MONTH) {
		// find start of day
		const formattedLastDateMs = dayjs(lastDateMs).hour(0).minute(0).valueOf();

		return getDateTicks(firstDateMs, formattedLastDateMs, 2, 'day');
	}

	return [];
};
