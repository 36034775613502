import {
	Vault,
	VAULT_SHARES_PRECISION_EXP,
	VaultDepositor,
} from '@drift-labs/vaults-sdk';
import {
	BigNum,
	BN,
	PERCENTAGE_PRECISION,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import {
	SerializedVaultDepositorRecord,
	SerializedVaultSnapshot,
} from 'src/db/schema';
import invariant from 'tiny-invariant';
import { UiVaultConfig } from 'src/@types/vaults';

/**
 * Returns the balance of a vault depositor, after fees if `afterFees` is true.
 */
export const getVaultDepositorBalance = (
	vaultDepositorAccountData:
		| (Pick<VaultDepositor, 'vaultShares'> &
				Partial<
					Pick<VaultDepositor, 'cumulativeProfitShareAmount' | 'netDeposits'>
				>)
		| undefined,
	vaultAccountData: Pick<Vault, 'totalShares' | 'profitShare'> | undefined,
	vaultTvlBase: BigNum | undefined,
	depositAssetPrecisionExp: BN,
	afterFees = false
) => {
	if (
		!vaultDepositorAccountData ||
		!vaultAccountData ||
		!vaultTvlBase ||
		vaultAccountData.totalShares?.eqn(0)
	) {
		return BigNum.from(0, depositAssetPrecisionExp);
	}

	const userVaultShares = BigNum.from(
		vaultDepositorAccountData.vaultShares,
		VAULT_SHARES_PRECISION_EXP
	);
	const totalVaultShares = BigNum.from(
		vaultAccountData.totalShares,
		VAULT_SHARES_PRECISION_EXP
	);

	const userBalanceBaseValue = userVaultShares
		.mul(vaultTvlBase)
		.div(totalVaultShares)
		.shiftTo(depositAssetPrecisionExp);

	if (afterFees) {
		invariant(
			vaultDepositorAccountData.cumulativeProfitShareAmount,
			'Need to provide cumulativeProfitShareAmount to calculate balance after fees'
		);
		invariant(
			vaultDepositorAccountData.netDeposits,
			'Need to provide netDeposits to calculate balance after fees'
		);

		const highWaterMark =
			vaultDepositorAccountData.cumulativeProfitShareAmount.add(
				vaultDepositorAccountData.netDeposits
			);

		const taxableGains = userBalanceBaseValue.sub(
			BigNum.from(highWaterMark, depositAssetPrecisionExp)
		);

		if (!taxableGains.gtZero()) {
			return userBalanceBaseValue;
		}

		const feesPayable = taxableGains.scale(
			new BN(vaultAccountData.profitShare),
			PERCENTAGE_PRECISION
		);
		const userBalanceAfterFees = userBalanceBaseValue.sub(feesPayable);

		return userBalanceAfterFees;
	}

	return userBalanceBaseValue;
};

export const getMaxDailyDrawdownFromHistory = (
	vaultSnapshots: Pick<
		SerializedVaultSnapshot,
		'ts' | 'totalAccountBaseValue' | 'netDeposits' | 'totalAccountQuoteValue'
	>[],
	valueField: 'totalAccountBaseValue' | 'totalAccountQuoteValue'
) => {
	const formattedSnapshots = vaultSnapshots.map((snapshot) => {
		const basePnl = new BN(snapshot.totalAccountBaseValue).sub(
			new BN(snapshot.netDeposits)
		);

		return {
			ts: +snapshot.ts,
			basePnl,
			tvlBase: new BN(snapshot[valueField]),
		};
	});

	const sortedSnapshots = formattedSnapshots.sort((a, b) => a.ts - b.ts);
	let maxDrawdown = 0;

	for (let i = 0; i < sortedSnapshots.length - 1; i++) {
		const currentDayAllTimeDayPnl = sortedSnapshots[i].basePnl;
		const previousDayAllTimeDayPnl = sortedSnapshots[i - 1]?.basePnl ?? ZERO;

		if (currentDayAllTimeDayPnl > previousDayAllTimeDayPnl) continue; // made profit for that day; no drawdown

		const currentDayPnl = currentDayAllTimeDayPnl.sub(previousDayAllTimeDayPnl);
		const currentDayTotalAccValue = sortedSnapshots[i].tvlBase;

		if (currentDayTotalAccValue.eqn(0)) {
			continue;
		}

		const drawdown =
			currentDayPnl
				.mul(PERCENTAGE_PRECISION)
				.div(currentDayTotalAccValue.sub(currentDayPnl))
				.toNumber() / PERCENTAGE_PRECISION.toNumber();

		if (drawdown < maxDrawdown) {
			maxDrawdown = drawdown;
		}
	}

	return maxDrawdown;
};

export const getApyToUse = (
	uiVaultConfig: UiVaultConfig,
	calculatedApy: number,
	numOfVaultSnapshots: number
) => {
	const isUsingTemporaryApy =
		uiVaultConfig.temporaryApy && numOfVaultSnapshots < 30;

	const apy = isUsingTemporaryApy ? uiVaultConfig.temporaryApy : calculatedApy;

	return {
		isUsingTemporaryApy,
		apy,
	};
};

export const getVaultApyDays = (numOfVaultSnapshots: number) => {
	const numOfDays = Math.min(numOfVaultSnapshots, 90);

	if (numOfDays < 2) {
		return `${numOfDays} day`;
	} else {
		return `${numOfDays} days`;
	}
};

export const getVaultDepositorNotionalNetDeposits = (
	vaultDepositorHistory: SerializedVaultDepositorRecord[]
) => {
	const notionalNetDeposits = vaultDepositorHistory.reduce((acc, record) => {
		const notionalBigNum = BigNum.from(
			record.notionalValue,
			QUOTE_PRECISION_EXP
		);

		if (record.action === 'deposit') {
			return acc.add(notionalBigNum);
		} else if (record.action === 'withdraw') {
			return acc.sub(notionalBigNum);
		} else {
			return acc;
		}
	}, BigNum.from(0, QUOTE_PRECISION_EXP));

	return notionalNetDeposits;
};
