import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { MarketPerformanceData } from 'src/@types/performance';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';

export function useFundingRateMarketPerformance({
	currentAccountPubKey,
	marketFilter,
}: {
	currentAccountPubKey: PublicKey;
	marketFilter: number | 'all';
}) {
	return useQuery<MarketPerformanceData[]>({
		queryKey: [
			'fundingRateMarketPerformance',
			currentAccountPubKey.toBase58(),
			marketFilter,
		],
		staleTime: 60_000,
		queryFn: async () => {
			const result = await ExchangeHistoryClient.getFullFundingRecordsForUser(
				currentAccountPubKey,
				marketFilter === 'all' ? undefined : marketFilter
			);

			if (result.success) {
				const allDataPoints = result?.body.data.fundingPaymentRecords.map(
					(record) => {
						return {
							date: new Date(record.ts.toNumber() * 1000),
							totalPnl: record.fundingPayment.toNum(),
						};
					}
				);
				const points90d = UI_UTILS.addZeroes(
					UI_UTILS.addInitialZero(
						UI_UTILS.cumulatizePnlPoints(allDataPoints, 'totalPnl'),
						'6m'
					),
					'totalPnl'
				);
				const points30d = UI_UTILS.addZeroes(
					UI_UTILS.cumulatizePnlPoints(
						UI_UTILS.addInitialZero(
							UI_UTILS.applyTimeFilter(allDataPoints, '30d'),
							'30d'
						),
						'totalPnl'
					),
					'totalPnl'
				);

				const points7d = UI_UTILS.addZeroes(
					UI_UTILS.cumulatizePnlPoints(
						UI_UTILS.addInitialZero(
							UI_UTILS.applyTimeFilter(allDataPoints, '7d'),
							'7d'
						),
						'totalPnl'
					),
					'totalPnl'
				);

				const points24h = UI_UTILS.addZeroes(
					UI_UTILS.cumulatizePnlPoints(
						UI_UTILS.addInitialZero(
							UI_UTILS.applyTimeFilter(allDataPoints, '24h'),
							'24h'
						),
						'totalPnl'
					),
					'totalPnl'
				);

				const performanceObj = {
					'24h': points24h,
					'7d': points7d,
					'30d': points30d,
					all: points90d,
				};

				return [
					{
						marketFilter,
						performance: performanceObj,
					},
				];
			}
		},
	});
}
