'use client';

import { Email as EmailIcon } from '@drift-labs/icons';
import styled from '@emotion/styled';
import React, {
	JSX,
	PropsWithChildren,
	Ref,
	useCallback,
	useMemo,
	useState,
} from 'react';
import Chevron from 'src/components/Icons/Chevron';
import RoundedGradientBorderBox from 'src/components/RoundedGradientBorderBox';
import NumLib from 'src/utils/NumLib';
import { INVARIANT_CHECKER_ATTRIBUTE_TYPES } from 'src/utils/UIInvariants/constants';
import { InvariantInputFieldValueType } from 'src/utils/UIInvariants/types';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';
import MarketIcon from '../Utils/MarketIcon';
import { handleSpecialRendering } from '../Utils/NumberDisplayV3';

type InputProps = {
	type: string;
	onChange: (value: string) => void;
	onEnter?: () => void;
	onEscape?: () => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	depsForOnChange?: any[];
	value?: string;
	stepAmount?: number;
	prefix?: string;
	prefixTextClass?: string;
	suffix?: string;
	suffixButton?: JSX.Element;
	showIconForMarketSymbol?: string;
	error?: boolean;
	disabled?: boolean;
	placeholder?: string;
	highlighted?: boolean;
	customRounding?: string;
	pattern?: string;
	stepSize?: number;
	className?: string;
	larger?: boolean;
	disableGradientBorder?: boolean;
	id?: string;
};

type StateHookProps = Pick<
	InputProps,
	| 'type'
	| 'onChange'
	| 'onEnter'
	| 'onEscape'
	| 'value'
	| 'stepAmount'
	| 'depsForOnChange'
	| 'stepSize'
>;

const StyledInput = styled.input`
	font-weight: 400;
`;

const Step = ({
	down,
	...props
}: {
	down?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<span
			className={`flex items-center justify-center hover:cursor-pointer opacity-50 hover:opacity-100 mr-1`}
			{...props}
		>
			<Chevron
				size={14}
				direction={down ? 'down' : 'up'}
				colour="var(--neutrals-30)"
			/>
		</span>
	);
};

const Steps = ({
	stepUp,
	stepDown,
}: {
	stepUp: () => void;
	stepDown: () => void;
}) => {
	return (
		<div className="flex flex-col justify-center h-full">
			<Step onClick={stepUp} />
			<Step down onClick={stepDown} />
		</div>
	);
};

const useTextFieldState = (props: StateHookProps) => {
	// Click event handler
	/**
	 * If the user clicks into a field with value '0' .. clear the field so that they can instantly start typing their new value
	 * @param e mouse event
	 */
	const handleClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
		e.stopPropagation();
		e.preventDefault();

		try {
			if (props.type === 'number' && props.value) {
				if (parseFloat(props.value) === 0) {
					props.onChange('');
				}
			}
		} catch (e) {
			// if parsing the value as a number fails, not a problem
		}
	};

	// Keyboard event handler

	const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
		switch (event.key) {
			case 'Enter':
				if (!props.onEnter) return;
				props.onEnter();
				return;
			case 'Escape':
				if (!props.onEscape) return;
				props.onEscape();
				return;
			default:
				return;
		}
	};

	const invalidNumberChars = ['e'];

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
		event
	) => {
		if (props.type === 'number') {
			if (invalidNumberChars.includes(event.key)) {
				event.preventDefault();
			}
		}
	};

	// Mouse Inside State

	const [mouseInside, setMouseInside] = useState(false);

	// Step Handlers

	const stepUp = () => {
		try {
			const newVal = NumLib.formatNum.toTradePrecision(
				parseFloat(props.value ? props.value : '0') + (props.stepAmount ?? 0)
			);
			props.onChange(`${newVal}`);
		} catch (e) {
			return;
		}
	};

	const stepDown = () => {
		try {
			const newVal = NumLib.formatNum.toTradePrecision(
				parseFloat(props.value ? props.value : '0') - (props.stepAmount ?? 0)
			);
			if (newVal >= 0) {
				props.onChange(`${newVal}`);
			}
		} catch (e) {
			return;
		}
	};

	// Change handler
	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value;

		if (props.stepSize) {
			value = UI_UTILS.roundToStepSizeIfLargeEnough(value, props.stepSize);
		}

		props.onChange(value);
	}, props.depsForOnChange ?? []);

	return {
		handleKeyUp,
		handleKeyDown,
		mouseInside,
		setMouseInside,
		stepUp,
		stepDown,
		handleClick,
		handleChange,
	};
};

type InputWrapperProps = Pick<InputProps, 'disabled'> & {
	className?: string;
	setMouseInside: (val: boolean) => void;
};

const InputWrapper = ({
	disabled,
	className,
	setMouseInside,
	children,
	...props
}: PropsWithChildren<InputWrapperProps>) => {
	return (
		<div
			className={`flex justify-center w-full relative ${className} ${
				disabled ? 'hover:cursor-not-allowed opacity-60' : ''
			}`}
			onMouseEnter={() => {
				setMouseInside(true);
			}}
			onMouseLeave={() => {
				setMouseInside(false);
			}}
			{...props}
		>
			{children}
		</div>
	);
};

const TextFieldPrefixOrSuffix = ({
	suffix,
	className,
	textClass,
}: {
	suffix: string;
	className?: string;
	textClass?: string;
}) => {
	return (
		<span
			className={twMerge(
				`pointer-events-none mt-0.5`,
				`text-text-default`,
				className,
				textClass
			)}
		>
			{suffix}
		</span>
	);
};

const SuffixHolder = (props: PropsWithChildren<{ className?: string }>) => {
	return (
		<div
			className={`absolute top-0 flex items-center h-full space-x-1 right-3 ${props.className} z-1 select-none`}
		>
			{props.children}
		</div>
	);
};

const PrefixHolder = (props: PropsWithChildren<{ className?: string }>) => {
	return (
		<div
			className={`absolute flex items-center h-full space-x-1 left-2 ${props.className} z-1 select-none`}
		>
			{props.children}
		</div>
	);
};

const ValueTooltip = ({ value }: { value: string }) => {
	return (
		<div className="absolute -top-[100%] left-0 px-2 py-1 text-xs bg-chip-default-bg rounded shadow-md text-text-default flex items-baseline">
			{value}
		</div>
	);
};

const Default = React.forwardRef(
	(
		{ onChange, className, ...props }: InputProps,
		ref: Ref<HTMLInputElement>
	) => {
		let rightPadding = 0;

		if (props.suffix) {
			rightPadding += props.suffix.length * 9 + 5;
		}

		if (props.showIconForMarketSymbol) {
			rightPadding += 20;
		}

		if (props.stepAmount) {
			rightPadding += 20 + 5;
		}

		const {
			handleKeyUp,
			handleKeyDown,
			handleClick,
			mouseInside,
			setMouseInside,
			stepUp,
			stepDown,
			handleChange,
		} = useTextFieldState({ onChange, ...props });

		const [isFocused, setIsFocused] = useState(false);

		const humanReadableValue = useMemo(() => {
			if (props.type !== 'number' || !props.value) return null;
			return handleSpecialRendering(props.value);
		}, [props.type, props.value]);

		const showTooltip =
			(mouseInside || isFocused) &&
			humanReadableValue &&
			props.value !== humanReadableValue;

		const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(true);
			props.onFocus?.(e);
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			setIsFocused(false);
			props.onBlur?.(e);
		};

		return (
			<InputWrapper
				setMouseInside={setMouseInside}
				className={twMerge('h-8 relative')}
			>
				{showTooltip && <ValueTooltip value={humanReadableValue} />}
				<RoundedGradientBorderBox
					className={twMerge(
						`absolute w-full h-[32px] text-sm inline-flex`,
						props.larger && 'h-[48px]'
					)}
					borderColour={`${
						props.error
							? `var(--red)`
							: props.highlighted
							? `var(--app-gradient)`
							: 'var(--tooltip-bg)'
					}`}
					borderWidth="1px"
					borderHoverClass={`${props.disabled ? `` : `var(--app-gradient)`}`}
					borderRadius={props.customRounding ?? '0.125rem'}
					disableGradientBorder={props.disableGradientBorder}
				>
					<PrefixHolder>
						<span
							className={twMerge(
								'text-text-default',
								props.larger && 'text-[18px]',
								props.larger && 'ml-1'
							)}
						>
							{props.prefix || null}
						</span>
					</PrefixHolder>
					<StyledInput
						type={props.type}
						value={props.value}
						onChange={handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						className={twMerge(
							`px-2 pt-0.5 w-full bg-input-bg text-text-input default-transition focus:outline-none border-none h-full`,
							props.type === 'number' && `font-numeral`,
							props.prefix && (props.larger ? 'pl-6' : `pl-5`),
							props.disabled && 'text-text-secondary',
							props.larger && 'text-[18px]',
							className
						)}
						disabled={props.disabled}
						onKeyUp={handleKeyUp}
						onKeyDown={handleKeyDown}
						onClick={handleClick}
						ref={ref}
						placeholder={props.placeholder}
						style={rightPadding ? { paddingRight: `${rightPadding}px` } : {}}
						pattern={props.pattern}
						{...props}
					/>
					<SuffixHolder>
						{props.stepAmount && mouseInside && !props.disabled && (
							<Steps stepUp={stepUp} stepDown={stepDown} />
						)}
						{props.showIconForMarketSymbol && (
							<div className="h-[18px] w-[18px]">
								<MarketIcon
									marketSymbol={props.showIconForMarketSymbol}
									sizeClass={'h-[18px] w-[18px]'}
									customHeight={18}
									customWidth={18}
								/>
							</div>
						)}
						{props.suffix && (
							<TextFieldPrefixOrSuffix
								suffix={props.suffix}
								className="text-xs"
							/>
						)}
						{props.suffixButton && (
							<div className="items-center justify-end">
								{props.suffixButton}
							</div>
						)}
					</SuffixHolder>
				</RoundedGradientBorderBox>
			</InputWrapper>
		);
	}
);

const Hero = React.forwardRef(
	(
		{
			onChange,
			...props
		}: Omit<InputProps, 'stepAmount'> & {
			subtextValue: string;
			subtextLabel: string;
			suffixNode: React.ReactNode;
			inputFieldValueType?: InvariantInputFieldValueType;
			subtextFieldValueType?: InvariantInputFieldValueType;
		},
		ref: Ref<HTMLInputElement>
	) => {
		let rightPadding = 0;

		if (props.suffix) {
			rightPadding += props.suffix.length * 9 + 5;
		}

		if (props.showIconForMarketSymbol) {
			rightPadding += 25;
		}

		const {
			handleKeyUp,
			handleKeyDown,
			handleClick,
			setMouseInside,
			handleChange,
		} = useTextFieldState({
			...props,
			onChange,
		});

		return (
			<InputWrapper
				setMouseInside={setMouseInside}
				className="h-16"
				{...{
					[INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType]: 'hero-wrapper',
				}}
			>
				<RoundedGradientBorderBox
					className={`absolute w-full h-16`}
					borderColour={`${props.error ? `var(--red)` : `var(--tooltip-bg)`}`}
					borderWidth="1px"
					borderHoverClass={`${props.disabled ? `` : `var(--app-gradient)`}`}
					borderRadius="0.125rem"
				>
					<StyledInput
						type={props.type}
						value={props.value}
						className={`px-3 pt-2 pb-6 h-full w-full bg-input-bg text-text-default default-transition focus:outline-none border-none text-xl leading-4 ${
							props.type === 'number' && `font-numeral`
						}`}
						disabled={props.disabled}
						onKeyUp={handleKeyUp}
						onKeyDown={handleKeyDown}
						onClick={handleClick}
						style={rightPadding ? { paddingRight: `${rightPadding}px` } : {}}
						ref={ref}
						onChange={handleChange}
						{...props}
						{...{
							[INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType]: 'hero',
							[INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType]:
								props.inputFieldValueType,
						}}
					/>
					<SuffixHolder>
						{props.suffixNode}
						{props.showIconForMarketSymbol && (
							<div className="h-[20px] w-[20px]">
								{props.showIconForMarketSymbol}
							</div>
						)}
						{props.suffix && (
							<TextFieldPrefixOrSuffix
								suffix={props.suffix}
								className="text-xl"
							/>
						)}
					</SuffixHolder>
					<div className="absolute bottom-2 left-3 text-text-label">
						<Text.BODY2>
							<span
								{...{
									[INVARIANT_CHECKER_ATTRIBUTE_TYPES.inputType]: 'hero-subtext',
									[INVARIANT_CHECKER_ATTRIBUTE_TYPES.valueType]:
										props.subtextFieldValueType,
								}}
							>
								{props.subtextValue}
							</span>{' '}
							<span>{props.subtextLabel}</span>
						</Text.BODY2>
					</div>
				</RoundedGradientBorderBox>
			</InputWrapper>
		);
	}
);

const Borderless = React.forwardRef(
	(
		{
			onChange,
			...props
		}: Omit<InputProps, 'stepAmount'> & {
			subtext: string;
			suffixNode: React.ReactNode;
			prefixNode?: React.ReactNode;
		},
		ref: Ref<HTMLInputElement>
	) => {
		let rightPadding = 0;

		if (props.suffix) {
			rightPadding += props.suffix.length * 9 + 5;
		}

		if (props.showIconForMarketSymbol) {
			rightPadding += 25;
		}

		const {
			handleKeyUp,
			handleKeyDown,
			handleClick,
			setMouseInside,
			handleChange,
		} = useTextFieldState({
			...props,
			onChange,
		});

		return (
			<InputWrapper
				setMouseInside={setMouseInside}
				className="h-14 sm:h-[68px]"
			>
				<div
					className={`absolute w-full h-14 sm:h-16 flex flex-row items-start justify-between`}
				>
					{props.prefixNode || null}
					<div className="relative flex flex-col items-start">
						<StyledInput
							type={props.type}
							value={props.value}
							className={`h-full w-full px-3 pt-4 pb-4 sm:pt-2 sm:pb-6 bg-transparent text-text-default default-transition focus:outline-none border-none text-3xl leading-4 placeholder-text-disabled`}
							disabled={props.disabled}
							onKeyUp={handleKeyUp}
							onKeyDown={handleKeyDown}
							onClick={handleClick}
							style={rightPadding ? { paddingRight: `${rightPadding}px` } : {}}
							ref={ref}
							onChange={handleChange}
							{...props}
						/>
						<div className="text-text-label absolute bottom-0 left-[10px]">
							<Text.BODY2>{props.subtext}</Text.BODY2>
						</div>
					</div>
					<div>
						{props.suffixNode}
						{props.showIconForMarketSymbol && (
							<div className="h-[20px] w-[20px]">
								{props.showIconForMarketSymbol}
							</div>
						)}
						{props.suffix && (
							<TextFieldPrefixOrSuffix
								suffix={props.suffix}
								className="text-xl"
							/>
						)}
					</div>
				</div>
			</InputWrapper>
		);
	}
);

const SubtleInput = styled.input`
	outline: none !important;
	border: none !important;
`;

const Search = React.forwardRef(
	(
		{
			onChange,
			...props
		}: Omit<InputProps, 'stepAmount'> & { className?: string },
		ref: Ref<HTMLInputElement>
	) => {
		const { handleKeyUp, handleKeyDown, handleClick, handleChange } =
			useTextFieldState({
				...props,
				onChange,
			});

		return (
			<SubtleInput
				type={props.type}
				value={props.value}
				onChange={handleChange}
				disabled={props.disabled}
				ref={ref}
				onKeyUp={handleKeyUp}
				onKeyDown={handleKeyDown}
				onClick={handleClick}
				{...props}
				className={twMerge(
					'w-full px-2 bg-transparent text-text-default default-transition focus:outline-none font-body',
					props.className
				)}
			/>
		);
	}
);

interface ClassNameProp {
	className?: string;
}

const Email = React.forwardRef(
	(
		{ onChange, className, ...props }: InputProps & ClassNameProp,
		ref: Ref<HTMLInputElement>
	) => {
		const {
			handleKeyUp,
			handleKeyDown,
			handleClick,
			setMouseInside,
			handleChange,
		} = useTextFieldState({ onChange, ...props });

		return (
			<InputWrapper
				setMouseInside={setMouseInside}
				className="relative w-full h-10 border rounded border-container-border"
			>
				<div className="absolute top-0 left-0 h-full w-[40px] bg-input-bg-hover rounded flex flex-col justify-center items-center text-text-label">
					<EmailIcon size={20} />
				</div>
				<StyledInput
					type={props.type}
					value={props.value}
					onChange={handleChange}
					className={`px-4 pl-14 w-full bg-input-bg text-text-input outline-none border-none h-full ${
						className || ''
					}`}
					disabled={props.disabled}
					onKeyUp={handleKeyUp}
					onKeyDown={handleKeyDown}
					onClick={handleClick}
					ref={ref}
					{...props}
				/>
			</InputWrapper>
		);
	}
);

const ComboBox = ({
	onChange,
	...props
}: Pick<
	InputProps,
	| 'highlighted'
	| 'onChange'
	| 'value'
	| 'type'
	| 'error'
	| 'disabled'
	| 'customRounding'
	| 'placeholder'
	| 'onBlur'
	| 'suffix'
	| 'pattern'
> & {
	label: string;
	textSizeClass?: string;
}) => {
	const {
		handleKeyUp,
		handleKeyDown,
		handleClick,
		setMouseInside,
		handleChange,
	} = useTextFieldState({ onChange, ...props });

	const textSizeClass = props.textSizeClass
		? props.textSizeClass
		: `text-[14px]`;

	return (
		<InputWrapper setMouseInside={setMouseInside}>
			<RoundedGradientBorderBox
				className={twMerge(`w-full text-sm inline-flex`)}
				borderColour={`${
					props.error
						? `var(--red)`
						: props.highlighted
						? `var(--app-gradient)`
						: 'var(--tooltip-bg)'
				}`}
				borderWidth="1px"
				borderHoverClass={`${props.disabled ? `` : `var(--app-gradient)`}`}
				borderRadius={props.customRounding ?? '0.125rem'}
			>
				<div className="flex w-full p-2 space-x-2 bg-input-bg">
					<div className={twMerge('text-text-emphasis', textSizeClass)}>
						{props.label}
					</div>
					<StyledInput
						type={props.type}
						value={props.value}
						// @ts-ignore
						onChange={handleChange}
						onBlur={props.onBlur}
						className={twMerge(
							`w-full bg-input-bg text-text-default default-transition focus:outline-none border-none h-full`,
							props.type === 'number' && `font-numeral`,
							'text-right',
							textSizeClass
						)}
						disabled={props.disabled}
						onKeyUp={handleKeyUp}
						onKeyDown={handleKeyDown}
						onClick={handleClick}
						placeholder={props.placeholder}
						pattern={props.pattern}
						{...props}
					/>
					<div className={twMerge('text-text-default', textSizeClass)}>
						{props.suffix}
					</div>
				</div>
			</RoundedGradientBorderBox>
		</InputWrapper>
	);
};

Search.displayName = 'SearchBox';

const TextField = {
	Default: Default,
	Email: React.memo(Email),
	Hero,
	Borderless,
	Search,
	ComboBox,
};

export default TextField;
