'use client';

import Text from 'src/components/Text/Text';
import React, { useState } from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import UnderlinedTabs from '../TabbedNavigation/UnderlinedTabs';
import { Token } from 'src/stores/types';
import AssetSelectList from './AssetSelectModal/AssetSelectList';
import StakeAccountsList from './AssetSelectModal/StakeAccountsList';
import { StakeAccountInfo } from 'src/@types/types';

const AssetSelectModal = ({ id }: { id?: string }) => {
	const setStore = useDriftStore((s) => s.set);
	const modalProps = useDriftStore((s) => s.modalsProps.showAssetSelectModal);
	const actions = useDriftActions();
	const [selectedTab, setSelectedTab] = useState('tokens');

	const tabOptions = modalProps.showStakeAccountsTab
		? [
				{
					label: 'Tokens',
					value: 'tokens',
				},
				{
					label: 'Stake Accounts',
					value: 'stakeAccounts',
				},
		  ]
		: [
				{
					label: 'Tokens',
					value: 'tokens',
				},
		  ];

	const onClose = () => {
		actions.showModal('showAssetSelectModal', false);
	};

	const handleSelectAsset = (selectedAsset: Token) => {
		setStore((s) => {
			s.modalsProps.showAssetSelectModal = {
				...s.modalsProps.showAssetSelectModal,
				selectedStakeAccount: undefined,
				selectedAsset,
			};
		});
		onClose();
	};

	const handleSelectStakeAccount = (selectedStakeAccount: StakeAccountInfo) => {
		setStore((s) => {
			s.modalsProps.showAssetSelectModal = {
				...s.modalsProps.showAssetSelectModal,
				selectedAsset: undefined,
				selectedStakeAccount,
			};
		});
		onClose();
	};

	return (
		<Modal onClose={onClose} id={id} className="h-auto px-4">
			<Modal.Body className="h-auto pb-0 mt-8">
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>Select</Text.H2>
				</Modal.Header>
				<div className="w-full">
					<div className="px-4">
						{modalProps.showStakeAccountsTab && (
							<UnderlinedTabs
								options={tabOptions}
								currentSelection={selectedTab}
								onChange={setSelectedTab}
								tabClassName="w-full text-[16px] align-center flex flex-row justify-center"
							/>
						)}
					</div>
					<div className="sm:max-h-[50vh]">
						{selectedTab === 'tokens' ? (
							<AssetSelectList
								assetType={'lsts'}
								assetList={modalProps.assetList}
								onSelect={handleSelectAsset}
								source={modalProps.source}
							/>
						) : (
							<StakeAccountsList onSelect={handleSelectStakeAccount} />
						)}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(AssetSelectModal);
