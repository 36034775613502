'use client';

import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useDriftAccountsStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import useAccountsLoaded from './useAccountsLoaded';
import useWalletIsConnected from './useWalletIsConnected';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import {
	COMMON_UI_UTILS,
	MarketId,
	OpenPosition,
	EQUALITY_CHECKS,
} from '@drift/common';
import { BN } from '@drift-labs/sdk';
import useMarketStateStore from 'src/stores/useMarketStateStore';
import { useEffect } from 'react';
import { DriftWindow } from '../window/driftWindow';
import useTickedAllSubAccounts from './useTickedAllSubAccounts';

const useSyncOpenPositions = () => {
	const connected = useWalletIsConnected();
	const accounts = useDriftAccountsStore((s) => Object.values(s.accounts));
	const accountsTicked = useTickedAllSubAccounts();
	const userAccountsLoaded = useAccountsLoaded();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);
	const marketDataState = useMarketStateStore((s) => s.marketDataState);

	const setAccountsState = useDriftAccountsStore((s) => s.set);

	const markPriceCallback = (marketIndex: number) => {
		return getMarketDataForMarket(MarketId.createPerpMarket(marketIndex))
			?.derivedState?.markPrice.val;
	};

	const getAndSetPositionsData = (accounts: AccountData[]) => {
		const newPositions = [];

		accounts?.forEach((acct) => {
			const user = acct.client;
			if (!user || !user.isSubscribed) return;

			const userAccountPositions = user.getUserAccount().perpPositions;

			const updatedPositions: OpenPosition[] =
				COMMON_UI_UTILS.getOpenPositionData(
					driftClient,
					userAccountPositions,
					user,
					PERP_MARKETS_LOOKUP,
					markPriceCallback
				);

			newPositions.push({
				userKey: acct.userKey,
				newPositions: updatedPositions,
			});
		});

		setAccountsState((s) => {
			newPositions.forEach(
				(item: { userKey: string; newPositions: OpenPosition[] }) => {
					const totalClaimablePnl = item.newPositions.reduce(
						(a, b) => a.add(b.unsettledPnl),
						new BN(0)
					);

					const totalUnrealizedPnl = item.newPositions.reduce(
						(a, b) => a.add(b.totalUnrealizedPnl),
						new BN(0)
					);

					// if there is some unclaimable pnl, store the market indexes sorted by difference, so we can prompt a user to settle it
					if (totalClaimablePnl.lt(totalUnrealizedPnl)) {
						const marketsNeedingSettlement = item.newPositions
							.filter((pos) => pos.totalUnrealizedPnl.gt(pos.unsettledPnl))
							.map((pos) => {
								return {
									diff: pos.totalUnrealizedPnl.sub(pos.unsettledPnl),
									marketIndex: pos.marketIndex,
								};
							})
							.sort((a, b) => b.diff.toNumber() - a.diff.toNumber())
							.map((val) => val.marketIndex);

						s.accounts[item.userKey].marginInfo.marketsNeedingSettlement =
							marketsNeedingSettlement;
					} else {
						s.accounts[item.userKey].marginInfo.marketsNeedingSettlement = [];
					}

					if (
						!totalClaimablePnl.eq(
							s.accounts[item.userKey].marginInfo.totalUnsettledPnl
						)
					) {
						s.accounts[item.userKey].marginInfo.totalUnsettledPnl =
							totalClaimablePnl;
					}

					const isSameAsPreviousPositions = EQUALITY_CHECKS.openPositionLists(
						s.accounts[item.userKey].openPerpPositions,
						item.newPositions
					);
					if (!isSameAsPreviousPositions) {
						s.accounts[item.userKey].openPerpPositions = [...item.newPositions];
					}

					s.accounts[item.userKey].positionsLoaded = true;
				}
			);

			DriftWindow.recordStartupTimeMetric('userHistoryLoaded');
		});
	};

	useEffect(() => {
		if (!connected) return;
		if (!userAccountsLoaded) return;
		if (!driftClientIsReady) return;
		if (!accounts || accounts.length === 0) return;

		getAndSetPositionsData(accounts);
	}, [
		accounts?.length,
		connected,
		userAccountsLoaded,
		driftClientIsReady,
		getMarketDataForMarket,
		marketDataState, // getMarketDataForMarket doesn't update when marketDataState changes,
		accountsTicked, // we don't want to put accounts as a dependency because it will cause a re-render loop, but we still want to ensure the displayed data is fresh, hence we use the ticked accounts as a dependency
	]);
};

export default useSyncOpenPositions;
