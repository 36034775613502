'use client';

import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import usePostHogCapture from './posthog/usePostHogCapture';
import { PosthogEvent } from 'src/providers/posthog/posthogConfig';
import { Properties } from 'posthog-js';

const useSetCollateralModalParams = () => {
	const set = useDriftStore((s) => s.set);
	const accountsSet = useDriftAccountStore((s) => s.set);
	const { captureEvent } = usePostHogCapture();

	const setCollateralModalParams = (params: {
		modalName: keyof Pick<
			DriftStore['modals'],
			| 'showDepositModal'
			| 'showWithdrawModal'
			| 'showBorrowModal'
			| 'showCollateralTransferModal'
		>;
		collateralTypeIndex: number;
		targetAccountKey: string;
		poolId: number;
		switchAccount?: boolean;
		event?: {
			name: PosthogEvent;
			properties?: { from: string } & Properties;
		};
	}) => {
		const {
			modalName,
			collateralTypeIndex,
			targetAccountKey,
			poolId,
			event,
			switchAccount,
		} = params;
		set((s) => {
			s.modals[modalName] = true;

			// this parent function is expected to be called from a CTA that is linked to an asset
			// hence we want to default the  asset to the asset that was clicked
			if (
				modalName === 'showWithdrawModal' ||
				modalName === 'showDepositModal'
			) {
				s.modalsProps[modalName] = {
					defaultMax: false,
				};
			}

			s.modalCollateralType = collateralTypeIndex;
			s.modalCollateralPoolId = poolId;
			s.modalTargetAccountKey = targetAccountKey;
		});
		if (switchAccount !== false) {
			accountsSet((s) => {
				s.currentUserKey = targetAccountKey;
			});
		}

		if (event) {
			captureEvent(event.name, event.properties);
		}
	};

	return setCollateralModalParams;
};

export default useSetCollateralModalParams;
