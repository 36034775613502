'use client';

import React, { useCallback, useMemo } from 'react';
import { Info } from '@drift-labs/icons';
import Modal from './Modal';
import useCurrentAccountOpenPerpPositions from 'src/hooks/useCurrentAccountOpenPerpPositions';
import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BigNum,
	MarketType,
	PRICE_PRECISION_EXP,
	PositionDirection,
	QUOTE_PRECISION_EXP,
	ZERO,
	calculateEstimatedEntryPriceWithL2,
} from '@drift-labs/sdk';
import Text from '../Text/Text';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useL2StateForMarket from 'src/hooks/useL2StateForMarket';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import CheckboxInput from '../CheckboxInput';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import MarketIcon from '../Utils/MarketIcon';
import Tooltip from '../Tooltip/Tooltip';
import useDriftActions from 'src/hooks/useDriftActions';
import { getPriceImpactForTargetMarket } from 'src/hooks/usePriceImpact';
import useMarketStateStore from '../../stores/useMarketStateStore';
import { COMMON_UI_UTILS, UIMarket } from '@drift/common';

const ClosePositionRow = ({ marketIndex }: { marketIndex: number }) => {
	const openPerpPositions = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.openPerpPositions
	);
	const openPositionInfo = openPerpPositions.find(
		(position) => position.marketIndex === marketIndex
	);
	const l2State = useL2StateForMarket(
		openPositionInfo.marketIndex,
		MarketType.PERP
	);

	const tradeFormSlippageTolerance = useDriftStore(
		(s) => s.tradeForm.slippageTolerance
	);

	const closeDirection =
		openPositionInfo.direction === 'long'
			? PositionDirection.SHORT
			: PositionDirection.LONG;

	const baseAmountOfCloseBN = openPositionInfo.baseSize.abs();

	const { entryPrice: estExitPrice, priceImpact: _priceImpact } =
		calculateEstimatedEntryPriceWithL2(
			'base',
			baseAmountOfCloseBN,
			closeDirection,
			BASE_PRECISION,
			l2State
		);

	const estExitPriceBigNum = BigNum.from(estExitPrice, PRICE_PRECISION_EXP);
	const baseSizeBigNum = BigNum.from(
		openPositionInfo.baseSize,
		BASE_PRECISION_EXP
	).abs();

	const quoteSize = baseSizeBigNum
		.shiftTo(QUOTE_PRECISION_EXP)
		.mul(estExitPriceBigNum)
		.toNum();

	const { takerFeeBps } = useCalculateTradeFee({
		quoteSize,
		marketType: MarketType.PERP,
		marketIndex: openPositionInfo.marketIndex,
	});

	const {
		estimatedProfit,
		notionalSizeAtEntry,
		notionalSizeAtExit,
		estimatedProfitBeforeFees,
		estimatedTakerFee,
	} = COMMON_UI_UTILS.calculatePotentialProfit({
		currentPositionSize: BigNum.from(
			openPositionInfo.baseSize,
			BASE_PRECISION_EXP
		).abs(),
		currentPositionDirection:
			openPositionInfo.direction === 'long'
				? PositionDirection.LONG
				: PositionDirection.SHORT,
		currentPositionEntryPrice: BigNum.from(
			openPositionInfo.entryPrice,
			PRICE_PRECISION_EXP
		),
		tradeDirection: closeDirection,
		exitBaseSize: baseSizeBigNum,
		exitPrice: estExitPriceBigNum,
		slippageTolerance: tradeFormSlippageTolerance,
		takerFeeBps,
		isMarketOrder: true,
	});

	return (
		<div className="py-4 space-y-1 text-text-default">
			<div className="flex flex-row items-center justify-start mb-2 space-x-2">
				<MarketIcon
					marketSymbol={openPositionInfo.marketSymbol}
					sizeClass="w-4 h-4"
				/>
				<Text.H5 className="font-normal text-text-emphasis">
					{baseSizeBigNum.abs().toFixed(4)} {openPositionInfo.marketSymbol}
				</Text.H5>
			</div>
			<div className="flex flex-row items-center justify-between w-full space-x-4">
				<Text.BODY3 className="text-text-secondary">Est. Exit Price</Text.BODY3>
				<Text.BODY3>{estExitPriceBigNum.toNotional()}</Text.BODY3>
			</div>

			<div className="flex flex-row items-center justify-between w-full">
				<Tooltip
					placement="bottom"
					content={
						<div className="w-full space-y-1">
							<div className="flex flex-row justify-between w-full space-x-4">
								<Text.BODY3>Notional size at entry:</Text.BODY3>
								<Text.BODY3>
									{' '}
									{notionalSizeAtEntry.abs().toNotional()}
								</Text.BODY3>
							</div>
							<div className="flex flex-row justify-between w-full space-x-4">
								<Text.BODY3>Notional size at exit:</Text.BODY3>
								<Text.BODY3>
									{' '}
									{notionalSizeAtExit.abs().toNotional()}
								</Text.BODY3>
							</div>
							<div className="flex flex-row justify-between w-full space-x-4">
								<Text.BODY3>Est. P&L before fees:</Text.BODY3>
								<Text.BODY3>
									{' '}
									{estimatedProfitBeforeFees.toNotional()}
								</Text.BODY3>
							</div>
							<div className="flex flex-row justify-between w-full space-x-4">
								<Text.BODY3>Est. taker fee:</Text.BODY3>
								<Text.BODY3>
									{' '}
									~ {estimatedTakerFee.abs().toNotional()}
								</Text.BODY3>
							</div>
						</div>
					}
				>
					<Text.BODY3 className="text-text-secondary">
						Est. realized P&L{' '}
						<Info size={14} className="relative top-0.5 left-1" />
					</Text.BODY3>
				</Tooltip>
				<Text.BODY3
					className={
						estimatedProfit.gteZero()
							? 'text-positive-green'
							: 'text-negative-red'
					}
				>
					{estimatedProfit.toNotional()}
				</Text.BODY3>
			</div>
		</div>
	);
};

const CloseAllPositionsModal = () => {
	const getDriftStoreState = useDriftStore((s) => s.get);
	const actions = useDriftActions();
	const getMarketStateStore = useMarketStateStore((s) => s.get);
	const getOracleData = useMarketStateStore((s) => s.getOracleDataForMarket);
	const checkIsSellPredictionMarket = useDriftStore(
		(s) => s.checkIsSellPredictionMarket
	);

	const modalProps = useDriftStore((s) => s.modalsProps.closeAllPositionsModal);

	const perpMarketIndexesToClose = modalProps.perpMarketIndexesToClose || [];

	const onClose = () => {
		actions.showModal('showCloseAllPositionsModal', false);
	};

	const [currentSettings, updateSettings] = useCurrentSettings();
	const positions = useCurrentAccountOpenPerpPositions();

	const filteredPositions = useMemo(
		() =>
			positions
				// Filter for prediction market or perp market filter
				.filter((pos) => perpMarketIndexesToClose.includes(pos.marketIndex))
				// Filter for non-zero base size
				.filter((pos) => !pos.baseSize.eq(ZERO)),
		[positions, perpMarketIndexesToClose]
	);

	const handleClosePositions = useCallback(async () => {
		actions.closeMultiplePerpPositions({
			positionsToCloseInfo: filteredPositions.map((position) => {
				const uiMarket = UIMarket.createPerpMarket(position.marketIndex);
				const marketId = uiMarket.marketId;
				const oracleData = getOracleData(marketId);
				const isSellPredictionMarket = checkIsSellPredictionMarket({
					isPredictionMarket: uiMarket.isPredictionMarket,
					isSellSide: position.direction === 'short',
				});

				const baseAmountOfCloseBN = position.baseSize.abs();

				const priceImpactInfo = getPriceImpactForTargetMarket(
					{
						side: position.direction === 'long' ? 'sell' : 'buy',
						quoteSize: ZERO,
						leadSide: 'base',
						baseSize: baseAmountOfCloseBN,
						marketId,
						uiOrderType: 'market',
						isSellPredictionMarket,
					},
					getDriftStoreState,
					getMarketStateStore,
					oracleData?.price
				);

				return {
					oraclePrice: oracleData?.price?.val || ZERO,
					position,
					priceImpactInfo,
				};
			}),
			cancelExistingOrders: currentSettings.cancelExistingOrdersOnClosePosition,
		});

		onClose();
	}, [filteredPositions]);

	const toggleCancelExistingOrdersOnClose = () => {
		updateSettings({
			cancelExistingOrdersOnClosePosition:
				!currentSettings.cancelExistingOrdersOnClosePosition,
		});
	};

	const ctaLabel = `Market Close ${filteredPositions.length} Position${
		filteredPositions.length > 1 ? 's' : ''
	}`;

	return (
		<Modal onClose={onClose} sizeConfig={{ xs: 12, sm: 8, md: 6, xl: 4 }}>
			<Modal.Body>
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>{ctaLabel}</Text.H2>
				</Modal.Header>
				<Modal.Body>
					<div className="p-6 pt-2">
						<div className="divide-y divide-container-border">
							{filteredPositions?.map((pos) => (
								<ClosePositionRow
									marketIndex={pos.marketIndex}
									key={pos.marketIndex}
								/>
							))}
							<div className="py-4">
								<CheckboxInput
									onChange={toggleCancelExistingOrdersOnClose}
									checked={currentSettings.cancelExistingOrdersOnClosePosition}
									secondaryStyle
									label={'Cancel existing open orders for these markets'}
								/>
							</div>
						</div>
						<Modal.Confirm
							onConfirm={handleClosePositions}
							customLabel={ctaLabel}
						/>
					</div>
				</Modal.Body>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(CloseAllPositionsModal);
