import { InvariantCheckingContext } from './types';

export const INVARIANT_CHECKER_INPUT_IDS = {
	baseSizeInput: 'base-size-input',
	quoteSizeInput: 'quote-size-input',
	orderDirectionInput: 'order-direction-input',
};

export const INVARIANT_CHECKER_INPUT_TYPES = {
	hero: 'hero',
	heroSubtext: 'hero-subtext',
	heroWrapper: 'hero-wrapper',
};

export const INVARIANT_CHECKER_ATTRIBUTE_TYPES = {
	// Use this attribute to classify the type of the element. E.g. it could be a hero input, heroSubtext input, slider input, etc.
	inputType: 'data-input-type',
	// Use this attribute to classify the type of the value of an element. E.g. it could be a price, a base size, an order direction, etc.
	valueType: 'data-value-type',
	// Use this attribute to store the value of the input element (when it's too hard to fetch it directly from the DOM another way). E.g. you might set it to the price value, the base size value, the order direction, etc.
	inputValue: 'data-input-value',
};

export const INVARIANT_CHECKER_CONTEXT_WRAPPER_IDS: Record<
	InvariantCheckingContext,
	string
> = {
	'perp-trade-form': 'trade-form-container',
	'perp-close-position-popup': 'close-position-popup',
	'prediction-trade-form': 'trade-form-container',
};
