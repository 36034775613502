'use client';

import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import React, { ReactNode, useEffect, useState } from 'react';
import {
	ChevronLeft,
	ChevronRight,
	ChevronsLeft,
	ChevronsRight,
} from '@drift-labs/icons';
import { useDebouncedCallback } from 'use-debounce';
import { notify } from 'src/utils/notifications';
import Button from './Button';
import PaginationInputPopup from './Popups/PaginationInputPopup';
import UI_UTILS from 'src/utils/uiUtils';
import useTargetedPopover from 'src/hooks/useTargetedPopover';

const Paginator = (props: {
	currentPage: number;
	pageSize: number;
	onPageChange: (newPage: number) => void;
	maxCount: number;
	noBorder?: boolean;
	leftChild?: ReactNode;
	fixedMaxPageIndex?: number;
}) => {
	const maxPages =
		props.fixedMaxPageIndex ??
		UI_UTILS.getPageNumForCount(props.maxCount, undefined, true);

	const [firstPageNumberShown, setFirstPageNumberShown] = useState(0);
	const [showFromLast, setShowFromLast] = useState(false);

	const isMobile = useIsMobileScreenSize();

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen: setShowPaginationInput,
		isPopoverOpen: showPaginationInput,
	} = useTargetedPopover(
		{
			placement: 'top',
		},
		{
			offset: 16,
			disableAutoPlacement: true,
		}
	);

	const debouncedExecutePageChange = useDebouncedCallback(
		(newPage: number) => {
			if (newPage < 0) {
				setShowFromLast(true);
			}

			props.onPageChange(newPage);
		},
		500,
		{ leading: true }
	);

	const handlePageChange = (newPage: number) => {
		if (debouncedExecutePageChange.isPending()) {
			notify({
				id: 'paginationRateLimit',
				type: 'info',
				message: 'Too many requests',
				description:
					"You can jump directly to a history page by clicking the '...' button",
			});
		}
		debouncedExecutePageChange(newPage);
	};

	useEffect(() => {
		const pageNumbersDisplayedInPaginator = [
			firstPageNumberShown,
			firstPageNumberShown + 1,
			firstPageNumberShown + 2,
		];

		if (
			(!pageNumbersDisplayedInPaginator.includes(props.currentPage) ||
				firstPageNumberShown > props.currentPage) &&
			props.currentPage !== maxPages
		) {
			setFirstPageNumberShown(props.currentPage);
		}
	}, [firstPageNumberShown, props.currentPage]);

	return (
		<>
			<div
				className={`px-1 md:px-4 py-1 flex justify-center md:justify-between text-neutrals-40 ${
					props.noBorder ? '' : 'border-t border-container-border'
				}`}
			>
				{props.leftChild ?? <span className="hidden md:block" />}

				<div className="md:mx-4 my-1 flex items-center h-[24px]">
					{!isMobile && (
						<Button.Ghost
							className="p-1 mx-1"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							disabled={props.currentPage == 0}
							onClick={(e) => {
								if (props.currentPage == 0) return;
								e?.stopPropagation();
								handlePageChange(0);
							}}
						>
							<ChevronsLeft
								size={20}
								color={
									props.currentPage == 0
										? 'var(--text-disabled)'
										: 'var(--text-label)'
								}
							/>
						</Button.Ghost>
					)}
					<Button.Ghost
						className="p-1 mx-1"
						size="SMALL"
						iconOnly
						roundedGradientBorder
						disabled={props.currentPage == 0}
						onClick={(e) => {
							if (props.currentPage == 0) return;
							e?.stopPropagation();
							handlePageChange(Math.max(0, props.currentPage - 1));
						}}
					>
						<ChevronLeft
							size={20}
							color={
								props.currentPage == 0
									? 'var(--text-disabled)'
									: 'var(--text-label)'
							}
						/>
					</Button.Ghost>
					<div className="flex items-center justify-center h-full font-numeral hover:cursor-pointer">
						<Button.Secondary
							className="mx-1 p-1 min-w-[28px]"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							selected={props.currentPage === firstPageNumberShown}
							onClick={(e) => {
								e?.stopPropagation();
								handlePageChange(firstPageNumberShown);
							}}
						>
							<div>{firstPageNumberShown + 1}</div>
						</Button.Secondary>
						{maxPages > 0 && (
							<Button.Secondary
								className="mx-1 p-1 min-w-[28px]"
								size="SMALL"
								iconOnly
								roundedGradientBorder
								selected={props.currentPage === firstPageNumberShown + 1}
								onClick={(e) => {
									e?.stopPropagation();
									handlePageChange(firstPageNumberShown + 1);
								}}
							>
								<div>{firstPageNumberShown + 2}</div>
							</Button.Secondary>
						)}
						{}
						{maxPages > 1 && (
							<Button.Secondary
								className="mx-1 p-1 min-w-[28px]"
								size="SMALL"
								iconOnly
								roundedGradientBorder
								selected={props.currentPage === firstPageNumberShown + 2}
								onClick={(e) => {
									e?.stopPropagation();
									handlePageChange(firstPageNumberShown + 2);
								}}
							>
								<div>{firstPageNumberShown + 3}</div>
							</Button.Secondary>
						)}
						{maxPages >= 6 && (
							<Button.Secondary
								className="mx-1 p-1 min-w-[28px]"
								size="SMALL"
								iconOnly
								roundedGradientBorder
								onClick={(e) => {
									e?.stopPropagation();
									setShowPaginationInput(true);
								}}
							>
								<div
									ref={refs.setReference}
									{...getReferenceProps()}
									className="select-none hover:cursor-pointer"
								>
									...
								</div>
							</Button.Secondary>
						)}
						{showFromLast && (
							<>
								{maxPages - 1 > firstPageNumberShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages - 2}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages - 2);
										}}
									>
										<div>{maxPages - 1}</div>
									</Button.Secondary>
								)}
								{maxPages > firstPageNumberShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages - 1}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages - 1);
										}}
									>
										<div>{maxPages}</div>
									</Button.Secondary>
								)}
								{maxPages + 1 > firstPageNumberShown + 3 && (
									<Button.Secondary
										className="mx-1 p-1 min-w-[28px]"
										size="SMALL"
										iconOnly
										roundedGradientBorder
										selected={props.currentPage === maxPages}
										onClick={(e) => {
											e?.stopPropagation();
											handlePageChange(maxPages);
										}}
									>
										<div>{maxPages + 1}</div>
									</Button.Secondary>
								)}
							</>
						)}
					</div>
					<Button.Ghost
						className="p-1 mx-1"
						size="SMALL"
						iconOnly
						roundedGradientBorder
						disabled={props.currentPage == maxPages}
						onClick={(e) => {
							if (props.currentPage == maxPages) return;
							e?.stopPropagation();
							handlePageChange(Math.min(maxPages, props.currentPage + 1));
						}}
					>
						<ChevronRight
							size={20}
							color={
								props.currentPage == maxPages
									? 'var(--text-disabled)'
									: 'var(--text-label)'
							}
						/>
					</Button.Ghost>
					{!isMobile && (
						<Button.Ghost
							className="p-1 mx-1"
							size="SMALL"
							iconOnly
							roundedGradientBorder
							disabled={props.currentPage == maxPages}
							onClick={(e) => {
								if (props.currentPage == maxPages) return;
								e?.stopPropagation();
								handlePageChange(-1);
							}}
						>
							<ChevronsRight
								size={20}
								color={
									props.currentPage == maxPages
										? 'var(--text-disabled)'
										: 'var(--text-label)'
								}
							/>
						</Button.Ghost>
					)}
				</div>
			</div>

			{showPaginationInput && (
				<PaginationInputPopup
					setFloating={refs.setFloating}
					floatingStyles={floatingStyles}
					getFloatingProps={getFloatingProps}
					initialValue={props.currentPage + 1}
					maxValue={maxPages + 1}
					onClose={(newVal: number) => {
						setShowPaginationInput(false);

						if (newVal - 1 < 0) return;
						if (newVal - 1 > maxPages) return;

						if (newVal - 1 !== props.currentPage) handlePageChange(newVal - 1);
					}}
				/>
			)}
		</>
	);
};

export default Paginator;
