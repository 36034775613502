'use client';

import {
	BigNum,
	BN,
	MarketStatus,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	MAIN_POOL_ID,
	MarketId,
} from '@drift/common';
import { Open } from '@drift-labs/icons';
import React, { useEffect, useState } from 'react';
import {
	CurrentSpotMarkets,
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDisplayPrecisionForCollateral from 'src/hooks/useDisplayPrecisionForCollateral';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftClient from 'src/hooks/useDriftClient';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSafePush from 'src/hooks/useSafePush';
// import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import UI_UTILS from 'src/utils/uiUtils';
import useAccountsBeingLiquidated from '../hooks/Liquidations/useAccountsBeingLiquidated';
import useMarkPrice from '../hooks/useMarkPrice';
import useTargetAccountData from '../hooks/useTargetAccountData';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { CollateralInput } from './Inputs/CollateralInput';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import Text from './Text/Text';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import {
	BorrowsDisabledMessage,
	getShouldBlockBorrows,
} from './PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import SettleUnsettledInfoMessage from './Utils/SettleUnsettledInfoMessage';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { DEFAULT_ACCOUNT_NAMES_BY_POOL_ID } from 'src/constants/constants';
import IsolatedPoolsHelpLink from './Earn/IsolatedPoolsHelpLink';

const BorrowModalContent = ({ onClose }: { onClose: () => void }) => {
	// # Constants
	const borrowData = useDriftStore((s) => s.borrowLendData);
	const actions = useDriftActions();
	const availableSubaccounts = useLazySubAccounts();
	const collateralTypeIndex = useDriftStore((s) => s.modalCollateralType);
	const selectedTargetAccountKey = useDriftStore(
		(s) => s.modalTargetAccountKey
	);
	const targetAccount = useTargetAccountData(selectedTargetAccountKey);
	const userAccount = targetAccount?.client?.getUserAccount() ?? null;
	const accountsBeingLiquidated = useAccountsBeingLiquidated();
	const safePush = useSafePush();
	const driftClient = useDriftClient();
	const { captureEvent } = usePostHogCapture();
	const driftClientIsReady = useDriftClientIsReady();
	const poolId = useDriftStore((s) => s.modalCollateralPoolId);

	const setState = useDriftStore((s) => s.set);
	// const setAccountState = useDriftAccountStore((s) => s.set);

	// # State
	const [submitting, setSubmitting] = useState(false);
	const [maxButtonTransition, setMaxButtonTransition] = useState(false);
	const [selectedCollatBank, setSelectedCollatBank] =
		useState<SpotMarketConfig>(OrderedSpotMarkets[collateralTypeIndex]);
	const [targetAccountKey, setTargetAccountKey] = useState(
		selectedTargetAccountKey
	);
	const [collatTransferAmount, setCollatTransferAmount] = useState('');
	const [isMax, setIsMax] = useState(false);

	const interestRateBigNum =
		borrowData
			?.find((item) => item.bankIndex == selectedCollatBank.marketIndex)
			?.borrowApr.mul(new BN(100)) ?? BigNum.zero();

	const [afterBorrowInterestRateBigNum, setAfterBorrowInterestRateBigNum] =
		useState(interestRateBigNum);

	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => `${account.userKey}` === `${selectedTargetAccountKey}`
	);

	const collateralDisplayPrecision =
		useDisplayPrecisionForCollateral(selectedCollatBank);

	const targetAccountBankBalance = useAccountTargetSpotBalance(
		selectedCollatBank,
		targetAccountKey,
		false
	);

	const targetAccountTotalNetQuoteValue = BigNum.from(
		targetAccount?.client?.getNetSpotMarketValue() ?? 0,
		QUOTE_PRECISION_EXP
	);

	const marketId = MarketId.createSpotMarket(selectedCollatBank.marketIndex);

	const spotMarketAccount = driftClient.getSpotMarketAccount(
		marketId.marketIndex
	);

	const markPrice = useMarkPrice(marketId);

	const getMarketInfo = useMarketsInfoStore()?.getMarketInfoByIndexAndType;

	const assetCurrentPrice =
		markPrice?.shiftTo(QUOTE_PRECISION_EXP) ?? BigNum.zero(QUOTE_PRECISION_EXP);

	const collatTransferAmountBignum = BigNum.fromPrint(
		collatTransferAmount ? collatTransferAmount : '0',
		selectedCollatBank.precisionExp
	);

	const quoteAmountToAdd =
		selectedCollatBank.symbol == 'USDC'
			? collatTransferAmountBignum.shiftTo(QUOTE_PRECISION_EXP)
			: collatTransferAmountBignum
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(assetCurrentPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

	const updateCollateralType = (newCollat: SpotMarketConfig) => {
		setSelectedCollatBank(newCollat);
		setState((s) => {
			s.modalCollateralType = newCollat.marketIndex;
		});
	};

	// # Effect Hooks
	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// If max is selected, keep input value in sync with max. allowed value
	useEffect(() => {
		if (isMax) {
			setCollatTransferAmount(
				targetAccountBankBalance.withdrawLimitBase.printShort()
			);
		}
	}, [isMax, targetAccountBankBalance.withdrawLimitBase?.toString()]);

	// keep the new interest rate updated with the borrow amount the user inputs
	useEffect(() => {
		if (spotMarketAccount) {
			const newInterestRateBigNum = UI_UTILS.getBorrowRateFromDelta(
				collatTransferAmountBignum.val.neg(),
				spotMarketAccount
			);
			setAfterBorrowInterestRateBigNum(newInterestRateBigNum);
		}
	}, [collatTransferAmountBignum?.toString(), spotMarketAccount.marketIndex]);

	// # Methods
	const handleWithdraw = async () => {
		setSubmitting(true);

		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedCollatBank.precisionExp
		);

		const isBorrow =
			targetAccountBankBalance.assetBaseBalance.lt(collateralAmount);

		const result = await actions.withdrawCollateral({
			amount: collateralAmount,
			spotMarketConfig: selectedCollatBank,
			userAccount: targetAccount?.client?.getUserAccount() ?? null,
			isBorrow,
		});

		await actions.fetchMarginAccountInfo(targetAccountKey);

		setSubmitting(false);

		captureEvent('submitted_borrow', {
			spot_market_symbol: selectedCollatBank.symbol,
		});

		if (result === true) {
			onClose();
		}
	};

	const handleValueChange = (newValue: string) => {
		setIsMax(false);
		setCollatTransferAmount(newValue);
	};

	const handleMax = () => {
		setCollatTransferAmount(
			targetAccountBankBalance.withdrawLimitBase.printShort()
		);
		setIsMax(true);
	};

	const handleTargetAccountChange = (accountKey: string) => {
		const { userId, userAuthority } =
			COMMON_UI_UTILS.getIdAndAuthorityFromKey(accountKey);

		if (
			userId !== undefined &&
			userAuthority !== undefined &&
			driftClientIsReady
		) {
			const account = driftClient.getUserAccount(userId, userAuthority);

			if (account.poolId !== poolId) {
				setState((s) => {
					s.modalCollateralPoolId = account.poolId;
				});
				setSelectedCollatBank(
					CurrentSpotMarkets.find((market) => market.poolId === account.poolId)
				);
			}
		}
		setTargetAccountKey(accountKey);
		setState((s) => {
			s.modalTargetAccountKey = accountKey;
		});
		// setAccountState((s) => {
		// 	s.currentUserKey = accountKey;
		// });
	};

	const isWithdraw =
		collatTransferAmountBignum.gtZero() &&
		targetAccountBankBalance.netBaseBalance.gte(collatTransferAmountBignum);

	const isPartialWithdraw =
		collatTransferAmountBignum.gtZero() &&
		targetAccountBankBalance.netBaseBalance.gtZero() &&
		collatTransferAmountBignum.gt(targetAccountBankBalance.netBaseBalance);

	const exceededMax = collatTransferAmountBignum.gt(
		targetAccountBankBalance.withdrawLimitBase
	);

	const shouldBlockBorrows =
		getShouldBlockBorrows(userAccount, getMarketInfo) &&
		selectedCollatBank.marketIndex !== 0;

	const allowConfirmation =
		!shouldBlockBorrows &&
		!submitting &&
		collatTransferAmount &&
		collatTransferAmountBignum.gt(
			BigNum.zero(selectedCollatBank.precisionExp),
			true
		) &&
		!selectedAccountCanbeLiqd;

	useEffect(() => {
		if (exceededMax) {
			setCollatTransferAmount(
				targetAccountBankBalance.withdrawLimitBase.print()
			);
		}
	}, [exceededMax]);

	const marketReduceOnly = ENUM_UTILS.match(
		spotMarketAccount.status,
		MarketStatus.REDUCE_ONLY
	);

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					{marketReduceOnly ? (
						<div className="items-center block">
							<Text.P1 className="mr-1 text-warn-yellow">
								Borrows are currently disabled on this market.
							</Text.P1>
						</div>
					) : (
						<div className="items-center block">
							<Text.P1 className="mr-1 text-text-emphasis">
								Borrowed amounts are withdrawn to your wallet.
							</Text.P1>
						</div>
					)}

					<Utility.VERTSPACERXL />

					{poolId !== MAIN_POOL_ID && (
						<>
							<InfoMessage
								type="warn"
								messageTitle=""
								message={
									<div className="flex flex-col gap-1">
										<Text.BODY2 className="text-text-emphasis font-semibold">
											Collateral Use Limitation
										</Text.BODY2>
										<Text.BODY2>
											This market is in an isolated pool. Only deposits within
											the same pool will be used as collateral for borrowing.
										</Text.BODY2>
										<IsolatedPoolsHelpLink />
									</div>
								}
							/>
							<Utility.VERTSPACERL />
						</>
					)}

					{availableSubaccounts.length > 1 && (
						<>
							<LabelledInput label="Select an account">
								<Select.Subaccount
									id="depositAccountSelection"
									onChange={handleTargetAccountChange}
									placeholderText={DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[poolId]}
									options={availableSubaccounts}
									initialSelection={targetAccountKey}
									disabled={!targetAccountKey && poolId !== MAIN_POOL_ID}
								/>
							</LabelledInput>
							<Utility.VERTSPACERXL />
						</>
					)}

					<CollateralInput
						selectedMarket={selectedCollatBank}
						maxAmount={targetAccountBankBalance.withdrawLimitBase}
						onChangeMarket={updateCollateralType}
						label="Asset to borrow and amount"
						value={
							marketReduceOnly
								? ''
								: isMax
								? targetAccountBankBalance.withdrawLimitBase.printShort()
								: collatTransferAmount
						}
						onChangeValue={handleValueChange}
						onMax={handleMax}
						amountLabel="Borrow limit"
						customElementRight={
							<Text.MICRO3
								onClick={() => {
									safePush('/earn/borrow-lend');
									onClose();
								}}
								className="inline-flex items-center text-th-grey-20 hover:cursor-pointer text-text-secondary"
							>
								<div className="mt-0.5">
									{`Borrow APR ${interestRateBigNum.prettyPrint()}%`}
								</div>
								<Open size={14} className="ml-1" />
							</Text.MICRO3>
						}
						tooltipText="Borrow limits are based on your account’s free collateral
						(which includes open orders) and liquidity limits within the
						protocol."
						disabled={marketReduceOnly}
						source={{
							type: 'default',
						}}
						poolId={poolId}
					/>

					<SettleUnsettledInfoMessage className={'mt-2'} onlyShowIfPositive />

					{shouldBlockBorrows && (
						<>
							<Utility.VERTSPACERL />
							<div className="max-w-[530px]">
								<BorrowsDisabledMessage />
							</div>
						</>
					)}

					{selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="error"
								messageTitle="Withdrawals from this account are paused"
								message={
									<>
										Once the account liquidation is complete, withdrawals will
										be enabled again.
									</>
								}
							/>
						</>
					)}

					{isWithdraw && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="info"
								messageTitle="Borrow amount is less than your deposits"
								message={
									<>
										The amount will be withdrawn from your account instead of
										borrowed.
									</>
								}
							/>
						</>
					)}

					{isPartialWithdraw && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="info"
								messageTitle="Total borrow amount will be less"
								message={
									<>
										{`Your ${selectedCollatBank.symbol} deposits will be withdrawn and the remaining amount will be borrowed.`}
									</>
								}
							/>
						</>
					)}

					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					{(isWithdraw || isPartialWithdraw) && (
						<>
							<ValueDisplay.ValueChange
								label="Asset balance"
								previousValue={targetAccountBankBalance.netBaseBalance}
								afterValue={targetAccountBankBalance.netBaseBalance.sub(
									collatTransferAmountBignum
								)}
								previousValuePrint={`${targetAccountBankBalance.netBaseBalance.prettyPrint()} ${
									selectedCollatBank.symbol
								}`}
								afterValuePrint={`${targetAccountBankBalance.netBaseBalance
									.sub(collatTransferAmountBignum)
									.prettyPrint()} ${selectedCollatBank.symbol}`}
							/>
							<Utility.VERTSPACERS />
						</>
					)}
					{!isWithdraw && (
						<>
							{isPartialWithdraw ? (
								<ValueDisplay.ValueChange
									label="Total borrowed"
									previousValue={targetAccountBankBalance.liabilityBaseBalance}
									afterValue={targetAccountBankBalance.liabilityBaseBalance.add(
										collatTransferAmountBignum.sub(
											targetAccountBankBalance.assetBaseBalance
										)
									)}
									previousValuePrint={`${targetAccountBankBalance.liabilityBaseBalance.toFixed(
										collateralDisplayPrecision
									)} ${selectedCollatBank.symbol}`}
									afterValuePrint={`${targetAccountBankBalance.liabilityBaseBalance
										.add(
											collatTransferAmountBignum.sub(
												targetAccountBankBalance.assetBaseBalance
											)
										)
										.toFixed(collateralDisplayPrecision)} ${
										selectedCollatBank.symbol
									}`}
								/>
							) : (
								<ValueDisplay.ValueChange
									label="Total borrowed"
									previousValue={targetAccountBankBalance.liabilityBaseBalance}
									afterValue={targetAccountBankBalance.liabilityBaseBalance.add(
										collatTransferAmountBignum
									)}
									previousValuePrint={`${targetAccountBankBalance.liabilityBaseBalance.toFixed(
										collateralDisplayPrecision
									)} ${selectedCollatBank.symbol}`}
									afterValuePrint={`${targetAccountBankBalance.liabilityBaseBalance
										.add(collatTransferAmountBignum)
										.toFixed(collateralDisplayPrecision)} ${
										selectedCollatBank.symbol
									}`}
								/>
							)}
							<Utility.VERTSPACERS />
						</>
					)}
					<>
						<ValueDisplay.ValueChange
							label="Borrow available"
							previousValue={targetAccountBankBalance.withdrawLimitBase}
							afterValue={targetAccountBankBalance.withdrawLimitBase.sub(
								collatTransferAmountBignum
							)}
							previousValuePrint={`${targetAccountBankBalance.withdrawLimitBase.prettyPrint()} ${
								selectedCollatBank.symbol
							}`}
							afterValuePrint={`${targetAccountBankBalance.withdrawLimitBase
								.sub(collatTransferAmountBignum)
								.prettyPrint()} ${selectedCollatBank.symbol}`}
						/>
						<Utility.VERTSPACERS />
					</>
					<>
						<ValueDisplay.ValueChange
							label="Net Account Balance (USD)"
							previousValue={targetAccountTotalNetQuoteValue}
							afterValue={targetAccountTotalNetQuoteValue.sub(quoteAmountToAdd)}
							previousValuePrint={UI_UTILS.toNotional(
								targetAccountTotalNetQuoteValue.toNum()
							)}
							afterValuePrint={UI_UTILS.toNotional(
								targetAccountTotalNetQuoteValue.sub(quoteAmountToAdd).toNum()
							)}
						/>
						<Utility.VERTSPACERS />
					</>
					<ValueDisplay.ValueChange
						label="Interest Rate"
						previousValue={interestRateBigNum}
						afterValue={afterBorrowInterestRateBigNum}
						previousValuePrint={`${interestRateBigNum.prettyPrint()}%`}
						afterValuePrint={`${afterBorrowInterestRateBigNum.prettyPrint()}%`}
					/>

					<Utility.VERTSPACERL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleWithdraw}
						customLabel={`Confirm ${isWithdraw ? 'Withdrawal' : 'Borrow'}`}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(BorrowModalContent);
