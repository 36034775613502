"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateMaxPctToLiquidate = exports.calculateBaseAssetAmountToCoverMarginShortage = void 0;
const anchor_1 = require("@coral-xyz/anchor");
const numericConstants_1 = require("../constants/numericConstants");
function calculateBaseAssetAmountToCoverMarginShortage(marginShortage, marginRatio, liquidationFee, ifLiquidationFee, oraclePrice, quoteOraclePrice) {
    const marginRatioBN = new anchor_1.BN(marginRatio)
        .mul(numericConstants_1.LIQUIDATION_FEE_PRECISION)
        .div(numericConstants_1.MARGIN_PRECISION);
    const liquidationFeeBN = new anchor_1.BN(liquidationFee);
    if (oraclePrice.eq(new anchor_1.BN(0)) || marginRatioBN.lte(liquidationFeeBN)) {
        // undefined is max
        return undefined;
    }
    return marginShortage.mul(numericConstants_1.PRICE_TIMES_AMM_TO_QUOTE_PRECISION_RATIO).div(oraclePrice
        .mul(quoteOraclePrice)
        .div(numericConstants_1.PRICE_PRECISION)
        .mul(marginRatioBN.sub(liquidationFeeBN))
        .div(numericConstants_1.LIQUIDATION_FEE_PRECISION)
        .sub(oraclePrice.mul(new anchor_1.BN(ifLiquidationFee)).div(numericConstants_1.LIQUIDATION_FEE_PRECISION)));
}
exports.calculateBaseAssetAmountToCoverMarginShortage = calculateBaseAssetAmountToCoverMarginShortage;
function calculateMaxPctToLiquidate(userLastActiveSlot, userLiquidationMarginFreed, marginShortage, slot, initialPctToLiquidate, liquidationDuration) {
    // if margin shortage is tiny, accelerate liquidation
    if (marginShortage.lt(new anchor_1.BN(50).mul(numericConstants_1.QUOTE_PRECISION))) {
        return numericConstants_1.LIQUIDATION_PCT_PRECISION;
    }
    let slotsElapsed;
    if (userLiquidationMarginFreed.gt(new anchor_1.BN(0))) {
        slotsElapsed = anchor_1.BN.max(slot.sub(userLastActiveSlot), new anchor_1.BN(0));
    }
    else {
        slotsElapsed = new anchor_1.BN(0);
    }
    const pctFreeable = anchor_1.BN.min(slotsElapsed
        .mul(numericConstants_1.LIQUIDATION_PCT_PRECISION)
        .div(liquidationDuration) // ~ 1 minute if per slot is 400ms
        .add(initialPctToLiquidate), numericConstants_1.LIQUIDATION_PCT_PRECISION);
    const totalMarginShortage = marginShortage.add(userLiquidationMarginFreed);
    const maxMarginFreed = totalMarginShortage
        .mul(pctFreeable)
        .div(numericConstants_1.LIQUIDATION_PCT_PRECISION);
    const marginFreeable = anchor_1.BN.max(maxMarginFreed.sub(userLiquidationMarginFreed), new anchor_1.BN(0));
    return marginFreeable.mul(numericConstants_1.LIQUIDATION_PCT_PRECISION).div(marginShortage);
}
exports.calculateMaxPctToLiquidate = calculateMaxPctToLiquidate;
