'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchBar } from './MarketsSearchBar';
import {
	MarketCategoriesTab,
	perpMarketCategoryFilter,
	spotMarketCategoryFilter,
} from './MarketCategoriesTabs';
import {
	MarketColumnHeader,
	MarketDisplayData,
	MarketList,
} from './MarketList';
import { PerpMarketInfo, SpotMarketInfo } from 'src/stores/types';
import {
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	DriftClient,
	MarketType,
	PRICE_PRECISION_EXP,
	PerpMarketConfig,
	SpotMarketConfig,
	isVariant,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	MarketDetails24H,
	MarketId,
	UIMarket,
} from '@drift/common';
import {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import { marketHasInsuranceFund } from 'src/hooks/useInfoForCurrentlySelectedMarket';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useMarketStateStore, {
	MarketStateStore,
} from 'src/stores/useMarketStateStore';
import useOrderedMarkets from 'src/hooks/useOrderedMarkets';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useWindowSizeString from 'src/hooks/useWindowSize';
import { MARKET_TAGS, MarketCategory } from 'src/constants/markets';
import useUiUpdateInterval from 'src/hooks/useUiUpdateInterval';
import PopoverWrapper, { FloatingPopoverProps } from '../PopoverWrapper';
import { twMerge } from 'tailwind-merge';
import useFavouriteMarkets from 'src/hooks/useFavouriteMarkets';
import { getTargetPerpMarketAccount } from 'src/hooks/useTargetMarketAccount';
import useDevSwitchIsOn from '../../hooks/useDevSwitchIsOn';

const getPricePercentChange = (price: number, price24hAgo: number) => {
	const _price24hAgo = price24hAgo || 0;
	const markPrice = price || 0;

	const pricePercentChange =
		_price24hAgo && markPrice
			? 100 * ((markPrice - _price24hAgo) / price24hAgo)
			: 0;

	return pricePercentChange;
};

const getPerpMarketsRenderingInfo = (
	defaultOrderedPerpMarkets: {
		marketInfo: PerpMarketInfo;
		marketConfig: PerpMarketConfig;
		isHot: boolean;
	}[],
	driftClient: DriftClient,
	marketsData: MarketDetails24H[],
	getMarketDataForMarket: MarketStateStore['getMarketDataForMarket'],
	predictedFundings: DriftStore['predictedFundings'],
	favouriteMarketsIncludes: (marketId: MarketId) => boolean
): MarketDisplayData[] => {
	return defaultOrderedPerpMarkets.map((mktInfo) => {
		const marketId = MarketId.createPerpMarket(
			mktInfo.marketConfig.marketIndex
		);

		if (!mktInfo.marketInfo) {
			const marketConfig = mktInfo.marketConfig;

			// Market Info Hasn't Loaded Yet .. Return default/blank values
			return {
				symbol: marketConfig.symbol,
				baseAssetSymbol: marketConfig.baseAssetSymbol,
				market: UIMarket.createPerpMarket(marketConfig.marketIndex),
				price: null,
				pricePercentChange: null,
				price24hAgo: null,
				volume: null,
				high: null,
				low: null,
				openInterest: null,
				funding: null,
				launchTs: 0,
				config: marketConfig,
				noInsuranceFund: false,
				marketAccount: undefined,
				isHot: false,
				isFavorite: favouriteMarketsIncludes(marketId),
			};
		}

		const mkt = mktInfo.marketInfo.config;

		const perpMarketAccount = driftClient.getPerpMarketAccount(mkt.marketIndex);
		const uiMarket = UIMarket.createPerpMarket(mkt.marketIndex);
		const market24h = marketsData.find(
			(mktData) =>
				mktData.marketIndex === uiMarket.marketIndex &&
				isVariant(mktData.marketType, 'perp')
		);
		const markPrice =
			getMarketDataForMarket(marketId)?.derivedState?.markPrice ??
			BigNum.zero(PRICE_PRECISION_EXP);
		const baseAmount = BN.max(
			perpMarketAccount.amm.baseAssetAmountLong,
			perpMarketAccount.amm.baseAssetAmountShort.abs()
		);

		const openInterest =
			baseAmount && markPrice
				? BigNum.from(baseAmount, BASE_PRECISION_EXP).mul(markPrice).toNum()
				: undefined;

		const noInsuranceFund = !marketHasInsuranceFund(perpMarketAccount);

		const fuelBoostMaker = perpMarketAccount.fuelBoostMaker;
		const fuelBoostTaker = perpMarketAccount.fuelBoostTaker;
		const fuelBoostPosition = perpMarketAccount.fuelBoostPosition;

		const avgLongFunding = market24h?.avgLongFunding ?? 0;
		const avgShortFunding = market24h?.avgShortFunding ?? 0;

		const funding24H =
			Math.abs(avgLongFunding) >= Math.abs(avgShortFunding)
				? avgLongFunding
				: avgShortFunding;

		return {
			symbol: mkt.symbol,
			baseAssetSymbol: mkt.baseAssetSymbol,
			market: uiMarket,
			price: markPrice,
			pricePercentChange: getPricePercentChange(
				markPrice.toNum(),
				market24h?.price24hAgo
			),
			price24hAgo: market24h?.price24hAgo ?? 0,
			volume: market24h?.quoteVolume ?? 0,
			high: market24h?.priceHigh,
			low: market24h?.priceLow,
			openInterest,
			funding: parseFloat(
				predictedFundings
					.find((funding) => funding.marketIndex === mkt.marketIndex)
					?.fundingRate.toFixed(5)
			),
			funding24H: funding24H,
			launchTs: mkt.launchTs,
			config: mkt,
			noInsuranceFund,
			marketAccount: mktInfo.marketInfo.account,
			isHot: mktInfo.isHot,
			isFavorite: favouriteMarketsIncludes(marketId),
			fuelBoostMaker,
			fuelBoostTaker,
			fuelBoostPosition,
		};
	});
};

const getSpotMarketsRenderingInfo = (
	defaultOrderedSpotMarkets: {
		marketInfo: SpotMarketInfo;
		marketConfig: SpotMarketConfig;
	}[],
	marketsData: MarketDetails24H[],
	getMarketDataForMarket: MarketStateStore['getMarketDataForMarket'],
	favouriteMarketsIncludes: (marketId: MarketId) => boolean
): MarketDisplayData[] => {
	return defaultOrderedSpotMarkets.map((mktInfo) => {
		const marketConfig = SPOT_MARKETS_LOOKUP[mktInfo.marketConfig.marketIndex];
		const uiMarket = UIMarket.createSpotMarket(marketConfig.marketIndex);
		const marketId = uiMarket.marketId;

		if (!mktInfo.marketInfo) {
			// Market Info Hasn't Loaded Yet .. Return default/blank values
			return {
				symbol: marketConfig.symbol,
				baseAssetSymbol: marketConfig.symbol,
				market: uiMarket,
				price: null,
				pricePercentChange: null,
				price24hAgo: null,
				volume: null,
				high: null,
				low: null,
				config: marketConfig,
				noInsuranceFund: false,
				isFavorite: favouriteMarketsIncludes(marketId),
			};
		}

		const mkt = mktInfo.marketInfo.config;

		const market24h = marketsData.find(
			(mktData) =>
				mktData.marketIndex === uiMarket.market.marketIndex &&
				isVariant(mktData.marketType, 'spot')
		);
		const markPrice =
			getMarketDataForMarket(marketId)?.derivedState?.markPrice ??
			BigNum.zero(PRICE_PRECISION_EXP);

		return {
			symbol: mkt.symbol,
			baseAssetSymbol: uiMarket.baseAssetSymbol(),
			market: uiMarket,
			price: markPrice,
			pricePercentChange: getPricePercentChange(
				markPrice.toNum(),
				market24h?.price24hAgo
			),
			price24hAgo: market24h?.price24hAgo ?? 0,
			volume: market24h?.quoteVolume ?? 0,
			high: market24h?.priceHigh,
			low: market24h?.priceLow,
			config: mkt,
			noInsuranceFund: false,
			isFavorite: favouriteMarketsIncludes(marketId),
		};
	});
};

const MarketSelectorDropdown = ({
	targetElementId,
	onClose,
	marketType,
	setFloating,
	getFloatingProps,
	floatingStyles,
}: {
	targetElementId: string;
	onClose?: () => void;
	marketType: MarketType;
} & FloatingPopoverProps) => {
	const currentMarketIsPerp = ENUM_UTILS.match(marketType, MarketType.PERP);

	const devSwitchIsOn = useDevSwitchIsOn();

	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const marketsData = useDriftStore((s) => s.marketsData24H);
	const predictedFundings = useDriftStore((s) => s.predictedFundings);
	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);
	const getMarketHasLiquidity = useMarketStateStore(
		(s) => s.getMarketHasLiquidity
	);
	const getMarketInfoByIndexAndType = useMarketsInfoStore(
		(s) => s.getMarketInfoByIndexAndType
	);
	const windowSize = useWindowSizeString();
	const isMobile = useIsMobileScreenSize();
	const { favouriteMarketsIncludes } = useFavouriteMarkets();

	const [searchValue, setSearchValue] = useState('');
	const [selectedCategory, setSelectedCategory] = useState<MarketCategory>(
		MarketCategory.All
	);
	const [sort, setSort] = useState<
		| {
				header: MarketColumnHeader;
				isDescending: boolean;
		  }
		| undefined
	>();

	const [filtersElementHeight, setFiltersElementHeight] = useState(
		currentMarketIsPerp ? 98 : 40
	);

	const filtersElementRef = useRef(null);

	// Perp markets
	const defaultOrderedPerpMarkets = useOrderedMarkets(MarketType.PERP);
	const defaultOrderedPerpMarketsToRender = useMemo(
		() =>
			getPerpMarketsRenderingInfo(
				defaultOrderedPerpMarkets.map((mktOrderingInfo) => {
					return {
						marketInfo: getMarketInfoByIndexAndType(
							mktOrderingInfo.marketIndex,
							mktOrderingInfo.marketType
						) as PerpMarketInfo,
						marketConfig: PERP_MARKETS_LOOKUP[mktOrderingInfo.marketIndex],
						isHot: mktOrderingInfo.isHot,
					};
				}),
				driftClient,
				marketsData,
				getMarketDataForMarket,
				predictedFundings,
				favouriteMarketsIncludes
			),
		[
			driftClientIsReady,
			marketsData,
			predictedFundings,
			defaultOrderedPerpMarkets,
		]
	);

	// Spot markets
	const defaultOrderedSpotMarkets = useOrderedMarkets(MarketType.SPOT);
	const defaultOrderedSpotMarketsToRender = useMemo(() => {
		return getSpotMarketsRenderingInfo(
			defaultOrderedSpotMarkets
				.filter(
					(mkt) =>
						devSwitchIsOn ||
						getMarketHasLiquidity(MarketId.createSpotMarket(mkt.marketIndex))
				)
				.map((mktOrderingInfo) => {
					return {
						marketInfo: getMarketInfoByIndexAndType(
							mktOrderingInfo.marketIndex,
							mktOrderingInfo.marketType
						) as SpotMarketInfo,
						marketConfig: SPOT_MARKETS_LOOKUP[mktOrderingInfo.marketIndex],
					};
				}),
			marketsData,
			getMarketDataForMarket,
			favouriteMarketsIncludes
		);
	}, [
		defaultOrderedSpotMarkets,
		driftClientIsReady,
		marketsData,
		devSwitchIsOn,
	]);

	const updateDisplayMarkets = useCallback(() => {
		const updatedDisplayMarkets = getDisplayMarkets();
		setDisplayMarkets(updatedDisplayMarkets);
	}, [
		marketType,
		selectedCategory,
		searchValue,
		sort,
		defaultOrderedSpotMarketsToRender,
		defaultOrderedPerpMarketsToRender,
	]);

	useUiUpdateInterval(updateDisplayMarkets, true, true);

	useEffect(() => {
		if (!filtersElementRef.current) {
			return;
		}

		setFiltersElementHeight(
			filtersElementRef.current.getBoundingClientRect().height
		);
	}, [filtersElementRef.current]);

	const getMaxHeightForPopup = () => {
		// max height for the drawer is the height of the screen minus the bottom of the target box
		const targetBox = document
			.getElementById(targetElementId)
			.getBoundingClientRect();

		const footerBox = document.getElementById('footer').getBoundingClientRect();

		const screenHeight = window.innerHeight;

		const maxHeight = screenHeight - targetBox.bottom - footerBox.height;

		return maxHeight;
	};

	function getDisplayMarkets() {
		const isAllMarkets = selectedCategory === MarketCategory.All;
		const isSpotMarkets = selectedCategory === MarketCategory.Spot;

		const sourceMarkets = isAllMarkets
			? [
					...defaultOrderedPerpMarketsToRender,
					...defaultOrderedSpotMarketsToRender,
			  ]
			: currentMarketIsPerp
			? defaultOrderedPerpMarketsToRender
			: defaultOrderedSpotMarketsToRender;

		// filter by categories
		const categoryFilteredMarkets = isAllMarkets
			? sourceMarkets
			: isSpotMarkets
			? defaultOrderedSpotMarketsToRender
			: sourceMarkets.filter((market) => {
					const perpMarketAccount =
						driftClient && driftClientIsReady
							? getTargetPerpMarketAccount(
									market.market.market.marketIndex,
									driftClient
							  )
							: undefined;

					return currentMarketIsPerp
						? perpMarketCategoryFilter(
								market.market.market as PerpMarketConfig,
								selectedCategory,
								market.isHot,
								perpMarketAccount
						  )
						: spotMarketCategoryFilter(
								market.market.market as SpotMarketConfig,
								selectedCategory
						  );
			  });

		// filter by search
		const searchFilteredMarkets = !searchValue
			? categoryFilteredMarkets
			: categoryFilteredMarkets.filter((market) => {
					let tags = [
						market.symbol.toLowerCase(),
						market.baseAssetSymbol.toLowerCase(),
					];

					const isPerpMarket = market.market.isPerp;

					tags = tags.concat(
						(isPerpMarket
							? MARKET_TAGS.perp[market.market.marketIndex]
							: MARKET_TAGS.spot[market.market.marketIndex]) ?? []
					);

					if (isPerpMarket) {
						tags.push(
							(market.market.market as PerpMarketConfig).fullName.toLowerCase()
						);
					}

					return tags.some((tag) => tag.includes(searchValue.toLowerCase()));
			  });

		// sort
		const sortedMarkets = searchFilteredMarkets; // naturally split between perp and spot already

		if (sort) {
			const { header, isDescending } = sort;
			sortedMarkets.sort((a, b) => {
				if (header === MarketColumnHeader.Market) {
					return isDescending
						? b.symbol.localeCompare(a.symbol)
						: a.symbol.localeCompare(b.symbol);
				}

				if (header === MarketColumnHeader.Price) {
					return isDescending
						? b.price.sub(a.price).toNum()
						: a.price.sub(b.price).toNum();
				}

				if (header === MarketColumnHeader.PriceChangePct) {
					return isDescending
						? b.pricePercentChange - a.pricePercentChange
						: a.pricePercentChange - b.pricePercentChange;
				}

				if (header === MarketColumnHeader.Volume) {
					return isDescending ? b.volume - a.volume : a.volume - b.volume;
				}

				if (header === MarketColumnHeader.OpenInterest) {
					return isDescending
						? b.openInterest - a.openInterest
						: a.openInterest - b.openInterest;
				}

				if (header === MarketColumnHeader.Funding) {
					return isDescending
						? b.funding24H - a.funding24H
						: a.funding24H - b.funding24H;
				}

				return 0;
			});
		}

		return sortedMarkets;
	}

	/**
	 * Toggles the sort order based on the provided header value.
	 * If the sort order is not currently set or the header value is different from the current sort header,
	 * it sets the sort order to ascending for the "Market" header and descending for other headers.
	 *
	 * The flow of the state is as follows: No sort -> Descending -> Ascending -> No sort.
	 *
	 * For the "Market" header, the flow is: No sort -> Ascending -> Descending -> No sort.
	 */
	function toggleSort(header: MarketColumnHeader) {
		if (!sort || sort.header !== header) {
			if (header === MarketColumnHeader.Market) {
				setSort({ header, isDescending: false });
			} else {
				setSort({ header, isDescending: true });
			}
			return;
		}

		if (header === sort.header) {
			if (header === MarketColumnHeader.Market) {
				if (sort.isDescending) {
					setSort(undefined);
				} else {
					setSort({ header, isDescending: true });
				}
				return;
			}

			if (sort.isDescending) {
				setSort({ header, isDescending: false });
			} else {
				setSort(undefined);
			}
			return;
		}
	}

	const [displayMarkets, setDisplayMarkets] = useState<MarketDisplayData[]>(
		getDisplayMarkets()
	);

	return (
		<PopoverWrapper
			ref={setFloating}
			style={floatingStyles}
			{...getFloatingProps()}
			className={twMerge(
				'max-w-full shadow-lg shadow-container-border',
				windowSize === 'xs' && 'z-[100] w-screen'
			)}
		>
			<div
				className="relative flex flex-col min-h-full overflow-y-auto rounded thin-scroll bg-container-bg"
				style={{
					height: isMobile ? getMaxHeightForPopup() : undefined,
					maxHeight: getMaxHeightForPopup(),
				}}
			>
				<div
					className="sticky top-0 z-30 flex flex-col gap-5 p-2 border-b bg-inherit border-container-border"
					ref={filtersElementRef}
				>
					<SearchBar
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						placeholder="Search Markets"
					/>
					<MarketCategoriesTab
						marketType={marketType}
						selectedCategory={selectedCategory}
						setSelectedCategory={setSelectedCategory}
					/>
				</div>
				{/* 448px = header row + 8 market rows + borders */}
				<div style={{ maxHeight: 448 }}>
					<MarketList
						markets={displayMarkets}
						marketType={marketType}
						onClose={onClose}
						toggleSort={toggleSort}
						sort={sort}
						filtersElementHeight={filtersElementHeight}
						marketCategory={selectedCategory}
					/>
				</div>
			</div>
		</PopoverWrapper>
	);
};

export default MarketSelectorDropdown;
