'use client';

import React from 'react';
import useInfoForCurrentlySelectedMarket from '../hooks/useInfoForCurrentlySelectedMarket';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import FundingRateInfo from './PerpPageStats/FundingRateInfo';
import GridSectionWrapper from './GridSections/GridSectionWrapper/GridSectionWrapper';
import Chevron from './Icons/Chevron';
import Text from './Text/Text';
import MarketIcon from './Utils/MarketIcon';
import MarketLeverageIndicator from './Utils/MarketLeverageIndicator';
import NoInsuranceWarningTooltip from './Utils/NoInsuranceWarningTooltip';
import { OracleSource, PerpMarketAccount } from '@drift-labs/sdk';
import { ENUM_UTILS } from '@drift/common';
import Tooltip from './Tooltip/Tooltip';
import MarketSelectorDropdown from './MarketSelectorDropdown';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import PoweredByOpenBookAndPhoenix from './Utils/PoweredByOpenbookAndPhoenix';
import FuelBoostTooltip from './Tooltip/FuelBoostTooltip';
import { TouchButton } from './Button';

const MarketName = ({ marketName }: { marketName: string }) => {
	return (
		<Text.H4
			className={`text-text-default text-[18px] font-normal whitespace-nowrap`}
		>
			{marketName}
		</Text.H4>
	);
};

/**
 * Displays the clickable market selector, which opens a dropdown drawer of markets to select from.
 * This element recursively renders itself (with the inDrawer state) and a drawer below it when expanded
 * @param props
 * @returns
 */
export const MarketSelectorDisplay = () => {
	const boxId = 'marketSelector';

	const currentlySelectedMarketId = useDriftStore(
		(s) => s.selectedMarket.marketId
	);
	const currentlySelectedMarketSymbol = useDriftStore(
		(s) => s.selectedMarket.current.market.symbol
	);
	const currentlySelectedMarketName = useDriftStore(
		(s) => s.selectedMarket.current.marketName
	);
	const currentlySelectedMarketOracleSource = useDriftStore(
		(s) => s.selectedMarket.current.market.oracleSource
	);

	const isPerp = currentlySelectedMarketId.isPerp;
	const currentlySelectedMarketInfo = useInfoForCurrentlySelectedMarket();

	const isMobile = useIsMobileScreenSize();

	const hasInsuranceFund = currentlySelectedMarketInfo.hasInsuranceFund;
	const isDriftSpotMarket = currentlySelectedMarketInfo.isDriftSpotMarket;

	const {
		refs,
		floatingStyles,
		getReferenceProps,
		getFloatingProps,
		setIsPopoverOpen: setShowMarketSelectorDropdown,
		isPopoverOpen: showMarketSelectorDropdown,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: 'bottom-start',
		},
		{
			offset: isMobile ? 0 : 4,
			disableAutoPlacement: true,
		}
	);

	const handleClose = () => {
		setShowMarketSelectorDropdown(false);
	};

	return (
		<GridSectionWrapper
			noPad
			className={`border-0 rounded-none rounded-l-sm flex flex-col justify-center ${
				isMobile ? 'w-full' : 'w-[308px] min-w-[308px]'
			}`}
		>
			<TouchButton
				ref={refs.setReference}
				{...getReferenceProps()}
				id={boxId}
				className={`z-40 change-market-inner h-full flex flex-fill flex-row w-full items-center justify-between bg-container-bg text-text-default border-container-border relative group hover:cursor-pointer hover:bg-container-bg-hover sm:p-2 border border-r-0`}
				onClick={() =>
					setShowMarketSelectorDropdown(!showMarketSelectorDropdown)
				}
			>
				<div className="flex flex-row items-center justify-between w-full asset-dropdown-header flex-fill xs:px-4 sm:px-2 sm:pr-0">
					<div className="flex items-center">
						<MarketIcon
							marketSymbol={currentlySelectedMarketSymbol}
							className="mr-2 mb-0.5"
							isSpot={!isPerp}
							customHeight={24}
							customWidth={24}
							sizeClass="w-[24px] h-[24px]"
						/>

						{ENUM_UTILS.match(
							currentlySelectedMarketOracleSource,
							OracleSource.PYTH_1K
						) ||
						ENUM_UTILS.match(
							currentlySelectedMarketOracleSource,
							OracleSource.PYTH_1M
						) ? (
							<Tooltip
								placement="top"
								content={
									<span>{`This perp represents the price of ${currentlySelectedMarketSymbol} * ${
										ENUM_UTILS.match(
											currentlySelectedMarketOracleSource,
											OracleSource.PYTH_1M
										)
											? '1 million'
											: '1 thousand'
									}`}</span>
								}
							>
								<MarketName marketName={currentlySelectedMarketName} />
							</Tooltip>
						) : (
							<MarketName marketName={currentlySelectedMarketName} />
						)}

						<span className="pl-1.5">
							<MarketLeverageIndicator
								marketIndex={currentlySelectedMarketId.marketIndex}
								marketType={currentlySelectedMarketId.marketType}
							/>
						</span>

						{(currentlySelectedMarketInfo?.info?.account?.fuelBoostMaker > 1 ||
							currentlySelectedMarketInfo?.info?.account?.fuelBoostTaker > 1 ||
							(currentlySelectedMarketInfo?.info?.account as PerpMarketAccount)
								?.fuelBoostPosition > 1) && (
							<div className="flex flex-row items-center ml-1">
								<FuelBoostTooltip
									fuelBoostMaker={
										currentlySelectedMarketInfo?.info?.account?.fuelBoostMaker
									}
									fuelBoostTaker={
										currentlySelectedMarketInfo?.info?.account?.fuelBoostTaker
									}
									fuelBoostPosition={
										(
											currentlySelectedMarketInfo?.info
												?.account as PerpMarketAccount
										)?.fuelBoostPosition
									}
								/>
							</div>
						)}

						{isPerp &&
							currentlySelectedMarketInfo?.info?.account &&
							!hasInsuranceFund && (
								<div className="ml-2">
									<NoInsuranceWarningTooltip
										marketInfo={
											currentlySelectedMarketInfo.info
												.account as PerpMarketAccount
										}
									/>
								</div>
							)}
					</div>

					<span className="flex items-center space-x-2 text-xs text-text-label">
						{isDriftSpotMarket && (
							<Tooltip
								allowHover
								className="text-interactive-link"
								content={<PoweredByOpenBookAndPhoenix />}
							/>
						)}
						<Chevron
							direction={showMarketSelectorDropdown ? 'up' : 'down'}
							className={`mx-1`}
							colour="var(--text-emphasis)"
							size={20}
						/>
					</span>
				</div>
			</TouchButton>
			{showMarketSelectorDropdown && (
				<MarketSelectorDropdown
					onClose={handleClose}
					targetElementId={boxId}
					marketType={currentlySelectedMarketId.marketType}
					setFloating={refs.setFloating}
					floatingStyles={floatingStyles}
					getFloatingProps={getFloatingProps}
				/>
			)}
		</GridSectionWrapper>
	);
};

const MarketSelector = () => {
	return (
		<div className="inline-flex items-center justify-center w-full h-full bg-container-bg thin-scroll">
			<MarketSelectorDisplay />
			<FundingRateInfo />
		</div>
	);
};

export default MarketSelector;
