'use client';

import React from 'react';
import { PnLTimeSeriesDataPoint } from 'src/@types/performance';
import useShowAccountValues from '../hooks/useShowAccountValues';
import {
	Area,
	AreaChart,
	CartesianGrid,
	DotProps,
	ResponsiveContainer,
	TooltipProps,
	XAxis,
	YAxis,
	Tooltip as ChartsTooltip,
	ReferenceLine,
} from 'recharts';
import dayjs from 'dayjs';
import currencyPipe from 'src/utils/currencyPipe';
import Text from 'src/components/Text/Text';
import ActiveDot from './Graph/ActiveDot';
import { twMerge } from 'tailwind-merge';
import {
	NEGATIVE_RED_COLOR,
	POSITIVE_GREEN_COLOR,
	getAreaStops,
	getTimeBasedTickFormatter,
	getTimeBasedXAxisTicks,
	getTooltipDate,
} from 'src/utils/graph';
import { BigNum } from '@drift-labs/sdk';

type PnlChartProps = {
	data: PnLTimeSeriesDataPoint[];
	field: keyof PnLTimeSeriesDataPoint;
	positive: boolean;
	id: string;
	label?: string;
	useSemanticTooltipValue?: boolean;
	disableTooltips?: boolean;
	nonNotional?: boolean;
	decimalsToShow?: number;
	isInModal?: boolean;
	isPercent?: boolean;
	overrideNoBeginAtZero?: boolean;
	isHourly: boolean;
	className?: string;
};

const CustomActiveDot = (
	props: DotProps & { payload: { y: number }; field: string }
) => {
	const { cx = 0, cy = 0, payload } = props;
	const isPositive = payload[props.field] >= 0;
	const stroke = isPositive ? POSITIVE_GREEN_COLOR : NEGATIVE_RED_COLOR;

	return <ActiveDot x={cx} y={cy} stroke={stroke} />;
};

const CustomTooltip = ({
	active,
	payload,
	decimalsToShow,
	nonNotional,
	isPercent,
	field,
	propsLabel,
	isHourly,
}: TooltipProps<any, any> &
	Pick<
		PnlChartProps,
		'decimalsToShow' | 'nonNotional' | 'isPercent' | 'field'
	> & {
		propsLabel?: string;
		isHourly: boolean;
	}) => {
	if (active && payload && payload.length) {
		const data = payload[0]?.payload;
		const amount = data[field];

		const dateText = getTooltipDate(data.date, {
			isRelativeToToday: isHourly,
			dateWithTime: false,
		});

		const amountText = nonNotional
			? isPercent
				? `${String(parseFloat(amount.toFixed(decimalsToShow)))}%`
				: String(amount)
			: currencyPipe(amount, '$', decimalsToShow ?? 2);
		const isPositive = amount >= 0;

		if (!data) return null;

		return (
			<div className="flex flex-col p-2 border rounded border-container-border bg-main-bg">
				<Text.H5 className="text-text-label">{dateText}</Text.H5>
				<span>
					<Text.H5 className="text-text-emphasis">
						{propsLabel ?? field}:{' '}
					</Text.H5>
					<Text.H5
						className={isPositive ? 'text-positive-green' : 'text-negative-red'}
					>
						{amountText}
					</Text.H5>
				</span>
			</div>
		);
	}
};

const PnlChart = (props: PnlChartProps) => {
	const CUSTOM_LINE_COLORS_ID = `custom-line-colors_${props.id}`;
	const CUSTOM_AREA_COLORS_ID = `custom-area-colors_${props.id}`;

	const showAccountValues = useShowAccountValues();

	const displayedData = props.data
		.map((point) => ({
			...point,
			date: dayjs(point.date).valueOf(),
		}))
		.sort((a, b) => a.date - b.date);

	const minValue = Math.min(
		...displayedData.map((point) => point[props.field] as number)
	);
	const maxValue = Math.max(
		...displayedData.map((point) => point[props.field] as number)
	);
	const isAllZeroValues = displayedData.every(
		(point) => point[props.field] === 0
	);
	const firstDateMs = displayedData[0]?.date ?? 0;
	const lastDateMs = displayedData[displayedData.length - 1]?.date ?? 0;
	const tickFormatterDuration = props.isHourly ? '2h' : '1d';

	const noHistory = props.data.length < 2;

	if (noHistory) {
		return (
			<div className="flex items-center justify-center w-full h-full text-text-default">
				<span>No History to display</span>
			</div>
		);
	}

	return (
		<ResponsiveContainer
			id={props?.id}
			className={twMerge('pnl-chart', props.className)}
			width="100%"
			height="100%"
		>
			{/* @ts-ignore */}
			<AreaChart data={displayedData} overflow={'visible'}>
				<CartesianGrid stroke={'var(--stroke-secondary)'} />
				<XAxis
					type="number"
					dataKey={'date'}
					domain={['dataMin', 'dataMax']}
					stroke={'var(--container-border)'}
					tickMargin={8}
					ticks={getTimeBasedXAxisTicks(
						firstDateMs,
						lastDateMs,
						tickFormatterDuration
					)}
					tickFormatter={getTimeBasedTickFormatter(tickFormatterDuration)}
					// text css are found in index.css
				/>
				<YAxis
					domain={['auto', 'auto']}
					stroke={'var(--container-border)'}
					padding={{ top: 12 }}
					tickMargin={8}
					tickFormatter={(tick) => {
						return props.nonNotional
							? props.isPercent
								? `${String(tick)}%`
								: String(tick)
							: showAccountValues
							? `$${
									+tick > 100_000
										? BigNum.fromPrint(`${tick}`).toMillified()
										: parseFloat(
												Number(tick).toFixed(props.decimalsToShow ?? 2)
										  )
							  }`
							: '∗∗∗∗';
					}}
					// text css are found in index.css
				/>

				<defs>
					<linearGradient
						id={CUSTOM_LINE_COLORS_ID}
						x1="0"
						y1="0"
						x2="0"
						y2="1"
					>
						{getAreaStops(minValue, maxValue)}
					</linearGradient>
				</defs>
				<defs>
					<linearGradient
						id={CUSTOM_AREA_COLORS_ID}
						x1="0"
						y1="0"
						x2="0"
						y2="1"
					>
						{getAreaStops(minValue, maxValue, {
							startOpacity: 0.6,
							endOpacity: 0.2,
						})}
					</linearGradient>
				</defs>
				<Area
					type="monotone"
					dataKey={props.field}
					stroke={`url(#${CUSTOM_LINE_COLORS_ID})`}
					fill={`url(#${CUSTOM_AREA_COLORS_ID})`}
					strokeWidth={2}
					dot={false}
					/* @ts-ignore */
					activeDot={<CustomActiveDot field={props.field} />}
					className="z-50"
				/>
				{isAllZeroValues && ( // this is a workaround because the normal <Area /> component doesn't render if all values are 0
					<ReferenceLine y="0" stroke={POSITIVE_GREEN_COLOR} />
				)}

				<ChartsTooltip
					content={(tooltipProps) => (
						<CustomTooltip
							{...tooltipProps}
							decimalsToShow={props.decimalsToShow}
							nonNotional={props.nonNotional}
							isPercent={props.isPercent}
							field={props.field}
							propsLabel={props.label}
							isHourly={props.isHourly}
						/>
					)}
					cursor={{
						stroke: 'var(--text-label)',
						strokeDasharray: '4',
					}}
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default React.memo(PnlChart);
