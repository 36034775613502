'use client';

import { BigNum, PRICE_PRECISION_EXP } from '@drift-labs/sdk';
import { COMMON_MATH, MarketId, MarketKey } from '@drift/common';
import { useContext, useEffect, useRef } from 'react';
import { Subscription } from 'rxjs/internal/Subscription';
import Env from '../../environmentVariables/EnvironmentVariables';
import DLOB_SERVER_WEBSOCKET_UTILS from '../../providers/websockets/dlobServerWebsocketUtils';
import { WebsocketSubjectContext } from '../../providers/websockets/websocketSubscriptionProvider';
import useMarketStateStore, {
	DlobListeningSelection,
	MarketState,
} from '../../stores/useMarketStateStore';
import useMarketsForDlobToTrack, {
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';
import { RawL2Output, deserializeL2Response } from './dlobServerUtils';
import { ResultSlotIncrementer } from '../../utils/resultSlotIncrementer';
import { useInterval } from 'react-use';
import { dlog } from '../../dev';
import { useUncrossOrderbook } from './useUncrossOrderbook';
import { UNCROSS_ONLY_FOR_UI } from '../../providers/orderbookDisplayProvider';
import useDevStore from '../../stores/useDevStore';

const websocketUrl = Env.dlobWsServerToUse;

const resultIncrementer = new ResultSlotIncrementer();

const UPDATE_TICK_MS = 100; // We want the tick rate for this to be fast, because the purpose of the websocket is to be highlighy responsive. But we still use a tick-rate to prevent UI lag during cascades of state changes.
const UPDATES_BUFFER: { marketId: MarketId; data: MarketState }[] = [];

const SUBSCRIPTIONS_HOLDER = new Map<
	MarketKey,
	{ subscription: Subscription; unsubscribe: () => void }
>();

const tryParse = (data: unknown) => {
	let result;
	try {
		result = JSON.parse(data as string);
	} catch (e) {
		result = data;
	}
	return result;
};

const useSyncMarketDataWithServerWebsocket = () => {
	const marketsToTrack = useMarketsForDlobToTrack(
		DlobListeningSelection.DLOB_SERVER_WEBSOCKET
	);

	const uncrossDisabled_ = useDevStore((s) => s.dlobSettings.disableUncross);
	const uncrossDisabled = useRef(uncrossDisabled_);
	useEffect(() => {
		uncrossDisabled.current = uncrossDisabled_;
	}, [uncrossDisabled_]);

	const uncross = useUncrossOrderbook();

	const allMarketsToSubscribe =
		marketsToTrack.categories[MarketStatePriority.SelectedMarket];

	const uniquenessValue = allMarketsToSubscribe
		.map((mkt) => mkt.key)
		.sort()
		.join(',');

	useEffect(() => {
		dlog(
			`market_subscriptions`,
			`expected_websocket_subscriptions .. ${uniquenessValue}`
		);
	}, [uniquenessValue]);

	const websocketProvider = useContext(WebsocketSubjectContext);

	const setDlobState = useMarketStateStore((s) => s.set);
	const handleDatasourceFallback = useMarketStateStore(
		(s) => s.fallbackDatasource
	);

	const subscriptions = useRef(SUBSCRIPTIONS_HOLDER);

	const handleNewMarketData = (marketId: MarketId, data: MarketState) => {
		UPDATES_BUFFER.push({ marketId, data });
	};

	const bufferUpdateMutex = useRef(false);

	// This loops through at the desired tick rate, updating market state with the latest updates. The reason for doing this is to prevent cascades of store state changes for each individual websocket response.
	useInterval(() => {
		if (bufferUpdateMutex.current) return;
		bufferUpdateMutex.current = true;

		// Get the buffered updates to handle
		const updatesToHandle = [...UPDATES_BUFFER];

		// Clear the buffer
		UPDATES_BUFFER.splice(0, UPDATES_BUFFER.length);

		setDlobState((state) => {
			for (const marketDataUpdate of updatesToHandle) {
				state.marketDataState[marketDataUpdate.marketId.key] =
					marketDataUpdate.data;

				state.latestUpdateSlot = Math.max(
					state.latestUpdateSlot ?? 0,
					marketDataUpdate.data.derivedState.updateSlot
				);
			}
		});

		bufferUpdateMutex.current = false;
	}, UPDATE_TICK_MS);

	useEffect(() => {
		// # Handle any necessary unsubscriptions
		const newSubscriptionsSet = new Set<MarketKey>(
			allMarketsToSubscribe.map((mkt) => mkt.key)
		);

		const oldSubscriptionsSet = new Set<MarketKey>(
			subscriptions.current.keys()
		);

		// Unsubscribe from old subscriptions which are no longer needed
		for (const oldSubscriptionKey of oldSubscriptionsSet) {
			if (!newSubscriptionsSet.has(oldSubscriptionKey)) {
				// Unsubscribe
				const subscription = subscriptions.current.get(oldSubscriptionKey);
				if (subscription) {
					dlog(
						`market_subscriptions`,
						`unsubscribing .. ${oldSubscriptionKey}`
					);
					subscription.unsubscribe();
					subscriptions.current.delete(oldSubscriptionKey);
				}
			}
		}

		// # Handle any new subscriptions
		allMarketsToSubscribe.forEach((marketId) => {
			if (oldSubscriptionsSet.has(marketId.key)) {
				// Don't double subscribe
				return;
			}

			const marketKey = marketId.key;
			try {
				const { subscription: marketSubscription, unsubscribe } =
					websocketProvider.subscribe(
						websocketUrl,
						`dlob_liquidity_${marketKey}`,
						{
							onSubMessage: () => {
								dlog(`market_subscriptions`, `subscribing :: ${marketKey}`);
								return JSON.stringify(
									DLOB_SERVER_WEBSOCKET_UTILS.getSubscriptionProps({
										type: 'orderbook',
										market: marketId,
									})
								);
							},
							onUnSubMessage: () => {
								return JSON.stringify(
									DLOB_SERVER_WEBSOCKET_UTILS.getUnsubscriptionProps({
										type: 'orderbook',
										market: marketId,
									})
								);
							},
							onMessageCallback: (message: {
								channel: string;
								data: string;
							}) => {
								try {
									const deserialized1 = tryParse(message.data) as RawL2Output;

									const resultKey = `${message.channel}_${marketId.key}`;

									const validResult = resultIncrementer.handleResult(
										resultKey,
										deserialized1.slot
									);

									if (!validResult) {
										return; // Skip results which aren't slot-increasing
									}

									const deserialized2 = deserializeL2Response(deserialized1);

									const spreadBidAskMark =
										COMMON_MATH.calculateSpreadBidAskMark(
											deserialized2,
											deserialized2?.oracleData?.price
										);

									const orderbookToUse = uncrossDisabled.current
										? deserialized2
										: UNCROSS_ONLY_FOR_UI
										? deserialized2
										: uncross.uncrossL2OrderbookWithOracle(
												deserialized2,
												marketId
										  );

									const marketDataState: MarketState = {
										marketId: marketId,
										orderbook: orderbookToUse,
										oracle: {
											price: BigNum.from(
												deserialized2.oracleData.price,
												PRICE_PRECISION_EXP
											),
											twap: BigNum.from(
												deserialized2.oracleData.twap,
												PRICE_PRECISION_EXP
											),
											slot: deserialized2.oracleData.slot,
											confidence: deserialized2.oracleData.confidence,
											hasSufficientNumberOfDataPoints:
												deserialized2.oracleData
													.hasSufficientNumberOfDataPoints,
											twapConfidence: deserialized2.oracleData.twapConfidence,
											maxPrice: BigNum.from(
												deserialized2.oracleData.maxPrice,
												PRICE_PRECISION_EXP
											),
										},
										derivedState: {
											markPrice: BigNum.from(
												spreadBidAskMark.markPrice,
												PRICE_PRECISION_EXP
											),
											// TODO : FALLBACKS
											fallbackBid: BigNum.from(
												spreadBidAskMark.bestBidPrice,
												PRICE_PRECISION_EXP
											),
											fallbackAsk: BigNum.from(
												spreadBidAskMark.bestAskPrice,
												PRICE_PRECISION_EXP
											),
											bestBid: BigNum.from(
												spreadBidAskMark.bestBidPrice,
												PRICE_PRECISION_EXP
											),
											bestAsk: BigNum.from(
												spreadBidAskMark.bestAskPrice,
												PRICE_PRECISION_EXP
											),
											updateSlot: deserialized2.slot,
										},
									};

									handleNewMarketData(marketId, marketDataState);
								} catch (e) {
									dlog(
										`websocket_debugging`,
										`${marketKey} CAUGHT ERROR IN WEBSOCKET onMessageCallback`,
										e
									);
									throw e;
								}
							},
							messageFilter: (message: { channel: string; data: string }) => {
								return DLOB_SERVER_WEBSOCKET_UTILS.getMessageFilter({
									type: 'orderbook',
									market: marketId,
								})(message);
							},
							onErrorCallback: () => {
								dlog(
									`websocket_debugging`,
									`${marketKey} CAUGHT ERROR IN WEBSOCKET: SWITCHING FROM DLOB-SERVER WEBSOCKET TO POLLING`
								);
								handleDatasourceFallback(marketId);
							},
							errorMessageFilter: (message?: any) => {
								if (message.error) {
									console.log(`ERROR IN ERROR MESSAGE FILTER`, message);
									return true;
								}

								return false;
							},
						}
					);

				subscriptions.current.set(marketKey, {
					subscription: marketSubscription,
					unsubscribe,
				});
			} catch (e) {
				dlog(
					`websocket_debugging`,
					`${marketKey} CAUGHT ERROR SUBSCRIBING TO WEBSOCKET: SWITCHING FROM DLOB-SERVER WEBSOCKET TO POLLING`,
					e
				);
				if (subscriptions.current.get(marketKey)) {
					subscriptions.current.get(marketKey).unsubscribe();
					subscriptions.current.delete(marketKey);
				}
				handleDatasourceFallback(marketId);
			}
		});
	}, [uniquenessValue]);
};

export default useSyncMarketDataWithServerWebsocket;
