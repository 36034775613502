'use client';

import { UISerializableOrder } from '@drift/common';
import useDriftActions from 'src/hooks/useDriftActions';
import { EditOrderPopupInner } from '../Popups/EditOrderPopup';
import Modal from './Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const EditOrderModal = ({
	existingOrder,
	onClose,
}: {
	existingOrder?: UISerializableOrder;
	onClose?: () => void;
}) => {
	const { hideEditOrderModal } = useDriftActions();

	const orderToEditInStore = useDriftStore(
		(s) => s.popups.editOrderPopupOptions.orderToEdit
	);

	const orderToEdit = existingOrder ?? orderToEditInStore;

	const handleClose = onClose ?? hideEditOrderModal;

	return (
		<Modal onClose={handleClose}>
			<Modal.Body>
				<Modal.Header onClose={handleClose} showX className="sm:block">
					<Modal.Title>Edit Order</Modal.Title>
				</Modal.Header>
				<div className="flex flex-col items-center w-full text-text-default">
					<EditOrderPopupInner
						existingOrder={orderToEdit}
						onClose={handleClose}
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EditOrderModal;
