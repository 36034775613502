export const OPEN_POSITION_SL_PAST_LIQ_WARNING = `Stop Loss Order(s) have a trigger oracle price past the position's
						current liquidation price. Strongly consider editing order(s) to
						execute before liquidation begins. Open orders are force cancelled for
						accounts in liquidation.`;

export const NEW_ORDER_SL_PAST_LIQ_WARNING = `This order is a Stop Loss and has a trigger oracle price past the
														estimated liquidation price. Strongly consider
														adjusting your order to execute before liquidation begins.
														Open orders are force cancelled for accounts in
														liquidation.`;

export const BRACKET_ORDER_SL_PAST_LIQ_WARNING = `Trigger Oracle Price for the attached Stop Loss order is past the current estimated
													liquidation price. Strongly consider adjusting it to
													execute before liquidation begins. Open orders are
													force cancelled for accounts in liquidation.`;
