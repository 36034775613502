import { UIMarket } from '@drift/common';
import { produce } from 'immer';
import { PredictionMarketConfig } from 'src/@types/predictionMarkets';
import { AccountDrawerTab } from 'src/@types/types';
import { ParentOrder } from 'src/components/OrderHistoryTable/ParentOrderRow';
import { AggregateLiqState } from 'src/hooks/Liquidations/useAggregateLiquidationDataForUser';
import { create } from 'zustand';
import { SpotMarketConfig } from '@drift-labs/sdk';

export type DrawerName = keyof Omit<
	DrawersStore,
	'handleCloseDrawer' | 'get' | 'set' | 'drawerIsTransitionClosing'
>;

/**
 * DrawerStore assumes only 1 drawer can be open at a time.
 */
export interface DrawersStore {
	set: (x: (s: DrawersStore) => void) => void;
	get: (x: any) => DrawersStore;

	/**
	 * If true, the drawer is transitioning to close. This assumes only one drawer can be open at a time.
	 */
	drawerIsTransitionClosing: boolean;

	/**
	 * Handle closing the drawer and setting the state to defaults for that drawer.
	 */
	handleCloseDrawer: (panelKey: DrawerName) => void;

	/**
	 * Drawers
	 */
	accounts: { show: boolean; defaultTab: AccountDrawerTab };
	liquidations:
		| { show: true; liquidation: AggregateLiqState }
		| { show: false; liquidation: undefined };
	orders:
		| { show: true; order: ParentOrder }
		| { show: false; order: undefined };
	insuranceFundVault:
		| { show: true; marketIndex: number }
		| { show: false; marketIndex: undefined };
	marketDetails:
		| { show: true; uiMarket: UIMarket; goToPnl: boolean }
		| { show: false; uiMarket: undefined; goToPnl: false };
	betMarketDetails:
		| { show: true; market: PredictionMarketConfig }
		| { show: false; market: undefined };
	borrowLendDetails:
		| {
				show: true;
				market: SpotMarketConfig;
				displayTab: 'deposit' | 'borrow';
				showCloseBorrow?: boolean;
		  }
		| {
				show: false;
				market: undefined;
				displayTab: 'deposit' | 'borrow';
				showCloseBorrow?: boolean;
		  };
	position: {
		show: boolean;
		marketIndex: number;
		isLpPosition: boolean;
		showClosePositionForm?: boolean;
	};
	connectWallet: {
		show: boolean;
		completeWalletConnectCallback?: () => void;
	};
	marginLeverageSettings: {
		show: boolean;
	};
	getInTouchWithDrift: {
		show: boolean;
	};
}

export const useDrawersStore = create<DrawersStore>((set, get) => {
	const immerSet = (fn: (state: DrawersStore) => void) => set(produce(fn));

	return {
		set: immerSet,
		get: () => get(),
		drawerIsTransitionClosing: false,
		accounts: {
			show: false,
			defaultTab: AccountDrawerTab.Subaccounts,
		},
		liquidations: {
			show: false,
			liquidation: undefined,
		},
		orders: {
			show: false,
			order: undefined,
		},
		insuranceFundVault: {
			show: false,
			marketIndex: undefined,
		},
		marketDetails: {
			show: false,
			uiMarket: undefined,
			goToPnl: false,
		},
		betMarketDetails: {
			show: false,
			market: undefined,
		},
		borrowLendDetails: {
			show: false,
			market: undefined,
			displayTab: 'deposit',
		},
		position: {
			show: false,
			marketIndex: -1,
			isLpPosition: false,
		},
		marginLeverageSettings: {
			show: false,
		},
		getInTouchWithDrift: {
			show: false,
		},
		connectWallet: {
			show: false,
		},
		handleCloseDrawer: (panelKey: DrawerName) => {
			const state = get();

			if (state.drawerIsTransitionClosing || !state[panelKey].show) {
				return;
			}

			immerSet((state) => {
				state.drawerIsTransitionClosing = true;
			});

			const animationTimeout = setTimeout(() => {
				immerSet((state) => {
					state.drawerIsTransitionClosing = false;
					state[panelKey].show = false;

					// set props to default values (if relevant)
					if (panelKey === 'liquidations') {
						state[panelKey].liquidation = undefined;
					} else if (panelKey === 'orders') {
						state[panelKey].order = undefined;
					} else if (panelKey === 'accounts') {
						state.accounts.defaultTab = AccountDrawerTab.Subaccounts;
					} else if (panelKey === 'betMarketDetails') {
						state[panelKey].market = undefined;
					} else if (panelKey === 'borrowLendDetails') {
						state.borrowLendDetails.displayTab = 'deposit';
						state.borrowLendDetails.market = undefined;
					} else if (panelKey === 'position') {
						state.position.marketIndex = -1;
						state.position.isLpPosition = false;
						state.position.showClosePositionForm = false;
					}
				});
			}, 300);

			return () => clearTimeout(animationTimeout);
		},
	};
});

export const useDrawerToggler = (drawerName: DrawerName) => {
	const set = useDrawersStore((s) => s.set);
	const hideDrawer = useDrawersStore((s) => s.handleCloseDrawer);

	const toggleDrawer = (show: boolean) => {
		if (show) {
			set((s) => {
				s[drawerName].show = true;
			});
		} else {
			hideDrawer(drawerName);
		}
	};

	return toggleDrawer;
};
