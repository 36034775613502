import {
	ExternalLinkAction,
	NotificationType,
	TxnLinkAction,
} from 'src/utils/notifications';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import { Typo } from '@drift-labs/react';
import { ArrowRight } from '@drift-labs/icons';

const NotificationLink = ({
	linkData,
	notificationType,
}: {
	linkData: TxnLinkAction | ExternalLinkAction;
	notificationType: NotificationType;
}) => {
	if (linkData.type === 'link') {
		return (
			<a
				className={twMerge(
					'flex items-center text-interactive-link gap-1',
					notificationType === 'success' && 'text-positive-green'
				)}
				href={linkData.href}
				target="_blank"
				rel="noreferrer noopener"
			>
				<Typo.B4>{linkData.label ?? 'More Info'}</Typo.B4>
				<ArrowRight size={12} />
			</a>
		);
	}

	return Array.isArray(linkData.txnSig) ? (
		<div className="space-y-1">
			{linkData.txnSig.map((txnSig, index) => (
				<NotificationTxnLinkWithIcon
					key={txnSig}
					txnSig={txnSig}
					notificationType={notificationType}
					label={`View Transaction #${index + 1}`}
				/>
			))}
		</div>
	) : (
		<NotificationTxnLinkWithIcon
			txnSig={linkData.txnSig}
			notificationType={notificationType}
		/>
	);
};

const NotificationTxnLinkWithIcon = ({
	txnSig,
	notificationType,
	label,
}: {
	txnSig: string;
	notificationType: NotificationType;
	label?: string;
}) => {
	return (
		<a
			className={twMerge(
				'flex items-center text-interactive-link gap-1',
				notificationType === 'success' && 'text-positive-green'
			)}
			href={UI_UTILS.getUrlForTx(txnSig)}
			target="_blank"
			rel="noreferrer noopener"
		>
			<Typo.B4>{label ?? 'View Transaction'}</Typo.B4>
			<ArrowRight size={12} />
		</a>
	);
};

export default NotificationLink;
