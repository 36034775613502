import produce from 'immer';
import { create } from 'zustand';
import { PriceInfo } from './types';
import { MarketId } from '@drift/common';

export interface OraclePriceStore {
	set: (x: (s: OraclePriceStore) => void) => void;
	get: (x: any) => OraclePriceStore;
	getMarketPriceData: (market: MarketId) => PriceInfo;
	symbolMap: {
		[index: string]: PriceInfo;
	};
}

/**
 * @deprecated :: THIS IS NOT ACTUALLY DEPRECATED BUT YOU SHOULD NEARLY ALWAYS BE USING useGetOraclePriceForMarket INSTEAD
 * - ONLY MARKETS IN "BLOCKCHAIN SYNCING" MODE WILL BE IN HERE
 * - YOUR MARKET IS NOT GUARANTEED TO BE IN THIS STORE
 * - YOU SHOULD ONLY BE USING THIS STORE IF YOU'RE VERY SPECIFICALLY AWARE OF THIS STUFF
 */
const useOraclePriceStore = create<OraclePriceStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	getMarketPriceData: (market: MarketId) => get().symbolMap[market.key],
	symbolMap: {},
}));

export default useOraclePriceStore;
