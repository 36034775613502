import { Connection, PublicKey } from '@solana/web3.js';
import { Observable, BehaviorSubject } from 'rxjs';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import { dlog } from 'src/dev';

const USE_WEBSOCKET_SUBSCRIPTION_REFRESH_INTERVAL = false; // Leaving this disabled for now because we think the bug that caused adding this was a rare ephemeral network issue and it's better not to add this extra load to the RPCs
const WEBSOCKET_SUBSCRIPTION_REFRESH_INTERVAL = 60_000; // 60 seconds

const getTokenAccountBalance = async (
	tokenAccountAddress: PublicKey,
	connection: Connection
): Promise<number> => {
	try {
		const tokenAccountBalance = await connection.getTokenAccountBalance(
			tokenAccountAddress
		);

		const returnNum = tokenAccountBalance.value.uiAmount
			?.toString()
			?.includes('e')
			? 0
			: tokenAccountBalance.value.uiAmount;

		return returnNum;
	} catch (e) {
		return -1;
	}
};

export const createTokenBalanceObservable = (
	tokenAccountAddress: string,
	connection: Connection
): Observable<number> => {
	const tokenBalanceSubject = new BehaviorSubject<number | undefined>(
		undefined
	);

	const accountChangeListenerRef: { current: number | undefined } = {
		current: undefined,
	};

	const observable = new Observable<number>((subscriber) => {
		// Subscribe to the BehaviorSubject
		const subscription = tokenBalanceSubject.subscribe(subscriber);

		// Get the initial token balance and push to the subject
		const checkAccountExists = () => {
			return new Promise<boolean>((resolve) =>
				getTokenAccountBalance(new PublicKey(tokenAccountAddress), connection)
					.then((balance) => {
						const accountExists = balance !== -1;
						tokenBalanceSubject.next(accountExists ? balance : 0);
						resolve(accountExists);
					})
					.catch((e) => {
						tokenBalanceSubject.next(0);
						resolve(false);
						dlog(
							`spl_subscription`,
							`caught_error_fetching_initial_balance_for_spl_account`,
							e
						);
					})
			);
		};

		const setUpConnection = () => {
			accountChangeListenerRef.current = connection.onAccountChange(
				new PublicKey(tokenAccountAddress),
				(_accountData) => {
					getTokenAccountBalance(
						new PublicKey(tokenAccountAddress),
						connection
					).then((balance) => {
						tokenBalanceSubject.next(balance ?? 0);
					});
				},
				DEFAULT_COMMITMENT_LEVEL
			);
		};

		const teardownConnection = () => {
			if (accountChangeListenerRef.current !== undefined) {
				connection.removeAccountChangeListener(
					accountChangeListenerRef.current
				);
			}
		};

		const refreshConnection = () => {
			dlog(
				`spl_subscription`,
				`refreshing_account_change_listener_for_spl_account :: ${tokenAccountAddress}`
			);
			teardownConnection();
			setUpConnection();
		};

		const initialCheckAccountExistsResultRef: {
			current: boolean | undefined;
		} = {
			current: undefined,
		};

		// Set up the initial connection
		checkAccountExists().then((accountExists) => {
			initialCheckAccountExistsResultRef.current = accountExists;
			if (!accountExists) {
				dlog(
					`spl_subscription`,
					`skipping_account_change_listener_for_non_existent_spl_account `
				);
			} else {
				dlog(
					`spl_subscription`,
					`creating_account_change_listener_for_spl_account :: ${tokenAccountAddress}`
				);
				setUpConnection();
			}
		});

		const refreshIntervalRef: { current: NodeJS.Timeout | undefined } = {
			current: undefined,
		};

		// Refresh the connection based on the interval. Necessary because sometimes the websocket subscription seems to be broken without any real warning
		if (USE_WEBSOCKET_SUBSCRIPTION_REFRESH_INTERVAL) {
			refreshIntervalRef.current = setInterval(() => {
				if (initialCheckAccountExistsResultRef.current) {
					// Only refresh the connection if we know that the account exists
					// NOTE :: In the future when we want to be able to stay in sync with NEW token accounts created while the UI is live - we will need to change this. Search DYNAMIC_TOKEN_ACCOUNTS_SUBSCRIPTION to see more
					refreshConnection();
				}
			}, WEBSOCKET_SUBSCRIPTION_REFRESH_INTERVAL) as NodeJS.Timeout;
		}

		// Return the teardown logic
		return () => {
			dlog(
				`spl_subscription`,
				`killing_account_change_listener_for_spl_account :: ${tokenAccountAddress}`
			);

			// Clear the refresh interval
			if (refreshIntervalRef.current !== undefined) {
				clearInterval(refreshIntervalRef.current);
			}

			// Teardown the connection
			teardownConnection();

			// Unsubscribe from the subject
			subscription.unsubscribe();
		};
	});

	return observable;
};
