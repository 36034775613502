'use client';

import useAccountData from '../useAccountData';
import useCurrentSettings from '../useCurrentSettings';

export default function useSwapMarginConfig() {
	const currentAccount = useAccountData();
	const [savedSettings] = useCurrentSettings();

	const hasMarginEnabled = currentAccount?.marginEnabled;
	const hasLeverageEnabled = savedSettings.enableSwapLeverage;
	const allowLeverage = hasMarginEnabled && hasLeverageEnabled;

	return { allowLeverage, hasMarginEnabled, hasLeverageEnabled };
}
