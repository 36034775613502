import { DriftEnv } from '@drift-labs/sdk';
import { RpcEndpoint } from '@drift/common';
import { CUSTOM_RPC_LABEL } from '../../constants/constants';
import { UserSettings } from '../../environmentVariables/EnvironmentVariableTypes';
import UI_UTILS from '../uiUtils';

/**
 * This file contains the logic for RPC Utils, which are not dependent on any current app-state
 */

const DEFAULT_RPC_LABEL = 'DEFAULT_RPC_LABEL';

const getFilledRpcParams = (
	params: Partial<RpcEndpoint> & { value: string }
): RpcEndpoint => {
	return {
		label: DEFAULT_RPC_LABEL,
		allowAdditionalConnection: false,
		...params,
	};
};

const chooseRandomRpc = (rpcOptions: RpcEndpoint[]) => {
	const randomIndex = Math.floor(Math.random() * rpcOptions.length);
	const randomRpc = rpcOptions[randomIndex];
	return randomRpc;
};

/**
 * Utility / Unit-testable getRpcToUse method
 * @param rpcOptions
 * @param usingMainnetOverride
 * @param sdkEnv
 * @param currentRpcSetting
 * @returns
 */
export const _GetRpcToUse = (
	usingMainnetOverride: boolean,
	sdkEnv: DriftEnv,
	currentRpcSetting?: UserSettings['rpcEndpoint']
): RpcEndpoint => {
	const usingCustomRpc = currentRpcSetting?.label === CUSTOM_RPC_LABEL;

	if (usingCustomRpc) {
		return getFilledRpcParams(currentRpcSetting);
	}

	const rpcOptionsToUse = usingMainnetOverride
		? UI_UTILS.getRpcOptions('mainnet-beta')
		: UI_UTILS.getRpcOptions(sdkEnv);

	const selectedRpcFromOptions = rpcOptionsToUse.find(
		(rpc) => rpc.value === currentRpcSetting?.value
	);

	// if rpc saved in settings is excluded, choose a random different one
	if (!selectedRpcFromOptions) {
		return chooseRandomRpc(rpcOptionsToUse);
	}

	if (usingMainnetOverride) {
		// if using mainnet override. Only use the custom rpc if it's for mainnet
		if (usingCustomRpc && !currentRpcSetting.value.includes('devnet')) {
			return getFilledRpcParams(currentRpcSetting);
		}

		// if using mainnet override. Only use the previously saved RPC if it's for mainnet
		if (
			selectedRpcFromOptions &&
			!selectedRpcFromOptions.value.includes('devnet')
		) {
			return selectedRpcFromOptions;
		}

		// Else choose random mainnet RPC
		return chooseRandomRpc(rpcOptionsToUse);
	}

	return selectedRpcFromOptions;
};
