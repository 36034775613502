"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVaultAddressSync = getVaultAddressSync;
exports.getVaultDepositorAddressSync = getVaultDepositorAddressSync;
exports.getTokenVaultAddressSync = getTokenVaultAddressSync;
exports.getInsuranceFundTokenVaultAddressSync = getInsuranceFundTokenVaultAddressSync;
exports.getVaultProtocolAddressSync = getVaultProtocolAddressSync;
exports.getTokenizedVaultAddressSync = getTokenizedVaultAddressSync;
exports.getTokenizedVaultMintAddressSync = getTokenizedVaultMintAddressSync;
const web3_js_1 = require("@solana/web3.js");
const anchor = __importStar(require("@coral-xyz/anchor"));
function getVaultAddressSync(programId, encodedName) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('vault')),
        Buffer.from(encodedName),
    ], programId)[0];
}
function getVaultDepositorAddressSync(programId, vault, authority) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('vault_depositor')),
        vault.toBuffer(),
        authority.toBuffer(),
    ], programId)[0];
}
function getTokenVaultAddressSync(programId, vault) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('vault_token_account')),
        vault.toBuffer(),
    ], programId)[0];
}
function getInsuranceFundTokenVaultAddressSync(programId, vault, marketIndex) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('vault_token_account')),
        vault.toBuffer(),
        new anchor.BN(marketIndex).toArrayLike(Buffer, 'le', 2),
    ], programId)[0];
}
function getVaultProtocolAddressSync(programId, vault) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('vault_protocol')),
        vault.toBuffer(),
    ], programId)[0];
}
function getTokenizedVaultAddressSync(programId, vault, sharesBase) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('tokenized_vault_depositor')),
        vault.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(sharesBase.toString())),
    ], programId)[0];
}
function getTokenizedVaultMintAddressSync(programId, vault, sharesBase) {
    return web3_js_1.PublicKey.findProgramAddressSync([
        Buffer.from(anchor.utils.bytes.utf8.encode('mint')),
        vault.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(sharesBase.toString())),
    ], programId)[0];
}
