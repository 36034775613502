import { isDev } from 'src/environmentVariables/EnvironmentVariables';
import UI_UTILS from 'src/utils/uiUtils';
import UIHelpTextLink from '../UIHelpTextLink';
import { MarketData } from 'src/hooks/useMarketInfoDisplayData';
import { OracleSource, PublicKey } from '@drift-labs/sdk';

export const MarketOracleInfo = ({
	marketData,
	oracleSource,
	selectedMarketBaseAssetSymbol,
	oracle,
	tenSlotOracleDelayText,
	confidenceIntervalTooLargeText,
}: {
	marketData: MarketData;
	oracleSource: OracleSource;
	selectedMarketBaseAssetSymbol: string;
	oracle: PublicKey;
	tenSlotOracleDelayText: string;
	confidenceIntervalTooLargeText: string;
}) => {
	return (
		<>
			<br />
			Oracle provided by
			<a
				href={UI_UTILS.getOracleUrl(
					oracleSource,
					selectedMarketBaseAssetSymbol,
					oracle?.toString(),
					isDev()
				)}
				target="_blank"
				rel="noreferrer"
			>
				{' '}
				{UI_UTILS.isPythOracle(oracleSource) ? 'Pyth' : 'Switchboard'}
			</a>
			.
			<br />
			<MarketOracleInfoText
				marketData={marketData}
				oracleSource={oracleSource}
				tenSlotOracleDelayText={tenSlotOracleDelayText}
				confidenceIntervalTooLargeText={confidenceIntervalTooLargeText}
			/>
		</>
	);
};

const MarketOracleInfoText = ({
	marketData,
	oracleSource,
	tenSlotOracleDelayText,
	confidenceIntervalTooLargeText,
}: {
	marketData: MarketData;
	oracleSource: OracleSource;
	tenSlotOracleDelayText: string;
	confidenceIntervalTooLargeText: string;
}) => {
	if (UI_UTILS.isPullOracle(oracleSource)) {
		return (
			<>Last pull: {Math.max(marketData.lastOracleSlotDelay, 0)} slots ago</>
		);
	}

	return (
		<>
			{marketData.lastOracleSlotDelay >= 10 ? (
				<>
					{'⚠️ Oracle Status: Delayed/Stale Data (Updated ' +
						`${marketData.lastOracleSlotDelay}` +
						' slots ago)'}
					<br /> <br />
					{tenSlotOracleDelayText + ' The order matching is still available.'}
					<UIHelpTextLink
						href="https://docs.drift.trade/trading/oracles"
						text="More Info..."
						className="mt-2"
					/>
				</>
			) : parseFloat(marketData.indexErr) / marketData.indexPrice >= 0.02 ? (
				<>
					{'⚠️ Oracle Status: Confidence Interval Too Large (Updated ' +
						`${
							(parseFloat(marketData.indexErr) / marketData.indexPrice) * 100
						}` +
						'% )'}
					<br /> <br />
					{confidenceIntervalTooLargeText +
						' The order matching is still available.'}
					<UIHelpTextLink
						href="https://docs.drift.trade/trading/oracles"
						text="More Info..."
						className="mt-2"
					/>
				</>
			) : (
				''
			)}
		</>
	);
};
