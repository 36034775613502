'use client';

import React from 'react';
import Text from './Text/Text';
import NumberDisplayV2 from './Utils/NumberDisplayV2';
import MarketIcon from './Utils/MarketIcon';
import { UserBalanceInfo } from './UserBalancesPortfolioTable/UserBalancesPortfolioTable';
import { Info, Swap } from '@drift-labs/icons';
import useAprBreakdowns from 'src/hooks/useAprBreakdowns';
import useDriftClient from 'src/hooks/useDriftClient';
import { COMMON_UTILS } from '@drift/common';
import { MarketType } from '@drift-labs/sdk';
import Tooltip from './Tooltip/Tooltip';
import AprBreakdownTooltip from './Tooltip/AprBreakdownTooltip';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import Button from './Button';
import { useSheetsStore } from 'src/stores/useSheetsStore';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { EXTRA_YIELD_SPOT_MARKETS } from 'src/environmentVariables/EnvironmentVariables';

interface AssetBalanceCardProps {
	userBalanceInfo: UserBalanceInfo;
	setModalParams: (modalName: string, collateralTypeIndex: number) => void;
}

const AssetBalanceCard = ({
	userBalanceInfo,
	setModalParams,
}: AssetBalanceCardProps) => {
	const set = useDriftStore((s) => s.set);
	const [currentSettings] = useCurrentSettings();
	const { showZeroValues } = currentSettings;

	const [balanceDisplayType, setBalanceDisplayType] = React.useState<
		'base' | 'notional'
	>('base');

	const driftClient = useDriftClient();
	const aprBreakdowns = useAprBreakdowns();

	const sheetsSet = useSheetsStore((s) => s.set);

	const marketAprBreakdown =
		aprBreakdowns[userBalanceInfo.spotMarketIndex] ?? [];

	const aprIcons = marketAprBreakdown
		.filter((aprDetails) => !!aprDetails.icon)
		.map((aprDetails) => aprDetails.icon);

	const showWeightsTooltip =
		userBalanceInfo.assetWeight !== userBalanceInfo.defaultAssetWeight ||
		userBalanceInfo.liabilityWeight !== userBalanceInfo.defaultLiabilityWeight;

	const lendingApr = COMMON_UTILS.getDepositAprForMarket(
		userBalanceInfo.spotMarketIndex,
		MarketType.SPOT,
		driftClient
	);

	let displayApr = lendingApr;

	if (
		EXTRA_YIELD_SPOT_MARKETS.includes(userBalanceInfo.spotMarketIndex) &&
		marketAprBreakdown?.[0]?.apr
	) {
		displayApr = lendingApr + marketAprBreakdown[0].apr;
	}

	const toggleBalanceDisplayType = () => {
		setBalanceDisplayType(balanceDisplayType === 'base' ? 'notional' : 'base');
	};

	const handleCloseBorrow = () => {
		set((s) => {
			s.modalsProps.showCloseBorrowModal = {
				marketIndex: userBalanceInfo.spotMarketIndex,
			};
			s.modals.showCloseBorrowModal = true;
		});
	};

	const handleDeposit = () => {
		setModalParams('showDepositModal', userBalanceInfo.spotMarketIndex);
	};

	const handleMore = () => {
		sheetsSet((s) => {
			s.balanceCardMore.show = true;
			s.balanceCardMore.marketIndex = userBalanceInfo.spotMarketIndex;
		});
	};

	if (!showZeroValues && userBalanceInfo.netBase.eqZero()) {
		return null;
	}

	return (
		<div
			className={`w-full bg-container-bg border-container-border border-t overflow-hidden pt-2 pb-3 last:mb-0`}
		>
			<div className="flex flex-col p-2 space-y-2">
				<div className="flex flex-row items-center justify-between border-container-border">
					<div className="flex flex-row items-center text-text-default">
						<div className="relative mb-0.5">
							<MarketIcon
								marketSymbol={userBalanceInfo.symbol}
								className="mr-2"
								sizeClass={userBalanceInfo.highlighted ? 'w-6 h-6' : 'w-4 h-4'}
							/>
						</div>
						<Text.BODY1>{userBalanceInfo.symbol}</Text.BODY1>
					</div>
					<div></div>
				</div>
				<div className="flex flex-row justify-between pt-2 space-x-2">
					<div
						onClick={toggleBalanceDisplayType}
						className="flex flex-col space-y-1"
					>
						<Text.BODY2 className="block mb-0.5 text-text-label">
							Balance{' '}
							<Swap
								size={14}
								color="var(--text-label)"
								className="relative top-0.5"
							/>
						</Text.BODY2>
						<Text.BODY2 className="block mb-0.5 text-text-default">
							{balanceDisplayType === 'base' ? (
								<NumberDisplayV2
									value={userBalanceInfo.netBase}
									displayType="asset"
									assetSymbol={userBalanceInfo.symbol}
									toTradePrecision
									isHiddenAccountValue
								/>
							) : (
								<NumberDisplayV2
									value={userBalanceInfo.netQuote}
									className="text-text-default"
									displayType="notional"
									isHiddenAccountValue
								/>
							)}
						</Text.BODY2>
					</div>
					<div className="flex flex-col items-end space-y-1 text-right">
						<Text.BODY2 className="block mb-0.5 text-text-label">
							Deposit/Borrow Rate
						</Text.BODY2>
						<Text.BODY2 className="block mb-0.5 text-text-default">
							<AprBreakdownTooltip
								symbol={userBalanceInfo.symbol}
								depositAprPercent={lendingApr}
								marketAprBreakdowns={marketAprBreakdown}
							>
								<div className="flex items-center divide-x divide-text-secondary">
									<div className="flex items-center gap-1">
										{aprIcons.map((icon, index) => (
											<img
												src={icon}
												key={`${icon}_${index}`}
												className="w-[14px] h-[14px] mr-1"
											/>
										))}
										<span className="pr-2 text-positive-green">
											{displayApr.toFixed(2)}%
										</span>
									</div>

									<span className="pl-2 text-warn-yellow-pressed">
										{COMMON_UTILS.getBorrowAprForMarket(
											userBalanceInfo.spotMarketIndex,
											MarketType.SPOT,
											driftClient
										).toFixed(2)}
										%
									</span>
								</div>
							</AprBreakdownTooltip>
						</Text.BODY2>
					</div>
				</div>
				<div className="flex flex-row justify-between pt-2 space-x-2">
					<div>
						<Text.BODY2 className="block mb-0.5 text-text-label">
							Liq. Price
						</Text.BODY2>
						<Text.BODY2 className="text-text-default">
							{userBalanceInfo.liqPrice.gtZero() ? (
								<NumberDisplayV2
									value={userBalanceInfo.liqPrice}
									displayType="notional"
									isHiddenAccountValue
								/>
							) : (
								'None'
							)}
						</Text.BODY2>
					</div>
					<div className="flex flex-col items-end space-y-1 text-right">
						<Text.BODY2 className="block mb-0.5 text-text-label">
							Asset/Liability Weight
						</Text.BODY2>
						<Text.BODY2 className="block mb-0.5 text-text-default">
							{showWeightsTooltip ? (
								<Tooltip
									allowHover
									content={
										<>
											{'IMF discount has been applied for this balance size.'}
											<a
												href="https://docs.drift.trade/getting-started/cross-collateral-deposits"
												target="_blank"
												rel="noreferrer"
												className="px-1"
											>
												Learn More
											</a>
										</>
									}
								>
									{`${userBalanceInfo.assetWeight}% / ${userBalanceInfo.liabilityWeight}%`}{' '}
									<Info className="ml-1" color="var(--text-default)" />
								</Tooltip>
							) : (
								`${userBalanceInfo.assetWeight}% / ${userBalanceInfo.liabilityWeight}%`
							)}
						</Text.BODY2>
					</div>
				</div>
				<div className="flex flex-row justify-between pt-2 space-x-2">
					{userBalanceInfo.netBase.gteZero() ? (
						<Button.Secondary
							size="MEDIUM"
							positiveGreen
							onClick={handleDeposit}
							className="w-1/2"
						>
							Deposit
						</Button.Secondary>
					) : userBalanceInfo.borrowsBase.gtZero() ? (
						<Button.Secondary
							size="MEDIUM"
							onClick={handleCloseBorrow}
							className="w-1/2"
							negativeRed
						>
							Close Borrow
						</Button.Secondary>
					) : null}

					<Button.Secondary
						size="MEDIUM"
						onClick={handleMore}
						className="w-1/2"
					>
						More
					</Button.Secondary>
				</div>
			</div>
		</div>
	);
};

export default React.memo(AssetBalanceCard);
