'use client';

import React, {
	useEffect,
	useState,
	useRef,
	memo,
	ReactNode,
	useMemo,
} from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../Text/Text';
import { ChevronRight, Info, SuccessFilled, Copy } from '@drift-labs/icons';
import Env, {
	CurrentSpotMarkets,
	// isDev,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import { BigNum, QUOTE_PRECISION_EXP, SpotMarketConfig } from '@drift-labs/sdk';
import useAccountExists from 'src/hooks/useAccountExists';
import Button from '../Button';
import { QRCodeSVG } from 'qrcode.react';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import { CollateralInput } from '../Inputs/CollateralInput';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import {
	NEW_ACCOUNT_BASE_COST,
	// WORMHOLE_WALLET_WHITELIST,
} from 'src/constants/constants';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import useReferralRewardStructure from 'src/hooks/useReferralRewardStructure';
import Utility from '../Inputs/Utility';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useSafePush from 'src/hooks/useSafePush';
import Tooltip from '../Tooltip/Tooltip';
import UI_UTILS from 'src/utils/uiUtils';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import { MIN_LEFTOVER_SOL, MarketId, ONE_DAY_MS } from '@drift/common';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import NewAccountCreationCost from '../Utils/NewAccountCreationCost';
import useNumberOfAccountsCreatedLast24h from 'src/hooks/utils/useNumberOfAccountsCreatedLast24h';
import InfoMessage from '../TradeForm/InfoMessage';
import { MaxSubaccountsWarning } from '../Utils/MaxSubaccountsWarning';
import { useMaxSubaccountsReached } from 'src/hooks/useMaxSubaccountsReached';
import { useSyncWalletBalances } from '../../hooks/useSyncWalletBalances';

const MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT = Env.minInitialDepositValue;
const SOL_BANK = CurrentSpotMarkets.find((bank) => bank.symbol === 'SOL');
const USDC_BANK = CurrentSpotMarkets.find((bank) => bank.symbol === 'USDC');
const SHOW_FAUCET_BUTTON = false; //isDev();
// This is hidden for now, until we decide where it links to or whether it will use some integration that opens in current page
// const SHOW_BUY_SOL_BUTTON = false;

const WalletBalanceBar = ({ bank }: { bank: SpotMarketConfig }) => {
	const [balance] = useCurrentWalletCollateralBalance(bank);

	return (
		<div className="flex flex-row items-center justify-between h-8 px-2 border rounded border-input-border bg-input-bg">
			<Text.BODY2>Wallet Balance</Text.BODY2>
			<Text.BODY2>
				{balance.toTradePrecision()} {bank.symbol}
			</Text.BODY2>
		</div>
	);
};

const ShortWalletAddressWithCopy = () => {
	const [copied, setCopied] = useState(false);
	const wallet = useCurrentWalletAdapter();
	const abbreviatedAddress = wallet?.publicKey
		? UI_UTILS.abbreviateAddress(wallet?.publicKey)
		: '';

	const handleCopy = () => {
		if (!copied) {
			UI_UTILS.copyToClipboard(wallet?.publicKey?.toString());
			setCopied(true);
			setTimeout(() => setCopied(false), 2000);
		}
	};

	return (
		<>
			<div className="text-text-label">
				<Text.BODY3>Your wallet address</Text.BODY3>
			</div>
			<div className="flex flex-row items-center justify-between h-8 pl-2 pr-1 border rounded border-input-border bg-input-bg">
				<Text.BODY2>{abbreviatedAddress}</Text.BODY2>
				<button
					className="flex items-center px-2 py-1 m-0 border-none outline-none bg-none"
					onClick={handleCopy}
				>
					{copied ? 'Copied!' : 'Copy'} <Copy size={16} className="ml-1" />
				</button>
			</div>
		</>
	);
};

const DepositSectionHeader = ({
	complete,
	children,
}: {
	complete?: boolean;
	children: string | ReactNode;
}) => {
	return (
		<div className="mt-4 py-2 px-4 border border-container-border flex flex-row justify-between items-center h-[43px]">
			<Text.BODY1>{children}</Text.BODY1>
			<div>
				{complete && <SuccessFilled size={20} className="relative top-0.5" />}
			</div>
		</div>
	);
};

const BridgeCta = (props: { onClick: () => void }) => {
	return (
		<div
			className="flex items-center justify-between px-4 py-3 mt-6 border cursor-pointer border-container-border"
			onClick={props.onClick}
		>
			<div className="flex items-center gap-3">
				<img src="/assets/images/bridge-chains-icon.webp" className="w-9" />
				<Text.H5 className="tracking-normal text-text-emphasis">
					Deposit funds from Arbitrum and Ethereum
				</Text.H5>
			</div>
			<span className="grid text-text-label place-items-center">
				<ChevronRight />
			</span>
		</div>
	);
};

function FundYourAccountPanel(props: { onClickBridgeCta: () => void }) {
	const actions = useDriftActions();
	const { captureEvent } = usePostHogCapture();
	const referrer = useDriftStore((s) => s.referrerParam);
	const { refereeDiscountPercent } = useReferralRewardStructure();
	const safePush = useSafePush();
	const accountExists = useAccountExists();
	const wallet = useCurrentWalletAdapter();
	const connected = useWalletIsConnected();
	const [lastRouteBeforeConnect, solBalance, isMagicAuth] = useDriftStore(
		(s) => [
			s.wallet.lastRouteBeforeConnect,
			s.wallet.currentSolBalance,
			s.wallet.isMagicAuth,
		]
	);
	const prevSolBalance = useRef(solBalance);
	useSyncWalletBalances();

	const [showDepositSolBox, setShowDepositSolBox] = useState(
		solBalance.lt(NEW_ACCOUNT_BASE_COST)
	);
	const [showDepositAssets, setShowDepositAssets] = useState(
		solBalance.gte(NEW_ACCOUNT_BASE_COST)
	);
	const [submitting, setSubmitting] = useState(false);

	// Deposit amount state
	const [userHasAgreedToFee, setUserHasAgreedToFee] = useState(false);
	const [depositAmount, setDepositAmount] = useState('');
	const [selectedBank, setSelectedBank] = useState(USDC_BANK);
	const [currentBalance] = useCurrentWalletCollateralBalance(selectedBank);
	const isInitialSolDeposit = selectedBank.symbol === 'SOL';
	const [transferableBalance] = useTransferableWalletCollateralBalance(
		selectedBank,
		isInitialSolDeposit
	);
	const oraclePrice = useMemoizedOraclePrice(
		MarketId.createSpotMarket(selectedBank.marketIndex)
	);

	const {
		numberOfAccountsCreatedLast24h,
		lastCreatedAccountTimestamp,
		incrementNumberOfAccountsCreated,
	} = useNumberOfAccountsCreatedLast24h();

	const hitMaxNumberOfAccountsIn24h =
		!accountExists &&
		Date.now() - lastCreatedAccountTimestamp < ONE_DAY_MS &&
		numberOfAccountsCreatedLast24h >= Env.maxNumberOfAccountsPer24h;

	const maxSubaccountsReached = useMaxSubaccountsReached();

	const assetCurrentPrice =
		oraclePrice?.shiftTo(QUOTE_PRECISION_EXP) ??
		BigNum.zero(QUOTE_PRECISION_EXP);

	const depositAmountBigNum = BigNum.fromPrint(
		depositAmount ? depositAmount : '0',
		selectedBank.precisionExp
	);
	const exceededMax = depositAmountBigNum.gt(transferableBalance);
	const exceedsMinDeposit =
		depositAmountBigNum
			.shiftTo(QUOTE_PRECISION_EXP)
			.mul(assetCurrentPrice)
			.toNum() >= MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT;

	const { totalCost: totalAccountCreationCost } = useAccountCreationCost();

	const solRequiredToDeposit = totalAccountCreationCost.add(MIN_LEFTOVER_SOL);
	const hasEnoughSolInWallet = solBalance.gte(solRequiredToDeposit);

	const canDeposit =
		exceedsMinDeposit &&
		showDepositAssets &&
		!submitting &&
		depositAmountBigNum.gtZero() &&
		userHasAgreedToFee &&
		hasEnoughSolInWallet &&
		!hitMaxNumberOfAccountsIn24h;

	if (!connected) {
		safePush('/');
	}

	if (accountExists) {
		safePush(isMagicAuth ? '/onboarding/complete' : '/');
	}

	const handleAirdrop = async (bank: SpotMarketConfig) => {
		await actions.tryFaucetAirdrop(bank);
	};

	const handleChangeAssetType = (bank: SpotMarketConfig) => {
		setDepositAmount('');
		setSelectedBank(bank);
	};

	const handleSkipDeposit = () => {
		captureEvent('onboarding_skip_deposit');
		safePush(lastRouteBeforeConnect || '/');
	};

	const handleConfirmDeposit = async () => {
		try {
			setSubmitting(true);

			const accountCreatedSuccess =
				await actions.initializeAndDepositCollateralForAccount({
					amount: depositAmountBigNum,
					spotMarketConfig: selectedBank,
					poolId: selectedBank.poolId,
				});

			if (accountCreatedSuccess) {
				incrementNumberOfAccountsCreated();
				if (isMagicAuth) {
					safePush('/onboarding/complete');
				} else {
					safePush(lastRouteBeforeConnect || '/');
				}
			}
		} catch (_err) {
			// this code block doesn't run even when it fails and idk why???
		}

		captureEvent('submitted_deposit', {
			spot_market_symbol: selectedBank.symbol,
			depositAmount: depositAmountBigNum.toNum(),
			newAccount: true,
		});

		setSubmitting(false);
	};

	const hideSolDepositBox = () => {
		// Only doing this as a state change in order to add a timing delay to hide different elements in order
		// May be able to refactor this away with the right CSS keyframes
		// But so far I could not get it to work as smoothly that way
		setTimeout(() => {
			setShowDepositAssets(true);
		}, 900);
		setTimeout(() => {
			setShowDepositSolBox(false);
		}, 1000);
	};

	useEffect(() => {
		if (
			solBalance.gte(totalAccountCreationCost) &&
			prevSolBalance.current.lte(totalAccountCreationCost) &&
			showDepositSolBox === true
		) {
			hideSolDepositBox();
		}
		prevSolBalance.current = solBalance;
	}, [solBalance?.toString(), showDepositSolBox]);

	useEffect(() => {
		if (exceededMax) {
			setDepositAmount(transferableBalance.print());
		}
	}, [exceededMax]);

	const walletString = useMemo(() => {
		return wallet?.publicKey?.toString();
	}, [wallet?.publicKey]);

	return (
		<div className="w-full h-full">
			<Text.H1 className="text-text-emphasis">Fund Your Account</Text.H1>

			{!isMagicAuth && <BridgeCta onClick={props.onClickBridgeCta} />}

			{/* ******** DEPOSIT SOL SECTION ******** */}
			<div className="mt-6 mb-4">
				<DepositSectionHeader complete={!showDepositSolBox}>
					Deposit SOL to Your Wallet
				</DepositSectionHeader>
				<div
					className={`onboarding-accordion-item ${
						showDepositSolBox
							? 'onboarding-accordion-item-open'
							: 'onboarding-accordion-item-closed-fade'
					} overflow-hidden`}
				>
					<div className="p-4 border border-t-0 border-container-border bg-container-bg">
						<div className="leading-4">
							<Text.BODY3>
								Only deposit funds using the Solana network
								<Tooltip
									content={
										<div
											className={
												'z-50 bg-tooltip-bg flex flex-col gap-2 text-left whitespace-nowrap rounded-[4px]'
											}
										>
											<a
												href="https://www.drift.trade/learn/dex-deposit-withdraw"
												target="_blank"
												rel="noreferrer"
											>
												<Text.BODY2 className="text-text-interactive">
													How to deposit from a CEX
												</Text.BODY2>
											</a>
											<a
												href="https://www.drift.trade/learn/how-to-bridge-assets"
												target="_blank"
												rel="noreferrer"
											>
												<Text.BODY2 className="text-text-interactive">
													How to bridge assets
												</Text.BODY2>
											</a>
										</div>
									}
									allowHover
									className="relative inline-flex ml-[2px] top-1"
								>
									<span className="text-text-label">
										<Info size={16} />
									</span>
								</Tooltip>
							</Text.BODY3>
						</div>
						<div className="mt-2 leading-4">
							<Text.BODY3>Your wallet address:</Text.BODY3>
						</div>
						<div className="w-full my-6 text-center">
							<div className="rounded bg-[#fff] p-2 w-auto mx-auto inline-block">
								<QRCodeSVG value={walletString} width={120} height={120} />
							</div>
						</div>
						<ShortWalletAddressWithCopy />
						<div className="mt-2">
							<WalletBalanceBar bank={SOL_BANK} />
						</div>
					</div>
				</div>

				{/* ******** DEPOSIT COLLATERAL SECTION ******** */}
				<DepositSectionHeader complete={!showDepositSolBox && false}>
					Deposit Collateral to Drift
				</DepositSectionHeader>
				<div
					className={`onboarding-accordion-item ${
						showDepositAssets
							? 'onboarding-accordion-item-open'
							: 'onboarding-accordion-item-closed-fade'
					} overflow-hidden`}
				>
					<div className="p-4 border border-t-0 border-container-border bg-container-bg">
						<div>
							<Text.BODY2 className="tracking-normal">
								Deposit assets as collateral.
							</Text.BODY2>
						</div>
						<div className="w-full my-6 text-center">
							<div className="rounded bg-[#fff] p-2 w-auto mx-auto inline-block">
								<QRCodeSVG value={walletString} width={120} height={120} />
							</div>
						</div>
						<ShortWalletAddressWithCopy />
						{/* <div className="mt-2">
							<WalletBalanceBar bank={selectedBank} />
						</div> */}
						<div className="mt-4">
							<CollateralInput
								selectedMarket={selectedBank}
								maxAmount={transferableBalance}
								currentBalance={currentBalance}
								onChangeMarket={handleChangeAssetType}
								label="Asset and amount to deposit"
								value={depositAmount}
								onChangeValue={(value) => setDepositAmount(value)}
								amountLabel={'Wallet balance'}
								source={{ type: 'wallet' }}
							/>
						</div>
					</div>
				</div>
			</div>

			{referrer && (
				<div className="mt-2 leading-4">
					<Text.BODY1 className="text-text-secondary">
						Referred by:{' '}
						<span className="text-text-emphasis">{referrer}. </span>
					</Text.BODY1>
					<Utility.VERTSPACERM />
					<Text.BODY1 className="text-text-secondary">
						You&apos;ll receive {refereeDiscountPercent}% off trading fees after
						creating your account.
					</Text.BODY1>
				</div>
			)}

			<div className="mt-6 leading-4">
				{hitMaxNumberOfAccountsIn24h ? (
					<>
						<InfoMessage
							type="warn"
							messageTitle=""
							message={
								<>
									You can only create 2 new Drift accounts every 24 hours.
									Please connect a different wallet with an existing account or
									try again later.
								</>
							}
						/>
					</>
				) : (
					<>
						{maxSubaccountsReached ? (
							<MaxSubaccountsWarning />
						) : !hasEnoughSolInWallet ? (
							<InfoMessage
								type="warn"
								messageTitle={
									<div className="flex items-center space-x-1">
										<span>Not enough SOL in your wallet</span>
									</div>
								}
								message={
									<>
										{`You need to have ${solRequiredToDeposit.prettyPrint(
											true
										)} SOL in your wallet to cover account creation and transaction fees.`}
									</>
								}
							/>
						) : (
							<NewAccountCreationCost
								checkboxUpdated={(selection) =>
									setUserHasAgreedToFee(selection)
								}
								forceAgreement={true}
							/>
						)}
					</>
				)}
			</div>

			{/* ******** BUTTONS SECTION ******** */}
			<div className="my-8">
				<Button.Secondary
					highlight
					className="w-full"
					size={'MEDIUM'}
					disabled={!canDeposit}
					onClick={handleConfirmDeposit}
				>
					{submitting
						? 'Confiming Deposit...'
						: !exceedsMinDeposit
						? `Minimum Initial Deposit: $${MIN_DEPOSIT_AMOUNT_FOR_NEW_ACCT}`
						: 'Confirm Deposit'}
				</Button.Secondary>
			</div>

			<div className="my-8 text-center">
				<Button.Ghost
					highlight
					className="w-full mb-4 text-text-secondary"
					size={'MEDIUM'}
					onClick={handleSkipDeposit}
				>
					Deposit Later
				</Button.Ghost>
			</div>

			{SHOW_FAUCET_BUTTON && (
				<div className="flex flex-row my-8">
					<Button.Secondary
						className="w-full mr-2"
						size={'MEDIUM'}
						onClick={() => {
							handleAirdrop(SOL_BANK);
						}}
					>
						Gimme SOL
					</Button.Secondary>
					<Button.Secondary
						className="w-full"
						size={'MEDIUM'}
						onClick={() => {
							handleAirdrop(USDC_BANK);
						}}
					>
						Gimme USDC
					</Button.Secondary>
					<Button.Secondary
						className="w-full ml-2"
						size={'MEDIUM'}
						onClick={() => {
							if (showDepositSolBox) {
								setShowDepositSolBox(false);
								setTimeout(() => {
									setShowDepositAssets(true);
								}, 500);
							} else {
								setShowDepositSolBox(true);
								setShowDepositAssets(false);
							}
						}}
					>
						Toggle
					</Button.Secondary>
				</div>
			)}
		</div>
	);
}

export default memo(FundYourAccountPanel);
