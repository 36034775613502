import { QuoteResponse } from '@drift-labs/sdk';
import InfoMessage from '../TradeForm/InfoMessage';
import Button from '../Button';
import { memo } from 'react';

const messageClass = 'flex flex-col gap-2 text-text-default';

export const COULD_NOT_FIND_ANY_ROUTE_ERROR = 'COULD_NOT_FIND_ANY_ROUTE';

// Display helpful error messages when the swap quote failed
const SwapQuoteErrorDisplay = ({
	quoteDetails,
	swapMode,
	useDirectRoute,
	className,
	onTryExactIn,
}: {
	quoteDetails: QuoteResponse;
	swapMode: 'ExactIn' | 'ExactOut';
	useDirectRoute: boolean;
	className?: string;
	onTryExactIn?: () => void;
}) => {
	if (quoteDetails?.error) {
		// Known error codes
		if (
			quoteDetails.errorCode === 'NO_ROUTES_FOUND' ||
			quoteDetails.errorCode === 'COULD_NOT_FIND_ANY_ROUTE'
		) {
			if (swapMode === 'ExactOut') {
				return (
					<InfoMessage
						className={className}
						type="warn"
						message={
							<div className="flex flex-col gap-2 text-text-default">
								<div>
									No routes found using ExactOut swap mode for this pair
								</div>
								<div>
									<Button.Secondary size="SMALL" onClick={onTryExactIn}>
										Try ExactIn Swap
									</Button.Secondary>
								</div>
							</div>
						}
					/>
				);
			} else if (useDirectRoute) {
				return (
					<InfoMessage
						className={className}
						type="warn"
						message={
							<div className="flex flex-col gap-2 text-text-default">
								<div>
									No routes found, try disabling direct route or swapping with
									smaller size
								</div>
							</div>
						}
					/>
				);
			} else {
				return (
					<InfoMessage
						className={className}
						type="warn"
						message={
							<div className="flex flex-col gap-2 text-text-default">
								<div>No routes found for this pair</div>
							</div>
						}
					/>
				);
			}
		}

		if (
			quoteDetails.errorCode === 'ROUTE_PLAN_DOES_NOT_CONSUME_ALL_THE_AMOUNT'
		) {
			return (
				<InfoMessage
					className={className}
					type="warn"
					message={
						<div className="flex flex-col gap-2 text-text-default">
							<div>
								Route would exceed max number of accounts allowed per
								transaction
							</div>
							<div>
								Try closing any open positions and settling balances in your
								Drift account, then try again, or use a different swap pair
							</div>
						</div>
					}
				/>
			);
		}

		// Default case, unknown error code
		return (
			<InfoMessage
				className={className}
				type="warn"
				message={
					<div className={messageClass}>
						<div>
							Jupiter swap quote could not be loaded, please try again later
						</div>
						<div>Error code: {quoteDetails.errorCode}</div>
					</div>
				}
			/>
		);
	}

	return null;
};

export default memo(SwapQuoteErrorDisplay);
